import {
  Box, Table, TableBody, TableCell, TableHead, TableRow
} from '@mui/material';
import Decimal from 'decimal.js';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Area, Bar, CartesianGrid, Cell, ComposedChart, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useAuth } from 'src/AuthProvider';
import useAppStore from 'src/store';
import { graphAreaColors, sxChartPaddingWrapper, sxChartTooltip, sxChartTooltipLegendBox, sxChartTooltipLegendBoxTitle, sxChartTooltipTable, sxChartWrapper, sxChartWatermarkTopCenterWide } from 'src/styles/Chart';
import { hslStrToHex } from 'src/utils/common';
import { date, formatDate, getDaysDifference, getPastDaysArray, today } from 'src/utils/date';
import { formatUSDWithSign } from 'src/utils/numbers';
import { PNLHistoricalItem, Token } from '../types';
import apis from '../utils/apis';
import theme from '../utils/theme';
import DefaultTokensSelector, { defaultTokens } from './DefaultTokensSelector';
import DeltaValue from './DeltaValue';
import GraphEmptyStateCurtain from './GraphEmptyStateCurtain';
import LoadingIcon from './Loading';
import RechartsDateAxisTicks from './RechartsDateAxisTicks';
import SidedQuantityValue from './SidedQuantityValue';
import ValueTableCell from './ValueTableCell';

/**
 * 
 * @param dataKey data to be rendered
 * @param bucketData aggregate data inbetween standard dates as bucket
 * @returns 
 */
function UserPNLGraph({
  tokenCodes,padGraph,hidesCumulative,showsIndividualTokens,daysRange,
}:{
  tokenCodes?:string[],padGraph?:boolean,hidesCumulative?:boolean,showsIndividualTokens?:boolean,daysRange?:number,
}){
  const auth = useAuth();  

  const [selectedTokenCodes,setSelectedTokenCodes] = useState(defaultTokens);
  useEffect(()=>{
    setSelectedTokenCodes(tokenCodes || defaultTokens);
  },[tokenCodes]);
  const {isLoading:isLoadingTokens,data:tokens} = apis.token.useMarketsPageTokens();
  // user account info
  const { currentUserAccountId: currentUserAccountId } = useAppStore();
  const { isFetching:isFetchingUserAccounts,data:userAccounts } = apis.user.useUserAccounts(auth);
  const tradeAccount = userAccounts&&apis.user.extractUserAccount(userAccounts,currentUserAccountId);
  const { data:pnlHistorical, dataUpdatedAt, isFetching:isFetchingPNL } = apis.user.useUserPNLHistorical(auth,{ accountId: tradeAccount?.accountId });
  const {data:userAccountPerformanceLive, isLoading:isLoadingRatePositions} = apis.user.useUserAccountPerformanceLive(auth,{ accountId: tradeAccount?.accountId });
  const groupedPositions = useMemo(() => userAccountPerformanceLive?.positionDetails, [userAccountPerformanceLive])
  const itemsMaxDays = useMemo(()=>{
    const lastDate = pnlHistorical && Object.keys(pnlHistorical).reduce((lastDate,tokenId)=>{
      return Math.min(lastDate,pnlHistorical[tokenId].reduce((lastDate,item)=>{
        return Math.min(lastDate,item.date);
      },lastDate));
    },Date.now());
    return lastDate!==undefined ? Math.abs(getDaysDifference(lastDate)) : 90;
  },[pnlHistorical]);
  const daysArray = getPastDaysArray(daysRange||itemsMaxDays).reverse();

  const isLoadingAny = isFetchingPNL;

  // checks if user has any order placed (for showing "start trading prompt")
  const hasPlacedOrders = useMemo(()=>{
    return (groupedPositions?.length||0)>=0;
  },[groupedPositions]);

  // token color mapping to keep them consistent through filter
  const tokenColors = useMemo(()=>{
    const tokenColors = new Map<string,string>();
    tokens?.forEach((token,i)=>{
      const areaColor = graphAreaColors[i%graphAreaColors.length];
      tokenColors.set(token.code,areaColor);
    })
    return tokenColors;
  },[tokens]);

  // token filter
  const tokenFilter = useCallback((t:Token)=>{
    // console.log('tokenFilter',selectedTokenCodes,t.code)
    if(selectedTokenCodes&&!selectedTokenCodes.includes(t.code)) return false;
    return true;
  },[selectedTokenCodes]);


  // generate rechart data
  const {data,xAxisTicks} = useMemo(()=>{
    const tokenCumulativeChangeMap = new Map<string,Decimal>();
    const xAxisTicks:number[] = [];
    const data = daysArray.filter(timestamp=>{
      if(daysRange!==undefined && daysRange<=today().diff(date(timestamp),'d')) return false;
      return true;
    }).map((timestamp,dayIndex)=>{
      xAxisTicks.push(timestamp);
      const entry:any = {
        timestamp, name:formatDate(moment(timestamp)), cumulativeMax: new Decimal(0), changeMax: new Decimal(0), 
        pnlSum: new Decimal(0), cumulativeSum: new Decimal(0), navInUSDSum: new Decimal(0),
      };
      selectedTokenCodes.forEach(tokenCode=>{
        const token = tokens?.find(t=>t.code===tokenCode);
        let changeMax = new Decimal(0);
        let change = new Decimal(0);
        let navInUSD = new Decimal(0);
        if(token){
          pnlHistorical&&Object.keys(pnlHistorical).forEach((tokenId)=>{
            if(token.tokenId!==Number(tokenId)) return;
            const item = pnlHistorical[tokenId]?.find((item:PNLHistoricalItem)=>{
              const itemTimestamp = date(item.date).unix()*1000;
              return itemTimestamp === timestamp;
            });
            if(item){
              const tokenPrice = new Decimal(item.tokenPrice||0);
              if(tokenPrice.eq(0)) console.log('tokenPrice 0 ', item.tokenId);
              navInUSD = new Decimal(item.netAssetValue||0).mul(tokenPrice); // should use historical token price 
              change = new Decimal(item.dailyPnl||0);
              changeMax = changeMax.abs().add(change.abs());
            }
          });
        }
        const cumulative = change.add(tokenCumulativeChangeMap.get(tokenCode)||new Decimal(0))
        entry[`${tokenCode}-navInUSD`] = navInUSD;
        entry[`${tokenCode}-pnl`] = change;
        entry[`${tokenCode}-cumulative`] = cumulative;
        tokenCumulativeChangeMap.set(tokenCode,entry[`${tokenCode}-cumulative`]);
        entry.cumulativeMax = Decimal.max(entry.cumulativeMax.abs(),entry[`${tokenCode}-cumulative`].abs()); // non stacked
        entry.changeMax = entry.changeMax.add(changeMax);
        //sum values
        entry.navInUSDSum = entry.navInUSDSum.add(navInUSD);
        entry.pnlSum = entry.pnlSum.add(change);
        entry.cumulativeSum = entry.cumulativeSum.add(cumulative);
      });
      if(!showsIndividualTokens){
        entry.cumulativeMax = entry.cumulativeSum;
      }
      entry.cumulativeMax*= 1.2;
      entry.changeMax*= 1.2;
      return entry;
    })
    return {data,xAxisTicks};
  },[tokens,daysArray,daysRange,selectedTokenCodes,pnlHistorical,showsIndividualTokens]);

  function ChartTooltip({active,payload,label}:any){
    if (active && payload && payload.length) {
      let pnlSum:Decimal|undefined;
      let cumulativeSum:Decimal|undefined;
      let navInUSDSum:Decimal|undefined;
      const valuesByToken = new Map<string,{tokenCode:string,navInUSD?:Decimal,pnl?:Decimal,cumulative?:Decimal}>();
      payload.forEach((p:{dataKey:string,value:Decimal,payload:any})=> {
        const [tokenCode] = p.dataKey.split('-');
        const tokenCodes = showsIndividualTokens ? [tokenCode] : selectedTokenCodes;
        tokenCodes.forEach(tokenCode=>{
          let {navInUSD: nav,pnl,cumulative} = valuesByToken.get(tokenCode)||{};
          nav = p.payload[`${tokenCode}-navInUSD`] ?? nav;
          pnl = p.payload[`${tokenCode}-pnl`] ?? pnl;
          cumulative = p.payload[`${tokenCode}-cumulative`] ?? cumulative;
          valuesByToken.set(tokenCode,{tokenCode,navInUSD: nav,pnl: pnl,cumulative});
        });
        // sums
        navInUSDSum = navInUSDSum ?? p.payload[`navInUSDSum`];
        pnlSum = pnlSum ?? p.payload[`pnlSum`];
        cumulativeSum = cumulativeSum ?? p.payload[`cumulativeSum`];
      });
      const rows:JSX.Element[] = [];
      valuesByToken.forEach(({tokenCode,navInUSD: nav,pnl: daily,cumulative})=>{
        const token = tokens?.find(t=>t.code===tokenCode);
        const fillColor = tokenColors.get(tokenCode);
        if(token){
          rows.push(
            <TableRow key={tokenCode}>
              <TableCell sx={{ fontWeight: '700'}}>{showsIndividualTokens&&<Box sx={sxChartTooltipLegendBox} style={{backgroundColor:fillColor}}></Box>}{tokenCode}</TableCell>
              <ValueTableCell mono align='right'><SidedQuantityValue value={nav} prefix='$' dp={2}/></ValueTableCell>
              <ValueTableCell mono align='right'><DeltaValue value={daily} prefix='$' dp={2}/></ValueTableCell>
              {!hidesCumulative&&<ValueTableCell mono align='right'><DeltaValue value={cumulative} prefix='$' dp={2}/></ValueTableCell>}
            </TableRow>
          );
        }
      });
      if(!showsIndividualTokens){
        rows.push(
          <TableRow key={'sum'} sx={{borderTop:'1px solid', borderColor: 'grey.800'}}>
            <TableCell sx={{ fontWeight: '700'}}>TOTAL</TableCell>
            <ValueTableCell mono align='right'><SidedQuantityValue value={navInUSDSum} prefix='$' dp={2}/></ValueTableCell>
            <ValueTableCell mono align='right'><DeltaValue value={pnlSum} prefix='$' dp={2}/></ValueTableCell>
            {!hidesCumulative&&<ValueTableCell mono align='right'><DeltaValue value={cumulativeSum} prefix='$' dp={2}/></ValueTableCell>}
          </TableRow>
        )
      }
      return (
        <Box sx={sxChartTooltip}>
          <Box sx={sxChartTooltipLegendBoxTitle}>{`${payload[0].payload.name}`}</Box>
          <Table sx={sxChartTooltipTable}>
            <TableHead><TableRow><TableCell></TableCell>
              <ValueTableCell align='right'>NAV</ValueTableCell><ValueTableCell align='right'>Daily P&L</ValueTableCell>
              {!hidesCumulative&&<ValueTableCell align='right'>Cumulative P&L</ValueTableCell>}
            </TableRow></TableHead>
            <TableBody>
              {rows}
            </TableBody>
          </Table>
        </Box>
      );
    }
    return null;
  }

  const GraphComponents = new Array<JSX.Element>();
  if(showsIndividualTokens){
    // make sure bars on top and areas below
    tokens?.filter(tokenFilter).forEach((t,i)=>{
      const fillColor = tokenColors.get(t.code);
      GraphComponents.push(
          <Bar type="monotone" dataKey={`${t.code}-pnl`} key={`${i}-pnl`} stackId="daily" yAxisId="daily" maxBarSize={8}>
            {data.map((c,i)=>(
              <Cell key={i} fill={fillColor}/>
            ))}
          </Bar>
      );
      if(!hidesCumulative){
        GraphComponents.unshift(<>
          <Area type="monotone" dataKey={`${t.code}-cumulative`} key={`${i}-cumulative`} yAxisId="cumulative" strokeWidth={2} stroke={fillColor} fillOpacity={0} /* fill={`url(#fill_cumulative_${i})`} */ connectNulls/>
          {/* <defs><linearGradient id={`fill_cumulative_${i}`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={fillColor} stopOpacity={0.5}/>
            <stop offset="0%" stopColor={fillColor} stopOpacity={0.2}/>
            <stop offset="20%" stopColor={fillColor} stopOpacity={0}/>
          </linearGradient></defs> */}
        </>);
      }
    });
  }else{
    const barColor = theme.palette.primary.light;
    const areaColor = theme.palette.primary.light;
    GraphComponents.push(
      <Bar type="monotone" dataKey={`pnlSum`} key={`pnlSum`} name="Daily P&L" stackId="daily" yAxisId="daily" maxBarSize={8} fill={barColor}/>
    );
    GraphComponents.unshift(
      <Area type="monotone" dataKey={`cumulativeSum`} key={`cumulativeSum`} name="Cumulative P&L" legendType={"plainline"} yAxisId="cumulative" strokeWidth={2} stroke={areaColor} strokeOpacity={0.5} fillOpacity={0.05} fill={areaColor} connectNulls/>
    );
  }

  const sxChartContainer = {
    ...(padGraph ? sxChartPaddingWrapper : sxChartWrapper),
    marginBottom: '-10px',
    "::before": {
      ...sxChartWatermarkTopCenterWide!["::before"]
    }
  }

  return (<>
    {(tokenCodes===undefined)&&<DefaultTokensSelector onChange={setSelectedTokenCodes}/>}
    <Box sx={sxChartContainer}>
      <ResponsiveContainer width="100%">
        <ComposedChart data={data} barGap={0} barCategoryGap={0}>
          <Legend verticalAlign="top" align="right" height={24}/>
          <ReferenceLine yAxisId="cumulative" y={0} stroke={`${hslStrToHex(theme.palette.text.primary)}88`} />
          <YAxis fontSize="0.625rem" yAxisId={'daily'} dataKey={!hidesCumulative?'cumulativeMax':'changeMax'} scale="linear" domain={
            (([dataMin,dataMax]:number[])=>{const absMax = Math.max(Math.abs(dataMin), Math.abs(dataMax)); return [-absMax, absMax];})
          } tickCount={8} tickLine={false} tickFormatter={(v,i)=>`${formatUSDWithSign(v,false,true)}`} width={42} stroke={`${hslStrToHex(theme.palette.text.primary)}88`}/>
          {/* keeping the axis for rendering - revisit to consolidate axes */}
          <YAxis hide fontSize="0.625rem" yAxisId="cumulative" orientation='right' dataKey="cumulativeMax" scale="linear" domain={
            (([dataMin,dataMax]:number[])=>{const absMax = Math.max(Math.abs(dataMin), Math.abs(dataMax)); if(absMax===Infinity) return [0,1]; else return [-absMax, absMax];})
          } tickCount={itemsMaxDays===0?1:8} tickLine={false} tickFormatter={(v,i)=>itemsMaxDays===0?'':`${formatUSDWithSign(v,false,true)}`} width={40} stroke={`${hslStrToHex(theme.palette.text.primary)}88`}/>
          {xAxisTicks.length>0&&<XAxis fontSize="0.625rem" dataKey="timestamp" domain={['dataMin','dataMax']} tickLine={false} interval={0} tick={<RechartsDateAxisTicks xAxisTicks={xAxisTicks}/>} stroke={`${hslStrToHex(theme.palette.text.primary)}88`}/>}
          {GraphComponents}
          <CartesianGrid vertical={false} stroke={`${hslStrToHex(theme.palette.text.primary)}22`} />
          <Tooltip content={<ChartTooltip/>} cursor={{strokeDasharray: '3 3', stroke: `${hslStrToHex(theme.palette.text.primary)}88`}}/>
        </ComposedChart>
      </ResponsiveContainer>
      {!hasPlacedOrders&&<GraphEmptyStateCurtain showsStartTrading extraText='If you have already started trading for the first time, this chart may take a few hours to update.'/>}
      {hasPlacedOrders&&itemsMaxDays===0&&<GraphEmptyStateCurtain extraText='No Orders'/>}
      {(isLoadingAny&&!pnlHistorical)&&<LoadingIcon curtain/>}
    </Box>
  </>);
}

export default UserPNLGraph;