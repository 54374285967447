import { Box, SxProps } from '@mui/material';
const sxWrapper:SxProps = {lineHeight:'1rem',display: 'inline-block',marginRight:'0.5em',position:'relative',top:'4px'};
const sxIcon:SxProps = {
  backgroundSize: 'contain',
  backgroundPosition: 'center bottom',
  backgroundRepeat: 'no-repeat',
  fontSize: '2.5em',
  lineHeight: 0,
};
function WalletIcon({wallet}:{wallet:string}) {
  const filename = wallet.toLowerCase().replaceAll(/\s/g,'-');
  const bgImgUrl = `url(${process.env.PUBLIC_URL}/images/wallets/${filename}.svg),url(${process.env.PUBLIC_URL}/images/wallets/${filename}.png)`;
  return (
    <Box sx={sxWrapper}>
    <Box component="i" sx={sxIcon} style={{backgroundImage:`${bgImgUrl}`}}>　</Box></Box> // fullwidth whitespace U+3000 `　`
  );
}
export default WalletIcon;