import { Box, Button, Checkbox, CheckboxProps, IconButton, InputAdornment, Link, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { ReactComponent as CheckedIcon } from 'src/Components/Icons/CheckedIcon.svg';
import { ReactComponent as IndeterminateIcon } from 'src/Components/Icons/IndeterminateIcon.svg';

const sxCheckbox: SxProps = {
    padding: '0',
    paddingRight: '12px',
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      fill: 'primary.light'
    },
    '&.Mui-checked': {
      color: 'primary.light',
    }
}

export const TableCheckbox = ({
    selectionStatus = 'none',
    onSelectionChange
} : {
    selectionStatus?: 'checked' | 'partial' | 'none',
    onSelectionChange?: (selectionStatus: 'checked' | 'partial' | 'none') => void
}) => {
    return (
        <Checkbox
            sx={sxCheckbox}
            onChange={() => onSelectionChange && onSelectionChange(selectionStatus)}
            checked={selectionStatus === 'checked'}
            checkedIcon={<CheckedIcon />}
            indeterminate={selectionStatus === 'partial'}
            indeterminateIcon={<IndeterminateIcon />}
        />
    )
}