import { EOrderSide, ERateOrderType, ETradeType } from "./market";

export enum ESwapLegType {
    PRIMARY = "leg1",
    SECONDARY = "leg2"
}

export enum EFormType {
    PRIMARY = "primary",
    SECONDARY = "secondary"
}

export enum ESwapTradeSideLabel {
    PAY = "Pay",
    RECEIVE = "Receive"
}

export type SameCurrencyLegProps = {
    primarySide: EOrderSide;
    legKey: string;
}

export type SwapOrderFormParams = {
    tradeType: ETradeType;
};

export interface FormStateProps {
  orderSide: EOrderSide,
  orderType: ERateOrderType,
  quantity: string,
  rate: string|undefined,
  maxSlippage: string|undefined,
}