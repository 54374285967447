import { useCallback } from "react";
import { isUndefined } from "lodash";
import BaseMarketSwitcher from "src/Components/Common/BaseMarketSwitcher";
import { useSwapParameterStore } from 'src/store/useSwapParametersStore';
import { ESwapLegType } from "src/types"

const SwapMarketSwitcher = ({
    legKey, 
}:{
    legKey: string
}) => {
    const markets = useSwapParameterStore((state) => {
        const leg = state.legs?.[legKey]
        if (legKey === ESwapLegType.PRIMARY) {
            return (state.markets ?? []).filter(m => m.tokenId === state.legs.leg1?.tokenId && !isUndefined(m.daysToMaturity))
        } else {
            const primaryMarket = (state.markets ?? []).find(pm => pm.marketId === state.legs.leg1?.marketId)
            const tokenId = leg?.tokenId
            return (state.markets ?? []).filter(m => m.tokenId === tokenId && (isUndefined(m.daysToMaturity) || m.daysToMaturity === primaryMarket?.daysToMaturity))
        }
    });
    const selectedMarketId = useSwapParameterStore((state) => state.legs?.[legKey]?.marketId)
    const setLegMarket = useSwapParameterStore((state) => state.setLegMarket)
    const onChange = useCallback(
        (newdMarketId: number) => {
            setLegMarket(legKey, newdMarketId)
        }, [setLegMarket, legKey]
    )

    return (
        <BaseMarketSwitcher onChangedSelectedMarketId={onChange} selectedMarketId={selectedMarketId} markets={markets}/>
    )
}

export default SwapMarketSwitcher;