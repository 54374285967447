import {
  Box, FormControl, InputAdornment, InputLabel, TextField
} from '@mui/material';
import { SxProps } from '@mui/system';
import Decimal from 'decimal.js';
import React, { useState } from 'react';
import { BootstrapInput, styleFormLabel, sxInputLabel, sxInputLabelFloatRight } from '../styles/Form';
import { dps } from '../utils/numbers';

interface onRateChangeFn {(rate:string):void};
function RateInput({
  name,label,rate,step,min,max,readOnly,disabled,
  sx, variant="bootstrap", fullWidth, bottomLabel, onRateChange
}:{
  name:string,label?:string,rate:string,step?:string,min?:string,max?:string,readOnly?:boolean,disabled?:boolean,
  sx:SxProps, variant?:"bootstrap"|"standard"|"filled"|"outlined"|undefined, fullWidth?:boolean, bottomLabel?:string|JSX.Element, onRateChange?:onRateChangeFn,
}){
  const [intermediateValue,setIntermediateValue]:[string|undefined,Function] = useState(undefined);
  const stepDecimal = dps(new Decimal(step||0).mul(100));
  // min/max needs to be multiplied as well for step (step takes into account of min/max)
  const minNormalized = !min?'':new Decimal(min).mul(100).toFixed(stepDecimal);
  const maxNormalized = !max?'':new Decimal(max).mul(100).toFixed(stepDecimal);
  const value = intermediateValue!=undefined?intermediateValue:(rate&&new Decimal(rate).mul(100).toFixed(stepDecimal));
  function handleChange(event:React.ChangeEvent<{value:string}>){
    setIntermediateValue(event.target.value);
  }
  function handleKeyPress(event:React.KeyboardEvent){
    switch(event.key){
      case 'Enter':
        triggerValueUpdate((event.target as any).value);
        break;
    }
  }
  function handleBlur(event:React.ChangeEvent<{value:string}>){
    triggerValueUpdate(event.target.value);
  }
  function triggerValueUpdate(value:string){
    try {
      let _rate = new Decimal(value);
      // console.log('_rate',_rate);
      const _min = new Decimal(min||0).mul(100);
      const _max = new Decimal(max||Infinity).mul(100);
      if(_rate.lt(_min)) _rate=_min;
      if(_rate.gt(_max)) _rate=_max;
      // refreshRangeValues(_rate,'');
      // if(_rate&&!_rate.eq(rangeFrom||0)){
      //   setRangeFrom(_rate.div(100));
      // }
      // console.log('RateInput triggerValueUpdate',_rate);
      onRateChange&&onRateChange(_rate.div(100).toString());
    }catch(error){
      // possible dirty input - discard
    }
    setIntermediateValue();
  }
  if(variant=='bootstrap'){
    return (<FormControl variant="standard" fullWidth={fullWidth}>
      <InputLabel shrink htmlFor={`input-${name}`} sx={sxInputLabel}>{label}</InputLabel>
      {bottomLabel&&<InputLabel shrink htmlFor={`input-${name}`} sx={sxInputLabelFloatRight}>{bottomLabel}</InputLabel>}
      <BootstrapInput name={name} type='number' sx={{width:'100%'}} id={`input-${name}`}
        endAdornment={(<InputAdornment position="end">%</InputAdornment>)}
        inputMode={'numeric'}
        readOnly={readOnly}
        inputProps={{
          step:new Decimal(step||0).mul(100), 
          min: minNormalized,
          max: maxNormalized,
        }}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
      />
      {/* <Box><Box sx={styleFormLabel}>{bottomLabel}</Box></Box> */}
    </FormControl>);
  }
  return (<Box sx={sx}>
    <TextField name={name} type='number' sx={{width:'100%'}} id={`input-${name}`}
      label={label} variant={variant||'standard'} InputLabelProps={{shrink: true}}
      InputProps={{
        endAdornment:(<InputAdornment position="end">%</InputAdornment>),
        inputMode: 'numeric', 
        readOnly: readOnly,
        inputProps: {
          step:new Decimal(step||0).mul(100), 
          min: minNormalized,
          max: maxNormalized,
        },
        disabled: disabled,
      }}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
    />
    <Box><Box sx={styleFormLabel}>{bottomLabel}</Box></Box>
  </Box>);
}

export default RateInput;