import { Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { broadcastQueryClient } from "@tanstack/query-broadcast-client-experimental";
import { SnackbarProvider, closeSnackbar, enqueueSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { SNACKBAR_TIMEOUT } from 'src/constants/app';
import AppContent from './AppContent';
import { AuthProvider } from './AuthProvider';
import ErrorFallback from './Components/ErrorFallback';
import WalletProvider from './WalletProvider';
import GlobalStylesComponent from './styles/GlobalStylesComponent';
import { WebsocketProvider } from './utils/apis/WebsocketContext';
import { parseError, subscribeCloseSnackbar, truncateErrorMessage, unsubscribeCloseSnackbar } from './utils/common';
import theme from './utils/theme';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';

/**
 * // DEV note on persist local storage cache across tabs:
 * queryClient useQuery must only save primitive & a few supported native types to prevent error from broadcast-channel's structured clone logic
 * this impacts src/types/ definitions
 * ref: https://stackoverflow.com/a/52223341
 */
function App() {
  // referential stable - without useState/useMemo a new cache will be generated each time App is rendered
  const {queryClient} = useMemo(() => {
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 1000 * 60 * 1,
          cacheTime: 1000 * 60 * 1,

          refetchInterval: false,      // disable auto refetch - should be done on per query level
          refetchOnWindowFocus: false, 
          // refetchOnMount: true,
          // refetchOnReconnect: true,
          // keepPreviousData: false,
          useErrorBoundary: false,     // use queryCahce.onError to handle error toast
          // retryDelay: 1000,
          retry: 1,
          meta: {'label':'defaultClient'},
        },
      },
      queryCache: new QueryCache({
        onError: (error)=>{
          const {key,message} = parseError(error);
          if(error===''||message==='') return; // ignore silent messages
          enqueueSnackbar(truncateErrorMessage(message),{key,variant:"error",persist:true});
        },
      })
    });
    broadcastQueryClient({
      queryClient
    });
    return {queryClient/* ,localStoragePersister */};
  },[]);
  (window as any).$QC = queryClient;
  // custom global custome event listener to handle closing API error snackbars
  useEffect(()=>{
    const handleCloseSnackbarEvent = (event:Event)=>{
      if(event instanceof CustomEvent){
        const snackbarKey = event.detail.key;
        if(snackbarKey!==undefined){
          closeSnackbar(snackbarKey);
          // also close generic `Network Error` snackbars
          closeSnackbar('Network Error');
        }
      }
    };
    subscribeCloseSnackbar((handleCloseSnackbarEvent));
    return ()=>{ unsubscribeCloseSnackbar(handleCloseSnackbarEvent); };
  },[]);
  return (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <GlobalStylesComponent/>
        <SnackbarProvider 
          style={{width:"480px",maxWidth:"100%",flexWrap:"nowrap",whiteSpace:"break-spaces",wordBreak: "break-word"}}
          autoHideDuration={SNACKBAR_TIMEOUT}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} dense preventDuplicate 
          maxSnack={10} 
          action={(snackbarId)=><Button sx={{minWidth:0}} onClick={()=>closeSnackbar(snackbarId)}><Close/></Button>}
        >
          <QueryClientProvider client={queryClient}>
            <WalletProvider>
              <AuthProvider>
                <WebsocketProvider>
                  <AppContent/>
                </WebsocketProvider>
              </AuthProvider>
            </WalletProvider>
          </QueryClientProvider>
        </SnackbarProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  </ErrorBoundary>
  );
}

export default App;
