// TODO add zod typeguard
import { useQueries, useQuery } from "@tanstack/react-query";
import Decimal from 'decimal.js';
import { AuthContextType } from 'src/AuthProvider';
import { z } from "zod";
import {
  AggregatedMarketPosition,
  AggregatedMarketPositionSchema,
  AccountPositionsOrderByImpactSchema,
  AccountRiskMetrics,
  AccountRiskMetricsSchema,
  AccountTokenPosition,
  AccountTokenPositionSchema,
  AccountTokenWithImpactSchema, AccountTransaction,
  AccountWalletToken,
  AuthResponse,
  EETHUserActionEventStatus, ETHUserActionEvent, EUserAccountType, EWithdrawalRequestStatus,
  GroupedPosition,
  MTMPerformanceSchema,
  MixedRateOrder,
  PNLHistoricalSchema,
  UserAccountPerformanceLiveSchema,
  Token,
  TokenWithBalance,
  UnderlyingAsset,
  UserAccount,
  UserMarkToMarket, UserAccountPerformanceLive, UserPositionAndDv01,
  WithdrawalRequest,
  WithdrawalRequestParams
} from '../../types';
import { containsToken, get, post, postFD, zp } from './common';

/**
 * verify signin
 * */
export async function verify(addr: string, nonceHash: string, signature: string):Promise<AuthResponse>{
  const params = ({ addr, nonceHash, signature }) as Record<string,string>;
  const { success, data, errorMsgKey } = await postFD('/api/user/login/verify',params);
  if(!success) throw new Error(errorMsgKey);
  return data;
};

export async function refresh():Promise<AuthResponse> {
  const { success, data, errorMsgKey } = await post('/api/user/login/refresh');
  if(!success) throw new Error(errorMsgKey);
  return data;
}

export async function login(addr:string,chainId:string,signature?:string){
  const params = { addr, chainId } as Record<string,string>;
  if(signature!=undefined) params.signature = signature;
  const { success, data, errorMsgKey } = await postFD('/api/user/login',params);
  if(!success) throw new Error(errorMsgKey);
  const { status, nonceHash, eip712Message, user } = data;
  return {
    user, status, nonceHash, eip712Message
  };
};


// user accounts
/**
 * fetch user account list
 * should sync cache time with user.useUserAccounts, user.useUserAccountPerformanceLive, rate.useUserAllPositions
 * */
export function useUserAccounts(auth:AuthContextType,cb?:(userAccounts:UserAccount[])=>void) {
  return useQuery(['user',{user:auth.user,type:'account',action:'listAccounts'}],async()=>await _fetchUserAccounts(auth),{
    enabled:!!auth.user&&!!auth.accessToken?.token,
    onSuccess:(data)=>cb&&cb(data)
  });
}
export async function _fetchUserAccounts(auth:AuthContextType):Promise<UserAccount[]> {
  if(!auth?.user) throw new Error('error.auth.notLoggedIn');
  const accounts = await get(`/api/user/accounts`,{},auth).then(ret=>ret?.data?.accounts) as UserAccount[];
  const accountRiskMetricsArray = await Promise.allSettled(accounts?.map(account=>{
    return get(`/api/user/account/risks`,{
      accountId: account.accountId,
    },auth).then(ret=>ret?.data?.accountRiskMetrics);
  }));
  const accountRiskMetricsMap = new Map<number,AccountRiskMetrics>();
  accountRiskMetricsArray.forEach((data,i)=>{
    const accountId = accounts.at(i)?.accountId;
    if(accountId!==undefined && data.status==='fulfilled'){
      const { value:accountRiskMetrics } = data as PromiseFulfilledResult<AccountRiskMetrics>;
      accountRiskMetricsMap.set(accountId,accountRiskMetrics);
    }
  });
  const accountLivePerformanceArray = await Promise.allSettled(accounts?.map(async account=>{
    return await _fetchUserAccountPerformanceLive(auth,{accountId:account.accountId});
  }));
  const accountTokenPositionsAccountIdMap = new Map<number,Map<number,AccountTokenPosition>>();
  accountLivePerformanceArray.forEach((data,i)=>{
    const accountId = accounts.at(i)?.accountId;
    if(accountId!==undefined && data.status==='fulfilled'){
      const { value: { tokenPositions:accountTokenPositions }} = data as PromiseFulfilledResult<UserAccountPerformanceLive>;
      accountTokenPositions.map(accountTokenPosition=>{
        const accountTokenPositionsAccountIdTokenIdMap = accountTokenPositionsAccountIdMap.get(accountId)||new Map<number,AccountTokenPosition>();
        accountTokenPositionsAccountIdTokenIdMap.set(accountTokenPosition.tokenId,accountTokenPosition);
        accountTokenPositionsAccountIdMap.set(accountId,accountTokenPositionsAccountIdTokenIdMap);
      });
    }
  });

  // get all erc721 token ids from underlying token api
  for await (const account of accounts) {
    account.tokens = (await Promise.all(
        account.tokens.map(async (token) => {
          if (token.tokenType === 2) {
            const underlyingAssets: UnderlyingAsset[] = token.underlyingAssets ?? [];
            if (underlyingAssets.length > 0)
              return underlyingAssets.map(ua => {
                const erc721TokenId: string = ua?.erc721TokenId?.toString()??'';
                const quantity = '1';
                const availableQuantity = '1';
                return {...token,erc721TokenId,quantity,availableQuantity}
              });
            const erc721TokenId = '';
            return {...token,erc721TokenId};
          }
          const erc721TokenId = '';
          return {...token,erc721TokenId};
        }))).flat(1);
    // console.log(`_fetchUserAccounts: account tokens: ${JSON.stringify(account.tokens)}`);
    account.healthScore = /* account.healthScore|| */accountRiskMetricsMap.get(account.accountId)?.healthscore;
    account.maxBorrowInUsd = /* account.maxBorrowInUsd|| */accountRiskMetricsMap.get(account.accountId)?.maxBorrowInUsd;
    account.tokenPositionMap = accountTokenPositionsAccountIdMap.get(account.accountId);
  }
  return accounts;
};

export function extractAccountTokenAccountFromAccounts(accounts:UserAccount[], accountType:EUserAccountType, tokenId?:number):AccountWalletToken {
  const accountByType = accounts.filter(account=>account.type==accountType)[0];
  return accountByType?.tokens.filter(t => containsToken(t, tokenId))[0];
};
export function extractUserAccount(accounts:UserAccount[], accountId?:number):UserAccount {
  return accounts.filter(account=>account.accountId==accountId)[0]||accounts.filter(account=>account.type==EUserAccountType.TRADING)[0];
};
export function extractAccountTokenAccount(accounts:UserAccount[], accountId?:number, tokenId?:number):AccountWalletToken {
  return extractUserAccount(accounts,accountId).tokens.filter(account=>account.tokenId==tokenId)[0];
};
export function extractAccountTokenAccountFromAccount(account:UserAccount, tokenId?:number):AccountWalletToken {
  return account.tokens.filter(account=>account.tokenId==tokenId)[0];
};

/**
 * fetch account transactions for user
 * */
export function useUserAccountTransactions(auth:AuthContextType,{
  accountId, type, types, startId, limit=20, enabled=true,
}:{
  accountId?:number, type?:number|string, types?:number[], startId?:number, limit?:number, enabled?:boolean,
}={},cb:(trxs:AccountTransaction[],hasNextPage:boolean,pageStartId?:number)=>void) {
  return useQuery(['user',{user:auth.user,type:'account',action:'listAccountTransactions',accountId,txType:type,types,startId,limit}],async()=>await _fetchUserAccountTransactions(auth,{accountId, type, types, startId, limit}),{
    enabled:(!!auth.user)&&enabled,
    onSuccess:({trxs,hasNextPage,pageStartId})=>{
      cb(trxs,hasNextPage,pageStartId);
    }
  });
}
async function _fetchUserAccountTransactions(auth:AuthContextType,{
  accountId, type, types, startId, limit=20
}:{
  accountId?:number, type?:number|string, types?:number[], startId?:number, limit?:number
}={}) {
  if(types){
    type = types.join(',');
  }
  const { data } = await get(`/api/user/account${accountId!=undefined?`/${accountId}`:''}/trxs`, {
    startId, limit:limit+1, type
  },auth);
  const trxs:AccountTransaction[] = data.trxs;
  const hasNextPage = limit+1==trxs?.length;
  const pageStartId = trxs.at(trxs.length-1)?.trxId;
  return { trxs:trxs.slice(0,limit), hasNextPage, pageStartId };
};


/**
 * Create new trading account
 * */
export async function createTradingAccount(auth:AuthContextType,params:ICreateTradingAccountParams):Promise<UserAccount> {
  const { data } = await post(`/api/user/account`, params, auth);
  const { account } = data;
  return account;
}
export interface ICreateTradingAccountParams {
  accountId: number; //
  name: string; //
};

/**
 * Transfer token (cash / collateral) between accounts
 * */
export async function transferToken(auth:AuthContextType, params:ITransferTokenParams):Promise<void> {
  if(params.fromAccountId==params.toAccountId){
    throw new Error('Different accounts required');
  }
  if(new Decimal(params.quantity).lte(0)){
    throw new Error('Amount must be greater than zero');
  }
  const { data } = await postFD(`/api/user/account/transfer/token`, params, auth);
  return;
}
export interface ITransferTokenParams {
  fromAccountId: number;
  toAccountId: number;
  tokenId: number;
  quantity: string;
};

/**
 * Request withdraw from infinity
 */

 export async function requestWithdrawal(auth:AuthContextType, params:WithdrawalRequestParams):Promise<void> {
  if(new Decimal(params.quantity).lte(0)){
    throw new Error('Amount must be greater than zero');
  }
  const { data } = await post(`/api/user/funding/withdraw`, [params], auth);
  return;
}

/** funding histories */
export function useUserWithdrawRequests(auth:AuthContextType,{pendingOnly=true,limit=100,startId}:{pendingOnly?:boolean,limit?:number,startId?:number}={}) {
  return useQuery(['user',{user:auth.user,type:'account',action:'listWithdrawals',pendingOnly,limit,startId}],async()=>await _getUserWithdrawRequests(auth,{pendingOnly,limit,startId}),{
    enabled:!!auth.user&&!!auth.accessToken?.token,
  });
}
async function _getUserWithdrawRequests(auth:AuthContextType,{pendingOnly=true,limit=100,startId}:{pendingOnly?:boolean,limit?:number,startId?:number}={}):Promise<WithdrawalRequest[]> {
  if(!auth?.user) throw new Error('error.auth.notLoggedIn');
  const { data } = await get(`/api/user/funding/withdraws`, {limit,startId},auth);
  return (data?.requests||[]).filter((r:WithdrawalRequest)=>{
    if(pendingOnly){
      return [EWithdrawalRequestStatus.PENDING,EWithdrawalRequestStatus.PREEXECUTE,EWithdrawalRequestStatus.EXECUTING].includes(r.status);
    }
    return true;
  });
}

export function useUserDepositEvents(auth:AuthContextType,{pendingOnly=true,limit=100,startId}:{pendingOnly?:boolean,limit?:number,startId?:number}={}) {
  return useQuery(['user',{user:auth.user,type:'account',action:'listDepositEvents',pendingOnly,limit,startId}],async()=>await _getUserDepositEvents(auth,{pendingOnly,limit,startId}),{
    enabled:!!auth.user&&!!auth.accessToken?.token,
  });
}
async function _getUserDepositEvents(auth:AuthContextType,{pendingOnly=true,limit=100,startId}:{pendingOnly?:boolean,limit?:number,startId?:number}={}):Promise<ETHUserActionEvent[]> {
  if(!auth?.user) throw new Error('error.auth.notLoggedIn');
  const { data } = await get(`/api/user/funding/deposits`, {limit,startId},auth);
  return (data?.deposits||[]).filter((event:ETHUserActionEvent)=>{
    if(pendingOnly){
      return [EETHUserActionEventStatus.PENDING].includes(event.status);
    }
    return true;
  });
}

async function getUserWithdrawalRequestsByIds(auth:AuthContextType,requestIds:number[]):Promise<WithdrawalRequest[]> {
 if(requestIds.length<=0){
   throw new Error('Empty Request IDs');
 }
 const { data } = await get(`/api/user/funding/withdraw/status`, {requestIds}, auth);
 return data;
}
 
export function useChainTokens(auth:AuthContextType,tokens?:Token[],cb?:(chainTokens:TokenWithBalance[])=>void){
  return useQuery(['user',{user:auth.user,type:'account',action:'listChainTokens',tokensId:tokens?.map(t=>t.tokenId)}],async()=>await auth.wallet!.getBalance(tokens!),{
    enabled:!!(tokens&&auth.wallet),
    retry: 0,
    // retryDelay: 1000*60,
    onSuccess:(d)=>cb&&cb(d)
  });
}



/**
 * fetch order history for both market types
 * @param auth 
 * @param param1 
 * @returns 
 */
export function useUserAllRateOrders(auth:AuthContextType,{
  accountId, pending, cutoffFloatingOrderId, cutoffFixedOrderId, limit, disabled, isOrderDirectionAsc
}:{
  accountId?:number, pending?:boolean, cutoffFloatingOrderId?:number, cutoffFixedOrderId?:number, limit?:number, disabled?:boolean, isOrderDirectionAsc?: boolean
}={}) {
  return useQuery(['user',{type:'orders',action:'listAllOrders',user:auth.user,accountId, pending, cutoffFloatingOrderId, cutoffFixedOrderId, limit}],async()=>await _fetchUserAllRateOrders(auth,{accountId, pending, cutoffFloatingOrderId, cutoffFixedOrderId, limit, isOrderDirectionAsc}),{
    keepPreviousData: true,
    enabled:!disabled&&!!auth.user&&accountId!=undefined
  });
}
async function _fetchUserAllRateOrders(auth:AuthContextType,{
  accountId, pending, cutoffFloatingOrderId, cutoffFixedOrderId, limit=20, isOrderDirectionAsc = false
}:{
  accountId?:number, pending?:boolean, cutoffFloatingOrderId?:number, cutoffFixedOrderId?:number, limit?:number, isOrderDirectionAsc?: boolean
}) {
  const { data } = await get(`/api/user/rate/allOrders`,{
    accountId, pending, cutoffFloatingOrderId, cutoffFixedOrderId, limit:limit, asc: isOrderDirectionAsc
  },auth);
  return data as { orders:MixedRateOrder[], hasNextPage:boolean, cutoffFloatingOrderId?:number, cutoffFixedOrderId?:number };
};

/**
 * 
 * @param auth 
 * @param param1 
 * @returns 
 */
export function useUserMarkToMarketByToken(auth:AuthContextType,{
  accountIds=[]
}:{
  accountIds?:number[]
}={}) {
  const queries = accountIds.map(accountId=>({
    queryKey:['user',{type:'mtm',action:'listByToken',user:auth.user,accountId}],
    queryFn:async()=>await _fetchUserMarkToMarketByToken(auth,{accountId}),
    enabled:!!auth.user&&!!auth.accessToken?.token&&accountId!=undefined,
  }));
  return useQueries({queries}).reduce((ret,data,i)=>{
    if(data.data) ret.data = [...ret.data,...data.data];
    ret.isError = ret.isError||data.isError;
    ret.isLoading = ret.isLoading||data.isLoading;
    ret.isFetching = ret.isFetching||data.isFetching;
    return ret;
  },{data:new Array<UserMarkToMarket>(),isFetching:false,isLoading:false,isError:false});
}
async function _fetchUserMarkToMarketByToken(auth:AuthContextType,{
  accountId
}:{
  accountId?:number
}) {
  const { data } = await get(`/api/user/rate/mtm/token`,{
    accountId
  },auth);
  const { mtm } = data;
  return mtm as UserMarkToMarket[];
};
/**
 * 
 * @param auth 
 * @param param1 
 * @returns 
 */
export function useUserMarkToMarketByMarket(auth:AuthContextType,{
  accountIds=[]
}:{
  accountIds?:number[]
}={}) {
  const queries = accountIds.map(accountId=>({
    queryKey:['user',{type:'mtm',action:'listByMarket',user:auth.user,accountId}],
    queryFn:async()=>await _fetchUserMarkToMarketByMarket(auth,{accountId}),
    enabled:!!auth.user&&!!auth.accessToken?.token&&accountId!=undefined,
  }));
  return useQueries({queries}).reduce((ret,data,i)=>{
    if(data.data) ret.data = [...ret.data,...data.data];
    ret.isError = ret.isError||data.isError;
    ret.isLoading = ret.isLoading||data.isLoading;
    ret.isFetching = ret.isFetching||data.isFetching;
    return ret;
  },{data:new Array<UserMarkToMarket>(),isFetching:false,isLoading:false,isError:false});
}
async function _fetchUserMarkToMarketByMarket(auth:AuthContextType,{
  accountId
}:{
  accountId?:number
}) {
  const { data } = await get(`/api/user/rate/mtm/market`,{
    accountId
  },auth);
  const { mtm } = data;
  return mtm as UserMarkToMarket[];
};


/**
 * 
 * @param param0 limit
 * @returns MarketHisotryTVLItem[]
 */
export function useHistoricalUserCount({
  limit,
}:{
  limit?: number
}={}) {
  return useQuery(['user',{type:'count',action:'historical',limit}],async()=>await _fetchHistoricalUserCount({limit}));
}
async function _fetchHistoricalUserCount({
  limit,
}:{
  limit?: number
}) {
  const { data } = await get(`/api/p/user/historical/count`,{
    limit
  });
  return data.data as {date:number,userCount:number,activeUserCount:number,createDate:number}[];
};


/* mtm */
export function useUserMTMPerformanceByToken(auth:AuthContextType,{
  accountId, start=0, end, // start=0 for all history
}:{
  accountId?:number, start?:number, end?:number,
}={}) {
  return useQuery(['user',{user:auth.user,type:'mtmPerformance',action:'get',accountId, start, end}],async()=>await _fetchUserMTMPerformanceByToken(auth,{accountId, start, end}),{
    enabled:!!auth.user&&!!auth.accessToken?.token&&accountId!==undefined,
  });
}
async function _fetchUserMTMPerformanceByToken(auth:AuthContextType,{
  accountId, start, end,
}:{
  accountId?:number, start?:number, end?:number,
}={}) {
  return []; // TEMP API disabled
  const { data } = await get(`/api/user/account/mtm/performance/historical`,{accountId,start,end},auth);
  return zp(MTMPerformanceSchema,data.items);
}

/**
 * performance live 
 * should sync cache time with user.useUserAccounts, user.useUserAccountPerformanceLive, rate.useUserAllPositions
 */
export function useUserAccountPerformanceLive(auth:AuthContextType,{
  accountId
}:{
  accountId?:number
}={}) {
  return useQuery(['user',{user:auth.user,type:'accountPerformanceLive',action:'get',accountId}],async()=>await _fetchUserAccountPerformanceLive(auth,{accountId}),{
    enabled:!!auth.user&&!!auth.accessToken?.token&&accountId!==undefined,
  });
}
async function _fetchUserAccountPerformanceLive(auth:AuthContextType,{
  accountId,
}:{
  accountId?:number, 
}={}) {
  const { data } = await get(`/api/user/account/tokens/performance/live`,{accountId},auth)
  const { positionDetails=[] }:{positionDetails:AggregatedMarketPosition[]} = data.accountLivePerformance;
  const positionsByTokenId = new Map<number,AggregatedMarketPosition[]>();
  // add positions to map
  positionDetails.forEach(r=>{
    const position = zp<AggregatedMarketPosition>(AggregatedMarketPositionSchema,r);
    if(!position || position.accountId!==accountId) return;
    const list = positionsByTokenId.get(position.tokenId)||[];
    positionsByTokenId.set(position.tokenId,[...list,position]);
  });
  // calculate grouped row
  const groupedPositionDetails =  Array.from(positionsByTokenId.entries()).map(([tokenId,positions])=>{
    let quantity = new Decimal(0);
    let interest = new Decimal(0);
    let mtm = new Decimal(0);
    let pv = new Decimal(0);
    let dv01 = new Decimal(0);
    let price = "0";
    positions.forEach(position=>{
      quantity = quantity.add(new Decimal(position.quantity));
      if(position.interest!==undefined) interest = interest.add(new Decimal(position.interest));
      if(position.mtm!==undefined) mtm = mtm.add(new Decimal(position.mtm));
      if(position.pv!==undefined) pv = pv.add(new Decimal(position.pv));
      if(position.dv01!==undefined) dv01 = dv01.add(new Decimal(position.dv01));
      price = position.price;
    });
    return {
      tokenId,
      quantity:quantity.toString(),
      interest:interest.toString(),
      mtm:mtm.toString(),
      pv:pv.toString(),
      dv01:dv01.toString(),
      price,
      positions,
    } as GroupedPosition;
  });
  return zp(UserAccountPerformanceLiveSchema,{ ...data.accountLivePerformance, positionDetails: groupedPositionDetails });
}


export function useUserPNLHistorical(auth:AuthContextType,{
  accountId, start=0, end, // start=0 for all history
}:{
  accountId?:number, start?:number, end?:number,
}={}) {
  return useQuery(['user',{user:auth.user,type:'pnlHistorical',action:'get',accountId, start, end}],async()=>await _fetchUserPNLHistorical(auth,{accountId, start, end}),{
    enabled:!!auth.user&&!!auth.accessToken?.token&&accountId!==undefined,
  });
}
async function _fetchUserPNLHistorical(auth:AuthContextType,{
  accountId, start, end,
}:{
  accountId?:number, start?:number, end?:number,
}={}) {
  const { data } = await get(`/api/user/account/performance/historical`,{accountId,start,end},auth);
  return zp(PNLHistoricalSchema,data.items);
}


/* accountTokenPositions - fetch function called from useUserAccount only */
async function _fetchUserAccountTokenPositions(auth:AuthContextType,{ accountId }:{ accountId?:number }={}) {
  const { data } = await get(`/api/user/account/tokens/positions`,{accountId},auth);
  return zp(z.array(AccountTokenPositionSchema),data.accountTokenPositions);
}
/* Risk */
export function useUserAccountRisk(auth:AuthContextType,{ accountId }:{ accountId?:number }={}) {
  return useQuery(['user',{user:auth.user,type:'risk',action:'get',accountId}],async()=>await _fetchUserAccountRisk(auth,{accountId}),{
    enabled:!!auth.user&&!!auth.accessToken?.token&&accountId!==undefined,
  });
}
async function _fetchUserAccountRisk(auth:AuthContextType,{ accountId }:{ accountId?:number }={}) {
  const { data } = await get(`/api/user/account/risks`,{accountId},auth);
  return zp(AccountRiskMetricsSchema,data.accountRiskMetrics);
}
/* Account Token Impact */
export function useUserAccountTokensWithImpact(auth:AuthContextType,{ accountId }:{ accountId?:number }={}) {
  return useQuery(['user',{user:auth.user,type:'accountTokenImpact',action:'get',accountId}],async()=>await _fetchUserAccountTokensWithImpact(auth,{accountId}),{
    enabled:!!auth.user&&!!auth.accessToken?.token&&accountId!==undefined,
  });
}
async function _fetchUserAccountTokensWithImpact(auth:AuthContextType,{ accountId }:{ accountId?:number }={}) {
  const { data } = await get(`/api/user/account/tokens/impact`,{accountId},auth);
  return zp(z.array(AccountTokenWithImpactSchema),data.accountTokens);
}
/* Account Positions Impact */
export function useUserPositionsWithImpact(auth:AuthContextType,{ accountId }:{ accountId?:number }={}) {
  return useQuery(['user',{user:auth.user,type:'accountPositionImpact',action:'get',accountId}],async()=>await _fetchUserPositionsWithImpact(auth,{accountId}),{
    enabled:!!auth.user&&!!auth.accessToken?.token&&accountId!==undefined,
  });
}
async function _fetchUserPositionsWithImpact(auth:AuthContextType,{ accountId }:{ accountId?:number }={}) {
  const { data } = await get(`/api/user/account/positions/impact`,{accountId},auth);
  return {
    accountPositionsOrderByImpact: zp(z.array(AccountPositionsOrderByImpactSchema),data.accountPositionsOrderByImpact),
    accountPositionsOrderByImpactPercentage: zp(z.array(AccountPositionsOrderByImpactSchema),data.accountPositionsOrderByImpactPercentage),
  } 
}