import { Box, Button, FormControl, InputLabel, Table, TableBody, TableCell, TableRow, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useQueryClient } from "@tanstack/react-query";
import Decimal from 'decimal.js';
// DEV @mui/x-date-pickers seems to be causing issue with other packages
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { isEqual } from 'lodash';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ComponentLoadingIcon from 'src/Components/ComponentLoadingIcon';
import withConnectWallet from 'src/Components/WithConnectWallet';
import { MAX_RATE, MAX_BORROW_SLIPPAGE } from 'src/constants/app';
import { generateClientOrderId, sortBy, usePrevious } from 'src/utils/common';
import { useAuth } from '../AuthProvider';
import useAppStore from '../store';
import { BootstrapMenuItem, BootstrapSelect, styleFormButton, styleFormCell, sxCompactFormTable, sxInputLabel, sxSelectMenu } from '../styles/Form';
import { BaseMarket, ConfirmationDialog, EOrderSide, ERateOrderType, ETradeType, MarketForSymbol, MarketInfo, RateOrderBook, SelectedOrderBookEntry, Token, TradeParams } from '../types';
import apis from '../utils/apis';
import MarketSwitcher from './MarketSwitcher';
import RateInput from './RateInput';
import TokenAmountInput from './TokenAmountInput';
import TradeDialog from './TradeConfirmationDialog';
import AccountInfo from './AccountInfo';
import { paths } from 'src/utils/paths';

interface MarketTradeFormProps {
  token:Token,marketId?:number,selectedOrderSide?:EOrderSide,selectedOrderBookEntry?:SelectedOrderBookEntry,onSelectOrderBookEntry?:(orderBookEntry?:SelectedOrderBookEntry)=>any,
  marketOrdersOnly?:boolean,hideMaturityOptions?:boolean,
  type?:'full'|'float'|'compact',
  onMarketSwitcherSelectPath?: (market:MarketForSymbol)=>string
};
interface InnerMarketTradeFormProps extends MarketTradeFormProps {
  marketInfo?:MarketInfo, orderBook?:RateOrderBook, market?:BaseMarket,
  isLoadingMarketInfo?:boolean, onMarketSwitcherSelectPath?: (market:MarketForSymbol)=>string
}

const MemoInnerMarketTradeForm = memo(InnerMarketTradeForm);

function MarketTradeForm(props:MarketTradeFormProps){
  const { marketId } = props;
  const {data:market} = apis.rate.useMarket({marketId});
  const {isLoading: isLoadingMarketInfo, data:marketInfo} = apis.rate.useMarketInfo({market});
  const {data:orderBook} = apis.rate.useRateOrderBook(market);
  return <MemoInnerMarketTradeForm 
    {...props} 
    marketInfo={marketInfo} orderBook={orderBook} market={market}
    isLoadingMarketInfo={isLoadingMarketInfo}
  />;
}

function InnerMarketTradeForm({
  token,marketId,selectedOrderSide,selectedOrderBookEntry,onSelectOrderBookEntry,
  marketOrdersOnly=false,hideMaturityOptions=false,
  marketInfo, orderBook, market,
  isLoadingMarketInfo,
  onMarketSwitcherSelectPath
}:InnerMarketTradeFormProps){
  /**
   * general hooks
   */
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { currentUserAccountId } = useAppStore();
  
  /**
   *  single state for multiple field simultaneous update 
   * */
  const [formState,setFormState] = useState<{
    orderSide: EOrderSide,
    orderType: ERateOrderType,
    quantity: string,
    rate: string|undefined,
    maxSlippage: string|undefined,
  }>({
    orderSide: selectedOrderSide!==undefined?selectedOrderSide:EOrderSide.LEND,
    orderType: ERateOrderType.MARKET,
    quantity: '',
    rate: undefined,
    maxSlippage: undefined,
  });
  const {
    orderSide,
    orderType,
    quantity,
    rate,
    maxSlippage,
  } = formState;
  
  /**
   * states
   */
  const confirmationDialogRef = useRef<ConfirmationDialog | null>(null)
  const [isPostingBorrowOrder,setIsPostingBorrowOrder] = useState(false);
  const [isPostingLendOrder,setIsPostingLendOrder] = useState(false);
  const isPostingAnyOrder = isPostingBorrowOrder||isPostingLendOrder;
  
  /**
   * api data
   */
  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  const tradeAccount = userAccounts&&apis.user.extractUserAccount(userAccounts,currentUserAccountId);
  const tradeTokenAccount = market&&tradeAccount&&apis.user.extractAccountTokenAccountFromAccount(tradeAccount,market.tokenId);
   
  /**
   * price calculation
  */
  const maxQuantity = (market&&token&&tradeAccount&&(orderSide===EOrderSide.BORROW?
    // borrow maxQuantity = healthscore max borrow in usd / price
    new Decimal(tradeAccount.maxBorrowInUsd||0).div(new Decimal(token.price||0)):
    // lend maxQuantity = trading account availableQuantity
    tradeTokenAccount?.availableQuantity&&new Decimal(tradeTokenAccount?.availableQuantity).sub(new Decimal(tradeTokenAccount?.availableQuantity).mod(new Decimal(market.quantityStep)))
  ))||new Decimal(0);
  const quantityStep = market?.quantityStep||'0'; 
  const decimals = new Decimal(quantityStep).decimalPlaces();
  // console.log('step',step,decimals.toString());
  const quantityObject = new Decimal(quantity||0);
  const isInvalidInput = quantityObject.gt(maxQuantity)||quantityObject.lte(0);
  // console.log(`orderBook askMinRate: ${askMinRate?.toString()}, bidMaxRate: ${bidMaxRate?.toString()}`);
  // orderRateDecimal: the onbook rate of the market - as a function for state change calculation
  const getOrderRateDecimal = useCallback((currentOrderType = orderType, currentOrderSide = orderSide)=>{
    // use next nearest appropriate rate in the orderbook if placing market order 
    const marketRateDecimal = new Decimal((marketInfo?.marketId===marketId&&(marketInfo?.midRate||marketInfo?.rate||market?.rate))||0);
    const {askMinRate,bidMaxRate} = orderBook||{};
    // orderRateDecimal: the onbook rate of the market
    const orderRateDecimal = (currentOrderType===ERateOrderType.MARKET&&askMinRate&&bidMaxRate&&new Decimal(currentOrderSide===EOrderSide.BORROW?askMinRate:bidMaxRate))||marketRateDecimal;
    return orderRateDecimal;
  },[marketId,marketInfo,orderBook,orderType,orderSide,market?.rate]);

  // orderRateDecimal: the onbook rate of the market
  const orderRateDecimal = getOrderRateDecimal();
  // max/min rates from onbook rate
  const rateMinDecimal = orderType===ERateOrderType.MARKET&&orderSide===EOrderSide.BORROW?orderRateDecimal:new Decimal(0);
  const rateMaxDecimal = orderType===ERateOrderType.MARKET&&orderSide===EOrderSide.LEND?orderRateDecimal:new Decimal(10);
  // rateDecimal: the rate used for the form
  const rateDecimal = Decimal.min(rateMaxDecimal,Decimal.max(rateMinDecimal,new Decimal(rate||0))).toDP(decimals*100);
  const maxSlippageDecimal = new Decimal(maxSlippage||0);
  // adjustedRateDecimal: form rate with slippage adjusted
  const adjustedRateDecimal = rateDecimal.add(maxSlippageDecimal.mul(orderSide===EOrderSide.BORROW?1:-1)).toDP(decimals*100);
  // targetRate & estimatedSlippage: interate orderbook to find the estimated slippage rate (absolute)
  const quantityDecimal = new Decimal(quantity||0);
  const {targetRate} = (orderType===ERateOrderType.MARKET&&orderBook&&(
    orderSide===EOrderSide.BORROW?sortBy(orderBook.asks,'rate'):sortBy(orderBook.bids,'rate',true)
  ).reduce(
    ({depth,targetRate},entry)=>{
      if(quantityDecimal.gt(depth)){
        targetRate = new Decimal(entry.rate);
      }
      depth = depth.add(new Decimal(entry.quantity||0));
      return {depth,targetRate};
    }
  ,{depth:new Decimal(0),targetRate:undefined} as {depth:Decimal,targetRate:Decimal|undefined}))||{};

  const maxSlippageRate = orderSide===EOrderSide.LEND ? rateDecimal.minus(maxSlippageDecimal) : rateDecimal.plus(maxSlippageDecimal)
  const { avgRate, fillAmount} = (orderType===ERateOrderType.MARKET&&orderBook&&(
    orderSide===EOrderSide.BORROW?sortBy(orderBook.asks,'rate'):sortBy(orderBook.bids,'rate',true)
  ).reduce(
    ({ avgRate, fillAmount, rateCount}, entry) => {
    const depth = new Decimal((orderSide === EOrderSide.LEND ? entry.borrowDepth : entry.lendDepth) ?? 0)
    if (orderSide === EOrderSide.LEND ? maxSlippageRate.lte(entry.rate) : maxSlippageRate.gte(entry.rate)) {
      if (depth.lte(quantityDecimal)) {
        return {
          avgRate,
          fillAmount: depth,
          rateCount: rateCount.plus(new Decimal(entry.quantity).mul(entry.rate))
        }
      } else if (avgRate.eq(0)){
        const finalRateCount = quantityDecimal.eq(0) ? quantityDecimal : rateCount.plus(quantityDecimal.minus(fillAmount).mul(entry.rate))
        return {
          avgRate: quantityDecimal.eq(0) ? avgRate : finalRateCount.dividedBy(quantityDecimal),
          fillAmount: quantityDecimal,
          rateCount: finalRateCount
        }
      } else {
        return { avgRate, fillAmount, rateCount}
      }
    } else {
      return { avgRate: avgRate.eq(0) ? (fillAmount.eq(0) ? fillAmount : rateCount.dividedBy(fillAmount)) : avgRate, fillAmount, rateCount}
    }
  }, {avgRate: new Decimal(0), fillAmount: new Decimal(0), rateCount: new Decimal(0)}))||{};

  const rateMin = rateMinDecimal.toString();
  const rateMax = rateMaxDecimal.toString();
  
  /**
   * state change: on market change - rate is calculated with marketInfo.rate (and orderBook if market order)
  */
  const prevMarketId = usePrevious(marketId);
  const prevMarketInfo = usePrevious(marketInfo);
  const prevOrderBook = usePrevious(orderBook);
  useEffect(()=>{
    const newState:Partial<typeof formState> = {};
    if(marketId&&marketId!==prevMarketId){
      // on market change - reset rate and slippage, and props.selectedRate
      // console.log('useEffect market change');
      onSelectOrderBookEntry && onSelectOrderBookEntry(undefined);
      newState.rate = undefined;
      newState.maxSlippage = undefined;
    }
    if(marketInfo&&marketInfo.marketId===marketId){
      // check marketInfo.marketId for API delay
      if(rate===undefined||Object.hasOwn(newState,'rate')){
        // only update form rate from marketInfo if it's not set, or unset from market change
        // console.log('useEffect marketInfo change');
        const orderRate = getOrderRateDecimal().toString();
        // console.log('useEffect marketInfo changed',orderRateDecimal.toString());
        if(rate!==orderRate){
          newState.rate = orderRate;
        }
        if(!newState.maxSlippage){
          newState.maxSlippage = orderType===ERateOrderType.MARKET?`${Number(market?.rateStep||0)*10||0.1}`:undefined;
        }
      }
    }
    if(Object.keys(newState).length>0){
      setFormState(formState=>({...formState,...newState}));
    }
  },[
    marketId,prevMarketId,onSelectOrderBookEntry,marketInfo,prevMarketInfo,orderBook,prevOrderBook,
    rate,getOrderRateDecimal,orderType,market?.rateStep,
  ]);

  useEffect(() => {
    if (orderBook && orderBook?.asks?.length === 0 && orderBook?.bids?.length === 0) {
      setFormState(formState => ({ ...formState, orderType: ERateOrderType.LIMIT }))
    }
  }, [orderBook])
  /**
   * state change: from props.selectedRate
   */
  const prevSelectedOrderBookEntry = usePrevious(selectedOrderBookEntry);
  const prevSelectedOrderSide = usePrevious(selectedOrderSide);
  const prevOrderType = usePrevious(orderType);
  useEffect(()=>{
    if(selectedOrderBookEntry && !isEqual(selectedOrderBookEntry, prevSelectedOrderBookEntry)) {
      // on prop.selectedOrderBookEntry change - update form data if necessary
      const newState:Partial<typeof formState> = {};
      switch(selectedOrderBookEntry.column!) {
        case 'lendDepth':
        case 'borrowDepth':
          newState.orderSide = selectedOrderBookEntry.side === 'lend' ? EOrderSide.LEND : (selectedOrderBookEntry.side === 'borrow' ? EOrderSide.BORROW : orderSide);
          const orderRateDecimal = getOrderRateDecimal(ERateOrderType.MARKET, newState.orderSide);
          const _maxSlippage = new Decimal(selectedOrderBookEntry?.rate||0).minus(orderRateDecimal).abs().toString();
          if(maxSlippage){
            if(maxSlippage !== _maxSlippage){
              newState.rate = orderRateDecimal.toString();
              newState.maxSlippage = _maxSlippage;
            }
          }else{
            newState.maxSlippage = `${Number(market?.rateStep||0)*10||0.1}`;
          }
          newState.quantity = selectedOrderBookEntry.size!.toFixed(decimals)
          newState.orderType = ERateOrderType.MARKET
          break
        case 'rate':
          if(selectedOrderBookEntry.rate !== rate) {
            newState.orderType = ERateOrderType.LIMIT
            newState.rate = selectedOrderBookEntry.rate;
            newState.maxSlippage = undefined;
          }
          break
      }
      
      setFormState(formState=>({...formState,...newState}));
    }
  },[selectedOrderBookEntry,prevSelectedOrderBookEntry,getOrderRateDecimal,market?.rate,market?.rateStep,marketInfo,orderBook,maxSlippage,orderSide,orderType,rate, decimals]);
  /**
   * state change: on order side or market type change
   */
  useEffect(()=>{
    if(selectedOrderSide&&selectedOrderSide!==prevSelectedOrderSide&&selectedOrderSide!==orderSide){
      const maxSlippage = `${Number(market?.rateStep||0)*10||0.1}`;
      setFormState(formState=>({...formState, orderSide:selectedOrderSide,maxSlippage:maxSlippage}));
    }
  },[selectedOrderSide,prevSelectedOrderSide,orderSide,market?.rateStep]);
  useEffect(()=>{
    if(orderType!==prevOrderType){
      setFormState(formState=>({...formState, maxSlippage:orderType===ERateOrderType.MARKET?`${Number(market?.rateStep||0)*10||0.1}`:undefined}));
    }
  },[orderType,prevOrderType,market?.rateStep]);
  
  /**
   * form UI related variables
   */

  const actionLabel = (orderSide===EOrderSide.LEND?'Lend':'Borrow')+(isPostingAnyOrder?'ing...':'');

  /**
   * functions
   */
  const handleSuccessOrder = () => {
    // reset form value
    setFormState({...formState, quantity:''});
    queryClient.invalidateQueries({queryKey:['user']});
    queryClient.invalidateQueries({queryKey:['market']});
  };

  const handleClickOrder = ()=>{
    if(!market||!tradeAccount
      ||!quantity
      ||!rate||new Decimal(rate)?.lt(0)
    ) return;
    const tradeParams: TradeParams = {
      primaryLeg: {
        marketId: market.marketId,
        accountId: tradeAccount.accountId,
        side: orderSide, //  1 for borrow, 0 for lend
        orderType: orderType, //  1 for market order, 2 for limit order
        quantity: quantity, 
        adjustedRate: adjustedRateDecimal,
        maxSlippage: maxSlippageDecimal,
        orderRate: orderRateDecimal,
        targetRate: targetRate!,
        estimatedSlippage: rateDecimal.minus(avgRate ?? 0).abs(),
        estimatedFillAmount: fillAmount,
        clientOrderId: generateClientOrderId(),
      }
    }
    confirmationDialogRef?.current?.openDialog({ tradeType: ETradeType.SINGLE, tradeParams, primaryToken: token, primaryMarket: market})
  }

  const isOrderBookEmpty = orderBook && orderBook?.asks?.length === 0 && orderBook?.bids?.length === 0;
  const fundError = (auth?.user && market && token && tradeAccount && ( maxQuantity.lte(0) || (quantity && maxQuantity.lt(quantity)))) ? <>Insufficient funds. <Link to={paths.portfolioPage('accounts')}>Transfer here</Link></> : ''
  
  /**
   * renders
   */
    return (<>
      <ComponentLoadingIcon isLoading={isLoadingMarketInfo || marketInfo === null} curtain/>
      <Table sx={sxCompactFormTable}><TableBody>
        <TableRow><TableCell>
          <ToggleButtonGroup
            color="primary"
            value={orderSide}
            exclusive
            onChange={(event,value)=>value!==null&&setFormState({...formState, orderSide:(value as unknown as EOrderSide)})}
            aria-label="order-direction-input"
            disabled={isPostingAnyOrder}
          >
            <ToggleButton value={EOrderSide.LEND}>Lend</ToggleButton>
            <ToggleButton value={EOrderSide.BORROW}>Borrow</ToggleButton>
          </ToggleButtonGroup>
        </TableCell></TableRow>
        {!marketOrdersOnly&&<TableRow><TableCell>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="orderType-select" sx={sxInputLabel}>Order Type</InputLabel>
            <BootstrapSelect MenuProps={{sx:sxSelectMenu}}
              id="orderType-select"
              value={orderType}
              onChange={(event)=>{
                setFormState({...formState, orderType:(parseInt(`${event.target.value}`) as ERateOrderType)})
              }}
              disabled={isPostingAnyOrder}
            > 
              <BootstrapMenuItem disabled={isOrderBookEmpty} value={ERateOrderType.MARKET}>Market</BootstrapMenuItem>
              <BootstrapMenuItem value={ERateOrderType.LIMIT}>Limit</BootstrapMenuItem>
            </BootstrapSelect>
          </FormControl>
        </TableCell></TableRow>}
        {!hideMaturityOptions&&<TableRow><TableCell>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="market-select" sx={sxInputLabel}>Maturity</InputLabel>
            <MarketSwitcher layoutStyle="select" token={token} onSelectPath={onMarketSwitcherSelectPath}/>
          </FormControl>
        </TableCell></TableRow>}
        <TableRow><TableCell sx={{ flexDirection: 'row'}}>
          <TokenAmountInput error={fundError} name='quantity' fullWidth
            label='Size'
            sx={styleFormCell} suffix={token?.code} bottomLabel={<>(Max <AccountInfo token={token} side={orderSide} type='amountOnly'/>)</>}
            readOnly={isPostingAnyOrder} 
            step={quantityStep} decimals={decimals} min={market?.minQuantity} max={maxQuantity.toString()}
            amount={quantity} onAmountChange={(quantity=>setFormState({...formState, quantity:quantity}))} allowsOutOfRange={true}
            placeholder={new Decimal(0).toFixed(decimals)}
          />
        </TableCell></TableRow>
        <TableRow><TableCell>
        <Box sx={{display:'grid',gridAutoColumns:'1fr',gridAutoFlow:'column',columnGap:'8px'}}>
          <RateInput name='rate' label='Rate' sx={styleFormCell} fullWidth
            rate={rateDecimal?.toString()||'0'} step={market?.rateStep} min={rateMin} max={rateMax}
            readOnly={isPostingAnyOrder} disabled={orderType!==ERateOrderType.LIMIT}
            onRateChange={(rate)=>setFormState({...formState, rate:rate})}
          />
          {orderType===ERateOrderType.MARKET&&<RateInput name='maxSlippage' label='Max Slippage' sx={styleFormCell} fullWidth
            rate={maxSlippageDecimal?.toString()||'0'} step={market?.rateStep} readOnly={isPostingAnyOrder} 
            onRateChange={(maxSlippage)=>setFormState({...formState, maxSlippage:maxSlippage})} min={quantityStep} max={orderSide === EOrderSide.BORROW ? rateMax : (rateDecimal?.toString() || '0')}
          />}
        </Box>
        </TableCell></TableRow>
        <TableRow><TableCell>
          <Button sx={styleFormButton} variant='contained'
            onClick={handleClickOrder} 
            disabled={(!market||!tradeAccount||isPostingAnyOrder||isInvalidInput)}>{actionLabel}</Button>
        </TableCell></TableRow>
      </TableBody></Table>
      <TradeDialog ref={confirmationDialogRef} onSuccess={handleSuccessOrder}/>
    </>);
}

export default withConnectWallet(MarketTradeForm);
