import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingIcon from 'src/Components/Loading';
import ComponentLoadingIcon from 'src/Components/ComponentLoadingIcon';
import MarketGrid from 'src/Components/MarketGrid';
import apis from 'src/utils/apis';
import { usePageTitle } from 'src/utils/common';
import { paths } from 'src/utils/paths';

function TradeDashboard(){
  const navigate = useNavigate();
  const { tokenId, marketId, marketCode } = useParams();
  const {isFetching:isFetchingMarkets,data:markets} = apis.rate.useMarkets();
  usePageTitle([marketCode||'','Trade'],[marketCode||'']);
  const tradePage = paths.useCallbackTradePage();
  useEffect(()=>{
    if(!isFetchingMarkets){
      if(!tokenId||!marketId){
        if(markets){
          const market = markets.at(0);
          if(market){
            navigate(tradePage(market));
            return;
          }
        }
        navigate('/404');
      } else {
        const market = markets?.find(m=>m.marketId===Number(marketId));
        if (!market) {
          navigate(`/unknown-market/${tokenId}`);
        }
      }
    }
  },[tokenId,marketId,isFetchingMarkets,markets,navigate,tradePage]);
  // return loading icon only - let useEffect hook deal with redirecting
  return (
    <>
      <ComponentLoadingIcon isLoading={isFetchingMarkets || (!markets && !tokenId && !marketId)} curtain/>
      <MarketGrid tokenId={Number(tokenId)} marketId={Number(marketId)}/>
    </>
  )
}

export default TradeDashboard;