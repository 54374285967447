import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import Decimal from 'decimal.js';
import Lottie from 'lottie-react';
import { messages } from 'src/utils/messages';
import { format } from 'src/utils/numbers';
import { Token, TokenWithBalance, TransferType, TransferData } from '../types';
import { LoadingText } from './Loading';
import StandardDialog from './StandardDialog';
import TokenIcon from './TokenIcon';
import {
  styleDialogButtonOutlined,
  sxActionButton, sxActionButtonFirst,
  sxConfirmDialogActionButtonContainer,
  sxConfirmationDialog,
  sxDialogCell, sxDialogCellValue, sxOrderStatusSuccessTitle, sxStatusTitleLabel, sxErrorStatusMain, sxSupport
} from 'src/styles/Dialog';
import { styleFormButton } from 'src/styles/Form';
import successAnimationData from 'src/Components/Icons/animated/success.json';
import errorAnimationData from 'src/Components/Icons/animated/error.json';
import { StylePrimaryButton } from '../styles/General';

const sxInfoBox:SxProps = {
  height: 'auto',
  width: '100%',
  position: 'relative',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'border.dark',
  borderRadius: '4px',
  backgroundColor: 'table.main',
  whiteSpace: 'normal',
  px: '20px',
  py: '12px',
  marginTop: '24px',
  fontSize: '0.75rem',
  fontWeight: '400',
  "> p": {
    my: '0.5rem',
  },
  "> ol":{
    paddingLeft: '1.5rem',
  }
};

export const sxSummaryCell:SxProps = {
  '&.MuiTableCell-root': {
    padding: '0',
    borderBottomWidth: '0',
    paddingTop: '20px',
    fontWeight: '700'
  }
};

const sxNoteContent: SxProps = {
  textAlign: 'center',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  color: 'note.main'
}

const sxTransferSummaryText: SxProps = { 
  marginTop: '4px',
  fontSize: '1.125rem',
  fontWeight: '400',
  marginBottom: '0'
}

const sxTransferResultStatusBox: SxProps = {
  // marginBottom: '36px',
  marginTop: 'auto',
  marginBottom: 'auto',
  py: '26px',
  height: '466px',
  display: 'flex',
}

const styleLottie = {
  height:55,
  width:55,
  margin:'0 1rem'
}

const sxConfirmSummary: SxProps = {
  marginTop: '4px',
  fontSize: '1rem',
  fontWeight: '400'
}

const successDefaultOptions = {
  loop: false,
  autoplay: true,
  animationData: successAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const errorDefaultOptions = {
  loop: false,
  autoplay: true,
  animationData: errorAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const TEXT_MAPPING = {
  [TransferType.TRANSFER_COMPLETE]: {
    label: messages.TRANSFER_CHAIN_COMPLETE_LABEL,
    note: ''
  },
  [TransferType.TRANSFER_CHAIN_COMPLETE]: {
    label: messages.TRANSFER_CHAIN_COMPLETE_LABEL,
    note: messages.TRANSFER_CHAIN_COMPLETE_NOTE
  },
  [TransferType.TRANSFER_CHAIN_FAUCET_COMPLETE]: {
    label: messages.TRANSFER_COMPLETE,
    note: ''
  },
  [TransferType.TRANSFER_WITHDRAWL]: {
    label: messages.TRANSFER_WITHDRAWL_LABEL,
    note: messages.TRANSFER_WITHDRAWL_NOTE
  },
  [TransferType.TRANSFER_ERROR]: {
    label: messages.TRANSFER_ERROR_LABEL,
    note: messages.TRANSFER_ERROR_NOTE
  }
}

const defaultTransferData = { 
  transferAmount: new Decimal(0),
  tokenCode: '',
  from: '',
  to: ''
}

const getAccountPrefix = (account: string) => {
  if (account.startsWith('Your')) { 
    return ''
  } else if (account.includes('Faucet')) {
    return 'the'
  } else {
    return 'your'
  }
}

const confirmationDialogContent = ({ from, to, token, transferAmount, transferFee, chainTransfer, faucetTransfer }: { token:Token|TokenWithBalance, transferAmount:Decimal, transferFee:Decimal, from:string, to:string, chainTransfer?:boolean, faucetTransfer?:boolean }) => {
  return (
  // FIXME: cannot select erc721 token inside infinity on chain, have to get token info from account wallet token
  <TableContainer sx={{ padding: '10px 28px 16px 28px' }} component={Box}>
    <Table>
      <TableBody>
        <TableRow>
          <TableCell  sx={sxDialogCell}><label>From</label></TableCell>
          <TableCell sx={sxDialogCellValue}>{from}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={sxDialogCell}><label>To</label></TableCell>
          <TableCell sx={sxDialogCellValue}>{to}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={sxDialogCell}><label>Token</label></TableCell>
          <TableCell sx={sxDialogCellValue}><TokenIcon token={token} size={20} withCode/></TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={sxDialogCell}><label>Transfer Amount</label></TableCell>
          <TableCell sx={sxDialogCellValue}>{format(transferAmount)}</TableCell>
        </TableRow>
        {transferFee.gt(0)&&(<TableRow>
          <TableCell  sx={sxDialogCell}><label>Transfer Fee</label></TableCell>
          <TableCell sx={sxDialogCellValue}>{format(transferFee)}</TableCell>
        </TableRow>)}
        <TableRow>
          <TableCell colSpan={2} sx={sxSummaryCell}>
            <Box><label>Summary</label></Box>
            <Box component={'p'} sx={sxConfirmSummary}>Transfer <b>{format(transferAmount)} {token.code}</b> from {from} to {to}</Box>
            {(chainTransfer||faucetTransfer)&&(<Box sx={sxInfoBox}>
              <p><label>NOTE</label></p>
              {faucetTransfer?messages.TRANSFER_FROM_FAUCET_HINT:(token?.nativeToken?messages.TRANSFER_FROM_CHAIN_TO_INFINITY_NATIVE_HINT:messages.TRANSFER_FROM_CHAIN_TO_INFINITY_HINT)}
            </Box>)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
)}

const transferResultDialogContent = ({ transferEndType, lastTransferData = defaultTransferData }: { transferEndType: TransferType, lastTransferData?: TransferData}) => {
  const { label, note } = TEXT_MAPPING[transferEndType]
  const { transferAmount, tokenCode, from, to } = lastTransferData
  return (
  <Box sx={sxTransferResultStatusBox}>
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Box sx={{...sxOrderStatusSuccessTitle, flexDirection: 'column', marginTop: '60px' }}>
        {transferEndType !== TransferType.TRANSFER_ERROR ? <Lottie {...successDefaultOptions} style={styleLottie}/> : <Lottie {...errorDefaultOptions} style={styleLottie} />}
        <Box component={'span'} sx={{...sxStatusTitleLabel, marginTop: '8px' }}>{label}</Box>
      </Box>
      {transferEndType !== TransferType.TRANSFER_ERROR && <Box sx={sxErrorStatusMain}>
        <Box component={'p'} sx={sxTransferSummaryText}>Transferred {format(transferAmount)} {tokenCode} from {getAccountPrefix(from)} {from} to {getAccountPrefix(to)} {to}</Box>
      </Box>}
      {note && <Box sx={sxNoteContent}>
        Note: {note}
      </Box>}
    </Box>
  </Box>)
}

function TokenTransferDialog({
  token, transferAmount, transferFee, from, to, transferEndType, chainTransfer, faucetTransfer, 
  isTransferring, open, handleClose, handleClickConfirmTransfer, lastTransferData
}:{
  token:Token|TokenWithBalance, transferAmount:Decimal, transferFee:Decimal, from:string, to:string, transferEndType?:TransferType, chainTransfer?:boolean, faucetTransfer?:boolean,
  isTransferring:boolean, open:boolean, handleClose:()=>void, handleClickConfirmTransfer:()=>void, lastTransferData?: TransferData
}){
  return (
    <StandardDialog
      sxCustomDialog={sxConfirmationDialog}
      title={transferEndType ? null : `Token Transfer`}
      content={transferEndType ? transferResultDialogContent({ transferEndType, lastTransferData }) : confirmationDialogContent({token, transferAmount, transferFee, from, to, chainTransfer, faucetTransfer})}
      actions={<>
        <Box sx={sxConfirmDialogActionButtonContainer}>
          {!transferEndType&&<Button variant="contained" sx={{ ...styleFormButton, ...sxActionButtonFirst } as SxProps} onClick={()=>handleClickConfirmTransfer()} disabled={isTransferring}>
            {(chainTransfer||faucetTransfer)?
              (isTransferring ? <LoadingText text='Awaiting Chain Confirmation' textColor={'rgb(16, 36, 62)'}/> : 'Confirm Transfer'):
              (isTransferring ? <LoadingText text='Transferring' textColor={'rgb(16, 36, 62)'}/> : 'Confirm Transfer')
            }
          </Button>}
          {transferEndType&&<Button variant="contained" sx={{ ...StylePrimaryButton, ...sxActionButton } as SxProps} onClick={()=>handleClose()}>Close</Button>}
          {!transferEndType&&!isTransferring&&<Button variant="outlined" sx={{ ...styleDialogButtonOutlined, ...sxActionButton } as SxProps} onClick={()=>handleClose()}>Cancel</Button>}
        </Box>
      </>}
      open={open}
      handleClose={transferEndType ? undefined : handleClose}
      disableBackdropClick
    />
  );
}

export default TokenTransferDialog;
