import {
  Box, Menu, MenuItem
} from '@mui/material';
import { SxProps } from '@mui/system';
import { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import { useNavigate } from 'react-router-dom';
import { HEADER_HEIGHT } from 'src/Components/Header';
import { FOOTER_HEIGHT } from 'src/Components/Footer';
import { GraphPositionProvider } from 'src/Components/MarketGrids/GraphPositionProvider';
import TokenMarketSwitcherPanel from 'src/Components/TokenMarketSwitcherPanel';
import { MARKET_GRID_VERSION } from 'src/constants/app';
import useAppStore from 'src/store';
import { MarketForSymbol, SelectedOrderBookEntry } from 'src/types';
import apis from 'src/utils/apis';
import { paths } from 'src/utils/paths';
import theme from 'src/utils/theme';
import LoadingIcon from './Loading';
import { GridsType, getGridsConfigs, gridBreakpoints, gridColumns, leftPanelWidth } from './MarketGrids';

const ResponsiveGridLayout = WidthProvider(Responsive);

const styleGrid:SxProps = {
  display: 'grid',
  p: 0,
  gridTemplateColumns: `${leftPanelWidth}px 1fr`,
  gridTemplateRows: '1fr',
  gridAutoRows: '1fr',
  gridTemplateAreas: `
    "panel main"`,
  transition: 'grid-template-columns 0.5s ease',
  [theme.breakpoints.down('desktopWider')]:{
    gridTemplateColumns: `0 1fr`,
  },
};
const styleLeftPanel:SxProps = {
  gridArea: "panel",
  overflow: "auto",
  position: "fixed",
  height: `calc(100% - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})`,
  width: `${leftPanelWidth}px`,
  transition: 'width 0.5s ease',
  [theme.breakpoints.down('desktopWider')]:{
    overflow: "hidden",
    width: `0px`,
  },
};
const styleGridContainer:SxProps = {
  p: '6px', // react-grid-layout has built in translate(10px);
  gridArea: "main",
};
const styleGridBox:SxProps = {
  borderRadius: '4px',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'border.dark',
  bgcolor: 'background.paper',
  overflow: 'hidden',
};

function MarketGrid({tokenId,marketId}:{tokenId:number,marketId:number}){
  const {isInitialLoading,isFetching,data:markets} = apis.rate.useMarkets({tokenId});
  const market = markets?.find(m=>m.marketId===marketId);
  const token = market?.token;
  const tradePage = paths.useCallbackTradePage();
  // rate
  const [ selectedOrderBookEntry, setSelectedOrderBookEntry ] = useState<SelectedOrderBookEntry|undefined>();

  /* grid layout storage menu */
  const {set,tradeMarketGridLayouts} = useAppStore();
  const onLayoutChange = useCallback((currentLayout: Layout[], allLayouts: ReactGridLayout.Layouts)=>{
    set('tradeMarketGridLayouts',{version:MARKET_GRID_VERSION,layouts:allLayouts});
  },[set]);
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const onContextMenu:MouseEventHandler<HTMLDivElement> = (event)=>{
    if((event.target as Element).classList.contains('marketgridHandle')){
      event.preventDefault();
      setContextMenu( contextMenu === null ? { mouseX: event.clientX + 2, mouseY: event.clientY - 6, } : null );
    }
  };
  const handleCloseContextMenu = ()=>{ setContextMenu(null); };
  /**
   * callbacks
   */
  const onMarketSwitcherSelectPath = useCallback((market:MarketForSymbol)=>tradePage(market),[tradePage]);
  
  const [ gridsType, setGridsType ] = useState<GridsType>('Default');
  const { layouts,componentKeys,GridComponentMap } = getGridsConfigs(gridsType);
  let loadedLayouts = layouts;
  if(tradeMarketGridLayouts?.version===MARKET_GRID_VERSION&&tradeMarketGridLayouts?.layouts){
    loadedLayouts = tradeMarketGridLayouts.layouts;
  }
  if (!market) return null
  return (
    <Box sx={styleGrid}>
      <Box sx={styleLeftPanel}><TokenMarketSwitcherPanel type="list"/></Box>
      <Box sx={styleGridContainer} onContextMenu={onContextMenu}>
        <GraphPositionProvider>
          <ResponsiveGridLayout className="marketgrid" draggableHandle=".marketgridHandle" 
            autoSize rowHeight={51} layouts={loadedLayouts} breakpoints={gridBreakpoints} cols={gridColumns} measureBeforeMount
            onLayoutChange={onLayoutChange}
          >
              {componentKeys.map((componentKey,i)=>{
                const Component = GridComponentMap[componentKey];
                return Component&&(
                  <Box key={componentKey} sx={styleGridBox}>
                    <Component token={token} market={market} isFetching={isFetching} selectedOrderBookEntry={selectedOrderBookEntry} onSelectOrderBookEntry={ setSelectedOrderBookEntry } onMarketSwitcherSelectPath={onMarketSwitcherSelectPath}/>
                  </Box>
                );
              })}
          </ResponsiveGridLayout>
        </GraphPositionProvider>
        <Menu open={contextMenu!==null} onClose={()=>handleCloseContextMenu()} anchorReference="anchorPosition"
          anchorPosition={ contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined }
        >
          <MenuItem onClick={()=>{set('tradeMarketGridLayouts',undefined);handleCloseContextMenu()}}>Reset Layout</MenuItem>
        </Menu>
      </Box>
      {(isInitialLoading)&&<LoadingIcon curtain/>}
    </Box>
  );
}

export default MarketGrid;