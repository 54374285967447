
import Decimal from "decimal.js";
import { useCallback } from "react";
import { AccountWalletToken, BaseMarket, EFormType, EOrderSide, FormStateProps, Token, UserAccount } from "src/types";

type FormValidationProps = {
    primaryMarket?: BaseMarket;
    primaryToken?: Token;
    secondaryMarket?: BaseMarket;
    secondaryToken?: Token;
    tradeAccount?: UserAccount;
    primaryTradeTokenAccount?: AccountWalletToken;
    secondaryTradeTokenAccount?: AccountWalletToken;
}

const useSwpaFormValidation = ({ primaryMarket, primaryToken, secondaryMarket, secondaryToken, tradeAccount, primaryTradeTokenAccount, secondaryTradeTokenAccount}: FormValidationProps) => {
    const hasEnoughAsset = useCallback(
        (state: FormStateProps, type: EFormType) => {
            const market = type === EFormType.PRIMARY ? primaryMarket : secondaryMarket;
            const token = type === EFormType.PRIMARY ? primaryToken : secondaryToken;
            const tradeTokenAccount = type === EFormType.PRIMARY ? primaryTradeTokenAccount : secondaryTradeTokenAccount;

            const maxQuantity = (market&&token&&tradeAccount&&(state.orderSide===EOrderSide.BORROW ?
                // borrow maxQuantity = healthscore max borrow in usd / price
                new Decimal(tradeAccount.maxBorrowInUsd||0).div(new Decimal(token.price||0)) :
                // lend maxQuantity = trading account availableQuantity
                tradeTokenAccount?.availableQuantity&&new Decimal(tradeTokenAccount?.availableQuantity).sub(new Decimal(tradeTokenAccount?.availableQuantity).mod(new Decimal(market.quantityStep)))
            ))||new Decimal(0);
            const quantityObject = new Decimal(state.quantity||0);

            return quantityObject.lte(maxQuantity) && quantityObject.gt(0);
        }, [primaryMarket, primaryToken, secondaryMarket, secondaryToken, tradeAccount, primaryTradeTokenAccount, secondaryTradeTokenAccount]
    )

    return { hasEnoughAsset };
}

export default useSwpaFormValidation;