import { Box, SxProps, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { Link } from 'react-router-dom';
import { styleTableContainer, sxBoxHeading, sxColumn, sxColumns, sxMonoFont, sxRow, sxTableTitleRow } from 'src/styles/General';
import { Token } from 'src/types';
import apis from 'src/utils/apis';
import { format, formatDayNumber } from 'src/utils/numbers';
import theme from 'src/utils/theme';
import LoadingIcon from './Loading';
import TokenIcon from './TokenIcon';

const styleTokenIcon:SxProps = {
  marginRight: '1.5rem',
  [theme.breakpoints.down('tablet')]:{
    width: '100%',
  },
};
const sxTokenVolumeBoxContainer:SxProps = {
  ...sxColumns,
  mx: -1, // overflow:'auto'
};
const sxTokenVolumeBox:SxProps = {
  ...sxColumn,
  flex:1,
  m: 1,
  "& > a":{
    textDecoration:'none',
    display: 'block',
    py: 2, px: 0.5,
  },
  bgcolor: 'dialog.light',
  ":hover":{
    bgcolor: 'action.hover',
  }
}
const sxTotalVolumeWrapper:SxProps = {
  display:'inline-block',
  [theme.breakpoints.down('tablet')]:{
    fontSize: '0.75rem',
    display: 'grid',
    marginTop: '-1rem',
  },
};
const styleInfoBox:SxProps = {
  display:"flex",
  flexDirection:"row",
  flexWrap:'wrap',
  whiteSpace:'nowrap',
  justifyContent:'center',
  fontSize:"0.5rem",
  mx:'0.125rem',
};
const styleInfoBoxValue:SxProps = {
  flex:1,
};
const styleInfoBoxValueLabel:SxProps = {
  ...styleInfoBoxValue,
  color: 'text.secondary',
}
const styleInfoBoxValueUp:SxProps = {
  ...styleInfoBoxValue,
  color: 'positive.main',
}
const styleInfoBoxValueDown:SxProps = {
  ...styleInfoBoxValue,
  color: 'negatiev.main',
}
const sxSize:SxProps = {fontSize:'1.25rem'};

function MarketsInfoRow({tokens,showsOnlyTotalVolume=false}:{tokens?:Token[],showsOnlyTotalVolume?:boolean}) {
  // TODO // DEV better API aggregation
  const {data:marketsRatesByToken} = apis.rate.useMarketsPastRatesByTokens({tokenIds:tokens?.map(t=>t.tokenId)});
  const {data:markets} = apis.rate.useRateMarkets();
  const {data:fixedMarketsSize} = apis.fixedrate.useMarketsSize();
  let totalVolume24 = new Decimal(0);
  const marketBoxes = tokens?.map((token,i)=>{
    const marketsRates = marketsRatesByToken&&marketsRatesByToken[token.tokenId];
    const market = markets?.filter(m=>m.tokenId==token.tokenId)[0];
    const fixedMarketSize = fixedMarketsSize?.filter(ms=>ms.tokenId==token.tokenId)[0];
    const lends = new Decimal(fixedMarketSize?.deposits||0).add(new Decimal(market?.deposits||0));
    const borrows = new Decimal(fixedMarketSize?.borrows||0).add(new Decimal(market?.borrows||0));
    const marketSize = lends.add(borrows);
    const volume24 = marketsRates?.reduce((sum,rate)=>sum.add(new Decimal(rate.volume24||0)),new Decimal(0))||new Decimal(0);
    totalVolume24 = totalVolume24.add(volume24);
    return (
      <Box sx={sxTokenVolumeBox} style={{textAlign:'center'}} key={i}>
        <Link key={i} to={`/markets/${token.tokenId}/${token.code}`}>
        <Box sx={styleTokenIcon}><TokenIcon token={token} withCode={true} size={24} variant={'light'}/></Box>
        <Box sx={sxSize}>{format(marketSize,0)}</Box>
        <Box sx={styleInfoBox}>
        {[0,7,30].map((d,i)=>{
          const r = marketsRates?.filter(r=>r.daysToMaturity==d)[0];
          const close = new Decimal(r?.close||0);
          const open = new Decimal(r?.open||0);
          const delta = close.sub(open);
          const label = d==0?'FL':formatDayNumber(d);
          return (
          <Box key={i} sx={styleInfoBox}>
            <Box sx={styleInfoBoxValueLabel}>{label}</Box>
            <Box sx={styleInfoBoxValue}>{close.toFixed(2)}</Box>
            <Box sx={delta.isNeg()?styleInfoBoxValueDown:styleInfoBoxValueUp}>{delta.isNeg()?'-':'+'}{delta.toFixed(2)}</Box>
          </Box>)
        })}
        </Box>
        </Link>
      </Box>
    );
  })||<LoadingIcon/>;
  if(showsOnlyTotalVolume){
    return (<Box component="span" sx={sxTotalVolumeWrapper}><b>Total Transactions (Last 24H)</b><Box component="span" sx={sxMonoFont}>&nbsp;${format(totalVolume24,0)}</Box></Box>);
  }
  return (
  <>
    <Box sx={sxTableTitleRow}>
      <Typography variant="h1">Markets</Typography>
      <Box sx={{textAlign:'right'}}>
        <Box>Total Transactions - 24HRS</Box>
        <Box sx={{...sxMonoFont,fontSize:'1.375rem'}}>${format(totalVolume24,0)}</Box>
      </Box>
    </Box>
    <Box sx={styleTableContainer}>
      <Box sx={sxRow}>
        <Box sx={sxBoxHeading}>Volume, Last 24hrs</Box>
        <Box sx={sxTokenVolumeBoxContainer}>
          {marketBoxes}
        </Box>
      </Box>
    </Box>
  </>);
}
export default MarketsInfoRow;