import { Box, Button, SxProps } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import StandardDialog from 'src/Components/StandardDialog';
import {
  sxActionButtonFirst,
  sxConfirmDialogActionButtonContainer,
  sxConfirmationDialog,
  sxOrderStatusTitle,
  sxStatusTitleLabel
} from 'src/styles/Dialog';
import { styleFormButton } from 'src/styles/Form';
import apis from 'src/utils/apis';
import { timeTo } from 'src/utils/date';

const RolloverDialog = forwardRef(function(props: any, ref: any) {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const reloadTimeoutRef = useRef<NodeJS.Timeout | undefined>()
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { nextMarketRolloverTime } = apis.infinity.useMarketRolloverHour();
    
    useEffect(()=>{
        if(!queryClient || !nextMarketRolloverTime || !isOpen) return;
        const ms = timeTo(nextMarketRolloverTime);

        if (ms > 0) {
            queryClient.invalidateQueries(['market']);
            queryClient.invalidateQueries(['market',{category:'all',action:'listPastRates'}]);
            queryClient.invalidateQueries(['market',{category:'fixed',action:'list'}]);
        }
      },[nextMarketRolloverTime, queryClient, isOpen]);

    useImperativeHandle(ref, () => {
        return {
          openDialog() {
            setIsOpen(true)
            reloadTimeoutRef.current = setTimeout(() => {
                navigate(0)
            }, 10000)
          }
        };
    }, []);

    const handleReload = useCallback(
      () => {
        if (reloadTimeoutRef?.current) clearTimeout(reloadTimeoutRef.current)
        navigate(0)
      }, [navigate, reloadTimeoutRef]
    );

    const content = (<Box sx={{ height: '450px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Box>
            <Box sx={{ ...sxOrderStatusTitle, marginBottom: '16px', marginTop: '0', }}>
                <Box component={'span'} sx={{ ...sxStatusTitleLabel, textAlign: 'center'}}>
                    Contract Date Rollover Occuring
                </Box>
            </Box>
            <Box sx={{ mx: '52px', fontSize: '1rem', lineHeight: '1.5rem', textAlign: 'center'}}>
                The markets are currently updating. This page will automatically refresh in 10 seconds.
            </Box>
        </Box>
    </Box>)

    const actions = (
        <Box sx={sxConfirmDialogActionButtonContainer}>
          <Button variant="contained" sx={{ ...styleFormButton, ...sxActionButtonFirst } as SxProps} onClick={handleReload}>Refresh page now</Button>
        </Box>)

    return (
        <>
        {isOpen ? 
            <StandardDialog
                sxCustomDialog={sxConfirmationDialog}
                open={isOpen}
                title={'Contract Date Rollover'}
                content={content}
                actions={actions}
            /> : null
        }
        </>
    );
  });

  export default RolloverDialog