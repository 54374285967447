import { GlobalStyles } from '@mui/material';

function GlobalStylesComponent(){
  return (
    <GlobalStyles styles={(theme)=>({
      ':root': {
        '--animValueUpColor': theme.palette.success.main,
        '--animValueDownColor': theme.palette.error.main,
        '--animCellUpBgColor': theme.palette.positive.dark,
        '--animCellDownBgColor': theme.palette.negative.dark,
        // font-family for typography in theme.tsx
        fontFamily: 'Inter, Helvetica, Arial, sans-serif',
        fontSmoothing: 'antialiased',
        WebkitFontSmoothing: 'antialiased',
      },
      '*': {
        boxSizing: 'border-box',
      },  
      'html': {
        colorScheme: theme.palette.mode||'dark',
        // TODO enhance scrollbar? overridding with css removes auto hide
        // '*::-webkit-scrollbar': {
        //   'width': '10px',
        //   'height': '10px',
        // },
        '*::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
          borderRadius: '10px',
          backgroundColor: 'transparent',
        },
        '*::-webkit-scrollbar': {
          width: '4px',
          height: '4px',
          backgroundColor: 'transparent',
        },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
          backgroundColor: theme.palette.slate?.['900']
        },
      },
      // "a,.MuiLink-root":{
      //   'color': 'inherit',
      // },
      ".MuiTableCell-root":{
        padding: '12px',
      },
      ".MuiButton-root":{
        textTransform: 'initial',
        fontWeight: 700,
        color: theme.palette.text.primary,
        borderRadius: '4px',
        whiteSpace: 'nowrap',
        '&.Mui-disabled':{
          backgroundColor: theme.palette.buttonDisabled.main,
          borderColor: theme.palette.buttonDisabled.main,
          color: theme.palette.buttonDisabled.dark,
        },
        "&.MuiButton-containedPrimary": {
          textDecoration: 'none',
          whiteSpace: 'nowrap',
          // 'color': theme.palette.text.primary,
          // "&:hover": {
          //   'background-color': theme.palette.dialog.light,
          // }
        },
        "&.MuiButton-containedSecondary": {
          textDecoration: 'none',
          whiteSpace: 'nowrap',
          'color': theme.palette.background.paper,
          // 'border-width': '1px',
          // 'border-style': 'solid',
          // 'border-color': theme.palette.secondary.light,
          "&:hover": {
            backgroundColor: theme.palette.secondary.light,
          }
        }
      },
      ".MuiButton-text:not(.MuiButton-contained):not(.MuiButton-outlined)": {
        "&,&:hover":{
          fontWeight: 400,
          textDecoration: "underline",
        },
      },
      ".MuiToggleButton-root": {
        fontSize: '0.75rem',
        // whiteSpace: 'nowrap',
      },
      ".MuiButtonGroup-grouped":{
        "&.MuiButton-outlined,&.MuiButton-contained":{
          borderWidth: '1px',
          borderColor: theme.palette.border.main,
        },
        ":is(.MuiButtonGroup-grouped):not(:last-of-type):hover": {
          borderRightColor: 'transparent',
        },
      },
      ".MuiButtonGroup-outlined":{
        margin: '8px 0', 
      },
      ".MuiToggleButtonGroup-root":{
        maxWidth:'100%',
      },
      ".MuiToggleButtonGroup-grouped":{
        fontWeight: 700, flex: 1, width: '50%', minHeight: '32px', padding: '0 8px', 
        overflow:'hidden',
        '&[aria-pressed="true"]':{
          color: theme.palette.border.light,
          borderColor: theme.palette.border.light,
          backgroundColor: theme.palette.button.main,
          '&:not(:last-of-type)':{ // priority
            marginRight: '1px',
          },
          '&:not(:first-of-type)':{ // priority
            borderColor: theme.palette.border.light,
          },
          zIndex: 2,
        },
        '&[aria-pressed="false"]':{
          color: theme.palette.border.dark,
          borderColor: theme.palette.border.dark,
          backgroundColor: theme.palette.button.dark,
          transition: 'all 0.3s ease',
          '&:hover':{
            color: theme.palette.border.main,
            borderColor: theme.palette.border.main,
            backgroundColor: theme.palette.button.main,
            zIndex: 1,
          },
        },
        '&:is(.MuiButtonBase-root)':{
          '&:not(:first-of-type),&:not(:last-of-type)':{
            borderLeftColor:'inherit'
          },
        }
      },
      /* mui input labels */
      ".MuiFormLabel-root.MuiInputLabel-root.Mui-focused":{
        color: 'inherit',
      },
      /* select & dropdown */
      ".MuiSelect-select.MuiInputBase-input":{
        // paddingTop: '6px', paddingBottom: '6px', paddingLeft: '8px',
      },
      ".MuiModal-root .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper":{
        backgroundImage: 'none',
        backgroundColor: theme.palette.background.paper,
      },
      ".MuiModal-root .MuiList-root.MuiMenu-list":{
        padding: '8px', borderRadius: '4px',
      },
      ".MuiModal-root .MuiList-root.MuiMenu-list .MuiButtonBase-root.MuiMenuItem-root":{
        margin: '4px auto', padding: '4px', borderRadius: '4px', minHeight: '32px',
      },
      "w3m-modal":{
        position: 'relative',
        zIndex: theme.zIndex.modal+1,
      },
    })}/>
  );
}

export default GlobalStylesComponent;