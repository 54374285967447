import {
  Box
} from '@mui/material';
import { SxProps } from '@mui/system';
import { useAuth } from 'src/AuthProvider';
import RateBucketsGraph from 'src/Components/RateBucketsGraph';
import { styleGrid, styleHeader, stylePanelChartContainer } from 'src/styles/GridBox';
import { GridComponentParams } from '.';
import DragGrabber from './DragGrabber';
import { withGraphPosition } from './GraphPositionProvider';

const RateBucketsGraphWithGraphPosition = withGraphPosition(RateBucketsGraph);

const styleTabs:SxProps = {
  py: '2px',
  bgcolor:'background.paper',
};

function LiquidityGraph({market,token}:GridComponentParams){
  const auth = useAuth();
  return (
    <Box sx={styleGrid}>
      <Box sx={styleHeader}>Liquidity<DragGrabber/></Box>
      <Box sx={stylePanelChartContainer}>
        <RateBucketsGraphWithGraphPosition token={token} type="liquidity"/>
        {/* {!auth?.user&&<LoginPromptPanel/>} */}
      </Box>
    </Box>
  );
}


export default LiquidityGraph;