import BaseOrderBook from 'src/Components/Common/BaseOrderBook';
import { GridComponentParams } from 'src/Components/MarketGrids';
import apis from 'src/utils/apis';
import { useSwapParameterStore } from 'src/store/useSwapParametersStore';
import { getTokenByLeg, getMarketByLeg } from 'src/Components/Swaps/selectors';
import { ETradeType, MarketInfo, OrderBookEntryWithDepth, EOrderSide, RateOrderBook } from 'src/types';
import { isEmpty, isUndefined } from 'lodash';
import Decimal from 'decimal.js';
import { useMarketRollingDateLabel } from 'src/Components/MarketRollingDateLabel';

type MergeOrderBook = {
    primaryMarketInfo?: MarketInfo;
    primaryOrderBook?: RateOrderBook;
    secondaryMarketInfo?: MarketInfo;
    secondaryOrderBook?: RateOrderBook;
    exchangeRate: string
}

type MergeOrderBooksSide = {
    primaryBookSide: OrderBookEntryWithDepth[]
    secondaryBookSide: OrderBookEntryWithDepth[];
    mergedOrdersEQRate: Decimal
    exchangeRate: string,
    bookSide: EBookSide
}

enum EMinSide {
    PRIMARY,
    SECONDARY,
    BOTH
}

enum EBookSide {
    LEND, BORROW
}

const sortFnDesc = (a:OrderBookEntryWithDepth,b:OrderBookEntryWithDepth) => Number(a.rate) < (Number(b.rate)) ? 1 : Number(a.rate) > (Number(b.rate)) ? -1 : 0;
const sortFnAsk = (a:OrderBookEntryWithDepth,b:OrderBookEntryWithDepth) => Number(a.rate) < (Number(b.rate)) ? -1 : Number(a.rate) > (Number(b.rate)) ? 1 : 0;

const mergeOrderBookSide = ({ primaryBookSide, secondaryBookSide, mergedOrdersEQRate, exchangeRate, bookSide}: MergeOrderBooksSide) => {
    if (isEmpty(primaryBookSide) || isEmpty(secondaryBookSide)) return []
    const primaryDecimal = new Decimal(primaryBookSide[0].quantity).dp()

    const primaryBook = primaryBookSide.map(p => ({
        rate: p.rate,
        step: new Decimal(p.step),
        quantity: new Decimal(p.quantity)
    }))

    const secondaryBook = secondaryBookSide.map(s => ({
        rate: s.rate,
        step: new Decimal(s.step),
        quantity: new Decimal(s.quantity).div(exchangeRate)
    }))

    let newOrderBook: OrderBookEntryWithDepth[] = []

    while(primaryBook.length > 0 && secondaryBook.length > 0 && newOrderBook.length < 20) {
        const pri = primaryBook[0]
        const sec = secondaryBook[0]
        const minSide = pri.quantity.lt(sec.quantity) ? EMinSide.PRIMARY : (pri.quantity.gt(sec.quantity) ? EMinSide.SECONDARY : EMinSide.BOTH)

        switch (minSide) {
            case (EMinSide.PRIMARY): {
                primaryBook.shift()

                const newOrderBookEntry = {
                    rate: bookSide === EBookSide.LEND ? mergedOrdersEQRate.plus(pri.step.abs()).plus(sec.step.abs()).div(100).valueOf() : mergedOrdersEQRate.minus(pri.step.abs()).minus(sec.step.abs()).div(100).valueOf(),
                    quantity: pri.quantity.toFixed(primaryDecimal),
                    side: bookSide === EBookSide.LEND ? EOrderSide.LEND : EOrderSide.BORROW,
                    step: (bookSide === EBookSide.LEND ? pri.step.abs().plus(sec.step.abs()) : pri.step.abs().plus(sec.step.abs()).negated()).toString()
                }
                if(newOrderBook.length > 0){
                    const oldEntryDepth = newOrderBook[newOrderBook.length - 1][bookSide === EBookSide.LEND ? 'lendDepth' : 'borrowDepth'];
                    const oldEntryDepthDecimal = oldEntryDepth ? new Decimal(oldEntryDepth) : undefined;
                    newOrderBookEntry[bookSide === EBookSide.LEND ? 'lendDepth' : 'borrowDepth'] = oldEntryDepthDecimal?.plus(pri.quantity) ?? pri.quantity.toString();
                }else{
                    newOrderBookEntry[bookSide === EBookSide.LEND ? 'lendDepth' : 'borrowDepth'] = pri.quantity.toString();
                }
                newOrderBook.push(newOrderBookEntry)
                sec.quantity = sec.quantity.minus(pri.quantity)
                break
            }
            case (EMinSide.SECONDARY): {
                secondaryBook.shift()

                const newOrderBookEntry = {
                    rate: bookSide === EBookSide.LEND ? mergedOrdersEQRate.plus(pri.step.abs()).plus(sec.step.abs()).div(100).valueOf() : mergedOrdersEQRate.minus(pri.step.abs()).minus(sec.step.abs()).div(100).valueOf(),
                    quantity: sec.quantity.toFixed(primaryDecimal),
                    side: bookSide === EBookSide.LEND ? EOrderSide.LEND : EOrderSide.BORROW,
                    step: (bookSide === EBookSide.LEND ? pri.step.abs().plus(sec.step.abs()) : pri.step.abs().plus(sec.step.abs()).negated()).toString()
                }
                if(newOrderBook.length > 0){
                    const oldEntryDepth = newOrderBook[newOrderBook.length - 1][bookSide === EBookSide.LEND ? 'lendDepth' : 'borrowDepth'];
                    const oldEntryDepthDecimal = oldEntryDepth ? new Decimal(oldEntryDepth) : undefined;
                    newOrderBookEntry[bookSide === EBookSide.LEND ? 'lendDepth' : 'borrowDepth'] = oldEntryDepthDecimal?.plus(sec.quantity) ?? sec.quantity.toString();
                }else{
                    newOrderBookEntry[bookSide === EBookSide.LEND ? 'lendDepth' : 'borrowDepth'] = sec.quantity.toString();
                }
                newOrderBook.push(newOrderBookEntry)
                pri.quantity = pri.quantity.minus(sec.quantity)
                break
            }
            case (EMinSide.BOTH): {
                primaryBook.shift()
                secondaryBook.shift()

                const newOrderBookEntry = {
                    rate: bookSide === EBookSide.LEND ? mergedOrdersEQRate.plus(pri.step.abs()).plus(sec.step.abs()).div(100).valueOf() : mergedOrdersEQRate.minus(pri.step.abs()).minus(sec.step.abs()).div(100).valueOf(),
                    quantity: pri.quantity.toFixed(primaryDecimal),
                    side: bookSide === EBookSide.LEND ? EOrderSide.LEND : EOrderSide.BORROW,
                    step: (bookSide === EBookSide.LEND ? pri.step.abs().plus(sec.step.abs()) : pri.step.abs().plus(sec.step.abs()).negated()).toString()
                }
                if(newOrderBook.length > 0){
                    const oldEntryDepth = newOrderBook[newOrderBook.length - 1][bookSide === EBookSide.LEND ? 'lendDepth' : 'borrowDepth'];
                    const oldEntryDepthDecimal = oldEntryDepth ? new Decimal(oldEntryDepth) : undefined;
                    newOrderBookEntry[bookSide === EBookSide.LEND ? 'lendDepth' : 'borrowDepth'] = oldEntryDepthDecimal?.plus(pri.quantity) ?? pri.quantity.toString();
                }else{
                    newOrderBookEntry[bookSide === EBookSide.LEND ? 'lendDepth' : 'borrowDepth'] = pri.quantity.toString();
                }
                newOrderBook.push(newOrderBookEntry)
                break
            }
        }
    }
    return newOrderBook
}

const mergeOrderBooks = ({ primaryMarketInfo, primaryOrderBook, secondaryMarketInfo, secondaryOrderBook, exchangeRate}: MergeOrderBook) => {
    const primaryOrdersEQRate = new Decimal(primaryMarketInfo?.midRate||primaryMarketInfo?.rate||0).mul(100).toDP(2)
    const secondaryOrdersEQRate = new Decimal(secondaryMarketInfo?.midRate||secondaryMarketInfo?.rate||0).mul(100).toDP(2)
    const mergedOrdersEQRate = new Decimal(primaryOrdersEQRate).minus(secondaryOrdersEQRate)
    const primaryDeposit = primaryOrderBook?.asks?.sort(sortFnAsk) ?? []
    const primaryBorrow = primaryOrderBook?.bids?.sort(sortFnDesc) ?? []
    const secondaryDeposit = secondaryOrderBook?.asks?.sort(sortFnAsk) ?? []
    const secondaryBorrow = secondaryOrderBook?.bids?.sort(sortFnDesc) ?? []
    const asks = mergeOrderBookSide({ primaryBookSide: primaryDeposit, secondaryBookSide: secondaryBorrow, mergedOrdersEQRate, exchangeRate, bookSide: EBookSide.LEND })
    const bids = mergeOrderBookSide({ primaryBookSide: primaryBorrow, secondaryBookSide: secondaryDeposit, mergedOrdersEQRate, exchangeRate, bookSide: EBookSide.BORROW })
    return { asks, bids, midRate: mergedOrdersEQRate }
}

function SwapOrderBook({ tradeType }: { tradeType: ETradeType}){
    const primaryMarket = useSwapParameterStore(getMarketByLeg('leg1'))
    const secondaryMarket = useSwapParameterStore(getMarketByLeg('leg2'))
    const getExchangeRate = useSwapParameterStore((state) => state.getExchangeRate)
    const setMergedOrderBook = useSwapParameterStore((state) => state.setMergedOrderBook)
    const primaryMarketLabel = useMarketRollingDateLabel({ market: primaryMarket, withMarketCode: true })
    const secondaryMarketLabel = useMarketRollingDateLabel({ market: secondaryMarket, withMarketCode: true })
    const exchangeRate = getExchangeRate()
    const {data:primaryMarketInfo} = apis.rate.useMarketInfo({ market: primaryMarket});
    const {isLoading, data:primaryOrderBook} = apis.rate.useRateOrderBook(primaryMarket, 30, primaryMarketInfo);
    const {data:secondaryMarketInfo} = apis.rate.useMarketInfo({ market: secondaryMarket});
    const {data:secondaryOrderBook} = apis.rate.useRateOrderBook(secondaryMarket, 30, secondaryMarketInfo);

    let orderBook = primaryOrderBook
    let market = primaryMarket
    let marketInfo = primaryMarketInfo
    let marketLabel = ''

    if (tradeType === ETradeType.CROSS_CURRENCY_SWAP && !isUndefined(secondaryMarket?.daysToMaturity)) {
        const { asks, bids, midRate } = mergeOrderBooks({ primaryMarketInfo, primaryOrderBook, secondaryMarketInfo, secondaryOrderBook, exchangeRate})
        orderBook = {
            ...orderBook!,
            asks,
            bids
        }
        marketInfo = {
            ...marketInfo!,
            rate: midRate.div(100).valueOf(),
            midRate: midRate.div(100).valueOf()
        }
        marketLabel = `${primaryMarketLabel} - ${secondaryMarketLabel}`
        if (orderBook.asks.length > 0 && orderBook.bids.length > 0) setMergedOrderBook({ bestAsk: orderBook.asks[0].rate, bestBid: orderBook.bids[0].rate})
    }

    return (<BaseOrderBook market={market} marketInfo={marketInfo} orderBook={orderBook} isLoading={isLoading} marketLabel={marketLabel}/>);
  }
  
  export default SwapOrderBook;