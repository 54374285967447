import { TradeHistoryItem } from './market';
import { ReactElement } from 'react';
import { SxProps } from '@mui/material';

export interface OrderTableHeadCell {
    disablePadding: boolean;
    id: keyof TradeHistoryItem;
    label: string | ReactElement;
    align: 'left'|'center'|'right';
    width?: string | ((type: string) => string);
    colSpan?: number;
    sticky?: boolean;
    type?: string;
    style?: SxProps;
    withSelector?: (type: 'open' | 'history') => boolean;
}

export enum EOrderStatusType {
    'PENDING','PARTIAL','DONE','ERROR',
  }