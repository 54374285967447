import { isNil } from 'lodash';
import { useEffect } from 'react';
import { MARKETS_PAGE_TOKENS } from 'src/constants/app';
import { useSwapParameterStore } from 'src/store/useSwapParametersStore';
import { ETradeType, Market, MarketFixedRate } from 'src/types';
import apis from 'src/utils/apis';


export const useSwapParameters = (tradeType: ETradeType) => {
    const {isLoading:isLoadingTokens,data:fetchedTokens} = apis.token.useTokens();
    const {data:marketsData} = apis.rate.useMarkets();
    const state = useSwapParameterStore();

    useEffect(
        () => {
            if (state.tradeType !== tradeType) {
                state.setTradeType(tradeType)
            }
        }, [tradeType, state]
    )

    useEffect(
        () => {
            const filteredTokens = fetchedTokens?.filter(token => MARKETS_PAGE_TOKENS.includes(token.code));
            if (filteredTokens) {
                state.setTokens(filteredTokens)
            }
        }, [fetchedTokens, state, tradeType]
    )

    useEffect(
        () => {
            if (!isNil(marketsData)) {
                state.setMarkets(marketsData as (Market|MarketFixedRate)[], 0, false)
            }
        }, [marketsData, state]
    )
}