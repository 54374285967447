import { Box, Button } from '@mui/material';
import { SxProps } from '@mui/system';
import { useQueryClient } from '@tanstack/react-query';
import Decimal from 'decimal.js';
import { useState } from 'react';
import { CHAIN_ID, FAUCET_TOKEN_ADDRESSES } from 'src/constants/app';
import { useAuth } from '../AuthProvider';
import { Token, TransferType } from '../types';
import TokenIcon from './TokenIcon';
import TokenTransferDialog from './TokenTransferDialog';
import { sendGA } from 'src/utils/common';

const sxButton:SxProps = {
  "&.MuiButton-root":{
    display: 'block',
    p: 0,
    minWidth: 'auto',
    color: 'primary.light',
    fontWeight: 400,
  }
};

const testCoinRequestAmounts:{[key:string]:Decimal} = {
  "USDT":new Decimal(100000)/* .pow(6) */, "T_USDT":new Decimal(100000)/* .pow(6) */,
  "USDC":new Decimal(100000)/* .pow(6) */, "T_USDC":new Decimal(100000)/* .pow(6) */,
  "DAI":new Decimal(100000)/* .pow(18) */, "T_DAI":new Decimal(100000)/* .pow(18) */,
  "WBTC":new Decimal(3)/* .pow(8) */, "T_WBTC":new Decimal(1)/* .pow(8) */,
  "WETH":new Decimal(50)/* .pow(18) */, "T_WETH":new Decimal(1)/* .pow(18) */,
  "ETH":new Decimal(50)/* .pow(18) */, "T_ETH":new Decimal(1)/* .pow(18) */,
}

function FaucetButton({token}:{token?:Token}){
  const auth = useAuth();
  const queryClient = useQueryClient();
  const [open,setOpen] = useState(false);
  const [isTransferring,setIsTransferring] = useState(false);
  const [transferEndType,setTransferEndType] = useState<TransferType|undefined>();
  if(!token) return null;
  const showButton = CHAIN_ID!=1&&FAUCET_TOKEN_ADDRESSES.length>0&&FAUCET_TOKEN_ADDRESSES.map(a=>a.toLowerCase()).includes(token?.tokenAddress.toLowerCase()||'');
  if(!showButton) return null;
  const transferAmount = testCoinRequestAmounts[token.code];
  if(!transferAmount) return null;
  const transferFee = new Decimal(0);
  const onClick = async()=>{
    setIsTransferring(true);
    sendGA('faucet_confirm',{'token_code':token.code});
    try{
      const _hash = await auth?.wallet?.requestToken(token?.tokenAddress||'');
      setTransferEndType(TransferType.TRANSFER_CHAIN_FAUCET_COMPLETE);
      sendGA('faucet_success',{'token_code':token.code});
    }catch(error){
      setTransferEndType(TransferType.TRANSFER_ERROR);
      sendGA('faucet_error',{'token_code':token.code});
    }
    setIsTransferring(false);
    queryClient.invalidateQueries(['user']);
  }
  return (<Box sx={{display:'flex'}}>
    <Box sx={{visibility:'hidden'}}><TokenIcon token={token} size={14}/></Box>
    <Button sx={sxButton} variant="text" onClick={()=>{
      sendGA('faucet_dialog',{'token_code':token.code});
      setOpen(true);
    }}>Faucet</Button>
    {token&&<TokenTransferDialog
      token={token} transferAmount={transferAmount} transferFee={transferFee} 
      from={`${token.code} Faucet`} to={"Your Wallet"}
      transferEndType={transferEndType}
      faucetTransfer
      isTransferring={isTransferring} open={open} handleClose={()=>{
        setOpen(false); setTransferEndType(undefined);
      }} handleClickConfirmTransfer={()=>onClick()}
      lastTransferData={{ transferAmount, tokenCode: token.code, from: `${token.code} Faucet`, to: "Your Wallet" }}
    />}
  </Box>)
}

export default FaucetButton;
