import { Box, SxProps } from '@mui/material';
import Decimal from 'decimal.js';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import 'echarts-gl';
import { DatasetComponent, GridComponent, TitleComponent, TooltipComponent, VisualMapComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import moment from 'moment';
import { useCallback, useMemo, useRef } from 'react';
import { hslStrToHex } from 'src/utils/common';
import { formatStartOfDayDate, today, useCallbackGetDaysFromMarketToday } from 'src/utils/date';
import theme from 'src/utils/theme';
import { Token } from '../types';
import apis from '../utils/apis';
import LoadingIcon from './Loading';
import {
  sxChartWatermarkBottomRight
} from '../styles/Chart';
echarts.use(
  [GridComponent, TooltipComponent, TitleComponent, VisualMapComponent, DatasetComponent, CanvasRenderer]
);

function MarketYieldCurvesHistoricalSurfaceChart({
  token,
}:{
  token?:Token,
}){
  const getDaysFromMarketToday = useCallbackGetDaysFromMarketToday();
  const maxDays = 28;
  const { isLoading:isLoadingHistorical, data:historicalData } = apis.fixedrate.useMarketsYieldCurveHistorical({tokenId:token?.tokenId,daysToInclude:maxDays});
  const { bucketLabels:yLabels, historicalCurve } = historicalData||{};

  // const isLoadingAny = isLoadingHistorical;
  // generate rechart data
  const {wrappedSeriesData,zmin,zmax/* ,xmin,xmax */} = useMemo(()=>{
    // generate surface map first in case of missing points from api
    const surfaceMap:{[daysFromToday:number]:{[daysToMaturity:number]:number}} = {};
    // safety measure to fill up surfce first in case API data is missing any vertex
    (new Array(maxDays).fill(0)).forEach((_,i)=>{
      const daysFromToday = -i;
      surfaceMap[daysFromToday] = {};
      yLabels?.forEach((_,j)=>{
        surfaceMap[daysFromToday][j] = 0;
      })
    });
    // console.log('surfaceMap init vertices', surfaceMap);
    // min max 
    let zmin = new Decimal(100);
    let zmax = new Decimal(0);
    let xmin = Number.MAX_SAFE_INTEGER;
    let xmax = 0;
    for(let dateStr in historicalCurve){
      for(let item of historicalCurve[dateStr]){
        // const historicalDate = date(item.genDate);
        // const maturityDate = date(item.maturityDate);
        // const daysToMaturity = getDaysDifference(maturityDate,historicalDate);
        // xAxis: historicalDate - compare plain date, not market date
        const xValue = getDaysFromMarketToday(moment(dateStr));
        // yAxis: daysToMaturity bucket - use xpos 
        const yValue = item.xpos;
        // zAxis: rate
        const rate = new Decimal(item.rate||0).mul(100);
        // const name = yValue==10?'Float':getRollingDateLabel(yValue);
        if(zmin.gt(rate)) zmin = rate;
        if(zmax.lt(rate)) zmax = rate;
        if(xmin>yValue) xmin = yValue;
        if(xmax<yValue) xmax = yValue;
        const zValue = rate.toNumber();
        // write to vertices
        if(surfaceMap[xValue]&&surfaceMap[xValue][yValue]!==undefined){
          surfaceMap[xValue] = {...surfaceMap[xValue]||{},[yValue]:zValue||0};
        }else{
          console.warn(`surfaceMap vertex ${xValue},${yValue} missing (z:${zValue})`);
        }
      }
    }
    const tokenData:[number,number,number][] = [];
    Object.keys(surfaceMap).forEach((daysFromToday)=>{
      Object.keys(surfaceMap[daysFromToday]).forEach((daysToMaturity)=>{
        const rate = surfaceMap[daysFromToday][daysToMaturity];
        tokenData.push([Number(daysFromToday),Number(daysToMaturity),rate])
      });
    });
    // console.log('tokenData',tokenData, 'zmin',zmin.toString(),'zmax',zmax.toString());
    // sort data to make sure surface vertices are in order (x rows->y rows i.e. rows sorted by y, then intra row items sorted by x)
    const data = tokenData.sort((a,b)=>{
      const xsort = (a?.at(0)||0)>(b?.at(0)||0)?1:(a?.at(0)||0)<(b?.at(0)||0)?-1:0;
      const ysort = (a?.at(1)||0)>(b?.at(1)||0)?1:(a?.at(1)||0)<(b?.at(1)||0)?-1:0;
      // const zsort = (a?.at(2)||0)>(b?.at(2)||0)?1:(a?.at(2)||0)<(b?.at(2)||0)?-1:0;
      return ysort!==0?ysort:xsort;
    });
    // console.log('sortedData',data);
    const wrappedSeriesData = {
      type: 'surface',
      wireframe: { show: true },
      data, dataShape:data.length===0?[0,0]:[Math.max(0,yLabels?.length||0),maxDays],
    };
    return {wrappedSeriesData,zmin,zmax,xmin,xmax};
  },[historicalCurve,yLabels,getDaysFromMarketToday]);
  const lineStyle = useMemo(()=>({color:`${hslStrToHex(theme.palette.text.primary)}66`}),[]);
  const getAxisOptions = useCallback((formatter:(n:number)=>string,opts={})=>{
    return {
      type: 'value', name: '',
      splitLine: {lineStyle},
      axisLine: {lineStyle},
      axisPointer:{lineStyle:{color:`${hslStrToHex(theme.palette.text.primary)}aa`},label:{formatter}},
      axisLabel: {formatter},
      ...opts,
    }
  },[lineStyle]);
  const xFormatter = useCallback((n:number)=>formatStartOfDayDate(today().add(n,'d')),[]);
  const yFormatter = useCallback((n:number)=>yLabels?.at(Math.round(n))||'',[yLabels]);
  const zFormatter = useCallback((n:number)=>`${new Decimal(n).toFixed(2)}%`,[]);

  const sxContainer: SxProps = {
    position:'relative',
    height:'100%',
    width:'100%',
    ...sxChartWatermarkBottomRight,
    "canvas": {
      cursor: 'pointer'
    }
  }

  return (<Box sx={sxContainer}>
    <ReactEChartsCore
      echarts={echarts}  
      style={{height:'100%',width:'100%'}}
      option={{
        tooltip: {formatter:({data}:{data:number[]})=>{
          const [x,y,z] = data;
          // `x<0&&y===0` means previous days' Floating rate data; shows '-' for N/A
          const zValue = (x<0&&y===0&&z===0)?'-':zFormatter(z);
          return `${xFormatter(x)}<br/>${token?.code}-${yFormatter(y)}<br/>${zValue}`;
        }},
        darkMode: true,
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
        visualMap: {
          show: false,
          dimension: 2,
          min: zmin.ceil().toNumber(),
          max: zmax.floor().toNumber(),
          inRange: { color: [ 
            '#313695',
            '#4575b4',
            '#74add1',
            '#abd9e9',
            '#e0f3f8',
            '#ffffbf',
            '#fee090',
            '#fdae61',
            '#f46d43',
            '#d73027',
            '#a50026'
          ].reverse() }
        },
        xAxis3D: getAxisOptions(xFormatter,{
          // history
          min: -maxDays, max: 0,
        }),
        yAxis3D: getAxisOptions(yFormatter,{
          // days to maturity
          min: 0
        }),
        zAxis3D: getAxisOptions(zFormatter,{
          // rate
          axisTick: {interval:0.01}, min: zmin.floor().toNumber(), max: zmax.ceil().toNumber(),
        }),
        grid3D: {
          lineStyle,
          boxWidth: 150,
          boxDepth: 60,
          boxHeight: 60,
          viewControl: {
            // projection: 'orthographic',
            distance: 150,
            center: [10,-25,0],
          }
        },
        series: [
          wrappedSeriesData,
        ],
      }}
      lazyUpdate // showLoading={isLoadingAny}
    />
    {isLoadingHistorical&&<LoadingIcon curtain/>}
  </Box>);
}

export default MarketYieldCurvesHistoricalSurfaceChart;