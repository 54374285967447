import ArrowBack from '@mui/icons-material/ArrowBack';
import { Box, SxProps } from '@mui/material';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import MarketFixedRateInfoRow from 'src/Components/MarketFixedRateInfoRow';
import { sxBox, sxBoxCompactInner, sxBoxTitle, sxRow } from 'src/styles/General';
import { sxContent } from 'src/styles/Page';
import { usePageTitle } from 'src/utils/common';
import { paths } from 'src/utils/paths';
import { useAuth } from 'src/AuthProvider';
import LiquidityForm from 'src/Components/LiquidityForm';
import LoadingIcon from 'src/Components/Loading';
import MarketDepthGraph from 'src/Components/MarketDepthGraph';
// import TradeHistoryTable from 'src/Components/TradeHistoryTable';
import apis from 'src/utils/apis';

const sxArrowBack:SxProps = {
  fontSize:12, verticalAlign:'middle',
};

function LiquidityTransaction(){
  usePageTitle(['Liquidity']);
  const auth = useAuth();
  const navigate = useNavigate();
  const { marketCode } = useParams();
  const {isLoading:isLoadingMarkets,data:markets} = apis.rate.useRateMarkets();
  const market = (markets||[]).filter(m=>m.instrumentId==marketCode)[0];
  const token = market?.token;
  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  useEffect(()=>{
    if(!isLoadingMarkets&&!(market&&token)){
      navigate(paths.liquidity(),{replace:true});
    }
  },[isLoadingMarkets,market,token]);
  if(!isLoadingMarkets&&!(market&&token)){
    return null;
  }

  return (
    <>
      <Box sx={sxContent}>
        <Box sx={{marginBottom:'16px'}}>
          <Link to={paths.liquidity()}><ArrowBack sx={sxArrowBack}/> Liquidity</Link>
        </Box>
      {isLoadingMarkets?(
        <><LoadingIcon curtain/></>
        ):(<>
        <Box sx={sxBox}>
          <MarketFixedRateInfoRow token={token}/>
        </Box>
        <Box sx={sxRow}>
          <Box sx={sxBox}>
            <MarketDepthGraph market={market} token={token} barWidth="7px" style='block'/>
          </Box>
          <Box sx={sxBox} style={{width:'300px',flexShrink:0}}>
            <Box sx={sxBoxTitle}>Your Account</Box>
            <Box sx={sxBoxCompactInner}>
              <LiquidityForm market={market} token={token}/>
            </Box>
          </Box>
        </Box>
        <Box sx={sxBox}>
          <Box sx={sxBoxTitle}>Your Trade History</Box>
          <Box sx={sxBoxCompactInner}>
            {/* <TradeHistoryTable type='history' selectedMarketId={market.marketId} filterBy='lp'/> */}
          </Box>
        </Box>
      </>)}
      </Box>
    </>
  );
}

export default LiquidityTransaction;