import ExploreAccountsPanel from 'src/Components/ExploreAccountsPanel';
import ExploreOverviewPanel from 'src/Components/ExploreOverviewPanel';
import ExploreTradePanel from 'src/Components/ExploreTradePanel';
import WithSubHeader from 'src/Components/WithSubHeader';
import { FEATURE_VERSION } from 'src/constants/app';

const tabs:{[key:string]:{
  label: string;
  title: string;
  component: JSX.Element;
}} = {
  "overview":{"label":"Overview","title":"Explore: Overview","component":<ExploreOverviewPanel/>},
  "trades":{"label":"Trades","title":"Explore: Trades","component":<ExploreTradePanel/>},
  "accounts":{"label":"Accounts","title":"Explore: Accounts","component":<ExploreAccountsPanel/>},
};
if(FEATURE_VERSION!=='development'){
  delete tabs.accounts;
}

function Explore({tab}:{tab:string}){
  let {label,title,component} = tabs[tab];
  return (
    <WithSubHeader parentTitle="Explore" urlBase="/explore" 
      label={label}
      title={title}
      tab={tab}
      tabs={tabs}
    >{component}</WithSubHeader>
  );
}

export default Explore;