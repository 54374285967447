import { SxProps } from '@mui/system';
import theme from '../utils/theme';

export const sxTable:SxProps = {
  tableLayout:"auto",
  borderCollapse:'separate',
  "th,td":{
    px:'12px', py: '6px',
    fontSize: '0.875rem',
    // fontSize:'0.5rem',
    whiteSpace: 'nowrap',
    borderStyle: 'solid', borderColor: 'border.dark', borderWidth: 0, borderBottomWidth: '1px',
    "&:not(:nth-of-type(2))":{
      width:0,
    },
    ":first-of-type":{
      position: 'sticky',
      left: 0,
      zIndex: 1,
    }
  },
  "th":{
    textAlign:"center",
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: 'border.dark',
    borderBottomWidth: '1px',
    bgcolor:'table.dark',
  },
  "tbody tr":{
    "&.MuiTableRow-hover:hover":{
      "td":{
        bgcolor:'table.light',
      },
    },
    "&,.MuiTableRow-hover:not(:hover)":{
      "td":{
        bgcolor:'table.main',
      },
      ":nth-of-type(even)":{
        "td":{
          bgcolor:'table.dark',
        },
      },
    },
  },
  "td:not(:first-of-type)":{
    width:0, textAlign:'right',
  },
  [theme.breakpoints.down('tablet')]:{
  },
  "label": {
    fontWeight: 700,
  }
};

export const sxStylelessTable:SxProps = {
  // px: 2, py: '2px',
  bgcolor:'transparent',
  tableLayout:"auto",
  "th,td":{
    px:'12px', py: '6px',
    whiteSpace: 'nowrap',
    borderStyle: 'solid', borderColor: 'border.dark', borderWidth: 0, 
    "&:not(:nth-of-type(2))":{
      width:0,
    },
  },
  "td":{
    fontSize: '0.875rem',
  },
  "th":{
    fontSize: '0.75rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    // bgcolor:'table.dark',
  },
  "td,tr:last-of-type th":{
    // borderBottomWidth: '1px',
  },
  "tbody":{
    zIndex: 1,
    "tr":{
      ".MuiTableRow-hover:hover":{
        "td":{
          // bgcolor:'table.light',
        },
      },
      ":not(:hover)":{
        "td":{
          // bgcolor:'table.main',
        },
        ":nth-of-type(even)":{
          "td":{
            // bgcolor:'table.dark',
          },
        },
      },
    },
    "tr.expandedChild:not(:hover)":{
      // bgcolor: 'table.dark'
    },
    "&+thead th": {
      paddingTop: '40px',
    },
  },
  ".MuiTablePagination-spacer":{
    flex:0,
  },
  ".MuiButton-containedPrimary":{
    // bgcolor: "primary.light",
    fontWeight: 600,
  },
  // TODO FIXME or implement new wrap around table style
  // [theme.breakpoints.down('tablet')]:{
  //   p: 0,
  //   mx: '-4px',
  //   width: 'calc(100% + 8px)',
  //   "thead":{
  //     display: 'none', visibility:'hidden', opacity: 0,
  //   },
  //   "tr":{
  //     display:"flex", flexDirection:"row", flexWrap:'wrap',
  //     bgcolor: 'table.main', marginBottom: '1rem',
  //   },
  //   "td":{
  //     "&:nth-of-type(n)":{
  //       // textAlign: 'left',
  //       fontSize: '0.875rem',
  //       width: 'auto', border:'none',
  //     },
  //   },
  //   "th,td":{
  //     bgcolor:'inherit',
  //   },
  // }
};

export const sxVerticalSeparator:SxProps = {
  ":is(td,th):is(td,th)":{
    borderRightColor: 'border.dark',
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
  },
};

export const sxActionButton: SxProps = {
  fontWeight: '400',
  fontSize: '0.875rem',
  textDecoration: 'underline',
  textUnderlineOffset: '3px'
}

export const sxBorderRight: SxProps = {
  borderRightWidth: '1px !important',
  borderRightStyle: 'solid',
  borderRightColor: 'border.dark'
}

export const sxListOuter:SxProps = {
  '.MuiTableContainer-root':{
    overflowX: 'auto',
  },
  '.MuiTableHead-root':{
    position:'sticky',
    top: 0,
    zIndex: 2,
  },
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'border.dark',
  borderRadius: '4px'
}
