export const messages = {
  jsx: (message:string)=>{
    return message.split('\n').map((l,i)=><p key={i}>{l}</p>);
  },
  UNKNOWN_ERROR: 'Something went wrong!',
  SESSION_EXPIRED_RETRY: 'Your session has expired, attempting to reconnect...',
  SESSION_EXPIRED: 'Your session has expired and need to login again.',
  SESSION_EXPIRED_RECONNECTED: 'Please retry your last action as your session was re-established.',
  TRANSFER_FROM_CHAIN_TO_INFINITY_NATIVE_HINT: <>Please click Transfer and confirm the Deposit transaction.</>,
  TRANSFER_FROM_CHAIN_TO_INFINITY_HINT: <>
    <p>This is a 2-step process:</p>
    <ol><li>Review the summary above and click or Tap ‘Confirm Transfer' to approve the token transfer</li>
    <li>Confirm the deposit transaction with your wallet</li></ol>
    <p>Following the above confirmations, the remaining process will take 10-15 blocks to see your proceeds on Infinity. Note that refereshing this page between the above steps may result in a failed transfer.</p>
  </>,
  TRANSFER_FROM_FAUCET_HINT: <>
    <p>This is a 2-step process:</p>
    <ol><li>Review the summary above and click or Tap ‘Confirm Transfer to approve the token transfer</li>
    <li>Confirm the deposit transaction with your wallet</li></ol>
    <p>Following the above confirmations, the remaining process will take 10-15 blocks to see your proceeds on chain. Note that refereshing this page between the above steps may result in a failed transfer.</p>
  </>,
  TRANSFER_COMPLETE: `Transfer Complete!`,
  TRANSFER_CHAIN_COMPLETE_LABEL: `Transfer Complete`,
  TRANSFER_CHAIN_COMPLETE_NOTE: `It will take 10-15 blocks for the transferred amount to be updated on Infinity.`,
  TRANSFER_CHAIN_FAUCET_COMPLETE: `Transfer Complete`,
  TRANSFER_WITHDRAWL_LABEL: `Withdrawal request sent`,
  TRANSFER_WITHDRAWL_NOTE: `It will take 10-15 blocks for the transferred amount to be updated in your wallet.`,
  TRANSFER_ERROR_LABEL: `Transfer Failed`,
  TRANSFER_ERROR_NOTE: `Something went wrong while processing your transaction. Please try again, or contact support if the issue persists.`,
  SIGNOUT_SUCCESS: `Successfully logged out!`,
  SIGNIN_SUCCESS: `Successfully logged in!`,
  SIGNIN_SIGNDATA: `Please sign the message in your wallet app to verify account ownership.`,
}