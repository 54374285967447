// TODO add zod typeguard
import { useMemo } from 'react';
import { UseQueryResult, useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import Decimal from 'decimal.js';
import _ from 'lodash';
import moment from 'moment';
import { AuthContextType } from 'src/AuthProvider';
import useQueryAsPromise from "src/hooks/useQueryAsPromise";
import { z } from "zod";
import { AggregatedMarketPosition, AggregatedMarketPositionSchema, BaseMarket, BaseRateOrder, BaseRateOrderSchema, RateOrderWSSchema, CancelledOrderResponseItem, EMarketCategory, EOrderSide, ERateOrderType, ExternalMarketInfo, ExternalMarketInfoSchema, GroupedPosition, Market, MarketData, MarketDataSummary, MarketFixedRateData, MarketFixedRateSchema, MarketHistoricalRates, MarketHistoryItem, MarketHistoryTVItem, MarketHistoryTVLItem, MarketInfo, MarketInfoSchema, MarketPastRates, MarketSchema, MixedRateOrder, OrderBook, OrderBookEntry, OrderBookEntryWithDepth, OrderBookSchema, OrderBookSchemaA, OrderRateBucket, OrderRateBucketSchema, RateOrderBook, RateOrderSchema, RatePosition, RateTransaction, RateTransactionSchema, Token, FloatRateOrderData } from '../../types';
import { ExternalProtocolsHelper, TMarketExternalProtocol } from "../ExternalProtocolsHelper";
import { getMarketWsSymbolCode, sortBy } from "../common";
import { useWebsocketWithContext } from "./WebsocketContext";
import { get, post, postFD, streamWait, zp } from './common';
import { TRADE_HISTORY_TABLE_PAGE_LIMIT } from "src/constants/app";

/**
 * fetch all active markets
 */
export function useMarkets({marketIds,tokenId}:{marketIds?:number[],tokenId?:number}={}) {
  const ret = useQuery(['market',{category:'all',action:'list'}],async()=>await _fetchMarkets(),{});
  let data = ret.data;
  if(marketIds&&data){ data = data.filter(m=>marketIds.includes(m.marketId)); }
  if(tokenId&&data){ data = data.filter(m=>tokenId===m.tokenId); }
  return {...ret,data} as UseQueryResult<BaseMarket[],unknown>;
}
async function _fetchMarkets():Promise<Array<BaseMarket>> {
  const { data:marketsData } = await get(`/api/p/rate/markets/all`);
  const { data:tokensData } = await get(`/api/p/tokens`);
  const { markets }:{ markets:Array<BaseMarket> } = marketsData;
  const { tokens }:{ tokens:Token[] } = tokensData;
  const ret:Array<BaseMarket> = [];
  markets.forEach((m)=>{
    const token = tokens.find(t=>t.tokenId===m.tokenId);
    if(!token) return;
    token.price = token.price||'0';
    if(m.daysToMaturity===undefined){
      m.subscriptions = m.subscriptions||'0';
      m.lendLpFeeRate = m.lendLpFeeRate||'0';
      m.borrowLpFeeRate = m.borrowLpFeeRate||'0';
      m.deposits = m.deposits||0;
      m.borrows = m.borrows||0;
      m.lpDeposit = new Decimal(m.subscriptions).mul(new Decimal(m.lendLpFeeRate)).div(new Decimal(2)).div(new Decimal(m.deposits||0)).toString();
      m.lpBorrower = new Decimal(m.subscriptions).mul(new Decimal(m.borrowLpFeeRate)).div(new Decimal(2)).div(new Decimal(m.borrows||0)).toString();
      m.referenceMarketSize = new Decimal(m.deposits||0).add(new Decimal(m.borrows||0)).toString();
      const market = zp(MarketSchema,{...m,token,category:EMarketCategory.SPOT});
      if(market){
        ret.push(market);
      }
    }else{
      const market = zp(MarketFixedRateSchema,{...m,token,category:EMarketCategory.FIXED});
      if(market){
        ret.push(market);
      }
    }
  });
  return ret;
}
/* for tradingview chart */
export async function fetchMarkets():Promise<Array<BaseMarket>> {
  return await _fetchMarkets();
}

/**
 * fetch rate markets with tokens
 * */
export function useMarket({tokenId,marketId}:{tokenId?:number,marketId?:number}) {
  const ret = useMarkets();
  let data:BaseMarket|undefined;
  if(ret.data){ data = ret.data.find(m=>{
    if(marketId!==undefined) return marketId===m.marketId;
    if(tokenId!==undefined) return tokenId===m.tokenId;
    return false;
  }) as Market||undefined; }
  return {...ret,data} as UseQueryResult<BaseMarket, unknown>;
}
export function useRateMarkets() {
  const ret = useMarkets();
  let data:Market[]|undefined;
  if(ret.data){ data = ret.data.filter(m=>m.daysToMaturity===undefined).map(m=>(m as Market)); }
  return {...ret,data} as UseQueryResult<Market[], unknown>;
}

/**
 * fetch market info from websocket
 */
export function useMarketInfo({market}:{market?:BaseMarket}){
  const queryKey = ['market',{category:`${market?.maturityDate===undefined?'floating':'fixed'}`,action:'marketInfo',marketId:market?.marketId}];
  const symbol = getMarketWsSymbolCode(market);
  const eventType = 'marketInfo';
  // const api = market&&`/api/p/${market.maturityDate==undefined?'rate':'fixed_rate'}/market/${market.marketId}/info`;
  const api = useQueryAsPromise({ useQuery:useMarkets, resolver: (markets)=>{
      return zp(MarketInfoSchema,markets?.find(m=>m.marketId===market?.marketId),false,true) as MarketInfo|undefined;
    },
  });
  const queryClient = useQueryClient();
  return useWebsocketWithContext<MarketInfo>({
    api, queryKey, symbol, eventType, receive:(payload) => {
      // also invalidate yield curve data for graph update
      market&&queryClient.invalidateQueries(['market',{category:'fixed',action:'yieldCurve',tokenId:market.tokenId}]);
      const marketInfo = zp(MarketInfoSchema,payload.data.marketInfo,false,true) as MarketInfo|undefined;
      // deep compare to prevent unneccesary rerendering - WS keeps sending identical data 
      if(_.isEqual(marketInfo,queryClient.getQueryData(queryKey))) return undefined;
      return marketInfo;
    },
  });
}

/**
 * fetch market history (ir & fr)
 * */
export function useMarketsHistoricalRates({
  tokenIds=[],frMarketIds=[],daysToInclude=30,
}:{
  tokenIds?:number[],frMarketIds?:number[],daysToInclude?:number,
}) {
  const queries = tokenIds.map(tokenId=>{
    return {queryKey: ['market',{category:'fixed',action:'historicalRates',tokenId}], queryFn:async()=>await _fetchMarketHistoricalRates({tokenId,frMarketIds,daysToInclude}),
      enabled:tokenId!=undefined
    };
  });
  return useQueries({queries}).reduce((ret,data,i)=>{
    if(data.data) ret.data = {...ret.data,[tokenIds[i]]:data.data};
    ret.isError = ret.isError||data.isError;
    ret.isLoading = ret.isLoading||data.isLoading;
    ret.isFetching = ret.isFetching||data.isFetching;
    return ret;
  },{data:{} as {[key:number]:MarketHistoricalRates},isFetching:false,isLoading:false,isError:false});
}
export function useMarketHistoricalRates({
  tokenId,frMarketIds=[],daysToInclude,
}:{
  tokenId:number,frMarketIds?:number[],daysToInclude:number,
}) {
 return useQuery(['market',{category:'all',action:'historicalRates',tokenId}],async()=>await _fetchMarketHistoricalRates({tokenId,frMarketIds,daysToInclude}),{});
}
async function _fetchMarketHistoricalRates({
  tokenId,frMarketIds=[],daysToInclude,
}:{
  tokenId:number,frMarketIds?:number[],daysToInclude:number,
}):Promise<MarketHistoricalRates> {
  const { data } = await get(`/api/p/rate/market/historicalRates`,{
    tokenId, frMarketIds:frMarketIds.join(',')||undefined, daysToInclude,
  });
  return data;
};
/**
 * fetch market history (ir & fr)
 * */
export function useMarketsPastRatesByTokens({frMarketIds=[], tokenIds=[]}:{frMarketIds?:number[],tokenIds?:number[]}) {
  const queries = tokenIds.map(tokenId=>({
    queryKey:['market',{category:'all',action:'listPastRates',tokenId,frMarketIds}],
    queryFn:async()=>await _fetchMarketsPastRates({frMarketIds, tokenId}),
    staleTime: 1000*60*60, keepPreviousData: true, enabled:tokenId!=undefined,
  }));
  return useQueries({queries}).reduce((ret,data,i)=>{
    if(data.data) {
      Object.keys(data.data).map((tokenId)=>{
        ret.data = {...ret.data,[tokenIds[i]]:data.data[tokenId]};
      })
    }
    ret.isError = ret.isError||data.isError;
    ret.isLoading = ret.isLoading||data.isLoading;
    ret.isFetching = ret.isFetching||data.isFetching;
    return ret;
  },{data:{} as MarketPastRates,isFetching:false,isLoading:false,isError:false});
}
export function useMarketsPastRates({frMarketIds=[], tokenId}:{frMarketIds?:number[],tokenId?:number}) {
  const queryKey = ['market',{category:'all',action:'listPastRates',tokenId,frMarketIds}];
  const queryFn = async()=>await _fetchMarketsPastRates({frMarketIds, tokenId});
  return useQuery(queryKey,queryFn,{ 
    staleTime: 1000*60*60, keepPreviousData: true, 
    enabled:tokenId!==undefined&&frMarketIds.length!==0, // enable ONLY when both tokenId & frMarketIds are present
  });
}
async function _fetchMarketsPastRates({frMarketIds=[], tokenId}:{frMarketIds?:number[],tokenId?:number}):Promise<MarketPastRates> {
  const { data } = await get(`/api/p/rate/markets/past/rates`,{
    frMarketIds: frMarketIds.join(',')||undefined,
    tokenId
  });
  return data;
};
/**
 * fetch market history for rates 
 * [Return] (Content-Type: x-ndjson) (Refers to: https://nurkiewicz.com/2021/08/json-streaming-in-webflux.html)
 * */
export function useMarketHistory({market,marketId,start,end,interval}:{market?:Market,marketId?:number|string,start:number,end:number,interval?:number}) {
  return useQuery(['market',{category:'floating',action:'history',market,marketId,start,end,interval}],async()=>await _fetchMarketHistory({market,marketId,start,end,interval}),{"keepPreviousData":true,"refetchInterval":60000});
}
/* for tradingview chart */
export function fetchMarketHistory({market,marketId,start,end,interval}:{market?:Market,marketId?:number|string,start:number,end:number,interval?:number}) {
  return _fetchMarketHistory({market,marketId,start,end,interval});
}
async function _fetchMarketHistory({market,marketId,start,end,interval}:{market?:Market,marketId?:number|string,start:number,end:number,interval?:number}):Promise<MarketHistoryItem[]>/*<{
  ts: number,
  lastBlock: number,
  chain: number,
  coins: Coin[],
}>*/ {
  if(market==undefined&&marketId==undefined) return [];
  if(start<1e12) start*= 1000;
  if(end<1e12) end*= 1000;
  if(interval==undefined){
    interval = 15*60*1000; // 15 mins;
  }
  const { data } = await streamWait(`/api/p/floating_rate/market/${marketId}/history`, {
    start,end,interval
  });
  const { marketHistory } = data;
  if(market&&marketHistory){
    // add current date graph bar data since market history only pulls up to yesterday
    if(market.rate!==undefined){
      marketHistory.push({
        // dont care about these values - not used by graph
        open: '0', close: '0', low: '0', volume: '0', totalValue: '0',
        // graph data
        high: new Decimal(market.rate).mul(2).toString(), lendDepth: market.deposits, borrowDepth: market.borrows, date: Date.now(),
      });
    }
  }
  return marketHistory;
}

/* recent trxs for market trades table */
export function useMarketTransactions({market,limit=100}:{market?:BaseMarket,limit?:number}) {
  const queryKey = ['market',{category:`${market?.maturityDate===undefined?'floating':'fixed'}`,action:'listTransactions',marketId:market?.marketId,limit}]
  const symbol = getMarketWsSymbolCode(market);
  const eventType = 'recentTrades';
  const api = market&&`/api/p/${market.maturityDate==undefined?'floating_rate':'fixed_rate'}/market/${market.marketId}/trxs/recent?limit=${limit}`;
  return useWebsocketWithContext<RateTransaction[]>({
    api, queryKey, symbol, eventType, arrayLimit:limit, receive:(payload) => {
      if(payload.data.trxs){
        return zp(z.array(RateTransactionSchema),payload.data.trxs,false,true);
      }
      if(payload.data.recentTrades){
        return zp(z.array(RateTransactionSchema),payload.data.recentTrades,false,true);
      }
    }, arraySorter: array=>sortBy(array,'date',true) // sort by date descending
  });
}

/**
 * fetch rates order book list
 * */
export function useRateOrderBook(market?: BaseMarket, limit=30, marketInfo?: any) {
  const queryKey = ['market',{category:`${market?.maturityDate===undefined?'floating':'fixed'}`,action:'orderBook',marketId:market?.marketId,limit}];
  const symbol = getMarketWsSymbolCode(market);
  const eventType = 'orderBook';
  const api = market&&`/api/p/${market.maturityDate==undefined?'floating_rate':'fixed_rate'}/market/${market.marketId}/orderBook?limit=${limit+1}`;
  return useWebsocketWithContext<RateOrderBook>({
    api, queryKey, symbol, eventType, receive:(payload) => {
      if(payload.data?.orderBook){
        return _parseOrderBookPayload({market:market!,orderBook:zp(OrderBookSchemaA,zp(OrderBookSchema,payload.data.orderBook)), marketInfo});
      }else{
        return _parseOrderBookPayload({market:market!,orderBook:zp(OrderBookSchemaA,payload.data), marketInfo });
      }
    },
  });
}
function _parseOrderBookPayload({
  market, orderBook, limit=10, marketInfo
}:{
  market: BaseMarket, orderBook?:OrderBook, limit?: number, marketInfo?: any
}):RateOrderBook {
  const marketRate = new Decimal(market?.midRate ?? market?.rate ?? 0).mul(100);
  const parseEntryToWithDepth = (bidOrAsk:OrderBookEntry)=>{
    const { rate, quantity } = bidOrAsk;
    const step = new Decimal(bidOrAsk.rate).mul(100).sub(marketRate).toString();
    return {
      rate, quantity, step
    }
  };
  const calculateDepth = (depth:Decimal,bidOrAsk:OrderBookEntryWithDepth)=>{
    if(bidOrAsk.side==EOrderSide.BORROW){ // borrows
      depth = depth.add(new Decimal(bidOrAsk.quantity));
      bidOrAsk.borrowDepth = depth.toString();
    }
    if(bidOrAsk.side==EOrderSide.LEND){ // lends
      depth = depth.add(new Decimal(bidOrAsk.quantity));
      bidOrAsk.lendDepth = depth.toString();
    }
    return depth;
  };
  const calculateSum = (sum:Decimal,bidOrAsk:OrderBookEntryWithDepth)=>{
    return sum.add(new Decimal(bidOrAsk.quantity));
  };
  const findMax = (max:Decimal,key:'quantity'|'rate',bidOrAsk:OrderBookEntryWithDepth)=>{
    const q = new Decimal(bidOrAsk[key]);
    return max.gt(q)?max:q;
  };
  const findMin = (min:Decimal,key:'quantity'|'rate',bidOrAsk:OrderBookEntryWithDepth)=>{
    const q = new Decimal(bidOrAsk[key]);
    return min.lt(q)?min:q;
  };
  const bids:OrderBookEntryWithDepth[] = (orderBook?.bids||[]).map(parseEntryToWithDepth).map((bid:any)=>({...bid,side:EOrderSide.BORROW}));
  const asks:OrderBookEntryWithDepth[] = (orderBook?.asks||[]).map(parseEntryToWithDepth).map((ask:any)=>({...ask,side:EOrderSide.LEND}));
  // bids: market rate to negative -> ascending
  bids.sort((a,b)=>new Decimal(a.step).lt(new Decimal(b.step))?-1:new Decimal(a.step).gt(new Decimal(b.step))?1:0).reverse().reduce(calculateDepth,new Decimal(0));
  const bidSum = bids.reduce(calculateSum,new Decimal(0)).toString();
  const bidMax = bids.reduce((max,bidOrAsk)=>findMax(max,'quantity',bidOrAsk),new Decimal(0)).toString();
  const bidMaxRate = bids.length>0?bids.reduce((max,bidOrAsk)=>findMax(max,'rate',bidOrAsk),new Decimal(0)).toString():undefined;
  // asks: market rate to positive -> descending
  asks.sort((a,b)=>new Decimal(a.step).lt(new Decimal(b.step))?1:new Decimal(a.step).gt(new Decimal(b.step))?-1:0).reverse().reduce(calculateDepth,new Decimal(0));
  const askSum = asks.reduce(calculateSum,new Decimal(0)).toString();
  const askMax = asks.reduce((max,bidOrAsk)=>findMax(max,'quantity',bidOrAsk),new Decimal(0)).toString();
  const askMinRate = asks.length>0?asks.reduce((min,bidOrAsk)=>findMin(min,'rate',bidOrAsk),new Decimal(Number.MAX_VALUE)).toString():undefined;
  // map to merge +-0%
  const depthMap = {} as Map<string,OrderBookEntryWithDepth>;
  const parseToMap = (bidOrAsk:OrderBookEntryWithDepth)=>{
    const key = bidOrAsk.step.toString();
    const entry = depthMap[key];
    const lendDepth = bidOrAsk.lendDepth||entry?.lendDepth;
    const borrowDepth = bidOrAsk.borrowDepth||entry?.borrowDepth;
    depthMap[key] = {...bidOrAsk,lendDepth,borrowDepth};
  };
  bids.map(parseToMap);
  asks.map(parseToMap);
  const depths = Object.keys(depthMap).map(key=>depthMap[key]).sort((a,b)=>new Decimal(a.step).lt(new Decimal(b.step))?1:new Decimal(a.step).gt(new Decimal(b.step))?-1:0);
  return {asks,bids,depths,bidSum,askSum,bidMax,askMax,bidMaxRate,askMinRate};
}
/**
 * place order
 * */
export async function postRateOrder(auth:AuthContextType,rateOrderParams:IRateOrderParams):Promise<BaseRateOrder|undefined> {
  if(rateOrderParams.orderType==2&&rateOrderParams.rate==undefined){
    throw new Error('Rate needed for limit order');
  }
  const { data } = await post(`/api/floating_rate/order`, rateOrderParams, auth);
  const { order } = data;
  return zp(BaseRateOrderSchema,{...order,orderId:order?.orderId||-1});
}

export interface IRateOrderParams {
  marketId: number;
  accountId: number;
  side: EOrderSide; 
  orderType: ERateOrderType;
  quantity: string; 
  rate?: string; //   Only required for limit order
  clientOrderId: string; //  Random string to prevent duplicate order
};

export async function cancelRateOrder(auth:AuthContextType,{orderId}:{orderId:number}):Promise<boolean> {
  await postFD(`/api/floating_rate/order/cancel`, {orderId}, auth);
  // throw on error
  return true;
}

export async function cancelBatchRateOrder(auth:AuthContextType,{ orderIds, accountId } : { orderIds: number[], accountId: number }): Promise<CancelledOrderResponseItem[]> {
  const result = await post(`/api/floating_rate/order/batch/cancel?accountId=${accountId}`, { orderIds }, auth);
  if (result?.success) {
    return result?.data?.cancelOrders ?? []
  } else {
    throw new Error('Batch cancel error')
  }
}

export async function cancelBatchOrder(auth:AuthContextType,{ orderIds, accountId, instrumentId } : { orderIds: number[], accountId: number, instrumentId: string }): Promise<CancelledOrderResponseItem[]> {
  const result = await post(`/api/trade/cancel-batch-orders`, { accountId, orderIds, instrumentId }, auth);
  if (result?.success) {
    return result?.data?.orders ?? []
  } else {
    throw new Error('Batch cancel error')
  }
}

 // user rate market (both fr & ir)
/**
 * fetch market data for both
 * */
export function useMarketDataByToken({tokenId,frMarketIds=[]}:{tokenId:number,frMarketIds?:number[]}) {
  return useQuery(['market',{category:'all',action:'marketData',tokenId}],async()=>await _fetchMarketDataByToken({tokenId,frMarketIds}),{
  });
}
async function _fetchMarketDataByToken({tokenId,frMarketIds=[]}:{tokenId:number,frMarketIds?:number[]}):Promise<{ir:MarketData,fr:MarketFixedRateData[]}> {
  const { data } = await get(`/api/p/rate/markets/dashboard`,{
    tokenId, frMarketIds:frMarketIds.join(',')||undefined,
  });
  const { ir,fr } = data;
  return {ir,fr};
};
/**
 * fetch user market data for both
 * */
export function useUserMarketDataByToken(auth:AuthContextType,{tokenId,frMarketIds=[]}:{tokenId?:number,frMarketIds?:number[]}) {
  return useQuery(['user',{category:'all',action:'marketData',tokenId,user:auth.user}],async()=>await _fetchUserMarketDataByToken(auth,{tokenId:tokenId!,frMarketIds}),{
    enabled:!!auth.user&&!!auth.accessToken?.token &&tokenId!=undefined,
  });
}
async function _fetchUserMarketDataByToken(auth:AuthContextType,{tokenId,frMarketIds=[]}:{tokenId:number,frMarketIds?:number[]}):Promise<{summary:MarketDataSummary,ir:MarketData,fr:MarketFixedRateData[]}> {
  const { data } = await get(`/api/user/rate/markets/dashboard`,{
    tokenId, frMarketIds:frMarketIds.join(',')||undefined,
  },auth);
  const { summary,ir,fr } = data;
  return {summary,ir,fr};
};

 // user rate market 
/**
 * fetch rate position for user
 * */
export function useUserRatePositions(auth:AuthContextType,{
  accountId, marketId
}:{
  accountId?:number, marketId?:number
}={}) {
  return useQuery(['user',{category:'all',action:'ratePositions',accountId,marketId,user:auth.user,}],async()=>await _fetchUserRatePositions(auth,{accountId,marketId}),{
    enabled:!!auth.user&&!!auth.accessToken?.token
  });
}
async function _fetchUserRatePositions(auth:AuthContextType,{
  accountId, marketId
}:{
  accountId?:number, marketId?:number
}={}):Promise<RatePosition[]> {
  const { data } = await get(`/api/user/floating_rate/positions`,{
    accountId, marketId
  },auth);
  const { positions } = data;
  return (positions||[]) as RatePosition[];
};

/**
 * fetch both float and fixed positions
 * should sync cache time with user.useUserAccounts, user.useUserAccountPerformanceLive, rate.useUserAllPositions
 * @param auth 
 * @param param1 
 * @returns 
 */
export function useUserAllPositions(auth:AuthContextType,{
  accountId
}:{
  accountId?:number
}={}) {
  return useQuery(['market',{category:'all',action:'allPositions',accountId}],async()=>await _fetchUserAllPositions(auth,{accountId}),{
    enabled:!!auth.user&&!!auth.accessToken?.token,
  });
}
async function _fetchUserAllPositions(auth:AuthContextType,{
  accountId
}:{
  accountId?:number
}={}) {
  const { data } = await get(`/api/user/rate/positions/all`,{
    accountId
  },auth);
  const { accountPositions=[] }:{accountPositions:AggregatedMarketPosition[]} = data;
  const positionsByTokenId = new Map<number,AggregatedMarketPosition[]>();
  // add positions to map
  accountPositions.forEach(r=>{
    const position = zp<AggregatedMarketPosition>(AggregatedMarketPositionSchema,r);
    if(!position || position.accountId!==accountId) return;
    const list = positionsByTokenId.get(position.tokenId)||[];
    positionsByTokenId.set(position.tokenId,[...list,position]);
  });
  // calculate grouped row
  return Array.from(positionsByTokenId.entries()).map(([tokenId,positions])=>{
    let quantity = new Decimal(0);
    let interest = new Decimal(0);
    let mtm = new Decimal(0);
    let pv = new Decimal(0);
    let dv01 = new Decimal(0);
    let price = "0";
    positions.forEach(position=>{
      quantity = quantity.add(new Decimal(position.quantity));
      if(position.interest!==undefined) interest = interest.add(new Decimal(position.interest));
      if(position.mtm!==undefined) mtm = mtm.add(new Decimal(position.mtm));
      if(position.pv!==undefined) pv = pv.add(new Decimal(position.pv));
      if(position.dv01!==undefined) dv01 = dv01.add(new Decimal(position.dv01));
      price = position.price;
    });
    return {
      tokenId,
      quantity:quantity.toString(),
      interest:interest.toString(),
      mtm:mtm.toString(),
      pv:pv.toString(),
      dv01:dv01.toString(),
      price,
      positions,
    } as GroupedPosition;
  });
};

/**
 * fetch transactions for user
 * */
async function fetchUserTransactions(auth:AuthContextType,{
  accountId, marketId, startId, limit=20
}:{
  accountId:number, marketId:number, startId?:number, limit?:number
 }):Promise<RateTransaction[]> {
  const { data } = await get(`/api/user/floating_rate/trxs`,{
    accountId, marketId, startId, limit
  },auth);
  const { trxs } = data;
  return (trxs||[]) as RateTransaction[];
};

/**
 * fetch transactions for user
 * */
export function useUserRateOrders(auth:AuthContextType,{
  accountId, marketId, pending, done, startId, limit, disabled, 
}:{
  accountId?:number, marketId?:number, pending?:boolean, done?:boolean, startId?:number, limit?:number, disabled?:boolean, 
}={}) {
  return useQuery(['user',{category:'floating',action:'rateOrders',user:auth.user,accountId, marketId, pending, done, startId, limit}],async()=>await _fetchUserRateOrders(auth,{accountId, marketId, pending, done, startId, limit}),{
    keepPreviousData: true,
    enabled:!disabled&&!!auth.user&&accountId!=undefined
  });
}
async function _fetchUserRateOrders(auth:AuthContextType,{
  accountId, marketId, pending, done, startId, limit=20  // pending/done = provide to exclusive show status [true:==, false:!=] to pending/done
}:{
  accountId?:number, marketId?:number, pending?:boolean, done?:boolean, startId?:number, limit?:number
}) {
  const { data } = await get(`/api/user/floating_rate/orders`,{
    accountId, marketId, pending, done, startId, limit:limit+1
  },auth);
  const orders = zp(z.array(RateOrderSchema),data.orders);
  return orders&&{ orders:orders.slice(0,limit), hasNextPage:orders.length>limit, lastOrderId:orders.at(orders.length-1)?.orderId };

};

export function  useRealTimeUserRateOrders(auth:AuthContextType, {
  accountId, market, pending, done, startId, limit, disabled, 
} : {
  accountId?:number, market?:BaseMarket, pending?:boolean, done?:boolean, startId?:number, limit?:number, disabled?:boolean, 
}={}) {
  if (disabled) return { isInitialLoading: false, isFetching: false, data: undefined }
  return _fetchRealTimeUserRateOrders(auth, { accountId, market, pending, done, startId, limit })
}

function _fetchRealTimeUserRateOrders(auth:AuthContextType, {
  accountId, market, pending, done, startId, limit = TRADE_HISTORY_TABLE_PAGE_LIMIT, 
} : {
  accountId?:number, market?:BaseMarket, pending?:boolean, done?:boolean, startId?:number, limit?:number 
}={}) {
  const queryKey = useMemo(() => {
    return ['user',{category:'floating',action:'liveRateOrders',userId:auth?.user?.userId,accountId, marketId: market?.marketId, pending, done, startId, limit}];
  }, [accountId, auth?.user?.userId, done, limit, market?.marketId, pending, startId]);
  const symbol = getMarketWsSymbolCode(market);
  const eventType = 'userOrder';
  const arrayLimit = limit
  const api = (accountId !== undefined && market?.marketId !== undefined) ? `/api/user/floating_rate/orders?accountId=${accountId}&marketId=${market?.marketId}&pending=${!!pending}&done=${!!done}&limit=${limit + 1}${ startId !== undefined ? `&startId=${startId}` : ''}` : undefined

  return useWebsocketWithContext<FloatRateOrderData>({
    api, queryKey, symbol, eventType, arrayLimit, receive:(payload) => {
      if (payload?.data?.orders) {
        const orders = zp(z.array(RateOrderSchema), payload.data.orders);
        return orders&&{ type: 'initial', orders:orders.slice(0,limit), hasNextPage:orders.length>limit, lastOrderId:orders.at(orders.length-1)?.orderId };
      } else {
        const order = zp(RateOrderSchema,zp(RateOrderWSSchema, payload.data.userOrder));
        return order && { type: 'update', orders: [order]}
      }
      
    },
  });
}

/**
 * fetch user rate bucket for both
 * */
export function useUserMarketRateBuckets(auth:AuthContextType,{irMarketId,frMarketIds,accountId}:{irMarketId?:number,frMarketIds?:number[],accountId?:number}) {
  return useQuery(['user',{category:'all',action:'rateBuckets',irMarketId,frMarketIds,user:auth.user}],async()=>await _fetchUserMarketRateBuckets(auth,{irMarketId,frMarketIds,accountId}),{
    enabled:!!auth.user&&!!auth.accessToken?.token&&irMarketId!=undefined&&frMarketIds!=undefined&&frMarketIds.length>0&&accountId!=undefined
  });
}
async function _fetchUserMarketRateBuckets(auth:AuthContextType,{irMarketId,frMarketIds,accountId}:{irMarketId?:number,frMarketIds?:number[],accountId?:number}):Promise<OrderRateBucket[]> {
  const { data } = await get(`/api/user/rate/orders/rateBuckets`,{
    irMarketId, frMarketIds:frMarketIds?.join(',')||undefined, accountId
  },auth);
  const { ir,fr } = data;
  return [...(ir||[]),...(fr||[])];
};
/**
 * fetch public rate bucket for both
 * */
export function useMarketRateBuckets({irMarketId,frMarketIds}:{irMarketId?:number,frMarketIds?:number[]}) {
  return useQuery(['market',{category:'all',action:'rateBuckets',irMarketId,frMarketIds}],async()=>await _fetchMarketRateBuckets({irMarketId,frMarketIds}),{
    enabled:irMarketId!=undefined&&frMarketIds!=undefined&&frMarketIds.length>0
  });
}
async function _fetchMarketRateBuckets({irMarketId,frMarketIds}:{irMarketId?:number,frMarketIds?:number[]}) {
  const marketIds = [];
  if(irMarketId) marketIds.push(irMarketId);
  if(frMarketIds) marketIds.push.apply(marketIds,frMarketIds);

  const { data } = await get(`/api/p/rate/orders/rateBuckets`,{
    marketIds: marketIds.join(',')
  });
  return zp<OrderRateBucket[]>(z.array(OrderRateBucketSchema),data.rateBuckets) ?? [];
};




/**
 * fetch order history (public) for both market types
 * @param auth 
 * @param param1 
 * @returns 
 */
export function useAllRateOrders({
  limit, asc, lastFloatingOrderId, lastFixedOrderId, userAddress,
}:{
  limit?:number, asc?:boolean, lastFloatingOrderId?:number, lastFixedOrderId?:number, userAddress?:string,
}={}) {
  return useQuery(['market',{type:'orders',action:'listAllOrders',limit,asc,lastFloatingOrderId,lastFixedOrderId,userAddress}],async()=>await _fetchAllRateOrders({limit, asc, lastFloatingOrderId, lastFixedOrderId, userAddress}),{
    keepPreviousData: true,
  });
}
async function _fetchAllRateOrders({
  limit, asc, lastFloatingOrderId, lastFixedOrderId, userAddress,
}:{
  limit?:number, asc?:boolean, lastFloatingOrderId?:number, lastFixedOrderId?:number, userAddress?:string,
}) {
  const { data } = await get(`/api/p/rate/allOrders`,{
    limit, asc, lastFloatingOrderId, lastFixedOrderId, userAddress
  });
  return data as { orders:MixedRateOrder[], hasNextPage:boolean, lastFloatingOrderId?:number, lastFixedOrderId?:number };
};


/**
 * 
 * @param param0 limit
 * @returns MarketHisotryTVLItem[]
 */
export function useHistoricalTotalValue({
  limit,
}:{
  limit?: number
}={}) {
  return useQuery(['market',{type:'totalValue',action:'listHistorical',limit}],async()=>await _fetchHistoricalTotalValue({limit}));
}
async function _fetchHistoricalTotalValue({
  limit,
}:{
  limit?: number
}) {
  const { data } = await get(`/api/p/rate/market/historical/totalValueUsd`,{
    limit
  });
  return data.data as MarketHistoryTVItem[];
};


/**
 * 
 * @param param0 limit
 * @returns MarketHisotryTVLItem[]
 */
export function useHistoricalTotalValueLocked({
  limit
}:{
  limit?:number
}) {
  return useQuery(['market',{type:'totalValueLocked',action:'listHistorical',limit}],async()=>await _fetchHistoricalTotalValueLocked({limit}));
}
async function _fetchHistoricalTotalValueLocked({
  limit
}:{
  limit?:number
}) {
  const startDate = moment().unix();
  const endDate = moment().subtract(limit,'d').unix();
  const { data } = await get(`/api/p/totalValueLocked/historical`,{
    startDate, endDate
  });
  return data.items as MarketHistoryTVLItem[];
};


/**
 * fetch external protocol rates from websocket 
 */
export function useExternalMarketInfo({market,externalProtocol}:{market?:BaseMarket,externalProtocol?:TMarketExternalProtocol}){
  const protocolId = ExternalProtocolsHelper.getId(externalProtocol);
  const queryKey = ['market',{category:'external',action:'rates',marketId:market?.marketId,externalProtocol}];
  const symbol = getMarketWsSymbolCode(market);
  const eventType = 'externalMarketInfo';
  const queryClient = useQueryClient();
  // no API - inital value from useMarketsPastRates()
  return useWebsocketWithContext<ExternalMarketInfo>({
    queryKey, symbol, eventType, receive:(payload) => {
      const marketInfo = zp(ExternalMarketInfoSchema,payload.data.externalMarketInfo,false,true) as ExternalMarketInfo|undefined;
      // match protocolId first
      if(marketInfo?.provider!==protocolId) return undefined;
      // deep compare to prevent unneccesary rerendering - WS keeps sending identical data 
      if(_.isEqual(marketInfo,queryClient.getQueryData(queryKey))) return undefined;
      return marketInfo;
    },
  });
}