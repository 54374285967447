import { Box } from '@mui/material';
import { useState } from 'react';
import { useAuth } from 'src/AuthProvider';
import { TradeHistoryTableByMarket } from 'src/Components/TradeHistoryTable'
import { styleGrid, styleHeader, stylePanelScrollable } from 'src/styles/GridBox';
import { sxTabButton, sxTabButtonActive } from 'src/styles/General';
import { GridComponentParams } from '.';
import DragGrabber from './DragGrabber';

function OrderHistory({market,token}:GridComponentParams){
  const auth = useAuth();
  const [tabType,setTabType] = useState<'open'|'history'>('open');
  return (
    <Box sx={styleGrid}>
      <Box sx={styleHeader}>
        <Box sx={tabType === 'open' ? sxTabButtonActive : sxTabButton} onClick={()=>setTabType('open')}>Open Orders</Box>
        <Box sx={tabType === 'history' ? sxTabButtonActive : sxTabButton} onClick={()=>setTabType('history')}>Order History</Box>
        <DragGrabber/>
      </Box>
      <Box sx={stylePanelScrollable}>
        {auth?.user && (
          <TradeHistoryTableByMarket token={token} selectedMarketId={market?.marketId} type={tabType}/>
        )}
      </Box>
    </Box>
  );
}

export default OrderHistory;