import { Box } from '@mui/material';
import RateBucketsGraph from 'src/Components/RateBucketsGraph';
import { styleGrid, styleHeader, stylePanelChartContainer } from 'src/styles/GridBox';
import { GridComponentParams } from '.';
import DragGrabber from './DragGrabber';
import { withGraphPosition } from './GraphPositionProvider';

const RateBucketsGraphWithGraphPosition = withGraphPosition(RateBucketsGraph);

function OrdersGraph({token}:GridComponentParams) {
  return (
    <Box sx={styleGrid}>
      <Box sx={styleHeader}>Orders<DragGrabber/></Box>
      <Box sx={stylePanelChartContainer}>
        <RateBucketsGraphWithGraphPosition token={token} type="userOrders"/>
      </Box>
    </Box>
  );
}

export default OrdersGraph;