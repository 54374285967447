import { useEffect, useState } from 'react';
import { useAuth } from '../AuthProvider';
import {
  EUserAccountType
} from '../types';
import apis from '../utils/apis';
import LiquidationMetricSection from './LiquidationMetricSection';
import RiskPositionsSection from './RiskPositionsSection';

function RiskOverviewPanel(){
  const auth = useAuth();
  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  const [ selectedAccountId, setSelectedAccountId ] = useState(getFirstTradingAccountId(userAccounts));
  useEffect(()=>{
    if(userAccounts&&selectedAccountId===undefined) setSelectedAccountId(getFirstTradingAccountId(userAccounts));
  },[userAccounts,selectedAccountId,setSelectedAccountId,getFirstTradingAccountId]);
  function handleClickAccount(event:React.MouseEvent){
    const accountId = parseInt(event.currentTarget.getAttribute('data-account-id')||`${getFirstTradingAccountId(userAccounts)}`);
    setSelectedAccountId(accountId==-1?undefined:accountId);
  }
  function getFirstTradingAccountId(_userAccounts=userAccounts){
    return _userAccounts?.filter(uw=>uw.type==EUserAccountType.TRADING).at(0)?.accountId;
  }
  return (
      <>
      {/* account picker */}
      {/* <Box sx={sxBox}>
        {userAccounts&&userAccounts.filter(userAccount=>userAccount.type==EUserAccountType.TRADING).map((userAccount, idx)=>(
          <Link key={idx} sx={
            Object.assign({},styleAccountTabLink,setSelectedAccountId==userAccount.accountId?styleAccountTabLinkActive:null)
          } data-account-id={userAccount.accountId} onClick={handleClickAccount}>{userAccount.name}</Link>
        ))}
      </Box> */}

      {/* My Risk Positions */}
      <RiskPositionsSection selectedAccountId={selectedAccountId}/>

      {/* Margin & Liquidation Metrics */}
      <LiquidationMetricSection selectedAccountId={selectedAccountId}/>

    </>
  );
}

export default RiskOverviewPanel;