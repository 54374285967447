import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Link, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { useQueryClient } from "@tanstack/react-query";
import Decimal from 'decimal.js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSnackbar from 'src/hooks/useSnackbar';
import { StyleConfirmBox, StyleConfirmBoxName, StyleConfirmRow, styleDialogButton, styleDialogPrimaryButton } from 'src/styles/Dialog';
import { sxTableBorder } from 'src/styles/General';
import { GeneralError, generateClientOrderId } from 'src/utils/common';
import { useAuth } from '../AuthProvider';
import { sxBorrowAmount, sxChildRow, sxEmptyRow, sxLabel, sxTokenTable, sxTokenTableContainer, sxTokenTableSubHeader } from '../styles/TokenTable';
import {
  AccountWalletTokenERC721,
  EMarketCategory,
  EOrderSide,
  ERateOrderType, ERateTwapOrderStatus, EUserAccountType,
  Market,
  RateLPPosition,
  RateLPPositionGroup,
  RatePosition, RateTwapOrder, Token,
  UserAccount
} from '../types';
import apis from '../utils/apis';
import { format } from '../utils/numbers';
import LoadingIcon from './Loading';
import StandardDialog from './StandardDialog';
import TokenAmountInput from './TokenAmountInput';
import TokenIcon from './TokenIcon';
import ValueTableCell from './ValueTableCell';

const styleAccountTabLink:SxProps = {
  cursor: 'pointer',
  marginRight: 1,
  opacity: .5,
  color: 'text.primary',
  textDecorationColor: 'inherit',
};
const styleAccountTabLinkActive:SxProps = {
  opacity: 1,
};

// TODO update to be grouped by token providers (infinity, basic, ...protocols)
enum EPositionTokenType { BASIC="Major Tokens", COMPLEX="Yield-Bearing Tokens"};
enum ECashPositionTokenType { ERC20, ERC721};
enum EPositionType {
  CASH, LP, RATE, TWAP
}
enum EPositionSide {
  LEND, BORROW
}
interface Position {
  tokenId: number,
  marketId: number,
  accountId: number,
  orderSize?: string,
  totalSize?: string,
  positionType: EPositionType,
  positionTokenType: EPositionTokenType,
  cashPositionTokenType: ECashPositionTokenType,
  price: string,
  side: EPositionSide,
  accruedInterest: string,
  mtm: string,
  tradeDate: number,
  long?: unknown,
  short?: unknown,
  completedPnct?: string,
  status?: string,
  rangeFrom?: string,
  rangeTo?: string,
  range?: string,
  ratePosition?: RatePosition,
  lpPositionGroup?: RateLPPositionGroup,
  lpPosition?: RateLPPosition,
  twapOrder?: RateTwapOrder
  matchedSize?: string,
  dv01?: string,
  action?: unknown,
  notes?: unknown,
  erc721TokenId?: string
}
interface HeadCell {
  disablePadding: boolean;
  id: keyof Position;
  label: string;
  align: 'left'|'center'|'right';
}
const headCells: readonly HeadCell[] = [
  {
    id: 'tokenId',
    align: 'left',
    disablePadding: false,
    label: 'Tokens',
  },
  // {
  //   id: 'positionType',
  //   align: 'left',
  //   disablePadding: false,
  //   label: 'Type',
  // },
  {
    id: 'long',
    align: 'left',
    disablePadding: false,
    label: 'Market',
  },
  {
    id: 'totalSize',
    align: 'left',
    disablePadding: false,
    label: 'Position',
  },
  {
    id: 'short',
    align: 'left',
    disablePadding: false,
    label: 'Rate or Price',
  },
  {
    id: 'accruedInterest',
    align: 'left',
    disablePadding: false,
    label: 'Accrued',
  },
  {
    id: 'mtm',
    align: 'left',
    disablePadding: false,
    label: 'MTM',
  },
  {
    id: 'dv01',
    align: 'left',
    disablePadding: false,
    label: 'DV01',
  },
  {
    id: 'completedPnct',
    align: 'left',
    disablePadding: false,
    label: '% Completed',
  },
  {
    id: 'status',
    align: 'left',
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'action',
    align: 'left',
    disablePadding: false,
    label: '',
  },
  {
    id: 'notes',
    align: 'left',
    disablePadding: false,
    label: 'Notes',
  },
];

interface UnwindPositionParams {
  position?: Position;
  market: Market;
  token: Token;
}

function LPPortfolioPanel(){
  const auth = useAuth();
  const snackbar = useSnackbar();
  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  const [ selectedAccountId, setSelectedAccountId ] = useState(getFirstTradingAccountId(userAccounts));
  function handleClickAccount(event:React.MouseEvent){
    const accountId = parseInt(event.currentTarget.getAttribute('data-account-id')||`${getFirstTradingAccountId(userAccounts)}`);
    setSelectedAccountId(accountId==-1?undefined:accountId);
  }
  function getFirstTradingAccountId(_userAccounts=userAccounts){
    return _userAccounts?.filter(uw=>uw.type==EUserAccountType.TRADING).at(0)?.accountId;
  }
  return (
    <Box>
      <Box sx={{px:2}}>
        {userAccounts&&userAccounts.filter(userAccount=>userAccount.type==EUserAccountType.TRADING).map((userAccount, idx)=>(
          <Link key={idx} sx={
            Object.assign({},styleAccountTabLink,selectedAccountId==userAccount.accountId?styleAccountTabLinkActive:null)
          } data-account-id={userAccount.accountId} onClick={handleClickAccount}>{userAccount.name}</Link>
        ))}
      </Box>
      <Box>
      {userAccounts?userAccounts.filter(
        userAccount=>userAccount.type==EUserAccountType.TRADING&&(selectedAccountId==undefined)||userAccount.accountId==selectedAccountId
      ).map((userAccount,idx)=>(
        <LPPortfolioAccountTable key={idx} account={userAccount}/>
      )):(<LoadingIcon curtain/>)}
      </Box>
    </Box>
  );
}

function LPPortfolioAccountTable({account}:{account:UserAccount}){
  const auth = useAuth();
  const [page, setPage] = useState({[EPositionTokenType.BASIC]:0,[EPositionTokenType.COMPLEX]:0});
  const pageLimit = 10;
  const { accountId, healthScore, maxBorrowInUsd, tokens:accountToken } = account;
  const {data:markets} = apis.rate.useRateMarkets();
  const {data:tokens} = apis.token.useTokens();
  const {data:lpPositionGroups,isLoading:isLoadingLpPositionGroups} = apis.lp.useUserLPPositionGroups(auth,{accountId});
  const positions:Position[] = [];
  if(markets&&tokens){
    if(lpPositionGroups){
      lpPositionGroups.map(lpPositionGroup=>{
        const { totalSize, fee, side:_side, accountId: accountId, marketId, createDate, matchedSize } = lpPositionGroup;
        const isLend = _side==EOrderSide.LEND;
        // const range = `${new Decimal(rangeFrom).mul(100).toString()}%-${new Decimal(rangeTo).mul(100).toString()}%`;
        const side = isLend?EPositionSide.LEND:EPositionSide.BORROW;
        const market = markets.find(market=>market.marketId==marketId);
        if(!market){
          console.warn('LPPortfolioAccountTable market missing:', marketId, markets);
          return;
        }
        const { tokenId, tokenValuationProtocol, price, tokenType } = market.token;
        const positionTokenType = tokenValuationProtocol==0?EPositionTokenType.BASIC:EPositionTokenType.COMPLEX;
        const cashPositionTokenType = tokenType==2?ECashPositionTokenType.ERC721:ECashPositionTokenType.ERC20;
        const positionType = EPositionType.LP;
        const accruedInterest = new Decimal(fee).mul(side==EPositionSide.LEND?1:-1).toString(); //new Decimal(totalSize).mul(Math.random()*0.01+0.01).toString();
        const mtm = '0';
        const tradeDate = createDate;
        positions.push({
          tokenId,
          marketId,
          accountId,
          totalSize,
          price,
          positionTokenType,
          cashPositionTokenType,
          positionType,
          // range,
          // rangeFrom,
          // rangeTo,
          side,
          accruedInterest,
          mtm,
          tradeDate,
          matchedSize,
          lpPositionGroup,
        });
      });
    }
    // positions.sort((a,b)=>a.tradeDate>b.tradeDate?-1:a.tradeDate<b.tradeDate?1:0);
  }
  const { name:accountName } = account;
  // map positions into type list - type->Position[]
  const positionTypeList:Map<EPositionTokenType,Position[]> = new Map();
  positions?.map(position=>{
    const {positionTokenType} = position;
    const list = positionTypeList.get(positionTokenType)||[];
    list.push(position);
    positionTypeList.set(positionTokenType,list);
  });

  const sxLPTokenTable: SxProps = {
    ...sxTokenTable,
    "th": {
      fontSize: '0.75rem',
      fontWeight: 700,
      textTransform: 'uppercase',
    }
  }

  return (<>
      <Box sx={{textAlign:'right', marginBottom: '10px'}}>
        <Box sx={{display:'inline-block',marginLeft:'2em'}}>Borrow Limit: ${format(maxBorrowInUsd||'0')}</Box>
        <Box sx={{display:'inline-block',marginLeft:'2em'}}>Health Score: {healthScore}</Box>
      </Box>
      {(isLoadingLpPositionGroups)?
        <Box sx={{width:'100%',textAlign:'center'}}><LoadingIcon inline={true}/></Box>:<>
        {markets&&tokens&&positions&&Array.from(positionTypeList.keys()).map((tokenType,index)=>{
          let positionUsdValueSum = new Decimal(0);
          let longUsdValueSum = new Decimal(0);
          let shortUsdValueSum = new Decimal(0);
          let accruedInterestUsdValueSum = new Decimal(0);
          const tokenRows = (positionTypeList.get(tokenType)?.map((position,index2)=>{
            const { positionType, price='0', totalSize, side, accruedInterest, cashPositionTokenType } = position;
            const signum = positionType==EPositionType.LP&&side==EPositionSide.BORROW?-1:1;
            positionUsdValueSum = positionUsdValueSum.add(new Decimal((cashPositionTokenType==ECashPositionTokenType.ERC721 ? 1 : totalSize)||0).mul(signum).mul(new Decimal(price)));
            if(side==EPositionSide.BORROW){
              longUsdValueSum = longUsdValueSum.add(new Decimal(totalSize||0).mul(signum).mul(new Decimal(price)));
            }else{
              shortUsdValueSum = shortUsdValueSum.add(new Decimal(totalSize||0).mul(signum).mul(new Decimal(price)));
            }
            accruedInterestUsdValueSum = accruedInterestUsdValueSum.add(new Decimal(accruedInterest||0).mul(new Decimal(price))); // accrued interest already signed
            return (<PositionRow key={`${index}-${index2}`} position={position} markets={markets} tokens={tokens} isChild={false} account={account}/>);
          })||[]).slice(page[tokenType]*pageLimit,(page[tokenType]+1)*pageLimit);
          tokenRows.unshift(
            <TableRow hover key={`header_${index}`} sx={sxTokenTableSubHeader}>
              <ValueTableCell colSpan={2} width="100%" sticky={true}>{tokenType.toUpperCase()}</ValueTableCell>
              {/* <TableCell>-</TableCell> */}
              <ValueTableCell mono label="Position" width="50%">{positionUsdValueSum.gte(0)?
                <Box>${format(positionUsdValueSum)}</Box>:
                <Box sx={sxBorrowAmount}>(${format(positionUsdValueSum.abs())})</Box>
              }</ValueTableCell>
              <ValueTableCell hideOnMobile={true}>-</ValueTableCell>
              <ValueTableCell mono label="Accrued" width="50%">{accruedInterestUsdValueSum.gte(0)?
                <Box>${format(accruedInterestUsdValueSum)}</Box>:
                <Box sx={sxBorrowAmount}>(${format(accruedInterestUsdValueSum.abs())})</Box>
              }</ValueTableCell>
              <ValueTableCell hideOnMobile={true}>-</ValueTableCell>
              <ValueTableCell hideOnMobile={true}>-</ValueTableCell>
              <ValueTableCell hideOnMobile={true}>-</ValueTableCell>
              <ValueTableCell hideOnMobile={true}>-</ValueTableCell>
              <ValueTableCell hideOnMobile={true}></ValueTableCell>
              <ValueTableCell hideOnMobile={true}></ValueTableCell>
            </TableRow>
          );
          return (<Box key={`${index}`} sx={{ ...sxTableBorder, bgcolor: 'table.dark' }}>
          <TableContainer component={Box} sx={sxTokenTableContainer}>
            <Table sx={sxLPTokenTable} aria-label="Markets" size="medium">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell,idx) => (
                    <ValueTableCell
                      key={idx}
                      align={headCell.align}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                      sticky={idx === 0}
                      sx={idx === 0 ? { borderTopLeftRadius: '4px' } : { borderTopRightRadius: '4px' }}
                    >
                      {headCell.label}
                    </ValueTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
              {tokenRows}
              {positions?.length==0&&<TableRow hover sx={sxEmptyRow}>
                <TableCell colSpan={10}>
                  Portfolio not found.
                <TableCell></TableCell>
                <TableCell></TableCell>
                </TableCell>
              </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={sxTokenTableSubHeader}>
            <TablePagination
              component={Box}
              rowsPerPageOptions={[/* 5,10,25,{label:'All',value:-1} */]}
              // labelRowsPerPage={null}
              // colSpan={1}
              count={positions.length}
              rowsPerPage={pageLimit}
              page={page[tokenType]}
              showFirstButton={true}
              showLastButton={true}
              sx={{position:'sticky',right:'auto',left:0}}
              onPageChange={(event:any,newPage:number)=>{
                setPage({...page,[tokenType]:newPage});
              }}
            />
          </Box>
          </Box>);
        })}
      </>}
  </>);
}

function PositionRow({position,markets,tokens,account,isChild,sx={}}:{position:Position,markets:Market[],tokens:Token[],account:UserAccount,isChild:boolean,sx?:SxProps}){
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  const [expanded,setExpanded] = useState(false);
  const [ underlyingExpanded, setUnderlyingExpanded ] = useState(false);
  const [ unwindPositionParams, setUnwindPositionParams ]:[ UnwindPositionParams|undefined, Function ] = useState();
  const { marketId, tokenId, price='0', orderSize, totalSize, rangeFrom, rangeTo, positionType, side, accruedInterest, mtm, tradeDate, ratePosition, lpPositionGroup, lpPosition, matchedSize, twapOrder, status, completedPnct } = position;
  const market = markets.filter(market=>market.marketId==marketId)[0];
  const token = market?.token||tokens.filter(token=>token.tokenId==tokenId)[0];
  const iconToken = {...token,erc721TokenId:isChild&&position.erc721TokenId||''}

  const ExpandIcon = expanded?KeyboardArrowUp:KeyboardArrowDown;
  // type display
  const typeSuffix = positionType==EPositionType.LP?'-LP':positionType==EPositionType.TWAP?'-TWAP':'';

  // add children positions if it's lp position group
  const childrenPositions = lpPositionGroup?.positions?.map(lpPosition=>{
    const { totalSize, fee, side:_side, accountId: accountId, marketId, createDate, matchedSize, rangeFrom, rangeTo } = lpPosition;
    const positionSize = totalSize;
    const isLend = _side==EOrderSide.LEND;
    // const range = `${new Decimal(rangeFrom).mul(100).toString()}%-${new Decimal(rangeTo).mul(100).toString()}%`;
    const side = isLend?EPositionSide.LEND:EPositionSide.BORROW;
    const market = markets.filter(market=>market.marketId==marketId)[0];
    const { tokenId, tokenValuationProtocol} = market.token;
    const positionTokenType = tokenValuationProtocol==0?EPositionTokenType.BASIC:EPositionTokenType.COMPLEX;
    const cashPositionTokenType = token.tokenType==2?ECashPositionTokenType.ERC721:ECashPositionTokenType.ERC20;
    const positionType = EPositionType.LP;
    const accruedInterest = new Decimal(fee).mul(isLend?1:-1).toString(); //new Decimal(totalSize).mul(Math.random()*0.01+0.01).toString();
    const mtm = '0';
    const tradeDate = createDate;
    return {
      tokenId,
      marketId,
      accountId,
      totalSize,
      price,
      positionTokenType,
      cashPositionTokenType,
      positionSize,
      positionType,
      rangeFrom,
      rangeTo,
      side,
      accruedInterest,
      mtm,
      tradeDate,
      lpPosition,
      matchedSize
    };
  });
  const wt = account.tokens.filter(token => token.tokenId == position.tokenId)[0];
  const underlyingAccountTokens = wt?.underlyingAssets;
  const underlyingPositions: Position[] = position.positionTokenType == EPositionTokenType.COMPLEX &&
      underlyingAccountTokens && underlyingAccountTokens.map(ua => {
        const accountId = account.accountId;
        const underlyingToken = tokens.filter(t => t.tokenId == ua.underlyingTokenId)[0];
        // const side = EPositionSide.LEND; // positive
        const { tokenValuationProtocol, tokenId, price, tokenType} = underlyingToken;
        const totalSize = new Decimal(ua.underlyingTokenIndex || '0').mul(new Decimal(position.totalSize || '0')).toString();
        const positionTokenType = tokenValuationProtocol==0?EPositionTokenType.BASIC:EPositionTokenType.COMPLEX;
        const cashPositionTokenType = tokenType==2?ECashPositionTokenType.ERC721:ECashPositionTokenType.ERC20;
        const positionType = EPositionType.CASH;
        const marketId = 0;
        const accruedInterest = '';
        const mtm = '';
        const tradeDate = Date.now();
        const erc721TokenId = ua.erc721TokenId?.toString() || '';
        return {
          tokenId,
          marketId,
          accountId,
          totalSize,
          price,
          positionTokenType,
          cashPositionTokenType,
          positionType,
          side,
          accruedInterest,
          mtm,
          tradeDate,
          erc721TokenId
        }
      }) || [];
  // for parsing erc721 Token's underlying assets
  if (token.tokenType == 2) {
    if (!isChild) {
      const accountToken = account.tokens.filter(token => token.tokenId == tokenId)[0];
      const erc721Tokens = accountToken?.erc721Tokens;

      const posERC721: Position[] | undefined = erc721Tokens?.map(wt721 => {
        const accountId = accountToken.accountId;
        const token = tokens.filter(t => t.tokenId == wt721.tokenId)[0];
        // const side = EPositionSide.LEND; // positive
        let {tokenId} = token;
        const totalSize = '1';
        const positionTokenType = EPositionTokenType.COMPLEX;
        const cashPositionTokenType = ECashPositionTokenType.ERC721;
        const positionType = EPositionType.CASH;
        const marketId = 0;
        const accruedInterest = '';
        const mtm = '';
        const tradeDate = Date.now();
        const erc721TokenId = wt721.erc721TokenId?.toString() || '';

        const tokenValueUsd = wt721?.underlyingAssets?.map(ua => {
          const ut = tokens.filter(t => t.tokenId == ua.underlyingTokenId)[0];
          const underlyingPrice = new Decimal(ut.price || '0');
          const underlyingTokenAmount = new Decimal(ua.underlyingTokenIndex || '0');
          return underlyingPrice.mul(underlyingTokenAmount);
        }).reduce((prev, next) => prev.add(next), new Decimal(0)) || new Decimal('0');

        const price = tokenValueUsd.toString();

        return {
          tokenId,
          marketId,
          accountId,
          totalSize,
          price,
          positionTokenType,
          cashPositionTokenType,
          positionType,
          side,
          accruedInterest,
          mtm,
          tradeDate,
          erc721TokenId
        }
      });

      if (posERC721)
        underlyingPositions.push(...posERC721);
    } else {
      const accountToken = account.tokens.filter(token => token.tokenId == tokenId)[0];
      const erc721Token: AccountWalletTokenERC721 | undefined = accountToken.erc721Tokens?.filter(wt721 => wt721.erc721TokenId == parseInt(position.erc721TokenId || ''))[0];
      const underlyingAssets = erc721Token?.underlyingAssets;

      if (underlyingAssets) {
        const pos = underlyingAssets.map(ua => {
          const accountId = accountToken.accountId;
          const underlyingToken = tokens.filter(t => t.tokenId == ua.underlyingTokenId)[0];
          const { tokenValuationProtocol, tokenId, tokenType, price} = underlyingToken;
          // NFT no need to multiply by price
          const totalSize = new Decimal(ua.underlyingTokenIndex || '0').toString();
          const positionTokenType = tokenValuationProtocol==0?EPositionTokenType.BASIC:EPositionTokenType.COMPLEX;
          const cashPositionTokenType = tokenType==2?ECashPositionTokenType.ERC721:ECashPositionTokenType.ERC20;
          const positionType = EPositionType.CASH;
          const marketId = 0;
          const accruedInterest = '';
          const mtm = '';
          const tradeDate = Date.now();
          const erc721TokenId = underlyingToken.erc721TokenId?.toString() || '';
          return {
            tokenId,
            marketId,
            accountId,
            totalSize,
            price,
            positionTokenType,
            cashPositionTokenType,
            positionType,
            side,
            accruedInterest,
            mtm,
            tradeDate,
            erc721TokenId
          }
        });
        underlyingPositions.push(...pos);
      }
    }
  }
  function handleClickRow(){
    if(lpPosition) navigate(`/portfolio/lp-positions/${account.accountId}/${lpPosition.lpId}`);
  }
  function handleClickExpandRow(){
    if(lpPositionGroup) setExpanded(!expanded);
  }

  function handleClickExpandUnderlyingRow(){
    if(position.positionTokenType==EPositionTokenType.COMPLEX) setUnderlyingExpanded(!underlyingExpanded);
  }
  function handleClickUnwindButton(position:Position){
    const { marketId } = position;
    const market = markets?.filter(market=>market.marketId==marketId)[0];
    const token = market?.token;
    setUnwindPositionParams({position,market,token});
  }
  let amount = new Decimal((isChild&&position.cashPositionTokenType==ECashPositionTokenType.ERC721 ? 1 : totalSize)||'0');
  // check if it is second layer in Uniswap LP
  let statusComponent;
  let accruedComponent;
  if(new Decimal(accruedInterest||0).gte(0)){
    accruedComponent = (<Box>{format(new Decimal(accruedInterest||0))} <Box sx={sxLabel}>${format(new Decimal(accruedInterest||0).mul(price))}</Box></Box>);
  }else{
    accruedComponent = (<Box sx={sxBorrowAmount}>({format(new Decimal(accruedInterest||0).abs())}) <Box sx={sxLabel}>(${format(new Decimal(accruedInterest||0).abs().mul(price))})</Box></Box>);
  }

  let marketString = '-';
  let rateOrPriceString = '-';
  switch(positionType){
    case EPositionType.RATE:
      rateOrPriceString = ratePosition?.rate !== undefined ? `${format(new Decimal(ratePosition?.rate || '0').mul(100),2)}%` : 'Float';
      switch(market.category){
        case EMarketCategory.SPOT: marketString = 'Float'; break;
        case EMarketCategory.FIXED: marketString = 'Fixed'; break;
      }
      break;
    case EPositionType.LP:
      if(lpPosition){
        rateOrPriceString = `${format(new Decimal(rangeFrom||0).mul(100),2)} - ${format(new Decimal(rangeTo||0).mul(100),2)}%`;
        marketString = `${side==EPositionSide.BORROW?'Borrow':'Lend'} LP`;
      }
      if(lpPositionGroup){
        rateOrPriceString = 'Various';
        marketString = 'LP (Various)';
      }
      break;
    case EPositionType.CASH:
      marketString = 'Cash';
      accruedComponent = <>-</>;
      break;
    case EPositionType.TWAP:
      marketString = 'TWAP';
      amount = new Decimal(orderSize||0);
      rateOrPriceString = `${format(new Decimal(rangeFrom||0).mul(100),2)} - ${format(new Decimal(rangeTo||0).mul(100),2)}%`;
      statusComponent = twapOrder?.status!==ERateTwapOrderStatus.STATUS_DONE?<span style={{color:"orange"}}>{completedPnct} {status}</span>:twapOrder?`${completedPnct} ${status}`:<></>;
      break;
  }
  const amountUsdValue = position.cashPositionTokenType==ECashPositionTokenType.ERC721?new Decimal(price):amount.mul(price);
  const lpMatchedPercentage = new Decimal(matchedSize||'0').div(amount||1).mul(100);
  let amountComponent;
  if(side==EPositionSide.LEND&&amount.gte(0)){
    amountComponent = <Box>{format(amount)} <Box sx={sxLabel}>${format(amountUsdValue)}</Box></Box>
  } else {
    amountComponent = <Box sx={sxBorrowAmount}>({format(amount.abs())}) <Box sx={sxLabel}>(${format(amountUsdValue.abs())})</Box></Box>
  }

  function handleClickTerminate(){
    if(!(twapOrder)) return;
    (async ()=>{
      try {
        await apis.twap.terminateTwapOrder(twapOrder.twapId);
        queryClient.invalidateQueries({queryKey:['user']});
        queryClient.invalidateQueries({queryKey:['market']});
      }catch(error){
        snackbar.error(error);
      }finally{
      }
    })();
  }

  return (
    <>
      <TableRow
        role="checkbox"
        tabIndex={-1}
        hover={true} // !!lpPosition}
        sx={{...sx,...lpPosition?{cursor:'pointer'}:{}}}
        className={lpPosition&&"expandedChild"}
        onClick={handleClickRow}
      >
        <ValueTableCell width="100%" sticky={true}><TokenIcon token={iconToken} withCode={true}/></ValueTableCell>
        <ValueTableCell label="Market" width="50%">{marketString}</ValueTableCell>
        <ValueTableCell mono label="Position" width="50%">{amountComponent}</ValueTableCell>
        <ValueTableCell mono label="Rate or Price" width="50%">{rateOrPriceString}</ValueTableCell>
        <ValueTableCell mono label="Accrued" width="50%">{accruedComponent}</ValueTableCell>
        <ValueTableCell mono label="MTM" width="50%">-{/* {format(mtm||0)} */}</ValueTableCell>
        <ValueTableCell mono label="DV01" width="50%">-{/* dv01 */}</ValueTableCell>
        <ValueTableCell mono label="% Completed" width="50%">{twapOrder?.status!==ERateTwapOrderStatus.STATUS_DONE?<span style={{color:"orange"}}>{completedPnct}</span>:twapOrder?completedPnct:'-'}</ValueTableCell>
        <ValueTableCell label="Status" width="50%">{statusComponent}</ValueTableCell>
        <ValueTableCell label="" width="50%">
          {lpPositionGroup&&<ExpandIcon sx={{cursor:'pointer'}} onClick={handleClickExpandRow}/>}
          {position&&(position.lpPosition||position.ratePosition)&&<UnwindButton position={position} handleClick={handleClickUnwindButton}/>}
          {position&&(twapOrder&&twapOrder.status!=10)&&<Button onClick={handleClickTerminate}>Terminate</Button>}
          {!(new Decimal(totalSize || '0').eq(0))&&position&&position.positionTokenType==EPositionTokenType.COMPLEX&&<ExpandIcon sx={{cursor:'pointer'}} onClick={handleClickExpandUnderlyingRow}/>}
        </ValueTableCell>
        <ValueTableCell label="Notes">{lpPosition&&`${format(lpMatchedPercentage,0)}% Full`}</ValueTableCell>
      </TableRow>
      {expanded&&childrenPositions?.map((position,index)=>(<PositionRow key={index} position={position} markets={markets} tokens={tokens} account={account} sx={sxChildRow} isChild={true}/>))}
      {underlyingExpanded&&underlyingPositions?.map((position,index)=>(<PositionRow key={index} position={position} markets={markets} tokens={tokens} account={account} sx={sxChildRow} isChild={true}/>))}
      {unwindPositionParams&&unwindPositionParams.position&&<PositionUnwindForm params={unwindPositionParams} handleClose={()=>setUnwindPositionParams()}/>}
    </>
  );
}
function UnwindButton({position,handleClick}:{position:Position,handleClick:Function}) {
  return (
    <Button variant="text" onClick={(event)=>{
      event.stopPropagation();
      handleClick(position);
    }}>Unwind</Button>
  );
}

const StyleFormRow:SxProps = {
  display:'flex', py: 1, marginRight: -1
};
const StyleFormCell:SxProps = {
  flex: 1, marginRight: 1
};

function PositionUnwindForm({params,handleClose}:{params:UnwindPositionParams,handleClose:Function}){
  const { position, market, token } = params;
  const { lpPosition, ratePosition, side, totalSize="0", rangeFrom, rangeTo, twapOrder } = position!;
  const interestIndex = ratePosition?(new Decimal(side==EPositionSide.LEND?market.lendPriceIndex:market.borrowPriceIndex).sub(new Decimal(ratePosition.priceIndex||'0'))):new Decimal(0);
  const totalSizeWithInterest = new Decimal(totalSize).mul(interestIndex.add(1)).toString();
  const interest = new Decimal(totalSizeWithInterest).sub(totalSize);
  const queryClient = useQueryClient();
  const auth = useAuth();
  const [unwindAmount,setUnwindAmount] = useState(totalSize||'0');
  const [isPostingUnwind,setIsPostingUnwind] = useState(false);
  const [isUnwindSuccess,setIsUnwindSuccess] = useState(false);
  useEffect(()=>{
    setIsPostingUnwind(false);
    setIsUnwindSuccess(false);
  },[params]);
  const snackbar = useSnackbar();
  function handleClickUnwind(){
    if(!(lpPosition||ratePosition)||isPostingUnwind) return;
    (async ()=>{
      setIsPostingUnwind(true);
      try {
        if(lpPosition){
          await apis.lp.unwindLPPosition({lpPositionId:lpPosition?.lpId});
        }else if(ratePosition){
          const oppositeSide = side==EPositionSide.LEND?EOrderSide.BORROW:EOrderSide.LEND;
          await apis.rate.postRateOrder(auth,{
            marketId: ratePosition.marketId, accountId: ratePosition.accountId, side: oppositeSide, 
            orderType: ERateOrderType.MARKET, quantity: unwindAmount, clientOrderId: generateClientOrderId(),
          });
        } else{
          snackbar.error(new GeneralError('Position missing'));
          return;
        }
        setIsUnwindSuccess(true);
        queryClient.invalidateQueries({queryKey:['user']});
        queryClient.invalidateQueries({queryKey:['market']});
        // handleClose(true);
      }catch(error){
        snackbar.error(error);
      }finally{
        setIsPostingUnwind(false);
      }
    })();
  }
  function handleClickUnwindAndTerminate(){
    // handleClickUnwindAndTerminate
  }
  const typeSuffix = lpPosition?'-LP':'';
  const step = market?.quantityStep||'0';
  const decimals = new Decimal(step).decimalPlaces();

  const simpleUnwindDialog = (<StandardDialog handleClose={()=>handleClose()} open={!!position}
    title={"Unwind Position"}
    content={<>
      <Box sx={StyleConfirmRow} style={{fontSize:'2rem'}}>
        <TokenIcon token={token} withCode={true} size={32}/>{typeSuffix}&nbsp;
      </Box>
      {(rangeFrom&&rangeTo)&&<Box sx={StyleConfirmRow}>
        <Box sx={StyleConfirmBox}>
          <Box sx={StyleConfirmBoxName}>{`${format(new Decimal(rangeFrom||0).mul(100),2)} - ${format(new Decimal(rangeTo||0).mul(100),2)}%`}</Box>
        </Box>
      </Box>}
      {ratePosition&&<>
      <Box sx={{...StyleFormRow,width:'100%'}}>
        <TextField name='totalAmount' label='Total Amount' type='number' 
          sx={StyleFormCell} variant='standard' InputLabelProps={{shrink: true}} 
          InputProps={{
            inputMode: 'numeric',
            disabled: true
          }}
          value={totalSize}
        />
        <TextField name='interest' label='Interest' type='number' 
          sx={StyleFormCell} variant='standard' InputLabelProps={{shrink: true}} 
          InputProps={{
            inputMode: 'numeric',
            disabled: true
          }}
          value={interest}
        />
      </Box>
      <Box sx={{...StyleFormRow,width:'100%'}}>
        <TokenAmountInput name='unwindAmount' label='Unwind Amount' 
          sx={StyleFormCell}
          step={step} min={'0'} max={totalSize} decimals={decimals}
          amount={unwindAmount} onAmountChange={(amount)=>setUnwindAmount(amount)}
        />
        <TextField name='totalFeesEarnedOrOwed' label={lpPosition?'Total Fees Earned':`Amount To Be ${(side==EPositionSide.LEND?'Unwind':'Repaid')}`} type='number' 
          sx={StyleFormCell} variant='standard' InputLabelProps={{shrink: true}} 
          InputProps={{
            inputMode: 'numeric',
            disabled: true
          }}
          value={new Decimal(unwindAmount).mul(interestIndex.add(1)).toString()}
        />
      </Box></>}
      {lpPosition&&<>
      <Box sx={{...StyleFormRow,width:'100%'}}>
        <TextField name='totalAmount' label='Total Amount' type='number' 
          sx={StyleFormCell} variant='standard' InputLabelProps={{shrink: true}} 
          InputProps={{
            inputMode: 'numeric',
            disabled: true
          }}
          value={totalSize}
        />
      </Box></>}
    </>}
    actions={<>
      <Button sx={styleDialogPrimaryButton} onClick={handleClickUnwind} disabled={!isPostingUnwind&&!(unwindAmount&&new Decimal(unwindAmount).gt(0))}>
        Unwind{isPostingUnwind&&'ing...'}
      </Button>
      {/* <Button onClick={handleClickUnwindAndTerminate}>
        Unwind and Terminate
      </Button> */}
    </>}
  />);

  return position&&market&&token&&(isUnwindSuccess?(
    <StandardDialog handleClose={()=>handleClose()} open={!!position}
      title={"Unwind Position Success"}
      content={ <Box sx={StyleFormRow}> <TokenIcon token={token} withCode={true} size={13}/>{typeSuffix} </Box> }
      actions={ <Button sx={styleDialogButton} onClick={()=>handleClose(true)}>Close</Button> }
    />
  ):(
    simpleUnwindDialog
  ))||null;
}

export default LPPortfolioPanel;