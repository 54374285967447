import { sortBy } from "lodash";
import moment, { Moment } from "moment";
import { useCallback } from "react";
import { BaseMarket, MarketFixedRate, MarketForSymbol } from "src/types";
import apis from "./apis";
import { unique, uniqueByKey } from "./common";

// Date related
const DAY_FORMAT = 'DD MMM YY';
const DAY_WITH_HYPHEN_FORMAT = 'DD-MMM-YY';
const MARKET_DAY_FORMAT = 'DDMMMYY';
const DATE_WITHOUT_YEAR_FORMAT = 'DD MMM';
const DATE_SIMPLIFIED_FORMAT = 'D/M';

/**
 * @returns now in UTC+0
 */
export function now(){
  return moment().utc();
}
/**
 * @returns Moment start of today in UTC+0
 */
export function today(){
  let d = moment().utc().startOf('day');
  return d;
}
/**
 * @params string|number datestring
 * @returns Moment start of provided datestring in UTC+0
 */
export function date(dateString:string|number|Moment){
  const m = moment(dateString).utc().startOf('day');
  return m;
}

/**
 * @returns `DD MMM YY`;
 */
export const formatDate = (date: Moment) => date.format(DAY_FORMAT).toUpperCase();
/**
 * @returns `D/M`;
 */
export const formatSimplifiedDate = (date: Moment) => date.format(DATE_SIMPLIFIED_FORMAT).toUpperCase();
/**
 * @returns `DDMMYY`;
 */
export const formatMarketDate = (date: Moment) => date.format(MARKET_DAY_FORMAT).toUpperCase();
/**
 * @returns `DD MMM`;
 */
export const formatDateWithoutYear = (date: Moment) => date.format(DATE_WITHOUT_YEAR_FORMAT).toUpperCase();
/**
 * @returns `DD-MMM-YY`;
 */
export const formatDateWithHyphen = (date: Moment) => date.format(DAY_WITH_HYPHEN_FORMAT).toUpperCase();

export function formatStartOfDayDate(dateStringOrMoment:string|number|Moment){
  const startOfDay = date(dateStringOrMoment);
  return formatDate(startOfDay)
}
export function formatStartOfDayWithHyphenDate(dateStringOrMoment:string|number|Moment){
  const startOfDay = date(dateStringOrMoment);
  return formatDateWithHyphen(startOfDay)
}
export function formatStartOfDayMarketDate(dateStringOrMoment:string|number|Moment){
  const startOfDay = date(dateStringOrMoment)
  return formatMarketDate(startOfDay)
}
/**
 * @param targetTime 
 * @param from optional
 * @returns time difference in milliseconds
 */
export function timeTo(targetTime:Moment,from?:Moment){
  return targetTime.diff(from||moment(),'ms');
}
export function getDaysToMaturitiesFromMarkets(fixedRateMarkets:BaseMarket[]=[]){
  return fixedRateMarkets.map(frm=>Number(frm.daysToMaturity)).filter(d=>d&&!isNaN(d)).sort((a,b)=>a>b?1:a<b?-1:0);
}



type RollingDates = {
  label: string;
  daysTo: number;
  date: Moment;
}[];


/**
 * returns market buckets at rolling dates
 *  */ 
export function useRollingDateMarkets(fixedRateMarkets?:MarketFixedRate[],dateOfReference?:Moment){
  const rollingDates = useRollingDates(dateOfReference);
  const days = rollingDates.map(({daysTo})=>daysTo);
  return fixedRateMarkets?.filter((m)=>days.indexOf(m.daysToMaturity)>=0);
  // deprecated: use daysToMaturity: let job server to update market days instead of trying to figure it out on the FE
  // // dateString check:
  // const dateStrings = rollingDates.map(({date})=>date.format(marketDateFormat));
  // return fixedRateMarkets?.filter((m)=>dateStrings.indexOf(date(m.maturityDate).format(marketDateFormat))>=0);
}

/**
 * 
 * @param days 
 * @returns string label
 */
export function useRollingDateLabel(daysOrMarket?:number|MarketForSymbol,dateOfReference?:Moment){
  const rollingDates = useRollingDates(dateOfReference);
  return getRollingDateLabel(rollingDates,daysOrMarket);
}
/**
 * Inner rolling date label function
 */
function getRollingDateLabel(rollingDates:RollingDates,daysOrMarket?:number|MarketForSymbol){
  const days = (typeof daysOrMarket=='number')?daysOrMarket:daysOrMarket?.daysToMaturity||undefined;
  if(days===undefined||days===0) return 'FLOAT';
  if(days<0) return 'Expired';
  let index = 0
  let distance = Number.POSITIVE_INFINITY
  rollingDates.forEach((rd, i) => {
    if (Math.abs(rd.daysTo - days) < distance) {
      index = i
      distance = Math.abs(rd.daysTo - days)
    }
  })
  return rollingDates[index].label;
}
/**
 * useCallback wrapper to use market rolling date label in callbacks
 * @returns rolling date label string
 */
export function useCallbackRollingDateLabel(dateOfReference?:Moment){
  const rollingDates = useRollingDates(dateOfReference);
  return useCallback((daysOrMarket?:number|MarketForSymbol)=>getRollingDateLabel(rollingDates,daysOrMarket),[rollingDates]);
}


/**
 * useCallback wrapper for formatDaysFromMarketToday
 * @param days 
 * @returns 
 */
export function useCallbackFormatDaysFromMarketToday(){
  const {marketRolloverDayOffset} = apis.infinity.useMarketRolloverHour();
  return useCallback((days:number)=>{
    return formatStartOfDayMarketDate(today().add(days+marketRolloverDayOffset,'d'));
  },[marketRolloverDayOffset]);
}
/**
 * useCallback wrapper for getDaysFromMarketToday
 * @param date 
 * @returns 
 */
export function useCallbackGetDaysFromMarketToday(){
  const {marketRolloverDayOffset} = apis.infinity.useMarketRolloverHour();
  return useCallback((date:Moment)=>{
    return getDaysDifference(date,today().add(marketRolloverDayOffset,'d'));
  },[marketRolloverDayOffset]);
}
/**
 * useCallback wrapper for parseMaturityDate
 * @param date 
 * @returns 
 */
export function useCallbackParseMaturityDate(){
  const {marketRolloverHour} = apis.infinity.useMarketRolloverHour();
  return useCallback((dateString:string|number|Moment)=>{
    return moment(dateString).utc().hour(marketRolloverHour);
  },[marketRolloverHour]);
}

/**
 * 
 * @returns number[] list of days
 */
export function useRollingDateDays(returnsUnique=false){
  const rollingDates = useRollingDates();
  const days = rollingDates.map(({daysTo})=>daysTo);
  if(returnsUnique) return unique(days);
  return days;
}
/** 
 * get actual dates for the rolling date markets:
 * Rolling: Floating, 1D (rolls over at 1400 UTC+0)
 * Fixed: 1W, 2W, 3W, 1M, 2M, 3M, 1Q, 2Q, 3Q, 4Q (snaps to the next last Friday of the week/month/quarter)
 * */
export function useRollingDates(dateOfReference?:Moment):RollingDates{
  // if current time is before server market reset time, use yesterday's date as reference (e.g. 01/23's 1D market would be 01/23 still, so the daysToMaturity of 1D would be calculated from 01/23)
  const {marketRolloverHour,isPastMarketRolloverHour} = apis.infinity.useMarketRolloverHour();
  if(dateOfReference){
    const _isPastMarketRolloverHour = dateOfReference.utc().hour()>=marketRolloverHour;
    dateOfReference = date(dateOfReference).add(_isPastMarketRolloverHour?0:-1,'d');
  }else{
    dateOfReference = today().add(isPastMarketRolloverHour?0:-1,'d');
  }
  return getRollingDates(dateOfReference);
}
export function getRollingDates(dateOfReference?:Moment):RollingDates{
  dateOfReference = dateOfReference ? date(dateOfReference) : today();
  const days1D = dateOfReference.clone().add(1,'day');
  const days2D = dateOfReference.clone().add(2,'day');
  // weeks - next 1-3 fridays
  const dayOfWeek = dateOfReference.weekday();
  const weekOffset = dayOfWeek>=5||dayOfWeek===0?1:0; // add one week if current day of week is past friday, or on sunday (which is 0)
  const days1W = dateOfReference.clone().add(weekOffset,'week').startOf('isoWeek').add(4,'day');
  const days2W = days1W.clone().add(1,'week');
  const days3W = days2W.clone().add(1,'week');
  // months - next 1-3 last fridays of month
  const monthOffset = dateOfReference.clone().isSameOrAfter(getLastFridayOfMonth(dateOfReference.clone()))?1:0; // add one month if current day is past last friday of this month
  const days1M = getLastFridayOfMonth(dateOfReference.clone().add(0+monthOffset,'month'));
  const days2M = getLastFridayOfMonth(dateOfReference.clone().add(1+monthOffset,'month'));
  const days3M = getLastFridayOfMonth(dateOfReference.clone().add(2+monthOffset,'month'));
  // quarters - next March/June/September/December's last friday of month
  const month = dateOfReference.clone().add(monthOffset,'month').month();
  // find next target quarter month
  const months = [2,5,8,11]; // month index starts from 0
  let yearOffset = 0;
  let targetMonthIdx;
  for(targetMonthIdx=-1;targetMonthIdx<months.length;targetMonthIdx++){
    if(month<=months[targetMonthIdx]) break;
  }
  if(targetMonthIdx>=months.length){
    targetMonthIdx = months.length - 1;
  }
  if(month === 0 && monthOffset === 1){
    targetMonthIdx = 0;
    yearOffset = 1;
  }
  let targetMonth = months[targetMonthIdx];
  const days1Q = getLastFridayOfMonth(dateOfReference.clone().add(yearOffset,'year').month(targetMonth));
  const days2Q = getLastFridayOfMonth(days1Q.clone().add(3,'month'));
  const days3Q = getLastFridayOfMonth(days1Q.clone().add(6,'month'));
  const days4Q = getLastFridayOfMonth(days1Q.clone().add(9,'month'));
  const array = [
    {label:'1D',daysTo:getDaysDifference(days1D,dateOfReference),date:days1D},
    {label:'2D',daysTo:getDaysDifference(days2D,dateOfReference),date:days2D},
    {label:'1W',daysTo:getDaysDifference(days1W,dateOfReference),date:days1W},
    {label:'2W',daysTo:getDaysDifference(days2W,dateOfReference),date:days2W},
    {label:'3W',daysTo:getDaysDifference(days3W,dateOfReference),date:days3W},
    {label:'1M',daysTo:getDaysDifference(days1M,dateOfReference),date:days1M},
    {label:'2M',daysTo:getDaysDifference(days2M,dateOfReference),date:days2M},
    {label:'3M',daysTo:getDaysDifference(days3M,dateOfReference),date:days3M},
    {label:'1Q',daysTo:getDaysDifference(days1Q,dateOfReference),date:days1Q},
    {label:'2Q',daysTo:getDaysDifference(days2Q,dateOfReference),date:days2Q},
    {label:'3Q',daysTo:getDaysDifference(days3Q,dateOfReference),date:days3Q},
    {label:'4Q',daysTo:getDaysDifference(days4Q,dateOfReference),date:days4Q},
  ];

  // reverse first to use the FIRST INSTANCE(i.e. the earliest label), 
  // then sort again with daysTo to make sure the array is in order
  return sortBy(uniqueByKey(array.reverse(),'daysTo'),'daysTo');
}

const DAY_IN_SECS = 60 * 60 * 24;
export function getDaysDifference(date:Moment|number,reference:Moment|number=moment()){
  if(typeof date === 'number') date = moment(date);
  return Math.ceil(date.diff(reference,'seconds') / DAY_IN_SECS); // treats <24 hours difference as 1 day
}
export function isWithinDays(date:Moment,days:number){
  const diff = date.diff(moment(),'day');
  return Math.abs(diff)<=Math.abs(days);
}
function getLastFridayOfMonth(date:Moment){
  const fridayOfLastWeek = date.clone().endOf('month').startOf('isoWeek').add(4,'day');
  if(fridayOfLastWeek.month()>date.month()){ // if last friday of week goes over to next month
    fridayOfLastWeek.subtract(1,'week');
  }
  return fridayOfLastWeek;
}


export function formatTimeAgo(reference:Moment=moment()){
  const plural = (n:number)=>n>1?'s':'';
  const now = moment();
  const seconds = now.diff(reference,'seconds');
  if(seconds<0) return 'in the future';
  if(seconds<60) return 'just now';
  const minutes = now.diff(reference,'minutes');
  if(minutes<60) return `${minutes} minute${plural(minutes)} ago`;
  const hours = now.diff(reference,'hours');
  if(hours<24) return `${hours} hour${plural(hours)} ago`;
  const days = now.diff(reference,'days');
  if(days<24) return `${days} day${plural(days)} ago`;
  const months = now.diff(reference,'month');
  if(months<12) return `${months} month${plural(months)} ago`;
  return 'more than a year ago';
}

export function getPastDaysArray(days:number=0){
  const timestamp = today().unix()*1000;
  return new Array(days).fill(0).map((_,i)=>{
    return timestamp - (i * (DAY_IN_SECS*1000));
  });
}