import { zFallback } from "src/utils/apis/common";
import { formatWeb3Address } from "src/utils/common";
import { z } from "zod";

const {
 REACT_APP_INFINITY_POOL_ADDRESS="",
 REACT_APP_CHAIN_ID="0",
 REACT_APP_CHAIN_GAS_LIMIT="21000",
 REACT_APP_API_BASE=`${window.location.protocol}//${window.location.host}`,
 REACT_APP_FEATURE_VERSION, //='development', // default set by zod schema below
 REACT_APP_FAUCET_TOKEN_ADDRESSES="",
 REACT_APP_WETH_ADDRESS="0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
 REACT_APP_WALLET_CONNECT_PROJECT_ID="",
} = process.env;

export const ETHERSCAN_CHAINSUBDOMAIN_MAPPING:{[key:number]:string} = {
  11155111: 'sepolia.'
};

// ref: https://chainid.network/chains_mini.json - only lists supported networks
type TEthChain = {
  "name": string;
  "label": string;
  "chainId": number;
  "rpc": string[];
  "blockExplorerUrl": string;
};
export const ETH_CHAIN_LIST:TEthChain[] = require('./chains.json');
export const ETH_CHAIN_MAP = ETH_CHAIN_LIST.reduce((map,obj)=>{
  map.set(obj.chainId,obj);
  return map;
},new Map<number,TEthChain>());

 // global
export const TESTNET_NOTIFICATION_HEIGHT = 26; //px
export const DEFAULT_LOGGER = 'debug';
export const TOKEN_MAX_DECIMALS = 9; // maximum decimal places to be handled within Infinity
export const TWAP_MAX_DURATION = 10080; // 7 days in minutes = 7 * 24 * 60
 // orderbook refetch interval
export const ORDERBOOK_REFETCH_INTERVAL = 5000;

 // infinity db chain ids
export const INFINITY_CHAIN_ID_ETHEREUM = 1;
 // markets page display tokens (code)
export const MARKETS_PAGE_TOKENS = ['ETH','USDC','USDT','DAI','WBTC'];
export const DEFAULT_MARKETS_PAGE_GRAPH_TOKENS = ['ETH','USDC'];
export const DEFAULT_MARKETS_PAGE_GRAPH_MARKET_DAYS = ['FLOAT'/* ,'2W','2Q' */];
 // market grid version:
export const MARKET_GRID_VERSION = 3;
 // paginations
export const TRADE_HISTORY_TABLE_PAGE_LIMIT = 50;
 // app
export const SNACKBAR_TIMEOUT = 5000;
 // ws
export const WS_HEARTBEAT_INTERVAL = 1000;
 // env
export const INFINITY_POOL_ADDRESS = formatWeb3Address(REACT_APP_INFINITY_POOL_ADDRESS);
export const CHAIN_ID = parseInt(REACT_APP_CHAIN_ID);
export const CHAIN_GAS_LIMIT = REACT_APP_CHAIN_GAS_LIMIT;
export const API_BASE = REACT_APP_API_BASE;
export const WS_BASE = 'wss://'+API_BASE.split('//')[1]+'/ws/';

const FeatureVersionSchema = z.enum(['development','testnet','production']);
export const FEATURE_VERSION = FeatureVersionSchema.or(zFallback<z.infer<typeof FeatureVersionSchema>>('development')).parse(REACT_APP_FEATURE_VERSION);
export const FAUCET_TOKEN_ADDRESSES = REACT_APP_FAUCET_TOKEN_ADDRESSES.split(',')||[];
 // public keys
export const INFURA_ID = 'a746618ff9a24885923e0920b90861f0';

 // walletconnect
export const WALLET_CONNECT_PROJECT_ID = REACT_APP_WALLET_CONNECT_PROJECT_ID;
export const WALLET_CONNECT_RELAY_URL = 'wss://relay.walletconnect.com';
export const WALLET_CONNECT_EIP155_METHODS = ["eth_sendTransaction", "personal_sign", "eth_signTypedData"];

// web3 
export const ETH_DECIMALS = 18;
export const WETH_ADDRESS = REACT_APP_WETH_ADDRESS;

// markets
export const FLOAT_SHORT = 'FL';
export const RATE_BUCKET_BP_THRESHOLD = 40;
export const RATE_BUCKET_SIZE = 4;

export const DISCORD_SUPPORT_LINK = 'https://discord.com/channels/954187499683610744/988673346973618218'

export const MAX_RATE = 1000
export const MAX_BORROW_SLIPPAGE = 1000
