import { Box, Button, Dialog, DialogTitle, Link, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Decimal from 'decimal.js';
import { isEmpty, isUndefined } from 'lodash';
import Lottie from 'lottie-react';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from 'src/AuthProvider';
import errorAnimationData from 'src/Components/Icons/animated/error.json';
import successAnimationData from 'src/Components/Icons/animated/success.json';
import LoadingIcon from 'src/Components/Loading';
import { useMarketRollingDateLabel } from 'src/Components/MarketRollingDateLabel';
import StandardDialog from 'src/Components/StandardDialog';
import TokenIcon from 'src/Components/TokenIcon';
import { DISCORD_SUPPORT_LINK } from 'src/constants/app';
import useSnackbar from 'src/hooks/useSnackbar';
import {
  styleDialogButtonOutlined,
  sxActionButton, sxActionButtonFirst,
  sxConfirmDialogActionButtonContainer,
  sxConfirmationDialog,
  sxDialog,
  sxDialogCell, sxDialogCellValue,
  sxDialogPanel,
  sxDialogTitle,
  sxErrorStatusMain, sxFulfilledTrade,
  sxOrderStatusContent,
  sxOrderStatusErrorTitle,
  sxOrderStatusSuccessTitle,
  sxOrderSummary,
  sxStatusTitleLabel,
  sxSummary,
  sxSummaryDetailsText,
  sxSupport
} from 'src/styles/Dialog';
import { styleFormButton } from 'src/styles/Form';
import {
  BaseRateOrder,
  ConfirmationDialogProps,
  DialogParams,
  EDialogStatus,
  EOrderSide,
  ERateOrderStatus,
  ERateOrderType,
  ETradeType,
  SummaryDialogProps,
  Token,
  TradeParams,
  getRateOrderStatusLabel
} from 'src/types';
import apis from 'src/utils/apis';
import { IRateOrderParams } from 'src/utils/apis/rate';
import { sendGA } from 'src/utils/common';
import { formatStartOfDayMarketDate } from 'src/utils/date';
import { dps, format } from 'src/utils/numbers';
import { paths } from 'src/utils/paths';
import { toPercentage } from 'src/utils/string';
import SidedQuantityValue from './SidedQuantityValue';

const getOrderTypeLabel = (orderType: ERateOrderType) => orderType === ERateOrderType.MARKET ? 'Market' : 'Limit';
const getTradeTypeLabel = (tradeType: ETradeType) => {
  switch(tradeType) {
    case ETradeType.INTEREST_RATE_SWAP:
      return 'Interest Rate Swap';
    case ETradeType.CROSS_CURRENCY_SWAP: 
      return 'Cross-Currency Swap'
    case ETradeType.UNWIND_POSITION:
      return 'Unwind Position';
    default:
      return 'Trade'
  }
}

enum EStatusType {
  'PENDING','PARTIAL','DONE','ERROR',
}
const statusSx:{[key in EStatusType]:SxProps} = {
  [EStatusType.PENDING]:{color:'primary.main'},
  [EStatusType.PARTIAL]:{color:'success.main'},
  [EStatusType.DONE]:{color:'success.main'},
  [EStatusType.ERROR]:{color:'error.main'},
};

const statusToTypeMapping = {
  [ERateOrderStatus.PENDING]: EStatusType.PENDING,
  [ERateOrderStatus.ONBOOK]: EStatusType.PENDING,
  [ERateOrderStatus.DONE]: EStatusType.DONE,
  [ERateOrderStatus.ERROR]: EStatusType.ERROR,
  [ERateOrderStatus.PARTIALLY_FILLED]: EStatusType.PARTIAL,
  [ERateOrderStatus.MARKET_EXPIRED]: EStatusType.ERROR,
  [ERateOrderStatus.AUTO_CANCELLED]: EStatusType.ERROR
}

const getTitle = (orderType: ERateOrderType, status: EDialogStatus, tradeType: ETradeType) => {
  const sideLabel = tradeType === ETradeType.SINGLE ? getOrderTypeLabel(orderType) : getTradeTypeLabel(tradeType);
  switch (status) {
    case EDialogStatus.WAIT_FOR_CONFIRMATION:
    case EDialogStatus.ORDER_IN_PROGRESS:
      return `Confirm ${sideLabel} Order`
    case EDialogStatus.ORDER_PLACED:
      return `${sideLabel} Order Placed`
    case EDialogStatus.ERROR:
      return 'Error'
    default:
      return "Confirmation"
  }
}

const getErrorReason = (status?: ERateOrderStatus): string => {
  switch (status) {
    case ERateOrderStatus.AUTO_CANCELLED: 
      return 'No matching order.'
    case ERateOrderStatus.MARKET_EXPIRED:
      return 'Market expired.'
    default:
      return 'System Error.'
  }
}

const getSummaryText = ({ tradeParams, token, tradeType, isFixedRate, marketTypeLabel, summaryRate, daysToMaturity }: {
  tradeParams: TradeParams; token: Token; tradeType: ETradeType; isFixedRate: boolean; marketTypeLabel: string; summaryRate: string; daysToMaturity?: number;
}) => {
  switch(tradeType){
    case ETradeType.SINGLE: {
      const { orderType, maxSlippage, orderRate, adjustedRate, targetRate, side, quantity } = tradeParams.primaryLeg;
      const orderTypeLabel = orderType === ERateOrderType.MARKET ? 'Market' : 'Limit';
      const rateWithSlippageLabel = orderType===ERateOrderType.LIMIT ? 'Rate' : (
        side === EOrderSide.BORROW ? 'Max Rate' : 'Min Rate'
      );
      const estimatedSlippage = targetRate?.minus(orderRate).abs();

      const slippageText = ((orderType === ERateOrderType.MARKET && !maxSlippage.eq(0)) || estimatedSlippage?.gt(0)) &&
        (<>{`(${rateWithSlippageLabel}: ${!adjustedRate?'-':toPercentage(adjustedRate)}%${estimatedSlippage?.gt(0) ? `, Estimated Slippage: ${toPercentage(estimatedSlippage)}%` : ''})`}</>);
        
      return (<>
        <Box component={'div'} sx={{ fontSize: '1.1rem', lineHeight: '1.7rem' }}>{getOrderTypeLabel(orderType)} <strong>{format(quantity||0)}</strong> {token?.code} @ {summaryRate} {marketTypeLabel} ({orderTypeLabel})</Box><Box component={'div'} sx={{ fontSize: '1.1rem', lineHeight: '1.7rem' }}>{isFixedRate ? ` for ${daysToMaturity} Days` : ''} {slippageText}</Box>
      </>)
    }
    default: return (<>summary</>)
  }
}

const successDefaultOptions = {
  loop: false,
  autoplay: true,
  animationData: successAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const errorDefaultOptions = {
  loop: false,
  autoplay: true,
  animationData: errorAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

/**
 * Dialogs
 */
const ConfirmationDialog = ({ handleClose, isOpen, isFixedRate, params: { tradeParams, primaryMarket, primaryToken, secondaryToken, secondaryMarket, tradeType, summaryText }, status, handleConfirm}: ConfirmationDialogProps) => {
  const dateLabel = useMarketRollingDateLabel({ market: primaryMarket })
  const dateWithMarketCodeLabel = useMarketRollingDateLabel({ market: primaryMarket, withMarketCode: true })
  const isPrimaryFixedRate = useMemo(() => !isUndefined(primaryMarket?.daysToMaturity), [primaryMarket])
  const isSecondaryFixedRate = useMemo(() => !isUndefined(secondaryMarket?.daysToMaturity), [secondaryMarket])
  const marketLabel = `${dateLabel} ${!primaryMarket?.maturityDate?'-':formatStartOfDayMarketDate(primaryMarket?.maturityDate)}`;
  const primaryQuantityStepDecimals = dps(new Decimal(primaryMarket?.quantityStep||0).toNumber());
  const { orderType, maxSlippage, orderRate, adjustedRate, side, quantity, estimatedSlippage, estimatedFillAmount } = tradeParams.primaryLeg;
  const { quantity:secondaryQuantity } = tradeParams.secondaryLeg ?? {};
  const [ primaryQuantityDecimal, secondaryQuantityDecimal ] = useMemo(()=>[
    new Decimal(quantity ?? 0), new Decimal(secondaryQuantity ?? 0), 
  ],[quantity,secondaryQuantity]);
  const unwindSideSign = side===EOrderSide.BORROW?1:-1;
  const marketTypeLabel = isFixedRate ? marketLabel : 'FLOAT' 
  const rateForRate = (orderType === ERateOrderType.MARKET && !maxSlippage?.eq(0)) ? orderRate : adjustedRate;
  const summaryRate = `${!rateForRate?'-':toPercentage(rateForRate)}%`;
  const confirmationSummaryText = summaryText ? (<>{summaryText}</>) : getSummaryText({ tradeParams, token: primaryToken, tradeType, isFixedRate, marketTypeLabel, summaryRate, daysToMaturity: primaryMarket?.daysToMaturity })
  const tradePage = paths.useCallbackTradePage();
  const minMaxRate = side === EOrderSide.LEND ? rateForRate?.minus(maxSlippage||0) : rateForRate?.plus(maxSlippage||0)
  const estAvgRate = side === EOrderSide.LEND ? rateForRate?.minus(estimatedSlippage||0) : rateForRate?.plus(estimatedSlippage||0)
  const content = (<>
  <TableContainer sx={{ padding: '10px 28px 32px 28px' }} component={Box}>
    <Table>
      {(tradeType !== ETradeType.SINGLE && tradeType !== ETradeType.UNWIND_POSITION) && <TableHead>
        <TableRow>
          <TableCell sx={sxDialogCell}>
          </TableCell>
          <TableCell sx={{...sxDialogCell, textAlign: 'right'}}>
            {`Leg 1 (${side === EOrderSide.LEND ? 'Receive' : 'Pay'})`}
          </TableCell>
          <TableCell sx={{...sxDialogCell, textAlign: 'right'}}>
            {`Leg 2 (${side === EOrderSide.LEND ? 'Pay' : 'Receive'})`}
          </TableCell>
        </TableRow>
      </TableHead>}
      {tradeType === ETradeType.SINGLE && <TableHead>
        <TableRow>
          <TableCell sx={{...sxDialogCell, color: 'text.disabled', lineHeight: '2rem'}}>
            INSTRUCTION
          </TableCell>
        </TableRow>
      </TableHead>}

      {tradeType === ETradeType.UNWIND_POSITION && <TableHead>
        <TableRow>
          <TableCell sx={{...sxDialogCell, lineHeight: '2rem'}} colSpan={2}>
            <Box sx={{lineHeight:'1.25rem', fontWeight: '400'}}>
              {!isPrimaryFixedRate&&<>This is an order to unwind all your {dateWithMarketCodeLabel} position. Please note, interest is calculated every minute and the displayed final size might change.</>}
              {isPrimaryFixedRate&&<>This is an order to unwind all your {dateWithMarketCodeLabel} position. You will still bear the interest rate difference until maturity. If you wish to partially unwind only, place an order from the <RouterLink to={tradePage(primaryMarket)}>Trade</RouterLink> page.</>}
            </Box>
            <br/>
            <Box sx={{lineHeight:'1.25rem', fontWeight: '400'}}>Please note, interest is calculated every minute and the displayed final size might change.</Box>
            <br/>
            <Box sx={{color: 'text.disabled'}}>INSTRUCTION</Box>
          </TableCell>
        </TableRow>
      </TableHead>}
      <TableBody>
        {tradeType === ETradeType.UNWIND_POSITION && primaryMarket.daysToMaturity===undefined ? <>
          <TableRow sx={{ height: '4rem'}}>
            <TableCell sx={{...sxDialogCell }}><Box>Token</Box></TableCell>
            <TableCell sx={{...sxDialogCellValue, borderTopWidth: '1px' }}><Box><TokenIcon token={primaryToken} withCode={true} size={20}/></Box></TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={sxDialogCell}><Box>Initial Size</Box></TableCell>
            <TableCell sx={sxDialogCellValue}><SidedQuantityValue value={primaryQuantityDecimal.mul(unwindSideSign)} token={primaryToken}/></TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={sxDialogCell}><Box>Final Size</Box></TableCell>
            <TableCell sx={sxDialogCellValue}><SidedQuantityValue value={primaryQuantityDecimal.add(secondaryQuantityDecimal).mul(unwindSideSign)} token={primaryToken}/></TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={sxDialogCell}><Box>Interest</Box></TableCell>
            <TableCell sx={sxDialogCellValue}><SidedQuantityValue value={secondaryQuantityDecimal.mul(unwindSideSign)} token={primaryToken}/></TableCell>
          </TableRow>
        </>:<>
          <TableRow sx={{ height: '4rem'}}>
            <TableCell sx={{...sxDialogCell, borderTopWidth: '1px' }}><Box>Direction</Box></TableCell>
            <TableCell sx={{...sxDialogCellValue, borderTopWidth: '1px' }}><Box>{side === EOrderSide.LEND ? 'Lend' : 'Borrow'}</Box></TableCell>
            {[ETradeType.INTEREST_RATE_SWAP, ETradeType.CROSS_CURRENCY_SWAP].includes(tradeType) ? <TableCell sx={{...sxDialogCellValue, borderTopWidth: '1px' }}><Box><TokenIcon token={secondaryToken} withCode={true} size={20}/></Box></TableCell> : null}
          </TableRow>
          <TableRow sx={{ height: '4rem'}}>
            <TableCell sx={{...sxDialogCell }}><Box>Token</Box></TableCell>
            <TableCell sx={{...sxDialogCellValue, borderTopWidth: '1px' }}><Box><TokenIcon token={primaryToken} withCode={true} size={20}/></Box></TableCell>
            {[ETradeType.INTEREST_RATE_SWAP, ETradeType.CROSS_CURRENCY_SWAP].includes(tradeType) ? <TableCell sx={{...sxDialogCellValue, borderTopWidth: '1px' }}><Box><TokenIcon token={secondaryToken} withCode={true} size={20}/></Box></TableCell> : null}
          </TableRow>
          <TableRow>
            <TableCell sx={sxDialogCell}><Box>Maturity</Box></TableCell>
            <TableCell sx={sxDialogCellValue}><Box>{marketTypeLabel}</Box></TableCell>
            {[ETradeType.INTEREST_RATE_SWAP, ETradeType.CROSS_CURRENCY_SWAP].includes(tradeType) ? (<TableCell sx={sxDialogCellValue}><Box>{isSecondaryFixedRate ? marketLabel : 'FLOAT'}</Box></TableCell>) : null}
          </TableRow>
          <TableRow>
            <TableCell sx={sxDialogCell}><Box>Size</Box></TableCell>
            <TableCell sx={sxDialogCellValue}><Box>{format(quantity||0, primaryQuantityStepDecimals)}</Box></TableCell>
            {[ETradeType.INTEREST_RATE_SWAP, ETradeType.CROSS_CURRENCY_SWAP].includes(tradeType) ? (<TableCell sx={sxDialogCellValue}><Box>{format(tradeParams?.secondaryLeg?.quantity||0)}</Box></TableCell>) : null}
          </TableRow>
          <TableRow>
            <TableCell sx={sxDialogCell}><Box>Rate</Box></TableCell>
            <TableCell sx={sxDialogCellValue}><Box>{summaryRate}</Box></TableCell>
            {[ETradeType.INTEREST_RATE_SWAP, ETradeType.CROSS_CURRENCY_SWAP].includes(tradeType) ? (<TableCell sx={sxDialogCellValue}><Box>{`${isSecondaryFixedRate ? `${!tradeParams.secondaryLeg?.orderRate?'-':toPercentage(tradeParams.secondaryLeg?.orderRate)}%` : '-'}`}</Box></TableCell>) : null}
          </TableRow>
        </>}
        {orderType === ERateOrderType.MARKET && tradeType === ETradeType.SINGLE && 
          (<><TableRow>
            <TableCell sx={sxDialogCell}><Box>{`${side === EOrderSide.LEND ? 'Min' : 'Max'} Rate (Max Slippage)`}</Box></TableCell>
            <TableCell sx={sxDialogCellValue}><Box>{`${toPercentage(minMaxRate ?? 0)}% (${toPercentage(maxSlippage)}%)`}</Box></TableCell>
            {[ETradeType.INTEREST_RATE_SWAP, ETradeType.CROSS_CURRENCY_SWAP].includes(tradeType) ? (<TableCell sx={sxDialogCellValue}><Box>{`${isSecondaryFixedRate ? `${!tradeParams.secondaryLeg?.orderRate?'-':toPercentage(tradeParams.secondaryLeg?.orderRate)}%` : '-'}`}</Box></TableCell>) : null}
          </TableRow>
          <TableRow>
            <TableCell sx={{...sxDialogCell, color: 'text.disabled', lineHeight: '4rem', height: '4rem', verticalAlign: 'bottom' }}><Box sx={{ lineHeight: '2rem'}}>ESTIMATED EXECUTION</Box></TableCell>
            <TableCell sx={sxDialogCellValue}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={sxDialogCell}><Box>Estimated Fill Amount</Box></TableCell>
            <TableCell sx={sxDialogCellValue}><Box>{format(estimatedFillAmount||0)}</Box></TableCell>
            {[ETradeType.INTEREST_RATE_SWAP, ETradeType.CROSS_CURRENCY_SWAP].includes(tradeType) ? (<TableCell sx={sxDialogCellValue}><Box>-</Box></TableCell>) : null}
          </TableRow>
          <TableRow>
            <TableCell sx={sxDialogCell}><Box>Estimated Avg. Rate</Box></TableCell>
            <TableCell sx={sxDialogCellValue}><Box>{`${toPercentage(estAvgRate ?? 0)}%`}</Box></TableCell>
            {[ETradeType.INTEREST_RATE_SWAP, ETradeType.CROSS_CURRENCY_SWAP].includes(tradeType) ? (<TableCell sx={sxDialogCellValue}><Box>-</Box></TableCell>) : null}
          </TableRow></>)
        }
      </TableBody>
    </Table>
  </TableContainer>
  { [ETradeType.INTEREST_RATE_SWAP, ETradeType.CROSS_CURRENCY_SWAP].includes(tradeType) && <Box sx={sxSummary}><Box component={'div'} sx={{ fontSize: '0.75rem', lineHeight: '1.7em', fontWeight: '700' }}>Summary</Box>{confirmationSummaryText}</Box>}
  </>)
  const actions = (
    <Box sx={sxConfirmDialogActionButtonContainer}>
      <Button variant="contained" sx={{ ...styleFormButton, ...sxActionButtonFirst } as SxProps} onClick={handleConfirm}>Confirm Order</Button>
      <Button variant="outlined" sx={{ ...styleDialogButtonOutlined, ...sxActionButton } as SxProps} onClick={handleClose}>Cancel</Button>
    </Box>)
  
  return (<StandardDialog sxCustomDialog={sxConfirmationDialog} open={isOpen}
    title={getTitle(orderType ?? ERateOrderType.MARKET, status, tradeType)}
    content={content}
    actions={actions}
  />)
}

const InProgressDialog = ({ isOpen, orderType=ERateOrderType.MARKET, tradeType, side }: { isOpen: boolean, orderType: ERateOrderType, tradeType: ETradeType, side?:EOrderSide }) => {
  return <Dialog sx={{...sxDialog, ...sxConfirmationDialog}} open={isOpen}>
    <DialogTitle sx={sxDialogTitle}>
      {getTitle(orderType, EDialogStatus.ORDER_IN_PROGRESS, tradeType)}
    </DialogTitle>
    <Box sx={{...sxDialogPanel, minHeight: '505px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <LoadingIcon size={48}/>
      <Box sx={{lineHeight: '1.8rem', fontSize: '1.1rem', paddingTop: '5px'}}>Processing...</Box>
    </Box>
  </Dialog>
}

const SummaryDialog = ({ isOpen, isFixedRate, lastOrder, tokenCode, tradeType, params: { primaryMarket }, handleClose }: SummaryDialogProps) => {
  const { orderType, status, side, quantity, averageTradeRate, fulfilled, rate } = lastOrder || {}
  const primaryQuantityStepDecimals = dps(new Decimal(primaryMarket?.quantityStep||0).toNumber());
  const formattedFulfilledQuantity = format(fulfilled || 0, primaryQuantityStepDecimals);
  const formattedQuantity = format(quantity || 0, primaryQuantityStepDecimals);
  const filledPercentage = toPercentage(new Decimal(fulfilled || 0).dividedBy(quantity || 0));
  const avgRate = new Decimal(averageTradeRate||0).toNumber();
  const marketTypeLabel = isFixedRate ? 'Fixed' : 'Float';
  const orderTypeLabel = orderType === ERateOrderType.MARKET ? 'Market' : 'Limit';
  const avgRateToShow = (orderType === ERateOrderType.LIMIT || !avgRate) ? rate : avgRate;
  const typeLabel = tradeType === ETradeType.SINGLE ? getOrderTypeLabel(orderType) : getTradeTypeLabel(tradeType);
  const statusText = getRateOrderStatusLabel(status);
  let statusType:EStatusType = status ? statusToTypeMapping[status] : EStatusType.PENDING;
  const placedSideLabel = side === EOrderSide.BORROW ? 'Borrowed' : 'Lent';
  const content = (<Box sx={{ marginBottom: '36px'}}>
    <Box sx={sxOrderStatusSuccessTitle}>
      <Lottie {...successDefaultOptions} style={{height:55,width:55,margin:'0.5rem 1rem'}}/>
      {tradeType === ETradeType.UNWIND_POSITION&&(
        <Box component={'span'} sx={sxStatusTitleLabel}>Unwind Order Filled!</Box>
      )}
      {tradeType !== ETradeType.UNWIND_POSITION&&(
        <Box component={'span'} sx={sxStatusTitleLabel}>{typeLabel} Order Placed!</Box>
      )}
    </Box>
    {tradeType !== ETradeType.SINGLE ? (<Box sx={sxOrderStatusContent}>
      <Box sx={sxOrderSummary}>
        <Box component={'div'} sx={sxSummaryDetailsText}>{placedSideLabel} {format(quantity||0)} {tokenCode} @ {`${toPercentage(avgRateToShow ?? 0)}%`} {marketTypeLabel} ({orderTypeLabel})</Box>
      </Box>
      <Box sx={sxFulfilledTrade}>{side===EOrderSide.LEND ?
          (<Box>{format(fulfilled)} {tokenCode} was fulfilled.</Box>) :
          (<>
            <Box><b>{format(fulfilled)} {tokenCode}</b> was fulfilled. </Box>
            <Box><b>{format(new Decimal(quantity).sub(new Decimal(fulfilled)))} {tokenCode}</b> remain unfulfilled.</Box>
          </>)
        }
      </Box>
      <Box>Status: {statusText}</Box>
    </Box>) : 
    (<TableContainer sx={{ padding: '10px 28px 32px 28px' }} component={Box}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{...sxDialogCell, color: 'text.disabled', lineHeight: '2rem'}}>
              EXECUTION
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ height: '4rem'}}>
            <TableCell sx={{...sxDialogCell, borderTopWidth: '1px' }}><Box>Fill Size</Box></TableCell>
            <TableCell sx={{...sxDialogCellValue, borderTopWidth: '1px' }}><Box>{`${formattedFulfilledQuantity}/${formattedQuantity} (${filledPercentage}%)`}</Box></TableCell>
          </TableRow>
          <TableRow sx={{ height: '4rem'}}>
            <TableCell sx={{...sxDialogCell }}><Box>Avg Rate</Box></TableCell>
            <TableCell sx={{...sxDialogCellValue, borderTopWidth: '1px' }}><Box>{`${toPercentage(avgRateToShow ?? 0)}%`}</Box></TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={sxDialogCell}><Box>Current Status</Box></TableCell>
            <TableCell sx={sxDialogCellValue}><Box sx={statusSx[statusType]}>{getRateOrderStatusLabel(status, fulfilled)}</Box></TableCell>
          </TableRow>
          { fulfilled !== quantity && orderType === ERateOrderType.LIMIT && isFixedRate && <TableRow>
            <TableCell sx={sxDialogCell}><Box>Order Expiry</Box></TableCell>
            <TableCell sx={sxDialogCellValue}><Box>{primaryMarket?.maturityDate ? formatStartOfDayMarketDate(primaryMarket.maturityDate!) : 'N/A'}</Box></TableCell>
          </TableRow>}
        </TableBody>
      </Table>
    </TableContainer>)}
    </Box>);
  
  const actions = (
    <Box sx={sxConfirmDialogActionButtonContainer}>
      <Button variant="contained" sx={{ ...styleFormButton, ...sxActionButtonFirst } as SxProps} onClick={handleClose}>Close</Button>
    </Box>)
  return (
    <StandardDialog
      sxCustomDialog={sxConfirmationDialog}
      open={isOpen}
      content={content}
      actions={actions}
    />
  );
}

const DefaultErrorDialogContent = ({ reason = ''}: { reason?: string }) => {
  return (<Box sx={{ marginBottom: '36px'}}>
  <Box sx={sxOrderStatusErrorTitle}>
    <Lottie {...errorDefaultOptions} style={{height:55,width:55,margin:'0.5rem 1rem'}} />
    <Box component={'span'} sx={sxStatusTitleLabel}>Order Failed!</Box>
  </Box>
    <Box sx={sxErrorStatusMain}>
      <Box component={'div'} sx={sxSummaryDetailsText}>Your order hasn't been placed.</Box>
      {reason && <Box component={'div'} sx={sxSummaryDetailsText}>{`Reason: ${reason}`}</Box>}
      <Box component={'div'} sx={{...sxSummaryDetailsText, marginTop: '1rem'}}><strong>Please try again.</strong></Box>
    </Box>
    <Box sx={sxSupport}>
      If you continue to experience problems, please reach out to our support team on <Link href={DISCORD_SUPPORT_LINK} color="inherit" target='_blank' rel='noopener'>Discord</Link> and we apologise for any inconvenience caused.
    </Box>
  </Box>);
}
const AutoCancelledErrorDialogContent = ({ reason = ''}: { reason?: string }) => {
  return (<Box sx={{ marginBottom: '36px'}}>
  <Box sx={sxOrderStatusErrorTitle}>
  <Lottie {...errorDefaultOptions} style={{height:55,width:55,margin:'0.5rem 1rem'}} />
    <Box component={'span'} sx={sxStatusTitleLabel}>Order Failed!</Box>
  </Box>
    <Box sx={sxErrorStatusMain}>
      <Box component={'div'} sx={sxSummaryDetailsText}>Your order hasn't been placed.</Box>
      {reason && <Box component={'div'} sx={sxSummaryDetailsText}>{`Reason: ${reason}`}</Box>}
      <Box component={'div'} sx={{...sxSummaryDetailsText, margin: '1rem 3.5rem 0' }}><strong>Please try again or place a Limit Order instead.</strong></Box>
    </Box>
  </Box>);
}

const ErrorDialog = ({ isOpen, handleClose, status }: { isOpen: boolean, handleClose: () => void, status?: ERateOrderStatus }) => {
  const reason: string = getErrorReason(status)
  const content = status === ERateOrderStatus.AUTO_CANCELLED ? (<AutoCancelledErrorDialogContent reason={reason}/>) : (<DefaultErrorDialogContent reason={reason}/>)
  
  const actions = (
    <Box sx={sxConfirmDialogActionButtonContainer}>
      <Button variant="contained" sx={{ ...styleFormButton, ...sxActionButtonFirst } as SxProps} onClick={handleClose}>Close</Button>
    </Box>)
  return (
    <StandardDialog
      open={isOpen}
      sxCustomDialog={sxConfirmationDialog}
      // title={'Error'}
      content={content}
      actions={actions}
    />
  );
}

const TradeDialog = forwardRef(function TradeConfirmationDialog({ onSuccess }: any, ref: any) {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [status, setStatus] = useState<EDialogStatus>(EDialogStatus.WAIT_FOR_CONFIRMATION)
    const [params, setParams] = useState<DialogParams | undefined>()
    const [lastOrder, setLastOrder] = useState<BaseRateOrder| undefined>();
    const auth = useAuth();
    const snackbar = useSnackbar();

    const isPrimaryFixedRate = useMemo(() => !isUndefined(params?.primaryMarket?.daysToMaturity), [params])
    const isSecondaryFixedRate = useMemo(() => !isUndefined(params?.secondaryMarket?.daysToMaturity), [params])

    useImperativeHandle(ref, () => {
        return {
          openDialog(dialogParams: DialogParams) {
            sendGA('order_dialog',{'trade_type':`${dialogParams.tradeType}`,'trade_direction':dialogParams.tradeParams.primaryLeg.side?'borrow':'deposit'});
            setIsOpen(true)
            setStatus(EDialogStatus.WAIT_FOR_CONFIRMATION)
            setParams(dialogParams)
          }
        };
      }, []);
    
    const handleConfirm = useCallback(
      async () => {
        setStatus(EDialogStatus.ORDER_IN_PROGRESS)
        if (isEmpty(params)) {
          setStatus(EDialogStatus.ERROR)
          return
        }
        sendGA('order_confirm',{'trade_type':`${params.tradeType}`,'trade_direction':params.tradeParams.primaryLeg.side?'borrow':'deposit'});
        if (params.tradeType === ETradeType.SINGLE || params.tradeType === ETradeType.UNWIND_POSITION) {
          const { marketId, accountId, side, orderType, quantity, adjustedRate, clientOrderId } = params.tradeParams.primaryLeg
          const orderParams: IRateOrderParams = {
            marketId,
            accountId,
            side,
            orderType, //  1 for market order, 2 for limit order
            quantity, 
            rate: adjustedRate?.toString() ?? '', //   Only required for limit order - convert to string
            clientOrderId
          }
          try{
            const order = await (isPrimaryFixedRate ?
              apis.fixedrate.postRateOrder(auth,orderParams) :
              apis.rate.postRateOrder(auth,orderParams)
            );
            if (order !== undefined) {
              if ([ERateOrderStatus.AUTO_CANCELLED, ERateOrderStatus.MARKET_EXPIRED].includes(order.status)) {
                setStatus(EDialogStatus.ERROR);
              } else {
                setStatus(EDialogStatus.ORDER_PLACED);
              }
              setLastOrder(order);
              onSuccess && onSuccess()
              sendGA('order_success',{'trade_type':`${params.tradeType}`,'trade_direction':params.tradeParams.primaryLeg.side?'borrow':'deposit'});
            } else {
              setStatus(EDialogStatus.ERROR)
              sendGA('order_error',{'trade_type':`${params.tradeType}`,'trade_direction':params.tradeParams.primaryLeg.side?'borrow':'deposit'});
            }
          } catch(error) {
            setStatus(EDialogStatus.ERROR)
            snackbar.error(error);
            sendGA('order_error',{'trade_type':`${params.tradeType}`,'trade_direction':params.tradeParams.primaryLeg.side?'borrow':'deposit'});
          }
        } else if ([ETradeType.INTEREST_RATE_SWAP, ETradeType.CROSS_CURRENCY_SWAP].includes(params.tradeType)) {
          const { marketId, accountId, side, orderType, quantity, clientOrderId } = params.tradeParams.primaryLeg
          const orderParamsPrimary: IRateOrderParams = {
            marketId,
            accountId,
            side,
            orderType, //  1 for market order, 2 for limit order
            quantity, 
            rate: '', //   Only required for limit order - convert to string
            clientOrderId
          }
          const leg = params.tradeParams.secondaryLeg;
          if(!leg){
            setStatus(EDialogStatus.ERROR)
            snackbar.error('Secondary leg missing!');
            return;
          }
          const orderParamsSecondary: IRateOrderParams = {
            marketId: leg.marketId,
            accountId: leg.accountId,
            side: leg.side,
            orderType: leg.orderType, //  1 for market order, 2 for limit order
            quantity: leg.quantity, 
            rate: '', //   Only required for limit order - convert to string
            clientOrderId: leg.clientOrderId,
          }
          try{
            const primaryOrder = await apis.fixedrate.postRateOrder(auth,orderParamsPrimary);
            const secondaryOrder = isSecondaryFixedRate ? await apis.fixedrate.postRateOrder(auth, orderParamsSecondary) : await apis.rate.postRateOrder(auth,orderParamsSecondary);
            if (primaryOrder !== undefined && secondaryOrder !== undefined) {
              setStatus(EDialogStatus.ORDER_PLACED);
              setLastOrder(primaryOrder);
              onSuccess && onSuccess()
              sendGA('order_success',{'trade_type':`${params.tradeType}`,'trade_direction':params.tradeParams.primaryLeg.side?'borrow':'deposit'});
            } else {
              setStatus(EDialogStatus.ERROR)
              sendGA('order_error',{'trade_type':`${params.tradeType}`,'trade_direction':params.tradeParams.primaryLeg.side?'borrow':'deposit'});
            }
          } catch(error) {
            setStatus(EDialogStatus.ERROR)
            snackbar.error(error);
            sendGA('order_error',{'trade_type':`${params.tradeType}`,'trade_direction':params.tradeParams.primaryLeg.side?'borrow':'deposit'});
          }
        }
      }, [params, auth, snackbar, onSuccess, isPrimaryFixedRate, isSecondaryFixedRate]
    );

    const handleClose = useCallback(
      () => {
        setIsOpen(false)
      }, [setIsOpen]
    );

    const getDialogByStatus =  useCallback(
      () => {
        switch (status) {
          case EDialogStatus.WAIT_FOR_CONFIRMATION: 
            return <ConfirmationDialog handleClose={handleClose} isOpen={isOpen} isFixedRate={isPrimaryFixedRate} params={params!} status={status} handleConfirm={handleConfirm}/>
          case EDialogStatus.ORDER_IN_PROGRESS:
            return <InProgressDialog isOpen={isOpen} tradeType={params!.tradeType} orderType={params!.tradeParams.primaryLeg.orderType} side={params!.tradeParams.primaryLeg.side} />
          case EDialogStatus.ORDER_PLACED:
            return !isEmpty(lastOrder) ? <SummaryDialog isOpen={isOpen} isFixedRate={isPrimaryFixedRate} lastOrder={lastOrder} tokenCode={params!.primaryToken?.code ?? ''} handleClose={handleClose} tradeType={params!.tradeType} params={params!}/> : null
          case EDialogStatus.ERROR:
            return <ErrorDialog handleClose={handleClose} isOpen={isOpen} status={lastOrder?.status}/>
          default:
            return <ErrorDialog handleClose={handleClose} isOpen={isOpen} />

        }
      }, [status, params, handleClose, handleConfirm, isOpen, lastOrder, isPrimaryFixedRate]
    );

    return (
        <>
        {(isOpen && !isEmpty(params)) ? 
            getDialogByStatus() : null
        }
        </>
    );
  });

  export default TradeDialog