// TODO add zod typeguard
import { useQuery } from "@tanstack/react-query";
import Decimal from 'decimal.js';
import { AuthContextType } from 'src/AuthProvider';
import { EOrderSide, GroupedRateTransaction, RateLPPosition, RateLPPositionGroup } from '../../types';
import { get, post, postFD } from './common';


/**
 * fetch LP positions
 * */
export function useUserLPPositions(auth:AuthContextType,{accountId,marketId,disabled}:{accountId?:number,marketId?:number,disabled?:boolean}={}) {
  return useQuery(['user',{user:auth.user,type:'lp',action:'listPositions',accountId, marketId}],async()=>await _fetchUserLPPositions(auth,{accountId, marketId}),{
    enabled:!disabled&&!!(auth.user&&accountId)
  });
}
async function _fetchUserLPPositions(auth:AuthContextType,{accountId,marketId}:{accountId?:number,marketId?:number}={}):Promise<RateLPPosition[]> {
  const { data } = await get(`/api/user/floating_rate/lps`, {
    accountId, marketId
  },auth);
  const { positions } = data;
  return positions;
}
export function useUserLPPositionGroups(auth:AuthContextType,{accountId,marketId}:{accountId?:number,marketId?:number}={}) {
  return useQuery(['user',{user:auth.user,type:'lp',action:'listPositionGroups',accountId, marketId}],async()=>await _fetchUserLPPositionGroups(auth,{accountId, marketId}),{
    enabled:!!(auth.user&&accountId)
  });
}
async function _fetchUserLPPositionGroups(auth:AuthContextType,{accountId,marketId}:{accountId?:number,marketId?:number}={}):Promise<RateLPPositionGroup[]> {
  const { data } = await get(`/api/user/floating_rate/lps`, {
    accountId, marketId
  },auth);
  const { positions } = data;
  const groupMap = positions?.reduce((groupMap:Map<string,RateLPPositionGroup>,lp:RateLPPosition)=>{
    const { marketId, accountId, side } = lp;
    const key = `${marketId}`;
    const {totalSize,matchedSize,fee,positions=[],createDate} = groupMap[key]||{};
    const signum = side==EOrderSide.BORROW?-1:1;
    positions.push(lp);
    groupMap[key] = {...groupMap[key]||{marketId,accountId,side},
      positions,
      totalSize:new Decimal(totalSize||0).add(new Decimal(lp.totalSize||0).mul(signum)).toString(),
      matchedSize:new Decimal(matchedSize||0).add(new Decimal(lp.matchedSize||0).mul(signum)).toString(),
      fee:new Decimal(fee||0).add(new Decimal(lp.fee||0)).toString(),
      createDate: Math.max(createDate||0,lp.createDate),
    };
    return groupMap;
  },{});
  const groups = Object.keys(groupMap).map(key=>groupMap[key]);
  return groups;
}

/**
 * fetch LP positions
 * */
export function useUserLPPositionTransactions(auth:AuthContextType,{lpId,startTrxId,limit}:{lpId?:number,startTrxId?:number,limit?:number}) {
  return useQuery(['user',{user:auth.user,type:'lp',action:'listTransactions',lpId,startTrxId,limit}],async()=>await _fetchUserLPPositionTransactions(auth,{lpId:lpId!,startTrxId,limit}),{
    enabled:!!(auth.user&&lpId)
  });
}
async function _fetchUserLPPositionTransactions(auth:AuthContextType,{lpId,startTrxId,limit}:{lpId:number,startTrxId?:number,limit?:number}):Promise<GroupedRateTransaction[]> {
  const { data } = await get(`/api/user/floating_rate/lp/trxs`, {
    lpId
  },auth);
  const { trxs }:{ trxs:GroupedRateTransaction[] } = data;
  return trxs;
}

/**
 * Create rate market LP position
 * */
export async function createLPPosition(params:ICreateLPPositionParams):Promise<RateLPPosition> {
  // if(!(params.rangeFrom&&new Decimal(params.rangeFrom||'').gt(0))){
  //   throw 'Invalid range';
  // }
  const { data } = await post(`/api/floating_rate/lp`, params);
  const { position } = data;
  return position;
}
export interface ICreateLPPositionParams {
  marketId: number; //
  accountId: number; //
  side: EOrderSide; //  - 1 for borrow, 0 for lend
  rangeFrom: string; //  - Interest rate range from. Must be >= 0. Must be divisible by rate market's rateStep
  rangeTo: string; //  - Integer rate range to. Must be divisible by rate market's rateStep
  totalSize: string; //  - Total order quantity. totalSize / (rangeTo - rangeFrom) Must be divisible by rate market's quantitySize
};


/**
 * Update rate market LP position
 * */
export async function updateLPPosition(params:IUpdateLPPositionParams):Promise<RateLPPosition> {
  if(params.rangeFrom<0){
    throw 'Invalid range';
  }
  const { data } = await post(`/api/floating_rate/lp/${params.lpPositionId}`, params);
  const { position } = data;
  return position;
}
export interface IUpdateLPPositionParams {
  lpPositionId: number;
  rangeFrom: number; //  - Interest rate range from. Must be >= 0. Must be divisible by rate market's rateStep
  rangeTo: number; //  - Integer rate range to. Must be divisible by rate market's rateStep
  totalSize: number; //  - Total order quantity. totalSize / (rangeTo - rangeFrom) Must be divisible by rate market's quantitySize
};


/**
 * Unwind rate market LP position
 * */
export async function unwindLPPosition(params:IUnwindLPPositionParams):Promise<void> {
  const { data } = await postFD(`/api/floating_rate/lp/unwind`,{
    lpId: params.lpPositionId
  });
  return;
}
export interface IUnwindLPPositionParams {
  lpPositionId: number;
};


/**
 * Transfer instant rate position between accounts. Cannot transfer if the positions are in different direction
 * */
export async function transferIRPosition(params:ITransferIRPositionParams):Promise<void> {
  if(params.fromAccountId==params.toAccountId){
    throw 'Different accounts required';
  }
  const { data } = await post(`/api/user/account/transfer/position/instantRate`, params);
  return;
}
export interface ITransferIRPositionParams {
  fromAccountId: number;
  toAccountId: number;
  marketId: number;
  quantity: number;
};




