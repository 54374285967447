import { SxProps } from '@mui/system';
import { sxMonoFont } from './General';

export const gridHeaderHeight = 32;
export const gridRowHeight = 24;

export const sxGridHeader: SxProps = {
  ".ag-header": {
    bgcolor: 'background.paper',
    zIndex: 1,
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: 'border.dark',
    borderBottomWidth: '1px',
  },
  ".ag-header-cell": {
    px:'0px',
    fontSize: '0.75rem',
    fontWeight: 700,
    fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    fontSmoothing: 'antialiased',
    WebkitFontSmoothing: 'antialiased',
    textTransform: 'uppercase',
    ".ag-header-cell-label": {
      justifyContent:'flex-end',
    },
    ":last-child": {
      px: '12px'
    }
  },
};

export const sxRowsContainer:SxProps & { ".ag-row": object; ".ag-cell": object;}= {
  width:'100%',
  fontSize: '0.875rem',
  ...sxGridHeader,
  ".ag-row":{
    ":hover":{
      cursor:'pointer',
      bgcolor:'table.light',
    },
    ":not(:hover)":{
      "&.ag-row-odd":{
        bgcolor:'table.main',
      },
      "&.ag-row-even":{
        bgcolor:'table.dark',
      },
    }
  },
  ".ag-cell": {
    ...sxMonoFont,
    borderWidth: 0,
    // borderStyle: 'solid',
    // borderColor: 'border.dark',
    // borderBottomWidth: '1px',
    // position:'relative',
    display: 'grid', alignItems: 'center', height: '100%',
    px: '0px',
    ".ag-react-container": { 
      display: 'contents',
    },
    ":last-child": {
      px: '12px'
    }
  },
};