import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Decimal from 'decimal.js';
import { useAuth } from 'src/AuthProvider';
import useAppStore from 'src/store';
import { sxCellBottomAlign, sxEmptyRow, sxTokenTable, sxTokenTableContainer } from 'src/styles/TokenTable';
import { MTMPerformanceItem, Token } from 'src/types';
import { formatStartOfDayWithHyphenDate } from 'src/utils/date';
import { formatUSD } from 'src/utils/numbers';
import apis from '../utils/apis';
import LoadingIcon from './Loading';
import ValueTableCell from './ValueTableCell';

function MTMPerformanceTable({tokens}:{tokens?:Token[]}){
  const auth = useAuth();  
  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  const { currentUserAccountId } = useAppStore();
  const tradeAccount = userAccounts&&apis.user.extractUserAccount(userAccounts,currentUserAccountId);
  const { data:mtmPerformanceByToken, isLoading } = apis.user.useUserMTMPerformanceByToken(auth,{
    accountId: tradeAccount?.accountId, 
  });
  const isLoadingAny = isLoading;

  const dataKeyMap = {};
  const dataByDateMap = {};
  mtmPerformanceByToken&&Object.keys(mtmPerformanceByToken).forEach((tokenId)=>{
    const token = tokens?.find(t=>t.tokenId===Number(tokenId));
    if(token){
      const label = token.code;
      mtmPerformanceByToken[tokenId]?.forEach((item:MTMPerformanceItem)=>{
        const date = item.date;
        const cashPosition = new Decimal(item.cashPosition||0).toNumber();
        const unrealized = new Decimal(item.unrealized||0).toNumber();
        const cashPositionKey = `${token.code}: Cash Position`;
        const unrealizedKey = `${token.code}: Unrealized`;
        dataKeyMap[cashPositionKey] = true;
        dataKeyMap[unrealizedKey] = true;
        dataByDateMap[date] = {...dataByDateMap[date]||{},label,date,[cashPositionKey]:cashPosition,[unrealizedKey]:unrealized};
      });
    }
  })
  
  return (<>
    <TableContainer sx={sxTokenTableContainer}>
      <Table sx={sxTokenTable}>
        <TableHead>
          <TableRow>
            <ValueTableCell sx={sxCellBottomAlign} sticky>Date</ValueTableCell>
            {Object.keys(dataKeyMap).map((dataKey,i)=>{
              return (<ValueTableCell key={dataKey} align="right">{dataKey}</ValueTableCell>)
            })}
            <ValueTableCell align="right">Total</ValueTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(dataByDateMap).map((dateKey,i)=>{
            let totalDecimal = new Decimal(0);
            const dataKeyCells = Object.keys(dataKeyMap).map((dataKey,i)=>{
              const value = dataByDateMap[dateKey][dataKey];
              if(dataKey.includes('Cash Position')){
                totalDecimal = totalDecimal.add(new Decimal(value||0));
              }
              return (<ValueTableCell mono key={dataKey} align="right">{(value&&`$${formatUSD(value)}`)||'-'}</ValueTableCell>)
            });
            return (
              <TableRow hover key={dateKey}>
                <ValueTableCell mono sticky>{formatStartOfDayWithHyphenDate(Number(dateKey))}</ValueTableCell>
                {dataKeyCells}
                <ValueTableCell mono align="right">${formatUSD(totalDecimal)}</ValueTableCell>
              </TableRow>
            );
          })}
          {!isLoadingAny&&Object.keys(dataByDateMap)?.length === 0 && <TableRow hover sx={sxEmptyRow}>
            <ValueTableCell colSpan={10}>No history yet.</ValueTableCell>
          </TableRow>}
          {isLoadingAny&&<TableRow hover><ValueTableCell colSpan={10} sx={{textAlign:'center'}}><LoadingIcon inline={true}/></ValueTableCell></TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  </>);
}

export default MTMPerformanceTable;