import { Box, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Decimal from 'decimal.js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AccountAddress from 'src/Components/WalletAddress';
import { sxBox, sxBoxCompactInner } from 'src/styles/General';
import { sxTable, sxVerticalSeparator } from 'src/styles/Table';
import { sxLabel } from 'src/styles/TokenTable';
import { format, formatUSDWithSign, isValidDecimal } from 'src/utils/numbers';
import { useAuth } from '../AuthProvider';
import { AccountsLossGivenDefault } from '../types';
import apis from '../utils/apis';
import LoadingIcon from './Loading';
import TokenIcon from './TokenIcon';

const sxPadBottom:SxProps = {
  "td:is(td)":{
    paddingBottom: 2,
  }
}
const sxNoPadRight:SxProps = {
  paddingRight: 0, 
};
const sxNoPadLeft:SxProps = {
  paddingLeft: 0, 
};
const sxVerticalSeparatorNoPadLeft:SxProps ={
  ...sxNoPadLeft, ...sxVerticalSeparator
}

const coverageLimitRange = (d:Decimal)=>Decimal.max(-100,Decimal.min(100,d));
const formatLossPoint = (lossPoint:Decimal)=>lossPoint.toDP(1).lte(-100)||lossPoint.toDP(1).gte(0)?'-':formatPercent(lossPoint);
const formatPercent = (p:Decimal)=>!isValidDecimal(p)?'-':`${format(coverageLimitRange(p).mul(100).toDP(1),1)}%`;

function ExploreAccountsPanel(){
  const auth = useAuth();
  const navigate = useNavigate();
  const [priceChange,setPriceChange] = useState(0.9);
  const {data:allTokens} = apis.token.useTokens();
  const {isLoading,data:accountsLossGivenDefault} = apis.infinity.useAccountsLossGivenDefault({priceChange});
  const tokens = Object.keys(accountsLossGivenDefault||{}).map((tokenIdString:string)=>allTokens?.filter(t=>t.tokenId==Number(tokenIdString))[0]).filter(t=>t!=undefined);
  const priceChangePercent = Math.round((1-priceChange)*100);
  const accountProfileMap:{[address:string]:{[tokenIdString:string]:AccountsLossGivenDefault}} = {};
  accountsLossGivenDefault&&Object.keys(accountsLossGivenDefault).map((tokenIdString:string)=>{
    accountsLossGivenDefault[tokenIdString].map(accountData=>{
      accountProfileMap[accountData.address] = accountProfileMap[accountData.address]||{};
      accountProfileMap[accountData.address][tokenIdString] = accountData;
    });
  });
  let infinityAsset = new Decimal(0);
  let infinityLiability = new Decimal(0);
  let infinityHealthscoreSum = new Decimal(0);
  let infinityTokenValueMap:{[tokenIdString:string]:Decimal} = {};
  let infinityTokenDeltaCoverageMap:{[tokenIdString:string]:Decimal} = {};
  let infinityTokenLossPointMap:{[tokenIdString:string]:Decimal} = {};
  const accountRows = Object.keys(accountProfileMap).map((address,i)=>{
    const tokenEntries = accountProfileMap[address];
    let originalAsset = new Decimal(0);
    let originalLiability = new Decimal(0);
    let newAsset = new Decimal(0);
    let newLiability = new Decimal(0);
    let healthscoreSum = new Decimal(0);
    Object.keys(tokenEntries).map(tokenIdString=>{
      const entry = tokenEntries[tokenIdString];
      originalAsset = originalAsset.add(new Decimal(entry.originalAsset));
      originalLiability = originalLiability.add(new Decimal(entry.originalLiability));
      newAsset = newAsset.add(new Decimal(entry.newAsset));
      newLiability = newLiability.add(new Decimal(entry.newLiability));
      healthscoreSum = healthscoreSum.add(new Decimal(entry.healthscore));
      infinityTokenValueMap[tokenIdString] = (infinityTokenValueMap[tokenIdString]||new Decimal(0)).add(new Decimal(entry.newAsset).sub(new Decimal(entry.newLiability)));
    });
    const healthscore = healthscoreSum.div(tokens.length);
    const coverage = (originalAsset.div(originalLiability.abs()));
    infinityAsset = infinityAsset.add(originalAsset);
    infinityLiability = infinityLiability.add(originalLiability);
    infinityHealthscoreSum = infinityHealthscoreSum.add(healthscore);
    return (
    <TableRow hover key={i}>
      <TableCell colSpan={1} sx={sxVerticalSeparator}><AccountAddress address={address} length={8}/></TableCell>
      <TableCell>{formatUSDWithSign(originalAsset,true)}</TableCell>
      <TableCell>{formatUSDWithSign(originalLiability.abs(),true)}</TableCell>
      <TableCell>{formatPercent(healthscore)}</TableCell>
      <TableCell sx={sxVerticalSeparator}>{formatPercent(coverage)}</TableCell>
      {tokens.map((token,i)=>{
        const tokenIdString = `${token!.tokenId}`;
        const tokenData = tokenEntries[tokenIdString];
        if(!tokenData) return (<TableCell key={token!.code}>-</TableCell>);
        const originalCoverage = (new Decimal(tokenData.originalAsset).div(new Decimal(tokenData.originalLiability).abs()));
        const newCoverage = (new Decimal(tokenData.newAsset).div(new Decimal(tokenData.newLiability).abs()));
        const deltaCoverage = originalCoverage.sub(newCoverage);
        const lossPoint = originalCoverage.sub(100).div(newCoverage).mul(1-priceChange).mul(-1);
        if(isValidDecimal(deltaCoverage)) infinityTokenDeltaCoverageMap[tokenIdString] = (infinityTokenDeltaCoverageMap[tokenIdString]||new Decimal(0)).add(deltaCoverage);
        if(isValidDecimal(lossPoint)) infinityTokenLossPointMap[tokenIdString] = (infinityTokenLossPointMap[tokenIdString]||new Decimal(0)).add(lossPoint);
        return (<>
          <TableCell key={`${token!.code}-delta`} sx={sxNoPadRight}>
            {formatPercent(deltaCoverage)}
          </TableCell>
          <TableCell key={`${token!.code}-lossPoint`} sx={i==tokens.length-1?sxVerticalSeparatorNoPadLeft:sxNoPadLeft} align='left'>
            <Box sx={sxLabel}>{formatLossPoint(lossPoint)}</Box>
          </TableCell>
        </>);
      })}
      <TableCell colSpan={1}>-</TableCell>
    </TableRow>
    )
  });
  console.log('infinityTokenDeltaCoverageMap',infinityTokenDeltaCoverageMap);
  const infinityCoverage = (infinityAsset.div(infinityLiability.abs()));
  const infinityHealthscore = infinityHealthscoreSum.div(accountRows.length);
  return (
    <Box sx={sxBox}>
      <Box sx={sxBoxCompactInner}>
        <TableContainer>
          <Table sx={sxTable}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={1} sx={sxVerticalSeparator}>{/* address */}</TableCell>
                <TableCell colSpan={4} sx={sxVerticalSeparator}>Baseline</TableCell>
                <TableCell colSpan={tokens.length*2 /* two columns per token for delta */} sx={sxVerticalSeparator}>Price Sensitivity ({priceChangePercent}% Delta)</TableCell>
                <TableCell colSpan={1}>Joint - {priceChangePercent}% $ LGD</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={1} sx={sxVerticalSeparator}>{/* address */}</TableCell>
                <TableCell>Asset</TableCell>
                <TableCell>Liabilities</TableCell>
                <TableCell>H/S</TableCell>
                <TableCell sx={sxVerticalSeparator}>Coverage</TableCell>
                {tokens.map((t,i)=>(
                  <TableCell key={i} sx={i==tokens.length-1?sxVerticalSeparator:{}} colSpan={2}><TokenIcon token={t}/>{t?.code}</TableCell>
                ))}
                <TableCell colSpan={1}></TableCell>
              </TableRow>
              </TableHead>
            <TableBody>
            {isLoading&&<TableRow hover><TableCell colSpan={100} sx={{textAlign:'center'}}><LoadingIcon inline={true}/></TableCell></TableRow>}
            {!isLoading&&(
            <TableRow hover sx={sxPadBottom}>
              <TableCell colSpan={1} sx={sxVerticalSeparator}>Infinity</TableCell>
              <TableCell>{formatUSDWithSign(infinityAsset,true)}</TableCell>
              <TableCell>{formatUSDWithSign(infinityLiability.abs(),true)}</TableCell>
              <TableCell>{formatPercent(infinityHealthscore)}</TableCell>
              <TableCell sx={sxVerticalSeparator}>{formatPercent(infinityCoverage)}</TableCell>
              {tokens.map((token,i)=>{
                const tokenIdString = `${token!.tokenId}`;
                const deltaCoverageSum = infinityTokenDeltaCoverageMap[tokenIdString];
                const lossPointSum = infinityTokenLossPointMap[tokenIdString];
                return (<>
                  <TableCell key={`${token!.code}-delta`} sx={sxNoPadRight}>
                    {!deltaCoverageSum?'-':formatPercent(deltaCoverageSum.div(accountRows.length))}
                  </TableCell>
                  <TableCell key={`${token!.code}-lossPoint`} sx={i==tokens.length-1?sxVerticalSeparatorNoPadLeft:sxNoPadLeft} align='left'>
                    {!lossPointSum?'-':<Box sx={sxLabel}>{formatLossPoint(lossPointSum.div(accountRows.length))}</Box>}
                  </TableCell>
                </>);
              })}
              <TableCell colSpan={1}>-</TableCell>
            </TableRow>
            )}
            {accountRows}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default ExploreAccountsPanel;