import { ArrowBack } from "@mui/icons-material";
import { Breadcrumbs, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

function BreadcrumbsBack(){
  const navigate = useNavigate();
  return <Breadcrumbs aria-label="breadcrumb" sx={{
    fontSize: '0.875rem',
    marginBottom: '1rem',
  }}><Link sx={{
    cursor: 'pointer',
    color: 'text.primary',
    textDecoration: 'underline',
    textDecorationColor: 'text.primary',
    "svg": {
      fontSize: '0.875rem',
      verticalAlign: 'middle',
    },
  }} onClick={()=>navigate(-1)}><ArrowBack/>Back</Link></Breadcrumbs>;
}


export default BreadcrumbsBack;