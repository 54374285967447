import React from "react";
import { useAuth } from "src/AuthProvider";
import ConnectWalletPromptPanel from 'src/Components/ConnectWalletPromptPanel';
import useAppStore from "src/store";

const withConnectWallet = <P extends {}>(WrappedComponent: {
  (props: P): JSX.Element;
  displayName?: string;
}): {
  (props: P): JSX.Element;
  displayName: string;
} => {
  function ConnectWallet(props: P) {
    const auth = useAuth();
    const { loginState } = useAppStore();
    return (
      <>
        <WrappedComponent {...props} />
        {loginState!=='INIT' && !auth?.user&&<ConnectWalletPromptPanel/>}
      </>
    );
  }

  ConnectWallet.displayName = getDisplayName(WrappedComponent);

  return ConnectWallet;
};

function getDisplayName(WrappedComponent: React.FC<any>) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export default withConnectWallet;
