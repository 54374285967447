import { Box } from '@mui/system';
import moment, { Moment } from 'moment';
import { useEffect, useRef, useState } from 'react';
import { sxLabelBold } from 'src/styles/General';
import { formatTimeAgo } from 'src/utils/date';
import { capitalize } from 'src/utils/string';
import LoadingIcon from './Loading';
import ReloadIcon from './ReloadIcon';

export type TReloadDateTextRefs = {
  isFetchingReloadRef: React.MutableRefObject<boolean>;
  handleClickReloadRef: React.MutableRefObject<() => any>;
  lastUpdatedTimeRef: React.MutableRefObject<moment.Moment>;
}
export function useReloadDateTextRefs(){
  const isFetchingReloadRef = useRef<boolean>(false);
  const handleClickReloadRef = useRef<()=>any>(()=>{});
  const lastUpdatedTimeRef = useRef<Moment>(moment());
  return {
    isFetchingReloadRef,
    handleClickReloadRef,
    lastUpdatedTimeRef,
  }
}

function ReloadDateText({
  isFetching,lastUpdatedTime,handleClick,reloadDateTextRefs,
}:{
  isFetching?:boolean,lastUpdatedTime?:Moment,handleClick?:()=>any,reloadDateTextRefs?:TReloadDateTextRefs,
}){
  if(reloadDateTextRefs!==undefined){
    isFetching = reloadDateTextRefs.isFetchingReloadRef.current;
    lastUpdatedTime = reloadDateTextRefs.lastUpdatedTimeRef.current;
    handleClick = reloadDateTextRefs.handleClickReloadRef.current;
  }
  const [time,setTime] = useState(Date.now());
  useEffect(()=>{
    const interval = setInterval(()=>setTime(Date.now()),1000);
    return ()=>{
      clearInterval(interval);
    }
  },[]);
  
  if(isFetching===undefined||lastUpdatedTime===undefined||handleClick===undefined) return null;
  const lastUpdatedWithinAMinute = moment().diff(lastUpdatedTime,'m')===0;
  return <Box>
    {isFetching&&<><Box component="span" sx={sxLabelBold}>Refreshing... </Box><LoadingIcon inline size={12}/></>}
    {!isFetching&&<><Box component="span" sx={sxLabelBold}>Last Updated: {capitalize(formatTimeAgo(lastUpdatedTime))}</Box>{
      !lastUpdatedWithinAMinute&&<ReloadIcon onClick={handleClick}/>
    }</>}
  </Box>;
}

export default ReloadDateText;