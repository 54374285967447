import {
  Box, FormControl, InputAdornment, InputLabel, TextField, Tooltip
} from '@mui/material';
import { SxProps } from '@mui/system';
import Decimal from 'decimal.js';
import React, { useState } from 'react';
import { TOKEN_MAX_DECIMALS } from 'src/constants/app';
import { format, isValidNumberInput } from 'src/utils/numbers';
import { BootstrapInput, styleFormLabel, sxInputLabel, sxInputLabelFloatRight } from '../styles/Form';
import { hslStrToHex } from 'src/utils/common';
import theme from 'src/utils/theme';

interface onAmountChangeFn {(rate:string):void};
function TokenAmountInput({
  name,label,amount,decimals,step,min,max,readOnly,disabled,allowsOutOfRange, placeholder='',
  sx, variant="bootstrap", fullWidth, suffix="", bottomLabel, onAmountChange, error
}:{
  name:string,label?:string|JSX.Element,amount:string,decimals:number,step?:string|number,min?:string,max?:string,readOnly?:boolean,disabled?:boolean,allowsOutOfRange?:boolean, placeholder?:string,
  sx?:SxProps, variant?:"bootstrap"|"standard"|"filled"|"outlined"|undefined, fullWidth?:boolean, suffix?:string, bottomLabel?:string|JSX.Element, onAmountChange?:onAmountChangeFn, error?: string|JSX.Element
}){
  const [intermediateValue,setIntermediateValue] = useState<string|undefined>();
  // global level token decimals should override token chain decimals
  decimals = Math.min(decimals,TOKEN_MAX_DECIMALS);
  if(step!=undefined){
    step = new Decimal(step).toFixed(decimals);
  }else{
    step = new Decimal(1).div(Math.pow(10,decimals)).toFixed(decimals);
  }
  if(min&&new Decimal(min).lt(new Decimal(step))){
    // console.log('token amount input set min to step', min, '->', step);
    min = step;
  }
  function handleChange(event:React.ChangeEvent<{value:string}>){
    if (isValidNumberInput(event.target.value)) {
      setIntermediateValue(event.target.value);
    }
  }
  function handleKeyPress(event:React.KeyboardEvent){
    switch(event.key){
      case 'Enter':
        triggerValueUpdate((event.target as any).value);
        break;
    }
  }
  function handleBlur(event:React.ChangeEvent<{value:string}>){
    triggerValueUpdate(event.target.value);
  }
  function triggerValueUpdate(value:string){
    if(value === '' && amount === '') return; // ignore empty string value
    value = value.replaceAll(/[^\.\d]/g,'')
    try{
      let _amount = new Decimal(value||'0');
      const _min = new Decimal(min||0);
      const _max = new Decimal(max||Infinity);
      if(!allowsOutOfRange){
        if(_amount.lt(_min)) _amount=_min;
        if(_amount.gt(_max)) _amount=_max;
      }
      onAmountChange&&onAmountChange(_amount.toDecimalPlaces(decimals,Decimal.ROUND_DOWN).toString());
    }catch(error){
    }
    setIntermediateValue(undefined);
  }
  if(variant === 'bootstrap') {
    return (<FormControl variant="standard" fullWidth={fullWidth} sx={{position: 'relative'}}>
      <InputLabel shrink htmlFor={`input-${name}`} sx={sxInputLabel}>{label}</InputLabel>
      {bottomLabel&&<InputLabel shrink htmlFor={`input-${name}`} sx={sxInputLabelFloatRight}>{bottomLabel}</InputLabel>}
      <BootstrapInput error={!!error} name={name} type='text' sx={{width:'100%'}} id={`input-${name}`}
        endAdornment={(<InputAdornment position="end">{suffix}</InputAdornment>)}
        inputMode={'numeric'}
        readOnly={readOnly}
        placeholder={placeholder}
        inputProps={{
          // min/max needs to be multiplied as well for step (step takes into account of min/max)
          step:step, 
          min: !min?'':new Decimal(min), 
          max: !max?'':new Decimal(max),
        }}
        disabled={disabled}
        value={intermediateValue!=undefined?intermediateValue:(amount&&format(new Decimal(amount),decimals))}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
      />
      {error !== '' && !!error && <InputLabel htmlFor={`input-${name}`} shrink sx={{ fontSize: '0.875rem', position: 'absolute', top: '110%', left: '0', color: `${hslStrToHex(theme.palette.error.main)}`}}>{error}</InputLabel>}
    </FormControl>);
  }
  return (<Box sx={sx||{}}>
    <TextField name={name} label={typeof label=='string'&&label} type='text' id={`input-${name}`}
      sx={{width:'100%'}} variant={variant||'standard'}
      InputLabelProps={{shrink: true}}
      InputProps={{
        endAdornment:(<InputAdornment position="end">{suffix}</InputAdornment>),
        inputMode: 'numeric', 
        readOnly: readOnly,
        inputProps: {
          // min/max needs to be multiplied as well for step (step takes into account of min/max)
          step:step, 
          min: !min?'':new Decimal(min), 
          max: !max?'':new Decimal(max),
        },
      disabled: disabled,
      }}
      value={intermediateValue!=undefined?intermediateValue:(amount&&format(new Decimal(amount),decimals))}
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
    />
    <Box><Box sx={styleFormLabel} style={{textAlign:'right'}}>{bottomLabel}</Box></Box>
  </Box>);
}

export default TokenAmountInput;