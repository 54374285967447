import { Info, OpenInNew } from '@mui/icons-material';
import { Box, Link, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import { SxProps } from '@mui/system';
import { useMemo, useState } from 'react';
import { INFINITY_CHAIN_ID_ETHEREUM } from 'src/constants/app';
import { sxInlineLink, sxMobileOnly } from 'src/styles/General';
import apis from 'src/utils/apis';
import { getChainLabel, getChainName, getEtherscanBaseUrl } from 'src/utils/common';
import LoadingIcon from './Loading';
import { useAuth } from 'src/AuthProvider';
import theme from 'src/utils/theme';
import InfoTooltipIcon from './InfoTooltipIcon';

export const FOOTER_HEIGHT_VALUE = 32;
export const FOOTER_HEIGHT = `${FOOTER_HEIGHT_VALUE}px`;

const sxFooterBlock:SxProps = {
  borderRightWidth: '1px',
  borderRightColor: 'border.dark',
  borderRightStyle: 'solid',
  px: '12px',
  userSelect: 'none',
};

const sxFooter:SxProps = {
  gridArea: 'footer',
  bgcolor:'background.paper',
  borderTopColor: 'border.dark',
  top: 0, zIndex: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'stretch',
  width:'100%',
  fontSize: '0.75rem',
  color: 'text.secondary',
  height: FOOTER_HEIGHT,
  lineHeight: FOOTER_HEIGHT,
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
  [theme.breakpoints.down('tablet')]:{
    fontSize: '0.6875rem',
  },
};

const sxShrinkMobile:SxProps = {
  [theme.breakpoints.down('tablet')]:{
    maxWidth: '1rem',
    overflow: 'hidden',
  },
}

const sxServerStatusIcon:SxProps = {display:'inline-block',height:'8px',width:'8px',marginRight:'4px',borderRadius:'50%'};

const StatusTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} PopperProps={{
    modifiers: [{ name: 'offset', options: {offset:[0,0]} }],
  }} 
    enterTouchDelay={0} /* allow mobile click trigger */
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.typography.pxToRem(14),
    padding: '12px',
    margin: '0px',
  },
  [`&:is(.${tooltipClasses.popper})[data-popper-placement] .${tooltipClasses.tooltip}`]: {
    marginBottom: 0,
  },
}));


function Footer() {
  const {data:blockchain,isLoading:isLoadingBlockChain} = apis.infinity.useBlockchain(INFINITY_CHAIN_ID_ETHEREUM);
  const {data:serverStatus,isLoading:isLoadingServerStatus} = apis.infinity.useServerStatus();
  const {data:serverTime,isLoading:isLoadingServerTime} = apis.infinity.useServerTime();
  const isLoadingAny = isLoadingBlockChain || isLoadingServerStatus || isLoadingServerTime;

  const { processedBlockNum } = blockchain ?? {};
  const etherscanBaseUrl = getEtherscanBaseUrl();

  // process status components
  const {serverPing,serverStatusSummary,serverStatusTooltipTitle} = useMemo(()=>{
    let serverStatusText = 'Server Status: Unknown';
    let serverStatusIconColor = 'text.secondary';
    if(serverStatus){
      if(serverStatus.jobServerStatus&&serverStatus.tradeServerStatus&&serverStatus.webServerStatus){
        serverStatusText = 'Server Status: Operational';
        serverStatusIconColor = 'success.main';
      }else{
        serverStatusText = 'Server Status: Limited';
        serverStatusIconColor = 'warning.main';
      }
    }
    const serverStatusTooltipTitle = <>{serverStatusText}</>;
    const serverStatusSummary = <Box><Box sx={{...sxServerStatusIcon,bgcolor:serverStatusIconColor}}/>
      Last Synced Block:&nbsp;
      {isLoadingAny&&<LoadingIcon/>}
      {!isLoadingAny&&<Link sx={{...sxInlineLink,color:'text.primary'}} href={`${etherscanBaseUrl}/block/${processedBlockNum}`} target="_blank">{processedBlockNum} <OpenInNew fontSize='inherit' sx={{marginBottom:'-2px'}}/></Link>}
    </Box>;
    // TODO server ping doesn't make sense with client time & server time - need API call level implementation
    const serverPing = serverTime && (serverTime?.clientTime - serverTime?.serverTime);
    return {serverPing,serverStatusSummary,serverStatusTooltipTitle};
  },[serverStatus,serverTime,etherscanBaseUrl,isLoadingAny,processedBlockNum]);

  // render
  return (
    <Box sx={sxFooter}>
      <Box sx={sxFooterBlock}><ConnectedChain/></Box>
      {/* <Box sx={sxFooterBlock}>{serverPing}ms</Box> */} {/* // TODO see above  */}
      <Box sx={sxFooterBlock}><StatusTooltip title={serverStatusTooltipTitle}>{serverStatusSummary}</StatusTooltip></Box>
      <Box sx={{flex:1}}></Box>
      <Box sx={sxFooterBlock}><Box sx={sxShrinkMobile}>
        <Box sx={sxMobileOnly}><InfoTooltipIcon title="Scheduled Maintenance Window: 14:00-15:00 (GMT+8)"/></Box>
        Scheduled Maintenance Window: 14:00-15:00 (GMT+8)
      </Box></Box>
    </Box>
  );
}

function ConnectedChain() {
  const auth = useAuth();
  const {chainId} = auth.wallet;
  return <StatusTooltip title={`Connected to ${getChainName(chainId)}`}><Box sx={{fontWeight:700}}>{getChainLabel(chainId)}</Box></StatusTooltip>;
}

export default Footer;
