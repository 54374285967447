import { Box, SxProps } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { sxCellLoading } from '../styles/General';


const ANIMATION_DURATION = '1200MS'

export const sxCurtain:SxProps = {
  zIndex: 100, display: 'grid', alignItems: 'center', justifyContent: 'center',
  "&,:before":{
    position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
  },
  ":before":{
    content:'""', zIndex: -1,bgcolor: 'background.default', opacity: 0.5,
  },
};

export const LoadingIcon = ({inline=false,curtain=false, size}:{inline?:boolean,curtain?:boolean, size?:number}) => {
  const el = (
  <Box sx={inline?{display:'inline-block',verticalAlign:'bottom'}:null}>
    <Box sx={{
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    }} title="Loading...">
      <CircularProgress color="primary" variant="indeterminate" disableShrink size={size} sx={{ animationDuration: ANIMATION_DURATION}}/>
    </Box>
  </Box>);
  if(curtain){
    return <Box sx={sxCurtain}>{el}</Box>;
  }
  return el;
}

const sxLoadingDots: SxProps = (color) => ({
  ":after": {
    content: '"."',
    animation: "dots 1s steps(5, end) infinite"
  },
  
  "@keyframes dots": {
    "0%, 20%": {
      color: "rgba(0,0,0,0)",
      textShadow: `
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0)`
    },
    "40%": {
      color: color,
      textShadow: `
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0)`
    },
    "60%": {
      textShadow: `
        .25em 0 0 ${color},
        .5em 0 0 rgba(0,0,0,0)`
    },
    "80%, 100%": {
      textShadow: `
        .25em 0 0 ${color},
        .5em 0 0 ${color}`
      }
    }
  
})

export const LoadingCell = ({ sx }: { sx?: SxProps }) => {
  return (<Box component='span' sx={{ ...(sx ?? {}), ...sxCellLoading } as SxProps}>-</Box>)
}

export const LoadingText = ({text, textColor = 'white', sx}: { text?: string, textColor?: string, sx?: SxProps}) => {
  return <Box component='span' sx={{ ...sxLoadingDots(textColor), ...(sx ?? {}) } as SxProps}>{text}</Box>
}

export default LoadingIcon;
