import { Box, Button, Divider, FormControl, InputLabel, SelectChangeEvent, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useQueryClient } from "@tanstack/react-query";
import Decimal from 'decimal.js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { INFINITY_CHAIN_ID_ETHEREUM, TOKEN_MAX_DECIMALS } from 'src/constants/app';
import useSnackbar from 'src/hooks/useSnackbar';
import { BootstrapMenuItem, BootstrapSelect, sxInputLabel } from 'src/styles/Form';
import { messages } from 'src/utils/messages';
import { useAuth } from '../AuthProvider';
import { sxCompactFormTable, sxInputLabelFloatRight } from '../styles/Form';
import { StylePrimaryButton } from '../styles/General';
import { AccountWalletToken, EUserAccountType, Token, TransferType, TransferData } from '../types';
import apis from '../utils/apis';
import { containsToken } from "../utils/apis/common";
import { format } from '../utils/numbers';
import { EAccountType, ETransferDirectionType, ISelectedTransferAccountToken } from './CashPositionsPanel';
import InfoTooltipIcon from './InfoTooltipIcon';
import TokenAmountInput from './TokenAmountInput';
import TokenIcon from './TokenIcon';
import TokenTransferDialog from './TokenTransferDialog';
import { sendGA } from 'src/utils/common';

function AccountTransferMenu({selectedTransferAccountToken}:{selectedTransferAccountToken?:ISelectedTransferAccountToken}){
  const auth = useAuth();
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const { data:tokens } = apis.token.useTokens();
  const { data:userAccounts } = apis.user.useUserAccounts(auth,(userAccounts)=>refreshSelectedAccountValues(userAccounts,undefined));
  const { data:chainTokens } = apis.user.useChainTokens(auth,tokens,(chainTokens)=>refreshSelectedAccountValues(undefined,chainTokens));
  const { data:withdrawalRequests } = apis.user.useUserWithdrawRequests(auth);

  // needed for init state
  const getSelectedChainToken = useCallback((tokenId: any) => { return chainTokens?.filter((t: Token) => containsToken(t, tokenId))?.at(0)||undefined; }, [chainTokens])
  const getCurrentAccount = useCallback((_userAccounts=userAccounts) => { return _userAccounts?.filter(w=>w.type==EUserAccountType.CURRENT)[0]; }, [userAccounts])
  const getTradingAccounts = useCallback((_userAccounts=userAccounts) => { return _userAccounts?.filter(w=>w.type==EUserAccountType.TRADING); }, [userAccounts])

  // selectedAccountFrom & selectedAccountTo: either 'chain' for on chain account, or `accountId` for userAccount
  type SelectedAccountKey = 'chain'|number|undefined;
  type SelectedAccountFromTokenKey = number|undefined;
  const [ selectedAccountFrom, setSelectedAccountFrom ] = useState('chain' as SelectedAccountKey);
  const [ selectedAccountTo, setSelectedAccountTo ] = useState(getCurrentAccount()?.accountId as SelectedAccountKey);
  // selectedAccountTokenId: which on chain account tokenId or user tokenAccount tokenId to trasnfer from
  const [ selectedAccountFromTokenId, setSelectedAccountFromTokenId ] = useState(chainTokens?.at(0)?.tokenId as SelectedAccountFromTokenKey);
  // transfer amount
  const [amount, setAmount] = useState('');
  const [amountMin, setAmountMin] = useState('0'); // possible min value for withdrawal (fee)
  // transfer states
  const [ isTransferring, setIsTransferring ] = useState(false);
  const [ isShowingConfirmPopup, setIsShowingConfirmPopup ] = useState(false);
  const [ transferEndType, setTransferEndType ] = useState<TransferType|undefined>();
  const [ lastTransferData, setLastTransferData ] = useState<TransferData | undefined>()

  const selectedUserAccount = (userAccounts && selectedAccountFrom !== 'chain' && apis.user.extractUserAccount(userAccounts,selectedAccountFrom)) || undefined;

  const getBalanceByToken = useCallback(
    (selectedAccountKey: any, selectedTokenId: any) => {
      const selectedAccount = (userAccounts && selectedAccountKey !== 'chain' && apis.user.extractUserAccount(userAccounts,selectedAccountKey)) || undefined;
      const selectedUserTokenAccount = (userAccounts && selectedAccount !== undefined && selectedTokenId !== undefined) ? apis.user.extractAccountTokenAccountFromAccounts(userAccounts,selectedAccount.type,selectedTokenId) : undefined;
      const selectedChainToken = getSelectedChainToken(selectedTokenId);
      if (selectedAccountKey === 'chain') {
        return selectedChainToken?.balance || '0';
      } else {
        return selectedUserTokenAccount?.availableQuantity||'0';
      }

    },
    [getSelectedChainToken, userAccounts]
  )
  const balance = useMemo(() => {
    const selectedUserTokenAccount = (userAccounts && selectedUserAccount !== undefined && selectedAccountFromTokenId !== undefined) ? apis.user.extractAccountTokenAccountFromAccounts(userAccounts,selectedUserAccount.type,selectedAccountFromTokenId) : undefined;
    const selectedChainToken = getSelectedChainToken(selectedAccountFromTokenId);
    if (selectedAccountFrom === 'chain') {
      return selectedChainToken?.balance || '0';
    } else {
      return selectedUserTokenAccount?.availableQuantity||'0';
    }
  }, [selectedAccountFrom, getSelectedChainToken, selectedUserAccount, selectedAccountFromTokenId, userAccounts])
  
  function refreshSelectedAccountValues(_userAccounts=userAccounts,_chainTokens=chainTokens) {
    const currentAccount = getCurrentAccount(_userAccounts);
    const selectedAccountFromValue = selectedAccountFrom != undefined ? selectedAccountFrom : 'chain' as SelectedAccountKey;
    const selectedAccountToValue = selectedAccountTo != undefined ? selectedAccountTo : currentAccount?.accountId as SelectedAccountKey;
    let selectedAccountFromTokenIdValue;
    if (selectedAccountFromValue == 'chain') {
      const ct = _chainTokens?.filter(t => containsToken(t, selectedAccountFromTokenId))?.at(0);
      selectedAccountFromTokenIdValue = ct != undefined ? (ct.tokenType == 2 ? parseInt(ct.tokenId.toString() + ct.erc721TokenId) : ct.tokenId) : _chainTokens?.find(ct => ct?.tokenId !== 0 && new Decimal(ct?.balance ?? 0).gt(0))?.tokenId;
    } else {
      const wt = currentAccount?.tokens.filter(t => containsToken(t, selectedAccountFromTokenId))?.at(0);
      const wt721 = wt?.erc721Tokens?.filter(wt721 => parseInt(wt721.tokenId.toString() + wt721.erc721TokenId.toString()) == selectedAccountFromTokenId)[0];
      selectedAccountFromTokenIdValue = wt != undefined ? (wt.tokenType == 2 ? parseInt(wt.tokenId.toString() + wt721?.erc721TokenId) : wt.tokenId) : currentAccount?.tokens?.at(0)?.tokenId;
    }
    if(selectedAccountFrom!=selectedAccountFromValue) setSelectedAccountFrom(selectedAccountFromValue);
    if(selectedAccountTo!=selectedAccountToValue) setSelectedAccountTo(selectedAccountToValue);
    if(selectedAccountFromTokenId!=selectedAccountFromTokenIdValue) setSelectedAccountFromTokenId(selectedAccountFromTokenIdValue);
  }

  const _resetForm = () => {
    setAmount('')
  }

  function getGAParameters(){
    return {'type':
      selectedAccountFrom==='chain'?'chain_to_infinity':(
        selectedAccountTo==='chain'?'infinity_to_chain':
        'infinity'
      ),'transfer_from':selectedAccountFromName,'transfer_to':selectedAccountToName
    };
  }

  async function handleClickTransfer(event: React.MouseEvent){
    setTransferEndType(undefined);
    setLastTransferData({
      transferAmount: transferAmount,
      tokenCode: selectedChainToken?.code ?? '',
      from: selectedAccountFromName,
      to: selectedAccountToName
    })
    setIsShowingConfirmPopup(true);
    sendGA('account_transfer_dialog',getGAParameters());
  }
  async function handleClickConfirmTransfer(){
    sendGA('account_transfer_confirm',getGAParameters());
    if(selectedAccountFrom==='chain'){
      _handleTransferChain();
    }else{
      if(selectedAccountTo==='chain'){
        _handleRequestWithdrawal();
      }else{
        _handleTransferAccount();
      }
    }
  }

  async function _handleTransferAccount(){
    try {
      if(!userAccounts||selectedAccountFrom=='chain'||selectedAccountTo=='chain'||selectedAccountFromTokenId==undefined){
        throw messages.UNKNOWN_ERROR;
      }
      const accountFrom = apis.user.extractUserAccount(userAccounts,selectedAccountFrom);
      const accountTo = apis.user.extractUserAccount(userAccounts,selectedAccountTo);
      const fromToken: AccountWalletToken | undefined = accountFrom.tokens.filter(t => containsToken(t, selectedAccountFromTokenId)).at(0);
      if (fromToken==undefined) throw messages.TRANSFER_ERROR_NOTE;
      let tokenId = selectedAccountFromTokenId;
      let transferAmount = amount;
      if (fromToken.tokenType == 2) {
        const wt = fromToken as AccountWalletToken;
        tokenId = wt.tokenId;
        const wts721 = wt?.erc721Tokens;
        transferAmount = wts721?.filter(wt => {
          const id = parseInt(tokenId.toString() + wt.erc721TokenId.toString());
          return selectedAccountFromTokenId == id;
        })[0]?.erc721TokenId?.toString() || transferAmount;
      }
      setIsTransferring(true);
      accountFrom&&accountTo&&(await apis.user.transferToken(auth, {
        fromAccountId: accountFrom.accountId,
        toAccountId: accountTo?.accountId,
        tokenId: tokenId,
        quantity: transferAmount,
      }));
      setTransferEndType(TransferType.TRANSFER_COMPLETE);
      setIsTransferring(false);
      _resetForm()
      queryClient.invalidateQueries({queryKey:['user']});
      queryClient.invalidateQueries({queryKey:['market']});
      sendGA('account_transfer_success',getGAParameters());
    } catch (error){
      snackbar.error(error);
      setTransferEndType(TransferType.TRANSFER_ERROR);
      setIsTransferring(false);
      sendGA('account_transfer_error',getGAParameters());
    }
  }
  async function _handleRequestWithdrawal(){
    try {
      if(!userAccounts||selectedAccountFrom=='chain'||selectedAccountTo!='chain'||selectedAccountFromTokenId==undefined){
        throw messages.UNKNOWN_ERROR;
      }
      const accountFrom = apis.user.extractUserAccount(userAccounts,selectedAccountFrom);
      const fromToken: AccountWalletToken | undefined = accountFrom.tokens.filter(t => containsToken(t, selectedAccountFromTokenId)).at(0);
      if (fromToken==undefined) throw messages.TRANSFER_ERROR_NOTE;
      let tokenId = selectedAccountFromTokenId;
      let transferAmount = amount;
      if (fromToken.tokenType == 2) {
        const wt = fromToken as AccountWalletToken;
        tokenId = wt.tokenId;
        const wts721 = wt.erc721Tokens;
        transferAmount = wts721?.filter(wt => {
          const id = parseInt(tokenId.toString() + wt.erc721TokenId.toString());
          return selectedAccountFromTokenId == id;
        })[0]?.erc721TokenId?.toString() || transferAmount;
      }
      setIsTransferring(true);
      accountFrom&&(await apis.user.requestWithdrawal(auth, {
        chainId: INFINITY_CHAIN_ID_ETHEREUM,
        tokenId: tokenId,
        quantity: transferAmount
      }));
      setTransferEndType(TransferType.TRANSFER_WITHDRAWL);
      setIsTransferring(false);
      _resetForm()
      queryClient.invalidateQueries({queryKey:['user']});
      queryClient.invalidateQueries({queryKey:['market']});
      sendGA('account_transfer_success',getGAParameters());
    } catch (error){
      snackbar.error(error);
      setTransferEndType(TransferType.TRANSFER_ERROR);
      setIsTransferring(false);
      sendGA('account_transfer_error',getGAParameters());
    }
  }
  async function _handleTransferChain(){
    try {
      const token = getSelectedChainToken(selectedAccountFromTokenId);
      const tokenAddress = token?.tokenAddress;
      if (tokenAddress&&amount){
        let amountInDecimals = new Decimal(amount||0).mul(Math.pow(10,token.decimals)).toFixed(0);
        if (token.tokenType == 2) {
          const erc721TokenId = token?.erc721Tokens&&token?.erc721Tokens.filter(erc721Id => token.tokenId.toString() + erc721Id == selectedAccountFromTokenId?.toString())[0] || '0';
          amountInDecimals = erc721TokenId;
        }
        setIsTransferring(true);
        const _hash = await auth?.wallet?.transferTokensToInfinity([{token:tokenAddress,amount:amountInDecimals}]);
        setIsTransferring(false);
        setTransferEndType(TransferType.TRANSFER_CHAIN_COMPLETE);
        _resetForm()
        sendGA('account_transfer_success',getGAParameters());
      }
    }catch(error){
      snackbar.error(error);
      setTransferEndType(TransferType.TRANSFER_ERROR);
      setIsTransferring(false);
      sendGA('account_transfer_error',getGAParameters());
    }
    queryClient.invalidateQueries({queryKey:['user']});
    queryClient.invalidateQueries({queryKey:['market']});
  }
  const _updateAmountInput = useCallback(
    (_selectedAccountFromTokenId = selectedAccountFromTokenId, _selectedAccountTo = selectedAccountTo, _selectedAccountFrom = selectedAccountFrom)=>{
      let newAmountMin = '0';
      const balanceByToken = getBalanceByToken(_selectedAccountFrom, _selectedAccountFromTokenId)
      let newAmount = new Decimal(balanceByToken).gt(amount || 0) ? amount : '0';
      // check account token withdrawal fee for withdrawal min amount
      if(_selectedAccountTo === 'chain'){
        const selectedChainToken = getSelectedChainToken(_selectedAccountFromTokenId);
        if(selectedChainToken){
          newAmountMin = selectedChainToken.withdrawFee;
          if (new Decimal(amount || "0").lt(newAmountMin)) {
            newAmount = newAmountMin
          }
          if (selectedChainToken.tokenType === 2) newAmount = '1';
        }
      }
      setAmountMin(newAmountMin);
      setAmount(newAmount === '0' ? '' : newAmount);
    },
    [getSelectedChainToken, selectedAccountFromTokenId, selectedAccountTo, amount, getBalanceByToken, selectedAccountFrom]
  )
  const { _handleChangeSelectedFromAccount, _handleChangeSelectedToAccount, handleChangeSelectedAccountTokenId, handleChangeSelectedFromAccount, handleChangeSelectedToAccount } = useMemo(()=>{
    const _handleChangeSelectedFromAccount = (accountFromKey:SelectedAccountKey)=>{
      let accountToKey = selectedAccountTo;
      let accountFromTokenIdKey = selectedAccountFromTokenId;
      if(accountFromKey === 'chain'){
        // chain account - token = first token
        const ct = chainTokens?.find(t => containsToken(t, accountFromTokenIdKey) && new Decimal(t.balance || 0).gt(0));
        accountFromTokenIdKey = ct !== undefined && ct.tokenType === 2 ? accountFromTokenIdKey : ct?.tokenId || chainTokens?.at(0)?.tokenId;
        // send to current
        accountToKey = getCurrentAccount()?.accountId || 0;
      }else{
        // userAccounts
        accountFromKey = parseInt(`${accountFromKey}`);
        const accountFromTokens = userAccounts?.filter(uw=>uw.accountId==accountFromKey)[0]?.tokens;
        const wt = accountFromTokens?.filter(t => containsToken(t, accountFromTokenIdKey))?.at(0);
        accountFromTokenIdKey = wt!= undefined && wt.tokenType === 2 ? accountFromTokenIdKey: wt?.tokenId || accountFromTokens?.at(0)?.tokenId;
        if(accountFromKey==getCurrentAccount()?.accountId){
          // current account: select chain
          accountToKey = 'chain';
        }else{
          // trading account: current
          accountToKey = getCurrentAccount()?.accountId||0;
        }
      }
      setSelectedAccountFrom(accountFromKey);
      setSelectedAccountFromTokenId(accountFromTokenIdKey);
      setSelectedAccountTo(accountToKey);
      _updateAmountInput(chainTokens?.at(0)?.tokenId || 0, accountToKey, accountFromKey);
    };
    const _handleChangeSelectedToAccount = (accountToKey:SelectedAccountKey)=>{
      let accountFromKey = selectedAccountFrom;
      let accountFromTokenIdKey = selectedAccountFromTokenId;
      if(accountToKey=='chain'){
        // chain account - token = first token
        const ct = chainTokens?.filter(t => containsToken(t, accountFromTokenIdKey))?.at(0);
        accountFromTokenIdKey = ct!= undefined && ct.tokenType === 2 ? accountFromTokenIdKey: ct?.tokenId || chainTokens?.at(0)?.tokenId;
        // accountFromTokenIdKey = (chainTokens?.filter(ct=>ct.tokenId==accountFromTokenIdKey)[0]||chainTokens?.at(0))?.tokenId;
        // send to current
        accountFromKey = getCurrentAccount()?.accountId||0;
      }else{
        // userAccounts
        accountToKey = parseInt(`${accountToKey}`);
        const accountFromTokens = userAccounts?.filter(uw=>uw.accountId==accountToKey)[0]?.tokens;
        const wt = accountFromTokens?.filter(t => containsToken(t, accountFromTokenIdKey))?.at(0);
        accountFromTokenIdKey = wt !== undefined && wt.tokenType === 2 ? accountFromTokenIdKey: wt?.tokenId || accountFromTokens?.at(0)?.tokenId;
        // accountFromTokenIdKey = (accountFromTokens?.filter(t=>t.tokenId==accountFromTokenIdKey)[0]||accountFromTokens?.at(0))?.tokenId;
        if(accountToKey === getCurrentAccount()?.accountId){
          // current account: select chain
          accountFromKey = 'chain';
        }else{
          // trading account: current
          accountFromKey = getCurrentAccount()?.accountId||0;
        }
      }
      setSelectedAccountFrom(accountFromKey);
      setSelectedAccountFromTokenId(accountFromTokenIdKey);
      setSelectedAccountTo(accountToKey);
      _updateAmountInput(chainTokens?.at(0)?.tokenId||0,accountToKey);
    };
    const _handleChangeSelectedAccount = (accountFromKey?:SelectedAccountKey,accountToKey?:SelectedAccountKey)=>{
    }
    const handleChangeSelectedAccountTokenId = (event:SelectChangeEvent<unknown>,child:React.ReactNode)=>{
      const tokenId = parseInt(`${event.target.value}`);
      if (tokenId !== selectedAccountFromTokenId) {
        setSelectedAccountFromTokenId(tokenId);
        _updateAmountInput(tokenId);
      }
    }
    const handleChangeSelectedFromAccount = (event:SelectChangeEvent<unknown>,child:React.ReactNode)=>{
      let accountFromKey = event.target.value as SelectedAccountKey;
      _handleChangeSelectedFromAccount(accountFromKey);
    }
    const handleChangeSelectedToAccount = (event:SelectChangeEvent<unknown>,child:React.ReactNode)=>{
      let accountToKey = event.target.value as SelectedAccountKey;
      if (accountToKey !== selectedAccountTo) _handleChangeSelectedToAccount(accountToKey);
    }
    return { _handleChangeSelectedFromAccount, _handleChangeSelectedToAccount, handleChangeSelectedAccountTokenId, handleChangeSelectedFromAccount, handleChangeSelectedToAccount };
  },[_updateAmountInput, chainTokens, getCurrentAccount, selectedAccountFromTokenId, selectedAccountTo, selectedAccountFrom, userAccounts]);
  
  const _handleCloseDialog = ()=>{
    setLastTransferData(undefined)
    setIsShowingConfirmPopup(false);
  }

  useEffect(()=>{
    // upadte selectd fields from table click action
    if(selectedTransferAccountToken){
      const {accountType,transferType,tokenId} = selectedTransferAccountToken;
      const currentAccountKey = getCurrentAccount()?.accountId;
      const tradingAccountKey = getTradingAccounts()?.at(0)?.accountId;
      let selectedAccountKey:SelectedAccountKey;
      switch(accountType){
        case EAccountType.CHAIN:
          selectedAccountKey = 'chain';
          break;
        case EAccountType.CURRENT:
          selectedAccountKey = currentAccountKey;
          break;
        case EAccountType.TRADING:
          selectedAccountKey = tradingAccountKey;
          break;
      }
      if(transferType === ETransferDirectionType.FROM){
        _handleChangeSelectedFromAccount(selectedAccountKey);
      }else{
        _handleChangeSelectedToAccount(selectedAccountKey);
      }
      setSelectedAccountFromTokenId(tokenId);
      _updateAmountInput(tokenId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedTransferAccountToken, getCurrentAccount, getTradingAccounts]);
  
  // menuItems: tokenFrom
  // chainTokens
  const chainAccountTokenMenuItems = chainTokens?.filter(t=>!!t.balance&&new Decimal(t.balance).gt(0)).map((token, index) => {
    const { tokenId, erc721Tokens } = token;
    const tokenWithdrawalRequests = withdrawalRequests?.filter(request => request.tokenId === tokenId) || [];
    if (token.tokenType === 2 && erc721Tokens) {
      return erc721Tokens.map(erc721Id => {
        const id = parseInt(tokenId.toString() + erc721Id);
        const iconToken = {...token, erc721TokenId: erc721Id};
        return (
            <BootstrapMenuItem key={index} value={id}>
              <TokenIcon token={iconToken} withCode size={14} keepsWETH/>
              {tokenWithdrawalRequests.length > 0 && (
                  <InfoTooltipIcon title={`${tokenWithdrawalRequests.length} Withdrawal Requests In Progress`}/>)}
            </BootstrapMenuItem>
        );
      });
    } else {
      const item = (
          <BootstrapMenuItem key={index} value={tokenId}>
            <TokenIcon token={token} withCode size={14} keepsWETH/>
            {tokenWithdrawalRequests.length > 0 && (
                <InfoTooltipIcon title={`${tokenWithdrawalRequests.length} Withdrawal Requests In Progress`}/>)}
          </BootstrapMenuItem>
      );
      return [item];
    }
  }).flat(1);
  // elligible collateral samples
  const elligibleCollateralTokemMenuItems = [
    {'label':'Basic Tokens','tokens':[{'name':'Ethereum Foundation','code':'ETH','tokenId':99901}, {'name':'BitGo','code':'WBTC','tokenId':99902}, {'name':'Circle','code':'USDC','tokenId':99903}, {'name':'Tether','code':'USDT','tokenId':99904}, {'name':'MakerDAO','code':'DAI','tokenId':99905}, ]},
    {'label':'Yield-Bearing Tokens','tokens':[{'name':'Aave','code':'aETH','tokenId':99906}, {'name':'Aave','code':'aWBTC','tokenId':99907}, {'name':'Aave','code':'aUSDC','tokenId':99908}, {'name':'Aave','code':'aUSDT','tokenId':99909}, {'name':'Aave','code':'aDAI','tokenId':99910}, {'name':'Compound','code':'cETH','tokenId':99911}, {'name':'Compound','code':'cWBTC','tokenId':99912}, {'name':'Compound','code':'cUSDC','tokenId':99913}, {'name':'Compound','code':'cUSDT','tokenId':99914}, {'name':'Compound','code':'cDAI','tokenId':99915}, ]},
    {'label':'Liquid Staking Tokens','tokens':[{'name':'Lido','code':'stETH','tokenId':99916}, {'name':'Rocket Pool','code':'RETH','tokenId':99917}, ]},
    {'label':'Tokenized RWA Tokens','tokens':[{'name':'MatrixDock','code':'STBT','tokenId':99918}, {'name':'OpenEden','code':'TBILL','tokenId':99919}, {'name':'Ondo Finance','code':'USDY','tokenId':99920}, {'name':'Ondo Finance','code':'OUSG','tokenId':99921}, {'name':'Ondo Finance','code':'OMMF','tokenId':99922}, {'name':'Mountain Protocol USD','code':'USDM','tokenId':99926}, {'name':'Backed Assets','code':'bIB01','tokenId':99923}, {'name':'Backed Assets','code':'bIBTA','tokenId':99924}, {'name':'Franklin Templeton','code':'FOBXX','tokenId':99925},]},
  ].map(({label,tokens},i)=>{
    return [(<Divider key={`divider_${i}`} textAlign='left'>{label}</Divider>),...tokens.map(({name,code,tokenId})=>{
      const token = chainTokens?.find(t=>t.code===code&&!!t.balance&&new Decimal(t.balance).gt(0));
      if(token){ 
        ({name,code,tokenId} = token);
      }
      return (
        <BootstrapMenuItem key={`elligibleCollateral_${code}`} value={tokenId}>
          <TokenIcon token={{name,code}} withCode size={14} keepsWETH/>
        </BootstrapMenuItem>
      );
    })];
  }).flat(1);
  // user tokenAccount
  const infinityAccountMenuItems = selectedUserAccount?.tokens.map((tokenAccount, index)=>{
    const { tokenId, tokenType, erc721Tokens } = tokenAccount;
    const token = tokens?.filter(token => token.tokenId === tokenId)[0];
    if (!token) return null;
    if (tokenType === 2) {
      return erc721Tokens?.map(wt721 => {
        // need to create new instance for immutability
        const newToken: Token = {...token}
        const newTokenId = parseInt(tokenId.toString() + wt721.erc721TokenId.toString());
        newToken.erc721TokenId = wt721?.erc721TokenId?.toString() || '';
        const tokenWithdrawalRequests = withdrawalRequests?.filter(request=>request.tokenId==tokenId&&request.quantity==newToken.erc721TokenId&&![10,99].includes(request.status))||[];
        return (
            <BootstrapMenuItem key={index} value={newTokenId}>
              <TokenIcon token={newToken} withCode size={14}/>
              {tokenWithdrawalRequests.length>0&&(<InfoTooltipIcon title={`${tokenWithdrawalRequests.length} Withdrawal Requests In Progress`}/>)}
            </BootstrapMenuItem>
        );
      });
    }
    else {
      const tokenWithdrawalRequests = withdrawalRequests?.filter(request => request.tokenId == tokenId && ![10, 99].includes(request.status)) || [];
      return (
          <BootstrapMenuItem key={index} value={tokenId}>
            <TokenIcon token={token} withCode size={14}/>
            {tokenWithdrawalRequests.length > 0 && (
                <InfoTooltipIcon title={`${tokenWithdrawalRequests.length} Withdrawal Requests In Progress`}/>)}
          </BootstrapMenuItem>
      );
    }
  }).flat(1);
  // balance
  const selectedUserTokenAccount = userAccounts&&selectedUserAccount!=undefined&&selectedAccountFromTokenId!=undefined&&apis.user.extractAccountTokenAccountFromAccounts(userAccounts,selectedUserAccount.type,selectedAccountFromTokenId)||undefined;
  const selectedChainToken = getSelectedChainToken(selectedAccountFromTokenId);
  // let balance = '0';
  let decimals = 0;
  let selectedAccountFromName = '';
  let selectedAccountToName = '';
  if (selectedAccountFrom === 'chain') {
    // balance = selectedChainToken?.balance || '0';
    decimals = selectedChainToken?.decimals || 0;
    selectedAccountFromName = 'Your Wallet';
  } else {
    const token = tokens?.filter(token => token.tokenId === selectedUserTokenAccount?.tokenId)[0];
    // balance = selectedUserTokenAccount?.availableQuantity||'0';
    decimals = token?.decimals||0;
    selectedAccountFromName = `${userAccounts?.filter(w => w.accountId === selectedAccountFrom).at(0)?.name} Account` || '';
  }
  if (selectedAccountTo === 'chain') {
    selectedAccountToName = 'Your Wallet';
  } else {
    selectedAccountToName = `${userAccounts?.filter(w => w.accountId === selectedAccountTo).at(0)?.name} Account` || '';
  }
  decimals = Math.min(decimals,TOKEN_MAX_DECIMALS)
  // menuItems: accountTo
  let accountToMenuItems:JSX.Element[] = [];
  const accountToMenuItemCurrentAccount = (
    <BootstrapMenuItem key={'current'} value={getCurrentAccount()?.accountId}>Infinity: {getCurrentAccount()?.name} Account</BootstrapMenuItem>
  );
  if (selectedAccountFrom=='chain') {
    // always goes into current account
    accountToMenuItems.push(accountToMenuItemCurrentAccount);
  } else {
    switch(selectedUserAccount?.type){
      case EUserAccountType.CURRENT: 
        accountToMenuItems.push((<BootstrapMenuItem key='chain' value="chain">Your Wallet</BootstrapMenuItem>));
        getTradingAccounts()?.map((w,i)=>{
          accountToMenuItems.push((
            <BootstrapMenuItem key={i} value={w.accountId}>
              Infinity: {w.name} Account{/* ({{[EUserAccountType.CURRENT]:'Current',[EUserAccountType.TRADING]:'Trading'}[w.type]}) */}
            </BootstrapMenuItem>
          ));
        });
        break;
      case EUserAccountType.TRADING:
        // Current Account Only
        accountToMenuItems.push(accountToMenuItemCurrentAccount);
        break;
    }
  }
  const transferFee = new Decimal(amountMin);
  const transferAmount = new Decimal(amount||0).sub(transferFee);
  const transferFeeString = selectedAccountTo!='chain'?'':` (Fee: ${format(transferFee)})`;
  const amountError = (auth?.user && (amount && new Decimal(balance).lt(amount))) ? <>Insufficient funds.</> : '';

  // render
  return (<>
    <Table sx={sxCompactFormTable}><TableBody>
    <TableRow><TableCell>
        {/* account from menu */}
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink sx={sxInputLabel}>From</InputLabel>
          <BootstrapSelect value={`${selectedAccountFrom!=undefined?selectedAccountFrom:''}`} onChange={handleChangeSelectedFromAccount}>
            <BootstrapMenuItem key='chain' value={'chain'}>Your Wallet</BootstrapMenuItem>
            {userAccounts?.map((w,i)=>(
              <BootstrapMenuItem key={i} value={w.accountId}>
                Infinity: {w.name} Account{/*  ({{[EUserAccountType.CURRENT]:'Current',[EUserAccountType.TRADING]:'Trading'}[w.type]}) */}
              </BootstrapMenuItem>
            ))}
          </BootstrapSelect>
        </FormControl>
      </TableCell></TableRow>
      <TableRow><TableCell>
        {/* account to menu */}
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink sx={sxInputLabel}>To</InputLabel>
          <BootstrapSelect value={`${selectedAccountTo !== undefined ? selectedAccountTo : ''}`} onChange={handleChangeSelectedToAccount}>
            {accountToMenuItems}
          </BootstrapSelect>
        </FormControl>
      </TableCell></TableRow>
      <TableRow><TableCell>
        {/* token select menu */}
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink sx={sxInputLabel}>Token</InputLabel>
          <InputLabel shrink sx={sxInputLabelFloatRight}>{`Balance: ${format(balance,decimals)}`}</InputLabel>
          <BootstrapSelect value={`${selectedAccountFromTokenId !== undefined ? selectedAccountFromTokenId : ''}`} onChange={handleChangeSelectedAccountTokenId}>
            {selectedAccountFrom === 'chain' ? elligibleCollateralTokemMenuItems /* chainAccountTokenMenuItems */ : infinityAccountMenuItems}
          </BootstrapSelect>
        </FormControl>
      </TableCell></TableRow>
      <TableRow><TableCell>
        {/* transfer amount input */}
        <Box sx={{ paddingBottom: '4px'}}>
          <TokenAmountInput name='amount' error={amountError} label={`Transfer Amount${transferFeeString}`} fullWidth
            amount={amount} decimals={decimals}
            min={amountMin} max={balance} placeholder={new Decimal(0).toFixed(decimals)}
            readOnly={isTransferring} disabled={new Decimal(amountMin).gt(new Decimal(amount||0))}
            onAmountChange={(amount)=>setAmount(amount)} allowsOutOfRange={true}
            // bottomLabel={}
          />
          </Box>
      </TableCell></TableRow>
      
      <TableRow><TableCell>
        {isTransferring?(
          <Button variant="contained" disabled={true}>Transfer</Button>
        ):(
          <Button variant="contained" sx={StylePrimaryButton} onClick={handleClickTransfer} disabled={!!amountError || (!isTransferring && transferAmount.lte(0))}>Transfer</Button>
        )}
      </TableCell></TableRow>
    </TableBody></Table>
    {/* confirm dialog */}
    {selectedChainToken&&<TokenTransferDialog
      token={selectedChainToken} transferAmount={transferAmount} transferFee={transferFee} 
      from={selectedAccountFromName} to={selectedAccountToName} 
      transferEndType={transferEndType} chainTransfer={selectedAccountFrom=='chain'} 
      isTransferring={isTransferring} open={isShowingConfirmPopup} handleClose={_handleCloseDialog} handleClickConfirmTransfer={handleClickConfirmTransfer}
      lastTransferData={lastTransferData} 
    />}
  </>);
}

export default AccountTransferMenu;