import {
    Box, TextField
} from '@mui/material';
import { SxProps } from '@mui/system';
import Decimal from 'decimal.js';
import { useEffect } from 'react';
import { useAuth } from '../AuthProvider';
import useAppStore from '../store';
import { styleFormCell } from '../styles/Form';
import { EOrderSide, Token } from '../types';
import apis from '../utils/apis';
import { format } from '../utils/numbers';
import TokenIcon from './TokenIcon';

const StyleBox:SxProps = {
  p: 2, mx: 'auto', width: '100%',
  marginBottom:4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}
const styleTokenLabelRow:SxProps = {
  whiteSpace:"nowrap",
  color: 'text.secondary',
  fontSize: '0.75rem',
  py: 1,
};
const styleTokenValueRow:SxProps = {
  whiteSpace:"nowrap",
  fontSize: '1.125rem',
  py: 1,
  "label": {
    color: 'text.secondary',
  }
};

function AccountInfo({token,type='default',side=EOrderSide.LEND}:{token?:Token,type?:'default'|'textbox'|'amountOnly',side?:EOrderSide}){
  const auth = useAuth();
  const { currentUserAccountId, set } = useAppStore();
  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  const currentUserAccount = userAccounts&&apis.user.extractUserAccount(userAccounts,currentUserAccountId);
  const tokenAccount = currentUserAccount&&token&&apis.user.extractAccountTokenAccountFromAccount(currentUserAccount,token.tokenId);
  const fixed = 6;
  const available = format(new Decimal(tokenAccount?.availableQuantity||0).toFixed(fixed));
  const usdValue = format(new Decimal(tokenAccount?.availableQuantity||0).mul(new Decimal(token?.price||0)).toFixed(fixed));
  const maxBorrow = format(new Decimal(currentUserAccount?.maxBorrowInUsd||0).div(new Decimal(token?.price||0)).toFixed(fixed));
  const maxBorrowInUsd = format(new Decimal(currentUserAccount?.maxBorrowInUsd||0).toFixed(fixed));
  useEffect(()=>{
    if(currentUserAccountId!=currentUserAccount?.accountId){
      set('currentUserAccountId',currentUserAccount?.accountId);
    }
  },[]);
  if(!auth.user) return (<></>);
  switch(type){
    case 'amountOnly':
      return <>{side==EOrderSide.LEND?available:maxBorrow}</>;
    case 'textbox':
      return (
        <TextField name='totalSize' label='LIMIT'
          sx={styleFormCell} variant='standard' InputLabelProps={{shrink: true}} 
          InputProps={{
            // endAdornment:<InputAdornment position="end">(${usdValue})</InputAdornment>,
            readOnly: true,
            disabled: true,
          }}
          value={side==EOrderSide.LEND?available:maxBorrow} 
        />
      );
    default:
    case 'default':
      return (
        <Box sx={StyleBox}>
          <Box sx={styleTokenLabelRow}><TokenIcon token={token}/>AVAILABLE</Box>
          <Box sx={styleTokenValueRow}>
            {side==EOrderSide.LEND?available:maxBorrow}
            <label>  (${side==EOrderSide.LEND?usdValue:maxBorrowInUsd})</label>
          </Box>
        </Box>
      );
  }
}

export default AccountInfo;