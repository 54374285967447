import { Box, FormControl, InputLabel, SxProps, Table, TableBody, TableCell, TableRow, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Decimal from 'decimal.js';
// DEV @mui/x-date-pickers seems to be causing issue with other packages
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { isUndefined } from 'lodash';
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useAuth } from 'src/AuthProvider';
import LoadingIcon from 'src/Components/Loading';
import RateInput from 'src/Components/RateInput';
import { getMarketByLeg, getTokenByLeg } from 'src/Components/Swaps/selectors';
import TokenAmountInput from 'src/Components/TokenAmountInput';
import AccountInfo from 'src/Components/AccountInfo';
import useAppStore from 'src/store';
import { useSwapParameterStore } from 'src/store/useSwapParametersStore';
import { BootstrapMenuItem, BootstrapSelect, styleFormCell, sxCompactFormTable, sxInputLabel, sxSelectMenu } from 'src/styles/Form';
import { EOrderSide, ERateOrderType, ETradeType, FormStateProps, Market, MarketFixedRate, MarketInfo, RateOrderBook, Token } from 'src/types';
import apis from 'src/utils/apis';
import { sortBy, usePrevious } from 'src/utils/common';
import { toPercentage } from 'src/utils/string';
import SwapMarketSwitcher from './SwapMarketSwitcher';
import SwapTokenSwitcher from './SwapTokenSwitcher';

interface MarketTradeFormProps {
  token?:Token,marketId?:number,selectedOrderSide?:EOrderSide,
  tradeType: ETradeType,
  hideMaturityOptions?:boolean
  type?:'full'|'float'|'compact',
  estimatedSlippage: Decimal | undefined,
  onFormStateChange: (formState: FormStateProps, isInvalidInput: boolean) => void
};
interface InnerMarketTradeFormProps extends MarketTradeFormProps {
  marketInfo?:MarketInfo, orderBook?:RateOrderBook, market?:Market|MarketFixedRate,
  isLoadingMarketInfo?:boolean
}

const sxSwapFormTable:SxProps = {
  maxWidth: '100%',
  "td":{
    border: 0,
    py: '12px',
    px: '0px'
  },
}

const sxAssetSelectedItem:SxProps = {
  ".MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input": {
    paddingLeft: '2px'
  }
}

const sxOrderTypeSelect:SxProps = {
  ".MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl": {
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0'
  }
}

const sxRateInput:SxProps = {
  ".MuiInputBase-root.MuiInputBase-formControl.MuiInputBase-adornedEnd": {
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0'
  }
}

const InnerMarketTradeForm = forwardRef(function InnerMarketForm({
  token,marketId,selectedOrderSide, hideMaturityOptions=false,
  marketInfo, orderBook, market, isLoadingMarketInfo,
  estimatedSlippage, onFormStateChange
}:InnerMarketTradeFormProps, ref: any){
  /**
   * general hooks
   */
  const auth = useAuth();
  const { currentUserAccountId: currentUserAccountId } = useAppStore();
  
  /**
   *  single state for multiple field simultaneous update 
   * */
  const [formState,setFormState] = useState<FormStateProps>({
    orderSide: selectedOrderSide!==undefined?selectedOrderSide:EOrderSide.LEND,
    orderType: ERateOrderType.MARKET,
    quantity: '',
    rate: undefined,
    maxSlippage: undefined,
  });
  const {
    orderSide,
    orderType,
    quantity,
    rate,
    maxSlippage,
  } = formState;

  useImperativeHandle(ref, () => {
    return {
      resetForm() {
        setFormState({
                orderSide: EOrderSide.LEND,
                orderType: ERateOrderType.MARKET,
                quantity: '',
                rate: undefined,
                maxSlippage: undefined,
            })
      }
    };
  }, []);
  
  /**
   * api data
   */
  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  const tradeAccount = userAccounts&&apis.user.extractUserAccount(userAccounts,currentUserAccountId);
  const tradeTokenAccount = market&&tradeAccount&&apis.user.extractAccountTokenAccountFromAccount(tradeAccount,market.tokenId);
   
  /**
   * rate calculation
  */
  const maxQuantity = (market&&token&&tradeAccount&&(orderSide===EOrderSide.BORROW?
    // borrow maxQuantity = healthscore max borrow in usd / price
    new Decimal(tradeAccount.maxBorrowInUsd||0).div(new Decimal(token.price||0)):
    // lend maxQuantity = trading account availableQuantity
    tradeTokenAccount?.availableQuantity&&new Decimal(tradeTokenAccount?.availableQuantity).sub(new Decimal(tradeTokenAccount?.availableQuantity).mod(new Decimal(market.quantityStep)))
  ))||new Decimal(0);
  const quantityStep = market?.quantityStep||'0'; 
  const decimals = new Decimal(quantityStep).decimalPlaces();
  const quantityObject = new Decimal(quantity||0);
  const isInvalidInput = quantityObject.gt(maxQuantity)||quantityObject.lte(0);
  // orderRateDecimal: the onbook rate of the market - as a function for state change calculation
  const getOrderRateDecimal = useCallback(()=>{
    // use next nearest appropriate rate in the orderbook if placing market order 
    const marketRateDecimal = new Decimal((marketInfo?.marketId===marketId&&(marketInfo?.midRate||marketInfo?.rate||market?.rate))||0);
    const {askMinRate,bidMaxRate} = orderBook||{};
    // orderRateDecimal: the onbook rate of the market
    const orderRateDecimal = (orderType===ERateOrderType.MARKET&&askMinRate&&bidMaxRate&&new Decimal(orderSide===EOrderSide.BORROW?askMinRate:bidMaxRate))||marketRateDecimal;
    return orderRateDecimal;
  },[marketId,marketInfo,orderBook,orderType,orderSide,market?.rate]);

  // orderRateDecimal: the onbook rate of the market
  const orderRateDecimal = getOrderRateDecimal();
  // max/min rates from onbook rate
  const rateMinDecimal = orderType===ERateOrderType.MARKET&&orderSide===EOrderSide.BORROW?orderRateDecimal:new Decimal(0);
  const rateMaxDecimal = orderType===ERateOrderType.MARKET&&orderSide===EOrderSide.LEND?orderRateDecimal:new Decimal(100);
  // rateDecimal: the rate used for the form
  const rateDecimal = Decimal.min(rateMaxDecimal,Decimal.max(rateMinDecimal,new Decimal(rate||0))).toDP(decimals*100);
  const maxSlippageDecimal = new Decimal(maxSlippage||0);
  // adjustedRateDecimal: form rate with slippage adjusted
  const adjustedRateDecimal = rateDecimal.add(maxSlippageDecimal.mul(orderSide===EOrderSide.BORROW?1:-1)).toDP(decimals*100);
  // targetRate & estimatedSlippage: interate orderbook to find the estimated slippage rate (absolute)
  const quantityDecimal = new Decimal(quantity||0);
  const {targetRate} = (orderType===ERateOrderType.MARKET&&orderBook&&(
    orderSide===EOrderSide.BORROW?sortBy(orderBook.asks,'rate'):sortBy(orderBook.bids,'rate',true)
  ).reduce(
    ({depth,targetRate},entry)=>{
      if(quantityDecimal.gt(depth)){
        targetRate = new Decimal(entry.rate);
      }
      depth = depth.add(new Decimal(entry.quantity||0));
      return {depth,targetRate};
    }
  ,{depth:new Decimal(0),targetRate:undefined} as {depth:Decimal,targetRate:Decimal|undefined}))||{};
  const rateMin = rateMinDecimal.toString();
  const rateMax = rateMaxDecimal.toString();

  
  /**
   * state change: on market change - rate is calculated with marketInfo.rate (and orderBook if market order)
  */
  const prevMarketId = usePrevious(marketId);
  const prevMarketInfo = usePrevious(marketInfo);
  const prevOrderBook = usePrevious(orderBook);
  useEffect(()=>{
    const newState:Partial<typeof formState> = {};
    if(marketId&&marketId!==prevMarketId){
      // on market change - reset rate and slippage
      newState.rate = undefined;
      newState.maxSlippage = undefined;
    }
    if(marketInfo&&marketInfo.marketId===marketId){
      // check marketInfo.marketId for API delay
      if(rate===undefined||Object.hasOwn(newState,'rate')){
        // only update form rate from marketInfo if it's not set, or unset from market change
        const orderRate = getOrderRateDecimal().toString();
        if(rate!==orderRate){
          newState.rate = orderRate;
        }
        if(!newState.maxSlippage){
          newState.maxSlippage = orderType===ERateOrderType.MARKET?`${Number(market?.rateStep||0)*10||0.1}`:undefined;
        }
      }
    }
    if(Object.keys(newState).length>0){
      setFormState(formState=>({...formState,...newState}));
    }
  },[ marketId, prevMarketId, marketInfo, prevMarketInfo, orderBook, prevOrderBook, rate, getOrderRateDecimal, orderType, market?.rateStep ]);
  /**
   * state change: from props.selectedRate
   */
  const prevSelectedOrderSide = usePrevious(selectedOrderSide);
  const prevOrderType = usePrevious(orderType);

  /**
   * state change: on order side or market type change
   */
  useEffect(()=>{
    if(selectedOrderSide&&selectedOrderSide!==prevSelectedOrderSide&&selectedOrderSide!==orderSide){
      const maxSlippage = `${Number(market?.rateStep||0)*10||0.1}`;
      setFormState(formState=>({...formState, orderSide:selectedOrderSide,maxSlippage:maxSlippage}));
    }
  },[selectedOrderSide,prevSelectedOrderSide,orderSide,market?.rateStep]);
  useEffect(()=>{
    if(orderType!==prevOrderType){
      setFormState(formState=>({...formState, maxSlippage:orderType===ERateOrderType.MARKET?`${Number(market?.rateStep||0)*10||0.1}`:undefined}));
    }
  },[orderType,prevOrderType,market?.rateStep]);
  
  useEffect(
    () => {
      if (formState) {
        const quantityObject = new Decimal(formState.quantity||0);
        const isInvalidInput = quantityObject.gt(maxQuantity)||quantityObject.lte(0);
        onFormStateChange && onFormStateChange(formState, isInvalidInput)
      }
    }, [formState, onFormStateChange, maxQuantity]
  )

  const fundError = (auth?.user && market && token && tradeAccount && ( maxQuantity.lte(0) || (quantity && maxQuantity.lt(quantity)))) ? `Insufficient funds for ${token?.code}` : ''
  
  /**
   * renders
   */
    return (<>
      {(isUndefined(token) || marketInfo === null)&&<LoadingIcon curtain/>}
      <Table sx={{...sxCompactFormTable, ...sxSwapFormTable }}>
        <TableBody>
          <TableRow hover>
            <TableCell>
              <ToggleButtonGroup
                color="primary"
                value={orderSide}
                exclusive
                onChange={(event,value)=>value!==null&&setFormState({...formState, orderSide:(value as unknown as EOrderSide)})}
                aria-label="order-direction-input"
              >
                <ToggleButton value={EOrderSide.LEND}>Receive</ToggleButton>
                <ToggleButton value={EOrderSide.BORROW}>Pay</ToggleButton>
              </ToggleButtonGroup>
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell>
              <Box sx={{display:'grid',gridAutoColumns:'1fr',gridAutoFlow:'column',columnGap:'16px'}}>
                {/* <Box sx={{ flex: 1}}> */}
                  <FormControl variant="standard" fullWidth sx={{...styleFormCell, ...sxAssetSelectedItem}}>
                    <InputLabel shrink htmlFor="market-select" sx={sxInputLabel}>Asset</InputLabel>
                    <SwapTokenSwitcher legKey={"leg1"}/>
                  </FormControl>
                {/* </Box> */}
                {/* <Box sx={{ flex: 1}}> */}
                  <FormControl variant="standard" fullWidth sx={styleFormCell}>
                    <InputLabel shrink htmlFor="market-select" sx={sxInputLabel}>Maturity</InputLabel>
                    <SwapMarketSwitcher legKey={"leg1"} />
                  </FormControl>
                {/* </Box> */}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell>
              <Box sx={{display:'grid',gridAutoColumns:'1fr',gridAutoFlow:'column',columnGap:'16px'}}>
                <Box sx={{ flex: 1, display: 'flex' }}>
                  <FormControl variant="standard" sx={{ flex: 1, ...sxOrderTypeSelect }}>
                    <InputLabel shrink htmlFor="orderType-select" sx={sxInputLabel}>Order Type</InputLabel>
                    <BootstrapSelect MenuProps={{sx:sxSelectMenu}}
                      id="orderType-select"
                      value={ orderType }
                      onChange={(event)=>{
                        setFormState({...formState, orderType:(parseInt(`${event.target.value}`) as ERateOrderType)})
                      }}
                      disabled={true}
                    > 
                      <BootstrapMenuItem value={ERateOrderType.MARKET}>Market</BootstrapMenuItem>
                      <BootstrapMenuItem value={ERateOrderType.LIMIT}>Limit</BootstrapMenuItem>
                    </BootstrapSelect>
                  </FormControl>
                  <Box sx={{ flex: 1, ...sxRateInput }}>
                    <RateInput name='rate' label='Rate' sx={styleFormCell} fullWidth
                      rate={rateDecimal?.toString()||'0'} step={market?.rateStep} min={rateMin} max={rateMax}
                      disabled={orderType!==ERateOrderType.LIMIT}
                      onRateChange={ (rate) => setFormState({...formState, rate:rate}) }
                    />
                  </Box>
                </Box>
                <RateInput name='maxSlippage' label='Slippage (Max)' sx={styleFormCell} fullWidth
                  rate={maxSlippageDecimal?.toString()||'0'} step={market?.rateStep} bottomLabel={<>{estimatedSlippage?.gt(0) ? `(Estimated: ${toPercentage(estimatedSlippage)}%)` : ''}</>}
                  onRateChange={(maxSlippage: any)=>setFormState({...formState, maxSlippage:maxSlippage})}
                />
              </Box>
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell>
              <TokenAmountInput name='quantity' fullWidth
                label='Size'
                sx={styleFormCell} suffix={token?.code} bottomLabel={<>(Max <AccountInfo token={token} side={orderSide} type='amountOnly'/>)</>}
                step={quantityStep} decimals={decimals} min={market?.minQuantity} max={maxQuantity.toString()}
                amount={quantity} onAmountChange={quantity=> {
                  setFormState({...formState, quantity:quantity})
                }} allowsOutOfRange={true}
                placeholder={new Decimal(0).toFixed(decimals)}
                error={fundError}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>);
});

const MemoInnerMarketTradeForm = memo(InnerMarketTradeForm);

const SwapMarketTradeForm = forwardRef(function (props:MarketTradeFormProps, ref: any){
  const market = useSwapParameterStore(getMarketByLeg("leg1"))
  const token = useSwapParameterStore(getTokenByLeg("leg1"))
  const {isLoading: isLoadingMarketInfo, data:marketInfo} = apis.rate.useMarketInfo({market});
  const {data:orderBook} = apis.rate.useRateOrderBook(market);
  return <MemoInnerMarketTradeForm 
    {...props}
    token={token}
    marketInfo={marketInfo} orderBook={orderBook} market={market}
    isLoadingMarketInfo={isLoadingMarketInfo}
    ref={ref}
  />;
})

export default SwapMarketTradeForm;