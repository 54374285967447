
import { Box, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';
import Decimal from 'decimal.js';
import { useEffect, useState } from 'react';
import { unique } from 'src/utils/common';
import { sxEmptyRow, sxTokenTable, sxTokenTableContainer } from '../styles/TokenTable';
import { EMarketType, FixedRateOrder, RateOrder, TradeHistoryItem, OrderTableHeadCell } from '../types';
import apis from '../utils/apis';
import LoadingIcon from './Loading';
// import { /*TradeHistoryTableHead, */ TradeHistoryTableRow } from './TradeHistoryTable';
import { mapFixedRateOrderHistoryItem, mapFloatRateOrderHistoryItem, TradeHistoryTableHead, TradeHistoryTableRow } from 'src/Components/TradeHistoryTable'
import { dps } from 'src/utils/numbers';
import InfoTooltipIcon from 'src/Components/InfoTooltipIcon';

const tradeHistoryPublicViewHeadCells: readonly OrderTableHeadCell[] = [
  {
    id: 'userAddress',
    align: 'left',
    disablePadding: false,
    label: 'User',
    sticky: true
  },
  {
    id: 'tokenId',
    align: 'left',
    disablePadding: false,
    label: 'Token',
    sticky: true
  },
  {
    id: 'quantityVector',
    align: 'right',
    disablePadding: false,
    label: 'Position',
  },
  {
    id: 'quantityVectorUSD',
    align: 'right',
    disablePadding: false,
    label: 'USD',
  },
  {
    id: 'maturityDate',
    align: 'left',
    disablePadding: false,
    label: 'Maturity',
  },
  {
    id: 'executed',
    align: 'right',
    disablePadding: false,
    label: <>Executed<InfoTooltipIcon sx={{ width: '1rem', height: '1rem', position: 'relative', top: '4px'}} title={
        `Current market at the time of execution`
      } outlined/></>, // Maturity on oexecution
  },
  {
    id: 'rate',
    align: 'left',
    disablePadding: false,
    label: 'Rate',
  },
  {
    id: 'orderType',
    align: 'right',
    disablePadding: false,
    label: 'Order Type',
  },
  {
    id: 'mtm',
    align: 'left',
    disablePadding: false,
    label: 'MTM',
  },
  {
    id: 'dv01',
    align: 'left',
    disablePadding: false,
    label: 'PV01',
  },
  {
    id: 'status',
    align: 'left',
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'orderDate',
    align: 'left',
    disablePadding: false,
    label: 'Date',
  },
];

function PublicTradeHistoryTable({userAddress}:{userAddress?:string}){
  const {data:markets} = apis.rate.useRateMarkets();
  const {data:tokens} = apis.token.useTokens();
  // pagination only supported with all rate orders
  const pageLimit = 20;
  const [page,setPage] = useState(0);
  const [maxCount,setMaxCount] = useState(-1);
  const [pageLastFloatingOrderIdArray,setPageLastFloatingOrderIdArray] = useState<Array<number|undefined>>([undefined]);
  const [pageLastFixedOrderIdArray,setPageLastFixedOrderIdArray] = useState<Array<number|undefined>>([undefined]);
  // const [lastFloatingOrderId,setLastFloatingOrderId] = useState<number|undefined>();
  // const [lastFixedOrderId,setLastFixedOrderId] = useState<number|undefined>();
  const {isLoading:isLoadingAllRateOrders,isFetching:isFetchingAllRateOrders,data:allRateOrdersData} = apis.rate.useAllRateOrders({
    limit:pageLimit, asc:false, userAddress, 
    lastFloatingOrderId: pageLastFloatingOrderIdArray[page]||undefined, 
    lastFixedOrderId: pageLastFixedOrderIdArray[page]||undefined,
  });
  const showsUserAddressLink = userAddress==undefined;
  useEffect(()=>{
    if(!allRateOrdersData) return;
    const {orders,hasNextPage,lastFloatingOrderId,lastFixedOrderId} = allRateOrdersData;
    if(lastFloatingOrderId!=undefined){
      pageLastFloatingOrderIdArray[page+1] = lastFloatingOrderId;
      setPageLastFloatingOrderIdArray([...pageLastFloatingOrderIdArray]);
    }
    if(lastFixedOrderId!=undefined){
      pageLastFixedOrderIdArray[page+1] = lastFixedOrderId;
      setPageLastFixedOrderIdArray([...pageLastFixedOrderIdArray]);
    }
    setMaxCount(hasNextPage?-1:page*pageLimit+orders.length);
  },[allRateOrdersData]);
  // table items
  const orderHistoryItems:TradeHistoryItem[] = [];
  allRateOrdersData?.orders?.map(order=>{
    switch(order.marketType){
      case EMarketType.FLOATING: {
        const rateOrder = { ...order, orderId: order.floatingOrderId } as RateOrder
        const orderHistoryItem = mapFloatRateOrderHistoryItem(rateOrder, markets)
        if (orderHistoryItem) orderHistoryItems.push(orderHistoryItem)
        break;
      }
      case EMarketType.FIXED: {
        const fixedRateOrder = { ...order, orderId: order.fixedOrderId } as FixedRateOrder
        orderHistoryItems.push(mapFixedRateOrderHistoryItem(fixedRateOrder, tokens, markets))
        break;
      }
    }
  });
  orderHistoryItems.sort((a,b)=>a.orderDate>b.orderDate?-1:a.orderDate<b.orderDate?1:0);
  const table = (
  <Box sx={{width:'100%'}}>
    <TableContainer component={Box} sx={sxTokenTableContainer}>
      <Table sx={sxTokenTable} aria-label="Markets" size="medium">
        <TradeHistoryTableHead cells={tradeHistoryPublicViewHeadCells} />
        <TableBody>
        {(isLoadingAllRateOrders)?
          <TableRow hover sx={sxEmptyRow}><TableCell colSpan={10}><LoadingIcon inline={true}/></TableCell></TableRow>:<>
          {orderHistoryItems.map((orderHistoryItem, index) => {
            const token = tokens?.filter(token => token.tokenId === orderHistoryItem?.tokenId)[0];
            return <TradeHistoryTableRow key={index} orderHistoryItem={orderHistoryItem} token={token} publicView showsUserAddressLink={showsUserAddressLink}/>
          })}
          {orderHistoryItems?.length==0&&<TableRow hover sx={sxEmptyRow}>
            <TableCell colSpan={10}>
              Trade history not found.
            </TableCell>
          </TableRow>}
        </>}
        </TableBody>
      </Table>
      {isFetchingAllRateOrders&&<LoadingIcon curtain/>}
    </TableContainer>
    <TablePagination
      component={Box}
      rowsPerPageOptions={[/* 5,10,25,{label:'All',value:-1} */]}
      // labelRowsPerPage={null}
      // colSpan={1}
      count={maxCount}
      rowsPerPage={pageLimit}
      page={page}
      showFirstButton={true}
      showLastButton={true}
      sx={{position:'sticky',right:'auto',left:0,zIndex:1}}
      onPageChange={(event:any,newPage:number)=>{
        setPage(newPage);
      }}
    />
  </Box>
  );
  return table;
}

export default PublicTradeHistoryTable;