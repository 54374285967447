import { Box } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';
import { ColumnApi, GridApi, IRowNode } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import Decimal from 'decimal.js';
import { memo, useEffect, useMemo, useState } from 'react';
import LoadingIcon from 'src/Components/Loading';
import DragGrabber from 'src/Components/MarketGrids/DragGrabber';
import MarketRollingDateLabel from 'src/Components/MarketRollingDateLabel';
import { gridHeaderHeight, gridRowHeight, sxGridHeader } from 'src/styles/AgGrid';
import { sxRightDeltaTextNegative, sxRightDeltaTextPositive } from 'src/styles/General';
import { styleGrid, styleHeader, styleHeaderExtra, stylePanelScrollable } from 'src/styles/GridBox';
import { sxCellInner, sxCellInnerPercentage, sxEQRateCell, sxRateCellAsks, sxRateCellBids, sxRowsAsks, sxRowsBids, sxRowsContainerOrderBook, sxRowsRate, sxVolumeCell } from 'src/styles/OrderBook';
import { sxDate } from 'src/styles/TokenTable';
import { BaseMarket, MarketInfo, OrderBookEntryWithDepth, RateOrderBook, SelectedOrderBookEntry } from 'src/types';
import apis from 'src/utils/apis';
import { usePrevious } from 'src/utils/common';
import { formatStartOfDayMarketDate } from 'src/utils/date';
import { dps, format } from 'src/utils/numbers';
import { toPercentage } from 'src/utils/string';
import { GridComponentParams } from '.';

const CellTooltip = styled(({ className, ...props }:TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} enterTouchDelay={0} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    cursor: 'default'
  },
});

const InnerOrderBook = memo(({
  market,marketInfo,orderBook,isLoading,onSelectOrderBookEntry,
}:{
  market?:BaseMarket,marketInfo?:MarketInfo,orderBook?:RateOrderBook,isLoading?:boolean,onSelectOrderBookEntry?:((orderBookEntry?: SelectedOrderBookEntry) => any),
})=>{
  const [ ordersDeposit ] = useState<OrderBookEntryWithDepth[]>([]);
  const [ ordersBorrow ] = useState<OrderBookEntryWithDepth[]>([]);
  const ordersEQRate = useMemo(() => ( marketInfo?.rate || marketInfo?.midRate) ? new Decimal( marketInfo?.rate || marketInfo?.midRate || 0).mul(100) : undefined, [marketInfo]);
  const previousOrdersEQRate = usePrevious(ordersEQRate);
  const ordersEQRateDelta = ( (previousOrdersEQRate&&ordersEQRate!==undefined) && (
    ordersEQRate.gt(previousOrdersEQRate)?1:
      ordersEQRate.lt(previousOrdersEQRate)?-1:
    0) )||0;
  const DISPLAY_LIMIT = 20;
  const { asks, bids } = orderBook||{};
  const quantityStepDecimals = dps(new Decimal(market?.quantityStep||0).toNumber());
  const [depositGridApi,setDepositGridApi] = useState<GridApi|undefined>();
  const [depositColumnApi,setDepositColumnApi] = useState<ColumnApi|undefined>();
  const onDepositGridReady = (params:any) => {
    setDepositGridApi(params.api);
    setDepositColumnApi(params.columnApi);
  };
  const [borrowGridApi,setBorrowGridApi] = useState<GridApi|undefined>();
  const [borrowColumnApi,setBorrowColumnApi] = useState<ColumnApi|undefined>();
  const onBorrowGridReady = (params:any) => {
    setBorrowGridApi(params.api);
    setBorrowColumnApi(params.columnApi);
  };

  const getRowId = useMemo(() => ({data}:{data:OrderBookEntryWithDepth}) => `${new Decimal(data?.rate||0).mul(100).toFixed(2)}`,[]);

  useEffect(()=>{
    if(!depositGridApi||!borrowGridApi||!depositColumnApi||!borrowColumnApi) return;
    // sort data
    const sortFn = (a:OrderBookEntryWithDepth,b:OrderBookEntryWithDepth)=>Number(a.rate)<=(Number(b.rate))?1:Number(a.rate)>=(Number(b.rate))?-1:0;
    const _ordersDeposit = (asks?.sort(sortFn)||[]).slice(-DISPLAY_LIMIT);
    const _ordersBorrow = (bids?.sort(sortFn)||[]).slice(0,DISPLAY_LIMIT);
    const ordersDepositMax = new Decimal((_ordersDeposit||[]).reduce((sum,o)=>Number(o.quantity)+(sum),0)||0);
    const ordersBorrowMax = new Decimal((_ordersBorrow||[]).reduce((sum,o)=>Number(o.quantity)+(sum),0)||0);
    const ordersMax = Decimal.max(ordersDepositMax, ordersBorrowMax).toString();
    const forEachFn = (data:OrderBookEntryWithDepth)=>{
      const lendDepthDecimal = data.lendDepth!==undefined ? new Decimal(data.lendDepth) : undefined;
      const borrowDepthDecimal = data.borrowDepth!==undefined ? new Decimal(data.borrowDepth) : undefined;
      return Object.assign(data,{localMax:ordersMax,localPercentage:(lendDepthDecimal || borrowDepthDecimal)?.div(ordersMax||0).toNumber()});
    };
    _ordersDeposit?.forEach(forEachFn);
    _ordersBorrow?.forEach(forEachFn);
    
    // iterate data and manipulate rows
    const iterateItems = (_orders:OrderBookEntryWithDepth[]|undefined,gridApi:GridApi,columnApi:ColumnApi)=>{
      const flashRows = new Array<IRowNode<any>>();
      const gridCfg:{[key:string]:Array<OrderBookEntryWithDepth>} = {add:[],update:[],remove:[]};
      const allItemIds = _orders?.map(data=>getRowId({data}))||[];
      gridApi.forEachNode((node)=>{
        const id = getRowId({data: node.data as OrderBookEntryWithDepth});
        if(!allItemIds.includes(id)){
          gridCfg.remove.push(node.data);
        }
      });
      _orders?.forEach((order,idx)=>{
        const lastDataRow = gridApi.getRowNode(getRowId({data:order}));
        if(lastDataRow){
          gridCfg.update.push(Object.assign(order,{quantityLast:lastDataRow.data.quantity}));
          if(order.quantity!==lastDataRow.data.quantity){
            flashRows.push(lastDataRow);
          }
        }else{
          gridCfg.add.push(order);
        }
      });
      gridApi.applyTransaction(gridCfg);
      gridApi.flashCells({rowNodes:flashRows}); // disable flash
      columnApi.applyColumnState({state:[{colId:'rate',sort:'desc'}]});
    }

    iterateItems(_ordersDeposit,depositGridApi,depositColumnApi);
    iterateItems(_ordersBorrow,borrowGridApi,borrowColumnApi);


  },[asks,bids,borrowGridApi,depositGridApi,borrowColumnApi,depositColumnApi,getRowId, quantityStepDecimals]);

  // DEV CellRenderer data paramter doesnt get updated correctly on applyTransaction
  const TotalCell = (({value,data}:{value:string,data:OrderBookEntryWithDepth})=>{
    const lendDepthDecimal = data.lendDepth!==undefined ? new Decimal(data.lendDepth) : undefined;
    const borrowDepthDecimal = data.borrowDepth!==undefined ? new Decimal(data.borrowDepth) : undefined;
    const depth = (lendDepthDecimal||borrowDepthDecimal)||new Decimal(0);
    return (
      <Box sx={sxCellInner}>
        <Box sx={{...sxVolumeCell, cursor: 'pointer' }}>
          {format(depth,quantityStepDecimals)}
        </Box>
      </Box>
    );
  });

  const QuantityCell = (({value,data}:{value:string,data:OrderBookEntryWithDepth})=>{
    return (
      <Box sx={sxCellInner}>
        <Box sx={sxVolumeCell}>
          {format(value ?? 0,quantityStepDecimals)}
        </Box>
      </Box>
    );
  });

  const RateCell = (({value,data}:{value:string,data:OrderBookEntryWithDepth})=>{
    // range is quantity/totalQuantity
    return (
      <Box sx={sxCellInner}>
          <Box sx={{...(data.side === 0 ? sxRateCellAsks : sxRateCellBids), cursor: 'pointer'} }>{toPercentage(value)}%</Box>
      </Box>
    );
  });
  const PercentageCell = (({value,data}:{value:number,data:OrderBookEntryWithDepth})=>{
    const ask = !!data.lendDepth;
    const minWidthPercentage = 5; // leave at least x% width
    const width = Math.min(100,value*(100-minWidthPercentage)+(minWidthPercentage)).toString(); 
    // position fixed inside `css:transform` element puts it in its own render layer
    return <Box sx={{...sxCellInnerPercentage,bgcolor:ask?'negative.400':'positive.400'}} style={{width:width+'%'}}></Box>;
  });
  const selectOrderBookEntry = (selectedOrderBookEntry: SelectedOrderBookEntry)=>{
    onSelectOrderBookEntry && onSelectOrderBookEntry(selectedOrderBookEntry);
  }

  return (
    <Box sx={styleGrid}>
      <Box sx={styleHeader}>
        Order Book
        {market&&<>: <Box component="span" sx={styleHeaderExtra}>
          <MarketRollingDateLabel market={market} withMarketCode/>&nbsp;{market.maturityDate&&<Box sx={sxDate}>{formatStartOfDayMarketDate(market.maturityDate)}</Box>}
        </Box></>}
      <DragGrabber/></Box>
      <Box sx={{...stylePanelScrollable,p:0,overflow:'hidden',position:'relative'}}>
      {isLoading && <LoadingIcon curtain /> }
      <>
        <Box sx={{
          position:'absolute',
          top:'0',
          width:'100%',
          ...sxGridHeader
        }}>
          <AgGridReact rowData={Array<OrderBookEntryWithDepth>()} suppressMovableColumns domLayout='autoHeight' headerHeight={gridHeaderHeight} rowHeight={gridRowHeight} 
            columnDefs={[
              { field:"localPercentage", headerName:"", cellRenderer:PercentageCell, width:0, flex:0, cellStyle:{ padding:0 }},
              { field:"rate", headerName:"Rate", width: 55 },
              { field:"quantity", headerName:"Size", flex: 1 },
              { field:"side", headerName:"Total", flex:1 },
            ]} suppressNoRowsOverlay
          />
        </Box>
        <Box sx={sxRowsContainerOrderBook}>
          <Box sx={sxRowsAsks}>
            <AgGridReact rowStyle={{ cursor: 'default'}} rowData={ordersDeposit} onGridReady={onDepositGridReady} getRowId={getRowId} suppressMovableColumns domLayout='autoHeight' headerHeight={/* gridHeaderHeight */0} rowHeight={gridRowHeight} /* animateRows */ enableCellChangeFlash cellFlashDelay={0} cellFadeDelay={1000} rowClassRules={{
                'value-delta-up': ({data})=>!!(data&&data.quantityLast&&data.quantity>data.quantityLast),
                'value-delta-down': ({data})=>!!(data&&data.quantityLast&&data.quantity<data.quantityLast),
              }}
              onCellClicked={({data, colDef }) => data&&selectOrderBookEntry({ rate: data.rate, side: 'borrow', column: colDef.field, size: data.lendDepth!==undefined ? new Decimal(data.lendDepth) : undefined})}
              columnDefs={[
                { field:"localPercentage", headerName:"", cellRenderer:PercentageCell, width:0, flex:0, cellStyle:{ padding:0 }},
                { field:"rate", headerName:"Rate", cellRenderer:RateCell, width: 55 },
                { field:"quantity", headerName:"Size", cellRenderer:QuantityCell, flex:1 },
                { field:"lendDepth", headerName:"Total", cellRenderer:TotalCell, flex:1, valueFormatter:()=>'' }, // percentage placeholder only
              ]} suppressNoRowsOverlay
            />
          </Box>
          <Box sx={sxRowsRate}><Box sx={sxEQRateCell}>
            <Box sx={ordersEQRateDelta>0?sxRightDeltaTextPositive:ordersEQRateDelta<0?sxRightDeltaTextNegative:undefined}>
              <CellTooltip title="Last Traded Rate" placement="right" arrow>
                <Box component='span' sx={{ cursor: 'default'}}>{ordersEQRate!==undefined?`${format(ordersEQRate,2)}%`:'-'}</Box>
              </CellTooltip>
            </Box>
          </Box></Box>
          <Box sx={sxRowsBids}>
            <AgGridReact rowStyle={{ cursor: 'default'}} rowData={ordersBorrow} onGridReady={onBorrowGridReady} getRowId={getRowId} suppressMovableColumns domLayout='autoHeight' headerHeight={/* gridHeaderHeight */0} rowHeight={gridRowHeight} /* animateRows */ enableCellChangeFlash cellFlashDelay={0} cellFadeDelay={1000} rowClassRules={{
                'value-delta-up': ({data})=>!!(data&&data.quantityLast&&data.quantity>data.quantityLast),
                'value-delta-down': ({data})=>!!(data&&data.quantityLast&&data.quantity<data.quantityLast),
              }}
              onCellClicked={({data, colDef }) => data && selectOrderBookEntry({ rate: data.rate, side: 'lend', column: colDef.field, size: data.borrowDepth!==undefined ? new Decimal(data.borrowDepth) : undefined})}
              columnDefs={[
                {field:"localPercentage", headerName:"", cellRenderer:PercentageCell, width:0, flex:0, cellStyle:{ padding:0 }},
                {field:"rate", headerName:"Rate", cellRenderer:RateCell, width: 55 },
                {field:"quantity", headerName:"Size", cellRenderer:QuantityCell, flex:1 },
                {field:"borrowDepth", headerName:"Total", cellRenderer:TotalCell, flex:1, valueFormatter:()=>'' }, // percentage placeholder only
              ]} suppressNoRowsOverlay
            />
          </Box>
        </Box>
      </>
      </Box>
    </Box>
  );
});

function OrderBook({market,onSelectOrderBookEntry}:GridComponentParams){
  const {data:marketInfo} = apis.rate.useMarketInfo({market});
  const {isLoading,data:orderBook} = apis.rate.useRateOrderBook(market);
  
  return (<InnerOrderBook market={market} marketInfo={marketInfo} orderBook={orderBook} isLoading={isLoading} onSelectOrderBookEntry={onSelectOrderBookEntry}/>);
}

export default OrderBook;