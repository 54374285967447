import { isEqual } from "lodash";
import { useCallback, useRef, useState } from "react";
import { TRADE_HISTORY_TABLE_PAGE_LIMIT } from "src/constants/app";
import { EMarketType, TradeHistoryItem } from "src/types";

type PageLastOrderId = {
    floatOrderId?: number;
    fixedOrderId?: number;
}

export const usePagination = () => {
    const page = useRef<Map<string, number>>(new Map())
    const maxCount = useRef<Map<string, number>>(new Map())
    const pageLastOrderIds = useRef<Map<string, PageLastOrderId[]>>(new Map())

    const updateLastOrderIds = useCallback(
        (orders: TradeHistoryItem[], marketKey: string, hasNextPage: boolean, cutoffFloatingOrderId?: number, cutoffFixedOrderId?: number) => {
            let lastFloatingOrderId // = cutoffFloatingOrderId ?? pageLastOrderIds[page].floatOrderId
            let lastFixedOrderId // = cutoffFixedOrderId ?? pageLastOrderIds[page].fixedOrderId
            const currentPageLastOrderIds = pageLastOrderIds.current.get(marketKey) ?? [{}]
            const currentPage = page.current.get(marketKey) ?? 0

            orders.forEach(order => {
                if (order.marketType === EMarketType.FLOATING && cutoffFloatingOrderId === undefined) {
                    lastFloatingOrderId = order.orderId
                } else if (order.marketType === EMarketType.FIXED && cutoffFixedOrderId === undefined) {
                    lastFixedOrderId = order.orderId
                }
            })

            if (lastFloatingOrderId === undefined) {
                lastFloatingOrderId = cutoffFloatingOrderId ?? currentPageLastOrderIds[currentPage].floatOrderId
            }
            if (lastFixedOrderId === undefined) {
                lastFixedOrderId = cutoffFixedOrderId ?? currentPageLastOrderIds[currentPage].fixedOrderId
            }

            if (!isEqual(currentPageLastOrderIds[currentPage + 1], { floatOrderId: lastFloatingOrderId, fixedOrderId: lastFixedOrderId})) {
                currentPageLastOrderIds[currentPage + 1] = { floatOrderId: lastFloatingOrderId, fixedOrderId: lastFixedOrderId}
                // setPageLastOrderIds(()[...pageLastOrderIds])
                pageLastOrderIds.current.set(marketKey, [...currentPageLastOrderIds])
                maxCount.current.set(marketKey, hasNextPage ? -1 : currentPage * TRADE_HISTORY_TABLE_PAGE_LIMIT + orders.length);
            }
        }, [pageLastOrderIds, page]
    )

    return {
        getPage: (marketKey: string) => page.current.get(marketKey) ?? 0,
        // currentLastOrderIds: pageLastOrderIds[page],
        getCurrentLastOrderIds: (marketKey: string) => (pageLastOrderIds.current.get(marketKey) ? pageLastOrderIds.current.get(marketKey)![page.current.get(marketKey) ?? 0] : {}) as PageLastOrderId,
        updateLastOrderIds,
        getMaxCount: (marketKey: string) => maxCount.current.get(marketKey) ?? -1,
        setPage: (marketKey: string, pageNumber: number) => page.current.set(marketKey, pageNumber)
    }
}