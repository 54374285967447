import { Box, keyframes } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { GlossaryItem } from "src/types";
import apis from "src/utils/apis";
import theme from "src/utils/theme";

const animTooltipFadeIn = keyframes` from{opacity:0} to{opacity:1}`;
const animTooltipFadeOut = keyframes` from{opacity:1} to{opacity:0}`;

/* const getElementOffset = (element:HTMLElement)=>{
  let offsetElement = element;
  let x = 0; let y = 0; 
  do  {
    x += element.offsetLeft-element.scrollLeft;
    y += element.offsetTop-element.scrollTop;
    element = element.offsetParent as HTMLElement;
    offsetElement = offsetElement.parentNode as HTMLElement;
    while (offsetElement !== element) {
      x -= offsetElement.scrollLeft;
      y -= offsetElement.scrollTop;
      offsetElement = offsetElement.parentNode as HTMLElement;
    }
  } while (element.offsetParent);
  return [x,y];
} */

const getElementTooltipContext = (element:HTMLElement)=>{
  let context:string|undefined;
  do  {
    context = element?.dataset?.tooltipContext;
    element = element.parentNode as HTMLElement;
  } while (element && context === undefined);
  return context?.toLowerCase();
}

/**
 * Any text enclosed by an element (`<el>text</el>`) would be matched to tooltip entry in `/public/data/glossary.json`. Context can be supplied and matched to the closest parent with `data-tooltip-context` set. (`<parent data-tooltip-context="context"><el>text</el></parent>`)
 */
function WithGlossaryTooltip({children}:{children:string|JSX.Element|JSX.Element[]}){
  const {data:glossaryMap} = apis.infinity.useGlossaryMap();
  const tooltipRef = useRef<HTMLElement|null>(null);
  const [config,setConfig] = useState<{
    isShowing:boolean,glossaryItem?:GlossaryItem,element?:HTMLElement,x:number,y:number,
  }>({
    isShowing:false,glossaryItem:undefined,element:undefined,x:0,y:0,
  });
  useEffect(()=>{
    if(!glossaryMap) return;
    const mouseover = (event:MouseEvent)=>{
      if(!glossaryMap) return;
      const element = (event.target as HTMLElement);
      const text = element?.textContent?.toLowerCase().split('(')[0].trim();
      if(text){
        let glossaryItem = text && glossaryMap.get(text);
        if(!glossaryItem){
          // checks context if no default
          const tooltipContext = getElementTooltipContext(element);
          const key = tooltipContext?`${tooltipContext}:${text}`:text;
          glossaryItem = text && glossaryMap.get(key);
        }
        if(glossaryItem){
          const {top:y,left/* ,height,width */} = element.getBoundingClientRect();
          // const [x,y] = getElementOffset(element);
          const x = (left+300>window.innerWidth)?window.innerWidth-300:left;
          setConfig({isShowing:true,glossaryItem,element,x,y});
          return;
        }
      }
      setConfig(config=>({...config,isShowing:false}));
    };
    const mouseout = (event:MouseEvent)=>{
      const element = (event.target as HTMLElement);
      if(element===config?.element){
        setConfig(config=>({...config,isShowing:false}));
      }
    };
    document.addEventListener('mouseover',mouseover);
    document.addEventListener('mouseout',mouseout);
    return ()=>{
      document.removeEventListener('mouseover',mouseover);
      document.removeEventListener('mouseout',mouseout);
    };
  },[glossaryMap,config?.element]);
  return <>
    {children}
    <Box ref={tooltipRef} sx={{
      animation: `${(config.isShowing&&config.glossaryItem?.tooltip)?animTooltipFadeIn:animTooltipFadeOut} 0.25s 1 ease-out forwards`, 
      pointerEvents:'none',position:'fixed',top:0,left:0,zIndex:theme.zIndex.tooltip,transform:`translate(calc(${config.x}px),calc(${config.y}px - 100%))`,
      backgroundColor: 'grey.600', borderRadius: '4px', padding: '4px 8px', maxWidth: '300px', margin: '2px', overflowWrap: 'break-word', 
      color: 'text.primary', fontSize: '0.75rem', fontWeight: '500', fontFamily: 'sans-serif',
    }}>
      {config.glossaryItem?.tooltip ?? ''}
    </Box>
  </>;
}

export default WithGlossaryTooltip;