import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { SxProps } from '@mui/system';

const sxIcon:SxProps = {
  cursor: 'grab', position:'absolute', top: '8px', right: '4px',
  ":active":{
    cursor: 'grabbing', 
  },
  ":not(:hover)":{
    opacity:0.5,
  },
  "> path":{
    pointerEvents: 'none',
  }
  // [theme.breakpoints.down('tablet')]: {
  //   display: 'none',
  // }
};

function DragGrabber(){
  return (<DragIndicatorIcon sx={sxIcon} className='marketgridHandle'/>);
}

export default DragGrabber;