import Decimal from 'decimal.js';
import { IRateOrderParams } from 'src/utils/apis/rate'
import { BaseMarket, BaseRateOrder, ETradeType } from './market';
import { Token } from './token';

export enum EDialogStatus {
  WAIT_FOR_CONFIRMATION, ORDER_IN_PROGRESS, ORDER_PLACED, ERROR
};
export enum EOrderCancelDialogStatus {
  WAIT_FOR_CONFIRMATION, ORDER_CANCEL_IN_PROGRESS, ORDER_CANCEL_SUCCESS, ORDER_CANCEL_PARTIAL_SUCCESS, ORDER_CANCEL_NOT_PROCESSED, ERROR
};

export interface OrderParams extends IRateOrderParams {
  adjustedRate?: Decimal; //   Only required for limit order - convert to string
  maxSlippage: Decimal;
  targetRate: Decimal;
  orderRate: Decimal;
  estimatedSlippage?: Decimal;
  estimatedFillAmount?: Decimal;
  summaryRate?: string;
}

export interface TradeParams {
  primaryLeg: OrderParams;
  secondaryLeg?: OrderParams;
}
  
export type DialogParams = {
  tradeType: ETradeType;
  tradeParams: TradeParams;
  primaryToken: Token;
  primaryMarket: BaseMarket;
  secondaryToken?: Token;
  secondaryMarket?: BaseMarket;
  summaryText?: string;
}
  
export type ConfirmationDialogProps = {
  handleClose: () => void;
  isOpen:boolean;
  isFixedRate: boolean;
  params: DialogParams;
  status: EDialogStatus;
  handleConfirm: () => void;
}
  
export type SummaryDialogProps = {
  isOpen: boolean;
  isFixedRate: boolean;
  lastOrder: BaseRateOrder;
  tokenCode: string;
  tradeType: ETradeType;
  params: DialogParams;
  handleClose: () => void;
}

export type ConfirmationDialog = {
  openDialog: (dialogParams: DialogParams) => void;
}

export enum TransferType {
  TRANSFER_COMPLETE = 1,
	TRANSFER_CHAIN_COMPLETE = 2,
	TRANSFER_CHAIN_FAUCET_COMPLETE = 3,
	TRANSFER_WITHDRAWL = 4,
	TRANSFER_ERROR = 5
}

export type TransferData = {
  transferAmount: Decimal,
  tokenCode: string,
  from: string,
  to: string
}