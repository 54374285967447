import { Box, FormControl, MenuItem, Select, SelectChangeEvent, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useState, ReactNode } from 'react';
import TokenIcon from 'src/Components/TokenIcon';
import { BootstrapMenuItem, BootstrapSelect, sxSelectMenu } from 'src/styles/Form';
import { Token } from 'src/types';

const sxAssetMenuItem = {
    "ul.MuiList-root.MuiMenu-list":{
        padding: '0px',
    },
}

const BaseTokenSwitcher = ({
    tokens,
    selectedTokenId,
    onSelectionChange
}: {
    tokens: Token[],
    selectedTokenId?: number,
    onSelectionChange?: (selectedTokenId: number) => void
}) => {
    const [isOpeningTokenSelect,setIsOpeningTokenSelect] = useState(false);

    return (
        <BootstrapSelect MenuProps={{sx: {...sxSelectMenu, ...sxAssetMenuItem}}}
            value={selectedTokenId}
            open={isOpeningTokenSelect}
            onOpen={()=>setIsOpeningTokenSelect(true)}
            onClose={()=>setIsOpeningTokenSelect(false)}
            onChange={(event: SelectChangeEvent<unknown>, child: ReactNode)=>{
                onSelectionChange && onSelectionChange(Number(event?.target?.value));
            }}
            disabled={false}
        > 
            {
                tokens?.map((token,i) => (<BootstrapMenuItem sx={{ paddingLeft: '0'}} key={`${token.code}-${i}`} value={token.tokenId}>
                    <Box sx={{paddingTop:'6px',paddingBottom:'6px', display: 'flex', flexDirection: 'row'}}>
                        <TokenIcon token={token} withCode={false} size={16} variant="bold"/>
                        <Box sx={{ fontSize: '14px'}}>{token.code}</Box>
                    </Box>
                    </BootstrapMenuItem>)
                )
            }
        </BootstrapSelect>
      )
}

export default BaseTokenSwitcher