import OpenInNew from '@mui/icons-material/OpenInNew';
import { Box, Link, SxProps } from '@mui/material';
import Blockies from 'react-blockies';
import { getEtherscanBaseUrl } from 'src/utils/common';

const sxLink:SxProps = {color:'text.primary',whiteSpace:'nowrap'};
const sxIcon:SxProps = {display:'inline',marginBottom:'2px',fontSize:14,verticalAlign:'middle'};
const sxInner:SxProps = {display:'inline',whiteSpace:'nowrap',
  "canvas":{
    display:'inline-block', verticalAlign:'middle', borderRadius:'50%', marginBottom: '2px', marginLeft: '2px',
  }
};

function WalletAddress({
  address,length,disableLink=false,iconOnly=false,
  withAvatar=false,avatarPosition='right',avatarSize=16,avatarScale=2,
  sx={},
}:{
  address:string,length?:number,disableLink?:boolean,iconOnly?:boolean,
  withAvatar?:boolean,avatarPosition?:'left'|'right',avatarSize?:number,avatarScale?:number,
  sx?:SxProps,
}){
  const addressAbbr = length==undefined?(address.slice(0,4)+'...'+address.slice(-4)):address.slice(0,length);
  const etherscanBaseUrl = getEtherscanBaseUrl();
  const icon = !disableLink&&<OpenInNew sx={({...sxIcon,...sx} as SxProps)}/>||null;
  const blockies = withAvatar&&<Blockies seed={address} size={avatarSize/avatarScale} scale={avatarScale}/> 
  const inner = iconOnly?icon:<Box sx={({...sxInner,...sx} as SxProps)}>{avatarPosition=='left'&&blockies} {addressAbbr} {avatarPosition=='right'&&blockies}{icon}</Box>;
  if(disableLink) return inner;
  return (
    <Link sx={sxLink} href={`${etherscanBaseUrl}/address/${address}`} target="_blank" title="View address on etherscan.io">
      {inner}
    </Link>
  );
}


export default WalletAddress;