import { Box, MenuItem, Select, SelectChangeEvent, SxProps, Typography } from '@mui/material';
import { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import NavButton from 'src/Components/NavButton';
import { sxContent, sxContentWide, sxPageHeading, sxPageHeadingRow, useSxSubHeader } from 'src/styles/Page';
import { usePageTitle } from 'src/utils/common';
import theme from 'src/utils/theme';

export interface SubHeaderTabs {
  [key:string]: SubHeaderTab;
};
interface SubHeaderTab {
  label:string;
  title?:string|JSX.Element|JSX.Element[];
  component?:string|JSX.Element|JSX.Element[];
  wide?:boolean;
};

const sxSubHeaderSelect:SxProps = {
  overflow:'hidden',textOverflow:'ellipsis',flex:1,
  fontWeight:600,
  '.MuiOutlinedInput-notchedOutline':{
    borderStyle:'none', outline:0,
  },
  [theme.breakpoints.up('tablet')]:{display:'none'}
};
const sxSubHeaderList:SxProps = {display:'flex',[theme.breakpoints.down('tablet')]:{display:'none'}};
const sxItem:SxProps = {display:'grid',alignItems:'center'};
const sxActive:SxProps = {...sxItem,fontWeight:600,borderBottom:'2px solid #fff',paddingTop:'2px'};
const sxLabel:SxProps = {lineHeight:'1rem'};

function WithSubHeader({
  parentTitle,urlBase,label,title,children,tabs,tab,
}:{
  parentTitle:string,urlBase:string,label:string,title?:string|JSX.Element|JSX.Element[],children:string|JSX.Element|JSX.Element[],
  tabs:SubHeaderTabs,tab:string,
}){
  const {wide,label:activeTabLabel=''} = tabs[Object.keys(tabs).filter(t=>t===tab)[0]]||{};
  usePageTitle([activeTabLabel,parentTitle],[parentTitle,activeTabLabel]);
  return (
    <>
      <SubHeader parentTitle={parentTitle} tab={tab} tabs={tabs} urlBase={urlBase}/>
      <Box sx={wide?sxContentWide:sxContent}>
        {typeof title=='undefined'||typeof title=='string'&&<Box sx={sxPageHeadingRow}>
          <Typography variant="h1" sx={sxPageHeading}>{title||label}</Typography>
        </Box>||title}
        {children}
      </Box>
    </>
  );
}

function SubHeader({parentTitle,tab,tabs,urlBase}:{parentTitle:string,tab?:string,tabs:SubHeaderTabs,urlBase:string}) {
  const sxSubHeader = useSxSubHeader();
  const navigate = useNavigate();
  const handleSelect = useCallback((event:SelectChangeEvent<string>)=>{
    const tab = event.target.value;
    navigate(`${urlBase}/${tab}`);
  },[navigate,urlBase]);
  return (
    <Box sx={sxSubHeader}>
      <Select sx={sxSubHeaderSelect} value={`${tab}`} onChange={(event)=>handleSelect(event)} variant='outlined'>
      {Object.keys(tabs).map((t)=>(
        <MenuItem key={t} value={t}>{tabs[t].label}</MenuItem>
      ))}
      </Select>
      <Box sx={sxSubHeaderList}>
      {Object.keys(tabs).map((t,i)=>{
        const {label} = tabs[t];
        return (
          <Box key={i} sx={{...(t==tab?sxActive:sxItem)}}><NavButton><Link to={`${urlBase}/${t}`}><Box sx={sxLabel}>{label}</Box></Link></NavButton></Box>
        );
      })}
        <Box sx={{flex:1}}> </Box>
      </Box>
    </Box>
  );
}


export default WithSubHeader;