/* User, Account, Wallet */
import { BigNumberish } from 'ethers';
import { z } from "zod";

/* enums */
export enum ETokenValuationProtocol {
	DEFAULT,
	AAVE_V2,
	UNISWAP_V3,
	CURVE,
	COMPOUND
}
export enum ETokenType {
	NULL=0, ERC20=1, ERC721=2,
}

/* tokens */
export const UnderlyingAssetSchema = z.object({
	underlyingTokenId: z.number(),
	underlyingTokenIndex: z.string(),
	erc721TokenId: z.number().optional(),
});
export interface UnderlyingAsset extends z.infer<typeof UnderlyingAssetSchema>{};
export const AccountWalletTokenERC721Schema = z.object({
	accountId: z.number(),
	tokenId: z.number(),
	erc721TokenId: z.number(),
	underlyingAssets: z.array(UnderlyingAssetSchema),
});
export interface AccountWalletTokenERC721 extends z.infer<typeof AccountWalletTokenERC721Schema>{};

export const ChainSchema = z.object({
	id: z.number(),
	name: z.string(),
});
export interface Chain extends z.infer<typeof ChainSchema>{};
export const TokenForIconSchema = z.object({
	code: z.string(),
	name: z.string(),
	erc721TokenId: z.string().optional(),
});
export interface TokenForIcon extends z.infer<typeof TokenForIconSchema>{};
export const TokenSchema = z.object({
	tokenId: z.number(),
	code: z.string(),
	name: z.string(),
	tokenType: z.nativeEnum(ETokenType), 
	valueType: z.number(), 
	tokenValuationProtocol: z.number(),
	tokenAddress: z.string(),
	decimals: z.number(),
	nativeToken: z.boolean(),
	withdrawFee: z.string(),
	price: z.string(), // USD price 
	erc721TokenId: z.string().optional(),
	underlyingAssets: z.array(UnderlyingAssetSchema).optional(),
});
export interface Token extends z.infer<typeof TokenSchema>{};
export const TokenWithBalanceSchema = TokenSchema.extend({
	balance: z.string().nullable(),
	erc721Tokens: z.array(z.string()).nullable(),
});
export interface TokenWithBalance extends z.infer<typeof TokenWithBalanceSchema>{};

export const TokenTransfersSchema = z.object({
	token: z.string(), // token address
	amount: z.custom<BigNumberish>(),
});
export interface TokenTransfers extends z.infer<typeof TokenTransfersSchema>{};
