import { Box } from '@mui/material';
import { useState } from 'react';
import TVChartContainer from 'src/Components/TVChartContainer';
import { styleGrid, styleHeader, stylePanel, stylePanelNoPadding } from 'src/styles/GridBox';
import { sxTabButton, sxTabButtonActive } from 'src/styles/General';
import { GridComponentParams } from '.';
import MarketsYieldCurvesChart from '../MarketsYieldCurvesChart';
import MarketYieldCurvesHistoricalSurfaceChart from '../MarketYieldCurvesHistoricalSurfaceChart';
import DragGrabber from './DragGrabber';

function MarketGraph({token,market}:GridComponentParams){
  const [tabType,setTabType] = useState<'tradingview'|'yieldcurve'|'yieldsurface'>('tradingview');
  return (
    <Box sx={styleGrid}>
      <Box sx={styleHeader}>
        <Box sx={tabType === 'tradingview'?sxTabButtonActive:sxTabButton} onClick={()=>setTabType('tradingview')}>Trading View</Box>
        <Box sx={tabType === 'yieldcurve'?sxTabButtonActive:sxTabButton} onClick={()=>setTabType('yieldcurve')}>Yield Curve</Box>
        <Box sx={tabType === 'yieldsurface'?sxTabButtonActive:sxTabButton} onClick={()=>setTabType('yieldsurface')}>Yield Surface</Box>
        <DragGrabber/>
      </Box>
      {tabType === 'tradingview'&&<Box sx={stylePanelNoPadding}><TVChartContainer token={token} market={market}/></Box>}
      {tabType === 'yieldcurve'&&<Box sx={{...stylePanel, p: '36px 12px 0 0' }}><MarketsYieldCurvesChart tokens={token?[token]:[]} initTokenCodes={token?[token.code]:[]} inline={true} withImpliedForwardRates/></Box>}
      {tabType === 'yieldsurface'&&<Box sx={stylePanelNoPadding}><MarketYieldCurvesHistoricalSurfaceChart token={token}/></Box>}
    </Box>
  );
}

export default MarketGraph;