import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Decimal from 'decimal.js';
import { useParams } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import useAppStore from '../store';
import { sxTokenTable, sxTokenTableContainer } from '../styles/TokenTable';
import { EOrderSide, GroupedRateTransaction } from '../types';
import apis from '../utils/apis';
import { format, formatDateTime } from '../utils/numbers';
import TokenIcon from './TokenIcon';
import ValueTableCell from './ValueTableCell';



interface TransactionItem {
  quantity: string,
  side: EOrderSide|EOrderSide,
  borrowPriceIndex?: number,
  lendPriceIndex?: number,
  lendFeeRate?: number,
  rate?: string,
  date: number,
  rateLow: string,
  rateHigh: string,
  transaction?: GroupedRateTransaction,
  action?: undefined,
}
interface HeadCell {
  disablePadding: boolean;
  id: keyof TransactionItem;
  label: string;
  numeric: boolean;
}
const headCells: readonly HeadCell[] = [
  {
    id: 'side',
    numeric: false,
    disablePadding: false,
    label: 'Side',
  },
  {
    id: 'quantity',
    numeric: true,
    disablePadding: false,
    label: 'Quantity',
  },
  {
    id: 'rateLow',
    numeric: true,
    disablePadding: false,
    label: 'Rate (Low)',
  },
  {
    id: 'rateHigh',
    numeric: true,
    disablePadding: false,
    label: 'Rate (High)',
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: 'Trade Date',
  },
  {
    id: 'action',
    numeric: true,
    disablePadding: false,
    label: '',
  },
];

function PortfolioLPTransactionsPanel(){
  const auth = useAuth();
  const { accountId, lpId } = useParams();
  const { currentUserAccountId } = useAppStore();
  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  const {isLoading:isLoadingMarkets,data:markets} = apis.rate.useRateMarkets();
  const {isLoading:isLoadingLPPositions,data:lpPositions} = apis.lp.useUserLPPositions(auth,{accountId:parseInt(accountId||'')});
  const lpPosition = lpPositions?.filter(lpPosition=>lpPosition.lpId==parseInt(lpId||''))[0];
  const market = markets?.filter(market=>market.marketId==lpPosition?.marketId)[0];
  const token = market?.token;
  const {isLoading:isLoadingRateOrders,data:lpPositionTransactions} = apis.lp.useUserLPPositionTransactions(auth,{lpId:lpId&&parseInt(lpId)||undefined});
  const transactionItems:TransactionItem[] = [];
  if(lpPosition&&markets){
    if(lpPositionTransactions){
      transactionItems.push(...lpPositionTransactions
        // .filter(transaction=>Boolean(transaction.side)==Boolean(lpPosition.side)&&!(new Decimal(transaction.quantity).eq(0)))
        .map(transaction=>{
        const { quantity, side, rateLow, rateHigh, date } = transaction;
        return {
          quantity, side, rateLow, rateHigh,
          date,
          transaction,
        };
      }));
    }
    transactionItems.sort((a,b)=>a.date>b.date?-1:a.date<b.date?1:0);
  }
  return (
    <TableContainer component={Box} sx={sxTokenTableContainer}>
      <Table sx={sxTokenTable} aria-label="Markets" size="medium">
        <TableHead>
          <TableRow>
            <TableCell colSpan={headCells.length}>
              <Box sx={{display:'grid',gridTemplateColumns:'1fr auto'}}>
                <Box sx={{textAlign:'left'}}>
                  <Box><TokenIcon token={token}/>{token?.code} - {lpPosition?.side==EOrderSide.LEND?'LEND':'BORROW'} - {formatDateTime(lpPosition?.createDate)}</Box>
                </Box>
                <Box sx={{textAlign:'right'}}>
                  <Box sx={{display:'inline-block',marginLeft:'2em'}}>Total Size: ${new Decimal(lpPosition?.totalSize||0).abs().toString()}</Box>
                  <Box sx={{display:'inline-block',marginLeft:'2em'}}>Rate: {format(new Decimal(lpPosition?.rangeFrom||0).mul(100).toNumber())}%-{format(new Decimal(lpPosition?.rangeTo||0).mul(100).toNumber())}%</Box>
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {markets&&transactionItems.map((orderHistoryItem, index) => {
            const { side, date, quantity, rateLow, rateHigh, transaction } = orderHistoryItem;
            const typeStrArr = [''];
            typeStrArr.push(side==EOrderSide.LEND?'LEND':'BORROW');
            const typeString = typeStrArr.join('');
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={index}
              >
                <ValueTableCell label="Side" align="left">{typeString}</ValueTableCell>
                <ValueTableCell mono label="Quantity" align="right">${format(quantity)}</ValueTableCell>
                <ValueTableCell mono label="Rate (Low)" align="right">{format(new Decimal(rateLow).mul(100).toNumber())}%</ValueTableCell>
                <ValueTableCell mono label="Rate (High)" align="right">{format(new Decimal(rateHigh).mul(100).toNumber())}%</ValueTableCell>
                <ValueTableCell mono label="Trade Date" align="right">{formatDateTime(date)}</ValueTableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PortfolioLPTransactionsPanel;