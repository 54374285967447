import { QueryCache } from "@tanstack/react-query";
import Decimal from 'decimal.js';
import {
	Bar, DatafeedConfiguration,
	ErrorCallback,
	GetMarksCallback,
	HistoryCallback,
	IDatafeedChartApi,
	IDatafeedQuotesApi,
	IExternalDatafeed,
	LibrarySymbolInfo, Mark,
	OnReadyCallback, PeriodParams, QuotesCallback,
	ResolutionString,
	ResolveCallback,
	SearchSymbolResultItem,
	SearchSymbolsCallback,
	ServerTimeCallback,
	SubscribeBarsCallback, SymbolResolveExtension, TimescaleMark
} from '../../charting_library/datafeed-api';
import { BaseMarket, EMarketCategory, Market, MarketFixedRate } from '../../types';
import apis from '../apis';
import { periodLengthMilliseconds } from '../numbers';
import { DataPulseProvider } from './tvchart-datapulse';

export interface UdfCompatibleConfiguration extends DatafeedConfiguration {
	// tslint:disable:tv-variable-name
	supports_search?: boolean;
	supports_group_request?: boolean;
	// tslint:enable:tv-variable-name
}
export type PeriodParamsWithOptionalCountback = Omit<PeriodParams, 'countBack'> & { countBack?: number };

/**
 * reference: UDFCompatibleDatafeedBase
 * This class implements interaction with UDF-compatible datafeed.
 * See UDF protocol reference at https://github.com/tradingview/charting_library/wiki/UDF
 */
export class TVChartDatafeed implements IExternalDatafeed, IDatafeedQuotesApi, IDatafeedChartApi {
	protected _configuration: UdfCompatibleConfiguration = defaultConfiguration();
	protected _symbolsCache: Record<string,LibrarySymbolInfo> = {};
	protected _queryCache: QueryCache|undefined;
	protected _market: BaseMarket|undefined;
	protected _marketType: EMarketCategory = EMarketCategory.SPOT;
	protected _getMarketSymbolCode: Function;

	private readonly _dataPulseProvider: DataPulseProvider;

	public constructor({queryCache,market,getMarketSymbolCode}:{queryCache:QueryCache,market:BaseMarket,getMarketSymbolCode:Function}) {
		this._queryCache = queryCache;
		this._market = market;
		this._getMarketSymbolCode = getMarketSymbolCode;
		if(market.daysToMaturity!==undefined) this._marketType = EMarketCategory.FIXED;
		this._dataPulseProvider = new DataPulseProvider({marketType:this._marketType});
		this._symbolsCache = {};
	}
    getQuotes(symbols: string[], onDataCallback: QuotesCallback, onErrorCallback: (msg: string) => void): void {
        throw new Error('Method not implemented.');
    }
    subscribeQuotes(symbols: string[], fastSymbols: string[], onRealtimeCallback: QuotesCallback, listenerGUID: string): void {
        throw new Error('Method not implemented.');
    }
    unsubscribeQuotes(listenerGUID: string): void {
        throw new Error('Method not implemented.');
    }

	public onReady(callback: OnReadyCallback): void {
		(async()=>{
			callback(this._configuration);
		})();
	}
	public searchSymbols(userInput: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback): void {
		(async ()=>{
			const markets:BaseMarket[] = (await apis.rate.fetchMarkets());
			const symbols:SearchSymbolResultItem[] = markets.map(this._getSearchSymbolResultItemFromMarket.bind(this));
			onResult(symbols);
			markets.forEach((market)=>{
				const symbolInfo: LibrarySymbolInfo = this._getSymbolInfoFromMarket(market);
				this._symbolsCache[market.marketId] = symbolInfo;
			});
		})();
	}

	public resolveSymbol(symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback, extension?: SymbolResolveExtension): void {
		(async ()=>{
			try{
				let symbolInfo = this._symbolsCache[symbolName];
				// debugger;
				if(symbolInfo==null&&this._market){
					const query = this._queryCache?.find(['market',{category:'all',action:'list'}]);
					const markets = (query?.state.data)?((query?.state.data||[]) as Array<Market|MarketFixedRate>):(await apis.rate.fetchMarkets());
					const market = markets.filter(market=>this._getMarketSymbolCode(market)===symbolName)[0];
					console.log("[resolveSymbol] floating market", market);
					// const market:Market = (await apis.rate.fetchMarket({code:symbolName}));
					symbolInfo = this._getSymbolInfoFromMarket(market);
					this._symbolsCache[market.marketId] = symbolInfo;
					onResolve(symbolInfo);
				}
				onResolve(symbolInfo);
			}catch(error:any){
				onError(error);
			}
		})();
	}

	public getBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, periodParams: PeriodParamsWithOptionalCountback, onResult: HistoryCallback, onError: ErrorCallback): void {
		(async()=>{
			// console.log('getBars',symbolInfo);
			const {from,to,countBack} = periodParams;
			const interval = periodLengthMilliseconds(resolution);
			const end = to*1000;
			const start = countBack?Math.min(from*1000,(end-(interval*countBack))):from;
			let marketHistory;
			if(this._marketType===EMarketCategory.SPOT){
				// spot
				marketHistory = await apis.rate.fetchMarketHistory({
					marketId:(symbolInfo.ticker as string),
					start,end,interval
				});
			}else{
				// fixed rate
				marketHistory = await apis.fixedrate.fetchMarketHistory({
					marketId:(symbolInfo.ticker as string),
					start,end,interval
				});
			}
			// const marketHistoryBars:Bar[] = marketHistory.map(history=>Object.assign({},history,{time:history.date}));
			const marketHistoryBars:Bar[] = (marketHistory||[]).map(history=>{
				const bar = {time:history.date,open:0,high:0,low:0,close:0};
				['open','high','low','close'].forEach(k=>{
					bar[k] = new Decimal(history[k]).mul(100).toDP(2).toNumber();
				});
				return bar;
			});
			onResult(marketHistoryBars);
		})();
	}

	public subscribeBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, onTick: SubscribeBarsCallback, listenerGuid: string, onResetCacheNeededCallback: () => void): void {
		this._dataPulseProvider.subscribeBars(symbolInfo, resolution, onTick, listenerGuid);
	}

	public unsubscribeBars(listenerGuid: string): void {
		this._dataPulseProvider.unsubscribeBars(listenerGuid);
	}

	public getMarks(symbolInfo: LibrarySymbolInfo, from: number, to: number, onDataCallback: GetMarksCallback<Mark>, resolution: ResolutionString): void {
		(async()=>{
			const marketHistoryMarks:Mark[] = [{
				id: 0,
				time: Date.now(),
				color: 'red',
				text: 'text',
				label: 'label',
				labelFontColor: 'white',
				minSize: 5,
			}];
			onDataCallback(marketHistoryMarks);
		})();
	}

	public getTimescaleMarks(symbolInfo: LibrarySymbolInfo, from: number, to: number, onDataCallback: GetMarksCallback<TimescaleMark>, resolution: ResolutionString): void {
		(async()=>{
			const marketHistoryTimescaleMark:TimescaleMark[] = [{
				id: 0,
				time: Date.now(),
				color: 'red',
				label: 'label',
				tooltip: [''],
			}];
			onDataCallback(marketHistoryTimescaleMark);
		})();
	}

	public getServerTime(callback: ServerTimeCallback): void {
		// callback(moment().unix());
		return;
	}

	private _getSearchSymbolResultItemFromMarket(market:BaseMarket): SearchSymbolResultItem {
		const exchange = this._marketType===EMarketCategory.SPOT?'FLOAT':'FIXED RATE';
		return {
			"ticker": `${market.marketId}`,
			"symbol": market.instrumentId,
			"full_name": this._getMarketSymbolCode(market,true),
			"description": this._getMarketSymbolCode(market,true),
			"exchange": exchange,
			"type": "index", //"stock" or "futures" or "crypto" or "forex" or "index"
		};
	}

	private _getSymbolInfoFromMarket(market:BaseMarket): LibrarySymbolInfo{
		const exchange = this._marketType===EMarketCategory.SPOT?'FLOAT':'FIXED RATE';
		const defCfg = defaultConfiguration();
		return {
			ticker: `${market.marketId}`,
			name: this._getMarketSymbolCode(market),
			description: this._getMarketSymbolCode(market,true),
			type: 'index',
			session: '24x7',
			exchange: exchange,
			listed_exchange: exchange,
			timezone: 'Asia/Hong_Kong',
			format: 'price',
			pricescale: 100,
			minmov: 0.01,
			has_intraday: true,
			supported_resolutions: defCfg.supported_resolutions||[],
		};
	}
}

function defaultConfiguration(): UdfCompatibleConfiguration {
	return {
		supports_search: false,
		supports_group_request: true,
		supported_resolutions: [
				'7D' as ResolutionString,
				'3D' as ResolutionString,
				'1D' as ResolutionString,
				'300' as ResolutionString,
				'120' as ResolutionString,
				'60' as ResolutionString,
				'15' as ResolutionString,
		],
		supports_marks: false,
		supports_timescale_marks: false,
	};
}
