import { Box } from '@mui/material';
import Decimal from 'decimal.js';
import { useAuth } from 'src/AuthProvider';
import UserTokensGraph from 'src/Components/UserTokensGraph';
import { styleGrid, styleHeader, styleHeaderExtra, stylePanelChartContainer } from 'src/styles/GridBox';
import apis from 'src/utils/apis';
import { format } from 'src/utils/numbers';
import { GridComponentParams } from '.';
import DragGrabber from './DragGrabber';
import { withGraphPosition } from './GraphPositionProvider';

const UserTokensGraphWithGraphPosition = withGraphPosition(UserTokensGraph);

function AmountsGraph({token}:GridComponentParams){
  const auth = useAuth();
  const tokenId = token?.tokenId;
  const {data:userMarketData} = apis.rate.useUserMarketDataByToken(auth,{tokenId});
  let userNetPositions;
  if(userMarketData){
    userNetPositions = new Decimal(userMarketData?.summary?.position||0);
  }
  const sideLabel = userNetPositions?.gte(0)?'Long':'Short'; // TODO confirm long or short label
  return (
    <Box sx={styleGrid}>
      <Box sx={styleHeader}>Amounts
        {userNetPositions&&<>:<Box component="span" sx={styleHeaderExtra}>{format(userNetPositions)} ({sideLabel})</Box></>}
      <DragGrabber/></Box>
      <Box sx={stylePanelChartContainer}>
        <UserTokensGraphWithGraphPosition token={token} graphKey="position" singleTokenGraph/>
      </Box>
    </Box>
  );
}

export default AmountsGraph;