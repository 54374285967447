import { Box, SxProps, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Link, Params, useParams } from 'react-router-dom';
import CashPositionsPanel from 'src/Components/CashPositionsPanel';
import CashTransfersHistory from 'src/Components/CashTransfersHistory';
import { MyPortfolioMenuComponents } from 'src/Components/Header';
import LPPortfolioPanel from 'src/Components/LPPortfolioPanel';
import MTMPerformancePanel from 'src/Components/MTMPerformancePanel';
import MarketRollingDateLabel from 'src/Components/MarketRollingDateLabel';
import PortfolioPanel from 'src/Components/PortfolioPanel';
import RiskOverviewPanel from 'src/Components/RiskOverviewPanel';
// import TradeHistoryTable from 'src/Components/TradeHistoryTable';
import { TradeHistoryTable } from 'src/Components/TradeHistoryTable'
import { sxTitleFloatRight } from 'src/styles/General';
import { sxContent, sxContentWide, sxPageHeading, sxPageHeadingRow, sxSideBar, sxWithSideBar } from 'src/styles/Page';
import { BaseMarket } from 'src/types';
import apis from 'src/utils/apis';
import { usePageTitle } from 'src/utils/common';
import { paths } from 'src/utils/paths';

type ComponentParams = Readonly<Params<string>>;
const tabs = {
  "accounts":{
    "label": "Accounts",
    "title": "Accounts",
    containerParams: { width: 'max-content' },
    "component":(params:ComponentParams)=><CashPositionsPanel/>,
    wide:true
  },
  "risk-overview": {
    "label": "Risk Overview",
    "title": <Box sx={sxPageHeadingRow}>
      <Typography variant="h1" sx={sxPageHeading}>Risk Overview</Typography>
      <Box sx={sxTitleFloatRight}>$ = USD</Box>
    </Box>,
    containerParams: {},
    "component":(params:ComponentParams)=><RiskOverviewPanel/>,
    wide:true
  },
  "positions-pnl": {
    "label": "Positions & PNL",
    "title": <Box sx={sxPageHeadingRow}>
      <Typography variant="h1" sx={sxPageHeading}>Portfolio Summary</Typography>
      <Box sx={sxTitleFloatRight}>$ = USD</Box>
    </Box>,
    containerParams: {},
    "component":(params:ComponentParams)=><PortfolioPanel/>,
    wide:true
  },
  "lp-positions":{
    "label": "LP Positions",
    "title": "Portfolio: Liquidity Provider Positions",
    containerParams: {},
    "component": (params:ComponentParams)=><LPPortfolioPanel/>,
    wide:false
  },
  "cash-transfers": {
    "label": "Transfer History",
    "title": undefined,
    containerParams: {},
    "component": (params:ComponentParams)=><CashTransfersHistory/>,
    wide:true
  },
  "order-history": {
    "label": "Order History",
    "title": <OrderHistoryTitle/>,
    containerParams: { },
    "component": (params:ComponentParams)=><TradeHistoryTable key="history" type="history"
      selectedTokenId={params.tokenId ? Number(params.tokenId) : undefined} 
      selectedMarketId={params.marketId ? Number(params.marketId) : undefined}
    />,
    wide:true
  },
  "open-orders": {
    "label": "Open Orders",
    "title": undefined,
    containerParams: { },
    "component": (params:ComponentParams)=><TradeHistoryTable key="open" type="open"/>,
    wide:true
  },
  "mtm-performance":{
    "label": "MTM Performance",
    "title": (<></>),
    containerParams: {},
    "component": (params:ComponentParams)=><MTMPerformancePanel/>,
    wide:false
  },
};
export type MY_PORTFOLIO_TABS = keyof typeof tabs;

const sxDefaultContainerParams: SxProps = {
  width: '100%',
  margin: '0 auto',
  position: 'relative'
}

function OrderHistoryTitle(){
  const pathParams = useParams();
  const tokenId = Number(pathParams.tokenId);
  const marketId = Number(pathParams.marketId);
  const {data:spotMarket} = apis.rate.useMarket({tokenId,marketId});
  const {data:fixedMarkets} = apis.fixedrate.useFixedRateMarketsByMarketIds(marketId?[marketId]:[]);
  const market = useMemo<BaseMarket>(()=>(spotMarket||fixedMarkets[0]),[spotMarket,fixedMarkets]);
  const hasBreadcrumbs = market!==undefined;
  return <Box sx={sxPageHeadingRow}>
    <Typography variant="h1" sx={sxPageHeading}>
      {hasBreadcrumbs?<>
        <Link to={paths.portfolioPage('order-history')}>Order History</Link> / <MarketRollingDateLabel market={market} withMarketCode/>
      </>:(
        <>Order History</>
      )}
    </Typography>
  </Box>;
}

function MyPortfolio({tab}:{tab:keyof typeof tabs}){
  const pathParams = useParams();
  let {label,title,component,wide, containerParams } = tabs[tab];
  const contentTitle = title||label;
  let titleComponent = <></>;
  if(typeof contentTitle=='undefined'||typeof contentTitle=='string'){
    titleComponent = <Box sx={sxPageHeadingRow}>
      <Typography variant="h1" sx={sxPageHeading}>{contentTitle}</Typography>
    </Box>;
  }else{
    titleComponent = contentTitle;
  }
  usePageTitle(['MyPortfolio',label],[label]);
  return (<Box sx={sxWithSideBar}>
    <Box sx={sxSideBar}>
      <Box component="ul">
        <Box component="li" sx={{fontWeight:700,fontSize:'1rem',textTransform:'uppercase',p:'4px',my:'8px'}}>
          {/* {label} */}
          My Portfolio
        </Box>
        <MyPortfolioMenuComponents showsBottomLine/>
      </Box>
    </Box>
    {/* use `contain:inline-size;` instead of `overflow:hidden;` to preserve scroll pane for `position:sticky` children elements */}
    <Box sx={{...(wide?sxContentWide:sxContent),contain:'inline-size'}}>
      <Box sx={{ ...sxDefaultContainerParams, ...(containerParams ?? {}) }}>
        {titleComponent}
        {component(pathParams)}
      </Box>
    </Box>
  </Box>);
}

export default MyPortfolio;