import { Box, Link, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import Decimal from 'decimal.js';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useAppStore from 'src/store';
import { sxChartPaddingWrapper } from 'src/styles/Chart';
import { sxBox, sxBoxCompactInner, sxBoxTitle, sxInlineLink, sxTitleFloatRight } from 'src/styles/General';
import { sxPageHeading, sxPageHeadingRow, sxPageHeadingSmall, sxPageHeadingWrapper } from 'src/styles/Page';
import { MTMPerformanceItem } from 'src/types';
import { formatUSD } from 'src/utils/numbers';
import { paths } from 'src/utils/paths';
import { useAuth } from '../AuthProvider';
import apis from '../utils/apis';
import DefaultTokensSelector, { defaultTokens } from './DefaultTokensSelector';
import MTMPerformanceGraph from './MTMPerformanceGraph';
import MTMPerformanceTable from './MTMPerformanceTable';
import ReloadDateText from './ReloadDateText';

function MTMPerformancePanel({accountId,graphOnly}:{accountId?:number,graphOnly?:boolean}){
  const auth = useAuth();
  const queryClient = useQueryClient();
  const [selectedTokenCodes,setSelectedTokenCodes] = useState(defaultTokens);
  const { data:tokens } = apis.token.useMarketsPageTokens();
  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  const { currentUserAccountId } = useAppStore();
  const tradeAccount = userAccounts&&apis.user.extractUserAccount(userAccounts,currentUserAccountId);
  const { data:mtmPerformanceByToken, dataUpdatedAt, isFetching } = apis.user.useUserMTMPerformanceByToken(auth,{
    accountId: accountId ?? tradeAccount?.accountId, 
  });

  let totalDecimal = new Decimal(0);
  mtmPerformanceByToken&&Object.keys(mtmPerformanceByToken).forEach((tokenId)=>{
    const token = tokens?.find(t=>t.tokenId===Number(tokenId));
    if(token){
      // const label = token.code;
      mtmPerformanceByToken[tokenId]?.forEach((item:MTMPerformanceItem)=>{
        const date = item.date;
        if(!moment(date).isSame(moment(),'d')) return;
        const cashPosition = new Decimal(item.cashPosition||0).toNumber();
        totalDecimal = totalDecimal.add(new Decimal(cashPosition||0));
      });
    }
  });

  const lastUpdatedTime = useMemo(()=>moment(),[dataUpdatedAt]);
  const handleClickReload = ()=>{
    queryClient.invalidateQueries(['user',{type:'mtmPerformance'}]);
  }
  
  if(graphOnly){
    return (
      <Box sx={{position:'relative'}}>
          <Box sx={sxPageHeadingRow}>
            <Box sx={sxPageHeadingWrapper}>
              <Typography variant="h1" sx={sxPageHeadingSmall}>MTM Performance</Typography>
              <Link component={RouterLink} sx={sxInlineLink} to={paths.portfolioPage("mtm-performance")}>View History</Link>
            </Box>
            <ReloadDateText isFetching={isFetching} lastUpdatedTime={lastUpdatedTime} handleClick={handleClickReload}/>
        </Box>
        <Box sx={sxBox}>
          <Box sx={sxBoxTitle}>
            Cash Position, Realized &amp; Unrealized Positions
            <Box sx={sxTitleFloatRight}>Current MTM: ${formatUSD(totalDecimal)}</Box>
          </Box>
          <Box sx={sxBoxCompactInner}>
            <Box sx={sxChartPaddingWrapper}>
              <MTMPerformanceGraph tokens={tokens?.filter(t=>selectedTokenCodes.includes(t.code))} extraFilterElement={
                <DefaultTokensSelector onChange={(tokens)=>(setSelectedTokenCodes(tokens))}/>
              }/>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box sx={sxPageHeadingRow}>
        <Box sx={sxPageHeadingWrapper}>
          <Typography variant="h1" sx={sxPageHeading}>MTM Performance</Typography>
          <Link component={RouterLink} sx={sxInlineLink} to={paths.portfolioPage("positions-pnl")}>View Positions &amp; PNL</Link>
        </Box>
        <DefaultTokensSelector onChange={(tokens)=>(setSelectedTokenCodes(tokens))}/>
      </Box>
      <Box sx={sxBox}>
        <Box sx={sxBoxTitle}>
          Cash Position, Realized &amp; Unrealized Positions
          <Box sx={sxTitleFloatRight}>Current MTM: ${formatUSD(totalDecimal)}</Box>
        </Box>
        <Box sx={sxBoxCompactInner}>
          <Box sx={sxChartPaddingWrapper}><MTMPerformanceGraph tokens={tokens?.filter(t=>selectedTokenCodes.includes(t.code))}/></Box>
        </Box>
      </Box>
      <Box sx={sxBox}>
        <Box sx={sxBoxTitle}>Your History</Box>
        <Box sx={sxBoxCompactInner}>
          <MTMPerformanceTable tokens={tokens?.filter(t=>selectedTokenCodes.includes(t.code))}/>
        </Box>
      </Box>
    </>
  );
}


export default MTMPerformancePanel;