import { Box } from '@mui/system';
import { Moment } from 'moment';
import { FLOAT_SHORT } from 'src/constants/app';
import { sxLabel } from 'src/styles/TokenTable';
import { MarketForSymbol } from 'src/types';
import { date, formatMarketDate, useRollingDateLabel } from 'src/utils/date';
import { formatDayNumber } from 'src/utils/numbers';

type Props = { 
  market?:MarketForSymbol, daysToMaturity?:number, referenceDate?:Moment, withMarketCode?:boolean, withMarketDate?:boolean, formatAsDay?:boolean, shortens?:boolean
};

export function useMarketRollingDateLabel({ 
  market, daysToMaturity, referenceDate, withMarketCode, formatAsDay, shortens, 
 }:Props){
  const days = daysToMaturity ?? market?.daysToMaturity;
  let dateLabel = useRollingDateLabel(days,referenceDate);
  if(days===undefined||days===0){
    dateLabel = shortens?FLOAT_SHORT:'FLOAT';
  }else if(formatAsDay){
    dateLabel = formatDayNumber(days);
  }
  if(withMarketCode&&!market) { 
    // console.warn(`[Market] market not found when generating rolling date label`);
    dateLabel = '-';
  }
  const marketCode = withMarketCode && market?.token?.code;
  if(marketCode) return `${marketCode}-${dateLabel}`;
  return dateLabel;
}

function MarketRollingDateLabel(props:Props){
  const dateLabel = useMarketRollingDateLabel(props);
  const {market,withMarketDate} = props;
  if(withMarketDate&&market?.maturityDate){
    const marketDate = date(market?.maturityDate);
    return <>{dateLabel}&nbsp;<Box sx={sxLabel}>{formatMarketDate(marketDate)}</Box></>;
  }
  return <>{dateLabel}</>;
}

export default MarketRollingDateLabel;