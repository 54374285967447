import { SxProps } from '@mui/system';
import theme from '../utils/theme';
import { sxMonoFont } from './General';

export const sxStickeyCell:SxProps = {
  position: 'sticky',
  left: 0,
  zIndex: 1,
  "&:after":{
    content: '\'\'', position:'absolute', top:0, left:0, right:'-1px', bottom:0, 
    border: 'solid transparent 0px',
    borderRightWidth: '1px',
    borderRightColor: 'border.dark',
    boxShadow: '0 0px 20px 5px #00000020',
    zIndex: -1,
    pointerEvents: 'none',
  }
};
export const sxCellBottomAlign:SxProps = {
  verticalAlign: 'bottom',
};
export const sxTableRowFollowLast:SxProps = {
  "th":{
    paddingTop: '0',
  }
}

export const sxTokenTableWithChartWrapper:SxProps = {
  display: 'flex',
  flexDirection: 'row',
};
export const sxTokenTableChart:SxProps = {
  // flex: 0, minWidth: '240px', maxWidth: '100%',
  ".MuiTableContainer-root":{
    height: '100%',
  }
};

export const sxTokenTableFilterBox:SxProps = {
  // float: 'right',
  marginBottom: '8px',
  position: 'absolute',
  right: '0px',
  bottom: '100%',
};

export const sxTokenTableContainer:SxProps = {
  // TODO FIXME or implement new wrap around table style
  // [theme.breakpoints.down('tablet')]:{
  //   overflow: 'hidden',
  // }
  position: 'relative',
};

export const sxTokenTable:SxProps = {
  // px: 2, py: '2px',
  bgcolor:'transparent',
  tableLayout:"auto",
  "th,td":{
    px:'12px', py: '6px',
    whiteSpace: 'nowrap',
    borderStyle: 'solid', borderColor: 'border.dark', borderWidth: 0, 
    "&:not(:nth-of-type(2))":{
      width:0,
    },
  },
  "td":{
    fontSize: '0.875rem',
  },
  "th":{
    fontSize: '0.75rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    bgcolor:'table.dark',
  },
  "tr:last-of-type th,tr th[rowspan]":{
    borderBottomWidth: '1px',
  },
  "tbody":{
    zIndex: 1,
    "tr":{
      ":is([data-active=true])":{
        "td":{
          bgcolor:'table.lighter',
        },
      },
      ":not([data-active=true])":{
        "&.MuiTableRow-hover:hover":{
          "td":{
            bgcolor:'table.light',
          },
        },
        "&,.MuiTableRow-hover:not(:hover)":{
          "td":{
            bgcolor:'table.main',
          },
          ":nth-of-type(even)":{
            "td":{
              bgcolor:'table.dark',
            },
          },
        },
      },
    },
    "tr.expandedChild:not(:hover)":{
      bgcolor: 'table.dark'
    },
    "&+thead th": {
      paddingTop: '40px',
    },
  },
  ".MuiTablePagination-spacer":{
    flex:0,
  },
  ".MuiButton-containedPrimary":{
    bgcolor: "primary.light",
    fontWeight: 600,
  },
  // TODO FIXME or implement new wrap around table style
  // [theme.breakpoints.down('tablet')]:{
  //   p: 0,
  //   mx: '-4px',
  //   width: 'calc(100% + 8px)',
  //   "thead":{
  //     display: 'none', visibility:'hidden', opacity: 0,
  //   },
  //   "tr":{
  //     display:"flex", flexDirection:"row", flexWrap:'wrap',
  //     bgcolor: 'table.main', marginBottom: '1rem',
  //   },
  //   "td":{
  //     "&:nth-of-type(n)":{
  //       // textAlign: 'left',
  //       fontSize: '0.875rem',
  //       width: 'auto', border:'none',
  //     },
  //   },
  //   "th,td":{
  //     bgcolor:'inherit',
  //   },
  // }
};
export const sxTokenTableSubHeader:SxProps = {
  borderStyle: 'solid', borderColor: 'grey.500', borderWidth: 0, borderTopWidth: '1px', borderBottomWidth: '0',
  '&:is(tr):is(tr) td':{
    bgcolor: 'table.main',
  }
}
export const sxTokenTableCompactCell:SxProps = {
  '&:is(th):is(th),&:is(td):is(td)':{
    px: '4px',
  }
}
export const sxTokenTableCompactButton:SxProps = {
  fontSize: '0.75rem',
  m: '-6px auto',
  p: '2px',
  "&.MuiButton-text:not(.MuiButton-contained):not(.MuiButton-outlined)": {
    textDecoration: 'none'
  },
  "&.MuiButton-text:not(.MuiButton-contained):not(.MuiButton-outlined):hover": {
    textDecoration: 'none'
  }
};
export const sxLabel:SxProps = {
  fontSize: '.875rem',
  opacity: .5,
  display: 'inline-block',
}
export const sxLabelBlock:SxProps = {
  ...sxLabel,
  display: 'block',
}
export const sxLendAmount:SxProps = {
  color: theme.palette.success.main,
}
export const sxBorrowAmount:SxProps = {
  color: theme.palette.warning.main,
}
export const sxNoDeltaAmount:SxProps = {
  color: theme.palette.text.primary,
  opacity: 0.5,
}
export const sxParentRow:SxProps = {
  "td": {
    borderTopWidth: '1px',
    borderBottomWidth: '1px',
    py: `8px`,
  },
  "&.MuiTableRow-root":{
    "&:not([data-active=true]):not(:hover), &:not([data-active=true]):not(:hover):nth-of-type(even)":{
      "td":{
        bgcolor: 'button.dark',
      }
    }
  }
}
export const sxChildRow:SxProps = {
  "td": {
    paddingLeft: `${0.875*2}rem`,
    // fontStyle: 'italic',
  },
  "&.MuiTableRow-root":{
    "&:not([data-active=true]), &:not([data-active=true]):nth-of-type(even)":{
      "td":{
        bgcolor: 'button.dark',
      }
    }
  }
}
export const sxShortRow:SxProps = {
  "td": {
    py: '2px',
  }
}
export const sxEmptyRow:SxProps = {
  "td":{
    textAlign: 'center',
    py: 4,
  }
}
export const sxEmptyRowCompact:SxProps = {
  "td":{
    textAlign: 'center',
  }
}
export const sxDate:SxProps = {
  ...sxMonoFont,
  opacity: 0.5,
  display: 'inline-block',
}

export const sxChartTable:SxProps = {
  width: 'max-content',
  margin: 'auto',
  "td": {
    py: '40px', borderWidth:'0px',
  },
  "label":{
    fontSize: '1.25rem',
    fontWeight: 700,
    '&+div':{
      fontSize: '1.25rem',
    }
  },
  ".recharts-responsive-container":{
    margin: '0 auto',
  }
}
