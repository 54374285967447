import { alpha, InputBase, MenuItem, Select, styled } from '@mui/material';
import { SxProps } from '@mui/system';
import { hslStrToHex } from 'src/utils/common';
import theme from 'src/utils/theme';
import { StylePrimaryButton, sxMonoFont } from './General';

export const styleFormRow:SxProps = {
  display:'flex', py: '8px', marginRight: -1, width: '100%',
};
export const styleFormCell:SxProps = {
  flex: 1, marginRight: 1
};
export const styleFormButton:SxProps ={
  ...StylePrimaryButton,
  display:'block',
  minWidth: '75%', mx: 'auto',
  // py: '8px',
  // fontSize: '1rem',
  // fontWeight: 700,
  // borderStyle: 'solid',
  // borderWidth: '1px',
  // borderRadius: '3px',
  // textTransform: 'initial',
  // color: 'text.primary',
  // bgcolor: 'primary.light',
  // borderColor: 'primary.light', 
}
export const styleFormLabel:SxProps = {
  fontSize: '.75rem',
  opacity: .5,
}
export const sxFormRadioButtonGroup:SxProps = {
  // display:'flex', flexDirection:'row',
}
export const sxFormRadioButton:SxProps = {
  flex:1,display:'inline-block',px:2,py:1,mx:'-1px',border:'1px solid',borderColor:'border.main',
}
export const sxInputLabel:SxProps = {
  width: '100%',
  fontWeight: 700,
};
export const sxInputLabelFloatRight:SxProps = {
  fontWeight: 400,
  left: 'auto', right: 0, textAlign: 'right', transformOrigin: 'top right',
  [theme.breakpoints.down('tablet')]:{
    // display: 'block',
    // float: 'none',
  }
};

/* compacts */
export const sxCompactFormTable:SxProps = {
  /* display:'flex',flexDirection:'column',flex:'1', */maxWidth:'300px',mx:'auto',
  my:'8px',
  "td":{
    border: 0, py: '12px'
  },
  ".MuiToggleButtonGroup-root,.MuiButton-root":{
    width:'100%',
  },
  // ".MuiInput-root,.MuiTypography-root,.MuiButton-root": {
  //   fontSize:'0.75rem',
  // }
};
export const sxCompactForm:SxProps = {
  display:'flex',flexDirection:'column',flex:'1', 
  // ".MuiInput-root,.MuiTypography-root,.MuiButton-root": {
  //   fontSize:'0.75rem',
  // }
};
export const sxCompactFormRow:SxProps = {
  display:'flex', py: '8px', marginRight: -1
};
export const sxCompactFormCell:SxProps = {
  flex: 1, marginRight: 1
};

export const sxInputFocused:SxProps = {
  boxShadow: `${alpha(theme.palette.primary.light, 0.25)} 0 0 1px 0`,
  borderColor: theme.palette.primary.light,
  backgroundColor: theme.palette.border.main,
};

/* styled components */
export const BootstrapSelect = styled(Select)(({ theme }) => ({
  'label + &': {
    marginTop: 'calc(0.875rem + 4px)',
  },
  '&': { //.MuiInputBase-input
    ...sxMonoFont,
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.button.main,
    border: `1px solid ${hslStrToHex(theme.palette.border.main)}`,
    fontSize: '0.875rem',
    width: 'auto',
    height: '32px', 
    padding: '0',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // // Use the system font instead of the default Roboto font.
    // fontFamily: [
    //   '-apple-system',
    //   'BlinkMacSystemFont',
    //   '"Segoe UI"',
    //   'Roboto',
    //   '"Helvetica Neue"',
    //   'Arial',
    //   'sans-serif',
    //   '"Apple Color Emoji"',
    //   '"Segoe UI Emoji"',
    //   '"Segoe UI Symbol"',
    // ].join(','),
    ".MuiSelect-select":{
      paddingLeft: '12px',
    },
    '&.Mui-focused,&:hover': sxInputFocused,
    '&:before':{
      display:'none',
    },
    ".MuiSvgIcon-root": {
      width: '0.833em',
      height: '0.833em',
      marginTop: '0.0833em',
      marginRight: '0.25em'
    }
  },
}));
export const BootstrapMenuItem = styled(MenuItem)(({ theme }) => ({
  '&': { //.MuiInputBase-input
    ...sxMonoFont,
  },
}));

export const sxSelectMenu:SxProps = {
  ":is(.MuiModal-root) .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper":{
    backgroundColor: theme.palette.button.main,
    border: `1px solid ${theme.palette.border.main}`,
    boxSizing: 'content-box', // for border
    marginTop: '4px',
  },
  "ul":{
    padding: '0px 4px',
  },
  "li":{
    fontSize: '0.875rem',
    padding: '4px 8px',
    "&:is(.Mui-selected),&:is(.Mui-selected):hover":{
      backgroundColor: `${hslStrToHex(theme.palette.button.light)}99`,
    },
  },
};

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 'calc(0.875rem + 4px)',
  },
  '&': { //.MuiInputBase-input
    ...sxMonoFont,
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.button.main,
    border: `1px solid ${hslStrToHex(theme.palette.border.main)}`,
    fontSize: '0.875rem',
    width: 'auto',
    height: '32px', 
    padding: '0 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // // Use the system font instead of the default Roboto font.
    // fontFamily: [
    //   '-apple-system',
    //   'BlinkMacSystemFont',
    //   '"Segoe UI"',
    //   'Roboto',
    //   '"Helvetica Neue"',
    //   'Arial',
    //   'sans-serif',
    //   '"Apple Color Emoji"',
    //   '"Segoe UI Emoji"',
    //   '"Segoe UI Symbol"',
    // ].join(','),
    '&.Mui-focused,&:hover': sxInputFocused,
    '&.Mui-error': {
      border: `1px solid ${hslStrToHex(theme.palette.error.main)}`,
      backgroundColor: theme.palette.error.light,
      
    }
  },
}));