import { useEffect, useState } from 'react';
import { MarketFixedRate, BaseMarket } from "src/types";
import { isNil, isEqual } from 'lodash';

const useDaysToMaturityMap = (spotMarket?: BaseMarket, marketsFixedRate?: MarketFixedRate[]) => {
    const [daysToMaturityMap, setDaysToMaturityMap] = useState<{ [key: string]: number}>({})

    useEffect(() => {
        const startingMap = !isNil(spotMarket?.marketId) ? { [spotMarket!.marketId]: 0 } : {}
        const newDaysToMaturityMap =  (marketsFixedRate ?? []).reduce((map, market) => {
            return {
                ...map,
                [market.marketId]: market.daysToMaturity
            }
        }, startingMap)
        if (!isEqual(daysToMaturityMap, newDaysToMaturityMap)) {
            setDaysToMaturityMap(newDaysToMaturityMap)
        }
    }, [spotMarket, marketsFixedRate, daysToMaturityMap])

    return daysToMaturityMap;
}

export default useDaysToMaturityMap;