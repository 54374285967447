import { useEffect, useRef } from "react";
import { CHAIN_ID, ETHERSCAN_CHAINSUBDOMAIN_MAPPING, ETH_CHAIN_MAP } from "src/constants/app";
import { BaseMarket, MarketForSymbol, MarketPastRate, Token } from "src/types";
import { BaseError } from "viem";
import { ZodError, z } from "zod";
import { date, formatMarketDate } from "./date";

export async function wait(time:number){
  return new Promise<void>(res=>{
    setTimeout(()=>res(),time);
  });
}

export function breakCamelCaseWithSpace(str:string){
  return str.replace(/([A-Z]+)/g,' $1').replace(/^ /, '');
}

export function truncateErrorMessage(msg:string){
  return msg.length>200?`${msg.slice(0,200)}...`:msg;
}

export function isTokenStableCoin(token:Token){
  return ['USDT','USDC','DAI'].includes(token?.code||'');
}

export function getMarketSymbolFromMarketRate(marketRate:MarketPastRate,token:Token):MarketForSymbol{
  return {marketId:marketRate.marketId, maturityDate:marketRate.maturityDate, daysToMaturity:marketRate.daysToMaturity, token:token};
}
export function getMarketWsSymbolCode(market?:BaseMarket){
  if(!market){
    return undefined;
  }else{
    return market.instrumentId;
  }
}
/**
 * simple market symbol code; for rolling date label, use date/useRollingDateLabel
 * @returns `TOKEN_CODE DDMMMYY`
 */
export function getMarketSymbolCode(market:MarketForSymbol){
  return `${market.token.code}-${!market.maturityDate?'FLOAT':formatMarketDate(date(market.maturityDate))}`;
}

/**
 * 
 * @param levels Title text in the hierachy of `${level[0]} | ${level[1]} | ...`
 * @param trigger rerender trigger key (used for useEffect)
 * @returns 
 */
export function usePageTitle(levels:string[],trigger?:any[]){
  return useEffect(()=>{
    const tag = process.env.REACT_APP_TITLE_TAG||'';
    document.title = `${tag}${[...levels,'Infinity'].join(' | ')}`;
  },trigger||[]);
}

export function usePrevious<T>(value:T,initialValue?:T):T|undefined {
  const ref = useRef<T|undefined>(initialValue);
  useEffect(() => {
    ref.current = value;
  },[value]);
  return ref.current;
}

/**
 * 
 * @param arr 
 * @param key 
 * @param compareFuncOrDescending default: numeric ascending, supply `true` to use numeric descending, or custom compare function 
 * @returns 
 */
export function sortBy<T>(
  arr:Array<T>,
  key:keyof T,
  compareFuncOrDescending?:boolean|((a:T,b:T)=>number),
){
  const compareFunc = typeof compareFuncOrDescending === 'function' ? compareFuncOrDescending : 
  (compareFuncOrDescending ? 
    // numeric descending 
    (a:T,b:T)=>a[key]>b[key]?-1:a[key]<b[key]?1:0 :
    // numeric ascending 
    (a:T,b:T)=>a[key]>b[key]?1:a[key]<b[key]?-1:0
  );
  return arr.sort(compareFunc);
}
export function unique<T>(arr:Array<T>){
  return arr.filter((value,index,self)=>self.indexOf(value)===index);
}
/**
 * 
 * @param arr original array of objects
 * @param key key of object, used as unique key
 * @returns unique array of objects, with the LAST duplicated item as the unique item
 */
export function uniqueByKey<T>(arr:Array<T>,key:keyof T){
  return [...new Map(arr.map(item=>[item[key],item])).values()];
}

export function hslStrToHex(hsl:string){
  const regex = /hsla?\((\d+),\s*([\d.]+)%,\s*([\d.]+)%(?:,\s*([\d.]+))?\)/g;
  const [h,s,l,a] = regex.exec(hsl)?.slice(1)||[];
  const n = Number;
  return hslToHex(n(h),n(s),n(l),a?n(a):undefined);
}
export function hslToHex(h:number, s:number, l:number, a?:number) {
  l /= 100;
  const aa = s * Math.min(l, 1 - l) / 100;
  const f = (n:number) => {
    const k = (n + h / 30) % 12;
    const color = l - aa * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  };
  const hexAlpha = a?(Math.round(255*a).toString(16)):'';
  // console.log(a,hexAlpha);
  return `#${f(0)}${f(8)}${f(4)}${hexAlpha}`;
}


/**
 * zod handling for schema alias
 * @param schema original schema 
 * @param aliasShapeMap 
 * @returns 
 */
export function zAliasShapeMap(schema:z.AnyZodObject,aliasShapeMap:{[key:string]:string}){
  const shapeMap = schema._def.shape();
  const schemaAlias = z.object(Object.keys(aliasShapeMap).reduce((ret,key)=>{
		const alias = aliasShapeMap[key];
		return {...ret,[alias]:shapeMap[key]};
	},{}))
  const schemaMerged = schema.merge(schemaAlias).partial();
  return schemaMerged.transform((object)=>{
    return Object.keys(shapeMap).reduce((ret,key)=>{
      const alias = aliasShapeMap[key];
      return {...ret,[key]:object[key]||object[String(alias)]};
    },{});
  });
}
export function zAliasShapeSchema(objectShapeMap:{[key:string]:(string|z.ZodTypeAny)[]},useAlias=false){
  return ;
}

type GeneralErrorType = 'api'|'general';
export class GeneralError extends Error {
  key: string;
  type: GeneralErrorType;
  message: string;
  constructor(params:{type?:GeneralErrorType,key?:string,message:string}|string){
    super();
    if(typeof params === 'string'){
      this.type = 'general'; 
      this.key = this.message = params;
    }else{
      const {type='general',key,message} = params;
      this.type = type;
      if(key!==undefined){
        this.key = key;
        this.message = `{${key}}${message}`;
      }else{
        this.key = this.message = message;
      }
    }
  }
}

export function parseError(err?:string|GeneralError|Error|ZodError|unknown){
  let key:string;
  let message:string = 'Unknown Error';
	if(!err){
    key = message;
  }else if(err instanceof GeneralError){
    ;({key,message} = err);
    // look up possible `{key}` label in message
    const keyFromMessage = (/(\{(?<key>.*)\})(?<message>.*)/g).exec(message);
    if(keyFromMessage){
      key = keyFromMessage.groups?.key||key;
      message = keyFromMessage.groups?.message||message;
    }
  }else if(err instanceof BaseError){ // viem BaseError 
    message = err.details;
    if(err.shortMessage.length<message.length) message = err.shortMessage;
    key = err.name;
  }else{
    if(typeof err=='string') message = err;
    if((err as ZodError).name==='ZodError') {
      message = `TypeError(${(err as ZodError).issues.length}): ${(err as ZodError).issues[0].code}`;
    }
    if((err as Error).message) message = (err as Error).message;
    key = message;
  }
  return {key,message};
}

const CLOSE_SNACKBAR_EVENT_KEY = 'CLOSE_SNACKBAR_EVENT_KEY';
export function subscribeCloseSnackbar(listener:EventListenerOrEventListenerObject) {
  document.addEventListener(CLOSE_SNACKBAR_EVENT_KEY, listener);
}
export function unsubscribeCloseSnackbar(listener:EventListenerOrEventListenerObject) {
  document.removeEventListener(CLOSE_SNACKBAR_EVENT_KEY, listener);
}
export function publishCloseSnackbar(key:string) {
  const event = new CustomEvent(CLOSE_SNACKBAR_EVENT_KEY,{detail:{key}});
  document.dispatchEvent(event);
}

/**
 * 
 * @returns clientOrderId string in `[A-Za-z0-9]{1,8}` format
 */
export function generateClientOrderId(){
  return (Date.now()).toString(16).slice(-8);
}

export const getChainIdInHex = (chainId:string|number):string=>{
  if(typeof chainId==='string') return chainId.replace(/^0x/,'');
  return chainId.toString(16);
};

export const formatWeb3Address = (address:string):`0x${string}`=>{
  if(address.indexOf('0x')===0) return address as `0x${string}`;
  return `0x${address}`;
}

/* web3 related */
export const getChainConfig = (chainId:number)=>{
  return (chainId && ETH_CHAIN_MAP.get(chainId));
}
export const getChainLabel = (chainId=CHAIN_ID)=>{
  return (chainId && ETH_CHAIN_MAP.get(chainId)?.label) ?? 'UNKNOWN';
}
export const getChainName = (chainId=CHAIN_ID)=>{
  return (chainId && ETH_CHAIN_MAP.get(chainId)?.name) ?? 'UNKNOWN';
}

export const getEtherscanBaseUrl = (chainId=CHAIN_ID)=>{
  return (chainId && ETH_CHAIN_MAP.get(chainId)?.blockExplorerUrl);
}

/* useEffectDebugger to track dependency changes */
export const useEffectDebugger = (effectHook:React.EffectCallback, dependencies?:React.DependencyList | undefined, dependencyNames:string[] = []) => {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps = dependencies?.reduce((accum:Object, dependency, index) => {
    if (dependency !== previousDeps?.at(index)) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps?.at(index),
          after: dependency
        }
      };
    }

    return accum;
  }, {} as Object)||{};

  if (Object.keys(changedDeps).length) {
    console.log('[use-effect-debugger] ', changedDeps);
  }

  useEffect(effectHook, dependencies);
};



/* simple gtag event wrapper */
export const setGAWalletAddress = (address?:string)=>{
  (window as any).gtag&&(window as any).gtag('set', 'user_properties', {'eth_wallet_address':address});
}
export const sendGA = (eventName:string,eventParameters:{[key:string]:string}={})=>{
  (window as any).gtag&&(window as any).gtag('event', eventName, eventParameters);
}