import { SxProps } from '@mui/system';
import { HEADER_HEIGHT } from 'src/Components/Header';
import { TESTNET_NOTIFICATION_HEIGHT } from 'src/constants/app';
import useAppStore from 'src/store';
import theme from 'src/utils/theme';

export const sxPageHeading:SxProps = {
  fontSize: '1.5rem',
  fontWeight: 700,
}
export const sxPageHeadingSmall:SxProps = {
  fontSize: '1.25rem',
  fontWeight: 700,
}
export const sxPageHeadingRow:SxProps = {
  display:'grid', gridTemplateColumns:'1fr auto', alignItems: 'center',
  marginBottom: '16px', "h1":{marginBottom:0},
  [theme.breakpoints.down('tablet')]:{
    display: 'block', marginBottom: '16px',
  },
}
export const sxPageHeadingRowAbove:SxProps = {
  marginBottom: '8px',
  position: 'absolute',
  right: '0px',
  bottom: '100%',
  py: '8px',
};
export const sxPageHeadingWrapper:SxProps = {
  display:'flex',alignItems:'center',columnGap:'8px',
}
export const sxPageSection:SxProps = {
  position: 'relative',
  my: '48px',
};
export const sxSubHeaderBase:SxProps = {
  position: 'sticky', zIndex: theme.zIndex.appBar, 
  display: 'flex', flexDirection: 'row', alignItems:'stretch',
  width:'100%', px: 2, bgcolor:'background.paper', 
  borderWidth: 0, borderStyle: 'solid', borderColor: 'border.dark', borderBottomWidth: '1px', 
};
export const useTestnetNotificationHeight = ()=>{
  const {showsTestnetNotification} = useAppStore();
  return `${((showsTestnetNotification && TESTNET_NOTIFICATION_HEIGHT) || 0)}px`;
}
export const useSxSubHeader = (overrides?:SxProps)=>{
  // TODO mobile layout 
  return {...sxSubHeaderBase,
    top: 0, minHeight:HEADER_HEIGHT, 
    [theme.breakpoints.down('tablet')]:{ 
    //   top:headerHeight
      flexDirection:'column',
    },
    ...(overrides||{})
  } as SxProps;
}
export const sxWithSideBar:SxProps = {
  display:'grid',
  gridTemplateColumns:'240px auto',
  minHeight:'100%',
  [theme.breakpoints.down('desktop')]:{
    gridTemplateColumns: `0 1fr`,
  },
};
export const sxSideBar:SxProps = {
  maxWidth:'240px',
  bgcolor:'table.dark',
  borderWidth:0,
  borderStyle:'solid',
  borderColor:'border.dark',
  borderRightWidth: '1px',
  "ul":{
    position:'sticky',
    top:0,
    p:'8px',
    m:0,
  },
  ".MuiButtonBase-root":{
    margin: '4px auto',
    padding: '4px',
    minHeight: '32px',
    borderRadius: '4px',
  },
  transition: 'width 0.5s ease',
  [theme.breakpoints.down('desktop')]:{
    overflow: "hidden",
    width: `0px`,
    borderRightWidth: '0px',
  },
};
export const sxContent:SxProps = {
  width:'100%',
  maxWidth:theme.breakpoints.values.laptop+64,
  mx: 'auto',
  p: '32px',
  [theme.breakpoints.down('tablet')]:{
    p: '16px',
  },
  'h1+h6':{
    marginTop:'-8px',
    marginBottom:'16px',
  }
};
export const sxContentWide:SxProps = {
  ...sxContent,
  maxWidth:theme.breakpoints.values.desktop+64,
};
export const sxContentWider:SxProps = {
  ...sxContent,
  maxWidth:theme.breakpoints.values.desktopWide+64,
};
export const sxContentFullHeight:SxProps = {
  ...sxContent,
  position: 'relative',
  height: '100%',
};


export const sxZoom25:SxProps = {
  zoom: 1.25,
}