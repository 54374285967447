
import {
  Box, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import Decimal from 'decimal.js';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Bar, CartesianGrid, Cell, ComposedChart, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CategoricalChartFunc as ICategoricalChartFuncProps } from 'recharts/types/chart/generateCategoricalChart';
import { useAuth } from 'src/AuthProvider';
import { GraphPositionContextType } from 'src/Components/MarketGrids/GraphPositionProvider';
import { FLOAT_SHORT } from 'src/constants/app';
import useAppStore from 'src/store';
import { graphAreaColors, sxChartPaddingWrapper, sxChartTooltip, sxChartTooltipLegendBox, sxChartTooltipLegendBoxTitle, sxChartTooltipTable, sxChartWrapper, sxChartWatermarkAboveCenter, sxChartWatermarkTopCenter, sxChartTooltipLegendLine } from 'src/styles/Chart';
import { hslStrToHex, sortBy, unique } from 'src/utils/common';
import { date, now, useCallbackGetDaysFromMarketToday, useCallbackRollingDateLabel, useRollingDateDays } from 'src/utils/date';
import { format, formatN, formatUSDWithSign } from 'src/utils/numbers';
import { Token, UserTokensGraphDateBucketType } from '../types';
import apis from '../utils/apis';
import theme from '../utils/theme';
import DefaultTokensSelector, { defaultTokens } from './DefaultTokensSelector';
import GraphEmptyStateCurtain from './GraphEmptyStateCurtain';
import LoadingIcon from './Loading';
import { TReloadDateTextRefs } from './ReloadDateText';
import ValueTableCell from './ValueTableCell';
import SidedQuantityValue from './SidedQuantityValue';

/**
 * 
 * @param dataKey data to be rendered
 * @param bucketData aggregate data inbetween standard dates as bucket
 * @returns 
 */
function UserTokensGraph({
  token,tokenCodes,graphKey,padGraph,bucketData,graphPosition, singleTokenGraph,showsUSDValue,dateBucketType='By Contract',
  reloadDateTextRefs,showsEmptyStateCurtain, hasMaxHeight = true, watermarkType = 'above-center', withLegend = false, minHeight
}:{
  token?:Token,tokenCodes?:string[],graphKey:'dv01'|'position'|'mtm'|'pv',padGraph?:boolean,bucketData?:boolean,graphPosition?:GraphPositionContextType, singleTokenGraph?: boolean,showsUSDValue?:boolean,dateBucketType?:UserTokensGraphDateBucketType,
  reloadDateTextRefs?:TReloadDateTextRefs,showsEmptyStateCurtain?:boolean, hasMaxHeight?: boolean, watermarkType?: string, minHeight?: number, withLegend?: boolean
}){
  const auth = useAuth();  
  const { xPosition, activeGraph, setActiveGraph, handleMousePosition } = graphPosition||{};
  const daysToMaturities = useRollingDateDays();
  const getRollingDateLabel = useCallbackRollingDateLabel();
  const getDaysFromMarketToday = useCallbackGetDaysFromMarketToday();

  const [selectedTokenCodes,setSelectedTokenCodes] = useState(defaultTokens);
  useEffect(()=>{
    setSelectedTokenCodes(tokenCodes || defaultTokens);
  },[tokenCodes]);
  const {isLoading:isLoadingTokens,data:tokens} = apis.token.useMarketsPageTokens();
  // user account info
  const { currentUserAccountId } = useAppStore();
  const { isFetching:isFetchingUserAccounts,data:userAccounts } = apis.user.useUserAccounts(auth);
  const tradeAccount = userAccounts&&apis.user.extractUserAccount(userAccounts,currentUserAccountId);
  const {isFetching:isFetchingMtm,isLoading:isLoadingMtms,data:mtms } = apis.user.useUserMarkToMarketByMarket(auth,{accountIds:userAccounts?.map(w=>w.accountId)});

  // checks if user has any order placed (for showing "start trading prompt")
  const {data:userAccountPerformanceLive, isFetching:isFetchingGroupedPositions} = apis.user.useUserAccountPerformanceLive(auth,{ accountId: tradeAccount?.accountId });
  const groupedPositions = useMemo(() => userAccountPerformanceLive?.positionDetails, [userAccountPerformanceLive])
  const hasPlacedOrders = useMemo(()=>{
    return (groupedPositions?.length||0)>=0;
  },[groupedPositions]);

  const isLoadingAny = isLoadingTokens||isFetchingUserAccounts||isFetchingGroupedPositions||isLoadingMtms;
  const isFetching = isFetchingGroupedPositions||isFetchingMtm;

  const singleTokenView = token!==undefined;

  // reload date text refs
  const queryClient = useQueryClient();
  useEffect(()=>{
    if(reloadDateTextRefs){ reloadDateTextRefs.isFetchingReloadRef.current = isFetching; }
  },[reloadDateTextRefs,isFetching]);
  useEffect(()=>{
    if(reloadDateTextRefs){ reloadDateTextRefs.lastUpdatedTimeRef.current = moment(); }
  },[reloadDateTextRefs,groupedPositions,mtms]);
  if(reloadDateTextRefs){
    reloadDateTextRefs.handleClickReloadRef.current = ()=>{ 
      queryClient.invalidateQueries(['user',{type:'positions',action:'list'}]); 
      queryClient.invalidateQueries(['user',{type:'mtm',action:'listByMarket'}]); 
    };
  }

  // token color mapping to keep them consistent through filter
  const tokenColors = useMemo(()=>{
    const tokenColors = new Map<string,string>();
    tokens?.forEach((token,i)=>{
      const fillColor = graphAreaColors[i%graphAreaColors.length];
      tokenColors.set(token.code,fillColor);
    })
    return tokenColors;
  },[tokens]);

  // token filter
  const tokenFilter = useCallback((t:Token)=>{
    // console.log('tokenFilter',selectedTokenCodes,t.code)
    if(selectedTokenCodes&&!selectedTokenCodes.includes(t.code)) return false;
    if(singleTokenView&&t.tokenId!==token.tokenId) return false;
    return true;
  },[selectedTokenCodes,singleTokenView,token?.tokenId]);

  // generate rechart data
  // type TData = {i:number;name:string;[code:string]:number|undefined;};
  const {cutoffDays,data,dataMax,isEmpty} = useMemo(()=>{
    // generate cutoff days according to dateBucketType
    let cutoffDays:number[];
    switch(dateBucketType){
      case 'Monthly':
        cutoffDays = (new Array(6).fill(0)).map((_,i)=>now().add(i+1,'month').diff(now(),'day'));
        break;
      case 'Quarterly':
        cutoffDays = (new Array(6).fill(0)).map((_,i)=>now().add(i+3,'month').diff(now(),'day'));
        break;
      case 'Semi-Annually':
        cutoffDays = (new Array(4).fill(0)).map((_,i)=>now().add(i+6,'month').diff(now(),'day'));
        break;
      case 'Annually':
        cutoffDays = (new Array(3).fill(0)).map((_,i)=>now().add(i+12,'month').diff(now(),'day'));
        break;
      case 'By Contract': default:
        cutoffDays = unique([0,...daysToMaturities]);
        break;
    }
    // console.log('bucketKeys',bucketKeys);
    // create buckets array
    const data:any[] = cutoffDays.map((day,i)=>({
      dayIndex:i, d:0, name:day===0?'FLOAT':getRollingDateLabel(day),
    }));
    // console.log('data.length',data.length);
    // const tokenValueMap:{[code:string]:{dv01?:Decimal,position?:Decimal}} = {};
    // fill in elements
    let valueMax = new Decimal(0);
    let isEmpty = true;
    // console.log('valueMax',valueMax);
    const mtmMarketMap = mtms?.reduce((map,mtmObj)=>{
      const { instrumentId, mtm:_mtm } = mtmObj;
      const mtm = new Decimal(map.get(instrumentId)||0).add(new Decimal(_mtm||0));
      map.set(instrumentId,mtm);
      return map;
    },new Map<string,Decimal>());
    tokens&&groupedPositions?.forEach(gps=>{
      gps.positions.forEach(d=>{
        let i; let cutoff = 0;
        const t = tokens.find(t=>t.tokenId===d.tokenId);
        if(!t) return;
        if(!tokenFilter(t)) return;
        const days = d.maturityDate===undefined?0:getDaysFromMarketToday(date(d.maturityDate));
        for(i=0;i<cutoffDays.length;i++){
          if(cutoff>=days) break;
          cutoff = cutoffDays[i];
        }
        if(!bucketData&&days!==cutoff) return;
        const dataIdx = days===0?0:i-1; // i-1 for previous matching key
        // console.log('iterate',i,dataIdx,days,t.code,d.position);
        if(!data[dataIdx]) return;
        const dv01 = new Decimal(d.dv01||0);
        const mtm = new Decimal(mtmMarketMap.get(d.instrumentId)||0).mul(showsUSDValue?(t.price):1);
        const position = new Decimal(d.quantity||0).mul(showsUSDValue?(t.price):1);
        const pv = position.add(mtm); // already in token price 
        const value = new Decimal(data[dataIdx][t.code]||0);
        switch(graphKey){
          case 'dv01': data[dataIdx][t.code] = dv01.add(value).toNumber(); break;
          case 'mtm': data[dataIdx][t.code] = mtm.add(value).toNumber(); break;
          case 'pv': data[dataIdx][t.code] = pv.add(value).toNumber(); break;
          case 'position': default: data[dataIdx][t.code] = position.add(value).toNumber(); break;
        }
        valueMax = Decimal.max(valueMax,Math.abs(data[dataIdx][t.code]));
        isEmpty = false;
      });
    });
    const dataMax = valueMax.mul(1.2).toNumber();
    return {cutoffDays,data,dataMax,isEmpty};
  },[daysToMaturities,dateBucketType,bucketData,graphKey,getDaysFromMarketToday,mtms,groupedPositions,tokens,getRollingDateLabel,showsUSDValue,tokenFilter]);

  function ChartLegend(props:any){
    const { /* chartHeight, */ chartWidth, height, /* width, */ iconSize, payload } = props;
    if(payload.length<=1) return null;
    return <div className="recharts-legend-wrapper" style={{
      position: 'absolute', width: chartWidth, height: height, right: 0, top: 0, transform: `translateY(calc(-100% - ${iconSize/2}px))`, 
    }}>
      <ul className="recharts-default-legend" style={{
        padding: '0px', margin: '0px', textAlign: 'right',
      }}>
        {payload.map((p:any,i:number)=>{
          const [code] = p.dataKey.split('-');
          const color = tokenColors.get(p.dataKey);
          return <li key={i} className="recharts-legend-item legend-item-0" style={{
            display: 'inline-block', marginRight: '10px', color: color,
          }}>
            <Box sx={sxChartTooltipLegendLine} style={{borderColor: color}}></Box> 
            {code}
          </li>;
        })}
      </ul>
    </div>;
  }

  function ChartTooltip({active,payload,label, singleTokenGraph}:any){
    if (active && payload && payload.length) {

      return (
        <Box sx={sxChartTooltip}>
          <Box sx={sxChartTooltipLegendBoxTitle}>{`${payload[0].payload.name}`}</Box>
          <Table sx={sxChartTooltipTable}>
            <TableBody>
            {payload.map((p:any,i:number)=> {
              let value:string|JSX.Element;
              switch(graphKey){
                case 'dv01': 
                  value = <SidedQuantityValue value={p.value} dp={10}/>; 
                  break;
                case 'mtm': case 'position': default: 
                  if(showsUSDValue){
                    value = <SidedQuantityValue value={p.value} prefix='$' dp={0}/>; 
                  }else{
                    value = <SidedQuantityValue value={p.value} dp={8}/>;
                  }
                break;
              }
              const fillColor = tokenColors.get(p.dataKey);
              return (
                <TableRow key={i}>
                  <TableCell><Box sx={sxChartTooltipLegendBox} style={{backgroundColor:fillColor}}></Box><b>{(!singleTokenGraph && p?.name) ? p.name : 'Size'}</b></TableCell>
                  <ValueTableCell mono align='right'>{value}</ValueTableCell>
                </TableRow>
              )}
            )}
            </TableBody>
          </Table>
        </Box>
      );
    }
    return null;
  }

  const handleMouseMove: ICategoricalChartFuncProps = useCallback(
    (e) => {
      if (e === null) {
        handleMousePosition&&handleMousePosition(null)
      } else {
        handleMousePosition&&handleMousePosition(e.activeLabel)
      }
    }, [handleMousePosition]
  );

  // If we remove and add back the the reference line every time when we leave or enter to the graph area then the bars would be rerendered with the rendering animation.
  // To avoid this effect we keep the reference line and just make it hidden when we don't want to show it
  const referenceLineVisibility = useMemo(
    () => {
      return (xPosition!== null && activeGraph !== graphKey && activeGraph !== null) ? 'visible' : 'hidden';
    }, [xPosition, activeGraph, graphKey]
  )

  const sxContainerStyle = {
    ...(padGraph ? sxChartPaddingWrapper : sxChartWrapper),
    ...(watermarkType === 'above-center' ? sxChartWatermarkAboveCenter : sxChartWatermarkTopCenter)
  }

  return (<>
    {(!singleTokenView&&tokenCodes===undefined)&&<DefaultTokensSelector onChange={setSelectedTokenCodes}/>}
    <Box sx={sxContainerStyle} onMouseEnter={setActiveGraph&&(() => setActiveGraph(graphKey))} onMouseLeave={setActiveGraph&&handleMousePosition&&(() => { setActiveGraph(null); handleMousePosition(null) })}>
      <ResponsiveContainer width="100%" maxHeight={hasMaxHeight ? 300 : undefined} minHeight={minHeight}>
        <ComposedChart data={data} barGap={0} onMouseMove={handleMouseMove} >
          <ReferenceLine y={0} stroke={`${hslStrToHex(theme.palette.text.primary)}88`} />
          <ReferenceLine x={xPosition !== null ? xPosition : 0} strokeDasharray='3 3' stroke={`${hslStrToHex(theme.palette.text.primary)}88`} visibility={referenceLineVisibility}/>
          <YAxis fontSize={"0.625rem"} dataKey={'value'} scale="linear" domain={[-dataMax,dataMax]} tickCount={8} tickLine={false} tickFormatter={(v,i)=>`${graphKey==='dv01'?format(v,2):formatN(v,2)}`} width={40} stroke={`${hslStrToHex(theme.palette.text.primary)}88`}/>
          <XAxis fontSize={"0.625rem"} dataKey={'dayIndex'} domain={['dataMin','dataMax']} tickLine={false} tickFormatter={(v,i)=>{
            switch(dateBucketType){
              case 'Monthly': return `${v+1}M`;
              case 'Quarterly': return `${v>=4?'1Y':''}Q${v%4+1}`;
              case 'Semi-Annually': return `${v>=2?'1Y':''}${v%2+1}H`;
              case 'Annually': return `${v+1}Y`;
              case 'By Contract': default:
                return `${v===0?FLOAT_SHORT:getRollingDateLabel(cutoffDays[v])}`;
            }
          }} stroke={`${hslStrToHex(theme.palette.text.primary)}88`}/>
          {tokens?.filter(tokenFilter).map((t,i)=>{
            const fillColor = tokenColors.get(t.code);
            return (
              <Bar type="monotone" dataKey={t.code} key={i}>
                {data.map((c,i)=>(
                  <Cell key={i} fill={fillColor}/>
                ))}
              </Bar>
            );
          })}
          <CartesianGrid vertical={false} stroke={`${hslStrToHex(theme.palette.text.primary)}22`} />
          <Tooltip content={<ChartTooltip singleTokenGraph={singleTokenGraph}/>} cursor={{strokeDasharray: '3 3', stroke: `${hslStrToHex(theme.palette.text.primary)}88`}}/>
          {withLegend && <Legend verticalAlign='top' align='right' content={<ChartLegend/>}/>}
        </ComposedChart>
      </ResponsiveContainer>
      {isEmpty&&showsEmptyStateCurtain&&<GraphEmptyStateCurtain showsStartTrading={!hasPlacedOrders} extraText={!hasPlacedOrders?undefined:'No Orders'}/>}
      {(isLoadingAny&&!groupedPositions)&&<LoadingIcon curtain/>}
    </Box>
  </>);
}

export default UserTokensGraph;