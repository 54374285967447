import { Box, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'src/AuthProvider';
import MarketFixedRateInfoRow from 'src/Components/MarketFixedRateInfoRow';
import MarketSwitcher from 'src/Components/MarketSwitcher';
import MarketTradeForm from 'src/Components/MarketTradeForm';
import withConnectWallet from 'src/Components/WithConnectWallet';
import { MARKETS_PAGE_TOKENS } from 'src/constants/app';
import { sxBox, sxBoxCompactInner, sxBoxTitle, sxRow, sxTitleFloatRight } from 'src/styles/General';
import { sxContentWide, sxZoom25 } from 'src/styles/Page';
import { EUserAccountType } from 'src/types';
import { usePageTitle } from 'src/utils/common';
import { format } from 'src/utils/numbers';
import { paths } from 'src/utils/paths';
import apis from '../utils/apis';

const MarketSwitcherWithConnectWallet = withConnectWallet(MarketSwitcher);

function MarketTrade(){
  const navigate = useNavigate();
  const { tokenCode } = useParams();
  usePageTitle([tokenCode||'','Lend & Borrow'],[tokenCode||'']);
  const auth = useAuth();
  const {isLoading:isLoadingTokens,data:tokens} = apis.token.useTokens();
  // use default first token if not supplied
  const token = (tokens||[]).filter(token=>token.code===(tokenCode||MARKETS_PAGE_TOKENS[0]))[0]||(tokens||[])[0];
  const tokenId = token?.tokenId;
  const {data:spotMarket} = apis.rate.useMarket({tokenId});
  const {isLoading:isLoadingMarketsFixedRate,data:marketsFixedRate} = apis.fixedrate.useFixedRateMarkets({tokenId:tokenId});
  // get user positions 
  const {data:userAccounts} = apis.user.useUserAccounts(auth);
  const tradingAccount = userAccounts?.filter(uw=>uw.type===EUserAccountType.TRADING)[0];
  const { accountId } = tradingAccount||{};
  const {data:userAccountPerformanceLive} = apis.user.useUserAccountPerformanceLive(auth,{ accountId });
  const groupedPositions = useMemo(() => userAccountPerformanceLive?.positionDetails, [userAccountPerformanceLive])
  const positionSum = groupedPositions?.filter(gps=>gps.tokenId===tokenId).reduce((sum,p)=>{return sum.add(new Decimal(p.quantity||0))},new Decimal(0));
  const position = positionSum?format(positionSum,0):'-';
  const [selectedMarketId,setSelectedMarketId] = useState<number|undefined>();
  // handle path
  const redirectPath = useCallback(()=>navigate(paths.lendAndBorrowMarket(token),{replace:true}),[navigate,token]); // update path if redirected
  useEffect(()=>token&&redirectPath(),[tokenCode,token,redirectPath]);
  useEffect(()=>{
    if(!tokenCode&&token) redirectPath();
    if(!token&&!marketsFixedRate&&!isLoadingMarketsFixedRate&&!isLoadingTokens){
      // no match & not loading
      navigate(paths.lendAndBorrow(),{replace:true});
    }
  },[tokenCode,token,marketsFixedRate,isLoadingMarketsFixedRate,isLoadingTokens,navigate,redirectPath]);
  useEffect(()=>{
    // initial selectedMarketId for MarketTradeForm marketId
    if(spotMarket&&selectedMarketId===undefined) setSelectedMarketId(spotMarket.marketId)
  },[spotMarket,selectedMarketId]);
  const onChangedSelectedMarketId = useCallback((selectedMarketId:number)=>{
    setSelectedMarketId(selectedMarketId);
  },[setSelectedMarketId]);
  if(!token&&!marketsFixedRate&&!isLoadingMarketsFixedRate&&!isLoadingTokens){
    // no match & not loading
    return null;
  }

  return (
    <Box sx={sxContentWide}>
      <Typography variant="h1">Lend & Borrow</Typography>
      <Box sx={{...sxZoom25, position: 'relative'}}>
        <Box sx={sxBox}>
          <MarketFixedRateInfoRow token={token} tokenSelect="lendBorrow"/>
        </Box>
        <Box sx={sxRow}>
          <Box sx={sxBox}>
            <Box sx={sxBoxTitle}>My Positions<Box sx={sxTitleFloatRight}>Net Amounts: {position}</Box></Box>
            <Box sx={sxBoxCompactInner}>
              <MarketSwitcherWithConnectWallet token={token} layoutStyle="table" columnType="user" onChangedSelectedMarketId={onChangedSelectedMarketId}/>
            </Box>
          </Box>
          <Box sx={sxBox}>
            <Box sx={sxBoxTitle}>Order Form for {token?.code ?? ''}</Box>
            <Box sx={sxBoxCompactInner} style={{padding:'32px 0'}}>
              <MarketTradeForm token={token} marketId={selectedMarketId} marketOrdersOnly hideMaturityOptions/>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default MarketTrade;