import { Box, Button, Link, SxProps } from '@mui/material';
import { useEffect } from 'react';
import useSnackbar from 'src/hooks/useSnackbar';
import { styleDialogButtonOutlined } from 'src/styles/Dialog';
import { uniqueByKey, usePrevious } from 'src/utils/common';
import { useAuth } from '../AuthProvider';
import WalletIcon from '../Components/WalletIcon';
import LoadingIcon from './Loading';
import StandardDialog from './StandardDialog';

const sxWalletButtonInner:SxProps = {
  width:'100%', textAlign:'left', fontSize: '1rem', p: '16px',
};

function LoginDialog({showing,handleClose}:{showing:boolean,handleClose:Function}){
  const auth = useAuth();
  const snackbar = useSnackbar();
  const { connectors, error, isLoading, pendingConnector } = auth.wallet;
  const lastError = usePrevious(error);

  useEffect(()=>{
    if(!snackbar) return;
    if(error&&error!==lastError) snackbar.error(error);
  },[snackbar,error,lastError]);
  
  async function handleClickConnect(event: React.MouseEvent) {
    const connector = connectors.find(connector=>connector.id===event.currentTarget.getAttribute('data-wallet-id'));
    if(connector){
      if(connector.ready){
        const connected = await auth.signin({connector});
        if(connected){
          _handleClose();
        }
      }else{
        // uisng map here for future expansion
        const walletLink = {
          "metaMask": "https://metamask.io/download/",
          /* coinbase will fallback to web UI */
        }[connector.id]||undefined;
        snackbar.queue({
          message: 'Wallet not install.',
          type:'warning',
          autoHide: true,
          action: walletLink&&<Button component={Link} href={walletLink} target="_blank" variant="contained" color="warning">Get MetaMask</Button>
        });
      }
    }
  }
  function _handleClose(){
    auth.mayCancelSignin();
    handleClose();
  }

  return (
    <StandardDialog 
      title={`Connect a Wallet`}
      handleClose={()=>_handleClose()} open={showing}
      content={<>
        {uniqueByKey(connectors,"name")./* filter(connector=>connector.ready). */map(connector=>(
          <Button key={connector.id} sx={styleDialogButtonOutlined} variant={"outlined"} onClick={handleClickConnect} data-wallet-id={connector.id} /* disabled={!connector.ready} */>
            <Box sx={sxWalletButtonInner}><WalletIcon wallet={connector.name}/>{connector.name}</Box>
            {/* {!connector.ready && ' (Not Installed)'} */}
            {isLoading && connector?.id === pendingConnector?.id && <LoadingIcon/>}
          </Button>
        ))}
        {auth.isLoggingIn && <LoadingIcon curtain/>}
      </>}
    />
  );
}


export default LoginDialog;