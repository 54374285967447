import { Box, Link, SxProps } from '@mui/material';
import { useMemo } from 'react';
import { sxContentWide } from 'src/styles/Page';
import { useParams } from 'react-router-dom';
import apis from 'src/utils/apis';

import { paths } from 'src/utils/paths';
import { useCallbackRollingDateLabel } from "src/utils/date";


const sxContainer:SxProps = {
    ...sxContentWide,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
}

const sxText: SxProps = {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: '700',
    marginBottom: '1rem'
}

function MarketUnknown () {
    const { tokenId } = useParams();
    const {isFetching:isFetchingMarkets,data:markets} = apis.rate.useMarkets();
    const getRollingDateLabel = useCallbackRollingDateLabel();
    const tradePage = paths.useCallbackTradePage();
    const market: any = useMemo(() => {
        if(!isFetchingMarkets && markets){
            if (!tokenId) {
                return markets.at(0)
            } else {
                return markets.find(m => m.tokenId === Number(tokenId)) ?? markets.at(0)
            }
        } else {
            return
        }
    }, [markets, tokenId, isFetchingMarkets])
    return (
        <>
        {market && (<Box sx={sxContainer}>
            <Box sx={sxText} component={'div'}> This market has expired</Box>
            <Box sx={{ fontSize: '0.875rem' }} component={'div'}> Visit the <Link sx={{ color: 'primary.light'}} href={tradePage(market)}>{`${market.token.code}-${getRollingDateLabel(market)}`}</Link> market page</Box>
        </Box>)}
        </>
    )
}

export default MarketUnknown