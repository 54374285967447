import { useCallback } from "react";
import BaseTokenSwitcher from "src/Components/Common/BaseTokenSwitcher";
import { useSwapParameterStore } from 'src/store/useSwapParametersStore';
import { ESwapLegType } from "src/types";

const SwapTokenSwitcher = ({
    legKey, 
}:{
    legKey: string
}) => {
    const tokens = useSwapParameterStore((state) => {
        if (legKey === ESwapLegType.PRIMARY) {
            return state.tokens
        } else {
            const leg1TokenId = state.legs.leg1?.tokenId
            return state.tokens.filter(t => t.tokenId !== leg1TokenId)
        }
    })
    const selectedTokenId = useSwapParameterStore((state) => state.legs?.[legKey]?.tokenId)
    const setLegToken = useSwapParameterStore((state) => state.setLegToken)
    const onChange = useCallback(
        (newTokenId: number) => {
            setLegToken(legKey, newTokenId)
        }, [setLegToken, legKey]
    )

    return (
        <BaseTokenSwitcher onSelectionChange={onChange} selectedTokenId={selectedTokenId} tokens={tokens}/>
    )
}

export default SwapTokenSwitcher; 