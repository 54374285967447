import { Box, Button, Dialog, DialogTitle, Link, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Decimal from 'decimal.js';
import { isEmpty } from 'lodash';
import Lottie from 'lottie-react';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { useAuth } from 'src/AuthProvider';
import errorAnimationData from 'src/Components/Icons/animated/error.json';
import successAnimationData from 'src/Components/Icons/animated/success.json';
import warningAnimationData from 'src/Components/Icons/animated/warning.json';
import LoadingIcon from 'src/Components/Loading';
import { useMarketRollingDateLabel } from 'src/Components/MarketRollingDateLabel';
import StandardDialog from 'src/Components/StandardDialog';
import TokenIcon from 'src/Components/TokenIcon';
import { DISCORD_SUPPORT_LINK } from 'src/constants/app';
import useSnackbar from 'src/hooks/useSnackbar';
import {sxActionButtonFirst,
  sxConfirmDialogActionButtonContainer,
  sxConfirmationDialog,
  sxDialog,
  sxDialogCell, sxDialogCellValue,
  sxDialogPanel,
  sxDialogTitle,
  sxErrorStatusMain,
  sxOrderStatusDetails,
  sxOrderStatusErrorTitle,
  sxOrderStatusSuccessTitle,
  sxStatusTitleLabel,
  sxSummaryDetailsText,
  sxSupport,
} from 'src/styles/Dialog';
import { styleFormButton } from 'src/styles/Form';
import {
  EOrderCancelDialogStatus,
  EOrderSide,
  ERateOrderType,
  CancelledOrderResponseItem,
  TradeHistoryItem,
  EMarketType
} from 'src/types';
import apis from 'src/utils/apis';
import useAppStore from '../store';
import { sendGA } from 'src/utils/common';
import { formatStartOfDayMarketDate } from 'src/utils/date';
import { dps, format, formatDateTime } from 'src/utils/numbers';
import { toPercentage } from 'src/utils/string';
import { sxDate } from '../styles/TokenTable';

const successDefaultOptions = {
  loop: false,
  autoplay: true,
  animationData: successAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const warningDefaultOptions = {
  loop: false,
  autoplay: true,
  animationData: warningAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const errorDefaultOptions = {
  loop: false,
  autoplay: true,
  animationData: errorAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

/**
 * Dialogs
 */
const SingleOrderConfirmationDialog = (
  {
    isOpen,
    order,
    handleConfirm,
    handleClose
  }: {
    isOpen: boolean,
    order?: TradeHistoryItem,
    handleConfirm: () => void,
    handleClose: () => void
  }) => {
    //@ts-ignore
    const { side, maturityDate, rate, orderType, orderDate, quantity } = order
    const isFixedRate = !!maturityDate
    const {data:marketsFloatRate} = apis.rate.useRateMarkets();
    const {data:marketsFixedRate} = apis.fixedrate.useFixedRateMarkets({ tokenId: order?.tokenId });
    const {data:tokens} = apis.token.useTokens();
    const token = tokens?.find(token => token.tokenId === order?.tokenId);
    const market = isFixedRate ? marketsFixedRate?.find(m => m.marketId === order?.marketId) : marketsFloatRate?.find(m => m.marketId === order?.marketId)
    const dateLabel = useMarketRollingDateLabel({ market })
    if (!order) {
      handleClose()
      return (<></>)
    }

    const marketLabel = `${dateLabel} ${!maturityDate ? '-' : formatStartOfDayMarketDate(maturityDate)}`;
    const marketTypeLabel = isFixedRate ? marketLabel : 'FLOAT' 
    const QuantityStepDecimals = dps(new Decimal(market?.quantityStep||0).toNumber());
    const summaryRate = `${!rate ? '-' : toPercentage(rate)}%`;
    const content = (<>
      <Box sx={{ my: '40px', mx: '28px', fontSize: '1rem', fontFamily: 'Inter', lineHeight: '1.5rem'}}>
        Are you sure you would like to cancel this order?
      </Box>
      <TableContainer sx={{ padding: '10px 28px 32px 28px' }} component={Box}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{...sxDialogCell, color: 'text.disabled', lineHeight: '2rem'}}>
                ORDER DETAILS
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              <TableRow sx={{ height: '4rem'}}>
                <TableCell sx={{...sxDialogCell, borderTopWidth: '1px' }}><Box>Direction</Box></TableCell>
                <TableCell sx={{...sxDialogCellValue, borderTopWidth: '1px' }}><Box>{side === EOrderSide.LEND ? 'Lend' : 'Borrow'}</Box></TableCell>
              </TableRow>
              <TableRow sx={{ height: '4rem'}}>
                <TableCell sx={{...sxDialogCell }}><Box>Token</Box></TableCell>
                <TableCell sx={{...sxDialogCellValue, borderTopWidth: '1px' }}><Box><TokenIcon token={token} withCode={true} size={20}/></Box></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={sxDialogCell}><Box>Size</Box></TableCell>
                <TableCell sx={sxDialogCellValue}><Box>{format(quantity||0, QuantityStepDecimals)}</Box></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={sxDialogCell}><Box>Maturity</Box></TableCell>
                <TableCell sx={sxDialogCellValue}><Box sx={{ opacity: '0.5'}}>{marketTypeLabel}</Box></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={sxDialogCell}><Box>Rate</Box></TableCell>
                <TableCell sx={sxDialogCellValue}><Box>{summaryRate}</Box></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={sxDialogCell}><Box>Order Type</Box></TableCell>
                <TableCell sx={sxDialogCellValue}><Box>{orderType === ERateOrderType.MARKET ? 'Market' : 'Limit'}</Box></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={sxDialogCell}><Box>Date</Box></TableCell>
                <TableCell sx={sxDialogCellValue}><Box sx={{...sxDate, fontFamily: 'Inter' }}>{formatDateTime(orderDate)}</Box></TableCell>
              </TableRow>
            </>
          </TableBody>
        </Table>
      </TableContainer>
  </>)
  const actions = (
    <Box sx={sxConfirmDialogActionButtonContainer}>
      <Button variant="contained" sx={{ ...styleFormButton, ...sxActionButtonFirst } as SxProps} onClick={handleConfirm}>Confirm</Button>
    </Box>)
  return (<StandardDialog sxCustomDialog={sxConfirmationDialog} open={isOpen}
    handleClose={handleClose}
    title="Cancel Order"
    content={content}
    actions={actions}
  />)
}

const MultipleOrdersConfirmationDialog = ({ isOpen, ordersCount, handleConfirm, handleClose }: { isOpen: boolean, ordersCount: number, handleConfirm: () => void, handleClose: () => void  }) => {
  return <StandardDialog
    sxCustomDialog={sxConfirmationDialog}
    handleClose={handleClose}
    title="Cancel Orders"
    open={isOpen}
    content={ (<Box>
      <Box sx={{ margin: '40px 28px', fontSize: '1rem', fontFamily: 'Inter', lineHeight: '1.5rem'}}>
        {`Are you sure you would like to cancel these ${ordersCount} selected orders?`}
      </Box>
      <Box sx={{...sxSupport, mx: '26px', marginBottom: '4px', fontSize: '0.875rem', lineHeight: '1.25rem', textAlign: 'left' }}>
        Note: Some orders may not be cancelled as their status may have changed at the time of submitting this instruction request.
      </Box>
    </Box>) }
    actions={ <Button variant="contained" sx={{ ...styleFormButton, ...sxActionButtonFirst } as SxProps} onClick={handleConfirm}>Confirm</Button> }
  />
}

const InProgressDialog = ({ isOpen }: { isOpen: boolean }) => {
  return <Dialog sx={{...sxDialog, ...sxConfirmationDialog}} open={isOpen}>
    <DialogTitle sx={sxDialogTitle}>
      Cancel Orders
    </DialogTitle>
    <Box sx={{...sxDialogPanel, display: 'flex', justifyContent: 'center', alignItems: 'center', py: '90px'}}>
      <LoadingIcon size={48}/>
      <Box sx={{lineHeight: '1.8rem', fontSize: '1.1rem', paddingTop: '5px'}}>Processing...</Box>
    </Box>
  </Dialog>
}

const SummaryDialog = ({ isOpen, lastResult, handleClose }: { isOpen: boolean, lastResult?: CancelledOrderResponseItem[], handleClose: () => void}) => {
  const successfulCancels = (lastResult ?? []).filter(r => r.code === 0)
  return (
    <StandardDialog
      sxCustomDialog={sxConfirmationDialog}
      open={isOpen}
      content={ (<Box sx={{ marginBottom: '16px'}}>
          <Box sx={sxOrderStatusSuccessTitle}>
            <Lottie {...successDefaultOptions} style={{height:55,width:55,margin:'0.5rem 1rem'}}/>
            <Box component={'span'} sx={sxStatusTitleLabel}>{`Order${(lastResult && lastResult?.length > 1)? 's' : ''} Cancelled!`}</Box>
          </Box>
          {lastResult && lastResult?.length > 1 && successfulCancels.length > 0 && <Box sx={sxOrderStatusDetails}>
            {`${successfulCancels.length} of ${lastResult?.length ?? 0} of your selected orders have been successfully cancelled`}
          </Box>}
          {lastResult && lastResult?.length === 1 && successfulCancels.length > 0 && <Box sx={sxOrderStatusDetails}>
            Your order was succssfully cancelled!
          </Box>}
          {successfulCancels.length !== (lastResult?.length ?? 0) && <Box sx={{...sxSupport, mx: '16px', fontSize: '0.875rem', lineHeight: '1.25rem', textAlign: 'left' }}>
            Note: Some orders were not cancelled as their status may have changed at the time of submitting this instruction request.
          </Box>}
        </Box>) }
      actions={ <Button variant="contained" sx={{ ...styleFormButton, ...sxActionButtonFirst } as SxProps} onClick={handleClose}>Close</Button>}
    />
  );
}

const PartialSuccessDialog: any = ({ isOpen, lastResult, handleClose }: { isOpen: boolean, lastResult?: CancelledOrderResponseItem[], handleClose: () => void}) => {
  const successfulCancels = (lastResult ?? []).filter(r => r.code === 0)
  return (
    <StandardDialog
      sxCustomDialog={sxConfirmationDialog}
      open={isOpen}
      content={ (<Box sx={{ marginBottom: '16px'}}>
          <Box sx={sxOrderStatusSuccessTitle}>
            <Lottie {...warningDefaultOptions} style={{height:55,width:55,margin:'0.5rem 1rem'}}/>
            <Box component={'span'} sx={sxStatusTitleLabel}>Some Orders Cancelled!</Box>
          </Box>
          <Box sx={sxOrderStatusDetails}>
            {`${successfulCancels.length} of ${lastResult?.length ?? 0} of your selected orders have been successfully cancelled`}
          </Box>
          <Box sx={{...sxSupport, mx: '16px', fontSize: '0.875rem', lineHeight: '1.25rem', textAlign: 'left' }}>
            Note: Some orders were not cancelled as their status may have changed at the time of submitting this instruction request. Please refresh the page or try again.
          </Box>
        </Box>) }
      actions={ <Button variant="contained" sx={{ ...styleFormButton, ...sxActionButtonFirst } as SxProps} onClick={handleClose}>Close</Button>}
    />
  );
}

const NotProcessedDialog = ({ isOpen, lastResult, handleClose }: { isOpen: boolean, lastResult?: CancelledOrderResponseItem[], handleClose: () => void}) => {
  return (
    <StandardDialog
      sxCustomDialog={sxConfirmationDialog}
      open={isOpen}
      content={ (<Box sx={{ marginBottom: '16px'}}>
          <Box sx={sxOrderStatusSuccessTitle}>
            <Lottie {...warningDefaultOptions} style={{height:55,width:55,margin:'0.5rem 1rem'}}/>
            <Box component={'span'} sx={sxStatusTitleLabel}>Cancel Order Instruction<br /> Not Processed</Box>
          </Box>
          <Box sx={{...sxSupport, marginLeft: '16px', marginRight: '26px', fontSize: '0.875rem', lineHeight: '1.25rem', textAlign: 'left' }}>
            Note: Your cancel order instruction was not processed as the order status may have changed at the time of submitting this instruction request. Please refresh the page or try again.
          </Box>
        </Box>) }
      actions={ <Button variant="contained" sx={{ ...styleFormButton, ...sxActionButtonFirst } as SxProps} onClick={handleClose}>Close</Button>}
    />
  );
}

const DefaultErrorDialogContent = ({ version = 'single'}: { version?: string }) => {
  return (<Box sx={{ marginBottom: '36px'}}>
  <Box sx={{ ...sxOrderStatusErrorTitle, flexDirection: 'column' }}>
    <Lottie {...errorDefaultOptions} style={{height:55,width:55,margin:'0 1rem'}} />
    <Box component={'span'} sx={sxStatusTitleLabel}>{`Cancel Order${version === 'multiple' ? 's' : ''} Error`}</Box>
  </Box>
    <Box sx={sxErrorStatusMain}>
      <Box component={'div'} sx={sxSummaryDetailsText}>{`Your order${version === 'multiple' ? 's' : ''} haven’t been cancelled.`}</Box>
       <Box component={'div'} sx={sxSummaryDetailsText}>Reason: System Error</Box>
      <Box component={'div'} sx={sxSummaryDetailsText}><strong>Please try again.</strong></Box>
    </Box>
    <Box sx={sxSupport}>
      If you continue to experience problems, please reach out to our support team on <Link href={DISCORD_SUPPORT_LINK} color="inherit" target='_blank' rel='noopener'>Discord</Link> and we apologise for any inconvenience caused.
    </Box>
  </Box>);
}

const ErrorDialog = ({ isOpen, handleClose, version }: { isOpen: boolean, handleClose: () => void, version: string }) => {
  const content = (<DefaultErrorDialogContent version={version} />)
  
  const actions = (
    <Box sx={sxConfirmDialogActionButtonContainer}>
      <Button variant="contained" sx={{ ...styleFormButton, ...sxActionButtonFirst } as SxProps} onClick={handleClose}>Close</Button>
    </Box>)
  return (
    <StandardDialog
      open={isOpen}
      sxCustomDialog={sxConfirmationDialog}
      // title={'Error'}
      content={content}
      actions={actions}
    />
  );
}

const OrderCancellationDialog = forwardRef(function TradeConfirmationDialog({ onSuccess }: any, ref: any) {
    const { isFetching:isFetchingMarkets, data:markets } = apis.rate.useMarkets();
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [status, setStatus] = useState<EOrderCancelDialogStatus>(EOrderCancelDialogStatus.WAIT_FOR_CONFIRMATION)
    const [ordersMap, setOrdersMap] = useState<{[key: string]: number[]}>({})
    const [firstOrder, setFirstOrder] = useState<TradeHistoryItem | undefined>()
    const [orderCount, setOrderCount] = useState<number>(0)
    const [lastResult, setLastResult] = useState<CancelledOrderResponseItem[] | undefined>()
    // const [lastOrder, setLastOrder] = useState<BaseRateOrder| undefined>();
    const auth = useAuth();
    const { currentUserAccountId } = useAppStore();
    /**
     * api data
     */
    const { data:userAccounts } = apis.user.useUserAccounts(auth);
    const tradeAccount = userAccounts&&apis.user.extractUserAccount(userAccounts,currentUserAccountId);
    const accountId = useMemo(() => tradeAccount?.accountId ?? 0, [tradeAccount])
    const snackbar = useSnackbar();

    // const isPrimaryFixedRate = useMemo(() => !isUndefined(params?.primaryMarket?.daysToMaturity), [params])
    // const isSecondaryFixedRate = useMemo(() => !isUndefined(params?.secondaryMarket?.daysToMaturity), [params])

    useImperativeHandle(ref, () => {
        return {
          openDialog(orders: TradeHistoryItem[]) {
            sendGA('cancel_dialog',{'trade_type':`${orders.length > 1 ? 'multiple' : 'single'}`,'order_count': `${orders.length}` });
            setIsOpen(true)
            setStatus(EOrderCancelDialogStatus.WAIT_FOR_CONFIRMATION)
            let counter = 0
            setOrdersMap(orders.reduce((mappedOrders, order) => {
              counter++
              return {
                ...mappedOrders,
                [order.marketId]: [...(mappedOrders[order.marketId] ?? []), order.orderId]
              }
            }, { }))
            if (counter > 0) {
              setFirstOrder(orders[0])
            }
            setOrderCount(counter)
          }
        };
      }, []);
    
    const handleConfirm = useCallback(
      async () => {
        setStatus(EOrderCancelDialogStatus.ORDER_CANCEL_IN_PROGRESS)
        const gaTradeType = `${orderCount > 1 ? 'multiple' : 'single'}`
        if (orderCount === 0) {
          setStatus(EOrderCancelDialogStatus.ERROR)
          sendGA('cancel_error',{'trade_type': gaTradeType, 'order_count': `${orderCount}` });
          return
        }
        sendGA('cancel_confirm',{'trade_type': gaTradeType, 'order_count': `${orderCount}` });
        try {
          const promises = Object.keys(ordersMap).map(marketId => {
            const market = markets?.find(m => m.marketId === Number(marketId))
            if (!market) return
            return apis.rate.cancelBatchOrder(auth,{ orderIds: ordersMap[marketId], accountId, instrumentId: market?.instrumentId ?? '' })
          }).filter(o => !!o)
          const requestResults = await Promise.allSettled(promises)
          //@ts-ignore
          const lastResult: CancelledOrderResponseItem[] = requestResults.reduce((result: CancelledOrderResponseItem[], r: {status: string, value: CancelledOrderResponseItem[], reason?: string }) => {
            return r.status === "fulfilled" ? result.concat(r.value) : requestResults
          }, [] as CancelledOrderResponseItem[])

          const successfulCancels = (lastResult ?? []).filter(r => r.code === 0)
          setLastResult(lastResult)
          if (successfulCancels.length === lastResult.length) {
            setStatus(EOrderCancelDialogStatus.ORDER_CANCEL_SUCCESS)
            sendGA('cancel_success',{'trade_type':`${orderCount > 1 ? 'multiple' : 'single'}`,'order_count': `${orderCount}` });
          } else if (successfulCancels.length === 0) {
            setStatus(EOrderCancelDialogStatus.ORDER_CANCEL_NOT_PROCESSED)
            sendGA('cancel_not_processed',{'trade_type':`${orderCount > 1 ? 'multiple' : 'single'}`,'order_count': `${orderCount}` });
          } else {
            setStatus(EOrderCancelDialogStatus.ORDER_CANCEL_PARTIAL_SUCCESS)
            sendGA('cancel_partial_success',{'trade_type':`${orderCount > 1 ? 'multiple' : 'single'}`,'order_count': `${orderCount}` });
          }
          
          onSuccess && onSuccess()
        } catch (error) {
          setStatus(EOrderCancelDialogStatus.ERROR)
          sendGA('cancel_error',{'trade_type':`${orderCount > 1 ? 'multiple' : 'single'}`,'order_count': `${orderCount}` });
        }
      }, [ordersMap, orderCount, accountId, onSuccess, auth]
    );

    const handleClose = useCallback(
      () => {
        setIsOpen(false)
      }, [setIsOpen]
    );

    const getDialogByStatus =  useCallback(
      () => {
        switch (status) {
          case EOrderCancelDialogStatus.WAIT_FOR_CONFIRMATION: 
            return orderCount === 1 ?
              <SingleOrderConfirmationDialog handleClose={handleClose} isOpen={isOpen} order={firstOrder} handleConfirm={handleConfirm}/> : 
              <MultipleOrdersConfirmationDialog handleClose={handleClose} isOpen={isOpen} ordersCount={orderCount} handleConfirm={handleConfirm}/>
          case EOrderCancelDialogStatus.ORDER_CANCEL_IN_PROGRESS:
            return <InProgressDialog isOpen={isOpen} />
          case EOrderCancelDialogStatus.ORDER_CANCEL_SUCCESS:
            return <SummaryDialog isOpen={isOpen} handleClose={handleClose} lastResult={lastResult}/>
          case EOrderCancelDialogStatus.ORDER_CANCEL_PARTIAL_SUCCESS:
            return <PartialSuccessDialog isOpen={isOpen} handleClose={handleClose} lastResult={lastResult}/>
          case EOrderCancelDialogStatus.ORDER_CANCEL_NOT_PROCESSED:
            return <NotProcessedDialog isOpen={isOpen} handleClose={handleClose} lastResult={lastResult}/>
          case EOrderCancelDialogStatus.ERROR:
            return <ErrorDialog handleClose={handleClose} isOpen={isOpen} version={orderCount > 1 ? 'multiple' : 'single'}/>
          default:
            return <ErrorDialog handleClose={handleClose} isOpen={isOpen} version="single"/>

        }
      }, [status, firstOrder, orderCount, handleClose, handleConfirm, isOpen, lastResult]
    );

    return (
        <>
        {(isOpen && orderCount > 0) ? 
            getDialogByStatus() : null
        }
        </>
    );
  });

  export default OrderCancellationDialog