import { Box, Button, Table, TableBody, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import Decimal from 'decimal.js';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { sxTokenTable } from 'src/styles/TokenTable';
import { paths } from 'src/utils/paths';
import { sxBox, sxBoxCompactInner, sxBoxTitle, sxLabel, sxTitleFloatRight } from '../styles/General';
import { EOrderSide, Market } from '../types';
import apis from '../utils/apis';
import { format } from '../utils/numbers';
import LoadingIcon from './Loading';
import TokenIcon from './TokenIcon';
import ValueTableCell from './ValueTableCell';

interface MarketSortable extends Market {
	tokenCode:string, // for market table sorting
  action?:unknown, 
}

function LiquidityProviderTable(){
  const navigate = useNavigate();
  const {isLoading:isLoadingMarkets,error:errorMarkets,data:markets} = apis.rate.useRateMarkets();
  const marketsSortable:MarketSortable[] = markets?.map(m=>{
    return {...m,tokenCode:(m.token.code)};
  })||[];
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof MarketSortable>('tokenCode');
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof MarketSortable,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleClickRow = (event:React.MouseEvent<unknown>, market:Market, side:EOrderSide)=>{
    // console.log('click row', code);
    market&&navigate(paths.liquidityPage(market));// /${side==ERateLPPositionSide.LEND?'lend':'borrow'}`);
    event.nativeEvent.stopPropagation();
  }

  const marketCapUSD = marketsSortable?.reduce((sumUSD,market)=>{
    const marketValue = new Decimal(market.subscriptions);
    sumUSD+= marketValue.mul(new Decimal(market.token.price||0)).toNumber();
    return sumUSD;
  },0);
  return (
    <Box sx={sxBox}>
      <Box sx={sxBoxTitle}>Liquidity Providers
      <Box sx={sxTitleFloatRight}>Total <Box component="span" sx={{fontWeight:400}}>${format(marketCapUSD)}</Box></Box>
      </Box>
      <Box sx={sxBoxCompactInner}>
        <TableContainer component={Box}>
          <Table sx={sxTokenTable} aria-label="Markets" size="medium">
            <EnhancedTableHead 
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={(marketsSortable||[]).length}
            />
            <TableBody>
              {(marketsSortable||[]).sort(getComparator(order, orderBy))
                .map((market, index) => {
                  const usdPrice = new Decimal(market.token.price||0);
                  const fee = new Decimal(market.makerFeeRate).toString();
                  const feeUSD = new Decimal(market.makerFeeRate).mul(usdPrice).toString();
                  const refSize = new Decimal(market.deposits).add(new Decimal(market.borrows)).toString();
                  const refSizeUSD = new Decimal(market.deposits).add(new Decimal(market.borrows)).mul(usdPrice).toString();
                  const subSize = new Decimal(market.subscriptions).toString();
                  const subSizeUSD = new Decimal(market.subscriptions).mul(usdPrice).toString();
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClickRow(event, market, EOrderSide.LEND)}
                      sx={{cursor:'pointer'}}
                      role="checkbox"
                      tabIndex={-1}
                      key={market.instrumentId}
                    >
                      <ValueTableCell
                        // component="th"
                        scope="row"
                        padding="normal"
                        width="34%"
                        sticky={true}
                      >
                        <TokenIcon token={market.token} withCode={true} size={14}/>
                      </ValueTableCell>
                      <ValueTableCell mono label="Lend LP" width="22%"
                      onClick={(event:any) => handleClickRow(event, market, EOrderSide.LEND)} sx={{cursor:'pointer'}}>
                        {new Decimal(market.lpDeposit||0).mul(100).toFixed(2).toString()}%
                      </ValueTableCell>
                      <ValueTableCell mono label="Borrower LP" width="22%"
                      onClick={(event:any) => handleClickRow(event, market, EOrderSide.BORROW)} sx={{cursor:'pointer'}}>
                        {new Decimal(market.lpBorrower||0).mul(100).toFixed(2).toString()}%
                      </ValueTableCell>
                      <ValueTableCell mono label="Fees/Day" width="22%" align="left">
                        {format(fee)}<Box sx={sxLabel}>(${format(feeUSD)})</Box>
                      </ValueTableCell>
                      <ValueTableCell mono label="Reference Market" width="50%" align="left">
                        {format(refSize)}<Box sx={sxLabel}>(${format(refSizeUSD)})</Box>
                      </ValueTableCell>
                      <ValueTableCell mono label="Subscription Market" width="50%" >
                        {format(subSize)}<Box sx={sxLabel}>(${format(subSizeUSD)})</Box>
                      </ValueTableCell>
                      <ValueTableCell label="Action" width="50%" >
                        <Button component={Link} to={paths.liquidityPage(market)} variant="contained">Provide Liquidity</Button>
                      </ValueTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {isLoadingMarkets&&<LoadingIcon curtain/>}
        </TableContainer>
      </Box>
    </Box>
  );
}

interface HeadCell {
  disablePadding: boolean;
  textAlign: "left" | "right" | "inherit" | "center" | "justify" | undefined;
  id: keyof MarketSortable;
  label: string;
}
const headCells: readonly HeadCell[] = [
  {
    id: 'tokenCode',
    disablePadding: false,
    textAlign: 'left',
    label: 'TOKENS',
  },
  {
    id: 'lpDeposit',
    disablePadding: false,
    textAlign: 'left',
    label: 'LEND LP',
  },
  {
    id: 'lpBorrower',
    disablePadding: false,
    textAlign: 'left',
    label: 'BORROWER LP',
  },
  {
    id: 'makerFeeRate', // TODO update this field
    disablePadding: false,
    textAlign: 'left',//'right',
    label: 'Fees/Day',
  },
  {
    id: 'referenceMarketSize',
    disablePadding: false,
    textAlign: 'left',//'right',
    label: 'REFERENCE MARKET',
  },
  {
    id: 'subscriptions',
    disablePadding: false,
    textAlign: 'left',
    label: 'SUBSCRIPTION MARKET',
  },
  {
    id: 'action',
    disablePadding: false,
    textAlign: 'left',
    label: '',
  },
];

type Order = 'asc' | 'desc';
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  // custom ordering for test markets - check market.name
  if( orderBy=='tokenCode' ){
    if(`${b[orderBy]}`.startsWith('T_') && !`${a[orderBy]}`.startsWith('T_')){
      return -1;
    }
    if(!`${b[orderBy]}`.startsWith('T_') && `${a[orderBy]}`.startsWith('T_')){
      return 1;
    }
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: Partial<{ [key in Key]: number | string | boolean | unknown }>,
  b: Partial<{ [key in Key]: number | string | boolean | unknown }>,
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof MarketSortable) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof MarketSortable) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell,idx) => (
          <ValueTableCell
            key={headCell.id}
            align={headCell.textAlign}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sticky={idx==0}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </ValueTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default LiquidityProviderTable;