import { Box } from '@mui/material';
import { sxBox } from 'src/styles/General';
import MarketsHistoricalVolumeChart from './MarketsHistoricalVolumeChart';
import ProtocolHistoricalChart from './ProtocolHistoricalChart';

const sxChartBox = {
  ...sxBox,
  padding: '0',
  paddingTop: '12px'
}

function ExploreOverviewPanel(){
  return (<>
    <Box sx={sxChartBox}>
      <MarketsHistoricalVolumeChart type="volume"/>
    </Box>
    <Box sx={sxChartBox}>
      <MarketsHistoricalVolumeChart type="size"/>
    </Box>
    <Box sx={sxChartBox}>
      <ProtocolHistoricalChart type="totalValueLockedUsd"/>
    </Box>
    <Box sx={sxChartBox}>
      <ProtocolHistoricalChart type="totalValue"/>
    </Box>
    <Box sx={sxChartBox}>
      <ProtocolHistoricalChart type="userCount"/>
    </Box>
  </>);
}

export default ExploreOverviewPanel;