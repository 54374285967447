
import { Box } from '@mui/material';
import { sxBox, sxBoxCompactInner, sxBoxTitle } from '../styles/General';
import PublicTradeHistoryTable from './PublicTradeHistoryTable';

function ExploreTradePanel(){
  return (
    <Box sx={sxBox}>
      <Box sx={sxBoxTitle}>All Trades</Box>
      <Box sx={sxBoxCompactInner}>
        <PublicTradeHistoryTable/>
      </Box>
    </Box>
  )
}

export default ExploreTradePanel;