import {
  Box
} from '@mui/material';
import { SxProps } from '@mui/system';
import Decimal from 'decimal.js';
import { useCallback, useMemo } from 'react';
import { useAuth } from 'src/AuthProvider';
import MarketSwitcher from 'src/Components/MarketSwitcher';
import { sxColumnSeparator, sxColumns } from 'src/styles/General';
import { styleGrid, styleHeader, stylePanelNoPadding } from 'src/styles/GridBox';
import { MarketForSymbol } from 'src/types';
import apis from 'src/utils/apis';
import { format, formatTokenAmount } from 'src/utils/numbers';
import { paths } from 'src/utils/paths';
import { GridComponentParams } from '.';
import DragGrabber from './DragGrabber';

const sxUserValueLabel:SxProps = {
  opacity:0.5,
};

function MyPositions({token,isFetching}:GridComponentParams){
  const auth = useAuth();
  const tokenId = token?.tokenId;
  const {data:userMarketData} = apis.rate.useUserMarketDataByToken(auth,{tokenId});
  const { userNetPositions, userCarry, userPv01 } = useMemo(()=>{
    let userNetPositions = new Decimal(0);
    let userCarry = new Decimal(0);
    let userPv01 = new Decimal(0);
    if(userMarketData){
      // summary: includes non standard markets
      userNetPositions = new Decimal(userMarketData.summary.position||0);
      userCarry = new Decimal(userMarketData.summary.carry||0);
      userPv01 = new Decimal(userMarketData.summary.dv01||0);
    }
    return { userNetPositions, userCarry, userPv01 };
  },[userMarketData]);

  const tradePage = paths.useCallbackTradePage();
  const onMarketSwitcherSelectPath = useCallback((market:MarketForSymbol)=>tradePage(market),[tradePage]);

  return (
    <Box sx={styleGrid}>
      <Box sx={styleHeader}>
          <Box sx={{display:'flex'}}>
            <Box sx={sxColumns} style={{gap:'16px'}}>
              <Box>My Positions</Box>
              <Box sx={sxColumnSeparator}></Box>
              <Box><Box component='span' sx={sxUserValueLabel}>Carry:</Box>&nbsp;{token&&userCarry?formatTokenAmount(token,userCarry):'-'}</Box>
              <Box><Box component='span' sx={sxUserValueLabel}>Net Amounts:</Box>&nbsp;{token&&userNetPositions?formatTokenAmount(token,userNetPositions):'-'}</Box>
              <Box><Box component='span' sx={sxUserValueLabel}>PV01:</Box>&nbsp;{token&&userPv01?format(userPv01,4):'-'}</Box>
            </Box>
            <Box sx={{flex:1}}></Box>
          </Box>
          <DragGrabber/>
      </Box>
      <Box sx={{...stylePanelNoPadding, flexGrow: '1', overflowY: 'auto'}} id={'box-panel-no-padding'}>
        {token&&<MarketSwitcher token={token} layoutStyle="panel" columnType="simple" onSelectPath={onMarketSwitcherSelectPath} isMarketSticky withExtraRow/>}
      </Box>
    </Box>
  );
}


export default MyPositions;