/**
 * usage:
		import useAppStore from '../../store';
	  const appstate = useAppStore();
	  const { set, appError, currentMarket } = useAppStore();
    set('appError',"");
	add fields to interface (&/or useAppStore)    
 * 
 * */

import { Layouts } from 'react-grid-layout';
import { create } from 'zustand';
import { createJSONStorage, /* devtools, */ persist } from 'zustand/middleware';
import { Timezone } from 'src/charting_library'

type TLoginState = 'INIT'|'LOGGED_OUT'|'LOGGED_IN'|'RECOVERING';

interface AppState {
	showsTestnetNotification: boolean,
	hideTestnetNoteForever: boolean,
	tradingViewTimeZone: "exchange" | Timezone,
	loginState: TLoginState, setLoginState: (loginState:TLoginState)=>void,
	currentMarketId?: number,
	currentUserAccountId?: number;
	set: Function,
	connectedWalletAddress?: string,
	setConnectedWalletAddress: Function,
	/* trade dashboard */
	tradeMarketGridLayouts?: {version:number,layouts:Layouts},
}
type ValidAppStateKeys = Omit<keyof AppState,'set'|'get'>;
const useAppStore = create(persist((set:Function,get:()=>AppState):AppState => ({
	showsTestnetNotification: true,
	hideTestnetNoteForever: false,
	tradingViewTimeZone: 'exchange',
	loginState: 'INIT', setLoginState: (loginState)=>set((state:AppState)=>({loginState})),
	set: (key:ValidAppStateKeys,value:any) => set((state:AppState) => ({ [key as string]: value })),
	setConnectedWalletAddress: (walletAddress:string) => set((state:AppState) => ({ connectedWalletAddress: walletAddress })),
	/* trade dashboard */
	tradeMarketGridLayouts: undefined,
}),{
	name: "infinity-appstore",
	storage: createJSONStorage(()=>localStorage),
	partialize: (state)=>({tradeMarketGridLayouts:state.tradeMarketGridLayouts, showsTestnetNotification: state.showsTestnetNotification, hideTestnetNoteForever: state.hideTestnetNoteForever, tradingViewTimeZone: state.tradingViewTimeZone })
}));
export default useAppStore;
