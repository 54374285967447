import { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useRef } from "react";

/**
 * Converts a useQuery hook into a Promise callback trigger
 * @param useQuery original useQuery function
 * @param queryParams params for useQuery function
 * @param resolver maps useQuery data into target data to be passed along to Promise.resolve
 * @returns 
 */
export default function useQueryAsPromise<Q,QP extends [],T>({
  useQuery, queryParams, resolver,
}:{
  useQuery:((...args:QP)=>UseQueryResult<Q,unknown>), queryParams?:QP, resolver:(data:Q)=>(T),
}){
  const {isSuccess,isError,error,data} = queryParams?useQuery.apply(useQuery,queryParams):useQuery.apply(useQuery);
  const apiPromiseResolve = useRef<Function>();
  const apiPromiseReject = useRef<Function>();
  useEffect(()=>{
    if(isSuccess&&apiPromiseResolve.current){
      apiPromiseResolve.current(resolver(data));
      apiPromiseResolve.current = undefined;
    }
    if(isError&&apiPromiseReject.current){
      apiPromiseReject.current(error);
      apiPromiseReject.current = undefined;
    }
  },[isSuccess,isError,data,error,resolver]);
  return ()=>{
    return new Promise<T>((res,rej)=>{
      try{
        if(data){
          res(resolver(data));
        }else{
          apiPromiseResolve.current = res;
          apiPromiseReject.current = rej;
        }
      }catch(err:any){
        rej(err);
      }
    });
  };
}