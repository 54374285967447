import { Box, FormControl, MenuItem, Select, SelectChangeEvent, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TokenIcon from 'src/Components/TokenIcon';
import { MARKETS_PAGE_TOKENS } from "src/constants/app";
import { BootstrapMenuItem, BootstrapSelect, sxSelectMenu } from 'src/styles/Form';
import { sxMenuItem } from 'src/styles/General';
import { Token } from 'src/types';
import apis from 'src/utils/apis';

const sxFormControl:SxProps = {
  '.MuiSelect-select':{
    py: '8px',
    minWidth: '120px',
  },
  '.MuiOutlinedInput-notchedOutline':{
    borderColor: 'border.dark',
  }
};

/**
 * 
 * @param props.onSelectPath function to return path string when a token is selected
 * @returns 
 */
function TokenSwitcher({token, tokenSkip, onSelectPath,type='select', onSelectionChange}:{
  token?:Token, tokenSkip?: number[], onSelectPath?:(token:Token)=>string|undefined,type?:'select'|'list'|'icons'|'form-select',
  onSelectionChange?:(tokenId: any) => void
}){
  const navigate = useNavigate();
  const { data:tokens } = apis.token.useTokens();
  const filteredTokens = useMemo(
    () => {
      return tokens?.filter((token: Token) => {
        const isMarketToken = MARKETS_PAGE_TOKENS.includes(token.code)
        if (tokenSkip) {
          return isMarketToken && !tokenSkip.includes(token.tokenId)
        } else {
          return isMarketToken
        }
      })
    }, [tokens, tokenSkip]
  );
  const [selectedTokenId,setSelectedTokenId] = useState<number|''>('');
  const [isOpeningTokenSelect,setIsOpeningTokenSelect] = useState(false);
  useEffect(()=>{
    if(token&&selectedTokenId!=token.tokenId) setSelectedTokenId(token.tokenId);
  },[token, selectedTokenId]);
  useEffect(
    () => {
      if (selectedTokenId === '') {
        const newTokenId = token?.tokenId||filteredTokens?.at(0)?.tokenId||''
        setSelectedTokenId(newTokenId)
        tokenSkip && onSelectionChange && onSelectionChange(newTokenId)
      }
    }, [selectedTokenId, filteredTokens, token]
  )
  switch(type){
    case 'list':
      return (<>
        {filteredTokens?.map((token,i)=>(
          <MenuItem key={i} sx={sxMenuItem} value={token.tokenId}
            onClick={(event)=>{
              setSelectedTokenId(token.tokenId);
              if(token&&onSelectPath){
                const path = onSelectPath(token);
                if(path) navigate(path);
              }
            }}
          ><TokenIcon token={token} withCode={true} size={16} variant="bold"/></MenuItem>
        ))}
      </>);
    case 'icons':
      return (
        <ToggleButtonGroup
          color="primary"
          value={selectedTokenId}
          exclusive
          onChange={(event,value)=>{
            if(value!=null){
              const token = filteredTokens?.find(t=>t.tokenId==(value as unknown as number))
              if(token){
                setSelectedTokenId(token.tokenId);
                if(onSelectPath){
                  const path = onSelectPath(token);
                  if(path) navigate(path);
                }
              }
            }
          }}
          aria-label="order-direction-input"
          // disabled={disabled}
        >
        {filteredTokens?.map((token,i)=>(
          <ToggleButton key={i} value={token.tokenId}>
            <Box sx={{paddingTop:'8px',paddingBottom:'4px'}}>
              <TokenIcon token={token} withCode={false} size={28} variant="bold"/>
              <Box>{token.code}</Box>
            </Box>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>);
    case 'form-select':
      return (
        <BootstrapSelect MenuProps={{sx:sxSelectMenu}}
        id="token-select"
        value={selectedTokenId}
        open={isOpeningTokenSelect}
        onOpen={()=>setIsOpeningTokenSelect(true)}
        onClose={()=>setIsOpeningTokenSelect(false)}
        onChange={(event: SelectChangeEvent<unknown>, child: ReactNode)=>{
          setSelectedTokenId(Number(event?.target?.value));
          onSelectionChange && onSelectionChange(event?.target?.value);
        }}
        disabled={false}
      > 
        {/* {isShowingAllMarketMaturities&&<BootstrapMenuItem key={'maturitiesToggle'} value={'maturitiesToggle'}>...Show Only Standard Maturities</BootstrapMenuItem>} */}
        {filteredTokens?.map((token,i)=> (<BootstrapMenuItem key={`${token.code}-${i}`} value={token.tokenId}>
          <Box sx={{paddingTop:'8px',paddingBottom:'4px', display: 'flex', flexDirection: 'row'}}>
            <TokenIcon token={token} withCode={false} size={20} variant="bold"/>
            <Box sx={{ fontSize: '18px'}}>{token.code}</Box>
          </Box>
        </BootstrapMenuItem>))}
        {/* {!isShowingAllMarketMaturities&&<BootstrapMenuItem key={'maturitiesToggle'} value={'maturitiesToggle'}>...Show All Maturities</BootstrapMenuItem>} */}
      </BootstrapSelect>
      )
    case 'select': default:
    return (
      <FormControl sx={sxFormControl} fullWidth>
        {filteredTokens&&<Select
          id="token-select"
          value={selectedTokenId}
          onChange={(event)=>{
            const tokenId = parseInt(`${event.target.value}`);
            const token = tokens?.filter(token=>token.tokenId==tokenId)[0];
            setSelectedTokenId(tokenId);
            if(token&&onSelectPath){
              const path = onSelectPath(token);
              if(path) navigate(path);
            }
          }}
        >
        {filteredTokens?.map((token,i)=>(
          <MenuItem key={i} sx={sxMenuItem} value={token.tokenId}><TokenIcon token={token} withCode={true} size={16} variant="bold"/></MenuItem>
        ))}
        </Select>}
      </FormControl>
    );
  }
}

export default TokenSwitcher;