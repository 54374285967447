import {
  Box, Button,
  SxProps,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import { Decimal } from 'decimal.js';
import { Ref, forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from 'src/AuthProvider';
import { sxChildRow, sxStickeyCell, sxTokenTable } from 'src/styles/TokenTable';
import { ExternalProtocolsHelper, TMarketExternalProtocol } from 'src/utils/ExternalProtocolsHelper';
import { getDaysToMaturitiesFromMarkets, useRollingDates } from 'src/utils/date';
import { format, formatUSDWithSign } from 'src/utils/numbers';
import { paths } from 'src/utils/paths';
import { sxBoxCompactInner, sxMonoFont, sxHalfOpacity } from '../styles/General';
import { MarketPastRate, Token } from '../types';
import apis from '../utils/apis';
import DeltaValue from './DeltaValue';
import { LoadingCell } from './Loading';
import TokenIcon from './TokenIcon';
import { sxVerticalSeparator } from 'src/styles/Table';
import SidedQuantityValue from "./SidedQuantityValue";

interface MarketData {
  daysToMaturity: number;
  marketId: number;
  midRateOrRate?: Decimal,
  midRateOrRate24?: Decimal;
  externalProtocolImpliedRate?: Decimal;
  externalProtocolLendRate?: Decimal;
  externalProtocolBorrowRate?: Decimal;
}

function _MarketAssetsTable({tokens}:{tokens?:Token[]},ref:Ref<any>){
  useImperativeHandle(ref,()=>({
    expandAll() {positionRowRefArray.current.forEach(ref=>ref.expand())},
    collapseAll() {positionRowRefArray.current.forEach(ref=>ref.collapse())},
  }),[]);
  const positionRowRefArray = useRef(new Array<{expand:Function,collapse:Function}>());
  const rollingDayLabels = [...useRollingDates().map(({label})=>label)];
  return (
    <Box sx={sxBoxCompactInner}>
      <TableContainer component={Box}>
        <Table sx={sxTokenTable}>
          <TableHead>
            <TableRow>
              <TableCell sx={sxStickeyCell}>Name</TableCell>
              <TableCell align='right' sx={sxVerticalSeparator}>Market Size</TableCell>
              <TableCell align='right'>Float</TableCell>
              {rollingDayLabels.map((label,i)=>(
                <TableCell key={i} align='right'>{label}</TableCell>
              ))}
              <TableCell>{/* Action */}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {tokens?.map((token,i)=>(
            <MarketAssetRow key={i} token={token} compact={true} ref={ref=>{positionRowRefArray.current[i] = ref}}/>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
const MarketAssetsTable = forwardRef(_MarketAssetsTable);

function _MarketAssetRow({token,externalProtocol,compact=false,}:{token:Token,externalProtocol?:TMarketExternalProtocol,compact?:boolean},ref:Ref<any>){
  const auth = useAuth();
  const navigate = useNavigate();

  // UI state
  const [expanded,setExpanded] = useState(false); // expand children if it's top level (non externalProtocol)
  useImperativeHandle(ref, () => ( { expand() { setExpanded(true) }, collapse() { setExpanded(false) } } ), []);
  const isExternalProtocol = externalProtocol!==undefined;

  // markets
  const tokenId = token.tokenId;
  const {isLoading:isLoadingSpotMarket,data:spotMarket} = apis.rate.useMarket({tokenId});
  const {isLoading:isLoadingMarketsFixedRate,data:marketsFixedRate} = apis.fixedrate.useFixedRateMarkets({tokenId});
  const daysToMaturities = getDaysToMaturitiesFromMarkets(marketsFixedRate);
  const frMarketIds = marketsFixedRate?.map(m=>m.marketId);
  const {isLoading:isLoadingFixedMarketSize,data:fixedMarketSize} = apis.fixedrate.useMarketSize(token.tokenId);
  const subscriptions = fixedMarketSize&&spotMarket&&(new Decimal(fixedMarketSize.subscriptions||0).add(new Decimal(spotMarket.subscriptions||0)));
  const subscriptionsUsd = subscriptions?.mul(token.price||'0');
  const {isLoading:isLoadingMarketsRatesByToken,data:marketsRatesByToken} = apis.rate.useMarketsPastRates({tokenId,frMarketIds});

  const isLoadingAny = isLoadingSpotMarket|| isLoadingMarketsFixedRate || isLoadingFixedMarketSize || isLoadingMarketsRatesByToken;

  // table data
  const {marketData,externalProtocolRows,path} = useMemo(()=>{
    const rateDateMap = {};
    marketsRatesByToken&&marketsRatesByToken[`${token.tokenId}`]?.forEach((mpd:MarketPastRate, index: number)=>{
      const t = mpd.daysToMaturity||0; 
      if(isExternalProtocol){
        let externalProtocolImpliedRate;
        let externalProtocolBorrowRate;
        let externalProtocolLendRate;
        switch(externalProtocol){
          case 'Aave v2': 
            if(mpd.aaveLendRate) externalProtocolLendRate = new Decimal(mpd.aaveLendRate);
            if(mpd.aaveBorrowRate) externalProtocolBorrowRate = new Decimal(mpd.aaveBorrowRate);
            break;
            case 'Deribit':
              if(mpd.deribitImpliedRate) externalProtocolImpliedRate = new Decimal(mpd.deribitImpliedRate); 
              break;
            case 'OKX':
              if(mpd.okxImpliedRate) externalProtocolImpliedRate = new Decimal(mpd.okxImpliedRate); 
              break;
          default: 
            break;
        }
        rateDateMap[t] = Object.assign({},rateDateMap[t],{
          marketId: mpd.marketId, 
          daysToMaturity: t, externalProtocolBorrowRate, externalProtocolLendRate, externalProtocolImpliedRate,
        });
      }else{
        const midRateOrRate = new Decimal(mpd.rate || mpd.midRate|| 0);
        let midRateOrRate24 = new Decimal(mpd.rate24 || mpd.midRate24 || 0);
        if (t > 0 && midRateOrRate24.eq(0)) {
          const prevMpr = marketsRatesByToken[`${token.tokenId}`][index - 1]
          midRateOrRate24 = new Decimal(prevMpr.rate24 || prevMpr.midRate24 || 0)
        }
        rateDateMap[t] = Object.assign({},rateDateMap[t],{
          marketId: mpd.marketId, 
          daysToMaturity: t, midRateOrRate, midRateOrRate24
        });
      }
    });
    const marketData:Array<MarketData|undefined> = [0,...daysToMaturities].map(days=>{ // prepend 0 for float
      return rateDateMap[days];
    });
    const externalProtocolRows = ExternalProtocolsHelper.toArray().map(protocol=>(
      <MarketAssetRow key={protocol} token={token} externalProtocol={protocol}/>
    ));
    const path = paths.lendAndBorrowMarket(token);
    return {marketData,externalProtocolRows,path};
  },[token,daysToMaturities,marketsRatesByToken,isExternalProtocol,externalProtocol]);
  return (<>
    <TableRow
      // onClick={(event) => navigate(path)}
      sx={isExternalProtocol?sxChildRow:{}}
    >
      <TableCell sx={sxStickeyCell}>
        {isExternalProtocol&&<b>{externalProtocol}</b>}
        {!isExternalProtocol&&<TokenIcon token={token} withCode={true} size={14} variant='bold'/>}
      </TableCell>
      <TableCell align='right' sx={{...sxMonoFont,...sxVerticalSeparator} as SxProps}>
        {!isExternalProtocol && 
          (!subscriptionsUsd ? 
            (isLoadingAny ? <LoadingCell /> : '-') :
            <>
              <SidedQuantityValue token={token} value={subscriptions}/>
              <SidedQuantityValue token={token} value={subscriptionsUsd} prefix="$" dp={2} sx={sxHalfOpacity}/>
            </>
          )
        }
      </TableCell>
      {marketData.map((md,i)=>{
        if(!md){ 
          return (<TableCell key={i} align='right' sx={{ minWidth: '4.6rem' }}>{isLoadingAny ? <LoadingCell /> : '-'}</TableCell>); 
        }
        return isExternalProtocol?
          <MarketAssetExteranlRateCell key={i} md={md} externalProtocol={externalProtocol}/>:
          <MarketAssetRateCell key={i} md={md} tokenId={tokenId}/>
        ;
      })}
      <TableCell>
        {!isExternalProtocol&&!isLoadingAny&&<Button component={NavLink} to={path} variant="contained">Lend or Borrow</Button>}
      </TableCell>
    </TableRow>
    {expanded&&externalProtocolRows}
  </>);
}
const MarketAssetRow = forwardRef(_MarketAssetRow);

function MarketAssetRateCell({md,tokenId}:{md:MarketData,tokenId:number}){
  // use marketInfo api for websocket data
  const marketId = md.marketId;
  const {isLoading:isLoadingMarket,data:market} = apis.rate.useMarket({marketId});
  const {data:marketInfo} = apis.rate.useMarketInfo({market});
  const rate = (marketInfo?.rate || marketInfo?.midRate)?(new Decimal(marketInfo?.rate || marketInfo?.midRate || 0)):undefined;
  const delta = md.midRateOrRate24 && rate?.minus(md.midRateOrRate24);
  return (
    <TableCell align='right' sx={{ ...sxMonoFont, minWidth: '4.6rem' }}>
      {format((rate || md.midRateOrRate)?.mul(100),2)}%
      <DeltaValue value={delta?.mul(100)} dp={2} suffix="%"/>
    </TableCell>
  );
}

function MarketAssetExteranlRateCell({md,externalProtocol}:{md:MarketData,externalProtocol:TMarketExternalProtocol}){
  // use externalMarketInfo api for websocket data
  const marketId = md.marketId;
  const {data:market} = apis.rate.useMarket({marketId});
  const {data:marketInfo} = apis.rate.useExternalMarketInfo({market,externalProtocol});
  const {borrow,lend,rate} = useMemo(()=>{
    if(marketInfo) return {borrow:marketInfo.borrowRate,lend:marketInfo.lendRate,rate:marketInfo.impliedRate};
    return {borrow:md.externalProtocolBorrowRate,lend:md.externalProtocolLendRate,rate:md.externalProtocolImpliedRate};
  },[marketInfo,md]);
  const hasBorrowNLend = lend!==undefined && borrow!==undefined;
  return(
    <TableCell align='right' sx={{ ...sxMonoFont, minWidth: '4.6rem' }}>
      {hasBorrowNLend?(<>
        L: {lend===undefined?'-':`${format(new Decimal(lend).mul(100),2)}%`} B: {borrow===undefined?'-':`${format(new Decimal(borrow).mul(100),2)}%`} 
      </>):(<>
        {rate===undefined?'-':`${format(new Decimal(rate).mul(100),2)}%`}
      </>)}
    </TableCell>
  );
}

export default MarketAssetsTable;