import { SnackbarAction, VariantType, useSnackbar as useNotistackBar } from 'notistack';
import { useCallback } from 'react';
import { parseError, truncateErrorMessage } from 'src/utils/common';

const useSnackbar = ()=>{
  const { enqueueSnackbar, closeSnackbar } = useNotistackBar();
  const queue = useCallback(({
    key,message,type,action,autoHide
  }:{
    key?:string,message:string,type:VariantType,action?:SnackbarAction,autoHide?:boolean,
  })=>{
    enqueueSnackbar(truncateErrorMessage(message),{
      key, variant:type, action, autoHideDuration: autoHide?undefined:null, /* persist: !autoHide, */
    });
  },[enqueueSnackbar]);
  const error = useCallback((error:any,customKey?:string)=>{
    const {key,message} = parseError(error);
    queue({key: customKey ?? key, message, type:'error'});
    console.error(error);
  },[queue]);
  const close = useCallback((key:string)=>closeSnackbar(key),[closeSnackbar]);
  const closeAll = useCallback(()=>closeSnackbar(),[closeSnackbar]);
  return {
    error, 
    /**
     * queue a new snackback message 
     * @params key: unique key to prevent repeated message
     * @params message: message content
     * @params type: icon & color of the message
     * @params action: SnackbarAction
     * @params autoHide: default false
     * 
     */
    queue, 
    close, 
    closeAll,
  };
}

export default useSnackbar;