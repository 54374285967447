import { Box, BoxProps } from '@mui/material';
import { TESTNET_NOTIFICATION_HEIGHT } from 'src/constants/app';
import useAppStore from 'src/store';
import { HEADER_HEIGHT } from './Header';

interface IProps extends BoxProps {
  withSubHeader?:boolean
};

function StickyBox(props:IProps){
  const {showsTestnetNotification} = useAppStore();
  const top = props.withSubHeader?HEADER_HEIGHT:'0px';
  const passProps = {...props}; delete passProps.withSubHeader;
  const topOffset = (showsTestnetNotification&&TESTNET_NOTIFICATION_HEIGHT||0);
  const topStyle = `calc(${topOffset}px + ${top} + 12px)`;
  // console.log('topStyle',topStyle);
  return (<Box {...passProps} style={{position:'sticky',top:topStyle}}>{props?.children}</Box>);
}

export default StickyBox;