import {
  Box, Table, TableBody, TableContainer, TableHead, TableRow
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import Decimal from 'decimal.js';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useAuth } from 'src/AuthProvider';
import useAppStore from 'src/store';
import { sxCellBottomAlign, sxDate, sxTableRowFollowLast, sxTokenTable, sxTokenTableContainer } from 'src/styles/TokenTable';
import { UserTokensGraphDateBucketType } from 'src/types/graphs';
import { sortBy, unique } from 'src/utils/common';
import { date, now, useCallbackFormatDaysFromMarketToday, useCallbackGetDaysFromMarketToday, useRollingDateDays } from 'src/utils/date';
import { formatTokenAmount } from 'src/utils/numbers';
import apis from '../utils/apis';
import LoadingIcon from './Loading';
import MarketRollingDateLabel from './MarketRollingDateLabel';
import { TReloadDateTextRefs } from './ReloadDateText';
import ValueTableCell from './ValueTableCell';

function UserRateBucketsTable({
  showsUSDValue,dateBucketType='By Contract',
  reloadDateTextRefs,
}:{
  showsUSDValue?:boolean,dateBucketType?:UserTokensGraphDateBucketType,
  reloadDateTextRefs?:TReloadDateTextRefs,
}){
  const auth = useAuth();  
  const daysToMaturities = useRollingDateDays();
  const getDaysFromMarketToday = useCallbackGetDaysFromMarketToday();
  const formatDaysFromMarketToday = useCallbackFormatDaysFromMarketToday();
  const {data:tokens,isLoading:isLoadingTokens} = apis.token.useMarketsPageTokens();
  // user account info
  const { currentUserAccountId: currentUserAccountId } = useAppStore();
  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  const tradeAccount = userAccounts&&apis.user.extractUserAccount(userAccounts,currentUserAccountId);
  const {isFetching:isFetchingOrders,data:allRateOrdersData} = apis.user.useUserAllRateOrders(auth,{accountId:tradeAccount?.accountId, pending:true, limit:10000});

  const isLoadingAny = isLoadingTokens;
  const isFetching = isFetchingOrders;

  // reload date text refs
  const queryClient = useQueryClient();
  useEffect(()=>{
    if(reloadDateTextRefs){ 
      reloadDateTextRefs.isFetchingReloadRef.current = isFetching; 
      reloadDateTextRefs.lastUpdatedTimeRef.current = moment(); 
    }
  },[reloadDateTextRefs,isFetching]);
  if(reloadDateTextRefs){
    reloadDateTextRefs.handleClickReloadRef.current = ()=>{ 
      queryClient.invalidateQueries(['user',{type:'orders',action:'listAllOrders'}]); 
    };
  }
  
  // generate rechart data
  // type TData = {i:number;day:string;[code:string]:number|undefined;};
  const {cutoffDays,data,tokenValueMap} = useMemo(()=>{
    // generate cutoff days according to dateBucketType
    let cutoffDays:number[];
    switch(dateBucketType){
      case 'Monthly':
        cutoffDays = (new Array(6).fill(0)).map((_,i)=>now().add(i+1,'month').diff(now(),'day'));
        break;
      case 'Quarterly':
        cutoffDays = (new Array(6).fill(0)).map((_,i)=>now().add(i+3,'month').diff(now(),'day'));
        break;
      case 'Semi-Annually':
        cutoffDays = (new Array(4).fill(0)).map((_,i)=>now().add(i+6,'month').diff(now(),'day'));
        break;
      case 'Annually':
        cutoffDays = (new Array(3).fill(0)).map((_,i)=>now().add(i+12,'month').diff(now(),'day'));
        break;
      case 'By Contract': default:
        cutoffDays = unique([0,...daysToMaturities]);
        break;
    }
    // create buckets array
    const data:any[] = cutoffDays.map((day,i)=>({
      i, d:0, day:day,
    }));
    // fill in elements
    const tokenValueMap:{[code:string]:Decimal} = {};
    tokens&&sortBy(allRateOrdersData?.orders||[],'maturityDate').forEach(d=>{
      let i; let cutoff = 0;
      const t = tokens.filter(t=>t.code===d.instrumentId?.split('-')[0])[0];
      if(!t) return;
      const days = d.maturityDate===undefined?0:getDaysFromMarketToday(date(d.maturityDate));
      for(i=0;i<cutoffDays.length;i++){
        if(cutoff>=days) break;
        cutoff = cutoffDays[i];
      }
      const dataIdx = days===0?0:i-1; // i-1 for previous matching key
      // console.log('iterate',i,dataIdx,days,token.code,d.position);
      if(!data[dataIdx]) return;
      const value = new Decimal(data[dataIdx][t.code]||0);
      data[dataIdx][t.code] = new Decimal(d.quantity||0).add(value).toNumber();
      tokenValueMap[t.code] = (tokenValueMap[t.code]||new Decimal(0)).add(value);
    });
    return {cutoffDays,data,tokenValueMap};
  },[daysToMaturities,dateBucketType,getDaysFromMarketToday,allRateOrdersData?.orders,tokens]);
  
  return (<>
    <TableContainer sx={sxTokenTableContainer}>
      <Table sx={sxTokenTable}>
        <TableHead>
          <TableRow>
            <ValueTableCell rowSpan={2} sx={sxCellBottomAlign} sticky>Total</ValueTableCell>
            {tokens?.map((token,i) => (<ValueTableCell key={i} align="right">{token.code}</ValueTableCell>))}
          </TableRow>
          <TableRow hover sx={sxTableRowFollowLast}>
            {tokens?.map((token,i) =>{
              const value = tokenValueMap[token.code];
              return (<ValueTableCell mono key={i} align="right">{value ? formatTokenAmount(token,value,showsUSDValue) : '-'}</ValueTableCell>)
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {cutoffDays?.map((d,dataIdx)=>{
            let labelCell:JSX.Element|string = <></>;
            switch(dateBucketType){
              case 'Monthly': labelCell = `${dataIdx+1}M`; break;
              case 'Quarterly': labelCell = `${dataIdx>=4?'1Y':''}Q${dataIdx%4+1}`; break;
              case 'Semi-Annually': labelCell = `${dataIdx>=2?'1Y':''}${dataIdx%2+1}H`; break;
              case 'Annually': labelCell = `${dataIdx+1}Y`; break;
              case 'By Contract': 
                const rollingDate = <MarketRollingDateLabel daysToMaturity={d}/>;
                const dateLabel = dataIdx===0?'':<Box component="span" sx={sxDate}>&nbsp;{formatDaysFromMarketToday(d)}</Box>;
                labelCell = <>{rollingDate}{dateLabel}</>;
                break;
            }
            return (
              <TableRow hover key={dataIdx}>
                <ValueTableCell mono sticky>{labelCell}</ValueTableCell>
                {tokens?.map((token,i) => {
                  const value = data[dataIdx][token.code];
                  return (<ValueTableCell mono key={i} align="right">{value ? formatTokenAmount(token,value,showsUSDValue) : '-'}</ValueTableCell>)
                })}
              </TableRow>
            );
          })}
          {isLoadingAny&&<TableRow hover><ValueTableCell colSpan={10} sx={{textAlign:'center'}}><LoadingIcon inline={true}/></ValueTableCell></TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  </>);
}

export default UserRateBucketsTable;