/*
  Config/Hub for all grid items on the Market page
*/

// import MarketSwitcher from './MarketSwitcher';
import withConnectWallet from 'src/Components/WithConnectWallet';
import { BaseMarket, Token, MarketForSymbol, SelectedOrderBookEntry } from 'src/types';
import theme from 'src/utils/theme';
/* original components for withConnectWallet */
import DV01GraphOriginal from './PV01Graph';
import MTMGraphOriginal from './MTMGraph';
import OrderHistoryOriginal from './OrderHistory';
import OrdersGraphOriginal from './OrdersGraph';
import AmountsGraphOriginal from './AmountsGraph';
/* actual grid components */
import LiquidityGraph from './LiquidityGraph';
import MarketGraph from './MarketGraph';
import MarketInfo from './MarketInfo';
import MarketTrades from './MarketTrades';
import OrderBook from './OrderBook';
import OrderForm from './OrderForm';
import YourPosition from './MyPositions';
export const DV01Graph = withConnectWallet(DV01GraphOriginal)
export const MTMGraph = withConnectWallet(MTMGraphOriginal);
export const OrdersGraph = withConnectWallet(OrdersGraphOriginal)
export const OrderHistory = withConnectWallet(OrderHistoryOriginal)
export const AmountsGraph = withConnectWallet(AmountsGraphOriginal)
/* Map for GridLayout component name assignment - needde for consistent component name (webpack build minifies class names otherwise) */
const GridComponentMap = {
  "LiquidityGraph":LiquidityGraph,
  "MarketGraph":MarketGraph,
  "MarketInfo":MarketInfo,
  "MarketTrades":MarketTrades,
  "OrderBook":OrderBook,
  "OrderForm":OrderForm,
  "YourPosition":YourPosition,
  "DV01Graph":DV01Graph,
  "MTMGraph":MTMGraph,
  "OrdersGraph":OrdersGraph,
  "OrderHistory":OrderHistory,
  "PositionsGraph":AmountsGraph,
};
type GridComponentMapKey = keyof typeof GridComponentMap;

export type Breakpoints = 'xl'|'lg'|'md'|'sm';
export const leftPanelWidth = 160;
export type GridsType = 'Default';//|'Rates'|'RatesTwoLeg'|'Fx';
export type GridComponentParams = { market?: BaseMarket; token?: Token; isFetching?: boolean; onSelectOrderBookEntry?: (orderBookEntry?:SelectedOrderBookEntry)=>any; selectedOrderBookEntry?: SelectedOrderBookEntry; onMarketSwitcherSelectPath?: (market:MarketForSymbol) => string;};
export const gridBreakpoints = {xl:theme.breakpoints.values.desktopWider,lg:theme.breakpoints.values.desktop,md:theme.breakpoints.values.tablet,sm:theme.breakpoints.values.mobile};
export const gridColumns:{[breakpoint in Breakpoints]:number} = {xl:24,lg:24,md:12,sm:2};
type GridCfgItem = {
  x:number, y:number, w:number, h:number, componentKey:GridComponentMapKey;
};
const _c = ([x,y,w,h]:number[],componentKey:GridComponentMapKey)=>({componentKey,x,y,w,h});
const gridCfgs = {
  Default: {
    xl:[
      _c([0,0,4,10],'OrderForm'),      _c([4,0,4,13],'OrderBook'),   _c([8,0,4,4], 'PositionsGraph'), _c([12,0,12,8], 'MarketGraph'),
                                                                 _c([8,3,4,4], 'MTMGraph'),      
                                                                 _c([8,6,4,4], 'DV01Graph'),      _c([12,4,12,7], 'YourPosition'),
      _c([0,9,4,10], 'MarketInfo'),    _c([4,8,4,7],'MarketTrades'), _c([8,9,4,4], 'OrdersGraph'),    _c([12,10,12,5],'OrderHistory'),
                                                                 _c([8,12,4,4],'LiquidityGraph'), 
    ],
    lg:[
      _c([0,0,5,10],'OrderForm'),      _c([5,0,5,10],'OrderBook'),   _c([10,0,5,3], 'PositionsGraph'), _c([15,0,9,8],'MarketGraph'),
                                                                 _c([10,3,5,3], 'MTMGraph'),      
                                                                 _c([10,6,5,3], 'DV01Graph'),      
                                                                 _c([10,9,5,3], 'OrdersGraph'),    _c([15,4,9,7],'YourPosition'),
      _c([0,9,5,5], 'MarketInfo'),    _c([5,8,5,5],'MarketTrades'),  _c([10,12,5,3],'LiquidityGraph'), 
      _c([0,15,24,5],'OrderHistory'), 
    ],
    md:[
      _c([0,0,5,10],'OrderForm'),     _c([5,0,4,10],'OrderBook'),   _c([9,0,3,3], 'PositionsGraph'), 
                                                                _c([9,3,3,3], 'MTMGraph'),      
                                                                _c([9,6,3,3], 'DV01Graph'),    
                                    _c([5,8,4,5],'MarketTrades'), _c([9,9,3,3], 'OrdersGraph'), 
      _c([0,9,5,5], 'MarketInfo'),                               _c([9,12,3,3],'LiquidityGraph'), 
      _c([0,15,12,6],'MarketGraph'),
      _c([0,21,12,7],'YourPosition'), 
      _c([0,27,12,5],'OrderHistory')
    ],
    sm:[
      _c([0,0,2,10],  'OrderForm'),
      _c([0,10,2,15], 'OrderBook'), 

      _c([0,25,2,4], 'PositionsGraph'),
      _c([0,29,2,4], 'MTMGraph'),
      _c([0,33,2,4], 'DV01Graph'),    
      _c([0,37,2,4], 'OrdersGraph'), 
      _c([0,41,2,4], 'LiquidityGraph'), 

      _c([0,45,2,6], 'MarketGraph'),
      _c([0,51,2,7], 'YourPosition'),
      _c([0,57,2,4], 'MarketInfo'),
      _c([0,61,2,4], 'MarketTrades'),
      _c([0,65,2,5], 'OrderHistory')
    ]
  },
};
export function getGridsConfigs(type:GridsType){
  const cfgs = gridCfgs[type];
  const componentKeys = new Array<GridComponentMapKey>();
  const layouts = Object.keys(gridColumns).reduce((layouts,breakpoint)=>{
    const items = (cfgs[breakpoint] as GridCfgItem[]);
    const layout = items.map(({x,y,w,h,componentKey})=>{
      if(!componentKeys.includes(componentKey)) componentKeys.push(componentKey);
      return {x,y,w,h,i:componentKey};
    });
    return {...layouts,[breakpoint]:layout};
  },{});
  return { layouts, componentKeys, GridComponentMap };
}
