import { Box, SxProps } from '@mui/material';
import Decimal from 'decimal.js';
import { sxColumns } from 'src/styles/General';
import { BaseMarket, Token } from 'src/types';
import apis from 'src/utils/apis';
import { formatUSDWithSign } from 'src/utils/numbers';
import { paths } from 'src/utils/paths';
import theme from 'src/utils/theme';
import LoadingIcon from './Loading';
import TokenIcon from './TokenIcon';
import TokenSwitcher from './TokenSwitcher';

const styleTokenIcon:SxProps = {
  fontWeight: 700,
  [theme.breakpoints.down('tablet')]:{
    width: '100%',
  },
};
const styleInfoBox:SxProps = {
  display:"block",
  textAlign:"center",
  p:1,
  [theme.breakpoints.down('tablet')]:{
    width: '100%', display: 'flex', justifyContent: 'space-between',
  },
};
const styleInfoBoxTitle:SxProps = {
  fontWeight: '700',
  fontSize:'0.875rem',
  display: 'inline-block',
};
const styleInfoBoxValue:SxProps = {
  fontSize:'0.875rem',
  display: 'inline-block',
};

function MarketFixedRateInfoRow({token,tokenSelect}:{token:Token,tokenSelect?:'lendBorrow'|'liquidity'}) {
  const {isLoading:isLoadingMarket,data:spotMarket} = apis.rate.useMarket({tokenId:token?.tokenId});
  const {isLoading:isLoadingMarketSize,data:fixedMarketSize} = apis.fixedrate.useMarketSize(token?.tokenId);
  const subscriptions = new Decimal(fixedMarketSize?.subscriptions||0).add(new Decimal(spotMarket?.subscriptions||0));
  const lends = new Decimal(fixedMarketSize?.deposits||0).add(new Decimal(spotMarket?.deposits||0));
  const borrows = new Decimal(fixedMarketSize?.borrows||0).add(new Decimal(spotMarket?.borrows||0));
  const onSelectPath = (token:Token)=>tokenSelect==='lendBorrow'?paths.lendAndBorrowMarket(token):paths.liquidityPage(spotMarket as BaseMarket);
  const subscriptionsInUsd = subscriptions.times(token?.price ?? 0)
  const lendsInUsd = lends.times(token?.price ?? 0)
  const borrowsInUsd = borrows.times(token?.price ?? 0)
  const isLoadingAny = isLoadingMarket||isLoadingMarketSize;
  return (
    <Box sx={{...sxColumns,alignItems:'center'}}>
      {isLoadingAny&&<LoadingIcon curtain/>}
      <Box sx={styleTokenIcon}>
        {tokenSelect&&<TokenSwitcher token={token} 
          onSelectPath={tokenSelect&&spotMarket&&onSelectPath}
        />}
        {!tokenSelect&&<TokenIcon token={token} withCode={true} size={16}/>}
      </Box>
      <Box sx={{flex:1}}></Box>
      <Box sx={styleInfoBox}>
        <Box sx={styleInfoBoxTitle}>Market Size:</Box>&nbsp;
        <Box sx={styleInfoBoxValue}>{(isLoadingAny || !token) ? '-' : formatUSDWithSign(subscriptionsInUsd.toNumber())}</Box>
      </Box>
      <Box sx={styleInfoBox}>
        <Box sx={styleInfoBoxTitle}>Available Lend:</Box>&nbsp;
        <Box sx={styleInfoBoxValue}>{(isLoadingAny || !token) ? '-' : formatUSDWithSign(lendsInUsd.toNumber())}</Box>
      </Box>
      <Box sx={styleInfoBox}>
        <Box sx={styleInfoBoxTitle}>Available Borrow:</Box>&nbsp;
        <Box sx={styleInfoBoxValue}>{(isLoadingAny || !token) ? '-' : formatUSDWithSign(borrowsInUsd.toNumber())}</Box>
      </Box>
    </Box>
  );
}
export default MarketFixedRateInfoRow;