import { ArrowDropDown } from '@mui/icons-material';
import { Box, Button, Link, Menu, MenuItem, SxProps } from '@mui/material';
import React, { useRef, useState } from 'react';
import useAppStore from 'src/store';
import { sxMenuItem, sxMenuItemSeparator, sxMenuModalRoot } from 'src/styles/General';
import theme from 'src/utils/theme';
import { useAuth } from '../AuthProvider';
import LoadingIcon from './Loading';
import LoginDialog from './LoginDialog';
import WalletAddress from './WalletAddress';
import { sendGA } from 'src/utils/common';

const sxWrapper:SxProps = {
  height:'100%', display:'grid', alignItems:"center",
  ".MuiButton-root":{
    height: '32px', py: 0, paddingRight: '24px',
  }
};
const sxArrowDropDown:SxProps = {
  fontSize:12, position:'absolute', top:'50%', right:8, transform:'translateY(-50%)',
};

function ConnectButton({mobile}:{mobile?:boolean}){
  const auth = useAuth();
  const { loginState } = useAppStore();
  const [ showingDialog, setShowingDialog ] = useState(false);

  const [hoverOpen,setHoverOpen] = useState(false);
  const wrapperRef = useRef<HTMLElement>(null);

  function handleClickOpenDialog(event: React.MouseEvent) {
    sendGA('connect_wallet_dialog');
    setShowingDialog(true);
  }
  function handleCloseDialog(){
    setShowingDialog(false);
  }
  function delegateClick(event: React.MouseEvent){
    (event.currentTarget.firstElementChild as HTMLElement)?.click();
    event.stopPropagation();
  }

  function handleClickDisconnect(event: React.MouseEvent) {
    auth.signout();
    //If we don't close the menu manually and log back without refresh, it will show the menu in the bottom-left corner after login
    setHoverOpen(false)
  }

  let buttonComponent = <></>;
  if(['RECOVERING', 'INIT'].includes(loginState)){
    buttonComponent = <Button><LoadingIcon size={30}/></Button>;
  }else if(!auth.user){
    buttonComponent = <Button component={Link} color="secondary" variant="contained" onClick={handleClickOpenDialog}>Connect Wallet</Button>;
  }else{
    buttonComponent = (
      <Box sx={sxWrapper} ref={wrapperRef}>
        <Button sx={{minWidth:0,paddingLeft:'6px'}} component={Link} color="primary" variant="outlined"
          onClick={()=>mobile&&setHoverOpen(!hoverOpen)}
          onMouseEnter={()=>!mobile&&setHoverOpen(true)}
          onMouseLeave={()=>!mobile&&setHoverOpen(false)}
        >
          <WalletAddress address={auth.user.address} disableLink withAvatar length={mobile?0:undefined}/><ArrowDropDown sx={sxArrowDropDown}/>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={wrapperRef.current}
          open={hoverOpen}
          // open={true} // DEBUG always open
          onMouseEnter={()=>setHoverOpen(true)}
          onMouseLeave={()=>setHoverOpen(false)}
          onClose={()=>setHoverOpen(false)}
          hideBackdrop={true}
          style={{pointerEvents:'none'}}
          sx={sxMenuModalRoot}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            style: {
              pointerEvents:'initial',overflow:'hidden',backgroundColor: theme.palette.background.paper,
              border: `1px solid ${theme.palette.border.dark}`,
            },
          }}
        >
          <MenuItem sx={sxMenuItem} onClick={delegateClick}>View Address on etherscan.io<WalletAddress address={auth.user.address} iconOnly/></MenuItem>
          <Box component="li" sx={sxMenuItemSeparator}></Box>
          {/* <MenuItem sx={sxMenuItem} onClick={handleClickOpenDialog}>Change Wallet</MenuItem> */}
          <MenuItem sx={sxMenuItem} onClick={handleClickDisconnect}>Disconnect Wallet</MenuItem>
        </Menu>
      </Box>
    );
  }
  return (
    <>
      {buttonComponent}
      <LoginDialog handleClose={handleCloseDialog} showing={showingDialog}/>
    </>
  );
}


export default ConnectButton;