import { SxProps } from '@mui/system';
import {
  blueDark, blueDarkA, brownDark, brownDarkA, purpleDark, purpleDarkA, tealDark, tealDarkA, tomatoDark, tomatoDarkA
} from '@radix-ui/colors';
import { hslStrToHex } from 'src/utils/common';
import theme from '../utils/theme';
import { sxMonoFont } from './General';

export const sxChartContainer:SxProps = {
  // p:2,my:2,
  flex:1,display:'flex',alignItems:'center',justifyContent:'center',
  // bgcolor: 'dialog.light',
  ".recharts-responsive-container":{
    // py:3,
  }
};

export const sxChartContainerDark:SxProps = {
  ...sxChartContainer,
  bgcolor: 'dialog.dark',
};

export const sxChartTooltip:SxProps = {
  ...sxMonoFont,
  bgcolor: 'tooltip.main',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'tooltip.light',
  p: '16px 12px', borderRadius: '4px',
  '&,&:focus-visible':{
    // border: 'none',
    outline: 'none',
  },
};
export const sxChartTooltipTable:SxProps = {
  borderTop: '1px solid',
  borderColor: 'grey.800',
  "th": {
    fontWeight: 700
  },
  "th, td": {
    padding: '4px',
    fontSize: '0.75rem',
    border: 0,
  }
}
export const sxChartTooltipLegendBox:SxProps = {
  width: '1em', height: '1em', display: 'inline-block', border: '#fff 1px solid', marginRight: '0.5em', verticalAlign: 'middle', borderRadius: '4px',
};
export const sxChartTooltipLegendLine:SxProps = {
  width: '1em', height: '2px', display: 'inline-block', border: '2px solid', marginRight: '0.5em', verticalAlign: 'middle', borderRadius: '1px',
};
export const sxChartTooltipLegendDashedLine:SxProps = {
  width: '1em', height: '2px', display: 'inline-block', border: '2px dashed', marginRight: '0.5em', verticalAlign: 'middle', borderRadius: '1px',
};
export const sxChartTooltipLegendBoxTitle:SxProps = {
  fontWeight: 700, marginBottom: '8px',
};
export const sxChartHeader:SxProps = {
  display:'flex',flexDirection:'row',/* alignItems:'center', */gap:'8px',
  flexWrap: 'wrap', p:'8px', rowGap: '8px', flexShrink: 0,
  paddingBottom: '28px',
  '.MuiButtonGroup-root':{
    /* flex:1, */overflow:'auto',maxWidth:'100%',
    p: '8px', my: 0, mx: '-8px',
  },
  '.MuiButtonGroup-grouped': {
    // borderWidth: '1px',
    borderColor: 'primary.main',
    fontSize: '0.75rem',
    fontWeight: 700,
    flexShrink: 0,
  },
  [theme.breakpoints.down('tablet')]: {
    flexDirection:'column',
    // paddingTop: '28px',
  },
}
export const sxChartHeading:SxProps = {
  display:'block',
  fontSize:'1.25rem',
  flex:1,
}
export const sxTokenSwitcher:SxProps = {
  display:'flex',flexDirection:'row',
}
export const sxTokenSwitchItem:SxProps = {
  fontSize:'0.725rem',p:1,my:-1,border:0,//cursor:'pointer',mx:'-1px',border:'1px solid', borderColor:'secondary.light',
  // bgcolor:`${hslStrToHex(theme.palette.secondary.light)}44`,
  ":hover":{
    border:0,
  //   bgcolor:`${hslStrToHex(theme.palette.secondary.light)}88`,
  },
}
export const sxMarketFilterGroup:SxProps = {
  my: '1rem', mx: '6px',
};


export const sxChartTableContainer:SxProps = {
  p:2,
};
export const sxChartTable:SxProps = {
  // px: 2, py: '2px',
  bgcolor:'transparent',
  "thead":{
    my: 1,
    "tr":{
      whiteSpace: 'nowrap',
      // bgcolor:'background.paper',
    }
  },
  "th:first-of-type,td:first-of-type":{ paddingLeft:0 },
  "th:last-of-type,td:last-of-type":{ paddingRight:0 },
  "th,td":{
    px: 1, py: 0.5, m: 0,
    // bgcolor:'background.paper',
  },
  "td":{
    fontSize: '0.725rem',
    border: 'none',
    ":first-of-type":{
      // textAlign:'right',
    },
  },
  "th":{
    fontSize: '.725rem',
    border: 'none',
    borderTop: '1px solid',
    ":hover":{
      "th,td":{
        bgcolor:'action.hover',
      },
    },
  },
  "tbody tr:not(:hover)":{
    // bgcolor: 'background.paper',
  },
  "tbody tr.expandedChild:not(:hover) td":{
    bgcolor: 'dialog.light'
  },
  tableLayout:"auto",
  [theme.breakpoints.down('tablet')]:{
    // tableLayout: 'fixed',
    "th,td":{
      ":nth-of-type(n)":{
        p:0.5,
      },
    },
    "th":{
      fontSize: '.5rem',
    },
    "td":{ 
      fontSize: '1rem',
      ":before":{
        display:'none',
      }
    },
  },
};

export const sxChartWrapper:SxProps = {
  position:'relative',width:'100%',height:'100%',
};
export const sxChartPaddingWrapper:SxProps = {
  ...sxChartWrapper, paddingRight:'12px',paddingTop:'12px',
  display:'grid',alignItems:'center',
};
export const sxChartAboveBar:SxProps = {
  // my: '8px',
  marginTop: '-16px',
  position: 'absolute',
  right: '0',
  transform: 'translateY(-100%)',
  display:'flex',
  columnGap:'8px',
  [theme.breakpoints.down('tablet')]:{
    position: 'relative',
    overflow: 'auto',
    padding: '8px 0',
    marginTop: '8px',
  }
};

export const graphLineColors = [
  hslStrToHex(blueDark.blue9),
  hslStrToHex(tealDark.teal9),
  hslStrToHex(purpleDark.purple9),
  hslStrToHex(brownDark.brown9),
  hslStrToHex(tomatoDark.tomato9),
];
export const graphAreaColors = [
  hslStrToHex(blueDarkA.blueA8),
  hslStrToHex(tealDarkA.tealA8),
  hslStrToHex(purpleDarkA.purpleA8),
  hslStrToHex(brownDarkA.brownA8),
  hslStrToHex(tomatoDarkA.tomatoA8),
];

export const sxChartWatermark = {
  '::before': {
    content: '" "',
    display: 'block',
    position: 'absolute',	
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    opacity: '0.07',
    backgroundImage: 'url(/images/infinity_header_logo.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundSize: '50%'
  }
}

export const sxChartWatermarkAboveCenter: SxProps = {
  '::before': {
    ...sxChartWatermark['::before'],
    backgroundPosition: '58% 25%',
    backgroundSize: '45%'
  }
}

export const sxChartWatermarkCenter: SxProps = {
  '::before': {
    ...sxChartWatermark['::before'],
    backgroundPosition: '50% 45%',
    backgroundSize: '30%'
  }
}

export const sxChartWatermarkTopCenter: SxProps = {
  '::before': {
    ...sxChartWatermark['::before'],
    backgroundPosition: '55% 21%',
    backgroundSize: '30%'
  }
}
export const sxChartWatermarkTopCenterWide: SxProps = {
  '::before': {
    ...sxChartWatermark['::before'],
    backgroundPosition: '55% 28%',
    backgroundSize: '30%'
  }
}

export const sxChartWatermarkBottomRight: SxProps = {
  '::before': {
    ...sxChartWatermark['::before'],
    backgroundPosition: '99% 100%',
    backgroundSize: '15%'
  }
}