import { Box, Button } from '@mui/material';
import { SxProps } from '@mui/system';
import { useQueryClient } from "@tanstack/react-query";
import Decimal from 'decimal.js';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAuth } from 'src/AuthProvider';
import { useMarketRollingDateLabel } from 'src/Components/MarketRollingDateLabel';
import SwapMarketTradeForm from 'src/Components/Swaps/SwapMarketTradeForm';
import SwapSecondaryTradeForm from 'src/Components/Swaps/SwapSecondaryTradeForm';
import { getMarketByLeg, getTokenByLeg } from 'src/Components/Swaps/selectors';
import useSwpaFormValidation from 'src/Components/Swaps/useSwapFormValidation';
import TradeDialog from 'src/Components/TradeConfirmationDialog';
import withConnectWallet from 'src/Components/WithConnectWallet';
import useAppStore from 'src/store';
import { useSwapParameterStore } from 'src/store/useSwapParametersStore';
import { styleFormButton } from 'src/styles/Form';
import { styleGrid, styleHeader, stylePanelScrollable } from 'src/styles/GridBox';
import { ConfirmationDialog, EFormType, EOrderSide, ERateOrderType, ESwapLegType, ESwapTradeSideLabel, ETradeType, FormStateProps, SameCurrencyLegProps, SwapOrderFormParams, TradeParams } from 'src/types';
import apis from 'src/utils/apis';
import { generateClientOrderId, sortBy } from 'src/utils/common';
import { formatStartOfDayMarketDate } from 'src/utils/date';
import { format } from 'src/utils/numbers';

const sxSectionTitle:SxProps = {
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    fontWeight: '700'
}

const sxSection:SxProps = {
    maxWidth:'100%',
    mx: '1rem',
    py: '1rem',
    borderBottom: '2px solid',
    borderColor: 'dialog.light'
}

const SameCurrencySecondLeg: React.FC<SameCurrencyLegProps> = ({ primarySide = EOrderSide.LEND, legKey }) => {
    const side = primarySide === EOrderSide.LEND ? ESwapTradeSideLabel.PAY : ESwapTradeSideLabel.RECEIVE;
    const token = useSwapParameterStore(getTokenByLeg(legKey))
    const tokenCode =  token?.code

    return (<>
        <Box sx={sxSectionTitle}>Leg 2: Float</Box>
        <Box>{`${side} ${tokenCode} @ FLOAT`}</Box>
    </>)
}

const getSecondaryLeg = ({ tradeType, primarySide, quantity, exchangeRate }: { tradeType: ETradeType, primarySide: EOrderSide, quantity: string, exchangeRate: string }) => {
    switch (tradeType) {
        case ETradeType.INTEREST_RATE_SWAP:
            return <><SameCurrencySecondLeg primarySide={primarySide} legKey={ESwapLegType.SECONDARY}/></>
        case ETradeType.CROSS_CURRENCY_SWAP:
            return <SwapSecondaryTradeForm legKey={ESwapLegType.SECONDARY} quantity={quantity} exchangeRate={exchangeRate} primarySide={primarySide}/>
        default:
            return null
    }
}

const useSummaryParameters = (formState: any, market: any, marketInfo: any, orderBook: any, decimals: any) => {
    const getOrderRateDecimal = useCallback(()=>{
        // use next nearest appropriate rate in the orderbook if placing market order 
        const marketRateDecimal = new Decimal((marketInfo?.marketId===market?.marketId&&(marketInfo?.midRate||marketInfo?.rate||market?.rate))||0);
        const {askMinRate,bidMaxRate} = orderBook||{};
        const orderRateDecimal = (formState?.orderType===ERateOrderType.MARKET&&askMinRate&&bidMaxRate&&new Decimal(formState?.orderSide===EOrderSide.BORROW?askMinRate:bidMaxRate))||marketRateDecimal;
        return orderRateDecimal;
      },[market?.marketId,marketInfo,market?.rate, orderBook, formState]);

      const summaryParams = useMemo(
        () => {
            const orderRateDecimal = getOrderRateDecimal();
            const rateDecimal = new Decimal(formState?.rate || 0)
            const maxSlippageDecimal = new Decimal(formState?.maxSlippage ?? 0)
            // targetRate & estimatedSlippage: interate orderbook to find the estimated slippage rate (absolute)
            const quantityDecimal = new Decimal(formState?.quantity || 0);
            const {targetRate} = (formState?.orderType === ERateOrderType.MARKET && orderBook && (
                formState?.orderSide===EOrderSide.BORROW ? sortBy(orderBook.asks,'rate') : sortBy(orderBook.bids,'rate',true)
            ).reduce(
                ({depth,targetRate},entry:any)=>{
                if(quantityDecimal.gt(depth)){
                    targetRate = new Decimal(entry.rate);
                }
                depth = depth.add(new Decimal(entry.quantity||0));
                return {depth,targetRate};
                }
            ,{depth:new Decimal(0),targetRate:undefined} as {depth:Decimal,targetRate:Decimal|undefined}))||{};
            const maxSlippageRate = formState?.orderSide===EOrderSide.LEND ? rateDecimal.minus(maxSlippageDecimal) : rateDecimal.plus(maxSlippageDecimal)
            const { avgRate, fillAmount} = (formState?.orderType===ERateOrderType.MARKET&&orderBook&&(
                formState?.orderSide===EOrderSide.BORROW?sortBy(orderBook.asks,'rate'):sortBy(orderBook.bids,'rate',true)
            ).reduce(
                ({ avgRate, fillAmount, rateCount}, entry:any) => {
                const depth = new Decimal((formState?.orderSide === EOrderSide.LEND ? entry.borrowDepth : entry.lendDepth) ?? 0)
                if (formState?.orderSide === EOrderSide.LEND ? maxSlippageRate.lte(entry.rate) : maxSlippageRate.gte(entry.rate)) {
                    if (depth.lte(quantityDecimal)) {
                        return {
                        avgRate,
                        fillAmount: depth,
                        rateCount: rateCount.plus(new Decimal(entry.quantity).mul(entry.rate))
                        }
                    } else if (avgRate.eq(0)){
                        const finalRateCount = quantityDecimal.eq(0) ? quantityDecimal : rateCount.plus(quantityDecimal.minus(fillAmount).mul(entry.rate))
                        return {
                        avgRate: quantityDecimal.eq(0) ? avgRate : finalRateCount.dividedBy(quantityDecimal),
                        fillAmount: quantityDecimal,
                        rateCount: finalRateCount
                        }
                    } else {
                        return { avgRate, fillAmount, rateCount}
                    }
                } else {
                    return { avgRate: avgRate.eq(0) ? (fillAmount.eq(0) ? fillAmount : rateCount.dividedBy(fillAmount)) : avgRate, fillAmount, rateCount}
                }
            }, {avgRate: new Decimal(0), fillAmount: new Decimal(0), rateCount: new Decimal(0)}))||{};
            const adjustedRateDecimal = rateDecimal.add(maxSlippageDecimal.mul(formState?.orderSide===EOrderSide.BORROW ? 1 : -1)).toDP(decimals*100);

            return {
                orderRateDecimal,
                rateDecimal,
                maxSlippageDecimal,
                targetRate,
                estimatedSlippage: rateDecimal.minus(avgRate ?? 0).abs(),
                estimatedFillAmount: fillAmount,
                adjustedRateDecimal
            }
        }, [formState, orderBook, getOrderRateDecimal, decimals]
      )

      return summaryParams
}

function SwapOrderForm({ tradeType}: SwapOrderFormParams){
    const primaryToken = useSwapParameterStore(getTokenByLeg("leg1"))
    const secondaryToken = useSwapParameterStore(getTokenByLeg("leg2"))
    const primaryMarket = useSwapParameterStore(getMarketByLeg("leg1"))
    const secondaryMarket = useSwapParameterStore(getMarketByLeg("leg2"))
    const {data:markets} = apis.rate.useMarkets();
    const {data:marketInfo} = apis.rate.useMarketInfo({ market: primaryMarket });
    const {data:secondaryMarketInfo} = apis.rate.useMarketInfo({ market: secondaryMarket });
    const {data:primaryOrderBook} = apis.rate.useRateOrderBook(primaryMarket);
    const {data:secondaryOrderBook} = apis.rate.useRateOrderBook(secondaryMarket);
    const dateLabel = useMarketRollingDateLabel({ market: primaryMarket })
    const [formState, setFormState] = useState<FormStateProps | undefined>();
    const [isValidInput, setIsValidInput] = useState(false);
    const confirmationDialogRef = useRef<ConfirmationDialog | null>(null)
    const quantityStep = primaryMarket?.quantityStep||'0';
    const decimals = new Decimal(quantityStep).decimalPlaces()
    const summaryParams = useSummaryParameters(formState, primaryMarket, marketInfo, primaryOrderBook, decimals);
    const getExchangeRate = useSwapParameterStore((state) => state.getExchangeRate)
    const { bestAsk, bestBid } = useSwapParameterStore((state) => state.mergedOrderBook)
    const primaryFormRef = useRef<any>(null)

    /**
     * general hooks
     */
    const auth = useAuth();
    const queryClient = useQueryClient();
    const { currentUserAccountId: currentUserAccountId } = useAppStore();

    /**
     * api data
     */
    const { data:userAccounts } = apis.user.useUserAccounts(auth);
    const tradeAccount = userAccounts&&apis.user.extractUserAccount(userAccounts,currentUserAccountId);
    const primaryTradeTokenAccount = primaryMarket&&tradeAccount&&apis.user.extractAccountTokenAccountFromAccount(tradeAccount, primaryMarket.tokenId);
    const secondaryTradeTokenAccount = secondaryMarket&&tradeAccount&&apis.user.extractAccountTokenAccountFromAccount(tradeAccount, secondaryMarket.tokenId);
    
    const { hasEnoughAsset } = useSwpaFormValidation({ primaryMarket, primaryToken, secondaryMarket, secondaryToken, primaryTradeTokenAccount, secondaryTradeTokenAccount, tradeAccount })

    const isFixedRate = !(primaryMarket?.daysToMaturity===undefined);
    const isSecondaryFixedRate = !(secondaryMarket?.daysToMaturity===undefined);
    const exchangeRate = getExchangeRate()

    const getSecondaryQuantity = useCallback(
        (quantity?: string) => {
            return quantity ? new Decimal(quantity).mul(exchangeRate).toFixed(decimals): new Decimal(0).toFixed(decimals)
        }, [exchangeRate, decimals]
    );

    useEffect(
        () => {
            if (tradeType) {
                setFormState({
                    orderSide: EOrderSide.LEND,
                    orderType: ERateOrderType.MARKET,
                    quantity: '',
                    rate: undefined,
                    maxSlippage: undefined,
                })
            }
        }, [tradeType]
    )

    const secondaryOrderRateDecimal = useMemo(()=>{
        // use next nearest appropriate rate in the orderbook if placing market order 
        const marketRateDecimal = new Decimal((secondaryMarketInfo?.marketId === secondaryMarket?.marketId&&(secondaryMarketInfo?.midRate||secondaryMarketInfo?.rate||secondaryMarket?.rate))||0);
        const {askMinRate,bidMaxRate} = secondaryOrderBook||{};
        const orderRateDecimal = (bidMaxRate&&askMinRate&&new Decimal(formState?.orderSide === EOrderSide.BORROW ? bidMaxRate : askMinRate)) || marketRateDecimal;
        return orderRateDecimal;
      },[secondaryMarketInfo, secondaryOrderBook, formState, secondaryMarket?.marketId, secondaryMarket?.rate]);

    const sideLabel = formState?.orderSide === EOrderSide.LEND ? ESwapTradeSideLabel.RECEIVE : ESwapTradeSideLabel.PAY;
    const secondarySideLabel = formState?.orderSide === EOrderSide.LEND ? ESwapTradeSideLabel.PAY : ESwapTradeSideLabel.RECEIVE;
    const summaryRate = (formState?.orderType === ERateOrderType.MARKET && !summaryParams.maxSlippageDecimal.eq(0)) ?
        `${format(summaryParams.orderRateDecimal.mul(100),2)}%` :
        `${format(summaryParams.rateDecimal.mul(100),2)}%`;
    const primaryRateText = `${sideLabel} ${format(formState?.quantity||0)} ${primaryToken?.code} @ ${summaryRate} ${isFixedRate ? 'Fixed' : 'Floating'}`;
    const secondarySideRateText = tradeType === ETradeType.INTEREST_RATE_SWAP ? `${format(formState?.quantity||0)} ${primaryToken?.code} @ FLOAT` : `${format(getSecondaryQuantity(formState?.quantity), 2)} ${secondaryToken?.code} @ ${isSecondaryFixedRate ? `${format(secondaryOrderRateDecimal.mul(100),2)}% Fixed` : 'FLOAT'}`
    const secondarySideSummaryText = `${secondarySideLabel} ${secondarySideRateText}`
    const maturityDateText = ` ${isFixedRate ? ` for ${dateLabel} ${formatStartOfDayMarketDate(primaryMarket?.maturityDate ?? 0)} ` : ''}`;
    const rateWithSlippageLabel = formState?.orderType === ERateOrderType.LIMIT ? 'Rate' : (
        formState?.orderSide === EOrderSide.BORROW ? 'Max Rate' : 'Min Rate'
    );
    const mergedRateText = `for a net rate of approx. ${formState?.orderSide===EOrderSide.LEND ? format(new Decimal(bestBid || 0).mul(100),2): format(new Decimal(bestAsk || 0).mul(100),2)}%`;
    const slippageText = ((formState?.orderType === ERateOrderType.MARKET && !summaryParams.maxSlippageDecimal.eq(0)) || summaryParams.estimatedSlippage?.gt(0)) &&
        `(${rateWithSlippageLabel}: ${format(summaryParams.adjustedRateDecimal.mul(100),2)}%${summaryParams.estimatedSlippage?.gt(0)?`, Estimated Slippage: ${format(summaryParams.estimatedSlippage.mul(100),2)}%`:''})`

    const summaryText = `${primaryRateText}${tradeType === ETradeType.INTEREST_RATE_SWAP ? maturityDateText : ''} ${slippageText} vs ${secondarySideSummaryText}${tradeType === ETradeType.CROSS_CURRENCY_SWAP ? maturityDateText : ''} ${(tradeType === ETradeType.CROSS_CURRENCY_SWAP && isSecondaryFixedRate) ? mergedRateText: ''}`
    const onTradeClick = useCallback(
        () => {
            if(!formState) return;
            const { quantity, rate, orderSide, orderType} = formState;
            if(!primaryMarket||!tradeAccount
                ||!quantity
                ||!rate||new Decimal(rate)?.lt(0)
              ) return;
              const tradeParams: TradeParams = {
                primaryLeg: {
                    marketId: primaryMarket.marketId,
                    accountId: tradeAccount.accountId,
                    side: orderSide, //  1 for borrow, 0 for lend
                    orderType: orderType, //  1 for market order, 2 for limit order
                    quantity: quantity,
                    maxSlippage: summaryParams.maxSlippageDecimal,
                    orderRate: summaryParams.orderRateDecimal,
                    targetRate: summaryParams.targetRate!,
                    estimatedSlippage: summaryParams.estimatedSlippage,
                    estimatedFillAmount: summaryParams.estimatedFillAmount,
                    summaryRate,
                    clientOrderId: generateClientOrderId(),
                },
                secondaryLeg: {
                    marketId: secondaryMarket!.marketId,
                    accountId: tradeAccount.accountId,
                    side: orderSide === EOrderSide.LEND ? EOrderSide.BORROW : EOrderSide.LEND,
                    orderType: orderType,
                    quantity: tradeType === ETradeType.INTEREST_RATE_SWAP ? quantity : getSecondaryQuantity(quantity),
                    maxSlippage: summaryParams.maxSlippageDecimal,
                    orderRate: secondaryOrderRateDecimal,
                    targetRate: new Decimal(secondaryMarket?.rate||0),
                    clientOrderId: generateClientOrderId(),
                }
              };
              primaryToken && primaryMarket && confirmationDialogRef?.current?.openDialog({ tradeType, tradeParams, primaryToken, primaryMarket, secondaryToken, secondaryMarket, summaryText})
        }, [formState, primaryMarket, tradeAccount, summaryText, summaryParams, primaryToken, summaryRate, tradeType, secondaryOrderRateDecimal, getSecondaryQuantity, secondaryToken, secondaryMarket]
    )

    const handleSuccessOrder = () => {
        // reset form value
        primaryFormRef?.current?.resetForm();
        queryClient.invalidateQueries({queryKey:['user']});
        queryClient.invalidateQueries({queryKey:['market']});
      };

    const onFormStateChange = (formState: FormStateProps) => {
        setFormState(formState)
        const isPrimaryValid = hasEnoughAsset(formState, EFormType.PRIMARY)
        const isSecondaryValid = tradeType === ETradeType.INTEREST_RATE_SWAP ? hasEnoughAsset({...formState, orderSide: formState.orderSide === EOrderSide.BORROW ? EOrderSide.LEND : EOrderSide.BORROW }, EFormType.PRIMARY) : hasEnoughAsset({...formState, quantity: getSecondaryQuantity(formState.quantity), orderSide: formState.orderSide === EOrderSide.BORROW ? EOrderSide.LEND : EOrderSide.BORROW }, EFormType.SECONDARY)
        setIsValidInput(isPrimaryValid && isSecondaryValid)
    }

    return (
        <Box sx={styleGrid} style={{flex:1, marginBottom: '10px'}}>
            <Box sx={styleHeader}>Interest Rate Swap Order Form</Box>
            {/* // TODO add maturity option on select callback */}
            <Box sx={{...stylePanelScrollable, flexDirection: 'column' }}>
                <Box sx={sxSection}>
                    <Box sx={sxSectionTitle}>Leg 1: Fixed {tradeType === ETradeType.CROSS_CURRENCY_SWAP ? `(${formState?.orderSide === EOrderSide.LEND ? ESwapTradeSideLabel.RECEIVE : ESwapTradeSideLabel.PAY})` : ''}</Box>
                    { primaryToken && 
                        <SwapMarketTradeForm
                            tradeType={tradeType}
                            token={primaryToken}
                            marketId={primaryMarket?.marketId}
                            onFormStateChange={onFormStateChange}
                            estimatedSlippage={summaryParams.estimatedSlippage}
                            ref={primaryFormRef}
                        />
                    }
                </Box>
                <Box sx={sxSection}>
                    {getSecondaryLeg({ tradeType, primarySide: formState?.orderSide ?? EOrderSide.LEND, quantity: getSecondaryQuantity(formState?.quantity), exchangeRate })}
                </Box>
                <Box sx={{...sxSection, borderBottom: '0'}}>
                    <Box sx={sxSectionTitle}>Summary</Box>
                    <Box>{summaryText}</Box>
                </Box>
                <Button sx={{...styleFormButton, maxWidth: '100%', mx: '1rem'}}
                    disabled={(!primaryMarket||!isValidInput)}
                    onClick={onTradeClick}>
                    Trade 2 Legs
                </Button>
                <TradeDialog ref={confirmationDialogRef} onSuccess={handleSuccessOrder}/>
        </Box>
        </Box>
    );
}

export default withConnectWallet(SwapOrderForm);
