import apis from 'src/utils/apis';
import { EMarketType, ERateOrderStatus, EUserAccountType, FixedRateOrder, RateOrder, Token, UserAccount, TradeHistoryItem, OrderTableHeadCell } from 'src/types';
import { TRADE_HISTORY_TABLE_PAGE_LIMIT } from 'src/constants/app';
import { useAuth } from 'src/AuthProvider';
import { useMemo } from 'react';
import { 
    CancelOrdersSnackbar,
    ConfirmationDialogElement,
    mapFixedRateOrderHistoryItem, 
    mapFloatRateOrderHistoryItem,
    TradeHistoryTableHead,
    TradeHistoryTableRow,
    useRowSelection,
    usePagination
} from 'src/Components/TradeHistoryTable'

type PageLastOrderId = {
    floatOrderId?: number;
    fixedOrderId?: number;
}

export const useUserTradeHistory = ({
    type = 'history',
    getCurrentLastOrderIds,
    updateLastOrderIds
}: {
    type?: 'open' | 'history',
    getCurrentLastOrderIds: (marketKey: string) => PageLastOrderId,
    updateLastOrderIds: (orders: TradeHistoryItem[], marketKey: string, hasNextPage: boolean, cutoffFloatingOrderId?: number, cutoffFixedOrderId?: number) => void
}) => {
    const auth = useAuth();
    const {data:tokens} = apis.token.useTokens();
    const {data:markets} = apis.rate.useRateMarkets();
    const {isLoading:isLoadingUserAccounts,data:userAccounts} = apis.user.useUserAccounts(auth);
    const tradingAccount = userAccounts?.filter(uw=>uw.type==EUserAccountType.TRADING)[0];
    const { accountId } = tradingAccount||{};
    const currentLastOrderIds = useMemo(
        () => {
            return getCurrentLastOrderIds(`allOrder-${type}`)
        }, [getCurrentLastOrderIds, type]
    )
    const {isInitialLoading:isInitialLoadingAllRateOrders,isFetching:isFetchingAllRateOrders,data:allRateOrdersData} = apis.user.useUserAllRateOrders(auth,{
      accountId,pending: type === 'open',limit:TRADE_HISTORY_TABLE_PAGE_LIMIT,
      cutoffFloatingOrderId: currentLastOrderIds.floatOrderId, 
      cutoffFixedOrderId: currentLastOrderIds.fixedOrderId
    });


    const isLoadingTable = useMemo(
        () => {
          return isInitialLoadingAllRateOrders || isLoadingUserAccounts
        }, [isInitialLoadingAllRateOrders, isLoadingUserAccounts]
    )
    
    // table items
    const orderHistoryItems:TradeHistoryItem[] = useMemo(() => {
        const items:TradeHistoryItem[] = []
        const { orders, hasNextPage, cutoffFloatingOrderId, cutoffFixedOrderId } = allRateOrdersData ?? {}
        if (orders && orders.length > 0) {
          orders?.map((order: any) =>{
            switch(order.marketType){
                case EMarketType.FLOATING: {
                const rateOrder = { ...order, orderId: order.floatingOrderId } as RateOrder
                const orderHistoryItem = mapFloatRateOrderHistoryItem(rateOrder, markets)
                if (orderHistoryItem) items.push(orderHistoryItem)
                break;
                }
                case EMarketType.FIXED: {
                const fixedRateOrder = { ...order, orderId: order.fixedOrderId } as FixedRateOrder
                items.push(mapFixedRateOrderHistoryItem(fixedRateOrder, tokens, markets))
                break;
                }
            }
        });
        
        updateLastOrderIds(items, `allOrder-${type}`, !!hasNextPage, cutoffFloatingOrderId, cutoffFixedOrderId)
        items.sort((a,b)=>a.orderDate>b.orderDate?-1:a.orderDate<b.orderDate?1:0);
      }
      return items
    }, [allRateOrdersData, markets, tokens, type, updateLastOrderIds])

    return {
        isLoadingTable,
        isFetchingUpdates: isFetchingAllRateOrders,
        orderHistoryItems
    }
}