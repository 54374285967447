import {
  Box, SxProps
} from '@mui/material';
import { Decimal } from 'decimal.js';
import { format } from 'src/utils/numbers';

function HealthscoreValue({
  value,fontSize,sx={},
}:{
  value?:number,fontSize?:string,sx?:SxProps,
}){
  if(value===undefined) return <>-</>;
  const valueDecimal = new Decimal(value||0).mul(100);
  const signum = valueDecimal.gte(0)?1:-1;
  if((valueDecimal.lt(100))){
    sx = {...sx, color:'error.main'} 
  }else if(valueDecimal.lt(200)){
    sx = {...sx, color:'warning.main'};
  }else{
    sx = {...sx, color:'success.main'};
  }
  if(fontSize){
    sx = {...sx,fontSize};
  }
  const valueText = format(valueDecimal.abs(),0);
  return (
    <Box sx={sx}>{signum===1?'':'-'}{valueText}%</Box>
  )
}

export default HealthscoreValue;