import { Close, Summarize } from '@mui/icons-material';
import { Box, Fab, IconButton, Link, Popover, SxProps, Tooltip } from '@mui/material';
import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from 'src/AuthProvider';
import { sxDialogCloseButton, sxDialogPanel, sxDialogPaper, sxDialogTitleCompact } from 'src/styles/Dialog';
import { sxMenuModalRoot, sxPrimaryText } from 'src/styles/General';
import { paths } from 'src/utils/paths';
import { FOOTER_HEIGHT } from './Footer';

const sxFabWrapper:SxProps = {
  position:'fixed',
  bottom: '1rem',
  right: '1rem',
  marginBottom: FOOTER_HEIGHT,
};
const sxTipsIcon:SxProps = {
  "&.material-symbols-outlined": {
    position: 'absolute',
    left: '12px',
    top: '12px',
    cursor: 'default',
    fontSize: '16px',
  }
};
const sxPopoverAnchor:SxProps = {
  position: 'absolute',
  bottom: '100%', right: 0,
  marginBottom: '8px',
};
const sxPopover:SxProps = {
  ...sxDialogPaper,
  width: '320px',
};
const sxPopoverTitle:SxProps = {
  ...sxDialogTitleCompact,
  position:'relative', paddingLeft:'2rem',
};
const sxOrderedList:SxProps = {
  paddingLeft: '2rem',
  // position: 'relative',
  "li":{
    // position: 'relative',
    counterIncrement: 'li',
    ":not(:last-of-type)":{
      marginBottom: '1rem',
    },
    "::marker": {
      content: '""',
    },
    "::before": {
      display: 'inline-block',
      position: 'absolute',
      transform: 'translate(-2rem,-0.25rem)',
      borderRadius: '50%',
      color:'text.primary',
      bgcolor:'primary.dark',
      content: 'counter(li)',
      lineHeight: '1.5rem',
      width: '1.5rem',
      textAlign:'center',
    },
    "&[data-checked=true]":{
      textDecoration: 'line-through',
      "::before": {
        content: '"\\2713"',
        bgcolor:'success.dark',
      }
    }
  },
};

function QuickStartTipsFab() {
  const anchorRef = useRef<HTMLElement>(null);
  const [open,setOpen] = useState(false);
  const auth = useAuth();
  return (<Box sx={sxFabWrapper}>
    <Tooltip title="Quickstart Tips"><Fab color="primary" aria-label="Quickstart Tips" aria-describedby='quick-start-tips' onClick={()=>setOpen(!open)}><Box component={'span'} className="material-symbols-outlined">quick_reference</Box></Fab></Tooltip>
    <Box sx={sxPopoverAnchor} ref={anchorRef}></Box>
    <Popover
      id="quick-start-tips"
      anchorEl={anchorRef.current}
      open={open}
      onClose={()=>setOpen(false)}
      sx={sxMenuModalRoot}
      anchorOrigin={{vertical:'top',horizontal:'right'}}
      transformOrigin={{vertical:'bottom',horizontal:'right'}}
      PaperProps={{
        sx: sxPopover,
      }}
    >
      <Box sx={sxPopoverTitle}>
        <Box sx={sxTipsIcon} component={'span'} className="material-symbols-outlined">quick_reference</Box>&nbsp;Quickstart Tips
        <IconButton aria-label="close" onClick={()=>setOpen(false)} sx={sxDialogCloseButton}><Close/></IconButton>
      </Box>
      <Box sx={sxDialogPanel}>
        <Box>New to Infinity? Here are a few quick tips to get going:</Box>
        <Box component="ol" sx={sxOrderedList}>
          <Box component="li" data-checked={!!(auth&&auth.user)}>Connect Wallet at the top right header</Box>
          <Box component="li">Go to {!!(auth&&auth.user) ? <Link component={RouterLink} sx={sxPrimaryText} to={paths.portfolioPage('accounts')}>Accounts</Link> : 'Accounts' }</Box>
          <Box component="li">Transfer tokens from your Wallet to your Infinity Trading Account</Box>
          <Box component="li">Start lending &amp; borrowing tokens!<br/><Link component={RouterLink} sx={sxPrimaryText} to={paths.lendAndBorrow()}>Go to Lend &amp; Borrow</Link></Box>
        </Box>
      </Box>
    </Popover>
  </Box>);
}

export default QuickStartTipsFab;
