import { Box } from '@mui/material';
import { Link } from "react-router-dom";

function InfinityLogo() {
  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontFamily: 'PT Mono',
      fontWeight: 400,
      "a": {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        px: 0,
        textDecoration: 'none',
      }
    }}>
      <Link to="/">
        <Box sx={{backgroundImage:`url('/images/infinity_header_logo.png')`,backgroundSize:'contain',backgroundPosition:'center',backgroundRepeat:'no-repeat',width:'110px',height:'24px'}}/>
        {/* <Box sx={{fontSize:'30px',fontWeight:400,marginTop:'1px',marginRight:'4px'}}>∞</Box>Infinity Pool */}
      </Link>
    </Box>
  );
}

export default InfinityLogo;
