import { useQueryClient } from "@tanstack/react-query";
import { filter } from "lodash"
import { useCallback, useMemo, useState } from "react"
import { TradeHistoryItem } from "src/types"

export const useRowSelection = (orderHistoryItems:TradeHistoryItem[]) => {
    const queryClient = useQueryClient();
    const [selectedRows, setSelectedRows] = useState<{[key: number]: boolean}>({})

    const onRowSelectionChange = useCallback(
        (orderId: number) => () => {
          setSelectedRows({
            ...selectedRows,
            [orderId]: !selectedRows[orderId]
          })
        }, [selectedRows, setSelectedRows]
    )

    const onHeaderSelectionChange = (currentSelection: 'checked' | 'partial' | 'none') => {
        if (['checked', 'partial'].includes(currentSelection)) {
          setSelectedRows({})
        } else if (currentSelection === 'none') {
          setSelectedRows(orderHistoryItems.reduce((rows, item) => {
            return {
              ...rows,
              [item?.orderId ?? 0]: true
            }
          }, {}))
        }
    }

    const selectedOrders = useMemo(() => {
        return Object.keys(selectedRows).reduce((orders, orderId) => {
          if (!!selectedRows[orderId]) {
            const item = orderHistoryItems.find(o => o?.orderId === Number(orderId))
            if (item) orders.push(item)
          }
          return orders
        }, [] as TradeHistoryItem[])
    }, [selectedRows, orderHistoryItems])

    const onCancelOrderSuccess = useCallback(
        () => {
          setSelectedRows({})
          queryClient.invalidateQueries({queryKey:['user']});
          queryClient.invalidateQueries({queryKey:['market']});
        }, [queryClient]
    )

    const handleCancelOrderModalClose = () => {
        setSelectedRows({})
    }

    const selectionStatus: 'checked' | 'partial' | 'none' = useMemo(() => {
        let isAllRowSelected = false
        let isSomeRowSelected = false
        const selectedOrderIds = filter(selectedRows, (value, key) => value === true)
        if (selectedOrderIds.length === orderHistoryItems.length && selectedOrderIds.length > 0) {
            isAllRowSelected = true 
        } else if (selectedOrderIds.length > 0) {
            isSomeRowSelected = true
        }

        return isAllRowSelected ? 'checked' : (isSomeRowSelected ? 'partial' : 'none')
    }, [selectedRows, orderHistoryItems])

    const isRowSelected = useCallback(
        (orderId: number) => {
            return !!selectedRows[orderId]
        }, [selectedRows]
    )

    return {
        onRowSelectionChange,
        onHeaderSelectionChange,
        selectedOrders,
        onCancelOrderSuccess,
        handleCancelOrderModalClose,
        selectionStatus,
        isRowSelected
    }
}
