import {
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@mui/material';
import { SxProps } from '@mui/system';
import Decimal from 'decimal.js';
import ValueTableCell from 'src/Components/ValueTableCell';
import { styleFormLabel } from 'src/styles/Form';
import theme from 'src/utils/theme';
import { styleBoxBlock, styleBoxPanel, styleTable, styleTableContainer, sxBox } from '../styles/General';
import { BaseMarket, Token } from '../types';
import apis from '../utils/apis';
import { dps, format, formatN } from '../utils/numbers';


const StyleBox:SxProps = {
  display: 'flex',
  flexDirection: 'row',
  my: 2,
}
const sxDepthTable:SxProps = {
  mx:1, my: 2, width: '100%',
  "th,td": {
    textAlign: 'left',
  }
}
const sxDepthGraph:SxProps = {
  height: '200px',
  width: '100%',
  px: 2,
  marginTop: '2rem', // for hover rate labels
  paddingTop: '2rem', // for hover rate labels
  display:'flex',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  position: 'relative',
  ":after":{
    content:'""', display: 'block', position: 'absolute', left: 0, right: 0, bottom: '24px',
    borderWidth: 0, borderBottomWidth:'1px', borderStyle: 'solid', borderColor: '#ffffff',
  }
}
const sxBarContainer:SxProps = {
  position: 'relative',
  ":not(:hover)":{
    opacity: .5,
  },
  ":not(:hover) label":{
    opacity: 0,
  },
}
const sxBarContainerMP:SxProps = {
  ":not(:hover) label:first-of-type, :not(:hover) label:last-of-type": {
    opacity: 1,
  }
}
const sxBarContainerShowsRate:SxProps = {
  ":not(:hover) label:last-of-type": {
    opacity: 1,
  }
}
const sxBar:SxProps = {
  position: 'relative', height: 'calc(100% - 36px)', width: '100%', marginTop: '12px', marginBottom: '12px',
};
const sxBarInner:SxProps = {
  position: 'absolute', zIndex: 1,
  bottom: 0, width: '100%',
}
const sxLendBar:SxProps = {
  bgcolor: 'negative.main',
};
const sxBorrowBar:SxProps = {
  bgcolor: 'positive.main',
};
const sxBarDash:SxProps = {
  position: 'absolute', zIndex: 0,
  top: 0, left: '50%', height: '50%', 
  transformOrigin: 'top', transform: 'scaleY(2)',
  marginLeft: '-1px',
  borderStyle: 'dashed',
  borderWidth: '1px',
  borderColor: '#ffffff',
}
const sxBarLabel:SxProps = {
  position: 'absolute', whiteSpace: 'nowrap', textAlign: 'center',
  left: '50%', transform: 'translateX(-50%)', fontSize: '.75rem',
};
const sxBarTopLabel:SxProps = {
  bottom:'100%',
};
const sxBarBottomLabel:SxProps = {
  top:'100%',
};
const sxMinimal:SxProps = {
  display: 'flex', flexDirection: 'row',
  ".MuiTableContainer-root":{
    width:'calc(100% / 2 - 4px)',
    p:0,
    "& + .MuiTableContainer-root":{
      marginLeft: 1,
    },
    "table":{
    },
    "tr,td,th":{
      bgcolor:'transparent',
      fontSize: '0.75rem',
      p: 0.25,
    }
  },
  [theme.breakpoints.down('tablet')]: {
    flexDirection:'column',
    ".MuiTableContainer-root":{
      width:'100%',
      "& + .MuiTableContainer-root":{
        marginLeft: 0,
      },
    },
  },
};


function MarketDepthGraph({
  market,token,barWidth='1em',showOrderBook=false,showTitle=false,style='box'
}:{
  market?:BaseMarket,token?:Token,barWidth?:string,showOrderBook?:boolean,showTitle?:boolean,style?:'box'|'block'|'minimal'
}){
  const {isLoading,data} = apis.rate.useRateOrderBook(market);
  const { depths } = data||{};
  const stepDecimal = dps(new Decimal(market?.rateStep||0).mul(100).toNumber());
  const bids = depths?.filter(d=>!!d.borrowDepth).sort((a,b)=>new Decimal(a.rate).comparedTo(new Decimal(b.rate)))||[];
  const asks = depths?.filter(d=>!!d.lendDepth).sort((a,b)=>new Decimal(a.rate).comparedTo(new Decimal(b.rate)))||[];
  const barMax = depths?.reduce((m,d)=>Decimal.max(new Decimal(d.quantity),m),new Decimal(0))||1;
  const bars = [...bids,...asks];
  //const bidsTotal = bids.reduce((sum,b)=>sum.add(new Decimal(b.quantity)),new Decimal(0));
  //const asksTotal = asks.reduce((sum,b)=>sum.add(new Decimal(b.quantity)),new Decimal(0));
  const getTable = (title:string,array:typeof depths)=>{
    const table = (
    <TableContainer sx={styleTableContainer}>
      <Table sx={styleTable}>
        <TableHead>
          <TableRow hover><TableCell>{style=='minimal'?title:'RATE'}</TableCell><TableCell>SIZE</TableCell></TableRow>
        </TableHead>
        <TableBody>
          {array?.map(({rate,quantity},idx)=>{
            const rateFormatted = new Decimal(rate).mul(100).toFixed(stepDecimal);
            return (
            <TableRow hover key={`borrow_${idx}`}>
              <ValueTableCell mono label="Rate" hideOnMobile={false}>{rateFormatted}%</ValueTableCell>
              <ValueTableCell mono label={`${rate}%`} width="100%">
                {format(new Decimal(quantity).toNumber(),2)}
                {style!='minimal'&&<Box sx={styleFormLabel}>(${format(new Decimal(quantity).mul(token?.price||0).toNumber(),2)})</Box>}
              </ValueTableCell>
            </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
    );
    if(style=='minimal') return table;
    return (
      <Box sx={sxDepthTable}>
        <Box>{title}</Box>
        {table}
      </Box>
    )
  };
  
  let accumulatedLends = new Decimal(0);
  let accumulatedBorrows = bids.reduce((sum,b)=>sum.add(new Decimal(b.quantity)),new Decimal(0));
  if(style=='minimal'){
    return (<Box sx={sxMinimal}>
      {getTable('BIDS (BORROW)',bids.slice(-10).reverse())} {getTable('OFFERS (LEND)',asks.slice(0,10))}
    </Box>);
  }
  return (
    <Box sx={style=='block'?styleBoxBlock:sxBox}>
      <Box sx={{...styleBoxPanel,height:'100%',alignItems:'stretch',overflow:'hidden'}}>
        {showTitle ? <Typography variant="h1">Orderbook</Typography> : null}
        <Box sx={sxDepthGraph}>
          {bars.map((d,idx)=>{
            const {rate,lendDepth,quantity} = d;
            const barHeight = (new Decimal(quantity)).div(barMax).mul(100);
            const sxBarInnerColor = lendDepth?sxLendBar:sxBorrowBar;
            const label = lendDepth?'BORROW':'LEND';
            //const total = lendDepth?asksTotal:bidsTotal;
            let total;
            if(lendDepth){
	          total = accumulatedLends = accumulatedLends.add(new Decimal(quantity));
            } else {
	          total = accumulatedBorrows;
	          accumulatedBorrows = accumulatedBorrows.sub(new Decimal(quantity));
            }
            const showsRate = idx%Math.round(bars.length/5)==0; // shows n+1 rates
            const isMarketRate = rate==market?.rate;
            
            return (
              <Box key={idx} sx={{...sxBarContainer,...isMarketRate?sxBarContainerMP:{},...showsRate?sxBarContainerShowsRate:{}, maxWidth: barWidth, flex: 1, px: '1px' } as SxProps}>
                <Box sx={sxBar}><Box sx={{...sxBarInner,...sxBarInnerColor,height:`${barHeight}%`} as SxProps}></Box></Box>
                <Box component="label" sx={sxBarDash}></Box>
                <Box component="label" sx={{...sxBarLabel,...sxBarTopLabel} as SxProps}>
                  <Box>
                    {formatN(new Decimal(quantity),2)}&nbsp;
                    <Box sx={{opacity:0.5,display:'inline'}}>(${formatN(new Decimal(quantity).mul(token?.price||0),2)})</Box>
                  </Box>
                  <Box>
                    {formatN(total,2)}&nbsp;
                    <Box sx={{opacity:0.5,display:'inline'}}>(${formatN(total.mul(token?.price||0),2)})</Box>
                  </Box>
                  <Box sx={{opacity:0.5}}>{label} AVAILABLE</Box>
                </Box>
                <Box component="label" sx={{...sxBarLabel,...sxBarBottomLabel} as SxProps}>
                  {new Decimal(rate).mul(100).toFixed(stepDecimal)}%
                </Box>
              </Box>
            );
          })}
        </Box>
        {showOrderBook&&
        <Box sx={StyleBox}>
          {getTable('BID',bids.slice(-10).reverse())} {getTable('OFFER',asks.slice(0,10))}
        </Box>
        }
      </Box>
    </Box>
  );
}

export default MarketDepthGraph;