// TODO add zod typeguard
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { MARKETS_PAGE_TOKENS } from 'src/constants/app';
import {
  BaseMarket,
  Token
} from '../../types';
import { get } from './common';
import { useCallback } from "react";

/**
 * fetch tokens
 * */
export function usePrefetchMarketsPageTokens(offset=0,limit=1000) {
  return;
  // DEV prefetch doesnt respect retry config - sunset this
  // useQueryClient().prefetchQuery({
  //   queryKey: ['tokens',],
  //   queryFn:async()=>await _fetchTokens(),
  //   retry: false,
  // });
}
export function useMarketsPageTokens() {
  const ret = useQuery(['tokens'],async()=>await _fetchTokens(),{});
  return {...ret,data:ret.data?.filter(t=>MARKETS_PAGE_TOKENS.indexOf(t.code)>=0)}
}
export function useTokens() {
  const updateTokensPrice = useUpdateTokensPriceCallback();
  return useQuery(['tokens'],async()=>{
    const tokens = await _fetchTokens();
    // also update token price with positions.price before 
    const tokenIdPriceMap = new Map<number,string>();
    tokens?.forEach(token=>{
      const {tokenId, price} = token;
      tokenIdPriceMap.set(tokenId,price);
    });
    updateTokensPrice(tokenIdPriceMap);
    return tokens;
  },{
    // refresh every 10s for updated token price
    staleTime: 10000, refetchInterval: 10000,
  });
}
async function _fetchTokens():Promise<Token[]> {
  // const { data } = await get(`/api/p/tokens/404`, {
  const { data } = await get(`/api/p/tokens`, {});
  const { tokens } = data;
  return tokens;
}

function useUpdateTokensPriceCallback(){
  const queryClient = useQueryClient();
  return useCallback((tokenIdPriceMap:Map<number,string>)=>{
    const updateTokenPrice = (tokenIdPriceMap:Map<number,string>,token:Token)=>{
      const newPrice = tokenIdPriceMap.get(token.tokenId);
      if(newPrice&&newPrice!==token.price){
        // console.log(`updateTokenPrice tokenId ${token.tokenId} price ${token.price}->${newPrice}`);
        return {...token, price: newPrice};
      }
      return token;
    };
    // queryClient.setQueryData<Token[]>(['tokens'], tokens=>tokens?.map(token=>updateTokenPrice(tokenIdPriceMap,token)));
    queryClient.setQueryData<BaseMarket[]>(['market',{category:'all',action:'list'}], markets=>markets?.map(market=>{
      return {...market,token:market.token && updateTokenPrice(tokenIdPriceMap,market.token)};
    }));
  },[queryClient]);
}


/**
 * fetch token
 * */
export function useToken({tokenId}:{tokenId?:number}) {
  const updateTokensPrice = useUpdateTokensPriceCallback();
  return useQuery(['token',{action:'get',tokenId}],async()=>{
    if(tokenId===undefined) return null as unknown as Token;
    const token = await fetchToken({tokenId})
    const tokenIdPriceMap = new Map<number,string>();
    tokenIdPriceMap.set(token.tokenId,token.price);
    updateTokensPrice(tokenIdPriceMap);
    return token;
  },{enabled:tokenId!==undefined});
}
export async function fetchToken({tokenId}:{tokenId:number}):Promise<Token> {
  const { data } = await get(`/api/p/token/${tokenId}`);
  const { token } = data;
  return token;
}

