import { Box, Button, Link } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DISCORD_SUPPORT_LINK } from 'src/constants/app';
import { sxErrorInnerBox, sxErrorMain, sxErrorStack, sxInlineLink } from 'src/styles/General';
import StandardDialog from './StandardDialog';

function ErrorFallback({error,resetErrorBoundary}:{error:Error, resetErrorBoundary:Function}){
  const navigate = useNavigate();
  const [ showingErrorLog, setShowingErrorLog ] = useState(false);
  const toggleErrorLog = ()=>{
    setShowingErrorLog(showingErrorLog=>!showingErrorLog);
  }
  const handleClickRefresh = ()=>{
    window.location.reload();
  }
  const handleClickIndex = ()=>{
    navigate('/');
    resetErrorBoundary();
  }
  return (
  <Box sx={sxErrorMain} role="alert">
    <StandardDialog 
      title="Error"
      open={true}
      width={600}
      content={<>
        <Box sx={sxErrorInnerBox}>
          <Box>Oops! Sorry, an unexpected error occured. <Box sx={sxInlineLink} onClick={()=>toggleErrorLog()}>See Error Log.</Box></Box>
          {showingErrorLog&&<Box sx={sxErrorStack}><pre>{error.stack}</pre></Box>}
          <Box>If the issue persists, please contact us on our <Link href={DISCORD_SUPPORT_LINK} sx={sxInlineLink}>Discord</Link></Box>
        </Box>
        <Box sx={sxErrorInnerBox}>
          <Button variant={"outlined"} onClick={()=>handleClickRefresh()}>Refresh and Try Again</Button>
          &nbsp;&nbsp;
          <Button variant={"contained"} onClick={()=>handleClickIndex()}>Back to Markets</Button>
        </Box>
      </>}
    />
  </Box>
  );
}


export default ErrorFallback;