import { Box, MenuItem, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ComponentLoadingIcon from 'src/Components/ComponentLoadingIcon';
import SwapOrderBook from 'src/Components/Swaps/SwapOrderBook';
import SwapOrderForm from 'src/Components/Swaps/SwapOrderForm';
import { useSwapParameters } from 'src/Components/Swaps/useSwapParameters';
import withConnectWallet from 'src/Components/WithConnectWallet';
import { useSwapParameterStore } from 'src/store/useSwapParametersStore';
import { sxColumns, sxMenuItem } from "src/styles/General";
import { sxContent, sxContentWide, sxPageHeading, sxSideBar, sxWithSideBar } from 'src/styles/Page';
import { ETradeType } from 'src/types';
import { hslStrToHex, usePageTitle } from 'src/utils/common';
import theme from 'src/utils/theme';

const sxGridBox:SxProps = {
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'border.dark',
    bgcolor: 'background.paper',
    overflow: 'hidden',
};

const SwapTrades = ({ tradeType }: { tradeType: ETradeType }) => {
    /**
     * general hooks
     */
    useSwapParameters(tradeType)
    const market = useSwapParameterStore((state) => {
        const primaryMarketId = state.legs?.leg1?.marketId
        const primaryMarket = state.markets.find(m => m.marketId === primaryMarketId)
        return primaryMarket
    })
    const token = useSwapParameterStore((state) => {
        const primaryTokenId = state.legs?.leg1?.tokenId
        const primaryToken = state.tokens.find(m => m.tokenId === primaryTokenId)
        return primaryToken
    })

    const component = (
        <>
            <Box sx={{...sxContentWide, p: '0'}}>
            <Box sx={sxColumns}>
                    <Box sx={{ ...sxGridBox, bgcolor: `${hslStrToHex(theme.palette.background.paper)}66`, flex: 2, height: 'auto' }}>
                        <SwapOrderForm
                            tradeType={tradeType}
                        />
                    </Box>
                    <Box sx={{ ...sxGridBox, flex: 1 }}>
                        <SwapOrderBook tradeType={tradeType}/>
                    </Box>
            </Box>
            </Box>
        </>
    )

    return (
        <>
            
            {(market && token) ? component : <ComponentLoadingIcon isLoading curtain/>}
        </>
      );
}

const SwapTypes = [
    {
        path:"/derivatives/irs",
        label:'Interest Rate Swap',
        title: "Interest Rate Swap (IRS)",
        description: `An Interest Rate Swap (IRS) is a Single-Currency Float Leg vs Fixed Leg swap. You agree to pay or receive the
        fixed rate with the floating leg being in the opposite direction`,
        key: 'irs',
        component: <SwapTrades tradeType={ETradeType.INTEREST_RATE_SWAP} />
    },
    {
        path:"/derivatives/ccs",
        label:'Cross-Currency Swap',
        title: "Cross-Currency Swap (CCS)",
        description: `A Currency Swap is a Dual-Currency Fixed-for-Fixed, or Fixed-for-Float swap.
        You swap the principal amounts at the onset, and at maturity, and then lend /
        receive the floating and/or fixed rates throughout the life of the trade`,
        key: 'ccs',
        component: <SwapTrades tradeType={ETradeType.CROSS_CURRENCY_SWAP}/>
    },
    {
        path:"",
        label:'Multi-Leg Generic Trade',
        title: "Multi-Leg Generic Trade (MLGT)",
        description: 'Customise Two Legs, and Trade them in tandem',
        key: 'mlgt',
        component: <SwapTrades tradeType={ETradeType.MULTI_LEG}/>
    },
]

export function SwapMenuComponents({ showsBottomLine } : { showsBottomLine?: boolean }){
    const { swapType }: { swapType?: string} = useParams();
    const children = new Array<JSX.Element>();
    SwapTypes.map(({path, label, key},j)=>{
        const active = swapType === key;
        children.push(<MenuItem key={`${j}`} sx={sxMenuItem} className={active?'Mui-selected':''} disabled={path===""}><Link to={path}>{label}</Link></MenuItem>);
    });
    return <>{showsBottomLine?children:children.slice(0,-1)}</>;
  }

const SwapsAndDerivatives = () => {
    const { swapType }: { swapType?: string} = useParams();
    const navigate = useNavigate();
    useEffect(
        () => {
            if (!swapType) navigate('derivatives/irs')
        }, [swapType, navigate]
    )
    
    let {label,title,component,description} = SwapTypes.find(s => s.key === swapType) ?? SwapTypes[0];
    usePageTitle(['Derivatives',label],[label]);
    return (
        <Box sx={sxWithSideBar}>
            <Box sx={sxSideBar}>
            <Box component="ul">
                <Box component="li" sx={{fontWeight:700,fontSize:'1rem',textTransform:'uppercase',p:'4px',my:'8px'}}>
                    SWAPS
                </Box>
                <SwapMenuComponents showsBottomLine/>
            </Box>
            </Box>
                <Box sx={sxContent}>
                    <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                        <Box sx={{ marginBottom: '1rem', flex: 2}}>
                            <Typography variant="h1" sx={sxPageHeading}>{title}</Typography>
                            <Box component='span'>{description}</Box>
                        </Box>
                        <Box sx={{ flex: 1}}/>
                    </Box>
                {component}
            </Box>
        </Box>
    );
}

export default withConnectWallet(SwapsAndDerivatives)