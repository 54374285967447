import { SxProps } from '@mui/system';
import { hslStrToHex } from 'src/utils/common';
import theme from '../utils/theme';
export const styleGrid:SxProps = {
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  ":not(:hover)":{
    ".marketgridHandle": {
      opacity: 0,
    },
  }
};
export const styleHeader:SxProps = {
  bgcolor: 'background.paper',
  display: 'flex',
  p: '12px',
  fontSize:'0.875rem',
  fontWeight: 700,
  borderBottom: '2px solid',
  borderColor: 'dialog.light',
  alignItems:'center',
};
export const styleHeaderInline:SxProps = {
  // bgcolor: 'background.paper',
  display: 'flex',
  // px: 2,
  paddingRight: 2,
  // py: 1.5,
  // borderBottom: '2px solid',
  // borderColor: 'dialog.light',
  cursor: 'move',
  alignItems:'center',
  fontSize:'1.5rem',
};
export const styleHeaderExtra:SxProps = {
  fontWeight:400, paddingLeft:'8px',
}
export const stylePanel:SxProps = {
  bgcolor: `${hslStrToHex(theme.palette.background.paper)}66`,
  display: 'flex',
  flex: 1,
  p: 1.5,
};
export const stylePanelNoPadding:SxProps = {
  ...stylePanel, 
  p: 0,
};
export const stylePanelScrollable:SxProps = {
  ...stylePanelNoPadding, 
  overflow:'auto',
};

export const stylePanelChartContainer:SxProps = {
  ...stylePanel, 
  padding: '12px 12px 0 0',
};