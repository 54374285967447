/* User, Account, Wallet */
import { z } from "zod";
import { AccountWalletTokenERC721Schema, UnderlyingAssetSchema } from './token';
import { GroupedPositionSchema } from './market'

/* enums */
export enum EUserAccountType {
	CURRENT=1,
	TRADING=2,
	LP=3
}
export enum EAccountTransactionType {
	BORROW = 1,
	LEND = 2,
	REPAY = 3,
	UNLEND = 4,
	INTEREST = 5,
	TRANSFER_IN = 6,
	TRANSFER_OUT = 7,
	EXTERNAL_TRANSFER_IN = 8,
	EXTERNAL_TRANSFER_OUT = 9,
	TRADE = 10,
	SYSTEM_AIRDROP = 11,
	TYPE_LP_FEE = 12,
	TYPE_SWAP_SECONDARY_TOKEN = 13,
	TYPE_WITHDRAW_FEE = 14,
	TYPE_TRX_FEE = 15,
	TYPE_LP_SETUP_IN = 16,
	TYPE_LP_SETUP_OUT = 17,
	TYPE_LP_UNWIND_IN = 18,
	TYPE_LP_UNWIND_OUT = 19,
	TYPE_LIQUIDATE_UNLEND = 20,
}

export enum ETransferAccountType {
	YOUR_WALLET = 1,
	CURRENT_ACCOUNT = 2,
	TRADING_ACCOUNT = 3
}

export function getTransactionTypeText(type:EAccountTransactionType){
	switch(type){
		case EAccountTransactionType.BORROW: return 'Borrow';
		case EAccountTransactionType.LEND: return 'Lend';
		case EAccountTransactionType.REPAY: return 'Repay';
		case EAccountTransactionType.UNLEND: return 'Unlend';
		case EAccountTransactionType.INTEREST: return 'Interest';
		case EAccountTransactionType.TRANSFER_IN: return 'Transfer'; // 'Transfer In';
		case EAccountTransactionType.TRANSFER_OUT: return 'Transfer'; // 'Transfer Out';
		case EAccountTransactionType.EXTERNAL_TRANSFER_IN: return 'Transfer'; // 'External Transfer In';
		case EAccountTransactionType.EXTERNAL_TRANSFER_OUT: return 'Transfer'; // 'External Transfer Out';
		case EAccountTransactionType.TRADE: return 'Trade';
		case EAccountTransactionType.SYSTEM_AIRDROP: return 'System Airdrop';
		case EAccountTransactionType.TYPE_LP_FEE: return 'Lp Fee';
		case EAccountTransactionType.TYPE_SWAP_SECONDARY_TOKEN: return 'Swap Secondary Token';
		case EAccountTransactionType.TYPE_WITHDRAW_FEE: return 'Withdraw Fee';
		case EAccountTransactionType.TYPE_TRX_FEE: return 'Trx Fee';
		case EAccountTransactionType.TYPE_LP_SETUP_IN: return 'Lp Setup In';
		case EAccountTransactionType.TYPE_LP_SETUP_OUT: return 'Lp Setup Out';
		case EAccountTransactionType.TYPE_LP_UNWIND_IN: return 'Lp Unwind In';
		case EAccountTransactionType.TYPE_LP_UNWIND_OUT: return 'Lp Unwind Out';
		case EAccountTransactionType.TYPE_LIQUIDATE_UNLEND: return 'Liquidate';
		default: return 'Unknown';
	}
}

export function getAccountTypeText(type: ETransferAccountType) {
	switch(type){
		case ETransferAccountType.YOUR_WALLET: return 'Your Wallet';
		case ETransferAccountType.CURRENT_ACCOUNT: return 'Current Account';
		case ETransferAccountType.TRADING_ACCOUNT: return 'Trading Account';
		default: return 'Unknown';
	}
}

/* user */
export const AccountWalletTokenSchema = z.object({
	availableQuantity: z.string(),
	lockedQuantity: z.string(),
	quantity: z.string(),
	tokenId: z.number(),
	tokenType: z.number(),
	accountId: z.number(),
	name: z.string().optional(),
	erc721Tokens: z.array(AccountWalletTokenERC721Schema).optional(),
	underlyingAssets: z.array(UnderlyingAssetSchema).optional(),
}); 
export interface AccountWalletToken extends z.infer<typeof AccountWalletTokenSchema>{}; 

export const AccountTokenWithImpactSchema = z.object({
	tokenId: z.number(),
	quantity: z.string(),
	netAssetValue: z.string(),
	stressedNav: z.string(),
	maintenanceMargin: z.string(),
	curveImpact: z.string(),
	parallelImpact: z.string(),
	slopeImpact: z.string(),
	fxImpact: z.string(),
});
export interface AccountTokenWithImpact extends z.infer<typeof AccountTokenWithImpactSchema>{};

/** 
 * Account Positions 
 * all values in local quantity
 * */
export const AccountTokenPositionSchema = z.object({
	accountId: z.number(),
	tokenId: z.number(),
	netAssetValue: z.string(),
	cash: z.string(),
	cashAvailable: z.string(),
	netPositions: z.string(),
	interest: z.string(),
	mtm: z.string(),
	pv: z.string(),
	price: z.string(), // token positions specific price
	netTransfers: z.string(),
	maxWithdraw: z.string(),
	totalTransfers: z.string().optional(),
});
export interface AccountTokenPosition extends z.infer<typeof AccountTokenPositionSchema>{};

export const UserAccountschema = z.object({
	userId:z.number(),
	type:z.nativeEnum(EUserAccountType),
	name:z.string(),
	accountId:z.number(),
	tokens:z.array(AccountWalletTokenSchema),
	healthScore:z.number().optional(),
	maxBorrowInUsd:z.string().optional(),
	tokenPositionMap:z.map(z.number(),AccountTokenPositionSchema).optional(),
	updateDate:z.number(),
});
export interface UserAccount extends z.infer<typeof UserAccountschema>{}; 

export const UserSchema = z.object({
	userId:z.number(),
	address:z.string(),
	userType:z.number(),
	userClass:z.number(),
	accounts:z.array(UserAccountschema),
});
export interface User extends z.infer<typeof UserSchema>{};
export const UserAccountHealthscoreSchema = z.object({
	accountId: z.number(),
	type: z.number(),
	healthscore: z.string(),
});
export interface UserAccountHealthscore extends z.infer<typeof UserAccountHealthscoreSchema>{};
export const AccountTransactionSchema = z.object({
	trxId: z.number(),
	accountId: z.number(),
	tokenId: z.number(),
	type: z.nativeEnum(EAccountTransactionType),
	quantity: z.string(),
	balance: z.string(),
	createDate: z.number(),
	erc721TokenId: z.number().optional(),
});
export interface AccountTransaction extends z.infer<typeof AccountTransactionSchema>{};

export const UserPositionAndDv01Schema = z.object({
	tokenId: z.number(),
	instrumentId: z.string(),
	position: z.string(),
	dv01: z.string(),
	accountId: z.number(),
	marketId: z.number(),
	maturityDate: z.number().optional(), // for fixed rate
});
export interface UserPositionAndDv01 extends z.infer<typeof UserPositionAndDv01Schema>{};
export const UserMarkToMarketSchema = z.object({
	accountId: z.number(),
	marketId: z.number(),
	instrumentId: z.string(),
	tokenId: z.number(),
	mtm: z.string(),
	maturityDate: z.number().optional(), // for fixed rate
});
export interface UserMarkToMarket extends z.infer<typeof UserMarkToMarketSchema>{};


/* user ETH */
export enum EETHUserActionEventStatus {
	PENDING = 1,
	EXECUTED = 2,
	TOKEN_NOT_SUPPORT = 101,
	REMOVED_DUE_TO_REORG = 102,
	NOT_FOUND = 103,
};
export enum EDepositEventStatus {
	PENDING = 1,
	EXECUTED = 2,
};

export enum EWithdrawalRequestStatus {
	PENDING = 1,
	PREEXECUTE = 2,
	EXECUTING = 3,
	SUCCESS = 10,
	FAILED = 99,
	CANCEL = 100,
	REJECTED = 101,
};
export const DepositEventSchema = z.object({
	a: z.string(), // token address
	q: z.string(), // quantity
	s: z.nativeEnum(EDepositEventStatus), // status
});
export interface DepositEvent extends z.infer<typeof DepositEventSchema>{};
export const ETHUserActionEventSchema = z.object({
	actions: z.array(z.any()),
	blockHash: z.string(),
	blockIdx: z.number(),
	chainId: z.number(),
	depositAddress: z.string(),
	deposits: z.array(DepositEventSchema),
	eventId: z.number(),
	logIdx: z.number(),
	senderAddress: z.string(),
	status: z.number(),
	transactionHash: z.string(),
	transactionIdx: z.number(),
});
export interface ETHUserActionEvent extends z.infer<typeof ETHUserActionEventSchema>{};
export const WithdrawalRequestSchema = z.object({
	id: z.number(),
	userId: z.number(),
	chainId: z.number(),
	tokenId: z.number(),
	quantity: z.string(),
	status: z.nativeEnum(EWithdrawalRequestStatus),
	withdrawMax: z.boolean(),
	userAddress: z.string().optional(),
	tokenAddress: z.string().optional(),
	tokenDecimals: z.number().optional(),
	transactionHash: z.string().optional(),
});
export interface WithdrawalRequest extends z.infer<typeof WithdrawalRequestSchema>{};
export const WithdrawalRequestParamsSchema = z.object({
	chainId: z.number(),
	tokenId: z.number(),
	quantity: z.string(),
});
export interface WithdrawalRequestParams extends z.infer<typeof WithdrawalRequestParamsSchema>{};
export const AccountsLossGivenDefaultSchema = z.object({
	accountId: z.number(),	
	tokenId: z.number(),	
	// change: z.string(),	
	originalAsset: z.string(),	
	originalLiability: z.string(),
	newAsset: z.string(),	
	newLiability: z.string(),	
	lossGivenDefault: z.string(),	
	address: z.string(),	
	healthscore: z.number(),
});
export interface AccountsLossGivenDefault extends z.infer<typeof AccountsLossGivenDefaultSchema>{};

/** 
 * mtm performance 
 * all values except `dailyPnl` are in local quantity
 * */
export const PNLHistoricalItemSchema = z.object({
	accountId: z.number(),
	tokenId: z.number(),
	tokenPrice: z.string(),
	netAssetValue: z.string(),
	cash: z.string(),
	lockedCash: z.string(),
	interest: z.string(),
	netPositions: z.string(),
	mtm: z.string(),
	pv: z.string(),
	netTransfers: z.string(),
	dailyPnl: z.string(), // in USD
	date: z.number(),
});
export interface PNLHistoricalItem extends z.infer<typeof PNLHistoricalItemSchema>{};
export const PNLHistoricalSchema = z.record(
	z.string(), z.array(PNLHistoricalItemSchema) // [tokenId: string]: [...items]
);
export interface PNLHistorical extends z.infer<typeof PNLHistoricalSchema>{};


/** 
 * p&l performance live api
 * */
export const UserAccountPerformancePrevCloseNavItemSchema = z.object({
	accountId: z.number(),
	tokenId: z.number(),
	tokenPrice: z.string(),
	netAssetValue: z.string(),
	cash: z.string(),
	lockedCash: z.string(),
	interest: z.string(),
	netPositions: z.string(),
	mtm: z.string(),
	pv: z.string(),
	dailyPnl: z.string(),
	date: z.number(),
});
export interface UserAccountPerformanceLiveItem extends z.infer<typeof UserAccountPerformancePrevCloseNavItemSchema>{};
export const UserAccountPerformanceLiveSchema = z.object({
	prevCloseNav: z.array(UserAccountPerformancePrevCloseNavItemSchema),
	tokenPositions: z.array(AccountTokenPositionSchema),
	positionDetails: z.array(GroupedPositionSchema),
	nav: z.string(),
	livePnL: z.string(),
	cash: z.string(),
	positionsPv: z.string(),
	borrowLimit: z.string(),
	healthscore: z.number(),
});
export interface UserAccountPerformanceLive extends z.infer<typeof UserAccountPerformanceLiveSchema>{};

/* mtm performance */
export const MTMPerformanceItemSchema = z.object({
	accountId: z.number(),
	tokenId: z.number(),
	date: z.number(), 
	cashPosition: z.string(),
	// realized: z.string(),
	unrealized: z.string(),
});
export interface MTMPerformanceItem extends z.infer<typeof MTMPerformanceItemSchema>{};
export const MTMPerformanceSchema = z.record(
	z.string(), z.array(MTMPerformanceItemSchema) // [tokenId: string]: [...items]
);
export interface MTMPerformance extends z.infer<typeof MTMPerformanceSchema>{};

/* Account Risk Metrics */
export const AccountRiskMetricsSchema = z.object({
	accountId: z.number().optional(),
	tokenId: z.number().optional(),
	quantity: z.string().optional(),
	healthscore: z.number(),
	netAssetValue: z.string(),
	pvAsset: z.string(),
	pvLiability: z.string(),
	maxBorrowInUsd: z.string(),
	stressedNav: z.string(),
	maintenanceMargin: z.string(),
	initialMargin: z.string(),
	curveImpact: z.string(),
	parallelImpact: z.string(),
	slopeImpact: z.string(),
	ratesImpact: z.string(),
	fxImpact: z.string(),
});
export interface AccountRiskMetrics extends z.infer<typeof AccountRiskMetricsSchema>{};

/* Account Positions By Impact */
export const AccountPositionsOrderByImpactSchema = z.object({
	marketId: z.number(),
	tokenId: z.number(),
	instrumentId: z.string(),
	quantity: z.string(),
	rate: z.string(),
	curveImpact: z.string(),
	parallelImpact: z.string(),
	slopeImpact: z.string(),
	fxImpact: z.string(),
	totalImpact: z.string(),
	impactPercentage: z.string(),
	originalValue: z.string(),
	presentValue: z.string(),
});
export interface AccountPositionsOrderByImpact extends z.infer<typeof AccountPositionsOrderByImpactSchema>{};

/* Access tokens */
export const AccessTokenDataPayloadSchema = z.object({
	user: z.object({
		userId: z.number(),
		address: z.string(),
		roles: z.array(z.string())
	}),
	access_type: z.string(),
	iat: z.number(),
    exp: z.number()
})
export const AccessTokenSchema = z.object({
	data: z.object({
		header: z.object({
			alg: z.string(),
            typ: z.string()
		}),
		payload: AccessTokenDataPayloadSchema
	}),
	token: z.string(),
	
});
export interface AccessToken extends z.infer<typeof AccessTokenSchema>{};

export interface AuthResponse {
	user: User,
	accessToken: AccessToken
}