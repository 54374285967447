import { Box, SxProps } from '@mui/material';
import { useState } from 'react';
import { sxBox, sxBoxCompactInner, sxBoxTitle, sxRow } from 'src/styles/General';
import theme from 'src/utils/theme';
import CashPositionsChart from './CashPositionsChart';
import CashPositionsTable from './CashPositionsTable';
import StickyBox from './StickyBox';
import AccountTransferMenu from './AccountTransferMenu';

export enum EAccountType {CHAIN,CURRENT,TRADING};
export enum ETransferDirectionType {FROM,TO};
export interface ISelectedTransferAccountToken {tokenId:number,accountType:EAccountType,transferType:ETransferDirectionType};

const sxSideMenu:SxProps = {
  width:'300px',flexShrink:0,
  '&.MuiBox-root':{
    overflow:'visible', // higher priority for proper position:sticky hierachy
  },
  [theme.breakpoints.down('tablet')]:{
    width:'100%',
  }
};

const sxBalanceContainer: SxProps = {
  width: '800px',
  minWidth: '550px',
  overflow: 'auto'
}

function CashPositionsPanel(){
  const [selectedTransferAccountToken,setSelectedTransferAccountToken] = useState<ISelectedTransferAccountToken|undefined>(undefined);
  return (<Box sx={sxRow}>
    <Box sx={sxSideMenu}>
      <StickyBox sx={sxBox}>
        <Box sx={sxBoxTitle}>Transfer</Box>
        <Box sx={sxBoxCompactInner}><AccountTransferMenu selectedTransferAccountToken={selectedTransferAccountToken}/></Box>
      </StickyBox>
    </Box>
    <Box sx={sxBalanceContainer}>
      <Box sx={sxBox}>
        <Box sx={sxBoxTitle}>Summary</Box>
        <Box sx={sxBoxCompactInner}><CashPositionsChart/></Box>
      </Box>
      <Box sx={sxBox}>
        <Box sx={sxBoxTitle}>Cash Balance</Box>
        <Box sx={sxBoxCompactInner}>
          <CashPositionsTable handleClickAccount={(selectedTransferAccountToken)=>{
            setSelectedTransferAccountToken(selectedTransferAccountToken);
          }}/>
        </Box>
      </Box>
    </Box>
  </Box>);
}

export default CashPositionsPanel;