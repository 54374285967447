import { Alert, Box, Button, Link, Snackbar, Typography } from '@mui/material';
import MarketAssetsTable from 'src/Components/MarketAssetsTable';
import MarketsHistoricalRatesChart from 'src/Components/MarketsHistoricalRatesChart';
import { sxBox, sxBoxInner, sxBoxTitle, sxInlineLink, sxRow, sxRowWideGap, sxTitleFloatRight } from 'src/styles/General';
import { sxContentWider, sxPageHeading, sxPageHeadingRow } from 'src/styles/Page';
import apis from 'src/utils/apis';
import { usePageTitle } from 'src/utils/common';
import MarketsInfoRow from '../Components/MarketsInfoRow';
import MarketsYieldCurvesChart from '../Components/MarketsYieldCurvesChart';
import { useRef, useState } from 'react';
import { CHAIN_ID, DEFAULT_MARKETS_PAGE_GRAPH_TOKENS, FEATURE_VERSION } from 'src/constants/app';
import theme from 'src/utils/theme';
import { sxDesktopOnly, sxMobileOnly } from 'src/styles/General';
import useAppStore from 'src/store';

const TestnetNote = () => {
  const appstate = useAppStore();
  const { hideTestnetNoteForever } = appstate;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const isTestnet = CHAIN_ID === 11155111
  const closeNote = () => {
    appstate.set('hideTestnetNoteForever',true);
  }

  return (isTestnet && !hideTestnetNoteForever) ? (<Box sx={{
    gridArea: 'notice-bar',
    bgcolor:'amber.800',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'amber.900',
    borderRadius: '4px',
    color: 'white',
    fontSize: '0.875rem',
    fontFamily: 'Inter',
    // height: noticeBarHeight,
    // lineHeight: noticeBarHeight,
    // position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    whiteSpace: 'nowrap',
    overflow: 'auto',
    zIndex: theme.zIndex.appBar,
    textAlign: 'center',
    marginBottom: '24px',
    'a':{
      display:'inline',
      fontWeight: '700'
    },
    ...sxDesktopOnly
  }}>
    <Box sx={{ display: 'flex', flexDirection: 'row'}}>
      <Box sx={{ fontWeight: '700', marginRight: 'auto', padding: '20px', fontSize: '0.75rem', lineHeight: '1rem' }}>TESTNET NOTE</Box>
      <Button sx={{minWidth:0, padding: '20px 12px 16px 12px', float: 'right', fontSize: '0.75rem', lineHeight: '1rem'}} onClick={() => closeNote()}>Hide Forever</Button>
      <Button sx={{minWidth:0, padding: '20px', paddingBottom: '16px',  float: 'right', fontSize: '0.75rem', lineHeight: '1rem'}} onClick={() => setIsCollapsed(collapsed => !collapsed)}>Collapse</Button>
    </Box>
    {!isCollapsed && <Box sx={{ mx: '20px', marginBottom:'20px', textAlign: 'left', fontSize: '0.875rem', lineHeight: '1.25rem'}}>
      <Box sx ={{ marginBottom: '1rem', width: 'auto', whiteSpace: 'pre-wrap'}}>Infinity Exchange’s Testnet App is currently running under the Sepolia ETH Network. In order to receive test tokens to trade on Infinity Exchange Testnet via the faucet functions below, go to&nbsp;<a target="_blank" rel="noreferrer" href="https://sepoliafaucet.com/">https://sepoliafaucet.com/</a>&nbsp;to receive Sepolia ETH to get started.</Box>
      <Box>For more information, please read our quickstart guide&nbsp;<a target="_blank" rel="noreferrer" href="https://www.infinity.exchange/article/infinity-testnet-v2-a-quickstart-guide-to-lend-borrow-crypto-dec-2023">here</a>.</Box>
    </Box>}
  </Box>) : null
}

function MarketsDashboard(){
  usePageTitle(['Markets']);
  const marketAssetsTableRef = useRef<{expandAll:Function,collapseAll:Function,isExpanded:()=>boolean}>();
  const [expandedAssetsTable,setExpandedAssetsTable] = useState(false);
  const { data:tokens } = apis.token.useMarketsPageTokens();
  return (
    <Box sx={sxContentWider}>
      <TestnetNote />
      <Box sx={sxPageHeadingRow}>
        <Typography variant="h1" sx={sxPageHeading}>Markets</Typography>
        {FEATURE_VERSION==='development'&&<Box sx={sxTitleFloatRight}>
          {!expandedAssetsTable&&<Link sx={sxInlineLink} onClick={()=>{
            setExpandedAssetsTable(true);
            marketAssetsTableRef.current?.expandAll();
          }}>Expand to compare other protocols</Link>}
          {expandedAssetsTable&&<Link sx={sxInlineLink} onClick={()=>{
            setExpandedAssetsTable(false);
            marketAssetsTableRef.current?.collapseAll();
          }}>Collapse to hide other protocols</Link>}
        </Box>}
      </Box>
      <Box>
        <Box sx={sxRowWideGap}>
          <Box sx={sxBox}>
            <Box sx={sxBoxTitle}>Yield Assets<Box sx={sxTitleFloatRight}><MarketsInfoRow tokens={tokens} showsOnlyTotalVolume={true}/></Box></Box>
            <MarketAssetsTable tokens={tokens} ref={ref=>{marketAssetsTableRef.current=ref;}}/>
          </Box>
        </Box>
        <Box sx={sxRowWideGap}>
          <Box sx={sxBox}>
            <Box sx={sxBoxTitle}>Yield Curve</Box>
            <Box sx={sxBoxInner}><MarketsYieldCurvesChart tokens={tokens} initTokenCodes={DEFAULT_MARKETS_PAGE_GRAPH_TOKENS}/></Box>
          </Box>
          <Box sx={sxBox}>
            <Box sx={sxBoxTitle}>Implied Average Floating Rates Per Period</Box>
            <Box sx={sxBoxInner}><MarketsYieldCurvesChart tokens={tokens} withImpliedForwardRates/></Box>
          </Box>
        </Box>
        <Box sx={sxRowWideGap}>
          <Box sx={sxBox}>
            <Box sx={sxBoxTitle}>Historical Rates</Box>
            <Box sx={sxBoxInner}><MarketsHistoricalRatesChart tokens={tokens} initTokenCodes={DEFAULT_MARKETS_PAGE_GRAPH_TOKENS}/></Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default MarketsDashboard;