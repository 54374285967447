import moment, { Moment } from 'moment';
import { useCallback } from 'react';

/**
 * XAxis Ticks to render Date ticks a la tradingview chart: first apparance of a month renders as `MMM`, and then every 7 days as `D`
 * @usage `<XAxis interval={0} tick={<RechartsDateAxisTicks xAxisTicks={xAxisTicks}/>}/>`
 * @param props.xAxisTicks Array<number> timestamps of dates, one day apart 
 */
function RechartsDateAxisTicks(props:any){
  const { x, y, width, fill, payload, visibleTicksCount } = props;
  const xAxisTicks = props.xAxisTicks as number[];
  const targetDayGap = Number(props.targetDayGap ?? 5) as number;
  const isMonthOnlyDisplay = xAxisTicks.length>90; // only shows month labels if there's more than 3 months of datapoints
  const isFirstTick = payload.index===0;
  const isLastTick = payload.index===visibleTicksCount-1;
  const isSecondLastTick = payload.index===visibleTicksCount-2;
  // use chart width to determine max ticks and gaps
  // assume each tick is 30px wide including padding
  const estimatedTickCount = Math.floor(width/30);
  // month gaps: number of months from the datapoints (asusming 30 days a month)
  const monthGap = Math.ceil(Math.round(xAxisTicks.length/30)/estimatedTickCount);
  // day gaps: number of weeks from the datapoints 
  const dayGap = targetDayGap * Math.max(1,Math.ceil(Math.round(xAxisTicks.length/targetDayGap)/estimatedTickCount));
  // console.log(`props`,props);
  const date = moment(payload.value);
  // find indices for current month first appearance
  const currentIdx = xAxisTicks.map(tick=>tick).indexOf(payload.value);
  // need to compare year as well to avoid skipping labels for data spanning over a year
  const year = date.year(); const month = date.month(); const day = date.date();
  const currentMonthFirstAppearanceIdx = xAxisTicks.map(tick=>`${new Date(tick).getFullYear()}-${new Date(tick).getMonth()}`).indexOf(`${year}-${month}`);
  const dayDiff = currentMonthFirstAppearanceIdx - currentIdx; // assumes ticks are always 1D apart
  // find current month index to tell which # tick this is for render skipping
  const currentMonthIdx = xAxisTicks.filter(tick=>new Date(tick).getDate()===1).map(tick=>`${new Date(tick).getFullYear()}-${new Date(tick).getMonth()}`).indexOf(`${year}-${month}`);
  let label:string|undefined;
  let fontWeight = 400;
  const getMonthLabel = useCallback((date:Moment)=>{
    const label = date.format('MMM');
    const fontWeight = 700;
    return {label,fontWeight};
  },[]);
  if(isMonthOnlyDisplay){
    if(dayDiff===0
      && day<=28 // skip last days of month to prevent month label overlap
      && currentMonthIdx%monthGap===0 // skip according to estimatedTickCount
    ) { 
      ({label,fontWeight} = getMonthLabel(date));
      console.log(`month label ${label}, index ${payload.index}, estimatedTickCount ${estimatedTickCount}`);
    }
  }else{
    if(isFirstTick||dayDiff===0){ // first tick or first day of month: renders as month label
      ({label,fontWeight} = getMonthLabel(date));
    }else if(                   // day labels for:
      (isLastTick               // end tick
      ||currentIdx%dayGap===0   // every day gap
      )
      &&!isSecondLastTick       // skip second to last tick
    ){
      label = date.format('D');
    }
  }
  if(!label) return null;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={8} textAnchor={isLastTick?"end":"middle"} fill={fill} style={{fontWeight}}>
        {label}
      </text>
    </g>
  );
}

export default RechartsDateAxisTicks;