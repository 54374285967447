import {
  Box, Button, ButtonGroup, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import Decimal from 'decimal.js';
import { useMemo, useState } from 'react';
import { Area, Bar, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { MARKETS_PAGE_TOKENS } from 'src/constants/app';
import { hslStrToHex } from 'src/utils/common';
import { date, formatStartOfDayDate, isWithinDays, useCallbackRollingDateLabel, useRollingDates } from 'src/utils/date';
import { ceilToNearestOoM, format, formatN, formatUSD, formatUSDWithSign, getEqualDistanceTicks } from 'src/utils/numbers';
import { capitalize } from 'src/utils/string';
import theme from 'src/utils/theme';
import { graphAreaColors, sxChartContainer, sxChartHeader, sxChartTooltip, sxChartTooltipLegendBox, sxChartTooltipLegendBoxTitle, sxChartTooltipTable, sxTokenSwitchItem, sxChartWatermarkCenter } from '../styles/Chart';
import { styleBoxBlock, styleBoxPanel, sxBoxTitle, sxTitleFloatRight } from '../styles/General';
import { MarketHistoricalRateItem } from '../types';
import apis from '../utils/apis';
import RechartsDateAxisTicks from './RechartsDateAxisTicks';
import DefaultTokensSelector from './DefaultTokensSelector';
import ValueTableCell from './ValueTableCell';

function MarketsHistoricalVolumeChart({type}:{type:'volume'|'size'}){
  const MARKET_KEY_FLOAT = 'FLOAT';
  const maxDays = 3650;
  const rollingDates = useRollingDates();
  const rollingDayLabels = [MARKET_KEY_FLOAT,...rollingDates.map(({label})=>label).filter(label=>label!=='4Q')];
  const getRollingDateLabel = useCallbackRollingDateLabel();
  const initialMarketKeys = rollingDayLabels.filter((label)=>[MARKET_KEY_FLOAT,'2W','2Q'].includes(label));
  const initTokenKeys = MARKETS_PAGE_TOKENS.slice(0,1);
  const [filteredMarketKeys,setFilteredMarketKeys] = useState<string[]>(initialMarketKeys);
  const [filteredTokenCodes,setFilteredTokenCodes] = useState(initTokenKeys);
  const timeframes = ['all','quarter','month','week'];
  const [selectedTimeframe,setSelectedTimeframe] = useState('month');
  const timeframeDays = {'quarter':90,'month':30,'week':7}[selectedTimeframe]||0;
  const { data:tokens } = apis.token.useMarketsPageTokens();
  const tokenIds = tokens?.map(t=>t.tokenId);
  const { data:historicalRatesByToken } = apis.rate.useMarketsHistoricalRates({
    tokenIds, daysToInclude:maxDays,
  });
  

  // token color mapping to keep them consistent through filter
  const tokenColors = useMemo(()=>{
    const tokenColors = new Map<string,string>();
    tokens?.forEach((token,i)=>{
      const areaColor = graphAreaColors[i%graphAreaColors.length];
      tokenColors.set(token.code,areaColor);
    })
    return tokenColors;
  },[tokens]);

  // DEV currently only calcuating totals from markets queried specifically from the apis
  const { data, totalVolume, totalSize, marketKeys, xAxisTicks, yAxisTicks } = useMemo(()=>{
    let totalVolume = new Decimal(0);
    let totalSize = new Decimal(0);
    const marketKeyMap = {};
    const volumeDateMap = {};
    const xAxisTicks:number[] = [];
    let yMax = new Decimal(0);
    let yMin = new Decimal(0);
    for(let tokenId in historicalRatesByToken){
      const token = tokens?.filter(t=>t.tokenId===parseInt(tokenId))[0];
      if(!token) continue;
      const showsInGraph = filteredTokenCodes.includes(token.code);
      const {fixed:historicalRatesFixed,floating:historicalRatesFloating} = historicalRatesByToken[tokenId];
      // eslint-disable-next-line no-loop-func
      historicalRatesFloating.forEach((r:MarketHistoricalRateItem)=>{
        const t = date(r.date).valueOf();
        const name = formatStartOfDayDate(t);
        const volume = new Decimal(r.volume||0);
        const size = new Decimal(r.lendDepth||0).add(new Decimal(r.borrowDepth||0)).mul(token.price||0);
        const value = type==='volume'?volume:size;
        // console.log('t',t,'rate',rate.toString,r.low,r.high);
        totalVolume = totalVolume.add(volume); totalSize = totalSize.add(size); 
        if(timeframeDays&&!isWithinDays(date(r.date),timeframeDays)) return;
        if(!showsInGraph) return;
        if(!filteredMarketKeys.includes(MARKET_KEY_FLOAT)) return;
        const stackedValue = value.add((volumeDateMap[t]&&volumeDateMap[t].stackedValue) ?? 0);
        volumeDateMap[t] = Object.assign({},volumeDateMap[t],{t,name,[`${token.code}-${MARKET_KEY_FLOAT}`]:value,stackedValue});
        yMax = Decimal.max(yMax,value);
        yMin = Decimal.min(yMin,value);
      });
      for(let marketKey in historicalRatesFixed){
        const marketKeyLabel = getRollingDateLabel(Number(marketKey));
        marketKeyMap[marketKeyLabel] = true;
        const rates:MarketHistoricalRateItem[] = historicalRatesFixed[marketKey];
        // console.log(marketKey,rates);
        // eslint-disable-next-line no-loop-func
        rates.forEach(r=>{
          const t = r.date;
          const name = formatStartOfDayDate(t);
          const volume = new Decimal(r.volume||0);
          const size = new Decimal(r.lendDepth||0).add(new Decimal(r.borrowDepth||0)).mul(token.price||0);
          const value = type==='volume'?volume:size;
          totalVolume = totalVolume.add(volume); totalSize = totalSize.add(size); 
          if(timeframeDays&&!isWithinDays(date(r.date),timeframeDays)) return;
          if(!showsInGraph) return;
          if(!filteredMarketKeys.includes(marketKeyLabel)) return;
          const stackedValue = value.add((volumeDateMap[t]&&volumeDateMap[t].stackedValue) ?? 0);
          volumeDateMap[t] = Object.assign({},volumeDateMap[t],{t,name,[`${token.code}-${marketKeyLabel}`]:value,stackedValue});
          yMax = Decimal.max(yMax,value);
          yMin = Decimal.min(yMin,value);
        });
      }
    }
    let yStackedMax = new Decimal(0);
    const marketKeys = [MARKET_KEY_FLOAT,...Object.keys(marketKeyMap)];
    const data:any[] = [];
    for(let date of Object.keys(volumeDateMap).sort()){
      data.push(volumeDateMap[date]);
      xAxisTicks.push(Number(date))
      yStackedMax = Decimal.max(yStackedMax, volumeDateMap[date].stackedValue ?? 0);
    }
    const yAxisTicks = getEqualDistanceTicks(yMin.toNumber(),yStackedMax.toNumber());
    return {data,totalVolume,totalSize,marketKeys,xAxisTicks,yAxisTicks};
  },[getRollingDateLabel, historicalRatesByToken, timeframeDays, tokens, type, filteredTokenCodes, filteredMarketKeys]);
  // console.log('data',data);
  function toggleFilteredMarketKey(marketKey:string){
    setFilteredMarketKeys(
      filteredMarketKeys.indexOf(marketKey)<0?
      [...filteredMarketKeys,marketKey]: // add
      filteredMarketKeys.filter(k=>k!==marketKey) // remove
    );
  }
  function ChartTooltip({active,payload,label}:any){
    if (active && payload && payload.length) {
      return (
        <Box sx={sxChartTooltip}>
          <Box sx={sxChartTooltipLegendBoxTitle}>{`${payload[0].payload.name}`}</Box>
          <Table sx={sxChartTooltipTable}>
            <TableBody>
              {payload.map((p:any,i:number)=>{
                let value = '';
                switch(type){
                  case 'size': value = formatUSDWithSign(p.value); break;
                  case 'volume': default: value = format(p.value); break;
                }
                return (
                  <TableRow key={i}>
                    <TableCell><Box sx={sxChartTooltipLegendBox} style={{backgroundColor:p.stroke}}></Box><b>{capitalize(p.name)}</b></TableCell>
                    <ValueTableCell mono align='right'>{value}</ValueTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      );
    }
    return null;
  }
  let titleRow = <></>;
  switch(type){
    case 'size': titleRow = <Box sx={{...sxBoxTitle, mx: '0'}}>Market Depth<Box sx={sxTitleFloatRight}>US${formatUSD(totalSize)}</Box></Box>; break;
    case 'volume': titleRow = <Box sx={{...sxBoxTitle, mx: '0'}}>Historical Trading Volume<Box sx={sxTitleFloatRight}>Cumulative Volume: ${formatN(totalVolume)}</Box></Box>; break;
  }
  const marketsAllSelected = filteredMarketKeys.length===rollingDayLabels?.length;
  const marketsNoneSelected = filteredMarketKeys.length===0;
  return (<>
    {titleRow}
    <Box sx={styleBoxBlock} style={{height:'100%',overflow:'hidden'}}>
      <Box sx={styleBoxPanel} style={{alignItems:'stretch',display:'flex',height:'100%'}}>
        <Box sx={{...sxChartHeader,justifyContent:'flex-start',overflow:'auto'}}>
          <DefaultTokensSelector onChange={setFilteredTokenCodes} showsAll initTokenCodes={initTokenKeys}/>
          <ButtonGroup variant="outlined" aria-label="outlined primary button group">
          {rollingDayLabels.map((marketKey,i)=>{
            // const color = graphLineColors[i%graphLineColors.length];
            const inactive = filteredMarketKeys&&filteredMarketKeys.indexOf(marketKey)<0;
            return (
              // TODO toggle lines
              <Button key={i} sx={sxTokenSwitchItem} variant={inactive?'outlined':'contained'} onClick={()=>toggleFilteredMarketKey(marketKey)}>{capitalize(marketKey)}</Button>
            )
          })}
          <Button sx={sxTokenSwitchItem} variant={!marketsAllSelected?'outlined':'contained'} onClick={()=>setFilteredMarketKeys(rollingDayLabels)}>All</Button>
          <Button sx={sxTokenSwitchItem} variant={!marketsNoneSelected?'outlined':'contained'} onClick={()=>setFilteredMarketKeys([])}>None</Button>
          </ButtonGroup>
          <ButtonGroup variant="outlined" aria-label="outlined primary button group">
          {timeframes.map((timeframe,i)=>{
            // const color = graphLineColors[i%graphLineColors.length];
            const inactive = timeframe!=selectedTimeframe;
            return (
              <Button key={i} sx={sxTokenSwitchItem} variant={inactive?'outlined':'contained'} onClick={()=>{
                setSelectedTimeframe(timeframe);
              }}>{capitalize(timeframe)}</Button>
            )
          })}
          </ButtonGroup>
        </Box>
        <Box sx={{position: 'relative', ...sxChartWatermarkCenter}}>
          <Box sx={{...sxChartContainer, marginLeft: '-10px', paddingRight: '12px' }}>
            <ResponsiveContainer minHeight={400}>
              <ComposedChart data={data} stackOffset="sign">
                <YAxis yAxisId="value" domain={[(dataMin:number)=>Math.min(dataMin,0),'dataMax']} tickLine={false} ticks={yAxisTicks} interval={'preserveStartEnd'} tickFormatter={(v,i)=>{
                  switch(type){
                    case 'size': return `${formatN({num:v,prefix:'$'})}`; 
                    case 'volume': default: return `${formatN(v,0)}`;
                  }
                }} stroke={`${hslStrToHex(theme.palette.text.primary)}88`}/>
                <XAxis dataKey="t" scale="point" domain={['dataMin','dataMax']} tickLine={false} interval={0} tick={<RechartsDateAxisTicks xAxisTicks={xAxisTicks}/>} stroke={`${hslStrToHex(theme.palette.text.primary)}88`}/>
                {tokens?.filter(token=>!(filteredTokenCodes&&filteredTokenCodes.indexOf(token.code)<0)).map((token,i)=>
                  marketKeys.filter(marketKey=>!(filteredMarketKeys&&filteredMarketKeys.indexOf(marketKey)<0)).map((marketKey,j)=>{
                    const fillColor = tokenColors.get(token.code);
                    const fillOpacity = (filteredMarketKeys.length-filteredMarketKeys.indexOf(marketKey))/(filteredMarketKeys.length)*0.8;
                    switch(type){
                      case 'size': 
                      case 'volume': 
                      return (<>
                          {data.length > 0 && <Area key={`${i}-${j}`} name={`${token.code}-${marketKey}`} type="monotone" dataKey={`${token.code}-${marketKey}`} yAxisId="value" strokeWidth={2} stroke={fillColor} fill={fillColor} fillOpacity={fillOpacity} dot={false} stackId={'all'}/>}
                        </>);
                      default: 
                      return (
                        <Bar key={`${i}-${j}`} name={`${token.code}-${marketKey}`} type="monotone" dataKey={`${token.code}-${marketKey}`} yAxisId="value" fill={fillColor} fillOpacity={fillOpacity} stackId={token.code}/>
                      );
                    }
                  })
                )}
                <CartesianGrid vertical={false} stroke={`${hslStrToHex(theme.palette.text.primary)}22`} />
                <Tooltip content={<ChartTooltip/>}/>
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  </>);
}

export default MarketsHistoricalVolumeChart;