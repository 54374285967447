
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import moment, { Moment } from "moment";
import { INFINITY_CHAIN_ID_ETHEREUM } from 'src/constants/app';
import { z } from 'zod';
import { BlockChainSchema, ETHUserActionEvent, GlossaryItem, ServerTimeSchema, AccountsLossGivenDefaultSchema, ServerStatusSchema, ServerStatus, ServerTime } from '../../types';
import { get, zp } from './common';

/**
 * get server time & market rollover time from server
 * @returns isPastMarketRolloverHour 
 * @returns marketRolloverDayOffset
 * @returns nextMarketRolloverTime 
 */
export function useMarketRolloverHour():{
  isPastMarketRolloverHour: boolean;
  marketRolloverHour: number,
  marketRolloverDayOffset: number;
  nextMarketRolloverTime?: Moment;
}{
  const {data} = useServerTime();
  if(!data){
    return {isPastMarketRolloverHour:false,marketRolloverHour:0,marketRolloverDayOffset:0,nextMarketRolloverTime:undefined};
  }
  const {serverTime,marketRolloverHour} = data;
  const isPastMarketRolloverHour = moment(serverTime).utc().hour()>=marketRolloverHour;
  const marketRolloverDayOffset = (isPastMarketRolloverHour?0:-1);
  const nextMarketRolloverTime = moment(serverTime).utc().hour(marketRolloverHour).startOf('hour').add(isPastMarketRolloverHour?1:0,'day');
  return {isPastMarketRolloverHour, marketRolloverHour, marketRolloverDayOffset, nextMarketRolloverTime};
}
export function useHoursSinceRollover(){
  const {marketRolloverHour} = useMarketRolloverHour();
  return moment().utc().hour() - marketRolloverHour;
}
export function useServerTime(){
  return useQuery(['appState','serverTime'],async()=>await _getServerTime(),{ refetchInterval: 5*60*1000 });
}
async function _getServerTime() {
  const { data } = await get(`/api/server/time`, {});
  return zp<ServerTime>(ServerTimeSchema,data);
}
export function useServerStatus(){
  return useQuery(['appState','serverStatus'],async()=>await _getServerStatus(),{ refetchInterval: 5*60*1000 });
}
async function _getServerStatus() {
  const { data } = await get(`/api/server/status`, {});
  return zp<ServerStatus>(ServerStatusSchema,data);
}

/**
 * To get the block chain data
* @param blockChainId  - For ethereum, it is 1
* @returns BlockChain blockChain
 */
export function useBlockchain(blockChainId:number,depositEvents?:ETHUserActionEvent[]){
  return useQuery(['appState','blockChain',INFINITY_CHAIN_ID_ETHEREUM,depositEvents?.length],async()=>await _getBlockchain(INFINITY_CHAIN_ID_ETHEREUM),{ enabled:true/* !!((depositEvents?.length||0)>0) */,refetchInterval: 7000 });
}
async function _getBlockchain(blockChainId:number) {
  const { data } = await get(`/api/p/blockChain`, {
    blockChainId
  });
  const r = BlockChainSchema.safeParse(data.blockChain);
  return r.success?r.data:undefined;
}
/**
 * To get the public accounts loss given default
* @params priceChange number
* @returns object map of values {[tokenId]:Array<AccountsLossGivenDefault>}
 */
export function useAccountsLossGivenDefault({priceChange,userAddress}:{priceChange:number,userAddress?:string}){
  return useQuery(['appState','accountsLossGivenDefault',priceChange],async()=>await _getAccountsLossGivenDefault({priceChange,userAddress}),{});
}
async function _getAccountsLossGivenDefault({priceChange,userAddress}:{priceChange:number,userAddress?:string}){
  const { data } = await get(`/api/p/accounts/lossgivendefault`, {
    priceChange, userAddress
  });
  const r = z.record(z.string(),z.array(AccountsLossGivenDefaultSchema)).safeParse(data.accountsLossGivenDefault);
  return r.success?r.data:undefined;
}

/**
 * Glossary map json
* @returns BlockChain blockChain
 */
export function useGlossaryMap(){
  return useQuery<Map<string,GlossaryItem>>(['appState','glossaryMap'],async()=>{
    const { data } = await axios.get(`/data/glossary.json`);
    const glossaryMap = data?.reduce((map:Map<string,GlossaryItem>,glossaryItem:any)=>{
      ([glossaryItem.key,...(glossaryItem.alias||[])]).forEach(key=>{
        const {context} = glossaryItem;
        if(context) key = `${context}:${key}`;
        map.set(key.toLowerCase(),glossaryItem);
      });
      return map;
    },new Map<string,GlossaryItem>());
    return glossaryMap;
  });
}