import { useCallback } from "react";
import { MY_PORTFOLIO_TABS } from "src/Pages/MyPortfolio";
import { BaseMarket, MarketForSymbol, Token } from "src/types";
import { useCallbackRollingDateLabel } from "./date";

export const paths = {
  lendAndBorrow: ()=>`/lend-borrow`,
  lendAndBorrowMarket: (token:Token)=>`/lend-borrow/${token.code}`,
  liquidity: ()=>`/liquidity`,
  liquidityPage: (market:BaseMarket)=>`/liquidity/${market.instrumentId}`,
  swapPage: (token:Token | undefined)=> token ? `/swap/${token.code}` : '/swap',
  useCallbackTradePage: ()=>{
    const getRollingDateLabel = useCallbackRollingDateLabel();
    return useCallback((market?:MarketForSymbol,marketName?:string)=>!market?`/trade`:`/trade/${market.token.tokenId}/${market.marketId}/${market.token.code}-${getRollingDateLabel(market)}`,[getRollingDateLabel]);
  },
  useCallbackSwapPage: ()=>{
    const getRollingDateLabel = useCallbackRollingDateLabel();
    return useCallback((market:MarketForSymbol,marketName?:string)=>`/trade/${market.token.tokenId}/${market.marketId}/${market.token.code}-${getRollingDateLabel(market)}`,[getRollingDateLabel]);
  },
  portfolioPage: (tab:MY_PORTFOLIO_TABS, subtab?:string)=>`/my-portfolio/${tab}${subtab ? `/${subtab}` : ''}`,
  explorePage: (tab:"overview"|"trades"|"accounts")=>`/explore/${tab}`,
  exploreUserPage: (userAddress:string)=>`/explore/user/${userAddress}`,
}
