import Close from '@mui/icons-material/Close';
import {
  Box, Dialog, DialogActions, DialogTitle, IconButton, SxProps
} from '@mui/material';
import { sxDialog, sxDialogActions, sxDialogCloseButton, sxDialogPanel, sxDialogTitle } from '../styles/Dialog';

function StandardDialog({
  title=null,content=null,actions=null,open=false,handleClose,disableBackdropClick=false, sxCustomDialog={}, width,
}:{
  title?:JSX.Element|string|null,content?:JSX.Element|string|null,actions?:JSX.Element|string|null,open:boolean,handleClose?:Function,disableBackdropClick?:boolean,sxCustomDialog?:SxProps, width?:number
}){
  return (
    <Dialog sx={{...sxDialog, ...sxCustomDialog} as SxProps} PaperProps={{
      sx:{width:width?`${width}px`:undefined}
    }} onClose={(event:Object,reason:string)=>{
      if(
        // reason=="backdropClick"&& // "backdropClick"|"escapeKeyDown"
      disableBackdropClick) return;
      handleClose && handleClose();
    }} open={open}>
      {(title !== null || handleClose !== undefined) && <DialogTitle sx={sxDialogTitle}>
        {title}
        {handleClose&&<IconButton aria-label="close" onClick={()=>handleClose()} sx={sxDialogCloseButton}><Close/></IconButton>}
      </DialogTitle>}
      <Box sx={sxDialogPanel}>{content}</Box>
      <DialogActions sx={sxDialogActions}>{actions}</DialogActions>
    </Dialog>
  );
}

export default StandardDialog;