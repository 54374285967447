import { Box, SxProps } from '@mui/material';
import { TokenForIcon } from 'src/types';
const styleOuterBox:SxProps = {display:"inline-block",verticalAlign:"top",alignSelf:"center"};
const styleBox:SxProps = {display:"flex",alignItems:"center",whiteSpace:"nowrap"};
const styleIcon:SxProps = {
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  display: 'inline-block',
  verticalAlign: 'bottom',
  whiteSpace: 'nowrap',
  pointerEvents:'none',
};
function TokenIcon({
  token,size=18,variant='caps',withCode=false,withName=false,keepsWETH=false,
}:{
  token?:TokenForIcon,size?:number,variant?:'caps'|'light'|'bold',withCode?:boolean,withName?:boolean,keepsWETH?:boolean,
}) {
  // custom WETH -> ETH logic
  const tokenCode = !keepsWETH&&token?.code==='WETH'?'ETH':token?.code;
  const tokenName = !keepsWETH&&token?.name==='WETH'?'ETH':token?.name;
  
  const bgImgUrl = tokenCode?`${process.env.PUBLIC_URL}/images/tokens/${tokenCode.toUpperCase()}.png`:'';
  const bgBlankImgUrl = `${process.env.PUBLIC_URL}/images/tokens/blank.png`;
  const fontSize = `${size}px`;
  const iconSize = `${size*3/2}px`;
  let fontWeight = 400;
  switch(variant){
    case 'light': fontWeight = 300; break;
    case 'bold': fontWeight = 700; break;
  }
  return (
  <Box sx={styleOuterBox}>
    <Box sx={styleBox} style={{
      fontSize, fontWeight, 
    }}>
      <Box component="i" sx={styleIcon} style={{
        backgroundImage:`url(${bgImgUrl}), url(${bgBlankImgUrl})`,
        fontSize: iconSize, lineHeight: fontSize
      }}>　</Box>
      {withCode&&<>{tokenCode||'Unknown'}{!!token?.erc721TokenId?' - '+token.erc721TokenId:''}</> || withName&&<>{tokenName||'Unknown'}</>}
    </Box>
  </Box>);
}
export default TokenIcon;