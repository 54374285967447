import { Box, Button, Link, SxProps } from '@mui/material';
import { useNavigate } from 'react-router';
import { sxLabel } from 'src/styles/General';
import { paths } from 'src/utils/paths';

const sxWrapper:SxProps = {
  bgcolor:'#00000066',position:'absolute',top:0,left:0,right:0,bottom:0,display:'grid',alignItems:'center',justifyContent:'center',zIndex:100
};
const sxInner:SxProps = {
  // position:'absolute',top:0,left:0,right:0,bottom:0
  textAlign: 'center', maxWidth: '19rem',
};

function GraphEmptyStateCurtain({extraText,showsStartTrading}:{extraText?:string,showsStartTrading?:boolean}) {
  const navigate = useNavigate();
  const tradePage = paths.useCallbackTradePage();
  return (
  <Box sx={sxWrapper}>
    <Box sx={sxInner}>
      {showsStartTrading&&<Button component={Link} color="primary" variant="contained" onClick={()=>{ navigate(tradePage()); }}>Start Trading</Button>}
      {extraText&&<Box sx={sxLabel}><br/>{extraText}</Box>}
    </Box>
  </Box>);
}

export default GraphEmptyStateCurtain;
