import { Box, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';
import { TRADE_HISTORY_TABLE_PAGE_LIMIT } from 'src/constants/app';
import { useAuth } from 'src/AuthProvider';
import { sxEmptyRow, sxTokenTable, sxTokenTableContainer } from 'src/styles/TokenTable';
import { sxBorderRight, sxListOuter } from 'src/styles/Table';
import { Token, UserAccount, OrderTableHeadCell, TradeHistoryItem } from 'src/types';
import apis from 'src/utils/apis';
import InfoTooltipIcon from 'src/Components/InfoTooltipIcon';
import LoadingIcon from 'src/Components/Loading';
import OrderCancellationDialog from 'src/Components/OrderCancellationDialog'
import { 
    CancelOrdersSnackbar,
    ConfirmationDialogElement,
    TradeHistoryTableHead,
    TradeHistoryTableRow,
    useRowSelection,
    usePagination,
    useUserTradeHistoryByMarket
} from 'src/Components/TradeHistoryTable'
import { useRef } from 'react';

const tradeHistoryTokenMarketHeadCells: readonly OrderTableHeadCell[] = [
    {
      id: 'tokenId',
      align: 'left',
      disablePadding: false,
      label: 'Token',
      sticky: true,
      width: '5rem',
      withSelector: ( type: 'open' | 'history' ) => type === 'open'
    },
    {
      id: 'quantityVector',
      align: 'right',
      disablePadding: false,
      label: 'Size',
    },
    {
      id: 'maturityDate',
      align: 'right',
      disablePadding: false,
      width: '5rem',
      label: 'Maturity',
    },
    {
      id: 'executed',
      align: 'right',
      disablePadding: false,
      label: <>Executed<InfoTooltipIcon sx={{ width: '1rem', height: '1rem', position: 'relative', top: '4px'}} title={
          `Current market at the time of execution`
        } outlined/></>, // Maturity on oexecution
      width: '5rem',
      type: 'history'
    },
    {
      id: 'rate',
      align: 'right',
      disablePadding: false,
      label: 'Rate',
      style: sxBorderRight
    },
    {
      id: 'orderType',
      align: 'right',
      disablePadding: false,
      label: 'Order Type',
    },
    {
      id: 'status',
      align: 'right',
      disablePadding: false,
      width: ( type: string ) => type === 'open' ? '13rem' : '16rem',
      label: 'Status',
    },
    {
      id: 'orderDate',
      align: 'right',
      disablePadding: false,
      width: '9.5rem',
      label: 'Create Time',
    },
    {
      id: 'updateDate',
      align: 'right',
      disablePadding: false,
      width: '9.5rem',
      label: 'Update Time',
    },
    {
      id: 'action',
      align: 'center',
      disablePadding: false,
      label: 'Action',
      type: 'open'
    },
];

export const TradeHistoryTableByMarket = ({
    account,token,selectedTokenId,selectedMarketId,type
}:{
  account?:UserAccount,token?:Token,selectedTokenId?:number,selectedMarketId?:number,
  type?:'open'|'history'
}) => {
  const auth = useAuth();
  const multiCancelDialogRef = useRef<ConfirmationDialogElement | null>(null)

  // pagination 
  const pageLimit = TRADE_HISTORY_TABLE_PAGE_LIMIT;
  const {
    getPage,
    getCurrentLastOrderIds,
    updateLastOrderIds,
    getMaxCount,
    setPage
  } = usePagination()

  const currentMaxCount = getMaxCount(`${selectedMarketId}-${type}`)
  const currentPage = getPage(`${selectedMarketId}-${type}`)

  const {
      isLoadingTable,
      orderHistoryItems,
      isFetchingUpdates,
  } = useUserTradeHistoryByMarket({ selectedMarketId, type, getCurrentLastOrderIds, updateLastOrderIds, page: currentPage })

  // apis
  const {data:tokens} = apis.token.useTokens();
  const {
      onRowSelectionChange,
      onHeaderSelectionChange,
      selectedOrders,
      onCancelOrderSuccess,
      handleCancelOrderModalClose,
      selectionStatus,
      isRowSelected
  } = useRowSelection(orderHistoryItems)

  if(!auth.user) return null;
  const table = (
      <Box sx={{...sxListOuter, width:'100%', bgcolor: 'table.dark'}}>
          {/* {(isAllRowSelected || isSomeRowSelected) && createPortal(<Box sx={{ width: '100px', border: '1px solid white', zIndex: 10, position: 'absolute', bottom: `${FOOTER_HEIGHT_VALUE + 10}px`, right: '50%'}}>SOMETHING</Box>, document.body)} */}
          {(selectionStatus !== 'none') && <CancelOrdersSnackbar orders={selectedOrders} dialogRef={multiCancelDialogRef} handleClose={handleCancelOrderModalClose}/>}
          <TableContainer component={Box} sx={sxTokenTableContainer}>
          <Table sx={sxTokenTable} aria-label="Markets" size="medium" stickyHeader>
              <TradeHistoryTableHead
              cells={tradeHistoryTokenMarketHeadCells.filter((cell: OrderTableHeadCell) => cell.type === undefined || cell.type === type)}
              selectionStatus={selectionStatus ?? 'none'}
              onSelectionChange={onHeaderSelectionChange}
              type={type}
              />
              <TableBody>
              {(isLoadingTable)?
              <TableRow hover sx={sxEmptyRow}><TableCell colSpan={12}><LoadingIcon inline={true}/></TableCell></TableRow>:<>
              {orderHistoryItems.map((orderHistoryItem: TradeHistoryItem, index: number) => {
                  const token = tokens?.filter(token => token.tokenId === orderHistoryItem?.tokenId)[0];
                  const orderId = orderHistoryItem?.orderId ?? 0
                  return <TradeHistoryTableRow key={index} orderHistoryItem={orderHistoryItem} token={token} type={type} onSelectionChange={onRowSelectionChange(orderId)} isSelected={isRowSelected(orderId)} dialogRef={multiCancelDialogRef}/>
              })}
              {orderHistoryItems?.length === 0 && <TableRow hover sx={sxEmptyRow}>
                  <TableCell colSpan={12}>
                  { type === 'open' ? 'No open orders yet.' : 'No orders have been placed.'}
                  </TableCell>
              </TableRow>}
              </>}
              </TableBody>
          </Table>
          {(isFetchingUpdates && !isLoadingTable)&&<LoadingIcon curtain/>}
          </TableContainer>
          <TablePagination
          component={Box}
          rowsPerPageOptions={[]}

          count={currentMaxCount}
          rowsPerPage={pageLimit}
          page={currentPage}
          showFirstButton={true}
          showLastButton={true}
          sx={{position:'sticky',right:'auto',left:0,zIndex:1, borderTopWidth: '1px', borderTopStyle: 'solid', borderTopColor: 'border.dark'}}
          onPageChange={(event:any,newPage:number)=>{
              setPage(`${selectedMarketId}-${type}`, newPage);
          }}
          />
          <OrderCancellationDialog onSuccess={onCancelOrderSuccess} ref={multiCancelDialogRef}/>
      </Box>
  );
  return table;
}