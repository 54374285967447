
import { Box, Button, ButtonGroup, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import Decimal from 'decimal.js';
import { useMemo, useState } from 'react';
import { INFINITY_CHAIN_ID_ETHEREUM, TRADE_HISTORY_TABLE_PAGE_LIMIT } from 'src/constants/app';
import { sxTokenSwitchItem } from 'src/styles/Chart';
import { useAuth } from '../AuthProvider';
import { sxTableBorder } from '../styles/General';
import { sxBorrowAmount, sxDate, sxEmptyRow, sxTokenTable, sxTokenTableContainer, sxTokenTableFilterBox } from '../styles/TokenTable';
import { AccountTransaction, BlockChain, EDepositEventStatus, EUserAccountType, EAccountTransactionType, ETHUserActionEvent, ETransferAccountType, getTransactionTypeText, getAccountTypeText, UserAccount, Token, WithdrawalRequest } from '../types';
import apis from '../utils/apis';
import { format, formatDateTime } from '../utils/numbers';
import ChainTransactionLink from './ChainTransactionLink';
import LoadingIcon from './Loading';
import TokenIcon from './TokenIcon';
import ValueTableCell from './ValueTableCell';


const styleGrey:SxProps = {
  opacity: .5,
};

interface HeadCell {
  disablePadding: boolean;
  id: keyof AccountTransactionTableItem;
  label: string;
  align: 'left'|'center'|'right';
}

interface AccountTransactionTableItem {
  tokenId: number,
  padding: void,
  fromAccount: string,
  toAccount: string,
  quantity: number,
  createDate: number,
  action?: unknown,
}
const tradeHistoryHeadCells: readonly HeadCell[] = [
  {
    id: 'fromAccount',
    align: 'left',
    disablePadding: false,
    label: 'From',
  },
  {
    id: 'toAccount',
    align: 'left',
    disablePadding: false,
    label: 'To',
  },
  {
    id: 'tokenId',
    align: 'left',
    disablePadding: false,
    label: 'Tokens',
  },
  {
    id: 'padding',
    align: 'left',
    disablePadding: false,
    label: '',
  },
  {
    id: 'quantity',
    align: 'left',
    disablePadding: false,
    label: 'size',
  },
  {
    id: 'action',
    align: 'left',
    disablePadding: false,
    label: 'Action',
  },
  {
    id: 'createDate',
    align: 'left',
    disablePadding: false,
    label: 'Date',
  },
];

const sxOuter:SxProps = {
  my: '8px',
};

const sxTransferHistoryTokenTable: SxProps = {
  ...sxTokenTable,
  "th": {
    fontSize: '0.75rem',
    fontWeight: 700,
    textTransform: 'uppercase',
  }
}
const AGGREGATED_TRANSACTION_TYPES = new Map([
  ["Transfers", [
    EAccountTransactionType.EXTERNAL_TRANSFER_IN,EAccountTransactionType.EXTERNAL_TRANSFER_OUT,
    EAccountTransactionType.TRANSFER_IN,EAccountTransactionType.TRANSFER_OUT,
  ]],
  [getTransactionTypeText(EAccountTransactionType.INTEREST),[EAccountTransactionType.INTEREST]],
  [getTransactionTypeText(EAccountTransactionType.TYPE_LIQUIDATE_UNLEND),[EAccountTransactionType.TYPE_LIQUIDATE_UNLEND]],
]);
function TransactionTypeSelector({ initialTypes=[], onChange }:{ initialTypes?:string[], onChange?:(selectedTypes:string[])=>any }){
  const [selectedTypes,setSelectedTypes] = useState(initialTypes); 
  return (
    <ButtonGroup sx={sxOuter} variant="outlined" aria-label="outlined primary button group">
    {[...AGGREGATED_TRANSACTION_TYPES.entries()].map(([key,types],i)=>{
      // const color = graphLineColors[i%graphLineColors.length];
      const inactive = selectedTypes&&selectedTypes.indexOf(key)<0;
      return (
        <Button key={i} sx={sxTokenSwitchItem} variant={inactive?'outlined':'contained'} 
          onClick={()=>{
            const types = selectedTypes.indexOf(key)<0?
            [...selectedTypes,key]: // add
            selectedTypes.filter(c=>c!==key) // remove
            ;
            setSelectedTypes(types);
            onChange&&onChange(types);
          }}
        >{key}</Button>
      )
    })}
    </ButtonGroup>
  );
}

function getWithdrawRequestRows({withdrawRequests = [], tokens}: {withdrawRequests?: WithdrawalRequest[], tokens: Token[]}) {
  return withdrawRequests?.map((w,index)=>{
    const { tokenId, quantity, status, transactionHash } = w;
    const q = new Decimal(quantity);
    // const account = userAccounts.find(uw=>uw.accountId==accountId);
    const token = tokens.find(token=>token.tokenId==tokenId);
    const statusText = transactionHash?<>Tx Hash: <ChainTransactionLink transactionHash={transactionHash}/></>:``;
    return (
      <TableRow hover tabIndex={-1} key={`withdraw-${index}`}>
        <ValueTableCell mono width="33%" label="From">Current Account</ValueTableCell>
        <ValueTableCell mono width="33%" label="To">Your Wallet</ValueTableCell>
        <ValueTableCell order={-10} width="50%"><TokenIcon token={token} withCode={true}/></ValueTableCell>
        <ValueTableCell order={10}>{statusText}</ValueTableCell>
        <ValueTableCell mono width="33%" label="Size">{format(q)}</ValueTableCell>
        <ValueTableCell mono width="33%" label="Action">Withdraw</ValueTableCell>
        <ValueTableCell order={-9} width="50%" label="Date">In Progress</ValueTableCell>
      </TableRow>
    );
  })
}

function getDepositEventRows({depositEvents = [], tokens, blockChain}: { depositEvents?: ETHUserActionEvent[], tokens: Token[], blockChain: BlockChain}) {
  return depositEvents?.map((event,index)=>{
    return event.deposits.reduce((rows,{ a:tokenAddress,q: quantityInDecimals,s: status },index2)=>{
      if(status==EDepositEventStatus.PENDING){
        const token = tokens.find(token=>token.tokenAddress==tokenAddress);
        if(token){
          let iconToken = {...token};
          let q = new Decimal(quantityInDecimals).div(new Decimal(10).pow(token.decimals));
          const blockDifference = Math.min(15,Math.max(0,(blockChain?.processedBlockNum||0)-event.blockIdx));
          if (token.tokenType==2) {
            iconToken.erc721TokenId = parseInt(q.toString()).toString();
            q = new Decimal('1');
          }
          rows.push((
            <TableRow hover tabIndex={-1} key={`${index}-${index2}`}>
              <ValueTableCell mono width="33%" label="From">Your Wallet</ValueTableCell>
              <ValueTableCell mono width="33%" label="To">Current Account</ValueTableCell>
              <ValueTableCell order={-10} width="50%"><TokenIcon token={iconToken} withCode={true}/></ValueTableCell>
              <ValueTableCell order={10}></ValueTableCell>
              <ValueTableCell mono width="33%" label="Size">{format(q)}</ValueTableCell>
              <ValueTableCell mono width="33%" label="Action">Deposit</ValueTableCell>
              <ValueTableCell order={-9} width="50%" label="Date">In Progress ({blockDifference}/15)</ValueTableCell>
            </TableRow>
          ));
        }
      }
      return rows;
    },Array<JSX.Element>());
  })
}

function getAccountTransactionRows({userAccounts, accountTransactions = [], tokens}: { userAccounts: UserAccount[], accountTransactions?: AccountTransaction[], tokens: Token[]}) {
  const emptyCell = <Box>-</Box>;
  return accountTransactions?.filter(trx=>{
    // for transfers - only shows current account ones
    if([EAccountTransactionType.TRANSFER_IN, EAccountTransactionType.TRANSFER_OUT, EAccountTransactionType.EXTERNAL_TRANSFER_IN, EAccountTransactionType.EXTERNAL_TRANSFER_OUT].includes(trx.type)){
      return userAccounts.find(uw=>uw.accountId===trx.accountId)?.type===EUserAccountType.CURRENT;
    }
    return true;
  }).map((trx, index) => {
    const { trxId, accountId, tokenId, type, quantity, balance, createDate, erc721TokenId } = trx;
    const account = userAccounts.find(uw=>uw.accountId==accountId);
    const token = tokens.find(token=>token.tokenId==tokenId);
    let iconToken = token && {...token};
    // const df = new Decimal(10).pow(token.decimals);
    // const b = new Decimal(balance);//.mul(df);
    let q = new Decimal(quantity);//.mul(df);
    // for nft only
    if (iconToken&&erc721TokenId) {
      iconToken.erc721TokenId = erc721TokenId.toString();
    }
        // components to be rendered
    let fromCell = emptyCell;
    let toCell = emptyCell;
    // check trx.type & wallet.type to determine quantities
    switch(type){
      case EAccountTransactionType.EXTERNAL_TRANSFER_IN: 
        fromCell = (<>{getAccountTypeText(ETransferAccountType.YOUR_WALLET)}</>)
        toCell = (<>{getAccountTypeText(ETransferAccountType.CURRENT_ACCOUNT)}</>)
        break;
      case EAccountTransactionType.EXTERNAL_TRANSFER_OUT: 
        fromCell = (<>{getAccountTypeText(ETransferAccountType.CURRENT_ACCOUNT)}</>)
        toCell = (<>{getAccountTypeText(ETransferAccountType.YOUR_WALLET)}</>)
        break;
      // internal transfers might be either current or trading
      case EAccountTransactionType.TRANSFER_IN:
        fromCell = (<>{getAccountTypeText(ETransferAccountType.TRADING_ACCOUNT)}</>)
        toCell = (<>{getAccountTypeText(ETransferAccountType.CURRENT_ACCOUNT)}</>)
        break;
      case EAccountTransactionType.TRANSFER_OUT:
        fromCell = (<>{getAccountTypeText(ETransferAccountType.CURRENT_ACCOUNT)}</>)
        toCell = (<>{getAccountTypeText(ETransferAccountType.TRADING_ACCOUNT)}</>)
        break;
      case EAccountTransactionType.INTEREST: 
        switch(account?.type){
          case EUserAccountType.CURRENT:
            toCell = (<>{getAccountTypeText(ETransferAccountType.CURRENT_ACCOUNT)}</>)
            break;
          case EUserAccountType.TRADING:
            toCell = (<>{getAccountTypeText(ETransferAccountType.TRADING_ACCOUNT)}</>)
            break;
        }
        break;
    }
    const actionText = getTransactionTypeText(type);
    return (
      <TableRow hover tabIndex={-1} key={`trx-${index}`}>
        <ValueTableCell mono width="33%" label="From">{fromCell}</ValueTableCell>
        <ValueTableCell mono width="33%" label="To">{toCell}</ValueTableCell>
        <ValueTableCell order={-10} width="50%"><TokenIcon token={iconToken} withCode={true}/></ValueTableCell>
        <ValueTableCell order={10}></ValueTableCell>
        <ValueTableCell mono width="33%" label="Size">{format(q.abs())}</ValueTableCell>
        <ValueTableCell mono width="33%" label="Action">{actionText}</ValueTableCell>
        <ValueTableCell order={-9} width="50%" label="Date"><Box sx={sxDate}>{formatDateTime(createDate)}</Box></ValueTableCell>
      </TableRow>
    );
  })
}

function CashTransfersHsitory(){
  const auth = useAuth();
  const {data:tokens} = apis.token.useTokens();
  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  const currentAccount = userAccounts?.find(uw=>uw.type===EUserAccountType.CURRENT);
  // ongoing events
  const { data:withdrawRequests, isLoading:isLoadingWithdrawRequests } = apis.user.useUserWithdrawRequests(auth);
  const { data:depositEvents, isLoading:isLoadingDepositEvents } = apis.user.useUserDepositEvents(auth);
  const { data:blockChain } = apis.infinity.useBlockchain(INFINITY_CHAIN_ID_ETHEREUM,depositEvents);
  // actual histories
  const [selectedTransactionTypes,setSelectedTransactionTypes] = useState<string[]>([]);
  const transactionTypes = useMemo(()=>{
    const unselected = selectedTransactionTypes.length===0;
    return [...AGGREGATED_TRANSACTION_TYPES.entries()].reduce((ret,[key,types])=>{
      if(unselected||selectedTransactionTypes.includes(key)) return [...ret,...types];
      return ret;
    },new Array<EAccountTransactionType>());
  },[selectedTransactionTypes]);
  const pageLimit = TRADE_HISTORY_TABLE_PAGE_LIMIT;
  const [page,setPage] = useState(0);
  const [pageStartIdMap,setPageStartIdMap] = useState<any>({});
  const [maxCount,setMaxCount] = useState(-1);
  const { data, isLoading:isLoadingAccountTransactions, isFetching:isFetchingAccountTransactions } = apis.user.useUserAccountTransactions(auth,{
    startId:pageStartIdMap[page],limit:pageLimit,types:transactionTypes,
    accountId:currentAccount?.accountId, enabled:!!currentAccount,
  },(trxs,hasNextPage,pageStartId)=>{
    if(!hasNextPage) setMaxCount(page*pageLimit+trxs.length);
    setPageStartIdMap({...pageStartIdMap,[page+1]:pageStartId});
  });
  const accountTransactions = data?.trxs;
  const isLoadingTable = isLoadingWithdrawRequests || isLoadingDepositEvents || isLoadingAccountTransactions
  const tableRows = useMemo(() => {
    if (tokens && userAccounts && blockChain) {
      if (page === 0) {
        const depositEventRows = getDepositEventRows({ depositEvents, tokens, blockChain })
        const withdrawRequestRows = getWithdrawRequestRows({ withdrawRequests, tokens})
        const accountTransactionRows = getAccountTransactionRows({ userAccounts, accountTransactions, tokens})
        return depositEventRows.concat(withdrawRequestRows).concat(accountTransactionRows)
      } else {
        return getAccountTransactionRows({ userAccounts, accountTransactions, tokens})
      }
    }
    return []
  }, [tokens, userAccounts, blockChain, depositEvents, page, accountTransactions, withdrawRequests])
  return (<Box sx={{position:'relative', ...sxTableBorder, bgcolor: 'table.dark' }}>
    <Box sx={sxTokenTableFilterBox}><TransactionTypeSelector onChange={(selectedTypes=>setSelectedTransactionTypes(selectedTypes))}/></Box>
    <TableContainer component={Box} sx={sxTokenTableContainer}>
      <Table sx={sxTransferHistoryTokenTable} aria-label="Transfer History" size="medium">
        <TableHead>
          <TableRow>
            {tradeHistoryHeadCells.map((headCell,idx) => (
              <ValueTableCell
                key={idx}
                align={headCell.align}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                // sticky={idx==0}
              >
                {headCell.label}
              </ValueTableCell>
            ))}
          </TableRow>
        </TableHead>
        {(isLoadingTable)?
          <TableBody><TableRow hover sx={sxEmptyRow}><TableCell colSpan={10}><LoadingIcon inline={true}/></TableCell></TableRow></TableBody>:<>
        <TableBody>
          {tableRows.map(t => t)}
          {tableRows?.length === 0 && <TableRow hover sx={sxEmptyRow}>
            <TableCell colSpan={10}>
              Cash transfer history not found.
            </TableCell>
          </TableRow>}
        </TableBody>
        </>}
      </Table>
      {(isFetchingAccountTransactions && !isLoadingTable)&&<LoadingIcon curtain/>}
    </TableContainer>
    <TablePagination
      component={Box}
      rowsPerPageOptions={[/* 5,10,25,{label:'All',value:-1} */]}
      // labelRowsPerPage={null}
      // colSpan={1}
      count={page*pageLimit+tableRows.length}
      rowsPerPage={pageLimit}
      page={page}
      showFirstButton={true}
      showLastButton={true}
      sx={{position:'sticky', right:'auto', left:0, zIndex:1, borderTopStyle: 'solid', borderTopColor: 'border.dark'}}
      onPageChange={(event:any,newPage:number)=>{
        setPage(newPage);
      }}
    />
  </Box>);
}

export default CashTransfersHsitory;