import {
  Box, Button, ButtonGroup, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import Decimal from 'decimal.js';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Area, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { hslStrToHex } from 'src/utils/common';
import { date, formatMarketDate, useRollingDates } from 'src/utils/date';
import { capitalize } from 'src/utils/string';
import theme from 'src/utils/theme';
import { graphAreaColors, graphLineColors, sxChartContainer, sxChartHeader, sxChartTooltip, sxChartTooltipLegendBox, sxChartTooltipLegendBoxTitle, sxChartTooltipTable, sxTokenSwitchItem, sxChartWatermarkCenter, sxChartTooltipLegendLine } from '../styles/Chart';
import { styleBoxBlock, styleBoxPanel } from '../styles/General';
import { MarketHistoricalRateItem, Token } from '../types';
import apis from '../utils/apis';
import LoadingIcon from './Loading';
import RechartsDateAxisTicks from './RechartsDateAxisTicks';
import DefaultTokensSelector from './DefaultTokensSelector';
import { DEFAULT_MARKETS_PAGE_GRAPH_MARKET_DAYS } from 'src/constants/app';
import { format, getEqualDistanceTicks } from 'src/utils/numbers';
import { type } from 'os';
import ValueTableCell from './ValueTableCell';
import { isEqual } from 'lodash';

function MarketsHistoricalRatesChart({tokens,initTokenCodes}:{tokens?:Token[],initTokenCodes?:string[]}){
  const MARKET_KEY_FLOAT = 'FLOAT';
  const maxDays = 28;
  const [chartData, setChartData] = useState<any>([]);
  const rollingDayLabels = [MARKET_KEY_FLOAT,...useRollingDates().map(({label})=>label)];
  const initialMarketKeys = rollingDayLabels.filter((label)=>DEFAULT_MARKETS_PAGE_GRAPH_MARKET_DAYS.includes(label));
  const [filteredMarketKeys,setFilteredMarketKeys] = useState<string[]>(initialMarketKeys);
  const [filteredTokenCodes,setFilteredGraphTokenCodes] = useState(initTokenCodes||[]);
  const tokenIds = tokens?.map(t=>t.tokenId);
  const { isLoading, data:historicalRatesByToken } = apis.rate.useMarketsHistoricalRates({
    tokenIds, daysToInclude:maxDays,
  });
  const { data:historicalFixedRatesByToken } = apis.fixedrate.useMarketsYieldCurveHistoricalByTokens({
    tokenIds,
    daysToInclude:maxDays,
  });
  // generate rechart data
  // convert market map to date map
  const {data,dataMax,yAxisTicks,xAxisTicks,marketKeys} = useMemo(()=>{
    const marketKeyMap = {};
    const rateDateMap = {};
    let dataMax = new Decimal(0);
    let dataMin = new Decimal(0);
    for(let tokenId in historicalRatesByToken){
      const token = tokens!.filter(t=>t.tokenId==parseInt(tokenId))[0];
      const {fixed:historicalRatesFixed,floating:historicalRatesFloating} = historicalRatesByToken[tokenId];
      // eslint-disable-next-line no-loop-func
      historicalRatesFloating.forEach((r:MarketHistoricalRateItem)=>{
        const t = date(r.date).utc().hour(0).valueOf();
        const name = formatMarketDate(moment(t))
        const rate = new Decimal(r.high||0).add(new Decimal(r.low||0)).div(2).mul(100);
        rateDateMap[t] = Object.assign({},rateDateMap[t],{t,name,[`${token.code}-${MARKET_KEY_FLOAT}`]:rate});
        if(filteredMarketKeys.indexOf(MARKET_KEY_FLOAT)>=0&&(filteredTokenCodes?.indexOf(token.code)||0)>=0){
          dataMax = Decimal.max(rate,dataMax);
          dataMin = Decimal.min(rate,dataMin);
        }
      });
    }
    for(let tokenId in historicalFixedRatesByToken){
      const token = tokens!.filter(t=>t.tokenId==parseInt(tokenId))[0];
      const {historicalCurve,bucketLabels} = historicalFixedRatesByToken[tokenId];
      for(let genDateStr in historicalCurve){
        // eslint-disable-next-line no-loop-func
        historicalCurve[genDateStr].forEach((item)=>{
          const t = moment(genDateStr).utc().hour(0).valueOf();
          const marketKeyLabel = item.label;
          if(marketKeyLabel!=undefined) marketKeyMap[marketKeyLabel] = true;
          const name = formatMarketDate(moment(t))
          const rate = new Decimal(item.rate||0).mul(100);
          rateDateMap[t] = Object.assign({},rateDateMap[t],{t,name,[`${token.code}-${marketKeyLabel}`]:rate});
          if(filteredMarketKeys.indexOf(marketKeyLabel)>=0&&(filteredTokenCodes?.indexOf(token.code)||0)>=0){
            dataMax = Decimal.max(rate,dataMax);
            dataMin = Decimal.min(rate,dataMin);
          }
        });
      }
    }
    // console.log('rateDateMap',rateDateMap);
    const marketKeys = [MARKET_KEY_FLOAT,...Object.keys(marketKeyMap)];
    const xAxisTicks:number[] = [];
    const data:any[] = [];
    for(let date of Object.keys(rateDateMap).sort()){
      data.push(rateDateMap[date]);
      xAxisTicks.push(Number(date));
    }
    const yAxisTicks = getEqualDistanceTicks(dataMin.toNumber(),dataMax.toNumber());
    return {data,dataMax,yAxisTicks,xAxisTicks,marketKeys};
  },[historicalFixedRatesByToken,historicalRatesByToken,filteredTokenCodes,filteredMarketKeys,tokens]);

  useEffect(() => {
    if (!isEqual(data, chartData)) {
      setTimeout(() => {
        setChartData(data)
      }, 100)
    }
  }, [data, chartData, setChartData])
  
  // console.log('data',data);
  function toggleFilteredMarketKey(marketKey:string){
    setFilteredMarketKeys(
      filteredMarketKeys.indexOf(marketKey)<0?
      [...filteredMarketKeys,marketKey]: // add
      filteredMarketKeys.filter(k=>k!=marketKey) // remove
    );
  }

  function ChartLegend(props:any){
    const { /* chartHeight, */ chartWidth, height, /* width, */ iconSize, payload } = props;
    if(payload.length<=1) return null;
    return <div className="recharts-legend-wrapper" style={{
      position: 'absolute', width: chartWidth, height: height, right: 0, top: 0, transform: `translateY(calc(-100% - ${iconSize/2}px))`, 
    }}>
      <ul className="recharts-default-legend" style={{
        padding: '0px', margin: '0px', textAlign: 'right',
      }}>
        {payload.map((p:any,i:number)=>{
          return <li key={i} className="recharts-legend-item legend-item-0" style={{
            display: 'inline-block', marginRight: '10px', color: p.color,
          }}>
            <Box sx={sxChartTooltipLegendLine} style={{borderColor: p.color}}></Box> 
            {p.dataKey}
          </li>;
        })}
      </ul>
    </div>;
  }

  function ChartTooltip({active,payload,label}:any){
    if (active && payload && payload.length) {
      return (
        <Box sx={sxChartTooltip}>
          <Box sx={sxChartTooltipLegendBoxTitle}>{`${payload[0].payload.name}`}</Box>
          <Table sx={sxChartTooltipTable}>
            <TableBody>
              {payload.map((p:any,i:number)=>{
                return (
                  <TableRow key={i}>
                    <TableCell><Box sx={sxChartTooltipLegendBox} style={{backgroundColor:p.stroke}}></Box><b>{capitalize(p.name)}</b></TableCell>
                    <ValueTableCell mono align='right'>{new Decimal(p.value).toFixed(2)}%</ValueTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      );
    }
    return null;
  }
  const marketsAllSelected = filteredMarketKeys.length==rollingDayLabels?.length;
  const marketsNoneSelected = filteredMarketKeys.length==0;
  const sxChartContainerMerged = {
    ...sxChartContainer,
    position: 'relative',
    '::before': {
      ...sxChartWatermarkCenter!['::before'] ?? {},
      backgroundPosition: '50% 38%',
      backgroundSize: '15%'
    }
  }
  return (
    <Box sx={styleBoxBlock} style={{height:'100%',overflow:'hidden'}}>
      <Box sx={styleBoxPanel} style={{alignItems:'stretch',display:'flex',height:'100%'}}>
        <Box sx={sxChartHeader}>
          {/* <Box sx={sxChartHeading}>Historical Rates</Box> */}
          <ButtonGroup variant="outlined" aria-label="outlined primary button group">
          {rollingDayLabels.map((marketKey,i)=>{
            // const color = graphLineColors[i%graphLineColors.length];
            const inactive = filteredMarketKeys&&filteredMarketKeys.indexOf(marketKey)<0;
            return (
              // TODO toggle lines
              <Button key={i} sx={sxTokenSwitchItem} variant={inactive?'outlined':'contained'} onClick={()=>toggleFilteredMarketKey(marketKey)}>{capitalize(marketKey)}</Button>
            )
          })}
            <Button sx={sxTokenSwitchItem} variant={!marketsAllSelected?'outlined':'contained'} onClick={()=>setFilteredMarketKeys(rollingDayLabels)}>All</Button>
            <Button sx={sxTokenSwitchItem} variant={!marketsNoneSelected?'outlined':'contained'} onClick={()=>setFilteredMarketKeys([])}>None</Button>
          </ButtonGroup>
          <DefaultTokensSelector onChange={tokens=>setFilteredGraphTokenCodes(tokens)} showsAll initTokenCodes={initTokenCodes}/>
        </Box>
        <Box sx={sxChartContainerMerged}>
          <ResponsiveContainer /* aspect={2} height="auto" */ minHeight={300} width={'100%'}>
            <ComposedChart data={chartData} stackOffset="sign" margin={{left:-20}}>
              <YAxis yAxisId="rate" orientation='left' domain={[(dataMin:number)=>Math.min(dataMin,0),(dataMax:number)=>Math.ceil(dataMax)]} interval={"preserveStartEnd"} minTickGap={0} ticks={yAxisTicks} tickLine={false} tickFormatter={(v,i)=>`${new Decimal(v).toFixed(0)}%`} stroke={`${hslStrToHex(theme.palette.text.primary)}88`}/>
              <XAxis dataKey="t" scale="linear" domain={['dataMin','dataMax']} type="number" tickLine={false} interval={0} tick={<RechartsDateAxisTicks xAxisTicks={xAxisTicks}/>} stroke={`${hslStrToHex(theme.palette.text.primary)}88`}/>
              {tokens?.filter(token=>!(filteredTokenCodes&&filteredTokenCodes.indexOf(token.code)<0)).map((token,i)=>
                marketKeys.filter(marketKey=>!(filteredMarketKeys&&filteredMarketKeys.indexOf(marketKey)<0)).map((marketKey,j)=>{
                  const lineColor = graphLineColors[i%graphLineColors.length];
                  const areaColor = graphAreaColors[i%graphAreaColors.length]; 
                  return (<>
                    {chartData.length > 0 && <Area key={`${i}-${j}`} name={`${token.code}-${marketKey}`} type="monotone" dataKey={`${token.code}-${marketKey}`} yAxisId="rate" strokeWidth={2} stroke={lineColor} fillOpacity={1} fill={`url(#fill_rates_${i})`} dot={false}/>}
                    <defs><linearGradient id={`fill_rates_${i}`} x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor={areaColor} stopOpacity={0.5}/>
                      <stop offset="0%" stopColor={areaColor} stopOpacity={0.2}/>
                      <stop offset="20%" stopColor={areaColor} stopOpacity={0}/>
                    </linearGradient></defs>
                  </>);
                })
              )}
              <CartesianGrid vertical={false} stroke={`${hslStrToHex(theme.palette.text.primary)}22`} />
              <Tooltip content={<ChartTooltip/>}/>
              <Legend verticalAlign='top' align='right' content={<ChartLegend/>}/>
            </ComposedChart>
          </ResponsiveContainer>
          {isLoading&&<LoadingIcon curtain/>}
        </Box>
      </Box>
    </Box>
  );
}

export default MarketsHistoricalRatesChart;