import { Box, Button } from '@mui/material';
import { SxProps } from '@mui/system';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { sxBoxCompactInner, sxBoxTitle } from 'src/styles/General';
import { MarketForSymbol } from 'src/types';
import apis from 'src/utils/apis';
import { paths } from 'src/utils/paths';
import LoadingIcon from './Loading';
import MarketSwitcher from './MarketSwitcher';

const sxTokenButton:SxProps = {
  px:1,py:0.25,minWidth:'auto',
  fontSize: '0.625rem',
  textAlign: 'left',
  cursor: 'pointer',
  bgcolor: 'button.dark',
  color: 'primary.main',
  "&:hover": {
    bgcolor: 'action.hover',
  },
  "&+.MuiButton-root":{
    marginLeft:'4px',
  }
};
const sxTokenButtonSelected:SxProps = {
  ...sxTokenButton, 
  bgcolor: 'primary.main',
  color: 'text.primary',
};
const sxOuter:SxProps = {
  "td,th":{
    fontSize: '0.75rem', py: '4px',
  }
};
const sxListOuter:SxProps = {
  '.MuiTableContainer-root':{
    overflow:'unset',
  },
  '.MuiTableHead-root':{
    position:'sticky',
    top: 0,
    zIndex: 1,
  }
}

const sxListInnerContainer:SxProps = {
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: 'border.dark',
}

function TokenMarketSwitcherPanel({type, onMarketSwitch}:{type?:'panel'|'list', onMarketSwitch?: (market:MarketForSymbol) => string | undefined}){
  // const auth = useAuth();
  const { tokenId } = useParams();
  // const appstate = useAppStore();
  const [selectedTokenId,setSelectedTokenId] = useState<number|undefined>(tokenId?parseInt(tokenId):undefined);
  useEffect(()=>{
    setSelectedTokenId(selectedTokenId);
  },[tokenId]);
  const {isLoading:isLoadingTokens,data:tokens} = apis.token.useMarketsPageTokens();
  const token = tokens?.filter(t=>t.tokenId==selectedTokenId)[0]||tokens?.at(0);
  // preload marketswitchers data
  const Preloader = ({tokenId}:{tokenId:number})=>{
    const {data:marketsFixedRate} = apis.fixedrate.useFixedRateMarkets({tokenId});
    const frMarketIds = marketsFixedRate?.map(m=>m.marketId);
    apis.rate.useMarketsPastRates({tokenId,frMarketIds});
    return <></>;
  }
  const tradePage = paths.useCallbackTradePage();
  const onDefaultMarketSwitch = useCallback((market:MarketForSymbol)=>tradePage(market),[tradePage]);

  const onMarketSwitcherSelectPath = onMarketSwitch ?? onDefaultMarketSwitch

  switch(type){
    case 'list':
      return (<Box sx={sxListOuter}>
        {tokens?.map((token,i)=>{
          return (
            <Box sx={sxListInnerContainer}><MarketSwitcher key={`token-${token.code}`} layoutStyle="table" columnType="compact" token={token} onSelectPath={onMarketSwitcherSelectPath} /></Box>
          );
        })}
      </Box>);
    case 'panel': default:
      return (<Box sx={sxOuter}>
        <Box sx={sxBoxTitle}>
        {tokens?.map((token,idx)=>(
          <Button
            key={idx}
            variant="contained"
            sx={Number(selectedTokenId)==token.tokenId?sxTokenButtonSelected:sxTokenButton}
            onClick={()=>setSelectedTokenId(token.tokenId)}
            onMouseEnter={()=>setSelectedTokenId(token.tokenId)}
          >{token.code}</Button>
        ))}
        </Box>
        <Box sx={sxBoxCompactInner}>
          {isLoadingTokens&&(<LoadingIcon/>)}
          {tokens?.map((token,i)=><Preloader key={i} tokenId={token.tokenId}/>)}
          {token&&<MarketSwitcher layoutStyle="table" columnType="public" token={token} onSelectPath={onMarketSwitcherSelectPath}/>}
        </Box>
      </Box>);
  }
}


export default TokenMarketSwitcherPanel;