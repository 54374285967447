import {
  Box, Button, ButtonGroup, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import Decimal from 'decimal.js';
import moment from 'moment';
import { useState } from 'react';
import { CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useAuth } from 'src/AuthProvider';
import useAppStore from 'src/store';
import { graphLineColors, sxChartAboveBar, sxChartTooltip, sxChartTooltipLegendBoxTitle, sxChartTooltipTable, sxChartWrapper, sxTokenSwitchItem } from 'src/styles/Chart';
import { MTMPerformanceItem, Token } from 'src/types';
import apis from 'src/utils/apis';
import { hslStrToHex } from 'src/utils/common';
import { formatDate, formatStartOfDayDate, today } from 'src/utils/date';
import { format, formatN } from 'src/utils/numbers';
import theme from 'src/utils/theme';
import LoadingIcon from './Loading';
import ValueTableCell from './ValueTableCell';

function MTMPerformanceGraph({tokens,extraFilterElement}:{tokens?:Token[],extraFilterElement?:JSX.Element}){
  const auth = useAuth();

  const [selectedDayRange,setSelectedDayRange] = useState<30|undefined>(30);

  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  const { currentUserAccountId } = useAppStore();
  const tradeAccount = userAccounts&&apis.user.extractUserAccount(userAccounts,currentUserAccountId);
  const { data:mtmPerformanceByToken, isLoading } = apis.user.useUserMTMPerformanceByToken(auth,{
    accountId: tradeAccount?.accountId, start: selectedDayRange?(today().subtract(selectedDayRange,'d').unix()*1000):undefined,
  });
  const isLoadingAny = isLoading;

  const dataKeyMap = {};
  const dataByDateMap = {};
  mtmPerformanceByToken&&Object.keys(mtmPerformanceByToken).forEach((tokenId)=>{
    const token = tokens?.find(t=>t.tokenId===Number(tokenId));
    if(token){
      const label = token.code;
      mtmPerformanceByToken[tokenId]?.forEach((item:MTMPerformanceItem)=>{
        const date = item.date;
        const cashPosition = new Decimal(item.cashPosition||0).toNumber();
        const unrealized = new Decimal(item.unrealized||0).toNumber();
        const cashPositionKey = `${token.code}: Cash Position`;
        const unrealizedKey = `${token.code}: Unrealized`;
        dataKeyMap[cashPositionKey] = true;
        dataKeyMap[unrealizedKey] = true;
        dataByDateMap[date] = {...dataByDateMap[date]||{},label,date,[cashPositionKey]:cashPosition,[unrealizedKey]:unrealized};
      });
    }
  })

  const data:any[] = [];
  Object.keys(dataByDateMap).sort().forEach((date)=>{
    if(dataByDateMap[date]) data.push(dataByDateMap[date]);
  })
  // console.log('data',data);

  function ChartTooltip({active,payload,label}:any){
    if (active && payload && payload.length) {
      return (
        <Box sx={sxChartTooltip}>
          <Box sx={sxChartTooltipLegendBoxTitle}>{formatDate(moment(label))}</Box>
          <Table sx={sxChartTooltipTable}>
            <TableBody>
              {Object.keys(dataKeyMap).map((dataKey,i)=>{
                return (
                  <TableRow key={dataKey}>
                    <TableCell sx={{ fontWeight: '700'}}>{dataKey}</TableCell>
                    <ValueTableCell mono align='right'>{format(new Decimal(payload[0].payload[dataKey]||0),0)}</ValueTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Box>
          </Box>
        </Box>
      );
    }
    return null;
  }
  return (<Box sx={{...sxChartWrapper,paddingTop:'36px'}}>
  <Box sx={sxChartAboveBar}>
    {extraFilterElement}
    <ButtonGroup variant="outlined" aria-label="outlined primary button group">
      <Button sx={sxTokenSwitchItem} variant={selectedDayRange===undefined?'contained':'outlined'} onClick={()=>setSelectedDayRange(undefined)}>All Time</Button>
      <Button sx={sxTokenSwitchItem} variant={selectedDayRange===30?'contained':'outlined'} onClick={()=>setSelectedDayRange(30)}>Last 30 days</Button>
    </ButtonGroup>
  </Box>
  <ResponsiveContainer aspect={2} height="auto" >
    <ComposedChart data={data.slice(0,selectedDayRange)}>
      <CartesianGrid vertical={false} stroke={`${hslStrToHex(theme.palette.text.primary)}22`} />
      <YAxis fontSize={"0.625rem"} yAxisId={'cashPosisiton'} type="number" 
        tickLine={false} tickFormatter={(v,i)=>`${formatN({num:v,prefix:'$'})}`} width={40} stroke={`${hslStrToHex(theme.palette.text.primary)}88`}
      />
      <XAxis fontSize={"0.625rem"} dataKey={'date'} type="number" domain={['dataMax','dataMin']} padding={{left:8,right:8}} tickLine={false} tickFormatter={(v,i)=>(`${formatStartOfDayDate(v)}`)} stroke={`${hslStrToHex(theme.palette.text.primary)}88`}/>
      {Object.keys(dataKeyMap).map((dataKey,i)=>{
        const lineColor = graphLineColors[(i/3)%graphLineColors.length];
        return (
          <Line key={dataKey} dataKey={dataKey} yAxisId="cashPosisiton" stroke={lineColor} type="monotone"/>
        );
      })}
      <Tooltip content={<ChartTooltip/>}/>
    </ComposedChart>
  </ResponsiveContainer>
  {isLoadingAny&&<LoadingIcon curtain/>}
  </Box>);
}

export default MTMPerformanceGraph;