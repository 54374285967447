import React, { createContext, useCallback, useContext, useState } from 'react';

export interface GraphPositionContextType {
  xPosition: number | null;
  activeGraph: string | null;
  handleMousePosition: (e: any) => void;
  setActiveGraph: (graphName: string | null) => void;
}
  
const GraphPositionContext = createContext<GraphPositionContextType>(null!);

const useGraphPosition = () => {
  return useContext(GraphPositionContext)
}

/**
 * 
 * @param Component 
 * @returns HOC Component with `graphPosition` prop from GraphPositionContext
 */
const withGraphPosition = <P extends {}>(Component:{(props:P):JSX.Element}) => {
  return (props:P) =>{
    const graphPosition = useContext(GraphPositionContext)
    return <Component {...props} graphPosition={graphPosition}/>
  }
};

const withLocalGraphPosition = <P extends {}>(Component:{(props:P):JSX.Element}) => {
  return (props:P) =>{
    const [xPosition, setXPosition] = useState<number | null>(null);

    const handleMousePosition = useCallback(
      (newXPosition: number | null) => {
        if (newXPosition !== xPosition) {
          setXPosition(newXPosition)
        }
      },
      [xPosition]
    );

    const graphPosition = {
      xPosition,
      handleMousePosition
    }
    return <Component {...props} graphPosition={graphPosition}/>
  }
};

const GraphPositionProvider = ({ children }: { children: React.ReactNode }) => {
  const [xPosition, setXPosition] = useState<number | null>(null)
  const [activeGraph, updateActiveGraph] = useState<string | null>(null)

  const handleMousePosition = useCallback(
    (newXPosition: number | null) => {
      if (newXPosition !== xPosition && activeGraph !== null) {
        setXPosition(newXPosition)
      }
    },
    [xPosition, activeGraph]
  );

  const setActiveGraph = (activeGraph: string | null) => {
    if (activeGraph === null) {
      setXPosition(null);
    }
    updateActiveGraph(activeGraph)
  }

  let value = { xPosition, activeGraph, setActiveGraph, handleMousePosition };

  return <GraphPositionContext.Provider value={value}>{children}</GraphPositionContext.Provider>;
}

export { GraphPositionProvider, useGraphPosition, withGraphPosition, withLocalGraphPosition };
