import { Box, Button, IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { MutableRefObject } from 'react';
import { createPortal } from 'react-dom';
import { TradeHistoryItem } from 'src/types';
import { sxActionButton } from 'src/styles/Table';
import { sxTokenTableCompactButton } from 'src/styles/TokenTable';
import { FOOTER_HEIGHT_VALUE } from 'src/Components/Footer';

const CANCEL_ORDER_SELECTION_SNACKBAR_WIDTH = 420
export interface ConfirmationDialogElement {
    openDialog: (orders: TradeHistoryItem[]) => void;
}

type DialogRefType = MutableRefObject<ConfirmationDialogElement | null>;

export const CancelOrdersSnackbar = ({
    orders, dialogRef, handleClose
}: {
    orders: TradeHistoryItem[],
    dialogRef: DialogRefType
    handleClose: () => void
    }) => {
    const orderCount = orders.length
    return createPortal(
        <Box sx={{ width: `${CANCEL_ORDER_SELECTION_SNACKBAR_WIDTH}px`, borderRadius: '4px', backgroundColor: 'primary.main', zIndex: 10, position: 'absolute', bottom: `${FOOTER_HEIGHT_VALUE + 10}px`, left: 'calc(50% - 210px)'}}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '14px 20px'}}>
                <Box sx={{ lineHeight: '20px', fontSize: '0.875rem'}}>{`${orderCount} order${orderCount > 1 ? 's': ''} selected`}</Box>
                <Box>
                <Button onClick={() => {
                    dialogRef.current?.openDialog(orders)
                }} sx={{...sxTokenTableCompactButton, margin: '0', height: '20px'}} variant="text"><Box sx={sxActionButton}>{`Cancel Order${orderCount > 1 ? 's' : ''}`}</Box></Button>
                {handleClose&&<IconButton aria-label="close"  sx={{ margin: '0', padding: '0', marginLeft: '1rem' }} onClick={()=>handleClose()}><Close sx={{ width: '1rem', height: '1rem'}}/></IconButton>}
                </Box>
            </Box>
        </Box>,
        document.body
    )
}