import Decimal from 'decimal.js';
import { EMarketType, ERateOrderStatus, FixedRateOrder, RateOrder, BaseMarket, Token } from 'src/types';
import { dps } from 'src/utils/numbers';

export const mapFixedRateOrderHistoryItem = (fixedRateOrder: FixedRateOrder, tokens?: Token[], markets?: BaseMarket[]) => {
    const { quantity, fulfilled, averageTradeRate, mtm, dv01, side, orderDate, updateDate, accountId, marketId, userAddress, source, status, instrumentId: code, orderId, maturityDate, orderType, rate } = fixedRateOrder;
    const tokenCode = code ? code?.split("-")[0] : '';
    const q = new Decimal(status === ERateOrderStatus.PARTIALLY_FILLED ? fulfilled : quantity);
    const quantityVector = q.abs().toString();
    const tokenId = fixedRateOrder.tokenId ?? tokens?.filter(token => token.code === tokenCode)[0].tokenId ?? -1;
    const referenceSpotMarket = markets?.filter(market => market.tokenId === tokenId)[0]; // only for quantity step use
    const decimals = dps(new Decimal(referenceSpotMarket?.quantityStep||0).toNumber());

    return {
        userAddress,
        tokenId,
        marketId,
        orderId,
        orderType,
        marketType: EMarketType.FIXED,
        accountId,
        quantityVector,
        quantity: new Decimal(quantity || 0),
        fulfilled: new Decimal(fulfilled || 0),
        side,
        rate,
        averageTradeRate,
        source,
        status,
        mtm,
        dv01,
        orderDate,
        updateDate,
        // order: fixedRateOrder,
        decimals,
        maturityDate
      }
}

export const mapFloatRateOrderHistoryItem = (rateOrder:RateOrder, markets?: BaseMarket[])=>{
    const { quantity, fulfilled, averageTradeRate, side, orderDate, updateDate, accountId, marketId, userAddress, source, status, orderId, orderType, rate } = rateOrder;
    const quantityVector = new Decimal(status === ERateOrderStatus.PARTIALLY_FILLED ? fulfilled : quantity).abs().toString();
    // const rate = averageTradeRate?new Decimal(averageTradeRate):undefined;
    const market = markets?.filter(market=>market.marketId === marketId)[0];
    const token = market?.token;
    const decimals = dps(new Decimal(market?.quantityStep || 0).toNumber());
    if(!token) return;
    const { tokenId } = token;
    return {
      userAddress,
      tokenId,
      marketId,
      orderId,
      orderType,
      marketType: EMarketType.FLOATING,
      accountId,
      quantityVector,
      quantity: new Decimal(quantity || 0),
      fulfilled: new Decimal(fulfilled || 0),
      side,
      rate,
      averageTradeRate,
      source,
      status,
      orderDate,
      updateDate,
      // order: rateOrder,
      decimals
    };
  }