import { Box } from '@mui/material';
import { useCallback, useState } from 'react';
import { BootstrapMenuItem, BootstrapSelect, sxSelectMenu } from 'src/styles/Form';
import { sxDate, } from 'src/styles/TokenTable';
import { BaseMarket } from 'src/types';
import { formatStartOfDayMarketDate } from 'src/utils/date';
import MarketRollingDateLabel from 'src/Components/MarketRollingDateLabel';

const BaseMarketSwitcher = ({
    disabled=false,
    onChangedSelectedMarketId,
    selectedMarketId,
    markets
}:{
    disabled?: boolean,
    onChangedSelectedMarketId?: (selectedMarketId:number)=>void,
    selectedMarketId?: number,
    markets: BaseMarket[]
}) => {
    const [isOpeningMarketSelect,setIsOpeningMarketSelect] = useState(false);

    const handleSelectedMarketId = useCallback((selectedMarketId:number)=>{
        onChangedSelectedMarketId&&onChangedSelectedMarketId(selectedMarketId);
    },[onChangedSelectedMarketId]);

    const marketSelectItem = (market:BaseMarket,i:number)=>{
        return (
        <BootstrapMenuItem key={`${market.tokenId}-${i}`} value={market.marketId}>
            <MarketRollingDateLabel market={market}/>&nbsp;{market.maturityDate&&<Box sx={sxDate}>{formatStartOfDayMarketDate(market.maturityDate)}</Box>}
        </BootstrapMenuItem>);
    }
    return (
        <BootstrapSelect MenuProps={{sx:sxSelectMenu}}
          value={markets?.map(frm => frm.marketId).includes(selectedMarketId || -1) ? selectedMarketId : ''}
          open={isOpeningMarketSelect}
          onOpen={() => setIsOpeningMarketSelect(true)}
          onClose={() => setIsOpeningMarketSelect(false)}
          onChange={(event) => {
            handleSelectedMarketId(parseInt(`${event.target.value}`))
          }}
          disabled={disabled}
        >
          {markets?.map((frm,i) => marketSelectItem(frm,i))}
        </BootstrapSelect>
      );
}

export default BaseMarketSwitcher;