import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { Box, Menu, SxProps, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { sxMenuModalRoot } from 'src/styles/General';
import theme from 'src/utils/theme';

const sxNavButton:SxProps = {
  p: 0, cursor: 'pointer', textTransform:'initial', position: 'relative',
  fontSize: '0.875rem', fontWeight: 600,
  display: 'grid', placeItems: 'center',
  "&:hover a:not(.MuiButton-root)": {
    bgcolor: 'action.hover',
  },
  "a": {
    display: 'grid', placeItems: 'center', textAlign: 'center',
    height: '32px', width: '100%', px: 2, py:0, borderRadius: '4px',
    textDecoration: 'none',
    transition: 'all .3s ease',
    [theme.breakpoints.down('tablet')]:{
      py: '8px', height: 'auto',
      display: 'block',
      textAlign: 'left',
    }
  },
  [theme.breakpoints.down('tablet')]:{
    bgcolor:'background.paper',
  }
};
const sxNavButtonIconOnly:SxProps = Object.assign({},sxNavButton,{
  "&>a": {
    px: 1,
  }
});
const sxNavButtonPadded:SxProps = Object.assign({},sxNavButton,{
  "&>a": {
    paddingRight: 3,
  }
});
const sxNavButtonPaddedHover:SxProps = Object.assign({},sxNavButton,{
  "&>a": {
    bgcolor: 'action.hover',
    paddingRight: 3,
  },
});
const sxArrowDropDown:SxProps = {
  fontSize:24,
  position:'absolute',
  top:'50%',
  right:0,
  transform:'translateY(-50%)',
  "&.MuiSvgIcon-root": {
    width: '0.833em',
    height: '0.833em',
    marginRight: '0.1em'
  }
};

function NavButton({children,hoverComponent,iconOnly=false,handleClick}:{children:React.ReactNode,hoverComponent?:React.ReactNode|React.ReactNode[],iconOnly?:boolean,handleClick?:React.MouseEventHandler}) {
  const [hoverOpen,setHoverOpen] = useState(false);
  const wrapperRef = useRef<HTMLElement>(null);
  return (<>
    <Typography
      variant="button"
      onMouseEnter={hoverComponent?()=>setHoverOpen(true):handleClick}
      onMouseLeave={hoverComponent?()=>setHoverOpen(false):handleClick}
      sx={(hoverComponent&&!iconOnly)&&hoverOpen?sxNavButtonPaddedHover:(hoverComponent&&!iconOnly)?sxNavButtonPadded:iconOnly?sxNavButtonIconOnly:sxNavButton}
      ref={wrapperRef}>{children}{(hoverComponent&&!iconOnly)&&<ArrowDropDown sx={sxArrowDropDown}/>}</Typography>
    {hoverComponent&&(<>
    {/* preload */}
    {!hoverOpen&&<Box sx={{display:"none"}}>{hoverComponent}</Box>}
    <Menu
      id="basic-menu"
      anchorEl={wrapperRef.current}
      open={hoverOpen}
      transitionDuration={100}
      onMouseEnter={hoverComponent?()=>setHoverOpen(true):handleClick}
      onMouseLeave={hoverComponent?()=>setHoverOpen(false):handleClick}
      onClose={()=>setHoverOpen(false)}
      hideBackdrop={true}
      style={{pointerEvents:'none'}}
      sx={sxMenuModalRoot}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
        style: {
          pointerEvents:'initial',overflow:'hidden',backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.border.dark}`,
        },
        onMouseLeave: ()=>setHoverOpen(false),
      }}
    >
      {hoverComponent}
    </Menu>
    </>)}
  </>);
}

export default NavButton;
