import OpenInNew from '@mui/icons-material/OpenInNew';
import { Link } from '@mui/material';
import { getEtherscanBaseUrl } from 'src/utils/common';

function ChainTransactionLink({transactionHash}:{transactionHash:string}){
  const etherscanBaseUrl = getEtherscanBaseUrl();
  const addressAbbr = transactionHash.slice(0,10)+'...'+transactionHash.slice(-4);
  return (
    <Link href={`${etherscanBaseUrl}/tx/${transactionHash}`} target="_blank" title="View transaction on etherscan.io">{addressAbbr}<OpenInNew sx={{fontSize:12}}/></Link>
  );
}


export default ChainTransactionLink;