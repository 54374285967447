/* User, Account, Wallet */
import { z } from "zod";

export const BlockChainSchema = z.object({
	name:z.string(),
	processedBlockNum:z.number(),
	processedBlockHash:z.string(),
	chainId:z.number().optional(),
});
export interface BlockChain extends z.infer<typeof BlockChainSchema>{};

export const ServerTimeSchema = z.object({
	clientTime:z.number().default(Date.now()),
	serverTime:z.number(),
	marketRolloverHour:z.number(),
});
export interface ServerTime extends z.infer<typeof ServerTimeSchema>{};

export const ServerStatusSchema = z.object({
	jobServerStatus: z.number().transform(v=>v!==0).default(0),
	webServerStatus: z.number().transform(v=>v!==0).default(0),
	tradeServerStatus: z.number().transform(v=>v!==0).default(0),
});
export interface ServerStatus extends z.infer<typeof ServerStatusSchema>{};


export const GlossaryItemSchema = z.object({
  key: z.string(),
  alias: z.array(z.string()).optional(),
  tooltip: z.string(),
  definition: z.string().optional(),
  context: z.string().optional(),
});
export interface GlossaryItem extends z.infer<typeof GlossaryItemSchema>{};