import {
  Box, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import Decimal from 'decimal.js';
import { useEffect, useMemo, useState } from 'react';
import { Area, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { hslStrToHex, sortBy } from 'src/utils/common';
import { date, formatStartOfDayDate } from 'src/utils/date';
import { format, formatN, formatUSD, getEqualDistanceTicks } from 'src/utils/numbers';
import { capitalize } from 'src/utils/string';
import theme from 'src/utils/theme';
import { graphAreaColors, graphLineColors, sxChartContainer, sxChartTooltip, sxChartTooltipLegendBox, sxChartTooltipLegendBoxTitle, sxChartTooltipTable, sxChartWatermarkCenter } from '../styles/Chart';
import { styleBoxBlock, styleBoxPanel, sxBoxTitle, sxTitleFloatRight } from '../styles/General';
import apis from '../utils/apis';
import RechartsDateAxisTicks from './RechartsDateAxisTicks';
import ValueTableCell from './ValueTableCell';
import { isEqual } from 'lodash';

function ProtocolHistoricalChart({type}:{type:'totalValue'|'totalValueLockedUsd'|'userCount'|'activeUserCount'}){
  const maxDays = 3650;
  const [chartData, setChartData] = useState<any>([]);
  const { data:historicalTVs } = apis.rate.useHistoricalTotalValue({limit:maxDays});
  const { data:historicalTVLs } = apis.rate.useHistoricalTotalValueLocked({limit:maxDays});
  const { data:historicalUserCounts } = apis.user.useHistoricalUserCount({limit:maxDays});
  const { data, yMax, xAxisTicks, yAxisTicks, totalSize, totalSizeLocked, totalUserCount } = useMemo(()=>{
    let yMax = new Decimal(0);
    let yMin = new Decimal(0);
    let totalSize = new Decimal(0);
    let totalSizeLocked = new Decimal(0);
    let totalUserCount = 0;
    const dateMap = {};
    const xAxisTicks:number[] = [];
    switch(type){
      case 'totalValue':
        sortBy(historicalTVs||[],"date").forEach(item=>{
          const { date:d, totalValue } = item;
          const t = date(d).unix()*1000;
          const name = formatStartOfDayDate(t);
          dateMap[t] = Object.assign({},dateMap[t],{t,name,totalValue});
          totalSize = new Decimal(totalValue); // simply the latest value, NOT sum
          yMax = Decimal.max(yMax,totalValue);
          yMin = Decimal.min(yMin,totalValue);
        });
        break;
      case 'totalValueLockedUsd':
        sortBy(historicalTVLs||[],"date").forEach(item=>{
          const { date:d, totalValueLockedUsd } = item;
          const t = date(d).unix()*1000;
          const name = formatStartOfDayDate(t);
          dateMap[t] = Object.assign({},dateMap[t],{t,name,totalValueLockedUsd});
          totalSizeLocked = new Decimal(totalValueLockedUsd); // simply the latest value, NOT sum
          yMax = Decimal.max(yMax,totalValueLockedUsd);
          yMin = Decimal.min(yMin,totalValueLockedUsd);
        });
        break;
      case 'userCount':
        sortBy(historicalUserCounts||[],"date").forEach(item=>{
          const { date:d, userCount, activeUserCount } = item;
          const t = date(d).unix()*1000;
          const name = formatStartOfDayDate(t);
          dateMap[t] = Object.assign({},dateMap[t],{t,name,userCount,activeUserCount});
          totalUserCount = userCount; // simply the latest value, NOT sum
          yMax = Decimal.max(yMax,userCount);
          yMin = Decimal.min(yMin,userCount);
        });
        break;
    }
    const data:any[] = [];
    for(let date of Object.keys(dateMap).sort()){
      data.push(dateMap[date]);
      xAxisTicks.push(Number(date));
    }
    const yAxisTicks = getEqualDistanceTicks(yMin.toNumber(),yMax.toNumber());
    return {data, yMax, xAxisTicks, yAxisTicks, totalSize, totalSizeLocked, totalUserCount};
  },[historicalTVs, historicalTVLs, historicalUserCounts, type]);

  useEffect(() => {
    if (!isEqual(data, chartData)) {
      setTimeout(() => {
        setChartData(data)
      }, 100)
    }
  }, [data, chartData, setChartData])

  function ChartTooltip({active,payload,label}:any){
    if (active && payload && payload.length) {
      return (
        <Box sx={sxChartTooltip}>
          <Box sx={sxChartTooltipLegendBoxTitle}>{`${payload[0].payload.name}`}</Box>
          <Table sx={sxChartTooltipTable}>
            <TableBody>
              {payload.map((p:any,i:number)=>{
                let value = '';
                switch(type){
                  // case 'totalValue': case 'userCount': 
                  default: value = format(p.value); break;
                }
                return (
                  <TableRow key={i}>
                    <TableCell><Box sx={sxChartTooltipLegendBox} style={{backgroundColor:p.stroke}}></Box><b>{capitalize(p.name)}</b></TableCell>
                    <ValueTableCell mono align='right'>{value}</ValueTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      );
    }
    return null;
  }
  let titleRow = <></>;
  switch(type){
    case 'totalValue': titleRow = <Box sx={{...sxBoxTitle, mx: '0'}}>Outstanding Trades<Box sx={sxTitleFloatRight}>US${formatUSD(totalSize)}</Box></Box>; break;
    case 'totalValueLockedUsd': titleRow = <Box sx={{...sxBoxTitle, mx: '0'}}>Total Value Locked<Box sx={sxTitleFloatRight}>US${formatUSD(totalSizeLocked)}</Box></Box>; break;
    case 'userCount': case 'activeUserCount': titleRow = <Box sx={{...sxBoxTitle, mx: '0'}}>Historical Users<Box sx={sxTitleFloatRight}>{totalUserCount}</Box></Box>; break;
  }

  const sxContainerStyleUpdated = {
    ...sxChartContainer,
    paddingRight: '12px',
    marginLeft: '-10px',
  }
  return (<>
    {titleRow}
    <Box sx={styleBoxBlock} style={{height:'100%',overflow:'hidden'}}>
      <Box sx={styleBoxPanel} style={{alignItems:'stretch',display:'flex',height:'100%'}}>
        <Box sx={{position: 'relative', ...sxChartWatermarkCenter}}>
          <Box sx={sxContainerStyleUpdated}>
            <ResponsiveContainer minHeight={400}>
              <ComposedChart data={chartData} stackOffset="sign">
                <YAxis yAxisId="value" dataKey={type} domain={[(dataMin:number,dataMax:number)=>Math.min(dataMin,0),yMax.toNumber()]} tickLine={false} ticks={yAxisTicks} interval={'preserveStartEnd'} tickFormatter={(v,i)=>{
                  switch(type){
                    // case 'totalValue': case 'userCount': 
                    default: return `${formatN(v,0)}`;
                  }
                }} stroke={`${hslStrToHex(theme.palette.text.primary)}88`}/>
                <XAxis dataKey="t" scale="point" domain={['dataMin','dataMax']} tickLine={false} interval={0} tick={<RechartsDateAxisTicks xAxisTicks={xAxisTicks}/>} stroke={`${hslStrToHex(theme.palette.text.primary)}88`}/>
                  {chartData.length > 1 && <Area type="monotone" dataKey={type} yAxisId="value" strokeWidth={2} stroke={graphLineColors[0]} fillOpacity={1} fill={`url(#fill)`} dot={false}/>}
                  <defs><linearGradient id={`fill`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor={graphAreaColors[0]} stopOpacity={0.5}/>
                    <stop offset="0%" stopColor={graphAreaColors[0]} stopOpacity={0.2}/>
                    <stop offset="20%" stopColor={graphAreaColors[0]} stopOpacity={0}/>
                  </linearGradient></defs>
                <CartesianGrid vertical={false} stroke={`${hslStrToHex(theme.palette.text.primary)}22`} />
                <Tooltip content={<ChartTooltip/>}/>
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  </>);
}

export default ProtocolHistoricalChart;