import { ArrowDropDown, ArticleOutlined, HelpOutline, LinkedIn, PlayForWork, Telegram, Twitter, YouTube } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Drawer, MenuItem, keyframes } from '@mui/material';
import { SxProps } from '@mui/system';
import { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { FEATURE_VERSION } from 'src/constants/layout';
import { TESTNET_NOTIFICATION_HEIGHT } from 'src/constants/app';
import useAppStore from 'src/store';
import { sxDesktopOnly, sxFlexFill, sxMenuItem, sxMenuItemSeparator, sxMobileOnly } from "src/styles/General";
import { paths } from 'src/utils/paths';
import theme from "src/utils/theme";
import { useAuth } from '../AuthProvider';
import ConnectButton from './ConnectButton';
import { Discord } from './Icons/Discord';
import { Medium } from './Icons/Medium';
import { X } from './Icons/X';
import InfinityLogo from './InfinityLogo';
import NavButton from './NavButton';
import TokenMarketSwitcherPanel from './TokenMarketSwitcherPanel';
import TokenSwitcher from './TokenSwitcher';

const sxSeparator:SxProps = {
  borderRight: 'border.dark',
};
const sxPadding:SxProps = {
  marginRight: '8px',
};
const sxFlexCenter:SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'stretch',
};
const sxDesktopMenuScroller:SxProps = {
  ...sxFlexCenter, 
  overflow:'auto',
  flex: 1,
};
const sxLogoWrapper:SxProps = {
  paddingRight: '16px',
}
const sxHeaderMenuHoverHeader:SxProps = {
  fontWeight: 700,
  minWidth:'200px',
  p: '4px',
  listStyle: 'none',
  opacity: 0.5,
  letterSpacing: '1.4px',
  cursor: 'default'
};
const sxMobileHeaderMenuIcon:SxProps = {
  minWidth: 0,
};
const sxMobileDrawerSection:SxProps = {
  py: '8px',
  backgroundImage: 'none',
  bgcolor: 'background.paper',
  borderWidth: 0,
  borderStyle: 'solid',
  borderColor: 'border.dark',
  borderBottomWidth: '1px',
  "li:not(:empty)":{
    py: '8px', px: '16px',
  },
  "li:empty":{
    mx: '16px', overflow: 'hidden',
  },
};
const sxMobileDrawerSectionTitle:SxProps = {
  minHeight: '0', py: '0', px: '16px',
  fontWeight: 700,
  // fontSize: '1rem',
};
export const HEADER_HEIGHT = '48px';

function Header({ featureVersion }: { featureVersion?: string } ) {
  const [mobileDrawerOpen,setMobileDrawerOpen] = useState(false);
  const {showsTestnetNotification} = useAppStore();
  const auth = useAuth();
  const topOffset = (showsTestnetNotification && TESTNET_NOTIFICATION_HEIGHT) || 0;
  /* sx */
  const sxHeader:SxProps = {
    gridArea: 'header',
    px: 2,
    bgcolor:'background.paper',
    position: 'fixed',
    top: `${topOffset}px`,
    left: 0,
    right: 0,
    height: HEADER_HEIGHT,
    zIndex: theme.zIndex.drawer+1, // +1 for mobile drawer slide in under header animation
    whiteSpace: 'nowrap',
    overflow:'hidden',
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: 'border.dark',
    borderBottomWidth: '1px',
    ...sxFlexCenter,
    [theme.breakpoints.down('tablet')]: {
      top: 0,
    }
  };
  const sxMobileDrawerHeader:SxProps = {
    px: 2,
    bgcolor:'background.paper',
    height: HEADER_HEIGHT,
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: 'border.dark',
    borderBottomWidth: '1px',
    ...sxFlexCenter,
  };
  /* sx */
  const getHeaderMenuItems = (mobile=false)=>{
    return (<>
      <NavButton><Link onClick={()=>mobile&&setMobileDrawerOpen(false)} to="/markets">Markets</Link></NavButton>
      <NavButton hoverComponent={mobile?undefined:<TokenSwitcher type="list" onSelectPath={(token)=>paths.lendAndBorrowMarket(token)}/>}>
        <Link onClick={()=>mobile&&setMobileDrawerOpen(false)} to="/lend-borrow">Lend & Borrow</Link>
      </NavButton>
      <NavButton hoverComponent={mobile?undefined:<TokenMarketSwitcherPanel/>}>
        {!mobile&&<Box component="a">Trade</Box>}
        {mobile&&<Link onClick={()=>mobile&&setMobileDrawerOpen(false)} to="/trade">Trade</Link>}
      </NavButton>
      {FEATURE_VERSION === 'development' && <NavButton><Link onClick={()=>mobile&&setMobileDrawerOpen(false)} to="/liquidity">Liquidity</Link></NavButton>}
      {FEATURE_VERSION === 'development' && <NavButton><Link onClick={()=>mobile&&setMobileDrawerOpen(false)} to="/derivatives">Derivatives</Link></NavButton>}
      {FEATURE_VERSION === 'development' && <NavButton><Link onClick={()=>mobile&&setMobileDrawerOpen(false)} to="/explore">Explore</Link></NavButton>}
      {/* mobile only */}
      {/* <NavButton><Link onClick={()=>mobile&&setMobileDrawerOpen(false)} to="/strategy">Strategy</Link></NavButton> */}
      {/* <Box sx={{...sxFlexCenter,flex:1}}></Box> */}
      {/* <NavButton><Link onClick={()=>mobile&&setMobileDrawerOpen(false)} to="/insights">Insights</Link></NavButton> */}
    </>);
  }

  const handleClickMenuItem = ()=>setMobileDrawerOpen(false);

  const mobileTree = (
    <Box sx={{...sxFlexCenter,flex:1,...sxMobileOnly} as SxProps}>
      <Button sx={sxMobileHeaderMenuIcon} onClick={()=>setMobileDrawerOpen(!mobileDrawerOpen)}><MenuIcon/></Button>
      <InfinityLogo/>
      <Box sx={sxFlexFill}></Box>
      <Box sx={{my:'4px'}}><ConnectButton mobile/></Box>
      <Drawer
        anchor={'top'}
        open={mobileDrawerOpen}
        onClose={handleClickMenuItem}
        // transitionDuration={0}
        PaperProps={{sx:{top:HEADER_HEIGHT,maxHeight:`calc(100% - ${HEADER_HEIGHT})`,animation:`${keyframes`from{opacity:0} to{opacity:1}`} 0.125s 1 ease-out`}}}
        ModalProps={{sx:{top:HEADER_HEIGHT}}}
        slotProps={{
          backdrop:{sx:{top:HEADER_HEIGHT,backgroundColor:'transparent'}},
        }}
      >
        {/* <Box sx={sxMobileDrawerHeader}>
          <Button sx={sxMobileHeaderMenuIcon} onClick={handleClickMenuItem}><CloseIcon/></Button>
          <InfinityLogo/>
          <Box sx={sxFlexFill}></Box>
        </Box> */}
        <Box sx={sxMobileDrawerSection}>{getHeaderMenuItems(true)}</Box>
        {auth.user&&
        <Accordion sx={sxMobileDrawerSection} square disableGutters>
          <AccordionSummary sx={sxMobileDrawerSectionTitle} expandIcon={<ArrowDropDown/>}>My Portfolio</AccordionSummary>
          <AccordionDetails sx={{p:0}}><MyPortfolioMenuComponents useNavButton handleClickMenuItem={handleClickMenuItem}/></AccordionDetails>
        </Accordion>
        }
      </Drawer>
    </Box>
  );
  const desktopTree = (
    <Box sx={{...sxFlexCenter,flex:1,overflow:'hidden',...sxDesktopOnly} as SxProps}>
      <Box sx={{...sxFlexCenter,flex:1,overflow:'hidden'}}>
        <Box sx={sxLogoWrapper}><InfinityLogo/></Box>
        <Box sx={sxDesktopMenuScroller}>{getHeaderMenuItems(false)}</Box>
        <Box sx={sxSeparator}></Box>
      </Box>
      {/* <Box sx={{...sxFlexCenter,flex:1}}></Box> */}
      <Box sx={sxFlexCenter}>
        <AuthStatus/>
      </Box>
    </Box>
  );
  
  return (
    <Box sx={sxHeader}> {mobileTree} {desktopTree} </Box>
  );
}


const MyPortfolioMenuComponentConfigs = FEATURE_VERSION === 'development' ? [
  {label:'CASH',items:[
    {path:"/my-portfolio/accounts",label:'Accounts'},
    {path:"/my-portfolio/cash-transfers",label:'Transfer History'},
    // {path:"/my-portfolio/mtm-performance",label:'MTM Performance'},
  ]},
  {label:'TRADING ACCOUNT',items:[
    {path:"/my-portfolio/positions-pnl",label:'Profit & Loss'},
    {path:"/my-portfolio/risk-overview",label:'Risk Overview'},
    {path:"/my-portfolio/open-orders",label:'Open Orders'},
    {path:"/my-portfolio/order-history",label:'Order History'},
  ]},
  {label:undefined,items:[
    {path:"/my-portfolio/lp-positions",label:'Liquidity Provider'},
  ]}
] : [
  {label:'CASH',items:[
    {path:"/my-portfolio/accounts",label:'Accounts'},
    {path:"/my-portfolio/cash-transfers",label:'Transfer History'},
    // {path:"/my-portfolio/mtm-performance",label:'MTM Performance'},
  ]},
  {label:'TRADING ACCOUNT',items:[
    {path:"/my-portfolio/positions-pnl",label:'Profit & Loss'},
    {path:"/my-portfolio/risk-overview",label:'Risk Overview'},
    {path:"/my-portfolio/open-orders",label:'Open Orders'},
    {path:"/my-portfolio/order-history",label:'Order History'},
  ]}
]
export function MyPortfolioMenuComponents({showsBottomLine,useNavButton,handleClickMenuItem}:{showsBottomLine?:boolean,useNavButton?:boolean,handleClickMenuItem?:Function}){
  const location = useLocation();
  const children = new Array<JSX.Element>();
  const ItemComponent = useNavButton?NavButton:MenuItem;
  MyPortfolioMenuComponentConfigs.map(({label,items},i)=>{
    if(label!==undefined) children.push(<Box key={`${i}_label`} component="li" sx={sxHeaderMenuHoverHeader}>{label}</Box>);
    items.map(({path,label},j)=>{
      const active = location.pathname == path;
      children.push(<ItemComponent key={`${i}_${j}`} sx={sxMenuItem} className={active?'Mui-selected':''}><Link to={path} onClick={()=>handleClickMenuItem&&handleClickMenuItem()}>{label}</Link></ItemComponent>);
    });
    children.push(<Box key={`${i}_sep`} component="li" sx={sxMenuItemSeparator}></Box>);
  });
  return <>{showsBottomLine?children:children.slice(0,-1)}</>;
}

function AuthStatus({onClick=()=>{}}:{onClick?:any}) {
  let auth = useAuth();
  // let navigate = useNavigate();

  return <>
  {auth.user&&<>
    <NavButton hoverComponent={<MyPortfolioMenuComponents/>}><Box component="a">My Portfolio</Box></NavButton>
  </>}
    <NavButton iconOnly hoverComponent={[
      // <Link to="/explore"><Explore/>Explore</Link>, 
      <a href="https://faq.infinity.exchange/start-here/introduction" target="_blank" rel="noopener noreferrer"><ArticleOutlined/>Docs</a>,
      null,
      <a href="https://twitter.com/infinitymaxima" target="_blank" rel="noopener noreferrer"><X/>X</a>,
      <a href="https://www.linkedin.com/company/infinity-exchange/" target="_blank" rel="noopener noreferrer"><LinkedIn/>LinkedIn</a>,
      <a href="https://discord.com/invite/cb3DW9zMt" target="_blank" rel="noopener noreferrer"><Discord/>Discord</a>,
      <a href="https://medium.com/@InfinityExchange" target="_blank" rel="noopener noreferrer"><Medium/>Medium</a>,
      <a href="https://t.me/infinityexchangechat" target="_blank" rel="noopener noreferrer"><Telegram/>Telegram</a>,
      <a href="https://www.youtube.com/@infinityexchange" target="_blank" rel="noopener noreferrer"><YouTube/>YouTube</a>,
    ].map((item,i)=>{
      if(!item) return <Box key={i} component="li" sx={sxMenuItemSeparator}></Box>;
      return <MenuItem key={i} sx={sxMenuItem}>{item}</MenuItem>;
    })}><Box component="a"><HelpOutline style={{fontSize:'1rem'}}/></Box></NavButton>
    <Box sx={sxSeparator}></Box>
    <Box sx={sxPadding}></Box>
  {auth.user&&<>
    <NavButton><Button component={Link} to={paths.portfolioPage('accounts')} variant="contained" color="secondary"><Box sx={{display:'inline-flex'}}><PlayForWork sx={{marginLeft:'-8px'}}/>Deposit</Box></Button></NavButton>
    <Box sx={sxPadding}></Box>
  </>}
    <NavButton><ConnectButton/></NavButton>
  </>;
}

export default Header;
