import { useQuery, useQueryClient } from "@tanstack/react-query";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { usePrevious } from 'src/utils/common';
import { WS_BASE } from 'src/constants/app';
import { wait } from "../common";
import { APIResponse, get } from "./common";
import { useAuth } from 'src/AuthProvider';
import { isEqual } from "lodash";

type TEventType = 'marketInfo'|'recentTrades'|'orderBook'|'externalMarketInfo'|'userOrder'|'userTrade'; // allowed event types
interface WSMessage {
  "e": string, // event type 
  "E": number, // event date
  "s": string, // symbol
  "m": number, // marketId
  "P": any,    // payload
}
const AUTH_REQUIRED_EVENT_TYPES = ['userOrder', 'userTrade']
const emptyFunc = ()=>{};
type WebsocketContextType = {
  isReady: boolean;
  messages: string[];
  consumeMessages: (count: number) => void,
  // send: ((data: string | ArrayBufferLike | Blob | ArrayBufferView) => void) | undefined;
  subscribeTopic: (topic: string) => void;
  unsubscribeTopic: (topic: string) => void;
};
export const websocketContextInit:WebsocketContextType = {
  isReady: false,
  messages: [],
  consumeMessages: emptyFunc,
  // send: undefined,
  subscribeTopic: emptyFunc,
  unsubscribeTopic: emptyFunc,
};
export const WebsocketContext = createContext<WebsocketContextType>(websocketContextInit);

const CHECK_SUBSCRIBED_INTERVAL = 60000; // 1 min
const RECONNECT_INTERVAL = 10000; // 10 secs
const MESSAGE_FLUSH_LIMIT = 1000; // limit needed to prevent huge amount of piled up messages
const INIT_API_DELAY = 0; // delays the initial API call to wait for possible WS data to reduce calls

export const WebsocketProvider = ({ children }:{children:JSX.Element|JSX.Element[]|string}) => {
  const [isReady, setIsReady] = useState(false);
  const loginStatus = useRef(false);
  const [messages, setMessages] = useState<string[]>([]);
  const authReqTopics = useRef<string[]>([]);
  const auth = useAuth();
  const [topicsCountMap, setTopicsCountMap] = useState<Map<string,number>>(new Map());
  const getTopics = useCallback(()=>Array.from(topicsCountMap.keys()).filter(k=>(topicsCountMap.get(k)||0)>0),[topicsCountMap]);
  const ws = useRef<WebSocket>();
  const listSubscriptionsMsgIdRef = useRef<number|undefined>();
  useEffect(() => {
    const MESSAGE_FLUSH_DELAY = 1000/2;
    const messageFlushCache:string[] = [];
    let messageFlushTimout:NodeJS.Timer|undefined;
    let socket:WebSocket|undefined;
    let reconnectInterval:NodeJS.Timer|undefined;
    let checkSubscribedInterval:NodeJS.Timer|undefined;
    const connect = ()=>{
      socket = new WebSocket(WS_BASE);
      socket.onopen = () => {
        // console.log('[WebsocketContext] socket.onopen');
        setIsReady(true);
        login();
        resubscribeTopics();
        // checkSubscribedInterval
        checkSubscribedInterval = setInterval(()=>{
          checkSubscribedTopics();
        },CHECK_SUBSCRIBED_INTERVAL);
        // reconnect attempts
        reconnectInterval = setInterval(()=>{
          // console.log('reconnectInterval');
          if(!socket||socket?.readyState===socket?.CLOSED){ connect(); }
        },RECONNECT_INTERVAL);
      };
      socket.onclose = () => {
        // console.log('[WebsocketContext] socket.onclose');
        setIsReady(false);
      };
      socket.onmessage = (event) => {
        try {
          const json:APIResponse = JSON.parse(event.data);
          if(json.success&&json.data.user){
            loginStatus.current = true
            resubscribeAuthReqTopics()
          }
        } catch (e) {
          console.log(e)
        }
        messageFlushCache.push(event.data);
        // discard earliest entries to prevent message queue overloading
        if(messageFlushCache.length>MESSAGE_FLUSH_LIMIT) messageFlushCache.splice(0,MESSAGE_FLUSH_LIMIT); 
        if(messageFlushTimout===undefined){
          messageFlushTimout = setTimeout(()=>{
            requestAnimationFrame(()=>{
              // console.log(`[WebsocketContext] messageFlushCache ${messageFlushCache.length}`);
              // set state
              setMessages([...messageFlushCache]);
              // check list subscriptions response
              const d = Date.now()
              // console.time(`subscriptionsMsgId timer ${d}`);
              if(listSubscriptionsMsgIdRef.current!==undefined){
                messageFlushCache.forEach((value)=>{
                  try{
                    const json:APIResponse = JSON.parse(value);
                    if(json.success&&json.data){
                      if(json.data.id===listSubscriptionsMsgIdRef.current){
                        // console.log(`WebsocketContext check subscribed topics`);
                        const { result } = json.data;
                        if(result&&Array.isArray(result)){
                          let missingTopics = Array.from(topicsCountMap.keys()).filter(topic=>!result.includes(topic));
                          // console.log(`WebsocketContext checked subscribed ${missingTopics.length} topics missing: ${missingTopics}`);
                          ws.current?.send(JSON.stringify({
                            method: 'SUBSCRIBE',
                            params: missingTopics,
                            id: getWebsocketMessageId(),
                          }));
                        }
                      }
                    }
                  }catch(ignore){
                    // 
                  }
                })
              }
              // console.timeEnd(`subscriptionsMsgId timer ${d}`);

              // clean up flush cache
              messageFlushCache.length = 0;
              messageFlushTimout = undefined;
            });
          },MESSAGE_FLUSH_DELAY);
        }
      };
      ws.current = socket;
    };
    connect();
    return () => {
      socket?.close();
      if(reconnectInterval) clearInterval(reconnectInterval);
      if(checkSubscribedInterval) clearInterval(checkSubscribedInterval);
    };
  },[]);
  
  const getWebsocketMessageId = useMemo(()=>{
    let id = 0;
    return ()=>id++;
  },[]);
  const login = useCallback(() => {
    if (auth.accessToken?.token) {
      ws.current?.send(JSON.stringify({
        method: 'LOGIN',
        params: {
          "accessToken": auth.accessToken?.token
        },
        id: getWebsocketMessageId(),
      }));
    }
  }, [auth, getWebsocketMessageId])
  useEffect(() => {
    if (auth.accessToken?.token && ws.current?.readyState===WebSocket.OPEN) {
        login()
    }
  }, [auth, login])
  const consumeMessages = useCallback((count:number)=>{
    setMessages(messages=>[...messages.slice(count)]);
  },[]);
  const checkSubscribedTopics = useCallback(()=>{
    const topics = getTopics();
    // console.log(`WebsocketProvider check subscripted topic: ${topics.length} topics`);
    if(topics.length>0){
      if(ws.current?.readyState===WebSocket.OPEN){
        const id = getWebsocketMessageId();
        ws.current?.send(JSON.stringify({
          method: 'LIST_SUBSCRIPTIONS', id,
        }));
        // save msg id to be retrieved in onmessage later
        listSubscriptionsMsgIdRef.current = id;
      }
    }
  },[getTopics,getWebsocketMessageId]);
  const resubscribeAuthReqTopics = useCallback(()=>{
    // console.log(`WebsocketProvider resubscribe: ${topics.length} topics`);
    if(authReqTopics.current.length>0){
      if(ws.current?.readyState===WebSocket.OPEN){
        ws.current?.send(JSON.stringify({
          method: 'SUBSCRIBE',
          params: authReqTopics.current,
          id: getWebsocketMessageId(),
        }));
        authReqTopics.current = []
      }
    }
  },[authReqTopics,getWebsocketMessageId]);
  const resubscribeTopics = useCallback(()=>{
    const topics = getTopics();
    // console.log(`WebsocketProvider resubscribe: ${topics.length} topics`);
    if(topics.length>0){
      if(ws.current?.readyState===WebSocket.OPEN){
        ws.current?.send(JSON.stringify({
          method: 'SUBSCRIBE',
          params: topics,
          id: getWebsocketMessageId(),
        }));
      }
    }
  },[getTopics,getWebsocketMessageId]);
  const subscribeTopic = useCallback((topic:string)=>{
    const topics = getTopics();
    const eventType = topic.split('@').at(-1)
    if(topics.includes(topic)) return;
    if (AUTH_REQUIRED_EVENT_TYPES.includes(eventType || '') && !loginStatus.current) {
      authReqTopics.current.push(topic)
      return
    }
    // console.log(`WebsocketProvider subscribe: ${topic}`);
    if(ws.current?.readyState===WebSocket.OPEN){
      ws.current?.send(JSON.stringify({
        method: 'SUBSCRIBE',
        params: [topic],
        id: getWebsocketMessageId(),
      }));
      setTopicsCountMap(topicsCountMap=>topicsCountMap.set(topic,(topicsCountMap.get(topic)||0)+1));
    }
  },[getTopics, getWebsocketMessageId, loginStatus, authReqTopics]);
  const unsubscribeTopic = useCallback((topic:string)=>{
    if(true) return;
    // DEBUG skip unsubscribe for now - on unmount unsubscribe is wrong as it does not account for multiple components subscribing to the same topic; probably add a topic counter in WebsocketProvider internal state?
    const topics = getTopics();
    if(!topics.includes(topic)) return;
    // console.log(`WebsocketProvider unsubscribe: ${topic}`);
    if(ws.current?.readyState===WebSocket.OPEN){
      ws.current?.send(JSON.stringify({
        method: 'UNSUBSCRIBE',
        params: [topic],
        id: getWebsocketMessageId(),
      }));
      setTopicsCountMap(topicsCountMap=>topicsCountMap.set(topic,Math.max(0,(topicsCountMap.get(topic)||0)-1)));
    }
  },[getTopics,getWebsocketMessageId]);
  const contextValue = useMemo(()=>({
    isReady,messages,consumeMessages,subscribeTopic,unsubscribeTopic, 
    // send: ws.current?.send.bind(ws.current),
  }),[isReady,messages,consumeMessages,subscribeTopic,unsubscribeTopic]);
  // TODO // DEV any way to stop context value change rerender triggers?
  return (
    <WebsocketContext.Provider value={contextValue}>
      {children}
    </WebsocketContext.Provider>
  );
};

/* ws hook */
/**
 * 
 * @param api GET url to retrieve initial data, or an async function that returns the initial data
   @param queryKey react-query query key
   @param symbol first part of <symbol>@<stream>, should be market name
   @param eventType last part of <symbol>@<stream>, should be topic stream
   @param arrayLimit number|undefined undefined if returned data is single object, number for array as slice limit
   @param receive callback function to parse raw data into provided type
 * @returns 
 * sample:
  const queryKey = ['market',{category:`${market?.maturityDate==undefined?'floating':'fixed'}`,action:'marketInfo',marketId:market?.marketId}];
  const symbol = market?.name.replace(/\s/g,'-')||'';
  const eventType = 'marketInfo';
  const api = market&&`/api/p/${market.maturityDate==undefined?'rate':'fixed_rate'}/market/${market.marketId}/info`;
  return useSubscribeWebsocket<MarketInfo>({
    api, queryKey, symbol, eventType, arrayLimit:undefined, receive:(payload) => {
      return zp(MarketInfoSchema,payload.data.marketInfo);
    },
  });
 */
export function useWebsocketWithContext<T>({api,queryKey,symbol,eventType,arrayLimit,arraySorter,receive}:{
  api?:string|(()=>Promise<T|undefined>), queryKey:any[], symbol?:string, eventType?:TEventType, arrayLimit?:number, arraySorter?:(array:any[])=>any[],
  receive?:(response:APIResponse)=>any|undefined,
}){
  const {isReady,messages,consumeMessages,subscribeTopic, unsubscribeTopic} = useContext(WebsocketContext);
  const prevQueryKey = usePrevious(queryKey)
  const auth = useAuth();
  const didUnmount = useRef(false);
  const isOnline = useRef(navigator.onLine);
  const topic = useMemo(
    () => {
      return symbol&&eventType&&`${symbol}@${eventType}`;
    }, [symbol, eventType]
  )
  const prevTopic = usePrevious(topic)
  const queryClient = useQueryClient();
  const handleReceiveCallback = useCallback((data?:any)=>{
    if(data!==undefined){
      if(arrayLimit===undefined){
        queryClient.setQueryData(queryKey, {...data});
      }else{
        queryClient.setQueryData(queryKey, (oldData)=>{
          const ret = Array.isArray(data)?[...(arraySorter?arraySorter(data):data)]:[data];
          const array = (Array.isArray(oldData))?[...ret,...oldData]:[...ret];
          return (arraySorter?arraySorter(array):array).slice(0,arrayLimit);
        });
      }
    }
  },[queryKey,arrayLimit,arraySorter,queryClient]);
  const handleOnlineChange = useCallback(()=>{
    if(didUnmount.current) return;
    const lastIsOnline = isOnline.current;
    isOnline.current = navigator.onLine;
    if(isOnline.current!==lastIsOnline&&isOnline.current){
      // on reconnect - clear queryCache and invalidate to rerun API
      // console.log('[WSContext] queryClient.invalidateQueries',queryKey);
      queryClient.setQueryData(queryKey,null);
      queryClient.invalidateQueries(queryKey);
    }
  },[queryClient,queryKey]);
  useEffect(() => {
    if (queryKey[0] === 'user') {
      if (!isEqual(queryKey,prevQueryKey)) {
        queryClient.setQueryData(queryKey,null);
        queryClient.invalidateQueries(queryKey);
      }
      if (topic && topic !== prevTopic) {
        unsubscribeTopic(topic)
      }
    }
  }, [queryKey, prevQueryKey, topic, prevTopic, unsubscribeTopic, queryClient])
  useEffect(() => {
    // checks reconnect
    window.addEventListener('online',handleOnlineChange);
    window.addEventListener('offline',handleOnlineChange);
    return ()=>{
      if(topic) unsubscribeTopic(topic)
      didUnmount.current = true;
      window.removeEventListener('online',handleOnlineChange);
      window.removeEventListener('offline',handleOnlineChange);
    }
  },[]);
  useEffect(() => {
    if(subscribeTopic&&isReady&&topic){
      subscribeTopic(topic);
    }
  },[subscribeTopic,isReady,topic]);
  useEffect(() => {
    // on receive message
    if (messages.length>0&&topic&&receive) {
      const data = new Array<T>();
      // mesages are chronological (old->new)
      messages.forEach(value=>{
        try{
          const json:WSMessage = JSON.parse(value);
          if(json.s===symbol&&json.e===eventType){
            const d = receive({success:true,data:{[eventType]:json.P}});
            if(d) data.push(d); 
          }
        }catch(ignore){
          //
        }
      });
      if(data.length>0){
        if(arrayLimit!==undefined){
          const flattenedData = data.reduce((arr,d)=>{
            if(Array.isArray(d)) return [...arr,...d];
            return [...arr,d];
          },new Array<any>());
          handleReceiveCallback(flattenedData);
        }else{
          // non array type takes last(i.e. newest) data 
          handleReceiveCallback(data[data.length-1]);
        }
      }
      consumeMessages(messages.length);
    }
  }, [messages]);
  return useQuery<T|undefined>(queryKey,async ()=>{
    // initial query - never refetch unless cleared specifically
    await wait(INIT_API_DELAY);
    const ret = queryClient.getQueryData<T>(queryKey);
    if(ret) return ret; // skip API call if data already exists
    if(api&&receive){
      let data:any;
      if(typeof api==='function'){
        data = await api();
      }else{
        const res = await get(api, undefined, eventType && AUTH_REQUIRED_EVENT_TYPES.includes(eventType) ? auth : undefined );
        data = receive(res);
      }
      if(data!==undefined) {
        const dataTyped = data as T;
        handleReceiveCallback(dataTyped);
        return dataTyped;
      }
    }
    return null as unknown as T;
    // DEV throwing CancelledError causes useQuery to return cached data from the previous queryKey in the render cycle - returns null instead
    // queryClient.setQueryData<T>(queryKey,undefined);
    // throw new CancelledError({silent:true,revert:false});
  },{staleTime:Infinity});
  // return queryClient.getQueryData<T>(queryKey);
}