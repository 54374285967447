import {
  Box, Button, Table, TableBody, TableCell, TableRow, ToggleButton, ToggleButtonGroup, Typography
} from '@mui/material';
import { useQueryClient } from "@tanstack/react-query";
import Decimal from 'decimal.js';
import { useEffect, useState } from 'react';
import withConnectWallet from 'src/Components/WithConnectWallet';
import useSnackbar from 'src/hooks/useSnackbar';
import { useAuth } from '../AuthProvider';
import useAppStore from '../store';
import { styleDialogButton } from '../styles/Dialog';
import { styleFormButton, styleFormCell, styleFormLabel, sxCompactFormTable } from '../styles/Form';
import { EOrderSide, Market, RateLPPosition, Token } from '../types';
import apis from '../utils/apis';
import { ICreateLPPositionParams } from '../utils/apis/lp';
import { format, formatDateTime } from '../utils/numbers';
import RateInput from './RateInput';
import StandardDialog from './StandardDialog';
import TokenAmountInput from './TokenAmountInput';

function LiquidityForm({market,token}:{market:Market,token:Token}){
  const auth = useAuth();
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  const { data:orderBook } = apis.rate.useRateOrderBook(market);
  const { currentUserAccountId } = useAppStore();
  const currentRate = market?.rate;
  // sides
  const [orderSide,setOrderSide] = useState<EOrderSide>(EOrderSide.LEND);
  const sideLabel = orderSide==EOrderSide.BORROW?'Borrow':'Lend';
  useEffect(()=>{ refreshRangeValues(); },[orderSide]);
  // form ref
  const [rangeFromMin,setRangeFromMin] = useState('');
  const [rangeFromMax,setRangeFromMax] = useState('');
  const [rangeToMin,setRangeToMin] = useState('');
  const [rangeToMax,setRangeToMax] = useState('');
  // form params
  const [totalSize,setTotalSize] = useState('');
  const [rangeFrom,setRangeFrom] = useState('');
  const [rangeTo,setRangeTo] = useState('');
  const [isPostingBorrowOrder,setIsPostingBorrowOrder] = useState(false);
  const [isPostingLendOrder,setIsPostingLendOrder] = useState(false);
  const isPostingAnyOrder = isPostingBorrowOrder||isPostingLendOrder;
  const [lastPosition,setLastPosition]:[RateLPPosition|undefined,Function] = useState();
  const tradeAccount = userAccounts&&market&&apis.user.extractUserAccount(userAccounts,currentUserAccountId);
  const tradeTokenAccount = market&&tradeAccount&&apis.user.extractAccountTokenAccountFromAccount(tradeAccount,market.tokenId);
  const maxQuantity = market&&token&&tradeAccount&&(orderSide==EOrderSide.BORROW?
    // borrow maxQuantity = healthscore max borrow in usd / price
    new Decimal(tradeAccount.maxBorrowInUsd||0).div(new Decimal(token.price||0)):
    // lend maxQuantity = trading account availableQuantity
    tradeTokenAccount?.availableQuantity&&new Decimal(tradeTokenAccount?.availableQuantity).sub(new Decimal(tradeTokenAccount?.availableQuantity).mod(new Decimal(market.quantityStep)))
  )||new Decimal(0);
  const quantityStep = market?.quantityStep||'0';
  const decimals = new Decimal(quantityStep).decimalPlaces();
  const totalSizeObject = new Decimal(totalSize||0);
  const isInvalidInput = totalSizeObject.lte(0)||totalSizeObject.gt(maxQuantity);
  const actionLabel = (orderSide==EOrderSide.LEND?'Lend':'Borrow')+(isPostingAnyOrder?'ing...':'');

  function refreshRangeValues(_rangeFrom?:string,_rangeTo?:string){
    const step = new Decimal(market!.rateStep);
    switch(orderSide){
      case EOrderSide.LEND: {// Lower starts = SPOT
      let lowerLimit = currentRate||'0';
      // use max bid rate if it's lower than current rate ---|max bid<-----|current
      const bidMaxRate = orderBook?.bidMaxRate!==undefined ? new Decimal(orderBook?.bidMaxRate) : undefined;
      if(bidMaxRate?.lt(new Decimal(lowerLimit))){
        lowerLimit = bidMaxRate.add(step).toString();
      }
      // min lower limit = 0.01 
      if(new Decimal(lowerLimit).lt(0.01)){
        lowerLimit = '0.01';
      }
      
      const upperLimit = '1';
      setRangeFromMin(lowerLimit); setRangeToMin(lowerLimit);
      setRangeFromMax(upperLimit); setRangeToMax(upperLimit);
      // pushes the other range input
      if(_rangeFrom&&rangeTo&&new Decimal(_rangeFrom).gt(new Decimal(rangeTo))){
        setRangeTo(_rangeFrom);
      }
      if(_rangeTo&&rangeFrom&&new Decimal(_rangeTo).lt(new Decimal(rangeFrom))){
        setRangeFrom(_rangeTo);
      }
      // init
      if((!_rangeFrom&&!_rangeTo)||!rangeFrom) setRangeFrom(lowerLimit);
      if((!_rangeFrom&&!_rangeTo)||!rangeTo) setRangeTo(Decimal.min(new Decimal(lowerLimit).add(new Decimal('0.1')), new Decimal(1)).toString());
      }break;
      case EOrderSide.BORROW: {// Upper starts = SPOT
      let upperLimit = currentRate||'0';
      // use min ask rate if it's greater than current rate  --------|current----->|min ask---
      const askMinRate = orderBook?.askMinRate!==undefined ? new Decimal(orderBook?.askMinRate) : undefined;
      if(askMinRate?.gt(new Decimal(upperLimit))){
        upperLimit = askMinRate.sub(step).toString();
      }
      // max upper limit = 1
      if(new Decimal(upperLimit).lte(0)||new Decimal(upperLimit).gt(1)){
        upperLimit = '1';
      }
      const lowerLimit = '0.001';
      setRangeFromMin(lowerLimit); setRangeToMin(lowerLimit);
      setRangeFromMax(upperLimit); setRangeToMax(upperLimit);
      // pushes the other range input
      if(_rangeFrom&&rangeTo&&new Decimal(_rangeFrom).gt(new Decimal(rangeTo))){
        // updating Lower: Upper should be pushed by step
        setRangeTo(_rangeFrom);
      }
      if(_rangeTo&&rangeFrom&&new Decimal(_rangeTo).lt(new Decimal(rangeFrom))){
        // updating Upper: Lower should be pushed by step
        setRangeFrom(_rangeTo);
      }
      // init
      if((!_rangeFrom&&!_rangeTo)||!rangeFrom) setRangeFrom(lowerLimit);
      if((!_rangeFrom&&!_rangeTo)||!rangeTo) setRangeTo(upperLimit);
      }break;
    }
  }
  function handleChangeRangeFrom(rangeFrom:string){
    refreshRangeValues(rangeFrom,'');
    setRangeFrom(rangeFrom);
  }
  function handleChangeRangeTo(rangeTo:string){
    refreshRangeValues('',rangeTo);
    setRangeTo(rangeTo);
  }
  function handleClickOrder(){
    postOrder(orderSide);
  }
  function postOrder(side:EOrderSide){
    if(!market||!tradeAccount) return;
    if(!rangeFrom){ snackbar.queue({message:'Range-Lower required.',type:"warning"}); return; }
    if(!rangeTo){ snackbar.queue({message:'Range-Upper required.',type:"warning"}); return;}
    if(!totalSize){ snackbar.queue({message:'Amount required.',type:"warning"}); return;}
    const createLPPositionParams:ICreateLPPositionParams = {
      marketId: market.marketId,
      accountId: tradeAccount.accountId,
      side: side, //  1 for borrow, 0 for lend
      rangeFrom: rangeFrom,
      rangeTo: rangeTo,
      totalSize: totalSize, 
    };
    (async()=>{
      side==EOrderSide.LEND?setIsPostingLendOrder(true):setIsPostingBorrowOrder(true);
      try{
        const _position = await apis.lp.createLPPosition(createLPPositionParams);
        setLastPosition(_position);
        // reset form value
        setTotalSize('');
        setRangeFrom('');
        setRangeTo('');
        queryClient.invalidateQueries({queryKey:['user']});
        queryClient.invalidateQueries({queryKey:['market']});
      }catch(error){
        snackbar.error(error);
      }
      side==EOrderSide.LEND?setIsPostingLendOrder(false):setIsPostingBorrowOrder(false);
    })()
  }
  return (<>
    <Table sx={sxCompactFormTable}><TableBody>
      <TableRow hover><TableCell>
        <ToggleButtonGroup
          color="primary"
          value={orderSide}
          exclusive
          onChange={(event,value)=>value!=null&&setOrderSide((value as unknown as EOrderSide))}
          aria-label="order-direction-input"
          disabled={isPostingAnyOrder}
        >
          <ToggleButton value={EOrderSide.LEND}>Lend</ToggleButton>
          <ToggleButton value={EOrderSide.BORROW}>Borrow</ToggleButton>
        </ToggleButtonGroup>
      </TableCell></TableRow>
      <TableRow hover><TableCell>
        <TokenAmountInput name='quantity' fullWidth
          label='Amount'
          sx={styleFormCell} suffix={token?.code} bottomLabel={`Min: ${market.minQuantity}, Step: ${quantityStep}`}
          // Max <AccountInfo token={token} side={orderSide} type='amountOnly'/>
          readOnly={isPostingAnyOrder} 
          step={quantityStep} decimals={decimals} min={market?.minQuantity} max={maxQuantity.toString()}
          amount={totalSize||'0'} onAmountChange={(quantity=>setTotalSize(quantity))} allowsOutOfRange={true}
        />
      </TableCell></TableRow>
      <TableRow hover><TableCell>
        <RateInput name='rangeFrom' label='Lower Range' sx={{...styleFormCell,order:1}} fullWidth
          rate={rangeFrom} step={market.rateStep} min={rangeFromMin} max={rangeFromMax} readOnly={isPostingAnyOrder}
          bottomLabel={rangeFromMin&&`Min = ${new Decimal(rangeFromMin).mul(100).toFixed(2).toString()}%${orderSide==EOrderSide.LEND?' (Current Rate)':''}`}
          onRateChange={handleChangeRangeFrom}
        />
      </TableCell></TableRow>
      <TableRow hover><TableCell>
        <RateInput name='rangeTo' label='Upper Range' sx={{...styleFormCell,order:1}} fullWidth
          rate={rangeTo} step={market.rateStep} min={rangeToMin} max={rangeToMax} readOnly={isPostingAnyOrder}
          bottomLabel={rangeToMax&&`Max = ${new Decimal(rangeToMax).mul(100).toFixed(2).toString()}%`}
           // TODO determine range cap for 0 rate issue
          onRateChange={handleChangeRangeTo}
        />
      </TableCell></TableRow>
      <TableRow hover><TableCell>
        <Box><Box sx={styleFormLabel}>SUMMARY&nbsp;&nbsp;&nbsp;&nbsp;</Box></Box><Box>Provide {format(totalSize||0)} {token?.code} {sideLabel} as Liquidity between {new Decimal(rangeFrom||0).mul(100).toFixed(2).toString()}%-{new Decimal(rangeTo||0).mul(100).toFixed(2).toString()}%</Box>
      </TableCell></TableRow>
      <TableRow hover><TableCell><Typography sx={{color:'error.main'}}>{auth?.user&&maxQuantity.lte(0)&&`Insufficient funds for ${token?.code}`}</Typography></TableCell></TableRow>
      <TableRow hover><TableCell>
        <Button sx={styleFormButton}
          onClick={handleClickOrder} 
          disabled={(!market||!tradeAccount||isPostingAnyOrder||isInvalidInput)}>{actionLabel}</Button>
      </TableCell></TableRow>
    </TableBody></Table>
    <PositionStatus handleClose={()=>setLastPosition(undefined)} lastPosition={lastPosition}/>
  </>);
}

function PositionStatus({lastPosition,handleClose}:{lastPosition?:RateLPPosition,handleClose:Function}){
  return (
    <StandardDialog handleClose={()=>handleClose()} open={!!lastPosition}
      title={`${lastPosition?.side==EOrderSide.LEND?'Lend':'Borrow'} Order Placed`}
      content={<>
        <Box>Quantity: {format(lastPosition?.totalSize)}</Box>
        <Box>Order Date: {formatDateTime(lastPosition?.createDate||Date.now())}</Box>
      </>}
      actions={<Button sx={styleDialogButton} onClick={()=>handleClose()}>OK</Button>}
    />
  );
}

export default withConnectWallet(LiquidityForm);