import { EthereumClient, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { CHAIN_ID, WALLET_CONNECT_PROJECT_ID } from 'src/constants/app';
import { Chain, WagmiConfig, configureChains, createConfig } from 'wagmi';
import { sepolia, mainnet } from 'wagmi/chains';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
// import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { getChainConfig } from './utils/common';

const projectId = WALLET_CONNECT_PROJECT_ID;

// chains array need to contain multiple chain as suggested by walletconnect, regarding the `eth_signTypedData_v4` not found/called issue: https://github.com/orgs/WalletConnect/discussions/3595
const chains = (()=>{
  const chains = new Array<Chain>();
  // push all chains
  chains.push(mainnet);
  chains.push(sepolia);
  [31337,31338].forEach((chainId)=>{
    const chainConfig = getChainConfig(chainId);
    if(!chainConfig) return;
    const {rpc,blockExplorerUrl,name} = chainConfig;
    chains.push({
      id: chainId,
      name: name,
      network: `${chainId}`,
      nativeCurrency: {
        decimals: 18,
        name: 'ETH',
        symbol: 'ETH',
      },
      rpcUrls: {
        public: { http: rpc },
        default: { http: rpc },
      },
      blockExplorers: {
        etherscan: { name: 'SnowTrace', url: blockExplorerUrl },
        default: { name: 'SnowTrace', url: blockExplorerUrl },
      },
      contracts: { },
    } as Chain); 
  })
  return chains;
})();

const { publicClient } = configureChains(chains, [ w3mProvider({ projectId }) ], {
  batch: {
    multicall: {
      wait: 1500,
      batchSize: 1024,
    }
  },
  retryCount: 3,
  retryDelay: 7000,
  pollingInterval: 7000,
  stallTimeout: 7000,
});
const wagmiConfig = createConfig({
  autoConnect: true,
  // connectors: w3mConnectors({ projectId, chains }),
  connectors: [
    // ...w3mConnectors({ projectId, chains }),
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'wagmi',
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: WALLET_CONNECT_PROJECT_ID,
      },
    }),
  ],
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

function WalletProvider({
  children,
}:{
  children:string|JSX.Element|JSX.Element[],
}) {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        {children}
      </WagmiConfig>

      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} defaultChain={chains.find(chain=>chain.id===CHAIN_ID)}/>
    </>
  )
}

export default WalletProvider;