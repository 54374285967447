/* User, Account, Wallet */
import Decimal from 'decimal.js';
import { zAliasShapeMap } from 'src/utils/common';
import { z } from "zod";
import { TokenSchema } from './token';

/* enums */
export enum ETransactionDirection {
	DEPOSIT=-1,
	BORROW=1,
}
export enum EOrderSide {
  LEND=0,
  BORROW=1,
};
// preprocess to cast boolean to number, then cast ZodEffects to remove possible unknown type from preprocess
const zEOrderSide = z.preprocess(v=>Number(v),z.nativeEnum(EOrderSide)) as z.ZodEffects<z.ZodNativeEnum<typeof EOrderSide>, EOrderSide, EOrderSide>;

export enum ERateOrderType {
  MARKET=1,
  LIMIT=2,
}; //  1 for market order, 2 for limit order
export enum ERateOrderStatus {
	PENDING = 0,
	ONBOOK = 1,
	DONE = 10,
	MANUALLY_CANCELLED = 11,
	AUTO_CANCELLED = 12,
	PARTIALLY_FILLED = 13,
  MARKET_EXPIRED = 14,
	ERROR = 99,
};

/* use string value for labeling purpose */
export enum ETradeType {
	SINGLE='single',
	INTEREST_RATE_SWAP='interest_rate_swap',
	CROSS_CURRENCY_SWAP='cross_currency_swap',
	MULTI_LEG='multi_leg',
	UNWIND_POSITION='unwind_position',
}

export const getRateOrderStatusLabel = (status:ERateOrderStatus, fulfilled?: string)=>{
	let statusText = 'Unknown';
	switch(status){
		case ERateOrderStatus.PENDING: statusText = 'Pending'; break;
		case ERateOrderStatus.ONBOOK: {
			statusText = (fulfilled && new Decimal(fulfilled).gt(0)) ? 'Onbook - Partially Filled' : 'Onbook';
			break;
		}
		case ERateOrderStatus.DONE: statusText = 'Done'; break;
		case ERateOrderStatus.MANUALLY_CANCELLED: statusText = 'Manually Cancelled'; break;
		case ERateOrderStatus.AUTO_CANCELLED: statusText = 'Auto Cancelled'; break;
		case ERateOrderStatus.PARTIALLY_FILLED: statusText = 'Partially Filled'; break;
		case ERateOrderStatus.ERROR: statusText = 'Error'; break;
	}
	return statusText;
}
export enum EOrderSource {
	WEB = 1,
	CHAIN = 2,
	LP = 3,
	LIQUIDATION = 4,
	TWAP = 5,
	ACCOUNT_TRANSFER = 6,
	NEGATIVE_BALANCE = 7,
}
export enum EMarketType {
	FLOATING = 1,
	FIXED = 2,
}
export enum EMarketCategory {
	SPOT=1,
	FIXED=2,
}
export enum ERateLPPositionStatus {
	STATUS_PENDING=0,
	STATUS_ACTIVE=1,
	STATUS_CLOSED=2,
}

export enum ERateTwapOrderStatus {
	STATUS_PENDING=0,
	STATUS_ACTIVE=1,
	STATUS_DONE=10,
}


/* Rate Market */
export const TransactionSchema = z.object({
	id: z.number(),
	rate: z.string(),
	size: z.string(),
	dir: z.nativeEnum(ETransactionDirection),
	ts: z.number(),
});
export interface Transaction extends z.infer<typeof TransactionSchema>{};
export const TradeSchema = z.object({
	orderId: z.number().optional(),
	side: zEOrderSide.optional(),
	rate: z.string(),
	quantity: z.string(),
	date: z.number().optional(),
});
export interface Trade extends z.infer<typeof TradeSchema>{};
export const BaseRateOrderSchema = z.object({
	orderId: z.number(),
	floatingOrderId: z.number().optional(),
	fixedOrderId: z.number().optional(),
	marketId: z.number(),
	instrumentId: z.string().optional(),
	// userId: z.number(),
	accountId: z.number(),
	side: zEOrderSide,
	orderType: z.nativeEnum(ERateOrderType),
	quantity: z.string(),
	fulfilled: z.string(),
	status: z.nativeEnum(ERateOrderStatus),
	clientOrderId: z.string(),
	orderDate: z.number(),
	updateDate: z.number().optional(),
	source: z.nativeEnum(EOrderSource),
	averageTradeRate: z.string().optional(), // only if traded
	rate: z.string().optional(), // only for RateOrder->limit order
	maturityDate: z.number().optional(), // only for FixedRateOrder
	dv01: z.string().optional(), // only for FixedRateOrder
	mtm: z.string().optional(), // only for FixedRateOrder
	userAddress: z.string().optional(), // only for MixedRateOrder
});
export interface BaseRateOrder extends z.infer<typeof BaseRateOrderSchema>{};
export const RateOrderSchema = BaseRateOrderSchema.extend({});
export const RateOrderWSSchema = zAliasShapeMap(RateOrderSchema, {
	quantity: 'q',
	fulfilled: 'a',
	side: 's',
	accountId: 'w',
	rate: 'p',
	orderDate: 'd',
	clientOrderId: 'i',
	orderId: 'o',
	orderType: 'O',
	source: 'M',
	status: 'S',
	marketId: 'm',
	instrumentId: 'I'
})
export interface RateOrder extends z.infer<typeof RateOrderSchema>{};
export const FixedRateOrderSchema = BaseRateOrderSchema.extend({
	maturityDate: z.number(), 
	dv01: z.string().default('0') as unknown as z.ZodString,
	mtm: z.string().default('0') as unknown as z.ZodString,
	// TEMP HOTFIX for TradeHistoryTable fr market API 
	tokenId: z.number().optional(),
});
export const FixedRateOrderWSSchema = zAliasShapeMap(FixedRateOrderSchema, {
	quantity: 'q',
	fulfilled: 'a',
	side: 's',
	accountId: 'w',
	rate: 'p',
	orderDate: 'd',
	clientOrderId: 'i',
	orderId: 'o',
	orderType: 'O',
	source: 'M',
	status: 'S',
	marketId: 'm',
	instrumentId: 'I'
})
export interface FixedRateOrder extends z.infer<typeof FixedRateOrderSchema>{};
export const MixedRateOrderSchema = BaseRateOrderSchema.extend({
	marketType: z.nativeEnum(EMarketType),
	floatingOrderId: z.number().optional(),
	fixedOrderId: z.number().optional(),
	userAddress: z.string().optional(),
});
export interface MixedRateOrder extends z.infer<typeof MixedRateOrderSchema>{};

export const RateTransactionSchemaA = z.object({
	// trxId: z.number(),
	side: zEOrderSide,
	rate: z.string(),
	quantity: z.string(),
	date: z.number(), // timestamp
	// marketId: z.number(),
	// borrowPriceIndex: z.string(),
	// lendPriceIndex: z.string(),
	// lendFeeRate: z.string(),
	// lendFee: z.string(),
	// borrowFeeRate: z.string(),
	// borrowFee: z.string(),
	// lendAccountId: z.number(),
	// borrowAccountId: z.number(),
	// lendOrderId: z.number(),
	// borrowOrderId: z.number(),
	// lendLpId: z.number(),
	// borrowLpId: z.number(),
});
export const RateTransactionSchema = zAliasShapeMap(RateTransactionSchemaA,{
	// trxId: 't',
	side: 's',
	rate: 'p',
	quantity: 'q',
	date: 'd',
});
export interface RateTransaction extends z.infer<typeof RateTransactionSchemaA>{};
export const RatePositionSchema = z.object({
	accountId: z.number(),
	marketId: z.number(),
	rate: z.string().optional(),
	priceIndex: z.string().optional(),
	quantity: z.string(),
	updateDate: z.number(),
});
export interface RatePosition extends z.infer<typeof RatePositionSchema>{};
export const FixedRatePositionSchema = z.object({
	accountId: z.number(),
	marketId: z.number(),
	rate: z.string(),
	quantity: z.string(),
	createDate: z.number(),
	daysToMaturity: z.number(),
});
export interface FixedRatePosition extends z.infer<typeof FixedRatePositionSchema>{};
export const MarketDataSummarySchema = z.object({
	dv01: z.string(),
	position: z.string(),
	carry: z.string(),
});
export const AggregatedMarketPositionSchema = z.object({
	accountId: z.number(),
	instrumentId: z.string(),
	tokenId: z.number(),
	quantity: z.string(),
	pv: z.string(),
	price: z.string(), // local price used when calculating positions
	// ir
	priceIndex: z.string().optional(),
	// fr (aggregate)
	rate: z.string().optional(),
	interest: z.string().optional(),
	mtm: z.string().optional(),
	dv01: z.string().optional(),
	maturityDate: z.number().optional(),
	// runtime
	side: zEOrderSide.optional(),
}).refine(schema=>{
	schema.side = Number(schema.quantity)<0?EOrderSide.BORROW:EOrderSide.LEND;
	return true;
});
export interface AggregatedMarketPosition extends z.infer<typeof AggregatedMarketPositionSchema>{};
export const GroupedPositionSchema = z.object({
	tokenId: z.number(),
	quantity: z.string(),
	pv: z.string(),
	interest: z.string(),
	mtm: z.string(),
	dv01: z.string(),
	positions: z.array(AggregatedMarketPositionSchema),
	price: z.string(), // local price used when calculating positions
	// runtime
	side: zEOrderSide.optional(),
}).refine(schema=>{
	schema.side = Number(schema.quantity)<0?EOrderSide.BORROW:EOrderSide.LEND;
	return true;
});
export interface GroupedPosition extends z.infer<typeof GroupedPositionSchema>{};
export interface MarketDataSummary extends z.infer<typeof MarketDataSummarySchema>{};
export const MarketDataSchema = MarketDataSummarySchema.extend({
	marketId: z.number(),
	side: zEOrderSide,
	bid: z.string(),
	bidStepSize: z.string(),
	ask: z.string(),
	askStepSize: z.string(),
});
export interface MarketData extends z.infer<typeof MarketDataSchema>{};
export const MarketFixedRateDataSchema = MarketDataSchema.extend({
	daysToMaturity: z.number(),
	marketRate: z.string(),
	pv01Series: z.string(),
});
export interface MarketFixedRateData extends z.infer<typeof MarketFixedRateDataSchema>{};
export const MarketHistoricalRateItemSchema = z.object({
	date: z.number(), // datetime
	open: z.string(),
	close: z.string(),
	high: z.string(),
	low: z.string(),
	volume: z.string(),
	totalValue: z.string(),
	lendDepth: z.string(),
	borrowDepth: z.string(),
	numDaysAgo: z.number(),
});
export interface MarketHistoricalRateItem extends z.infer<typeof MarketHistoricalRateItemSchema>{};
export const MarketPastRateSchema = MarketHistoricalRateItemSchema.extend({
	tokenId: z.number(),
	marketId: z.number(),
	daysToMaturity: z.number().optional(),
	maturityDate: z.number().optional(),
	volume24: z.string(),
	midRate:z.string(),
	midRate24:z.string(),
	midRateDelta24:z.string(),
	rate:z.string(),
	rate24:z.string(),
	rateDelta24:z.string(),
	// other protocols
	aaveLendRate:z.string().optional(),
	aaveBorrowRate:z.string().optional(),
	deribitImpliedRate:z.string().optional(),
	okxImpliedRate:z.string().optional(),
});
export interface MarketPastRate extends z.infer<typeof MarketPastRateSchema>{};
export const MarketPastRatesSchema = z.record(
	// { [key:number]: MarketPastRate[] }
	z.number(), z.array(MarketPastRateSchema)
);
export interface MarketPastRates extends z.infer<typeof MarketPastRatesSchema>{};
export const MarketHistoricalRatesSchema = z.object({
	// {
	// 	'fixed':{[daysToMaturities:number]:MarketHistoricalRateItem[]},
	// 	'floating':MarketHistoricalRateItem[],
	// }
	fixed: z.record(
		z.number(), z.array(MarketHistoricalRateItemSchema),
	),
	floating: z.array(MarketHistoricalRateItemSchema),
});
export type MarketHistoricalRates = z.infer<typeof MarketHistoricalRatesSchema>;
export const OrderRateBucketSchema = z.object({
	marketId: z.number(),
	rate: z.string(),
	quantity: z.string(),
});
export interface OrderRateBucket extends z.infer<typeof OrderRateBucketSchema>{};

const MarketInfoSchemaA = z.object({
	marketId: z.number(),
	rate: z.string(),
	direction: z.boolean(),
	updateDate: z.number(),
	// rate market
	priceIndexDate: z.number().optional(),
	borrowPriceIndex: z.string().optional(),
	lendPriceIndex: z.string().optional(),
	// fixed rate market
	midRate: z.string().optional(),
}).omit({updateDate:true});
export const MarketInfoSchema = zAliasShapeMap(MarketInfoSchemaA,{
	marketId: 'm',
	rate: 'p',
	direction: 'd',
	// updateDate: 'u',
	priceIndexDate: 'pd',
	borrowPriceIndex: 'b',
	lendPriceIndex: 'l',
	midRate: 'M',
});
export interface MarketInfo extends z.infer<typeof MarketInfoSchemaA>{};

/* external protocol market info */
const ExternalMarketInfoSchemaA = z.object({
	provider: z.number(),
	marketId: z.number(),
	lendRate: z.string().optional(),
	borrowRate: z.string().optional(),
	impliedRate: z.string().optional(),
	updateDate: z.number(),
});//.omit({updateDate:true});
export const ExternalMarketInfoSchema = zAliasShapeMap(ExternalMarketInfoSchemaA,{
	provider: 'p',
	marketId: 'm',
	lendRate: 'L',
	borrowRate: 'B',
	impliedRate: 'i',
	updateDate: 'u',
});
export interface ExternalMarketInfo extends z.infer<typeof ExternalMarketInfoSchemaA>{};

export const SpotRatePositionSchema = z.object({
	accountId: z.number(),
	marketId: z.number(),
	priceIndex: z.string(),
	quantity: z.string(),
});
export interface SpotRatePosition extends z.infer<typeof SpotRatePositionSchema>{};

export const OrderBookEntrySchemaA = z.object({
	rate: z.string(),
	quantity: z.string(),
});
export const OrderBookEntrySchema = zAliasShapeMap(OrderBookEntrySchemaA,{
	rate: 'p',
	quantity: 'q',
});
export interface OrderBookEntry extends	z.infer<typeof OrderBookEntrySchemaA>{};
export const OrderBookEntryWithDepthSchema = OrderBookEntrySchemaA.extend({
	side: zEOrderSide,
	step: z.string(),
	borrowDepth: z.string().optional(),
	lendDepth: z.string().optional(),
	// for orderbook graph
	localMin: z.string().optional(),
	localMax: z.string().optional(),
	quantityLast: z.string().optional(),
	localPercentage: z.number().optional(),
});
export interface OrderBookEntryWithDepth extends z.infer<typeof OrderBookEntryWithDepthSchema>{};

export const RateOrderBookSchema = z.object({
	asks:z.array(OrderBookEntryWithDepthSchema),
	bids:z.array(OrderBookEntryWithDepthSchema),
	depths:z.array(OrderBookEntryWithDepthSchema),
	bidSum:z.string(),
	askSum:z.string(),
	bidMax:z.string(),
	askMax:z.string(),
	bidMaxRate:z.string().optional(),
	askMinRate:z.string().optional(),
});
export interface RateOrderBook extends z.infer<typeof RateOrderBookSchema>{};

// deep level alias needs to be defined separately to extract correct interface from schema
export const OrderBookSchemaA = z.object({
	asks: z.array(OrderBookEntrySchemaA),
	bids: z.array(OrderBookEntrySchemaA),
});
export const OrderBookSchema = zAliasShapeMap(z.object({
	asks: z.array(OrderBookEntrySchema),
	bids: z.array(OrderBookEntrySchema),
}),{
	asks: 'a',
	bids: 'b',
});

export interface OrderBook extends z.infer<typeof OrderBookSchemaA>{};

/**
 * Object only for parsing symbol code (e.g. ETH-2D)
 */
export const MarketForSymbolSchema = z.object({
	// only need token for code & maturity date for rolling date 
	token:TokenSchema,
	marketId:z.number(),
	maturityDate:z.number().optional(),
	daysToMaturity:z.number().optional(),
});
export interface MarketForSymbol extends z.infer<typeof MarketForSymbolSchema>{};
export const BaseMarketSchema = z.object({
	marketId:z.number(),
	tokenId:z.number(),
	quantityStep:z.string(),
	minQuantity:z.string(),
	maxQuantity:z.string(),
	rateStep:z.string(),
	category:z.nativeEnum(EMarketCategory), // TODO remove this
	enable:z.boolean(),
	name:z.string(),
	instrumentId:z.string(),
	token: TokenSchema,
	direction: z.boolean().optional(),
	rate:z.string().optional(),
	midRate:z.string().optional(),
	updated: z.boolean().optional(),
	updateDate: z.number().optional(),
	// fr/ir compatibility
	daysToMaturity:z.number().optional(),
	maturityDate:z.number().optional(),
	deposits:z.number().optional(),
	borrows:z.number().optional(),
	subscriptions: z.string().optional(),
	volume24:z.string().optional(),
	rate24:z.string().optional(),
  lpDeposit: z.string().optional(),
  lpBorrower: z.string().optional(),
	borrowPriceIndex:z.string().optional(),
	lendPriceIndex:z.string().optional(),
  referenceMarketSize: z.string().optional(),
	takerFeeRate: z.string().optional(),
	makerFeeRate: z.string().optional(),
	interestFeeRate: z.string().optional(),
	borrowLpFeeRate: z.string().optional(),
	lendLpFeeRate: z.string().optional(),
});
export interface BaseMarket extends z.infer<typeof BaseMarketSchema>{};
export const MarketFixedRateSchema = BaseMarketSchema.extend({
	maturityDate: z.number(), // timestamp 1667001600000,
	daysToMaturity: z.number(),
	// DEV catch makes this optional
	// category: z.preprocess(v=>EMarketCategory.FIXED,z.nativeEnum(EMarketCategory)),
});
export interface MarketFixedRate extends z.infer<typeof MarketFixedRateSchema>{};
export const FixedRateActiveMarketSchema = z.object({
	tokenId: z.number(),
	marketId: z.number(),
	name: z.string(),
	timeLabel: z.string(),
	maturityDate: z.date(),
	// taskDate: z.date(), //ignore
});
export type FixedRateActiveMarket = z.infer<typeof FixedRateActiveMarketSchema>;
export const MarketSchema = BaseMarketSchema.extend({
	takerFeeRate: z.string(),
	makerFeeRate: z.string(),
	interestFeeRate: z.string(),
	borrowLpFeeRate: z.string().optional(),
	lendLpFeeRate: z.string().optional(),
	borrowPriceIndex: z.string(),
	actualRate: z.string(),
	deposits: z.number(),
	borrows: z.number(),
	subscriptions: z.string(),
	lendPriceIndex: z.string(),
	priceIndexDate: z.number(),
  lpDeposit: z.string().optional(),
  lpBorrower: z.string().optional(),
  referenceMarketSize: z.string().optional(),
});
export interface Market extends z.infer<typeof MarketSchema>{};
export const MarketHistoryItemSchema = z.object({
	open: z.string(),
	close: z.string(),
	high: z.string(),
	low: z.string(),
	volume: z.string(),
	totalValue: z.string().optional(),
	lendDepth: z.number().optional(),
	borrowDepth: z.number().optional(),
	date: z.number(), // timestamp / 1000
});
export interface MarketHistoryItem extends z.infer<typeof MarketHistoryItemSchema>{};
export const MarketHistoryTVItemSchema = z.object({
	totalValue: z.string(),
	date: z.number(), // timestamp / 1000
});
export interface MarketHistoryTVItem extends z.infer<typeof MarketHistoryTVItemSchema>{};
export const MarketHistoryTVLItemSchema = z.object({
	totalValueLockedUsd: z.string(),
	date: z.number(), // timestamp / 1000
});
export interface MarketHistoryTVLItem extends z.infer<typeof MarketHistoryTVLItemSchema>{};

/* Fixed Rate Market */
export const MarketFixedRateWithSizeSchema = z.object({
	tokenId: z.number(),
	instrumentId: z.string(),
	name: z.string(),
	updated: z.boolean().optional(),
	deposits: z.string(),
	borrows: z.string(),
	subscriptions: z.string(),
});
export interface MarketFixedRateWithSize extends z.infer<typeof MarketFixedRateWithSizeSchema>{};
export const MarketYieldCurveRateSchema = z.object({
	"marketId": z.number(),
	"tokenId": z.number(),
	"interpolatedPx": z.string(),
	"pv01Series": z.string().optional(),
	"daysToMaturity": z.number(),
	"maturityDate": z.number().optional(),
	"enable": z.boolean(),
});
export interface MarketYieldCurveRate extends z.infer<typeof MarketYieldCurveRateSchema>{};
export const MarketYieldCurveHistoricalItemSchema = z.object({
	"marketId": z.number(),
	"rate": z.string().optional(), 			// zAxis: might be null
	// "genDate": z.number(), 					// xAxis: historicalDate
	"label": z.string(), 								// bucketLabels
	"xpos": z.number(),  								// yAxis: daysToMaturity bucket
});
export interface MarketYieldCurveHistoricalItem extends z.infer<typeof MarketYieldCurveHistoricalItemSchema>{};
export const MarketYieldCurveHistoricalMapSchema = z.record(
	//  [genDateStr:string]:MarketYieldCurveHistoricalItem[];
	z.string(), z.array(MarketYieldCurveHistoricalItemSchema)
)
export interface MarketYieldCurveHistoricalMap extends z.infer<typeof MarketYieldCurveHistoricalMapSchema>{};

export const MarketImpliedForwardRateRateSchema = z.object({
	"fromMarketId": z.number(),
	"toMarketId": z.number(),
	"fromMaturityDate": z.number().optional(),
	"toMaturityDate": z.number().optional(),
	"fromRate": z.string(),
	"toRate": z.string(),
	"daysBetweenNowAndM1": z.number(),
	"daysBetweenNowAndM2": z.number(),
	"daysBetweenM1AndM2": z.number(),
	"impliedForwardRate": z.string(),
});
export interface MarketImpliedForwardRateRate extends z.infer<typeof MarketImpliedForwardRateRateSchema>{};

/* LPs */
export const RateLPPositionSchema = z.object({
	lpId: z.number(),
	accountId: z.number(),
	marketId: z.number(),
	side: zEOrderSide,
	relative: z.boolean(), //false to be relative
	distribution: z.string(),
	rangeFrom: z.string(),
	rangeTo: z.string(),
	totalSize: z.string(),
	matchedSize: z.string(), // matchedSize / totalSize = usage percentage
	status: z.nativeEnum(ERateLPPositionStatus),
	fee: z.string(),
	createDate: z.number(),
});
export interface RateLPPosition extends z.infer<typeof RateLPPositionSchema>{};

/* TWAPS */
export const RateTwapOrderSchema = z.object({
	twapId: z.number(),
	accountId: z.number(),
	marketId: z.number(),
	side: zEOrderSide,
	durationMin: z.number(),
	quantity: z.string(),
	fulfilled: z.string(),
	status: z.nativeEnum(ERateTwapOrderStatus),
	createDate: z.number(),
	orderDate: z.number(),
});
export interface RateTwapOrder extends z.infer<typeof RateTwapOrderSchema>{};

/* group by marketId-side */
export const RateLPPositionGroupSchema = z.object({
	accountId: z.number(),
	marketId: z.number(),
	side: zEOrderSide,
	totalSize: z.string(),
	matchedSize: z.string(), // matchedSize / totalSize = usage percentage
	fee: z.string(),
	positions: z.array(RateLPPositionSchema),
	createDate: z.number(),
});
export interface RateLPPositionGroup extends z.infer<typeof RateLPPositionGroupSchema>{};

/*   */
export const GroupedRateTransactionSchema = RateTransactionSchemaA.extend({
	rateLow: z.string(),
	rateHigh: z.string(),
});
export interface GroupedRateTransaction extends z.infer<typeof GroupedRateTransactionSchema>{};

export type RateBucketEntry = {
	marketId: number;
	rate: string;
	quantity: string;
  }

export type SelectedOrderBookEntry = {
	rate?: string;
	side?: string;
	column?: string;
	size?: Decimal;
  }

export type CancelledOrderResponseItem = {
	accountId: number;
	orderId: number;
	clientOrderId: string;
	code: number;
}

export type OrderCancelResponse = {
	success: boolean;
	data: {
		cancelOrders: CancelledOrderResponseItem[]
	}
}

export interface TradeHistoryItem {
	tokenId: number,
	marketId: number,
	orderId: number,
	marketType: EMarketType,
	accountId: number,
	quantity: Decimal,
	quantityVector: string,
	quantityVectorUSD?: string,
	side: EOrderSide|boolean,
	orderDate: number,
	updateDate?: number,
	source: EOrderSource,
	rate?: string,
	averageTradeRate?: string, // read from `averageTradeRate`
	status: ERateOrderStatus,
	fulfilled?: Decimal,
	userAddress?: string,
	mtm?: string,
	dv01?: string,
	type?: unknown,
	long?: unknown,
	short?: unknown,
	// rateOrder?: RateOrder,
	// fixedRateOrder?: FixedRateOrder,
	// order: RateOrder | FixedRateOrder,
	market?: unknown,
	maturityDate?: number,
	executed?: unknown,
	orderType?: ERateOrderType,
	action?: unknown,
	decimals?: number;
  }

export type FixedRateOrderData = {
    type: string,
    orders: FixedRateOrder[],
    hasNextPage?: boolean,
    lastOrderId?: number
}
export type FloatRateOrderData = {
    type: string,
    orders: RateOrder[],
    hasNextPage?: boolean,
    lastOrderId?: number
}
