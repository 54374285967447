import { keyframes } from '@mui/material';
import { SxProps } from '@mui/system';
import theme from '../utils/theme';

export const sxMobileOnly:SxProps = { [theme.breakpoints.up('tablet')]:{ display: 'none', visibility: 'hidden', opacity: 0, width: 0, height: 0, pointerEvents: 'none' } };
export const sxDesktopOnly:SxProps = { [theme.breakpoints.down('tablet')]:{ display: 'none', visibility: 'hidden', opacity: 0, width: 0, height: 0, pointerEvents: 'none' } };

export const sxMonoFont:SxProps = {
  fontFamily: "'Azeret Mono',monospace",
  letterSpacing: '-0.04rem',
}

export const sxClickable:SxProps = {cursor:'pointer'};
export const sxInlineLink:SxProps = {display:'inline',cursor:'pointer',color:'primary.light'};
export const sxFlexFill:SxProps = {flex:1};
export const sxBoxWithHeadingWrapper:SxProps = {display:'flex',flexDirection:'column',height:'100%','.MuiBox-root:last-of-type':{flex:1}};
export const sxBox:SxProps = {
  height: 'auto',
  width: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'border.dark',
  borderRadius: '4px',
  backgroundColor: 'background.paper',
  p: '12px',
  marginBottom: '8px',
  contain: 'content',
  [theme.breakpoints.down('tablet')]:{
    borderRadius: '4px',
    p: 1,
    m: '6px',
    width: 'calc(100% - 12px)',
    maxWidth: 'calc(100vw - 12px)',
    '.MuiTableContainer-root': {
      p: 0,
    }
  },
};
export const sxBoxTitle:SxProps = {
  borderWidth: '0',
  borderStyle: 'solid',
  borderColor: 'border.dark',
  borderBottomWidth: '1px',
  mx: '-12px',
  px: '12px',
  paddingBottom: '12px',
  marginBottom: '12px',
  fontSize: '0.875rem',
  fontWeight: 700,
  [theme.breakpoints.down('tablet')]:{
    m: '-8px', marginBottom: '8px',
    paddingTop: '12px',
  }
};
export const sxBoxInner:SxProps = {
  flex: 1,
  m: '-12px',
  padding: '12px 12px 0 4px',
  [theme.breakpoints.down('tablet')]:{
    m: '-8px', marginTop: '8px', p: '8px',
  }
};
export const sxTitleFloatRight:SxProps = {
  float: 'right',
  textAlign: 'right',
  flex: 1,
  fontWeight: 'initial',
  [theme.breakpoints.down('tablet')]:{
    display: 'block',
    float: 'none',
  }
};
export const sxTitleRow:SxProps = {
  p:'16px', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
}

export const sxPrimaryText:SxProps = {
  color: 'primary.light',
  textDecorationColor: theme.palette.primary.light,
};

/* box table */
export const sxBoxCompactInner:SxProps = {
  m: '-12px',
  [theme.breakpoints.down('tablet')]:{
    m: '-8px',
  }
};
export const sxBoxCompactInnerWithFullTable = {
  ...sxBoxCompactInner,
  height:'100%',
  ".MuiTableContainer-root, .MuiTable-root":{
    height:'100%',
  }
}
export const sxBoxTableContainer:SxProps = {
  flex:1,display:'flex',alignItems:'center',justifyContent:'center',
  // bgcolor: 'dialog.light',
  ".recharts-responsive-container":{
    // py:3,
  }
};
export const sxBoxTableContainerBordered:SxProps = {
  // flex:1,display:'flex',alignItems:'center',justifyContent:'center',
  borderStyle: 'solid', borderColor: 'border.dark', borderWidth: '1px', mx: '-1px', width: 'calc(100% + 2px)',
};


export const sxRow:SxProps = {
  // display: 'grid',
  // gridTemplateColumns: 'repeat(auto-fit, minmax(33%, 1fr))',
  // gridColumnGap: '8px',
  // marginBottom: '8px',
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'center',
  gap: '8px',
  // width: '100%'
  ">.MuiBox-root":{
    overflow:'hidden',
  },
  [theme.breakpoints.down('tablet')]:{
    flexDirection:'column',
  }
};
export const sxRowWideGap:SxProps = {
  ...sxRow, gap: '16px', marginBottom: '8px',
};
export const sxColumns:SxProps = {
  display: 'flex',
  flexDirection:"row",
  columnGap:'8px', rowGap:'8px',
  [theme.breakpoints.down('tablet')]:{
    borderRadius: '8px',
    m: '6px', width: 'calc(100% - 12px)',
    flexDirection:'column',
  }
};
export const sxColumnsWrap:SxProps = {
  ...sxColumns,
  flexWrap:'wrap',
}
export const sxColumn:SxProps = {
  flex: 1, maxWidth: '100%', display:'flex', overflow: 'hidden',
  [theme.breakpoints.down('tablet')]:{
    width: '100%',
    marginBottom: '40px',
  },
};
export const sxColumnShrink:SxProps = {
  ...sxColumn,
  overflow:'hidden',
};
export const sxColumnForGraph:SxProps = {
  ...sxColumnShrink,
  paddingRight:3,paddingTop:3,paddingBottom:2
}
export const sxColumn33:SxProps = {
  ...sxColumn, flex: 'auto', width: '33%',
};
export const sxColumn50:SxProps = {
  ...sxColumn, flex: 'auto', width: '50%',
};
export const sxColumn66:SxProps = {
  ...sxColumn, flex: 'auto', width: '66%',
};
export const sxColumnSeparator:SxProps = {
  display: 'inline-block',
  height: '100%', width: '1px',
  bgcolor: 'text.primary',
  mx: '-4px',
}

export const sxColumnsGrid:SxProps = {
  display: 'grid',
  gap: '20px',
  [theme.breakpoints.down('tablet')]:{
    display: 'block',
  }
};
export const sxColumnGridGraph:SxProps = {
  overflow: 'hidden', p:'8px', display: 'flex', flexDirection: 'column',
  [theme.breakpoints.down('tablet')]:{
    display: 'grid',
    minHeight: '300px',
    maxHeight: '50vh',
  }
}

export const sxColumnGridTable:SxProps = {
  overflow: 'hidden', m:'calc(-12px - 1px)', borderWidth:'1px', borderStyle:'solid', borderColor:'#0a4481',
  [theme.breakpoints.down('tablet')]:{
    border: '0', m: '-6px',
  }
}

export const styleBoxColumns:SxProps = {...sxBox,...sxColumns};
export const styleBoxBlock:SxProps ={
  flex: 1,
};
export const styleBoxHeader:SxProps = {
  bgcolor: 'background.paper',
  textAlign: 'center',
  px: 2,
  py: 1.5,
  // borderBottom: '2px solid',
  // borderColor: 'dialog.light',
};
export const styleBoxPanel:SxProps = {
  // bgcolor: 'background.paper',
  display: 'flex',
  flexDirection:'column',
  flex: 1,
  alignItems: 'center',
  // px: 2,
  // py: 1.5,
};
export const sxTableTitleRow:SxProps = {
  display:'grid',gridTemplateColumns:'1fr auto',marginBottom:6,alignItems:'center',
  [theme.breakpoints.down('tablet')]:{
    display: 'block',
  }
};
export const styleTableContainer:SxProps = {
  p:2,
};
export const styleTable:SxProps = {
  // px: 2, py: '2px',
  bgcolor:'transparent',
  "thead tr":{
    whiteSpace: 'nowrap',
    bgcolor:'background.paper',
  },
  "td":{
    fontSize: '1.125rem',
  },
  "th":{
    fontSize: '.725rem',
  },
  "tbody tr:not(:hover)":{
    // bgcolor: 'background.paper',
  },
  "tbody tr.expandedChild:not(:hover) td":{
    bgcolor: 'dialog.light'
  },
  tableLayout:"auto",
  "td:not(:first-of-type)":{
    width:0,
  },
  "th,td":{
    px:2, py: 1,
    bgcolor:'background.paper',
  },
  "tr:hover":{
    "th,td":{
      bgcolor:'action.hover',
    },
  },
  "th:first-of-type,td:first-of-type":{ paddingLeft:0 },
  "th:last-of-type,td:last-of-type":{ paddingRight:0 },
  [theme.breakpoints.down('tablet')]:{
    // tableLayout: 'fixed',
    "th,td":{
      ":nth-of-type(n)":{
        p:0.5,
      },
    },
    "th":{
      fontSize: '.5rem',
    },
    "td":{ 
      fontSize: '1rem',
      ":before":{
        display:'none',
      }
    },
  },
};

export const styleTableMobileWrap:SxProps = {
  ...styleTable,
  [theme.breakpoints.down('tablet')]:{
    tableLayout: 'fixed',
    p: 0,
    mx: '-4px',
    width: 'calc(100% + 8px)',
    "thead":{
      display: 'none', visibility:'hidden', opacity: 0,
    },
    "tr":{
      display:"flex", flexDirection:"row", flexWrap:'wrap',
      borderRadius: '8px', marginBottom: '1rem',
      "th,td":{
        bgcolor: 'dialog.main', 
      },
    },
    "td":{
      "&:nth-of-type(n)":{
        // textAlign: 'left',
        fontSize: '0.75rem',
        width: 'auto', border:'none',
      }
    },
  }
};
export const sxBoxBigLabel:SxProps = {
  fontSize: '1.5rem',
  // display: 'inline-block',
}
export const sxLabel:SxProps = {
  fontSize: '.75rem',
  opacity: .5,
  // display: 'inline-block',
}
export const sxLabelBold:SxProps = {
  opacity: .5,
  fontWeight: 700,
}
export const sxHalfOpacity:SxProps = {
  opacity: .5,
}
export const StylePrimaryButton:SxProps = {
  "&,&.MuiButton-containedPrimary":{
    fontSize: '1rem',
    fontWeight: 700,
    textTransform: 'initial',
    borderRadius: '4px',
    height: '40px', 
    py: 0,
    "&:not([disabled])":{
      bgcolor: 'primary.light',
      // color: 'primary.dark',
      "&:hover": {
        bgcolor: 'primary.main',
      //   color: 'primary.dark',
      },
    },
  },
}

export const sxMenuModalRoot:SxProps = {
  "&.MuiModal-root":{
    ".MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper":{
      m:'4px',
    },
  }
};

export const sxMenuItem:SxProps = {
  maxWidth: '100%',
  minWidth: '104px',
  fontWeight: 600,
  fontSize: '0.875rem',
  m: 'auto',
  "a":{
    textDecoration:'none',
    width:'100%',
    flex: '1',
    margin: '-4px',
    padding: '4px',
  },
  "svg":{
    verticalAlign:'bottom',
    marginRight: '8px',
  },
  ".Mui-focusVisible":{
    bgcolor: 'inherit',
  }
};
export const sxMenuItemSeparator:SxProps = {
  bgcolor: 'transparent',
  borderStyle: 'solid',
  borderColor: 'border.dark',
  borderWidth: 0,
  borderBottomWidth: '1px',
  mx: '-8px', my: '8px', height: 0,
}

export const sxBoxHeading:SxProps = {
  display:'block',
  fontSize:'1.25rem',
}

export const sxErrorMain:SxProps = {
  gridArea:'main',
  height:'100%',
  width:'100%',
  margin:'0',
  background:'background.default',
  color:'text.primary',
  fontFamily:'sans-serif',
  display:'grid',
  alignItems:'center',
  justifyContent:'center',
};
export const sxErrorStack:SxProps = {
  my:'16px',
  maxWidth: '100%',
  maxHeight: '80vh',
  overflow: 'auto',
  fontFamily: 'monospace',
  bgcolor:'background.default',
}
export const sxErrorInnerBox:SxProps = {
  my:'8px',textAlign:'center'
};

const animTextUp = keyframes`from{color:var(--animValueUpColor); } to{color:inherit; } `;
const animTextDown = keyframes` from{color:var(--animValueDownColor); } to{color:inherit; }`;
const animTextBookUp = keyframes`from{color:var(--animCellDownBgColor); } to{color:inherit; } `;
const animTextBookDown = keyframes` from{color:var(--animCellUpBgColor); } to{color:inherit; }`;
const animTextArrowFade = keyframes` from{opacity:1 } to{opacity:0 }`;

export const sxDeltaTextPositive:SxProps = { 
  animation: `${animTextUp} 1.5s 1 ease-out forwards`, 
  ":before":{
    animation: `${animTextArrowFade} 1.5s 1 ease-out forwards`,  
    content: '"\\2191"',
  },
};
export const sxDeltaTextNegative:SxProps = { 
  animation: `${animTextDown} 1.5s 1 ease-out forwards`, 
  ":before":{
    animation: `${animTextArrowFade} 1.5s 1 ease-out forwards`, 
    content: '"\\2193"',
  },
};
export const sxRightDeltaTextPositive:SxProps = { 
  animation: `${animTextBookUp} 1.5s 1 ease-out forwards`,
  ":after":{
    animation: `${animTextArrowFade} 1.5s 1 ease-out forwards`,
    content: '"\\2191"',
  },
};
export const sxRightDeltaTextNegative:SxProps = { 
  animation: `${animTextBookDown} 1.5s 1 ease-out forwards`,
  ":after":{
    animation: `${animTextArrowFade} 1.5s 1 ease-out forwards`,
    content: '"\\2193"',
  },
};

export const sxTabButton:SxProps = {
  opacity: 0.5,
  m: '-12px',
  marginRight: '12px',
  p: '12px',
  cursor: 'pointer',
  ":hover":{
    opacity: 0.75,
  },
};

export const sxTabButtonActive:SxProps = {
  ...sxTabButton,
  opacity: 1,
  borderWidth: 0,
  borderStyle: 'solid',
  borderColor: 'text.primary',
  paddingBottom: '10px',
  borderBottomWidth: '2px',
};

export const sxCellLoading:SxProps = {
  "@keyframes loadingAnim": {
      "0%": {
        opacity: 1,
      },
      "50%": {
        opacity: 0,
      },
      "100%": {
        opacity: 1,
      },
  },
  animation: 'loadingAnim 1s infinite linear',
}


export const sxButtonPlaceholder:SxProps = {
  zIndex:-1,opacity:0,pointerEvents:'none'
}

export const sxTableBorder: SxProps = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'border.dark',
  borderRadius: '4px',
}

export const sxFilterBoxRow: SxProps = {
  display:'flex',columnGap:'8px',/*p:'8px',*/overflow:'auto',
  flexWrap: 'wrap', rowGap: '8px', flexShrink: 0,
  [theme.breakpoints.down('tablet')]:{
    // paddingTop: '28px',
  }
}

