import {
  Box, Table, TableBody, TableContainer, TableHead, TableRow
} from '@mui/material';
import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { useAuth } from 'src/AuthProvider';
import useAppStore from 'src/store';
import { sxCellBottomAlign, sxDate, sxTableRowFollowLast, sxTokenTable, sxTokenTableContainer } from 'src/styles/TokenTable';
import { Token, UserTokensGraphDateBucketType } from 'src/types';
import { sortBy, unique } from 'src/utils/common';
import { date, now, useCallbackFormatDaysFromMarketToday, useCallbackGetDaysFromMarketToday, useRollingDateDays } from 'src/utils/date';
import { formatTokenAmount, formatTokenDV01 } from 'src/utils/numbers';
import apis from '../utils/apis';
import LoadingIcon from './Loading';
import MarketRollingDateLabel from './MarketRollingDateLabel';
import SidedQuantityValue from './SidedQuantityValue';
import ValueTableCell from './ValueTableCell';

/**
 * 
 * @param dataKey data to be rendered
 * @param bucketData aggregate data inbetween standard dates as bucket
 * @returns 
 */
function UserTokensTable({dataKey,bucketData,showsUSDValue,dateBucketType='By Contract'}:{dataKey:'dv01'|'position'|'mtm'|'pv',bucketData?:boolean,showsUSDValue?:boolean,dateBucketType?:UserTokensGraphDateBucketType}){
  const auth = useAuth();  
  const daysToMaturities = useRollingDateDays();
  const getDaysFromMarketToday = useCallbackGetDaysFromMarketToday();
  const formatDaysFromMarketToday = useCallbackFormatDaysFromMarketToday();
  const {data:tokens,isLoading:isLoadingTokens} = apis.token.useMarketsPageTokens();
  // user account info
  const { currentUserAccountId: currentUserAccountId } = useAppStore();
  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  const tradeAccount = userAccounts&&apis.user.extractUserAccount(userAccounts,currentUserAccountId);
  const {data:userAccountPerformanceLive} = apis.user.useUserAccountPerformanceLive(auth,{ accountId: tradeAccount?.accountId });
  const groupedPositions = useMemo(() => userAccountPerformanceLive?.positionDetails, [userAccountPerformanceLive])
  const { data:mtms } = apis.user.useUserMarkToMarketByMarket(auth,{accountIds:userAccounts?.map(w=>w.accountId)});

  const isLoadingAny = isLoadingTokens;

  // generate rechart data
  // type TData = {i:number;name:string;[code:string]:number|undefined;};
  const {cutoffDays,data,tokenValueMap} = useMemo(()=>{
    // generate cutoff days according to dateBucketType
    let cutoffDays:number[];
    switch(dateBucketType){
      case 'Monthly':
        cutoffDays = (new Array(6).fill(0)).map((_,i)=>now().add(i+1,'month').diff(now(),'day'));
        break;
      case 'Quarterly':
        cutoffDays = (new Array(6).fill(0)).map((_,i)=>now().add(i+3,'month').diff(now(),'day'));
        break;
      case 'Semi-Annually':
        cutoffDays = (new Array(4).fill(0)).map((_,i)=>now().add(i+6,'month').diff(now(),'day'));
        break;
      case 'Annually':
        cutoffDays = (new Array(3).fill(0)).map((_,i)=>now().add(i+12,'month').diff(now(),'day'));
        break;
      case 'By Contract': default:
        cutoffDays = unique([0,...daysToMaturities]);
        break;
    }
    // create buckets array
    const data:any[] = cutoffDays.map((day,i)=>({
      i, d:0, day:day,
    }));
    // fill in elements
    const tokenValueMap:{[code:string]:Decimal} = {};
    const mtmMarketMap = mtms?.reduce((map,mtmObj)=>{
      const { instrumentId, mtm:_mtm } = mtmObj;
      const mtm = new Decimal(map.get(instrumentId)||0).add(new Decimal(_mtm||0));
      map.set(instrumentId,mtm);
      return map;
    },new Map<string,Decimal>());
    tokens&&groupedPositions?.forEach(gps=>{
      gps.positions.forEach(d=>{
        let i; let cutoff = 0;
        const t = tokens.filter(t=>t.tokenId===d.tokenId)[0];
        if(!t) return;
        const days = d.maturityDate===undefined?0:getDaysFromMarketToday(date(d.maturityDate));
        // find the next cutoff day index i.e. a 27 day market would have a cutoff of 30 day
        for(i=0;i<cutoffDays.length;i++){
          if(cutoff>=days) break;
          cutoff = cutoffDays[i];
        }
        if(dateBucketType==='By Contract'){
          // checks exact cutoff only for contract days
          if(!bucketData&&days!==cutoff) return;
        }else{
          // otherwise cuts off days beyond cutoff
          if(!bucketData&&days>cutoff) return;
        }
        const dataIdx = days===0?0:i-1; // i-1 for previous matching key
        // console.log('iterate',i,dataIdx,days,token.code,d.position);
        if(!data[dataIdx]) return;
        const dv01 = new Decimal(d.dv01||0);
        const mtm = new Decimal(mtmMarketMap.get(d.instrumentId)||0);
        const position = new Decimal(d.quantity||0);
        const pv = mtm.add(position);
        let value = new Decimal(0);
        switch(dataKey){
          case 'dv01': value = dv01; break;
          case 'mtm': value = mtm; break;
          case 'pv': value = pv; break;
          case 'position': default: value = position; break;
        }
        data[dataIdx][t.code] = new Decimal(data[dataIdx][t.code]||0).add(value).toNumber();
        tokenValueMap[t.code] = (tokenValueMap[t.code]||new Decimal(0)).add(value);
      });
    });
    return {cutoffDays,data,tokenValueMap};
  },[daysToMaturities,dateBucketType,bucketData,dataKey,getDaysFromMarketToday,mtms,groupedPositions,tokens]);

  const formatValue = (token:Token,value:any)=>{
    switch(dataKey){
      case 'dv01': 
      return formatTokenDV01(token,value);
      case 'pv': case 'mtm': case 'position': default: 
        return formatTokenAmount(token,value,showsUSDValue);
    }
  }
  
  return (<>
    <TableContainer sx={sxTokenTableContainer}>
      <Table sx={sxTokenTable}>
        <TableHead>
          <TableRow>
            <ValueTableCell rowSpan={2} sx={sxCellBottomAlign} sticky>Total</ValueTableCell>
            {tokens?.map((token,i) => (<ValueTableCell key={i} align="right">{token.code}</ValueTableCell>))}
          </TableRow>
          <TableRow hover sx={sxTableRowFollowLast}>
            {tokens?.map((token,i) => {
              const value = tokenValueMap[token.code];
              return (<ValueTableCell mono key={i} align="right">
                <SidedQuantityValue value={value} token={token} showsTokenUSDValue={showsUSDValue} />
              </ValueTableCell>)
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {cutoffDays?.map((d,dataIdx)=>{
            let labelCell:JSX.Element|string = <></>;
            switch(dateBucketType){
              case 'Monthly': labelCell = `${dataIdx+1}M`; break;
              case 'Quarterly': labelCell = `${dataIdx>=4?'1Y':''}Q${dataIdx%4+1}`; break;
              case 'Semi-Annually': labelCell = `${dataIdx>=2?'1Y':''}${dataIdx%2+1}H`; break;
              case 'Annually': labelCell = `${dataIdx+1}Y`; break;
              case 'By Contract': 
                const rollingDate = <MarketRollingDateLabel daysToMaturity={d}/>;
                const dateLabel = dataIdx===0?'':<Box component="span" sx={sxDate}>&nbsp;{formatDaysFromMarketToday(d)}</Box>;
                labelCell = <>{rollingDate}{dateLabel}</>;
                break;
            }
            return (
              <TableRow hover key={dataIdx}>
                <ValueTableCell mono sticky>{labelCell}</ValueTableCell>
                {tokens?.map((token,i) => {
                  const value = data[dataIdx][token.code];
                  return (<ValueTableCell mono key={i} align="right">
                    <SidedQuantityValue value={value} token={token} showsTokenUSDValue={showsUSDValue} />
                  </ValueTableCell>)
                })}
              </TableRow>
            );
          })}
          {isLoadingAny&&<TableRow hover><ValueTableCell colSpan={10} sx={{textAlign:'center'}}><LoadingIcon inline={true}/></ValueTableCell></TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  </>);
}

export default UserTokensTable;