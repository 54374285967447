import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { Box, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useQueryClient } from "@tanstack/react-query";
import Decimal from 'decimal.js';
import moment from "moment";
import { Fragment, MouseEvent, ReactElement, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { MARKETS_PAGE_TOKENS } from "src/constants/app";
import useAppStore from "src/store";
import { sxPageHeadingRow, sxPageHeadingSmall } from "src/styles/Page";
import { sxVerticalSeparator } from "src/styles/Table";
import { date, today } from "src/utils/date";
import { paths } from "src/utils/paths";
import { AuthContextType, useAuth } from 'src/AuthProvider';
import { sxBox, sxBoxCompactInner, sxBoxWithHeadingWrapper, sxClickable, sxHalfOpacity } from '../styles/General';
import {
  sxChildRow, sxLabelBlock, sxTokenTable, sxTokenTableContainer,
  sxTokenTableSubHeader, sxTokenTableWithChartWrapper
} from '../styles/TokenTable';
import {
  DepositEvent, ETokenValuationProtocol,
  EUserAccountType,
  PNLHistoricalItem,
  Token,
  TokenWithBalance,
  UnderlyingAsset,
  UserAccount,
  WithdrawalRequest
} from '../types';
import apis from '../utils/apis';
import { EAccountType, ETransferDirectionType, ISelectedTransferAccountToken } from './CashPositionsPanel';
import FaucetButton from "./FaucetButton";
import InfoTooltipIcon from './InfoTooltipIcon';
import LoadingIcon, { LoadingCell } from './Loading';
import ReloadDateText from "./ReloadDateText";
import SidedQuantityValue from "./SidedQuantityValue";
import TokenIcon from './TokenIcon';
import ValueTableCell from './ValueTableCell';
import WalletAddress from './WalletAddress';

// TODO update to be grouped by token providers (infinity, basic, ...protocols)
enum ECashPositionTokenType { BASIC="Basic Tokens", COMPLEX="Yield-Bearing Tokens"};
type handleClickAccountFn = (selectedTransferAccountToken:ISelectedTransferAccountToken)=>void;

type TokenSumMap = {
  [key: string]: Decimal | undefined
}
interface CashPosition {
  tokenId: number;
  tokenType: ECashPositionTokenType;
  minter?: string;
  price: Decimal;
  chainBalance?: Decimal;
  currentAccountBalance: Decimal;
  tradingAccountsBalance: Decimal;
  mtm: Decimal;
  expandIcon?: unknown;
  // special field for WETH entry: ETH value for transfering in
  chainBalanceETH?: Decimal;
  currentAccountBalanceETH?: Decimal;
  tradingAccountsBalanceETH?: Decimal;
  // overview fields
  totalTransfers?: Decimal,
  realPNL?: Decimal,
  netPositions?: Decimal,
  interest?: Decimal,
  cash?: Decimal,
  freeCash?: Decimal,
  lockedCash?: Decimal,
  // pnl
  transfers?: Decimal,
  transfersInUsd?: Decimal,
  nav?: Decimal,
  navInUsd?: Decimal,
  pnl?: Decimal,
  pv?: Decimal,
}
interface HeadCell {
  disablePadding: boolean;
  id: keyof CashPosition;
  label: string;
  borderRight?: boolean;
}
const TOKENS_TO_SKIP = [
  'UNI-V3-POS',
  'CRV-TRI',
  'CRV-3POOL'
]

const headCells: readonly HeadCell[] = [
  {
    id: 'tokenId',
    disablePadding: false,
    label: 'Basic Tokens',
  },
  // {
  //   id: 'minter',
  //   disablePadding: false,
  //   label: 'Type',
  // },
  {
    id: 'chainBalance',
    disablePadding: false,
    label: 'Your Wallet',
  },
  {
    id: 'currentAccountBalance',
    disablePadding: false,
    label: 'Current Account',
  },
  {
    id: 'tradingAccountsBalance',
    disablePadding: false,
    label: 'Trading Account',
  },
  // {
  //   id: 'mtm',
  //   disablePadding: false,
  //   label: 'MTM',
  // },
  {
    id: 'expandIcon',
    disablePadding: false,
    label: '',
  },
];

const headCellsOverview: readonly HeadCell[] = [
  {
    id: 'tokenId',
    disablePadding: false,
    label: 'Tokens',
  },
  // net asset value - totalTransfers - interest
  {
    id: 'totalTransfers',
    disablePadding: false,
    label: 'Transfers',
  },
  {
    id: 'realPNL',
    disablePadding: false,
    label: 'Realized P&L',
  },
  {
    id: 'netPositions',
    disablePadding: false,
    label: 'Position Tokens',
  },
  {
    id: 'interest',
    disablePadding: false,
    label: 'Interest',
    borderRight: true,
  },
  {
    id: 'cash',
    disablePadding: false,
    label: 'Cash',
    borderRight: true,
  },
  {
    id: 'freeCash',
    disablePadding: false,
    label: 'Free Cash',
  },
  {
    id: 'lockedCash',
    disablePadding: false,
    label: 'Locked Cash',
  },
  {
    id: 'expandIcon',
    disablePadding: false,
    label: '',
  },
];

const headCellsPNL: readonly HeadCell[] = [
  {
    id: 'tokenId',
    disablePadding: false,
    label: 'Tokens',
  },
  {
    id: 'nav',
    disablePadding: false,
    label: 'PREV. CLOSE NAV',
  },
  {
    id: 'transfers',
    disablePadding: false,
    label: 'Transfers',
    borderRight: true,
  },
  {
    id: 'pnl',
    disablePadding: false,
    label: 'P&L',
  },
];

const sxFirstCell = {
  "th:first-of-type, td:first-of-type": {
    borderRightWidth: '1px',
    borderColor: 'border.dark',
    paddingLeft: '25px'
  }
}

const sxSubHeaderRow = {
  borderStyle: 'solid', borderColor: 'border.dark', borderTopWidth: '1px',
  "th": {
    py: '6px !important'
  }
}

const sxRowTopBorder = {
 borderStyle: 'solid', 
 borderColor: 'border.dark',
 borderTBottomWidth: '1px'
}

const getTokenHeads = ({ label, tokenType, auth }: { label?: string, tokenType?: ECashPositionTokenType, auth: AuthContextType}): ReactElement => {
  return (
    <TableHead sx={{fontSize:'0.75rem'}}>
      <TableRow hover sx={{...sxTokenTableSubHeader, ...sxFirstCell, ...sxSubHeaderRow, borderTopWidth: '1px', }}>
        {headCells.map((headCell,i) => (
          <ValueTableCell
            key={headCell.id}
            label={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sticky={i===0}
            width={i === 0 ? '' : '33%'}
            align={i===0?"left":"right"}
          >
            {i===0 ? (label || tokenType?.toUpperCase()) : headCell.label}
            {headCell.id=='chainBalance' && auth?.user && (<WalletAddress address={auth.user.address} iconOnly sx={{color:'primary.light', marginLeft: '5px'}}/>)}
          </ValueTableCell>
        ))}
      </TableRow>
    </TableHead>);
}

const getTotalValueForAllToken = (sums: {[key: string]: Decimal | undefined}) => {
  return Object.keys(sums).reduce((total, subTypeKey) => {
    return total.add(sums[subTypeKey] || 0)
  }, new Decimal(0))
}

const getAllTokenRows = (chainBalanceUsdValueSum: TokenSumMap, currentAccountBalanceUsdValueSum: TokenSumMap, tradingAccountsBalanceUsdValueSum: TokenSumMap) => {
  return [<TableRow key={`alltokens_summary`} hover sx={{...sxTokenTableSubHeader, ...sxFirstCell, ...sxSubHeaderRow}}>
    <ValueTableCell sticky={true}>Total</ValueTableCell>
    <ValueTableCell align="right" label="Your Account" mono>
      <SidedQuantityValue value={getTotalValueForAllToken(chainBalanceUsdValueSum)} prefix="$" dp={2}/>
    </ValueTableCell>
    <ValueTableCell align="right" label="Current" mono><SidedQuantityValue value={getTotalValueForAllToken(currentAccountBalanceUsdValueSum)} prefix="$" dp={2}/></ValueTableCell>
    <ValueTableCell align="right" label="Trading" mono><SidedQuantityValue value={getTotalValueForAllToken(tradingAccountsBalanceUsdValueSum)} prefix="$" dp={2}/></ValueTableCell>
    <ValueTableCell></ValueTableCell>
  </TableRow>,
  <TableRow key={`alltokens_head`} sx={{...sxTokenTableSubHeader, ...sxFirstCell, ...sxRowTopBorder}}>
    <ValueTableCell sticky={true} height={"48px"}></ValueTableCell>
    <ValueTableCell ></ValueTableCell>
  </TableRow>]
}

function CashPositionsTable({type='full',daysRange,handleClickAccount}:{type?:'full'|'overview'|'pnl',daysRange?:number,handleClickAccount?:handleClickAccountFn}){
  const auth = useAuth();
  const { data:tokens } = apis.token.useTokens();
  const { data:userAccounts, isFetching:isFetchingUserAccounts } = apis.user.useUserAccounts(auth);
  const { currentUserAccountId } = useAppStore();
  const tradeAccount = userAccounts&&apis.user.extractUserAccount(userAccounts,currentUserAccountId);
  // TODO handle long chain balance load state
  const { data:chainTokens } = apis.user.useChainTokens(auth,tokens);
  const { data:withdrawalRequests } = apis.user.useUserWithdrawRequests(auth);
  const { data:depositEvents } = apis.user.useUserDepositEvents(auth);
  const { data:mtms, isFetching:isFetchingMTMs } = apis.user.useUserMarkToMarketByToken(auth,{accountIds: userAccounts?.map(w=>w.accountId)});
  const { data:pnlHistorical, isFetching:isFetchingPNL } = apis.user.useUserPNLHistorical(auth,{ accountId: tradeAccount?.accountId });
  const { data:userAccountPerformanceLive, isFetching:isFetchingAccountPerformanceLive } = apis.user.useUserAccountPerformanceLive(auth,{ accountId: tradeAccount?.accountId });
  const { data:accountRisk } = apis.user.useUserAccountRisk(auth,{accountId:tradeAccount?.accountId});
  
  // reload text
  const queryClient = useQueryClient();
  const isFetching = isFetchingUserAccounts||isFetchingMTMs||isFetchingPNL||isFetchingAccountPerformanceLive;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lastUpdatedTime = useMemo(()=>moment(),[isFetching]);
  const handleClickReload = ()=>{
    queryClient.invalidateQueries(['user',{type:'account',action:'listAccounts'}]);
    queryClient.invalidateQueries(['user',{type:'mtm',action:'listByToken'}]);
  };

  // parse user data into cashPositionTypeList
  const cashPositionTypeList = useMemo(()=>{
    const cashPositionMap:Map<number,CashPosition> = new Map();
    const ethTokenId = tokens?.find(t=>t.code==='ETH')?.tokenId;
    const nethTokenId = tokens?.find(t=>t.code==='NETH')?.tokenId;
    const getCashPositionMapEntry = (tokenId:number)=>{
      return cashPositionMap.get(tokenId)||{
        tokenId: tokenId,
        minter: '',
        tokenType: 'Basic Tokens',
        price: new Decimal(0),
        chainBalance: undefined,
        currentAccountBalance: new Decimal(0),
        tradingAccountsBalance: new Decimal(0),
        mtm: new Decimal(0),
        chainBalanceETH: undefined,
        currentAccountBalanceETH: undefined,
        tradingAccountsBalanceETH: undefined,
      };
    };
    const updateCashPositionMap = (tokenId:number,update:Partial<CashPosition>)=>{
      const p = getCashPositionMapEntry(tokenId);
      cashPositionMap.set(tokenId,Object.assign(p,update));
    };
    // iterate default tokens first to ensure list order
    MARKETS_PAGE_TOKENS.forEach(tokenCode=>{
      const token = tokens?.find(t=>t.code===tokenCode);
      if(token) updateCashPositionMap(token.tokenId,{});
    })
    // iterate userAccounts to fill in positions
    userAccounts?.forEach(uw=>{
      const {type} = uw;
      uw.tokens.forEach(tw=>{
        const { tokenId, quantity } = tw;
        let { currentAccountBalance, tradingAccountsBalance } = getCashPositionMapEntry(tokenId);
        if(type===EUserAccountType.CURRENT){
          currentAccountBalance = currentAccountBalance.add(new Decimal(quantity));
          updateCashPositionMap(tokenId,{currentAccountBalance})
        }else if(type===EUserAccountType.TRADING){
          tradingAccountsBalance = tradingAccountsBalance.add(new Decimal(quantity));
          updateCashPositionMap(tokenId,{tradingAccountsBalance})
        }
      });
    });
    // iterate chainTokens for walletBalance
    chainTokens?.forEach(ct=>{
      if(ct.nativeToken) return; // skip nativeToken (ETH)
      const { tokenId, balance } = ct;
      let { chainBalance } = getCashPositionMapEntry(tokenId);
      chainBalance = (chainBalance||new Decimal(0)).add(new Decimal(balance||0));
      updateCashPositionMap(tokenId,{chainBalance});
    });
    // iterate tokens for USD values
    tokens?.forEach(token=>{
      if(token.nativeToken) return; // skip nativeToken (ETH)
      // price
      const { tokenId, tokenValuationProtocol, price } = token;
      const tokenType = tokenValuationProtocol===0?ECashPositionTokenType.BASIC:ECashPositionTokenType.COMPLEX;
      // performance
      let interest:Decimal|undefined;
      let nav:Decimal|undefined;
      let navInUsd:Decimal|undefined;
      let transfers:Decimal|undefined;
      let transfersInUsd:Decimal|undefined;
      let pnl:Decimal|undefined;
      let pv:Decimal|undefined;
      // check perf live for prev close nav & live pnl - should be the same as the last item from pnlHistorical
      if(userAccountPerformanceLive){
        const item = userAccountPerformanceLive.prevCloseNav.find(d=>d.tokenId===tokenId);
        if(item){
          nav = new Decimal(item.netAssetValue ?? 0);
          navInUsd = nav.mul(new Decimal(item.tokenPrice ?? 0));
          pnl = new Decimal(item.dailyPnl ?? 0);
        }
      }
      // check historical for pnl
      pnlHistorical&&pnlHistorical[tokenId]?.forEach((item:PNLHistoricalItem)=>{
        const itemDayDiff = today().diff(date(item.date),'d');
        if(daysRange!==undefined && daysRange<itemDayDiff) return;
        // transfers & pnl are sum of all records in time range
        transfers = (transfers||new Decimal(0)).add( new Decimal(item.netTransfers ?? 0) );
        transfersInUsd = (transfersInUsd||new Decimal(0)).add( transfers.mul(new Decimal(item.tokenPrice ?? 0)) );
        if(daysRange!==1){ // live - already set from perf live data
          pnl = (pnl||new Decimal(0)).add( new Decimal(item.dailyPnl||0) );
        }
      });
      // update
      updateCashPositionMap(tokenId,{tokenType,price:new Decimal(price ?? 0),pnl,pv,transfers,transfersInUsd,nav,navInUsd,interest});
    });
    mtms?.forEach(mtmObj=>{
      const { tokenId, mtm:_mtm } = mtmObj;
      const { mtm } = getCashPositionMapEntry(tokenId);
      updateCashPositionMap(tokenId,{mtm:mtm.add(_mtm||0)});
    });
    // special route for ETH & NETH to combine row
    if(ethTokenId!==undefined&&nethTokenId!==undefined){
      const pETH = getCashPositionMapEntry(ethTokenId);
      const pNETH = getCashPositionMapEntry(nethTokenId);
      // only concerns `chainBalance`, `currentAccountBalance`, and `tradingAccountsBalance`
      const chainBalanceETH = pETH.chainBalance;
      const chainBalance = pETH.chainBalance?.add(pNETH.chainBalance||0);
      const currentAccountBalanceETH = pETH.currentAccountBalance;
      const currentAccountBalance = pETH.currentAccountBalance.add(pNETH.currentAccountBalance);
      const tradingAccountsBalanceETH = pETH.tradingAccountsBalance;
      const tradingAccountsBalance = pETH.tradingAccountsBalance.add(pNETH.tradingAccountsBalance);
      // save ETH row
      updateCashPositionMap(ethTokenId,{
        chainBalanceETH, chainBalance,
        currentAccountBalanceETH, currentAccountBalance,
        tradingAccountsBalanceETH, tradingAccountsBalance,
      });
      // delete NETH row
      cashPositionMap.delete(nethTokenId);
    }
    // map cashpositions into type list - type->cashPosition[]
    const cashPositionTypeList:Map<ECashPositionTokenType,CashPosition[]> = new Map();
    for(let [tokenId,cashPosition] of cashPositionMap){
      const {tokenType} = cashPosition;
      const list = cashPositionTypeList.get(tokenType)||[];
      list.push(cashPosition);
      cashPositionTypeList.set(tokenType,list);
    }
    return cashPositionTypeList;
  },[chainTokens,mtms,tokens,pnlHistorical,userAccountPerformanceLive,daysRange,userAccounts]);

  // 
  const {
    portfolioNavTotal, portfolioCashTotal, portfolioFreeCashTotal, portfolioLockedCashTotal, portfolioNetPositionsTotal, portfolioInterestTotal, portfolioPVTotal, portfolioRealPNLTotal, portfolioTotalTransfersTotal, 
  } = useMemo(()=>{
    let portfolioTotalTransfersTotal = new Decimal(0);
    let portfolioInterestTotal = new Decimal(0);
    let portfolioRealPNLTotal = new Decimal(0);
    let portfolioNavTotal = new Decimal(0);
    let portfolioCashTotal = new Decimal(0);
    let portfolioFreeCashTotal = new Decimal(0);
    let portfolioLockedCashTotal = new Decimal(0);
    let portfolioNetPositionsTotal = new Decimal(0);
    let portfolioPVTotal = new Decimal(0);
    userAccounts?.forEach(account=>{
      if(account.type!==EUserAccountType.TRADING) return;
      if(account.tokenPositionMap){
        for(let [entryTokenId,tokenPosition] of account.tokenPositionMap){
          const positionTokenPriceDecimal = new Decimal(tokenPosition.price ?? 0);
          portfolioTotalTransfersTotal = portfolioTotalTransfersTotal.add(new Decimal(tokenPosition.totalTransfers||0).add(tokenPosition.netTransfers ?? 0).mul(positionTokenPriceDecimal));
          portfolioNavTotal = portfolioNavTotal.add(new Decimal(tokenPosition.netAssetValue||0).mul(positionTokenPriceDecimal));
          portfolioCashTotal = portfolioCashTotal.add(new Decimal(tokenPosition.cash||0).mul(positionTokenPriceDecimal));
          portfolioNetPositionsTotal = portfolioNetPositionsTotal.add(new Decimal(tokenPosition.netPositions||0).mul(positionTokenPriceDecimal));
          portfolioInterestTotal = portfolioInterestTotal.add(new Decimal(tokenPosition.interest||0).mul(positionTokenPriceDecimal));
          portfolioPVTotal = portfolioPVTotal.add(new Decimal(tokenPosition.pv||0).mul(positionTokenPriceDecimal));
        }
        // Position Tokens should be flipped for calculation & display purpose
        portfolioNetPositionsTotal = portfolioNetPositionsTotal.mul(-1);
        // real gain / loss  = cash - totalTransfers - interest - Position Tokens
        portfolioRealPNLTotal = portfolioCashTotal.sub(portfolioTotalTransfersTotal).sub(portfolioInterestTotal).sub(portfolioNetPositionsTotal);
      }
      account.tokens.forEach(tokenAccount=>{
        const token = tokens?.find(t=>t.tokenId===tokenAccount.tokenId);
        const tokenPriceDecimal = new Decimal(token?.price||0);
        portfolioLockedCashTotal = portfolioLockedCashTotal.add(new Decimal(tokenAccount.lockedQuantity||0).mul(tokenPriceDecimal));
      });
    });
    portfolioLockedCashTotal = portfolioLockedCashTotal.sub(new Decimal(accountRisk?.initialMargin||0));
    portfolioFreeCashTotal = portfolioCashTotal.minus(portfolioLockedCashTotal);
    return {
      portfolioNavTotal, portfolioCashTotal, portfolioFreeCashTotal, portfolioLockedCashTotal, portfolioNetPositionsTotal, portfolioInterestTotal, portfolioPVTotal, portfolioRealPNLTotal, portfolioTotalTransfersTotal, 
    };
  },[userAccounts,accountRisk,tokens]);

  const {pnlNavTotalUsdValue,pnlTransfersTotalUsdValue,pnlValueTotal} = useMemo(()=>{
    let pnlNavTotalUsdValue = new Decimal(0);
    let pnlTransfersTotalUsdValue = new Decimal(0);
    let pnlValueTotal = new Decimal(0);
    Array.from(cashPositionTypeList.keys()).forEach((tokenType) => {
      cashPositionTypeList.get(tokenType)?.forEach((cashPosition)=>{
        const { navInUsd, transfersInUsd, pnl } = cashPosition;
        pnlNavTotalUsdValue = pnlNavTotalUsdValue.add( navInUsd ?? 0 );
        pnlTransfersTotalUsdValue = pnlTransfersTotalUsdValue.add( transfersInUsd ?? 0 );
        pnlValueTotal = pnlValueTotal.add( pnl ?? 0 ); // PNL is in USD value already
      });
    });
    return {pnlNavTotalUsdValue,pnlTransfersTotalUsdValue,pnlValueTotal};
  },[cashPositionTypeList]);


  // iterate for token rows & sums
  let chainBalanceUsdValueSum: {[key: string ]: Decimal | undefined} = Array.from(cashPositionTypeList.keys()).reduce((r,v) => {
    return {
      ...r,
      [v]: undefined
    }
  }, {})
  let currentAccountBalanceUsdValueSum = Array.from(cashPositionTypeList.keys()).reduce((r,v) => {
    return {
      ...r,
      [v]: new Decimal(0)
    }
  }, {})
  let tradingAccountsBalanceUsdValueSum = Array.from(cashPositionTypeList.keys()).reduce((r,v) => {
    return {
      ...r,
      [v]: new Decimal(0)
    }
  }, {})
  let mtmUsdValueSum = new Decimal(0);
  const tokenBodies = tokens && userAccounts && Array.from(cashPositionTypeList.keys()).map((tokenType, index) => {
    if((type==='overview'||type==='pnl') && tokenType===ECashPositionTokenType.COMPLEX) return <Fragment key={index}></Fragment>;
    const tokenRows = cashPositionTypeList.get(tokenType)?.map((cashPosition,index2)=>{
      const { tokenId, price, chainBalance, currentAccountBalance, tradingAccountsBalance, mtm } = cashPosition;
      const token = tokens.filter(token=>token.tokenId==tokenId)[0];
      // custom rule: skip `UNI-V3-POS` row
      if(TOKENS_TO_SKIP.includes(token.code)) return null;
      // we don't need erc721 id in cash position table for now
      if (token && token.erc721TokenId) token.erc721TokenId = '';
      const complexTokenPrice: Decimal = token.underlyingAssets?.map(ua => {
        const underlying = tokens.filter(token=>token.tokenId==ua.underlyingTokenId)[0];
        return new Decimal(underlying.price || '0').mul(new Decimal(ua.underlyingTokenIndex || '0'));
      })
          .reduce((prev, next) => prev.add(next), new Decimal('0')) || new Decimal('0');
      const newPrice = tokenType == ECashPositionTokenType.COMPLEX ? complexTokenPrice:price;
      cashPosition.price = newPrice;
      // for atoken on chain usd value, it's different from infinity
      if(chainBalance) {
        const chainTokenPrice = token.tokenValuationProtocol==ETokenValuationProtocol.AAVE_V2? new Decimal(tokens?.find(t => t.tokenId==token.underlyingAssets?.at(0)?.underlyingTokenId)?.price || '0') : price;
        chainBalanceUsdValueSum[tokenType] = (chainBalanceUsdValueSum[tokenType]||new Decimal(0)).add(chainBalance.mul(chainTokenPrice));
      }
      currentAccountBalanceUsdValueSum[tokenType] = currentAccountBalanceUsdValueSum[tokenType].add(currentAccountBalance.mul(newPrice));
      tradingAccountsBalanceUsdValueSum[tokenType] = tradingAccountsBalanceUsdValueSum[tokenType].add(tradingAccountsBalance.mul(newPrice));
      mtmUsdValueSum = mtmUsdValueSum.add(mtm);
      if (token.tokenType==2) { // update erc721 token usd value
          userAccounts.map(uw => {
            const {type} = uw;
            const tw = uw.tokens.filter(token => token.tokenId==tokenId)[0];
            const totalValueUsd = tw?.erc721Tokens?.map(wt721 => {
              const tokenValueUsd = wt721?.underlyingAssets?.map(ua => {
                const ut = tokens?.filter(t => t.tokenId == ua.underlyingTokenId)[0];
                const underlyingPrice = new Decimal(ut?.price || '0');
                const underlyingTokenAmount = new Decimal(ua.underlyingTokenIndex || '0');
                return underlyingPrice.mul(underlyingTokenAmount);
              }).reduce((prev, next) => prev.add(next), new Decimal(0)) || new Decimal('0');
              return tokenValueUsd;
            }).reduce((previousValue, currentValue) => previousValue.add(currentValue), new Decimal('0')) || new Decimal('0');
            if(type==EUserAccountType.CURRENT){
              currentAccountBalanceUsdValueSum[tokenType] = currentAccountBalanceUsdValueSum[tokenType].add(totalValueUsd);
            }else{
              tradingAccountsBalanceUsdValueSum[tokenType] = tradingAccountsBalanceUsdValueSum[tokenType].add(totalValueUsd);
            }
        });
      }
      const tokenWithdrawalRequests = withdrawalRequests?.filter(request=>request.tokenId==tokenId)||[];
      const tokenDeposits = depositEvents?.reduce((deposits,event)=>([...deposits,...event.deposits.filter(d=>d.a==token.tokenAddress)||[]]),new Array())||new Array();
      return (
      <CashPositionRow 
        key={`${index}-${index2}`}
        position={cashPosition}
        tokens={tokens}
        chainTokens={chainTokens}
        withdrawalRequests={tokenWithdrawalRequests}
        tokenDeposits={tokenDeposits}
        handleClickAccount={handleClickAccount}
        accounts={userAccounts}
        isChild={false}
        type={type}
      />);
    })||[];
    let tokenHead:JSX.Element;
    switch(type){
      case 'overview':
        // tokenRows.push(<TableRow hover key={index} sx={{...sxFirstCell,height:'100%'}}><ValueTableCell width="100%" sticky={true}>&nbsp;</ValueTableCell><ValueTableCell colSpan={10}>&nbsp;</ValueTableCell></TableRow>);
        tokenHead = (
          <TableHead sx={{fontSize:'0.75rem'}}>
            <TableRow hover sx={{...sxTokenTableSubHeader, ...sxFirstCell}}>
              <ValueTableCell sticky={true}>Total{/* tokenType.toUpperCase() */}</ValueTableCell>
              <ValueTableCell align="right" label="Transfers" mono><SidedQuantityValue value={portfolioTotalTransfersTotal} prefix="$" dp={2}/></ValueTableCell>
              <ValueTableCell align="right" label="Realized P&L" mono><SidedQuantityValue value={portfolioRealPNLTotal} prefix="$" dp={2}/></ValueTableCell>
              <ValueTableCell align="right" label="Position Tokens" mono><SidedQuantityValue value={portfolioNetPositionsTotal} prefix="$" dp={2}/></ValueTableCell>
              <ValueTableCell align="right" label="Interest" mono borderRight><SidedQuantityValue value={portfolioInterestTotal} prefix="$" dp={2}/></ValueTableCell>
              <ValueTableCell align="right" label="Cash" mono borderRight><SidedQuantityValue value={portfolioCashTotal} prefix="$" dp={2}/></ValueTableCell>
              <ValueTableCell align="right" label="Free Cash" mono><SidedQuantityValue value={portfolioFreeCashTotal} prefix="$" dp={2}/></ValueTableCell>
              <ValueTableCell align="right" label="Locked Cash" mono><SidedQuantityValue value={portfolioLockedCashTotal} prefix="$" dp={2}/></ValueTableCell>
              <ValueTableCell align="right" label="" width="100%" mono>&nbsp;</ValueTableCell>
            </TableRow>
          </TableHead>
        );
        break;
      case 'pnl':
        tokenHead = (
          <TableHead sx={{fontSize:'0.75rem'}}>
            <TableRow hover sx={{...sxTokenTableSubHeader, ...sxFirstCell}}>
              <ValueTableCell sticky={true}>Total{/* tokenType.toUpperCase() */}</ValueTableCell>
              <ValueTableCell align="right" label="NAV" mono><SidedQuantityValue value={pnlNavTotalUsdValue} prefix="$" dp={2}/></ValueTableCell>
              <ValueTableCell align="right" label="Transfers" mono borderRight><SidedQuantityValue value={pnlTransfersTotalUsdValue} prefix="$" dp={2}/></ValueTableCell>
              <ValueTableCell align="right" label="P&L" mono><SidedQuantityValue value={pnlValueTotal} prefix="$" dp={2}/></ValueTableCell>
            </TableRow>
          </TableHead>
        );
        break;
      default:
        tokenHead = getTokenHeads({ tokenType, auth })
        tokenRows.unshift(<TableRow key={`headrow_summary_${index}`} hover sx={{...sxTokenTableSubHeader, ...sxFirstCell, ...sxSubHeaderRow}}>
          <ValueTableCell sticky={true}>Total{/* tokenType.toUpperCase() */}</ValueTableCell>
          <ValueTableCell align="right" label="Your Account" mono>
            <SidedQuantityValue value={chainBalanceUsdValueSum[tokenType]} prefix="$" dp={2}/>
          </ValueTableCell>
          <ValueTableCell align="right" label="Current" mono><SidedQuantityValue value={currentAccountBalanceUsdValueSum[tokenType]} prefix="$" dp={2}/></ValueTableCell>
          <ValueTableCell align="right" label="Trading" mono><SidedQuantityValue value={tradingAccountsBalanceUsdValueSum[tokenType]} prefix="$" dp={2}/></ValueTableCell>
          <ValueTableCell></ValueTableCell>
        </TableRow>)

        if(tokenType===ECashPositionTokenType.BASIC){
          tokenRows.push(<TableRow key={`headrow_basic_${index}`} sx={{...sxTokenTableSubHeader, ...sxFirstCell, ...sxRowTopBorder}}>
            <ValueTableCell sticky={true} height={"48px"}></ValueTableCell>
            <ValueTableCell></ValueTableCell>
          </TableRow>)
        }
        break;
    }
    return (<Fragment key={index} >{tokenHead}<TableBody>{tokenRows}</TableBody></Fragment>);
  });
  if(!(type==='overview' || type==='pnl')) {
    const allTokenHead = getTokenHeads({ label: 'All Tokens', auth })
    const allTokenRows = getAllTokenRows(chainBalanceUsdValueSum, currentAccountBalanceUsdValueSum, tradingAccountsBalanceUsdValueSum)

    tokenBodies?.unshift(<Fragment key={-1}>{allTokenHead}<TableBody>{allTokenRows}</TableBody></Fragment>)
  }
  let headCellsComponents;
  switch(type){
    case 'overview': headCellsComponents = headCellsOverview; break;
    case 'pnl': headCellsComponents = headCellsPNL; break;
    // default: headCellsComponents = headCells; break;
  }
  const chartContent = (<Box sx={{...sxTokenTableWithChartWrapper,height:'100%'}} data-tooltip-context={"positions"}>
    <TableContainer component={Box} sx={sxTokenTableContainer}>
      <Table sx={{...sxTokenTable, height:'100%'}} aria-label="Tokens" size="medium">
        {headCellsComponents ? <TableHead>
          <TableRow hover sx={sxFirstCell}>
            {headCellsComponents.map((headCell,i) => (
              <ValueTableCell
                key={headCell.id}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sticky={i===0}
                align={i===0?"left":"right"}
                sx={headCell.borderRight?sxVerticalSeparator:undefined}
              >
                {headCell.label}
                {headCell.id=='chainBalance'&&auth?.user&&(<WalletAddress address={auth.user.address} iconOnly sx={{color:'primary.light'}}/>)}
              </ValueTableCell>
            ))}
          </TableRow>
        </TableHead> : null}
        {tokenBodies||(
        <TableBody><TableRow hover>
          <TableCell colSpan={10}><Box sx={{display:'flex',justifyContent:'center',py:4}}><LoadingIcon/></Box></TableCell>
        </TableRow></TableBody>
        )}
      </Table>
    </TableContainer>
  </Box>);
  switch(type){
    case 'overview':
      return (
      <Box sx={sxBoxWithHeadingWrapper}>
        <Box sx={sxPageHeadingRow}>
          <Typography variant="h1" sx={sxPageHeadingSmall}>Cash Details</Typography>
          <ReloadDateText isFetching={isFetching} lastUpdatedTime={lastUpdatedTime} handleClick={handleClickReload}/>
        </Box>
        <Box sx={{...sxBox,contain:'content'}}><Box sx={sxBoxCompactInner}>{chartContent}</Box></Box>
      </Box>
      );
    case 'pnl': default:
      return chartContent;
  }
}

function CashPositionRow({
  position,tokens,chainTokens,accounts,withdrawalRequests,tokenDeposits,isChild,type='full',handleClickAccount
}:{
  position:CashPosition,tokens:Token[],chainTokens?:TokenWithBalance[],accounts:UserAccount[],withdrawalRequests:WithdrawalRequest[],tokenDeposits:DepositEvent[],isChild:boolean,type?:'full'|'overview'|'pnl',handleClickAccount?:handleClickAccountFn
}){
  interface IContextMenu {left:number;top:number;};
  const [contextMenu,setContextMenu] = useState<IContextMenu|null>(null);
  const [accountType,setAccountType] = useState<EAccountType|undefined>();
  const [expanded,setExpanded] = useState(false);
  const ExpandIcon = expanded?KeyboardArrowUp:KeyboardArrowDown;
  const { tokenId, minter, price, chainBalance, currentAccountBalance, tradingAccountsBalance, mtm, nav, navInUsd, pnl, pv, transfers, transfersInUsd } = position;
  const token = tokens.find(token => token.tokenId === tokenId);
  if (token && token.erc721TokenId) token.erc721TokenId = '';
  const underlyingAccountTokensMap: Map<number,UnderlyingAsset[]> = new Map();
  if (position.tokenType==ECashPositionTokenType.COMPLEX) {
    for (const account of accounts) {
      const {type, tokens} = account;
      const underlying = tokens.find(token => token.tokenId == tokenId)?.underlyingAssets;
      if (underlying)
        underlyingAccountTokensMap.set(type,underlying);
    }

    const ctUnderlying = token?.underlyingAssets;
    if (ctUnderlying)
      underlyingAccountTokensMap.set(EAccountType.CHAIN, ctUnderlying);
  }

  const chainAccountUnderlying = underlyingAccountTokensMap.get(EAccountType.CHAIN);
  const currentAccountUnderlying = underlyingAccountTokensMap.get(EAccountType.CURRENT);
  const tradingAccountUnderlying = underlyingAccountTokensMap.get(EAccountType.TRADING);

  const underlyingAccountTokens = chainAccountUnderlying || currentAccountUnderlying || tradingAccountUnderlying;

  const underlyingCashPositions: CashPosition[] = position.tokenType == ECashPositionTokenType.COMPLEX &&
      underlyingAccountTokens && underlyingAccountTokens.map(ua => {
        const underlyingToken = tokens.filter(token => token.tokenId == ua.underlyingTokenId)[0];
        const minter = '';
        const tokenId = ua.underlyingTokenId;
        const tokenType = underlyingToken?.tokenValuationProtocol==0?ECashPositionTokenType.BASIC:ECashPositionTokenType.COMPLEX;
        const price = new Decimal(underlyingToken?.price || '0');
        const chainTokenIndex = new Decimal(chainAccountUnderlying?.filter(cua => cua.underlyingTokenId==ua.underlyingTokenId)[0]?.underlyingTokenIndex || '0');
        const currentAccountTokenIndex = new Decimal(currentAccountUnderlying?.filter(cua => cua.underlyingTokenId==ua.underlyingTokenId)[0]?.underlyingTokenIndex || '0');
        const tradingAccountsTokenIndex = new Decimal(tradingAccountUnderlying?.filter(cua => cua.underlyingTokenId==ua.underlyingTokenId)[0]?.underlyingTokenIndex || '0');

        // no need to multiply token index if chain token is atoken
        const chainBalance = !position.chainBalance?undefined:(token?.tokenValuationProtocol==ETokenValuationProtocol.AAVE_V2?position.chainBalance:position.chainBalance.mul(chainTokenIndex));
        const currentAccountBalance = position.currentAccountBalance.mul(currentAccountTokenIndex);
        const tradingAccountsBalance = position.tradingAccountsBalance.mul(tradingAccountsTokenIndex);
        const mtm = new Decimal(0);
        return {
          tokenId,
          minter,
          tokenType,
          price,
          chainBalance,
          currentAccountBalance,
          tradingAccountsBalance,
          mtm,
        };
      }) || [];

  // USD Values need to be calculated before hand since historical token price varies
  const {
    portfolioTotalTransfers, portfolioCash, portfolioFreeCash, portfolioLockedCash, portfolioNetPositions, portfolioInterest, portfolioPV, portfolioRealPNL, 
    portfolioTotalTransfersLocalQuantity, portfolioCashLocalQuantity, portfolioFreeCashLocalQuantity, portfolioLockedCashLocalQuantity, portfolioNetPositionsLocalQuantity, portfolioInterestLocalQuantity, portfolioPVLocalQuantity, portfolioRealPNLLocalQuantity, 
    pnlNav, pnlTransfers, pnlValue, 
    pnlNavLocalQuantity, pnlTransfersLocalQuantity, 
  } = useMemo(()=>{
    let portfolioTotalTransfers = new Decimal(0);
    let portfolioRealPNL = new Decimal(0);
    let portfolioCash = new Decimal(0);
    let portfolioFreeCash = new Decimal(0);
    let portfolioLockedCash = new Decimal(0);
    let portfolioNetPositions = new Decimal(0);
    let portfolioInterest = new Decimal(0);
    let portfolioPV = new Decimal(0);
    let portfolioTotalTransfersLocalQuantity = new Decimal(0);
    let portfolioRealPNLLocalQuantity = new Decimal(0);
    let portfolioCashLocalQuantity = new Decimal(0);
    let portfolioFreeCashLocalQuantity = new Decimal(0);
    let portfolioLockedCashLocalQuantity = new Decimal(0);
    let portfolioNetPositionsLocalQuantity = new Decimal(0);
    let portfolioInterestLocalQuantity = new Decimal(0);
    let portfolioPVLocalQuantity = new Decimal(0);
    accounts.forEach(account=>{
      if(account.type!==EUserAccountType.TRADING) return;
      if(account.tokenPositionMap){
        let positionTokenPriceDecimal
        for(let [entryTokenId,tokenPosition] of account.tokenPositionMap){
          if(tokenId===entryTokenId){
            if (!positionTokenPriceDecimal) positionTokenPriceDecimal = new Decimal(tokenPosition.price ?? 0);
            portfolioTotalTransfersLocalQuantity = portfolioTotalTransfersLocalQuantity.add(new Decimal(tokenPosition.totalTransfers||0)).add(new Decimal(tokenPosition.netTransfers||0));
            portfolioCashLocalQuantity = portfolioCashLocalQuantity.add(new Decimal(tokenPosition.cash||0));
            portfolioFreeCashLocalQuantity = portfolioFreeCashLocalQuantity.add(new Decimal(tokenPosition.maxWithdraw||0));
            portfolioNetPositionsLocalQuantity = portfolioNetPositionsLocalQuantity.add(new Decimal(tokenPosition.netPositions||0));
            portfolioInterestLocalQuantity = portfolioInterestLocalQuantity.add(new Decimal(tokenPosition.interest||0));
            portfolioPVLocalQuantity = portfolioPVLocalQuantity.add(new Decimal(tokenPosition.pv||0));
            portfolioTotalTransfers = portfolioTotalTransfers.add(portfolioTotalTransfersLocalQuantity.mul(positionTokenPriceDecimal));
            portfolioCash = portfolioCash.add(portfolioCashLocalQuantity.mul(positionTokenPriceDecimal));
            portfolioFreeCash = portfolioFreeCash.add(portfolioFreeCashLocalQuantity.mul(positionTokenPriceDecimal));
            portfolioNetPositions = portfolioNetPositions.add(portfolioNetPositionsLocalQuantity.mul(positionTokenPriceDecimal));
            portfolioInterest = portfolioInterest.add(portfolioInterestLocalQuantity.mul(positionTokenPriceDecimal));
            portfolioPV = portfolioPV.add(portfolioPVLocalQuantity.mul(positionTokenPriceDecimal));
            const tokenAccount = account.tokens.find(t=>t.tokenId===tokenId);
            if(tokenAccount){
              portfolioLockedCashLocalQuantity = portfolioLockedCashLocalQuantity.add(new Decimal(tokenAccount.lockedQuantity||0));
              portfolioLockedCash = portfolioLockedCash.add(portfolioLockedCashLocalQuantity.mul(positionTokenPriceDecimal));
            }
          }
        }
        // Position Tokens should be flipped for calculation & display purpose
        portfolioNetPositionsLocalQuantity = portfolioNetPositionsLocalQuantity.mul(-1);
        portfolioNetPositions = portfolioNetPositions.mul(-1);
        // real gain / loss  = cash - totalTransfers - interest - Position Tokens
        portfolioRealPNLLocalQuantity = portfolioCashLocalQuantity.sub(portfolioTotalTransfersLocalQuantity).sub(portfolioInterestLocalQuantity).sub(portfolioNetPositionsLocalQuantity);
        portfolioRealPNL = portfolioRealPNLLocalQuantity.mul(positionTokenPriceDecimal ?? 1);
      }
    });
    const pnlNavLocalQuantity = nav;
    const pnlTransfersLocalQuantity = transfers;
    const pnlNav = navInUsd;
    const pnlTransfers = transfersInUsd;
    // PNL already in USD value
    const pnlValue = new Decimal(pnl||0);
    // local quantity doesnt make sense for PNL historical since token price is different every day
    // const pnlValueLocalQuantity = pnlValue.div(tokenPriceDecimal);
    return {
      portfolioTotalTransfers, portfolioCash, portfolioFreeCash, portfolioLockedCash, portfolioNetPositions, portfolioInterest, portfolioPV, portfolioRealPNL, 
      portfolioTotalTransfersLocalQuantity, portfolioCashLocalQuantity, portfolioFreeCashLocalQuantity, portfolioLockedCashLocalQuantity, portfolioNetPositionsLocalQuantity, portfolioInterestLocalQuantity, portfolioPVLocalQuantity, portfolioRealPNLLocalQuantity, 
      pnlNav, pnlTransfers, pnlValue, 
      pnlNavLocalQuantity, pnlTransfersLocalQuantity, 
    };
  },[accounts,tokenId,nav,navInUsd,pnl,transfers,transfersInUsd]);
  
  // for atoken on chain usd value, it's different from infinity
  const chainTokenPrice = token?.tokenValuationProtocol==ETokenValuationProtocol.AAVE_V2? new Decimal(tokens?.find(t => t.tokenId==token?.underlyingAssets?.at(0)?.underlyingTokenId)?.price || '0') : price;
  const chainBalanceUsdValue = chainBalance&&chainBalance.mul(chainTokenPrice);
  let currentAccountBalanceUsdValue = currentAccountBalance.mul(price);
  let tradingAccountsBalanceUsdValue = tradingAccountsBalance.mul(price);
  let mtmLocalValue = mtm.div(price);
  let pvUsdValue = pv?.mul(price);
  if (token?.tokenType==2) { // update erc721 token usd value
    accounts.map(uw => {
      const {type} = uw;
      const tw = uw.tokens.filter(token => token.tokenId==tokenId)[0];
      const totalValueUsd = tw?.erc721Tokens?.map(wt721 => {
        const tokenValueUsd = wt721?.underlyingAssets?.map(ua => {
          const ut = chainTokens?.filter(t => t.tokenId == ua.underlyingTokenId)[0];
          const underlyingPrice = new Decimal(ut?.price || '0');
          const underlyingTokenAmount = new Decimal(ua.underlyingTokenIndex || '0');
          return underlyingPrice.mul(underlyingTokenAmount);
        }).reduce((prev, next) => prev.add(next), new Decimal(0)) || new Decimal('0');
        return tokenValueUsd;
      }).reduce((previousValue, currentValue) => previousValue.add(currentValue), new Decimal('0')) || new Decimal('0');
      if(type==EUserAccountType.CURRENT){
        currentAccountBalanceUsdValue = totalValueUsd;
      }else{
        tradingAccountsBalanceUsdValue = totalValueUsd;
      }
    });
  }
  const withdrawalTooltipText = `${withdrawalRequests.length} Withdrawal Requests In Progress`;
  const depositTooltipText = `${tokenDeposits.length} Deposits In Progress`;
  let tooltipText = '';
  if(withdrawalRequests.length>0) tooltipText+= withdrawalTooltipText;
  if(withdrawalRequests.length>0&&tokenDeposits.length>0) tooltipText+= "\n";
  if(tokenDeposits.length>0) tooltipText+= depositTooltipText;
  function handleClickCell(event:MouseEvent,type:EAccountType){
    if(position.tokenType!=ECashPositionTokenType.BASIC||isChild) return;
    setAccountType(type);
    setContextMenu({left:event.clientX,top:event.clientY});
  }
  function handleClickExpandRow(){
    if(position.tokenType==ECashPositionTokenType.COMPLEX) setExpanded(!expanded);
  }
  function handleClickMenuItem(type:ETransferDirectionType){
    let id = position.tokenId;
    accountType!=undefined&&handleClickAccount&&handleClickAccount({tokenId:position.tokenId,accountType:accountType,transferType:type});
    setContextMenu(null);
  }
  function handleCloseContextMenu(){
    setContextMenu(null);
  }
  /* account view cells */
  let tokenCellWidth;
  switch(type){
    case 'full': tokenCellWidth = "13rem"; break;
    case 'overview': tokenCellWidth = "8rem"; break;
    default: tokenCellWidth = undefined;
  }
  const cellToken = <ValueTableCell key="cellToken" width={tokenCellWidth} sticky={true} data-testid="cas-position-token-cell">
      <TokenIcon token={token} withCode={true} size={14} variant="bold"/>
      {(withdrawalRequests.length>0||tokenDeposits.length>0)&&(
        <Link to={paths.portfolioPage("cash-transfers")}><InfoTooltipIcon title={tooltipText}/></Link>
      )}
      {type==='full'&&<FaucetButton token={token}/>}
    </ValueTableCell>;
  const cellYourAccount = <ValueTableCell key="cellYourAccount" align="right" width="33%" label="Your Account" mono
      sx={(!isChild&&position.tokenType==ECashPositionTokenType.BASIC&&handleClickAccount)&&sxClickable}
      onClick={(event:MouseEvent)=>handleClickAccount&&handleClickCell(event,EAccountType.CHAIN)}
    >
      {!chainBalance&&<LoadingCell />}
      {chainBalance&&(<>
        <SidedQuantityValue token={token} value={chainBalance}/>
        {token?.tokenType!==2&&<SidedQuantityValue token={token} value={chainBalanceUsdValue} sx={sxLabelBlock} prefix="$" dp={2}/>}
      </>)}
    </ValueTableCell>;
  const cellCurrent = <ValueTableCell key="cellCurrent" align="right" width="33%" label="Current" mono
      sx={(!isChild&&position.tokenType==ECashPositionTokenType.BASIC&&handleClickAccount)&&sxClickable}
      onClick={(event:MouseEvent)=>handleClickAccount&&handleClickCell(event,EAccountType.CURRENT)}
    >
      <SidedQuantityValue token={token} value={currentAccountBalance}/>
      <SidedQuantityValue sx={sxLabelBlock} value={currentAccountBalanceUsdValue} prefix="$" dp={2}/>
    </ValueTableCell>; /*FIXME: have to specify atoken market value in terms of quantity and also usd value*/
  const cellTrading = <ValueTableCell key="cellTrading" align="right" width="33%" label="Trading" mono
      sx={(!isChild&&position.tokenType==ECashPositionTokenType.BASIC&&handleClickAccount)&&sxClickable}
      onClick={(event:MouseEvent)=>handleClickAccount&&handleClickCell(event,EAccountType.TRADING)}
    >
      <SidedQuantityValue token={token} value={tradingAccountsBalance}/>
      <SidedQuantityValue sx={sxLabelBlock} value={tradingAccountsBalanceUsdValue} prefix="$" dp={2}/>
    </ValueTableCell>;
  const cellMTM = <ValueTableCell key="cellMTM" align="right" label="MTM" mono>
    <SidedQuantityValue token={token} value={mtmLocalValue}/>
    <SidedQuantityValue sx={sxLabelBlock} value={mtm} prefix="$" dp={2}/>
  </ValueTableCell>;
  const cellPV = <ValueTableCell key="cellPV" align="right" label="PV" mono>
    <SidedQuantityValue token={token} value={pv}/>
    <SidedQuantityValue sx={sxLabelBlock} value={pvUsdValue} prefix="$" dp={2}/>
  </ValueTableCell>;
  const cellAction = <ValueTableCell key="cellAction" label="" mono>
      {chainBalance&&(<>
        {(!(chainBalance.eq(0)&&currentAccountBalance.eq(0)&&tradingAccountsBalance.eq(0))&&token?.tokenType!=2)&&position.tokenType==ECashPositionTokenType.COMPLEX&&<ExpandIcon sx={{cursor:'pointer'}} onClick={handleClickExpandRow}/>}
      </>)}
    </ValueTableCell>;
  /* overview cells */
  const cellTotalTransfers = <ValueTableCell key="cellTotalTransfers" align="right" label="Transfers" mono>
    <SidedQuantityValue token={token} value={portfolioTotalTransfersLocalQuantity}/>
    <SidedQuantityValue token={token} value={portfolioTotalTransfers} prefix="$" dp={2} sx={sxHalfOpacity}/>
  </ValueTableCell>;
  const cellRealPNL = <ValueTableCell key="cellRealPNL" align="right" label="Realized P&L" mono>
    <SidedQuantityValue token={token} value={portfolioRealPNLLocalQuantity}/>
    <SidedQuantityValue token={token} value={portfolioRealPNL} prefix="$" dp={2} sx={sxHalfOpacity}/>
  </ValueTableCell>;
  const cellNetPositions = <ValueTableCell key="cellNetPositions" align="right" label="Position Tokens" mono>
    <SidedQuantityValue token={token} value={portfolioNetPositionsLocalQuantity}/>
    <SidedQuantityValue token={token} value={portfolioNetPositions} prefix="$" dp={2} sx={sxHalfOpacity}/>
  </ValueTableCell>;
  const cellInterest = <ValueTableCell key="cellInterest" align="right" label="Interest" mono borderRight>
    <SidedQuantityValue token={token} value={portfolioInterestLocalQuantity}/>
    <SidedQuantityValue token={token} value={portfolioInterest} prefix="$" dp={2} sx={sxHalfOpacity}/>
  </ValueTableCell>;
  const cellCash = <ValueTableCell key="cellCash" align="right" label="Cash" mono borderRight>
    <SidedQuantityValue token={token} value={portfolioCashLocalQuantity}/>
    <SidedQuantityValue token={token} value={portfolioCash} prefix="$" dp={2} sx={sxHalfOpacity}/>
  </ValueTableCell>;
  const cellFreeCash = <ValueTableCell key="cellFreeCash" align="right" label="Free Cash" mono>
    <SidedQuantityValue token={token} value={portfolioFreeCashLocalQuantity}/>
    <SidedQuantityValue token={token} value={portfolioFreeCash} prefix="$" dp={2} sx={sxHalfOpacity}/>
  </ValueTableCell>;
  const cellLockedCash = <ValueTableCell key="cellLockedCash" align="right" label="Locked Cash" mono>
    <SidedQuantityValue token={token} value={portfolioLockedCashLocalQuantity}/>
    <SidedQuantityValue token={token} value={portfolioLockedCash} prefix="$" dp={2} sx={sxHalfOpacity}/>
  </ValueTableCell>;
  const cellPortfolioPV = <ValueTableCell key="cellPortfolioPV" align="right" label="P" mono>
    <SidedQuantityValue token={token} value={portfolioPVLocalQuantity}/>
    <SidedQuantityValue token={token} value={portfolioPV} prefix="$" dp={2} sx={sxHalfOpacity}/>
  </ValueTableCell>;
  /* pnl cells */
  const cellPnlNav = <ValueTableCell key="cellPnllNav" align="right" label="Nav" mono>
    <SidedQuantityValue token={token} value={pnlNavLocalQuantity}/>
    <SidedQuantityValue token={token} value={pnlNav} prefix="$" dp={2} sx={sxHalfOpacity}/>
  </ValueTableCell>;
  const cellTransfers = <ValueTableCell key="cellTransfers" align="right" label="Transfers" mono borderRight>
    <SidedQuantityValue token={token} value={pnlTransfersLocalQuantity}/>
    <SidedQuantityValue token={token} value={pnlTransfers} prefix="$" dp={2} sx={sxHalfOpacity}/>
  </ValueTableCell>;
  const cellPNL = <ValueTableCell key="cellPNL" align="right" label="P&L" mono>
    {/* local quantity doesnt make sense for PNL historical since token price is different every day */}
    {/* <SidedQuantityValue token={token} value={pnlValueLocalQuantity}/> */}
    <SidedQuantityValue token={token} value={pnlValue} prefix="$" dp={2}/>
  </ValueTableCell>;
  const cellEmpty = <ValueTableCell key="cellEmpty" align="right" label="" mono width="100%">&nbsp;</ValueTableCell>;
  /* cells for different views */
  const cells = new Array<JSX.Element>();
  switch(type){
    case 'overview':
      cells.push.apply(cells,[ cellToken, cellTotalTransfers, cellRealPNL, cellNetPositions, cellInterest, cellCash, cellFreeCash, cellLockedCash, cellEmpty ]);
      break;
    case 'pnl':
      cells.push.apply(cells,[ cellToken, cellPnlNav, cellTransfers, cellPNL ]);
      break;
    default:
      cells.push.apply(cells,[ cellToken, cellYourAccount, cellCurrent, cellTrading, /* cellMTM, */ cellAction ])
      break;
  }
  return (
    <>
      <TableRow
        role="checkbox"
        tabIndex={-1}
        hover={true}
        sx={{
          ...sxFirstCell,
          ...(isChild?sxChildRow:{})
        }}
      >
        {cells}
      </TableRow>
      <Menu open={contextMenu!==null} onClick={handleCloseContextMenu} anchorReference='anchorPosition' anchorPosition={contextMenu||undefined}>
        <MenuItem onClick={()=>handleClickMenuItem(ETransferDirectionType.FROM)}>Transfer From</MenuItem>
        <MenuItem onClick={()=>handleClickMenuItem(ETransferDirectionType.TO)}>Transfer To</MenuItem>
      </Menu>
      {expanded&&underlyingCashPositions?.map((position,index)=>(<CashPositionRow key={index} position={position} tokens={tokens} chainTokens={chainTokens} accounts={accounts} tokenDeposits={[]} withdrawalRequests={[]} isChild={true}/>))}
    </>
  );
}

export default CashPositionsTable;