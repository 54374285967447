/* Simple external protocols keys/values helper */

/* value should be unique, key is used as label */
const MarketExternalProtocols = { 
  'Aave v2': 5,
  'Deribit': 6,
  'OKX': 7,
};
export type TMarketExternalProtocol = keyof typeof MarketExternalProtocols;
const MarketExternalProtocolArray = Object.keys(MarketExternalProtocols) as TMarketExternalProtocol[];
export const ExternalProtocolsHelper = {
  toMap:()=>MarketExternalProtocols, 
  toArray:()=>MarketExternalProtocolArray,
  getId: (protocol?:TMarketExternalProtocol)=>{
    return protocol && MarketExternalProtocols[protocol];
  }
}

