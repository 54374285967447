import {
  Box, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import { scaleSqrt } from 'd3-scale';
import Decimal from 'decimal.js';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Area, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Scatter, Tooltip, XAxis, YAxis } from 'recharts';
import { breakCamelCaseWithSpace, hslStrToHex, usePrevious } from 'src/utils/common';
import { getDaysToMaturitiesFromMarkets, useCallbackRollingDateLabel } from 'src/utils/date';
import { format, getEqualDistanceTicks } from 'src/utils/numbers';
import { capitalize } from 'src/utils/string';
import theme from 'src/utils/theme';
import { 
  graphAreaColors,
  graphLineColors,
  sxChartContainer,
  sxChartHeader,
  sxChartTooltip,
  sxChartTooltipLegendBoxTitle,
  sxChartTooltipLegendDashedLine,
  sxChartTooltipLegendLine,
  sxChartTooltipTable,
  sxChartWatermarkCenter
} from '../styles/Chart';
import { styleBoxBlock, styleBoxPanel } from '../styles/General';
import { MarketImpliedForwardRateRate, MarketYieldCurveRate, Token } from '../types';
import apis from '../utils/apis';
import DefaultTokensSelector from './DefaultTokensSelector';
import LoadingIcon from './Loading';
import ValueTableCell from './ValueTableCell';
import { isEqual } from 'lodash';

const NAME_TO_RANGE_DAY_MAP = {
  'Float': 0,
  '1D': 1.5,
  '2D': 3,
  '1W': 5,
  '2W': 8,
  '3W': 11,
  '1M': 14,
  '2M': 18,
  '3M': 22,
  '1Q': 22,
  '2Q': 28,
  '3Q': 34,
  '4Q': 40
}


function MarketsYieldCurvesChart({
  tokens,initTokenCodes,toggleFilteredTokenCode,
  inline=false, withImpliedForwardRates,
}:{
  tokens?:Token[],initTokenCodes?:string[],toggleFilteredTokenCode?:(tokenCode:string)=>any,
  inline?:boolean, withImpliedForwardRates?:boolean,
}){

  const lastInitTokenCodes = usePrevious(initTokenCodes)
  const [chartData, setChartData] = useState<any>([]);
  const [rerenderCounter, setRerenderCounter] = useState<number>(0)
  const { isLoading:isLoadingRates, data:rates } = apis.fixedrate.useMarketsYieldCurve(tokens?.map(t=>t.tokenId));
  const { isLoading:isLoadingImpliedForwardRates, data:impliedForwardRates } = apis.fixedrate.useMarketsImpliedForwardRate(tokens?.map(t=>t.tokenId));
  
  // DEV get fixed rate markets to filter YC data by standard dates
  const {isLoading:isLoadingMarkets,data:fixedRateMarkets} = apis.fixedrate.useFixedRateMarkets({tokenId:tokens?.at(0)?.tokenId});

  const isLoadingAny = isLoadingRates||isLoadingImpliedForwardRates||isLoadingMarkets;

  const getRollingDateLabel = useCallbackRollingDateLabel();
  
  const [filteredTokenCodes,setFilteredGraphTokenCodes] = useState(initTokenCodes||[]);

  // highlight implied line segment
  const [impliedLinePayloadIndex,setImpliedLinePayloadIndex] = useState(-1);

  useEffect(() => {
    if (withImpliedForwardRates) {
      setRerenderCounter((counter => counter + 1))
    }
  }, [filteredTokenCodes, withImpliedForwardRates])
  
  const {data,xmax,yAxisTicks,daysToMaturities} = useMemo(()=>{
    const daysToMaturities: number[] = [0,...getDaysToMaturitiesFromMarkets(fixedRateMarkets)];
    // generate rechart data
    let ymin = new Decimal(0);
    let ymax = new Decimal(0);
    let xmin = Number.MAX_SAFE_INTEGER;
    let xmax = 0;
    // generate rechart data
    const rateDateMap = {};
    const rateDateMarketIdMap = new Map<number,number>(); // <marketId,date>
    if(daysToMaturities&&daysToMaturities.length>0){
      for(let tokenIdKey in rates){
        // eslint-disable-next-line no-loop-func
        rates[tokenIdKey].forEach((p:MarketYieldCurveRate)=>{
          if(!p.enable) return;
          const token = tokens?.filter(t=>t.tokenId===p.tokenId)[0];
          if(!token) return;
          const t = p.maturityDate||0;
          const dtm = p.daysToMaturity
          const name = p.daysToMaturity===0 ? 'Float' : getRollingDateLabel(p.daysToMaturity)
          const d = NAME_TO_RANGE_DAY_MAP[name];
          if(dtm < 0) return;
          if(t && dtm===0) return; // daysToMaturity===0 shouldnt be fixed rate market
          if(!daysToMaturities.includes(dtm)) return;
          rateDateMarketIdMap.set(p.marketId,t);
          const px = new Decimal(new Decimal(p.interpolatedPx).mul(100).toFixed(2));
          if(ymin.gt(px)) ymin = px;
          if(ymax.lt(px)) ymax = px;
          if(xmin>d) xmin = d;
          if(xmax<d) xmax = d;
          rateDateMap[t] = Object.assign({},rateDateMap[t],{t,d,name,[`${token.code}-yieldCurve`]:px.toNumber(), dtm});
        });
      }
      // map implied forward rates to data
      for(let tokenIdKey in impliedForwardRates){
        // eslint-disable-next-line no-loop-func
        impliedForwardRates[tokenIdKey].forEach((p:MarketImpliedForwardRateRate)=>{
          const token = tokens?.filter(t=>t.tokenId===Number(tokenIdKey))[0];
          if(!token) return;
          const tFrom = rateDateMarketIdMap.get(p.fromMarketId);
          const tTo = rateDateMarketIdMap.get(p.toMarketId);
          if(tFrom===undefined||tTo===undefined) return;
          const px = new Decimal(new Decimal(p.impliedForwardRate).mul(100).toFixed(2));
          if(ymin.gt(px)) ymin = px;
          if(ymax.lt(px)) ymax = px;
          if(rateDateMap[tFrom]){
            rateDateMap[tFrom] = Object.assign({},rateDateMap[tFrom],{t: tFrom,[`${token.code}-impliedAverageFloatingRate`]:px.toNumber()});
          }
          if(rateDateMap[tTo]){
            rateDateMap[tTo] = Object.assign({},rateDateMap[tTo],{t: tTo,[`${token.code}-impliedAverageFloatingRate`]:px.toNumber()});
          }
        });
      }
    }
    const data:any[] = [];
    for(let date of Object.keys(rateDateMap).sort()){
      data.push(rateDateMap[date]);
    }
    const yAxisTicks = getEqualDistanceTicks(ymin.toNumber(),ymax.plus(1).toNumber()); // Add an extra tick to make sure the label of the Implied Average Floating Rate is visible
    return {data,xmax,yAxisTicks,daysToMaturities};
  },[fixedRateMarkets,getRollingDateLabel,rates,impliedForwardRates,tokens]);

  useEffect(() => {
    if (!isEqual(data, chartData)) {
      setTimeout(() => {
        setChartData(data)
      }, 100)
    }
  }, [data, chartData, setChartData])

  useEffect(() => {
    if (initTokenCodes?.toString() !== lastInitTokenCodes?.toString()) {
      setFilteredGraphTokenCodes(initTokenCodes || [])
    }
  }, [initTokenCodes, lastInitTokenCodes, setFilteredGraphTokenCodes])

  function ChartLegend(props:any){
    const { /* chartHeight, */ chartWidth, height, /* width, */ iconSize, payload } = props;
    if(payload.length<=1) return null;
    return <div className="recharts-legend-wrapper" style={{
      position: 'absolute', width: chartWidth, height: height, right: 0, top: 0, transform: `translateY(calc(-100% - ${iconSize/2}px))`, 
    }}>
      <ul className="recharts-default-legend" style={{
        padding: '0px', margin: '0px', textAlign: 'right',
      }}>
        {payload.map((p:any,i:number)=>{
          const [code,type] = p.dataKey.split('-');
          const label = (withImpliedForwardRates&&type)?`${capitalize(breakCamelCaseWithSpace(type))}`:code;
          return <li key={i} className="recharts-legend-item legend-item-0" style={{
            display: 'inline-block', marginRight: '10px', color: p.color,
          }}>
            <Box sx={type==='impliedAverageFloatingRate'?sxChartTooltipLegendDashedLine:sxChartTooltipLegendLine} style={{borderColor:p.color}}></Box> 
            {label}
          </li>;
        })}
      </ul>
    </div>;
  }
  function ChartTooltip(props:any){
    // console.log('ChartTooltip',props);
    const {active,payload,label,onInactive} = props;
    useEffect(()=>{
      if(!active) onInactive&&onInactive();
    },[active,onInactive]);
    if (active && payload && payload.length) {
      const dayLabel = payload[0].payload.name;
      return (
        <Box sx={sxChartTooltip}>
          <Box sx={sxChartTooltipLegendBoxTitle}>{`${dayLabel}`}</Box>
          <Table sx={sxChartTooltipTable}>
            <TableBody>
            {payload.map((p:any,i:number)=>{
              const [code,type] = p.name.split('-');
              let label = (withImpliedForwardRates&&type) ? `${capitalize(breakCamelCaseWithSpace(type))}` : code;
              // let labelSuffix = '';
              if(withImpliedForwardRates){
                if(type==='impliedAverageFloatingRate'){
                  const nextDayIndex = daysToMaturities.indexOf(p.payload.dtm)+1;
                  if(nextDayIndex===daysToMaturities.length){
                    const prevDay = daysToMaturities.at(nextDayIndex-2);
                    const prevDayLabel = getRollingDateLabel(prevDay);
                    // labelSuffix = ` ${prevDayLabel}-${dayLabel}`;
                    label = ` ${prevDayLabel}-${dayLabel}`;
                  }else{
                    const nextDay = daysToMaturities.at(nextDayIndex);
                    if(nextDayIndex>0){
                      const nextDayLabel = getRollingDateLabel(nextDay);
                      // labelSuffix = ` ${dayLabel}-${nextDayLabel}`;
                      label = ` ${dayLabel}-${nextDayLabel}`;
                    }
                  }
                }
              }
              return (
                <TableRow key={i}>
                  <TableCell><Box sx={type==='impliedAverageFloatingRate'?sxChartTooltipLegendDashedLine:sxChartTooltipLegendLine} style={{borderColor:p.stroke}}></Box><b>{label}{/* labelSuffix */}</b></TableCell>
                  <ValueTableCell mono align='right'>{new Decimal(p.value).toFixed(2)}%</ValueTableCell>
                </TableRow>
              );
            })}
            </TableBody>
          </Table>
        </Box>
      );
    }
    return null;
  }
  function ChartTooltipCursor(props:any){
    const {points,payloadIndex,onChangePayloadIndex} = props;
    // console.log(`ChartTooltipCursor [${payloadIndex}] ${points[0].x}`,props);
    useEffect(()=>{
      onChangePayloadIndex&&onChangePayloadIndex(payloadIndex);
    },[payloadIndex,onChangePayloadIndex]);
    // points.forEach((p:any,i:number)=>{
    //   // console.log(`ChartTooltipCursor [${i}] ${p.x},${p.y}`);
    // })
    return null;
  }
  // clean up yaxis logic to render ticks in either 2s, 5s or 10s interval
  // const yTickCount = 5;
  // const yPreferredInterval = ymax.ceil().lt(10)?2:ymax.lt(50)?5:10;
  // const yInterval = ymax.div(yTickCount).div(yPreferredInterval).ceil().mul(yPreferredInterval).toNumber();
  // const yPreferredMax = ymax.toNumber();//;ymax.div(yInterval).ceil().mul(yInterval).toNumber();
  // const yTicks = [...(new Array(yTickCount)).fill(0).map((_,i)=>yInterval*i)];
  // console.log('yInterval',yInterval,'yPreferredMax',yPreferredMax,'yTicks',yTicks,'data',data);
  const chartComponent = (<Box sx={{position:'relative',width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center', ...sxChartWatermarkCenter}}>
  <ResponsiveContainer /* aspect={inline?undefined:2} height={inline?undefined:"auto"} */ minHeight={inline?undefined:300} width={inline?undefined:"100%"}>
    <ComposedChart data={chartData} stackOffset="sign" margin={{left:-16}} key={rerenderCounter}>
      <YAxis yAxisId="px" orientation='left' domain={[(dataMin:number)=>Math.min(dataMin,0),'dataMax']} ticks={yAxisTicks} interval={'preserveStartEnd'} allowDataOverflow allowDecimals={false} tickLine={false} tickFormatter={(v,i)=>`${format(v,0)}%`} stroke={`${hslStrToHex(theme.palette.text.primary)}88`}/>
      <XAxis 
        dataKey="d" type="number" scale="linear" domain={[0,'dataMax']} stroke={`${hslStrToHex(theme.palette.text.primary)}88`} tickLine={false} 
        tickFormatter={(d,i)=>{
          const currentData = data.find((da: any) => da.d === d)
          return d===0 ? 'FL' : currentData?.name
        }}
      />
      {tokens?.filter(token=>filteredTokenCodes.includes(token.code)).map((t,i)=>{
        const lineColor = graphLineColors[i%graphLineColors.length];
        const areaColor = graphAreaColors[i%graphAreaColors.length]; 
        const impliedLineColor = theme.palette.secondary.main;
        // custom implied charted component for complex line
        const impliedChartComponent = <Scatter
          type="stepAfter"
          dataKey={`${t.code}-impliedAverageFloatingRate`}
          yAxisId="px" 
          stroke={impliedLineColor} fill={impliedLineColor} /* fill used for legend only */
          line={
            <ImpliedLine strokeDasharray={"9 3"} strokeWidth={2} stroke={impliedLineColor} fillOpacity={0.06} fill={areaColor} data={chartData} token={t} impliedLinePayloadIndex={impliedLinePayloadIndex}/>
          }
          shape={<>{/* remove scattered points */}</>}
        />;
        return (<Fragment key={i}>
          {withImpliedForwardRates&&impliedChartComponent}
          {chartData.length > 1 && <Area type="monotone" dataKey={`${t.code}-yieldCurve`} yAxisId="px" dot={false} 
            strokeWidth={1} stroke={lineColor} strokeOpacity={1} fillOpacity={1} fill={`url(#fill_yield_${i})`} 
            activeDot={!withImpliedForwardRates}
          />}
          <defs><linearGradient id={`fill_yield_${i}`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={areaColor} stopOpacity={0.5}/>
            <stop offset="0%" stopColor={areaColor} stopOpacity={0.2}/>
            <stop offset="20%" stopColor={areaColor} stopOpacity={0}/>
          </linearGradient></defs>
        </Fragment>);
      })}
      <CartesianGrid vertical={false} stroke={`${hslStrToHex(theme.palette.text.primary)}22`} />
      <Tooltip content={<ChartTooltip onInactive={()=>setImpliedLinePayloadIndex(-1)}/>} cursor={<ChartTooltipCursor onChangePayloadIndex={(payloadIndex:number)=>setImpliedLinePayloadIndex(payloadIndex)}/>}/>
      <Legend verticalAlign='top' align='right' content={<ChartLegend/>}/>
    </ComposedChart>
  </ResponsiveContainer>
  {isLoadingAny&&<LoadingIcon curtain/>}
  </Box>);
  if(inline) return chartComponent;
  return (
    <Box sx={styleBoxBlock} style={{height:'100%'}}>
      <Box sx={styleBoxPanel} style={{alignItems:'stretch',display:'flex',height:'100%'}}>
        <Box sx={sxChartHeader}>
          <DefaultTokensSelector onChange={tokens=>setFilteredGraphTokenCodes(tokens)} exclusive={withImpliedForwardRates} showsAll={!withImpliedForwardRates} initTokenCodes={initTokenCodes}/>
        </Box>
        <Box sx={sxChartContainer}>
          {chartComponent}
        </Box>
      </Box>
    </Box>
  );
}

function ImpliedLine(props:any){
  const {fill,fillOpacity,height,points,stroke,strokeDasharray,strokeWidth,width,data,token,impliedLinePayloadIndex} = props;
  const highlighted = impliedLinePayloadIndex>=0;
  const {lineX,lineY,lineXHighlighted,textLabels,shade} = useMemo(()=>{
    const lineXArray:string[] = [];
    const lineXHighlightedArray:string[] = [];
    const lineYArray:string[] = [];
    const shadeArray:string[] = [];
    const textLabels:JSX.Element[] = [];
    const {x:firstX,y:firstY} = points[0]||{};
    const {x:lastX} = points[points.length-1]||{};
    // shades start from bottom left of the graph
    shadeArray.push(`M ${firstX} ${height}`);
    let prevY = 0;
    let prevX = 0;
    points.forEach(({x,y}:{x:number,y:number},i:number)=>{
      const xRounded = Math.round(x*1000)/1000;
      const yRounded = Math.round(y*1000)/1000;
      const movedY = prevY!==yRounded;
      // before drawing the line: check if Y is changed i.e. the line is not horizontal
      if(i>0 && movedY){
        // shade and line x - use last Y to level the current line first
        shadeArray.push(`L ${xRounded} ${prevY}`);
        lineXArray.push(`L ${xRounded} ${prevY}`);
        // line y - move the cursor to last Y and draw a line straght up/down
        lineYArray.push(`M ${xRounded} ${prevY} L${xRounded},${yRounded}`);
      }
      // highlighted segment 
      const isCurrentlyHighlighted = i===(impliedLinePayloadIndex+1);
      if(highlighted && isCurrentlyHighlighted){ // +1 so we can grab prevX
        lineXHighlightedArray.push(`M ${prevX} ${prevY} L ${xRounded} ${prevY}`);
      }
      // add text label for previous X
      const width = xRounded-prevX;
      const currentData = data[i-1]; // -1 offset for data since text is drawn over the next point
      const value = currentData&&currentData[`${token.code}-impliedAverageFloatingRate`];
      textLabels.push(<text key={i} x={prevX+width/2} y={prevY-4} style={{
        fontSize:'12px',fill:stroke,transform:'translateX(-50%)',transformBox:'fill-box',
        opacity:highlighted&&!isCurrentlyHighlighted?0.5:1,
      }}>{value&&`${format(value,2)}%`}</text>);
      // Y cursor is guaranteed horizontal
      // shade and line x - draw horizonal line
      shadeArray.push(`L ${xRounded} ${yRounded}`);
      lineXArray.push(`${i===0||movedY?'M':'L'} ${xRounded} ${yRounded}`);
      // line y - no need to move or draw at all outside of Y change
      // save previous Y for elevation change
      prevY = yRounded;
      prevX = xRounded;
    });
    // close shade path
    shadeArray.push(`L ${lastX||0} ${firstY||0}`);
    shadeArray.push(`L ${lastX||0} ${height}`);
    shadeArray.push('Z');
    return {
      shade: shadeArray.join(' '),
      lineX: lineXArray.join(' '),
      lineY: lineYArray.join(' '),
      lineXHighlighted: lineXHighlightedArray.join(' '),
      textLabels, 
    }
  },[points,height,highlighted,stroke,impliedLinePayloadIndex,data,token]);
  return <>
    {/* shade - // TEMP disabled // TODO change it to shade the intersections between ImpliedFR and YC lines */}
    {/* <path fill-opacity={fillOpacity} fill={fill} width={width} height={height} stroke="none" d={shade}></path> */}
    {/* line x */}
    <path strokeDasharray={strokeDasharray} strokeWidth={strokeWidth} stroke={stroke} strokeOpacity={highlighted?0.5:1} fillOpacity={fillOpacity} fill="none" width={width} height={height} d={lineX}></path>
    {/* line x highlighted */}
    <path strokeDasharray={strokeDasharray} strokeWidth={strokeWidth} stroke={stroke} fillOpacity={fillOpacity} fill="none" width={width} height={height} d={lineXHighlighted}></path>
    {/* line y  half width and opacity */}
    <path strokeDasharray={strokeDasharray} strokeWidth={strokeWidth/2} stroke={stroke} strokeOpacity={0.5} fillOpacity={fillOpacity} fill="none" width={width} height={height} d={lineY}></path>
    {/* text labels */}
    {textLabels}
  </>;
}

export default MarketsYieldCurvesChart;