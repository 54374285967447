import { Box } from '@mui/material';
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect } from 'react';
import { hslStrToHex } from 'src/utils/common';
import theme from 'src/utils/theme';
// import './index.css';
import { useCallbackRollingDateLabel } from 'src/utils/date';
import {
  ChartingLibraryWidgetOptions, IChartingLibraryWidget, LanguageCode, ResolutionString, UndoRedoState, widget
} from '../charting_library';
import { BaseMarket, MarketForSymbol, Token } from '../types';
import { TVChartDatafeed } from '../utils/apis/tvchart-datafeed';
import useAppStore from 'src/store';

export interface ChartContainerProps {
  symbol?: ChartingLibraryWidgetOptions['symbol'];
  interval?: ChartingLibraryWidgetOptions['interval'];

  // BEWARE?: no trailing slash is expected in feed URL
  datafeedUrl?: string;
  libraryPath?: ChartingLibraryWidgetOptions['library_path'];
  chartsStorageUrl?: ChartingLibraryWidgetOptions['charts_storage_url'];
  chartsStorageApiVersion?: ChartingLibraryWidgetOptions['charts_storage_api_version'];
  clientId?: ChartingLibraryWidgetOptions['client_id'];
  userId?: ChartingLibraryWidgetOptions['user_id'];
  fullscreen?: ChartingLibraryWidgetOptions['fullscreen'];
  autosize?: ChartingLibraryWidgetOptions['autosize'];
  studiesOverrides?: ChartingLibraryWidgetOptions['studies_overrides'];
  container?: ChartingLibraryWidgetOptions['container'];
}

function getLanguageFromURL(): LanguageCode | null {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
}

function useCallbackMarketSymbolCode(){
  const getRollingDateLabel = useCallbackRollingDateLabel();
  return useCallback((market:MarketForSymbol)=>`${market.token.code}-${getRollingDateLabel(market)}`,[getRollingDateLabel]);
}

function TVChartContainer({token,market}:{token?:Token,market?:BaseMarket}) {
  let tvWidget: IChartingLibraryWidget | null = null;
  const appstate = useAppStore();
  const { tradingViewTimeZone } = appstate;
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  const getMarketSymbolCode = useCallbackMarketSymbolCode();
  useEffect(()=>{
    // console.log('TVChartContainer useEffect',market);
    if(!token||!market) return;
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: getMarketSymbolCode(market),
      datafeed: new TVChartDatafeed({queryCache,market,getMarketSymbolCode}),

      locale: getLanguageFromURL() || 'en',
      disabled_features: ['use_localstorage_for_settings',"left_toolbar",
        "header_fullscreen_button","header_screenshot","header_saveload","header_symbol_search"
      ],
      enabled_features: ["move_logo_to_main_pane"],

      // symbol: 'ETH-SPOT',
      interval: '15' as ResolutionString,
      container: 'tv_chart_container',
      // datafeedUrl: 'https://demo_feed.tradingview.com',
      library_path: '/charting_library/',
      charts_storage_url: 'https://saveload.tradingview.com',
      charts_storage_api_version: '1.1',
      client_id: 'tradingview.com',
      user_id: 'public_user_id',
      fullscreen: false,
      autosize: true,
    //   logo: {
    //     image: "/images/infinity_tradingview.png",
    //     link: "https://testnet.infinity.exchange/markets"
    // },
      // studiesOverrides: {
        // "volume.volume.color.0": "rgba(0, 255, 255, 0.8)",
        // "volume.volume.color.1": "#0000FF",
        // "volume.volume ma.color": "rgba(255, 0, 0, 0.7)",
        // "volume.volume ma.linewidth": 5,
        // "volume.volume ma.visible": true,
        // "bollinger bands.median.color": "#33FF88",
        // "bollinger bands.upper.linewidth": 7
      // },

      timeframe: '1d',
      time_frames: [
        { text: "6m", resolution: "3D" as ResolutionString, description: "6 Months" },
        { text: "3m", resolution: "1D" as ResolutionString, description: "3 Months"},
        { text: "1m", resolution: "120" as ResolutionString, description: "1 Months" },
        { text: "5d", resolution: "60" as ResolutionString, description: "5 Days" },
        { text: "1d", resolution: "15" as ResolutionString, description: "1 Day"},
      ],
      timezone: tradingViewTimeZone ?? "exchange",
      // custom
      theme: "dark",
      custom_css_url: '/css/tvchart.css',
      loading_screen: { backgroundColor: hslStrToHex(theme.palette.background.paper), },  // TODO convert hsl to hex
      toolbar_bg: hslStrToHex(theme.palette.background.paper),
      overrides: {
        "paneProperties.backgroundType": "solid",
        "paneProperties.background": hslStrToHex(theme.palette.background.paper),
      },
    };

    const _tvWidget = new widget(widgetOptions);
    tvWidget = _tvWidget;

    _tvWidget.onChartReady(() => {
      _tvWidget.subscribe('undo_redo_state_changed', (state: UndoRedoState) => {
        if (state?.undoText === "Changed Time Zone") {
          const newTimeZone = _tvWidget.chart().getTimezoneApi().getTimezone()
          appstate.set('tradingViewTimeZone',newTimeZone);
        }
      })
      _tvWidget.headerReady().then(() => {
        const button = _tvWidget.createButton();
        button.setAttribute('title', 'Click to show a notification popup');
        button.classList.add('apply-common-tooltip');
        button.addEventListener('click', () => _tvWidget.showNoticeDialog({
            title: 'Notification',
            body: 'TradingView Charting Library API works correctly',
            callback: () => {
              console.log('Noticed!');
            },
          }));
        button.innerHTML = 'Check API';
      });
    });
  },[market?.marketId]);

  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      '.TVChartContainer':{
        width: '100%',
        height: '100%',
      }
    }}>
      <div
        id={ 'tv_chart_container' }
        className={ 'TVChartContainer' }
      />
    </Box>
  );
}
export default TVChartContainer;