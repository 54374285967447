import Decimal from 'decimal.js';
import { isEmpty, isNil, reduce } from 'lodash';
import { useMemo } from 'react';
import { RATE_BUCKET_BP_THRESHOLD, RATE_BUCKET_SIZE } from 'src/constants/app';
import { MarketPastRate, RateBucketEntry, Token } from 'src/types';

type AdditionalBucketData = {
  dayIndex:number,
  bp:number,
  localMaxQuantity?:number
}

type BucketData = {
  quantity:number,
  minRate: number;
  maxRate: number;
};

type FlattenedBucketData = BucketData & AdditionalBucketData;
type MarketRatesMap = {
  [key: string]: MarketPastRate & { marketRate: number };
};

type DaysToMaturity = {
  [key: string]: number
}

const generateEmptyMap = (cutoffDays: any) => {
  const map = {}
  const numberOfBucket = new Decimal(RATE_BUCKET_BP_THRESHOLD).mul(2).div(RATE_BUCKET_SIZE).toNumber()
  for (let i = 0; i < cutoffDays.length; i++) {
    map[i] = {}
    for (let j = 0; j < numberOfBucket; j++) {
      const currentBucket = (RATE_BUCKET_BP_THRESHOLD * -1) + (j * RATE_BUCKET_SIZE)
      map[i][currentBucket] = {}
    }
  }
  return map
}

const useRateBucketData = ({
  marketRatesMap, daysToMaturityMap, cutoffDays, dataArray, showsUSDValue, token, 
}:{
  marketRatesMap: MarketRatesMap, daysToMaturityMap: DaysToMaturity, cutoffDays: number[], dataArray: RateBucketEntry[], showsUSDValue?:boolean, token?: Token
}) => {
  const data = useMemo(() => {
    if (isEmpty(marketRatesMap) || isEmpty(daysToMaturityMap) || isEmpty(cutoffDays)) return [];
    const dataMap = generateEmptyMap(cutoffDays);
    let maxQuantityInRange = 0;
    const quantityMultiplier = Number(showsUSDValue?(token?.price||0):1);
    dataArray.forEach((p: RateBucketEntry)=>{
      
      // DEV check bp range as primitive number type for speed - Decimal conversion for thousands of records tanks performance
      const marketRate = marketRatesMap[p.marketId]?.marketRate;
      const daysTo = daysToMaturityMap[p.marketId];
      if (isNil(marketRate) || isNil(daysTo)) return;

      // const dayIndex = cutoffDays.indexOf(daysTo);
      let i = 0; let cutoff = 0;
      for(i=0;i<cutoffDays.length;i++){
        if(cutoff>=daysTo) break;
        cutoff = cutoffDays[i];
      }
      const dayIndex = daysTo===0?0:i-1; // i-1 for previous matching key

      if(dayIndex === -1) return;

      const rate = new Decimal(p.rate);
      const bp = rate.sub(marketRate).mul(100*100);
      // check bp range up top 
      if(bp.gte(RATE_BUCKET_BP_THRESHOLD) || bp.lt(RATE_BUCKET_BP_THRESHOLD * -1)) return;
      
      const bpBucket = bp.divToInt(RATE_BUCKET_SIZE).mul(RATE_BUCKET_SIZE).toNumber();
      const bucket = dataMap?.[dayIndex]?.[bpBucket] ?? {};
      const quantity = Number(p.quantity||0)*quantityMultiplier;
      const newBucketData = {
        quantity: (bucket?.quantity ?? 0) + quantity,
        minRate: Math.min((bucket?.minRate ?? Number.POSITIVE_INFINITY), rate.toNumber()),
        maxRate: Math.max((bucket?.maxRate ?? Number.NEGATIVE_INFINITY), rate.toNumber())
      }
      if (dataMap[dayIndex]) {
        dataMap[dayIndex][bpBucket] = newBucketData;
      } else {
        dataMap[dayIndex] = {
          [bpBucket]: newBucketData
        };
      }

      if (newBucketData.quantity > maxQuantityInRange) maxQuantityInRange = newBucketData.quantity;
    });

    const flattenedData = reduce(dataMap, (flat, marketData, marketKey) => {
      const arrayOfDay = reduce(marketData, (f, bucketData: BucketData, bucketKey) => {
        f.push({ ...bucketData, bp: bucketKey, dayIndex: Number(marketKey), localMaxQuantity: maxQuantityInRange })
        return f
      }, [] as FlattenedBucketData[]);
      return flat.concat(arrayOfDay);
    }, [] as FlattenedBucketData[]);
    return flattenedData;
  }, [marketRatesMap,daysToMaturityMap,cutoffDays,dataArray,showsUSDValue,token?.price]);

  return data;
}

export default useRateBucketData;
