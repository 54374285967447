import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useMemo, useState } from 'react';
import useAppStore from 'src/store';
import { sxTokenSwitchItem } from 'src/styles/Chart';
import { sxBox, sxBoxTableContainerBordered, sxBoxTitle, sxColumnGridGraph, sxColumnGridTable, sxColumnsGrid, sxFilterBoxRow, sxTitleRow } from 'src/styles/General';
import { sxPageHeadingRow, sxPageHeadingSmall, sxPageHeadingWrapper } from 'src/styles/Page';
import { today } from 'src/utils/date';
import { useAuth } from '../AuthProvider';
import apis from '../utils/apis';
import CashPositionsTable from './CashPositionsTable';
import DefaultTokensSelector, { defaultTokens } from './DefaultTokensSelector';
import ReloadDateText from './ReloadDateText';
import UserPNLGraph from './UserPNLGraph';

type TimeUnitType = 'Live'|'MTD'|'YTD'|'All Time';
const TIME_UNIT_TYPES:TimeUnitType[] = ['Live','MTD','YTD','All Time'];
function getTimeUnitDays(timeUnit:TimeUnitType){
  switch(timeUnit){
    case 'Live': return 1;
    case 'MTD': return today().diff(today().startOf('month'),'d');
    case 'YTD': return today().diff(today().startOf('year'),'d');
    default: return undefined;
  }
}
type GraphTimeRangeType = 'All Time'|'Last 30 Days';
const GRAPH_TIME_RANGE_TYPES:GraphTimeRangeType[] = ['All Time','Last 30 Days'];
function getGraphDaysRange(timeUnit:GraphTimeRangeType){
  switch(timeUnit){
    case 'Last 30 Days': return 30;//today().subtract(30,'d').unix()*1000;
    default: return undefined;
  }
}

function PNLSection({accountId,graphOnly}:{accountId?:number,graphOnly?:boolean}){
  const auth = useAuth();
  const queryClient = useQueryClient();
  const [selectedTimeUnitType,setSelectedTimeUnitType] = useState(TIME_UNIT_TYPES[0]);
  const [selectedGraphTimeRangeType,setSelectedGraphTimeRangeType] = useState(GRAPH_TIME_RANGE_TYPES[0]);
  // const [selectedGraphCumulativeType,setSelectedGraphCumulativeType] = useState(false);
  const [selectedTokenCodes,setSelectedTokenCodes] = useState(defaultTokens);
  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  const { currentUserAccountId } = useAppStore();
  const tradeAccount = userAccounts&&apis.user.extractUserAccount(userAccounts,currentUserAccountId);
  const { dataUpdatedAt, isFetching } = apis.user.useUserAccountPerformanceLive(auth,{ accountId: accountId ?? tradeAccount?.accountId });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lastUpdatedTime = useMemo(()=>moment(),[dataUpdatedAt]);
  const handleClickReload = ()=>{
    queryClient.invalidateQueries(['user',{type:'accountPerformanceLive'}]);
  }
  
  return (
    <Box sx={{my:'24px',position:'relative'}}>
        <Box sx={sxPageHeadingRow}>
          <Box sx={sxPageHeadingWrapper}>
            <Typography variant="h1" sx={sxPageHeadingSmall}>Profit &amp; Loss</Typography>
            {/* <Link component={RouterLink} sx={sxInlineLink} to={paths.portfolioPage("mtm-performance")}>View MTM Performance</Link> */}
          </Box>
          <ReloadDateText isFetching={isFetching} lastUpdatedTime={lastUpdatedTime} handleClick={handleClickReload}/>
      </Box>
      <Box sx={sxBox}>
        <Box /* sx={sxBoxCompactInner} */>
          <Box sx={{...sxColumnsGrid,gridTemplateColumns:'5fr 7fr',}}>
            <Box sx={sxColumnGridTable}>
              <Box sx={sxTitleRow}>
                <Typography variant="h2" sx={sxPageHeadingSmall}>P&amp;L</Typography>
                <Box sx={{display:'flex',columnGap:'8px',p:'8px',alignItems:'center'}}>
                  <TimeUnitSelector selectedType={selectedTimeUnitType} onChange={selectedType=>setSelectedTimeUnitType(selectedType)}/>
                </Box>
              </Box>
              <Box sx={sxBoxTableContainerBordered}><CashPositionsTable type="pnl" daysRange={getTimeUnitDays(selectedTimeUnitType)}/></Box>
            </Box>
            <Box sx={{...sxColumnGridGraph, padding: '0'}}>
              <Box sx={{...sxFilterBoxRow, paddingTop: '16px', paddingLeft: '16px'}}>
                <DefaultTokensSelector showsAll onChange={setSelectedTokenCodes}/>
                {/* <GraphCulmulativeSelector selectedType={selectedGraphCumulativeType} onChange={setSelectedGraphCumulativeType}/> */}
                <GraphTimeRangeSelector selectedType={selectedGraphTimeRangeType} onChange={setSelectedGraphTimeRangeType}/>
              </Box>
              <UserPNLGraph tokenCodes={selectedTokenCodes} padGraph /* hidesCumulative={!selectedGraphCumulativeType} */ daysRange={getGraphDaysRange(selectedGraphTimeRangeType)}/>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function TimeUnitSelector({ selectedType, onChange }:{ selectedType:TimeUnitType, onChange?:(selectedType:TimeUnitType)=>any }){
  return (
    <ButtonGroup variant="outlined" aria-label="outlined primary button group">
    {TIME_UNIT_TYPES.map((type,i)=>{
      const inactive = selectedType!==type;
      return (
        <Button key={i} sx={sxTokenSwitchItem} variant={inactive?'outlined':'contained'} 
          onClick={()=>{
            onChange&&onChange(type);
          }}
        >{type}</Button>
      )
    })}
    </ButtonGroup>
  );
}

function GraphCulmulativeSelector({ selectedType, onChange }:{ selectedType:boolean, onChange?:(selectedType:boolean)=>any }){
  return (
    <ButtonGroup variant="outlined" aria-label="outlined primary button group">
    {[false,true].map((type,i)=>{
      const inactive = selectedType!==type;
      return (
        <Button key={i} sx={sxTokenSwitchItem} variant={inactive?'outlined':'contained'} 
          onClick={()=>{
            onChange&&onChange(type);
          }}
        >{type?'Cumulative P&L':'Daily P&L'}</Button>
      )
    })}
    </ButtonGroup>
  );
}

function GraphTimeRangeSelector({ selectedType, onChange }:{ selectedType:GraphTimeRangeType, onChange?:(selectedType:GraphTimeRangeType)=>any }){
  return (
    <ButtonGroup variant="outlined" aria-label="outlined primary button group">
    {GRAPH_TIME_RANGE_TYPES.map((type,i)=>{
      const inactive = selectedType!==type;
      return (
        <Button key={i} sx={sxTokenSwitchItem} variant={inactive?'outlined':'contained'} 
          onClick={()=>{
            onChange&&onChange(type);
          }}
        >{type}</Button>
      )
    })}
    </ButtonGroup>
  );
}

export default PNLSection;