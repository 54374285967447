import {
  Box
} from '@mui/material';
import { Decimal } from 'decimal.js';
import { sxBorrowAmount, sxLendAmount, sxNoDeltaAmount } from 'src/styles/TokenTable';
import { format } from 'src/utils/numbers';

function DeltaValue({value,dp=2,prefix="",suffix="",showsZero=false}:{value?:number|Decimal,dp?:number,prefix?:string,suffix?:string,showsZero?:boolean}){
  const valueDecimal = value ? (Decimal.isDecimal(value)?(value as Decimal):new Decimal(value)) : new Decimal(0);
  const signum = valueDecimal?.gte(0)?1:-1;
  let sx = (!valueDecimal||valueDecimal.eq(0))?sxNoDeltaAmount:valueDecimal.gte(0)?sxLendAmount:sxBorrowAmount;
  const valueText = valueDecimal===undefined ? '-' : format(valueDecimal.abs(),dp);
  const text = `${prefix}${valueText}${suffix}`;
  return (
    <Box sx={sx}>{
      (!showsZero&&(!valueDecimal||valueDecimal.toDP(dp).eq(0)))?
        '-': // 0 value & not showsZero: '-'
        `${signum===1?'+':'-'}${text}`
    }</Box>
  );
}

export default DeltaValue;