import { Box } from '@mui/material';
import { useQueryClient } from "@tanstack/react-query";
import { useWeb3Modal } from '@web3modal/react';
import Decimal from 'decimal.js';
import { reject } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import WalletPrompt from 'src/Components/WalletPrompt';
import infinityPoolABI from 'src/abi/InfinityPool.abi.json';
import tokenABI from 'src/abi/erc20.abi.json';
import tokenERC721ABI from 'src/abi/uniswapLp.abi.json';
import { CHAIN_ID, ETH_DECIMALS, INFINITY_POOL_ADDRESS, WETH_ADDRESS } from 'src/constants/app';
import useSnackbar from 'src/hooks/useSnackbar';
import useAppStore from 'src/store';
import { sxContent } from 'src/styles/Page';
import { Token, TokenTransfers, TokenWithBalance, User } from 'src/types';
import apis from 'src/utils/apis';
import { formatWeb3Address, getChainIdInHex, sendGA, setGAWalletAddress } from 'src/utils/common';
import { messages } from 'src/utils/messages';
import { Connector, useAccount, useConnect, useDisconnect, useNetwork, useSignTypedData } from 'wagmi';
import { fetchBalance, getAccount, prepareWriteContract, readContract, switchNetwork, waitForTransaction, writeContract } from 'wagmi/actions';

/**
 * onSessionUpdateFn gets called when the wallet plugin detects a session/account/chain change
*/
type onSessionUpdateFn = ({accounts,chainId}:{accounts?:string[],chainId?:number})=>void;
type signinFn = ({address,connector,skipsRecover}:{address?:string,connector?: Connector<any,any>,skipsRecover?:boolean})=>Promise<boolean>;
type connectFsn = ({connector}:{connector?:Connector<any,any>})=>Promise<void>;
type signDataFn = (from:string,data:string)=>Promise<string>;
type getBalanceFn = (tokens:Token[])=>Promise<TokenWithBalance[]>;
type transferTokensToInfinityFn = (tokenTransfers: TokenTransfers[])=>Promise<string|null>;
type requestTokenFn = (tokenAddress: string)=>Promise<string|null>;
export type AccessToken = {
  token: string;
  exp: number;
}
interface iWallet {
  accounts?: string[];
  chainId?: number;
  connect: connectFsn;
  signData: signDataFn;
  getBalance: getBalanceFn;
  onSessionUpdate: onSessionUpdateFn;
  transferTokensToInfinity: transferTokensToInfinityFn;
  requestToken: requestTokenFn;
}
interface WalletCompatibilityLayer extends iWallet {
  connectors: Connector<any,any>[];
  error: Error|null;
  isLoading: boolean;
  activeConnector?: Connector<any,any>;
  pendingConnector?: Connector<any,any>;
}
export interface AuthContextType {
  user?: User;
  wallet: WalletCompatibilityLayer;
  isLoggingIn: boolean;
  signin: signinFn;
  signout: (silent?:boolean) => Promise<void>;
  mayInitSignin: () => void;
  mayCancelSignin: () => void;
  sessionExpires: () => Promise<boolean>;
  updateAccessToken: (accessToken: AccessToken) => void;
  accessToken?: AccessToken;
  isWSLoggedIn: boolean;
  setWSLogginStatus: (status: boolean) => void
}

export type AccessResponse = {
  user?: User;
  accessToken?: AccessToken;
}

const AuthContext = React.createContext<AuthContextType>(null!);

function useAuth() {
  return React.useContext(AuthContext);
}

function AuthProvider({ children }: { children: React.ReactNode }) {
  const appstate = useAppStore();
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  const userRef = useRef<User|undefined>();
  const [currentAccessToken, setCurrentAccessToken] = useState<AccessToken|undefined>()
  const { open:openWeb3Modal, close:closeWeb3Modal } = useWeb3Modal();
  const [ isLoggingIn, setIsLoggingIn ] = useState(false);
  const [ isWSLoggedIn, setWSLogginStatus ] = useState(false);
  const connectorConnectCallbackResolveRejectRef = useRef<{
    resolve:((value: boolean | PromiseLike<boolean>) => void),
    reject:((reason?: any) => void),
  }|undefined>();
  
  const { chain } = useNetwork();
  const { address: activeAddress, connector: activeConnector, isConnecting, isReconnecting, isConnected, isDisconnected } = useAccount();
  const { connectAsync, connectors, error, isLoading, pendingConnector } = useConnect();
  const { disconnectAsync } = useDisconnect();
  const { signTypedDataAsync, variables } = useSignTypedData();

  const retryTransactionVerification = async (receipt: any, tryCount: number): Promise<string> => {
    try {
      const retriedTransaction = await waitForTransaction(receipt);
      return receipt.hash
    } catch(error) {
      if (tryCount >= 3) {
        throw error
      } else {
        return retryTransactionVerification(receipt, tryCount + 1)
      }
    }
  }

  const value = useMemo<AuthContextType>(()=>{
    /* API calls */
    const recoverSignin = async (recoverAddress?:string): Promise<AccessResponse>=>{
      if(!activeConnector&&appstate.loginState!=='INIT'){
        appstate.setLoginState('LOGGED_OUT');
        return {};
      }
      try{
        appstate.setLoginState('RECOVERING');
        const {activeAddress,accounts={}} = JSON.parse(localStorage.getItem('i_auth')||'{}');
        const {signature} = accounts[recoverAddress||activeAddress]||{};
        if(signature){
          const { user, accessToken } = await apis.user.refresh()
          if (user) {
            if (!activeAddress) {
              localStorage.setItem('i_auth',JSON.stringify({activeAddress: recoverAddress,accounts}));
            }
            return {
              user: user,
              accessToken: {
                token: accessToken.token,
                exp: accessToken.data.payload.exp
              }
            }
          } else {
            return {}
          }
        } else {
          return {}
        }
      } catch(error){
        console.error(error);
        return {}
      }
    }
    const doSignin = async({address,signature}:{address?:string, signature?:string}={address:activeAddress}): Promise<AccessResponse>=>{
      try{
        setIsLoggingIn(true);
        if(!address) throw new Error('Wallet not found');
        const chainId = CHAIN_ID;
        const res = await apis.user.login(address,getChainIdInHex(chainId),signature);
        const { status, nonceHash, eip712Message, user:_user } = res;
        let user = _user;
        let accessToken
        switch(status){
          case 'verify':
            snackbar.close('login'); snackbar.close('logout');
            snackbar.queue({key:'signdata',message:messages.SIGNIN_SIGNDATA,type:'info'});
            signature = await wallet.signData(address,eip712Message);
            const { user: verifiedUser, accessToken: newAccessToken } = await apis.user.verify(address,nonceHash,signature);
            user = verifiedUser
            if (newAccessToken) {
              accessToken = {
                token: newAccessToken.token,
                exp: newAccessToken.data.payload.exp
              }
            }
            break;
          case 'loggedIn':
            break;
          default:
            throw new Error(status);
        }
        const {accounts={}} = JSON.parse(localStorage.getItem('i_auth')||'{}');
        accounts[address] = {signature,address,chainId};
        localStorage.setItem('i_auth',JSON.stringify({activeAddress: address,accounts}));
        return { user, accessToken } as AccessResponse;
      }catch(error){
        mayDisconnect(); // reset connector connection
        snackbar.close('login'); snackbar.close('logout'); snackbar.close('signdata');
        snackbar.error(error);
        return {}
      }finally{
        setIsLoggingIn(false);
      }
    }
    const doSignout = async(silent?:boolean)=>{
      const {activeAddress,accounts={}} = JSON.parse(localStorage.getItem('i_auth')||'{}');
      delete accounts[activeAddress];
      localStorage.setItem('i_auth',JSON.stringify({activeAddress:null,accounts}));
      handleUserChange(undefined);
      snackbar.close('login'); snackbar.close('signdata');
      if(silent) return;
      // console.trace('doSignout snackbar');
      snackbar.queue({key:'logout',message:messages.SIGNOUT_SUCCESS,type:"success",autoHide:true});
    }
    /* wagmi web3 calls */
    const maySwitchNetwork = async (chainId?:number)=>{
      chainId = chainId ?? chain?.id
      if(chainId !== undefined && CHAIN_ID !== chainId){
        await switchNetwork({chainId:CHAIN_ID});
      }
      return CHAIN_ID;
    };
    const connectorConnectCallback = (connector?:Connector<any,any>)=>{
      if(connector?.id==='walletConnect'){
        openWeb3Modal();
      //   await closeWeb3Modal();
        return new Promise<boolean>((resolve,reject)=>{ connectorConnectCallbackResolveRejectRef.current = {resolve,reject}; });
      }
      return Promise.resolve(false);
    }
    const connect = async({connector}:{connector?:Connector<any,any>}={})=>{
      const connected = await connectorConnectCallback(connector);
      if(!connected) await connectAsync({connector});
      await maySwitchNetwork(await connector?.getChainId());
    };
    const mayDisconnect = async()=>{
      if(isConnected) await disconnectAsync();
    };
    const signData = async(from:string,data:string)=>{
      await maySwitchNetwork();
      return await signTypedDataAsync(JSON.parse(data));
    };
    const getBalance = async(tokens:Token[]):Promise<TokenWithBalance[]>=>{
      await maySwitchNetwork();
      return !activeAddress?[]:(await Promise.all(tokens.map(async token=>{
        const tokenAddress = formatWeb3Address(token.tokenAddress);
        let balance:string|null = '0';
        let erc721Tokens:string[]|null = [];
            // look up on chain
        try {
          if(token.code==='ETH'){ // WETH is coded as ETH in infinity
            const {value:balanceNumber} = (await fetchBalance({address: activeAddress}));
            const {value:WETHbalanceNumber} = (await fetchBalance({address: activeAddress,token:tokenAddress}));
            balance = new Decimal(balanceNumber.toString()).add(new Decimal(WETHbalanceNumber.toString())).div(Math.pow(10,ETH_DECIMALS)).toString();
            // debugger;
          }else{
          //Connect to contract
            if (token.tokenType === 2) {
              const balanceNumber =/*  0;// */(await fetchBalance({address: activeAddress,token:tokenAddress})).value;
              if (balanceNumber > 0) {
                const erc721TokenList = new Array<string>();
                const bns = Array.from(Array(balanceNumber).keys());
                for(let i=0;i<bns.length;i++){
                  const bn = bns[i];
                  const result = await readContract({ 
                    address: tokenAddress, abi: tokenERC721ABI, functionName:'tokenOfOwnerByIndex', args:[activeAddress, bn.toString()]
                  });
                  erc721TokenList.push(String(result));
                };
                erc721Tokens = erc721TokenList;
                balance = erc721TokenList.length.toString();
              }
            } else {
              balance = (await fetchBalance({address: activeAddress,token:tokenAddress})).formatted;
            }
          }
        }catch(err){
          console.warn(`get token balance error: (${token.name}[${token.tokenAddress}])`,err);
          balance = null;
        }
        return {...token,balance,erc721Tokens};
      })));
    }
    const transferTokensToInfinity = async(tokenTransfers: TokenTransfers[]): Promise<string|null>=>{
      await maySwitchNetwork();
      if(!activeAddress) throw new Error(`Wallet not connected`);
      let value;
      const tokenTransfersParams:TokenTransfers[] = [];
      for(let i=0;i<tokenTransfers.length;i++){
        const tokenTransfer = tokenTransfers[i];
        let {token,amount} = tokenTransfer;
        const tokenAddress = formatWeb3Address(token);
        // console.log(`Amount = ${amount}`);
        if(parseInt(token,16)===0) { // legacy ETH transfer logic 
          // for ETH: skip approve and set transaction eth value
          value = BigInt(amount.toString());
          continue;
        }
        if(parseInt(token,16)===parseInt(WETH_ADDRESS,16)) {
          // for WETH: since infinity combines WETH & ETH, we need to get both WETH & ETH balance to determine how much is needed from each token
          const {value:balanceNumber} = (await fetchBalance({address: activeAddress}));
          const {value:WETHbalanceNumber} = (await fetchBalance({address: activeAddress,token:tokenAddress}));
          // exhausts WETH first
          const WETHamountUsed = Decimal.min(new Decimal(amount.toString()),new Decimal(WETHbalanceNumber.toString()));
          value = BigInt(new Decimal(amount.toString()).sub(WETHamountUsed).toFixed());
          if(value===BigInt(0)) value = undefined;
          amount = WETHamountUsed.toFixed();
          if(WETHamountUsed.eq(0)) continue;
        }
        const approveRequest = await prepareWriteContract({
          address: tokenAddress, abi: tokenABI, functionName: 'approve', args: [INFINITY_POOL_ADDRESS,amount],
        });
        const receipt = await writeContract(approveRequest);
        const transaction = await waitForTransaction(receipt);
        // console.log('approval tx complete',hash);
        tokenTransfersParams.push({token,amount});
      }
      // console.log(tokenTransfersParams,value);
      const depositRequest = await prepareWriteContract({
        address: INFINITY_POOL_ADDRESS, abi: infinityPoolABI, functionName: 'deposit', value, args: [tokenTransfersParams,[]], 
      });
      const receipt = await writeContract(depositRequest);
      const transaction = await waitForTransaction(receipt);
      // console.log('deposit tx complete',hash);
      return receipt.hash;
    };
    const requestToken = async (tokenAddress:string)=>{
      await maySwitchNetwork();
      const abi = [ { "inputs": [], "name": "requestToken", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, ];
      const request = await prepareWriteContract({
        address: formatWeb3Address(tokenAddress), abi, functionName: 'requestToken',
      });
      const receipt = await writeContract(request);
      try {
        const transaction = await waitForTransaction(receipt);
        return receipt.hash;
      } catch (error) { 
        return await retryTransactionVerification(receipt, 1)
      }
    };
    /* exported functions */
    const signin:signinFn = async ({address,connector,skipsRecover}): Promise<boolean> => {
      try{
        setIsLoggingIn(true);
        if(!isConnected && connector) await wallet.connect({connector});
        if(!address) address = await getAccount().address;
        await maySwitchNetwork();
        // always try recovering first
        let user
        let accessToken
        const { user: recoveredUser, accessToken: recoveredAccessToken } = skipsRecover?{user:undefined,accessToken:undefined}:await recoverSignin(address);
        if(!recoveredUser){
          const { user: signedInUser, accessToken: newAccessToken } = await doSignin({address});
          user = signedInUser;
          accessToken = newAccessToken;
        } else {
          user = recoveredUser;
          accessToken = recoveredAccessToken;
        }
        if(user){
          handleUserChange(user, accessToken);
          snackbar.close('signdata'); snackbar.close('logout');
          snackbar.queue({key:'login',message:messages.SIGNIN_SUCCESS,type:"success",autoHide:true});
          return true;
        }
        return false;
      }finally{
        setIsLoggingIn(false);
      }
    };
    const signout = async (silent?:boolean) => {
      await mayDisconnect();
      await doSignout(silent);
    };
    /**
     * Expires current logged-in session object 
     * @returns true if attemped reconnect
     */
    const sessionExpires = async (silent?:boolean)=>{
      if(userRef.current !== undefined){
        userRef.current = undefined;
        // try to recover signin
        appstate.setLoginState('RECOVERING');
        const { user, accessToken } = await recoverSignin();
        handleUserChange(user, accessToken);
        if(!user&&!silent){
          snackbar.close('login'); snackbar.close('signdata');
          snackbar.queue({key:'logout',message:messages.SESSION_EXPIRED,type:"warning"});
        }
        return true;
      }
      return false;
    };
    const handleUserChange = (user?:User, accessToken?:AccessToken)=>{
      // console.log('handleUserChange',user);
      if(user !== userRef.current){
        userRef.current = user;
        setCurrentAccessToken(accessToken);
        queryClient.invalidateQueries({queryKey:['user']});
      }
      if(user !== undefined){
        appstate.setLoginState('LOGGED_IN');
      }else{
        appstate.setLoginState('LOGGED_OUT');
      }
      setGAWalletAddress(user?.address);
      sendGA(user===undefined?'signed_out':'signed_in');
    };

    const updateAccessToken = (accessToken: AccessToken) => {
      if (accessToken.token !== currentAccessToken?.token) {
        setCurrentAccessToken(accessToken)
      }
    }
    const mayInitSignin = async()=>{
      if(appstate.loginState==='INIT'){
        const { user, accessToken } = await recoverSignin();
        handleUserChange(user, accessToken);
      }
    };
    const mayCancelSignin = async()=>{
      setIsLoggingIn(false);
    }
    const onSessionUpdate:onSessionUpdateFn = async({accounts,chainId})=>{
      if(chainId!==undefined&&chainId!==CHAIN_ID){
        // do not auto reconnect to avoid network tug of war
        await maySwitchNetwork();
        console.log(`[AuthProvider] onSessionUpdate chain changed`);
      }
      // console.log(`[AuthProvider] onSessionUpdate new address:${accounts?.at(0)}`);
      const newAddress = accounts?.at(0);
      if(newAddress!==undefined){
        if(userRef.current?.address!==newAddress){
          await doSignout();
        }
        await signin({address: newAddress, skipsRecover: true});
      }
    };

    const wallet:WalletCompatibilityLayer = { 
      connectors, error, isLoading, pendingConnector, activeConnector,
      connect, signData, getBalance, onSessionUpdate, transferTokensToInfinity, requestToken,
    };

    return { 
      user: userRef.current, wallet, accessToken: currentAccessToken, isWSLoggedIn, setWSLogginStatus,
      isLoggingIn, signin, signout, sessionExpires, mayInitSignin, mayCancelSignin, updateAccessToken
    };
  },[
    appstate,queryClient,snackbar,activeConnector,activeAddress,
    chain, signTypedDataAsync, isLoggingIn, isConnected, 
    connectAsync, connectors, disconnectAsync, error, isLoading, pendingConnector,
    openWeb3Modal, currentAccessToken, isWSLoggedIn, setWSLogginStatus
  ]);

  // on init 
  useEffect(()=>{
    value.mayInitSignin();
  },[isConnected,isConnecting,isReconnecting,isLoading,value]);

  // on account change
  useEffect(()=>{
    const onChange = ({account,chain}:{account?:string,chain?:{ id: number; unsupported: boolean; }})=>{
      // console.log('[AuthProvider] onChange',account,chain);
      const accounts = account?[account]:[];
      const chainId = chain?.id;
      value.wallet.onSessionUpdate({accounts,chainId});
    };
    // console.log('[AuthProvider] activeConnector listener',activeConnector);
    activeConnector?.on('change',onChange);
    return ()=>{
      activeConnector?.off('change',onChange);
    }
  },[activeConnector, value.wallet]);

  useEffect(()=>{
    if(connectorConnectCallbackResolveRejectRef.current){
      const {resolve} = connectorConnectCallbackResolveRejectRef.current;
      if(activeConnector){
        if(isConnected){
          resolve(true);
        }else{
          reject(new Error('Wallet disconnected'));
        }
      }
      connectorConnectCallbackResolveRejectRef.current = undefined;
    }
  },[connectorConnectCallbackResolveRejectRef,activeConnector,isConnected]);

  // on network change
  useEffect(()=>{
    // if(chain&&chain.id!==CHAIN_ID){
    //   value.signout(true);
    // }
  },[chain]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  const { loginState } = useAppStore();
  // let location = useLocation();

  if(loginState==='INIT'||loginState==='RECOVERING') return (<></>);
  // TODO richer "signup" page
  if (!auth.user) {
    // return <Navigate to="/" state={{ from: location }} />;
    return (
      <Box sx={sxContent}>
        <WalletPrompt/>
      </Box>
    );
  }

  return children;
}

export { AuthProvider, RequireAuth, useAuth };

