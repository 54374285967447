import './polyfills';

import ReactDOM from 'react-dom/client';
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';

// do not replace - breaks @mui/material/Table component stylings (hover, sorticon)
// unstable_ClassNameGenerator.configure((componentName) =>
//   componentName.replace('Mui', ''),
// );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Helmet>
      {/* <link href={process.env.PUBLIC_URL+"/fonts/PTMono-Regular.ttf"} rel="stylesheet"/> */}
      {/*<script src={process.env.PUBLIC_URL+"/datafeeds/udf/dist/bundle.js"}></script>*/}
      <link href={process.env.PUBLIC_URL+"/fonts/Inter/fonts.css"} rel="stylesheet"/>
      <link href={process.env.PUBLIC_URL+"/fonts/Azeret_Mono/fonts.css"} rel="stylesheet"/>
      <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
      <meta name="debank-cloud-site-verification" content={process.env.REACT_APP_DEBANK_CONTENT_ID} />
      {/* <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
      <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet"/> */}
    </Helmet>
    <App />
  </BrowserRouter>,
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
