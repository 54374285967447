import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { useAuth } from 'src/AuthProvider';
import {
    EUserAccountType,
} from 'src/types';
import apis from 'src/utils/apis';

const useFreeCashByToken = (tokenId?: number) => {
    const auth = useAuth();
    const { data:tokens } = apis.token.useTokens();
    const { data:userAccounts } = apis.user.useUserAccounts(auth);

    const { portfolioFreeCashLocalQuantity, portfolioFreeCash }  = useMemo(() => {
        if (!tokenId || !userAccounts) return {}
        let portfolioFreeCashLocalQuantity = new Decimal(0);
        let portfolioFreeCash = new Decimal(0);
        userAccounts.forEach(account=>{
            if(account.type!==EUserAccountType.TRADING) return;
            if(account.tokenPositionMap){
                for(let [entryTokenId,tokenPosition] of account.tokenPositionMap){
                    if(tokenId===entryTokenId){
                        const token = tokens?.find(t=>t.tokenId===tokenId);
                        const tokenPriceDecimal = new Decimal(token?.price||0);
                        portfolioFreeCashLocalQuantity = portfolioFreeCashLocalQuantity.add(new Decimal(tokenPosition.maxWithdraw || 0));
                        portfolioFreeCash = portfolioFreeCash.add(portfolioFreeCash.mul(tokenPriceDecimal));
                    }
                }
            }
        });

        return { portfolioFreeCashLocalQuantity, portfolioFreeCash }
    }, [userAccounts, tokens, tokenId])

    return { portfolioFreeCashLocalQuantity: portfolioFreeCashLocalQuantity ?? new Decimal(0), portfolioFreeCash: portfolioFreeCash ?? new Decimal(0) }
}

export default useFreeCashByToken;