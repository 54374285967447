import { Box, Button, Link, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { TradeHistoryItem, Token, EOrderSide, EOrderStatusType, ERateOrderStatus, ERateOrderType, EOrderSource, ERateLPPositionStatus, EMarketType } from 'src/types';
import { sxBorrowAmount, sxDate, sxLabel, sxTokenTable, sxTokenTableCompactButton } from 'src/styles/TokenTable';
import { sxActionButton, sxBorderRight } from 'src/styles/Table'
import { formatStartOfDayMarketDate, getDaysDifference, now, useCallbackParseMaturityDate } from 'src/utils/date';
import { format, formatDateTime } from 'src/utils/numbers';
import Decimal from 'decimal.js';
import moment from 'moment';
import MarketRollingDateLabel from 'src/Components/MarketRollingDateLabel';
import ValueTableCell from 'src/Components/ValueTableCell';
import { LoadingCell } from 'src/Components/Loading';
import TokenIcon from 'src/Components/TokenIcon';
import AccountAddress from 'src/Components/WalletAddress';
import { Link as RouterLink } from 'react-router-dom';
import { paths } from 'src/utils/paths';
import { TableCheckbox } from './TableCheckbox';

export const TradeHistoryTableRow = ({
    orderHistoryItem,
    token,
    publicView = false,
    showsUserAddressLink = false,
    type = 'history',
    onSelectionChange,
    isSelected = false,
    dialogRef
  }:{
    orderHistoryItem: TradeHistoryItem,
    token?: Token,
    publicView?: boolean,
    showsUserAddressLink?: boolean,
    type?: 'open' | 'history',
    onSelectionChange?: () => void,
    isSelected?: boolean,
    dialogRef?: any
  }) => {
    const parseMaturityDate = useCallbackParseMaturityDate();
    const { userAddress, quantityVector, side, rate, orderDate, updateDate,/* rateOrder, fixedRateOrder, */ dv01, mtm, source, decimals, marketType, status, orderId, averageTradeRate, orderType, maturityDate } = orderHistoryItem;
    const isFixedRateOrder = marketType === EMarketType.FIXED
    // const status = status ?? 0
    const fulfilled = new Decimal(orderHistoryItem.fulfilled || 0)
    const quantity = new Decimal(orderHistoryItem.quantity || 0)
    const amount = format(status === ERateOrderStatus.PARTIALLY_FILLED ? (quantity ?? quantityVector) : quantityVector,(decimals ?? 0));
    const amountUsdValue = format(new Decimal(quantityVector||0).mul(new Decimal(token?.price||0)),0);
    const amountComponent = side==EOrderSide.BORROW?
      <Box sx={sxBorrowAmount}>({amount})</Box>:
      <Box>{amount}</Box>;
    const amountUsdComponent = side==EOrderSide.BORROW?
      <Box sx={sxBorrowAmount}><Box sx={sxLabel}>(${amountUsdValue})</Box></Box>:
      <Box sx={sxLabel}>${amountUsdValue}</Box>;
    const mtmComponent = token&&mtm?format(mtm,8):'-';
    const dv01Str = !dv01?'-':format(dv01,8);
    const tokenDecimals = ['ETH', 'WBTC'].includes(token?.code ?? '') ? 4 : 2;
  
    let executedMaturityComponent = <Box component="span">FLOAT</Box>;
    let maturityComponent = <>FLOAT</>;
    let maturityDateComponent = <></>;
    let rateStr = '';
    let orderTypeStr = ''
    let statusStrComponent:string|JSX.Element = '';

    let statusType:EOrderStatusType = EOrderStatusType.PENDING;
    const statusSx:{[key in EOrderStatusType]:SxProps} = {
      [EOrderStatusType.PENDING]:{color:'primary.light'},
      [EOrderStatusType.PARTIAL]:{color:'success.main'},
      [EOrderStatusType.DONE]:{color:'success.main'},
      [EOrderStatusType.ERROR]:{color:'error.main'},
    };
    if(!isFixedRateOrder){
      switch(orderType){
        case ERateOrderType.MARKET:
          rateStr = 'Mkt';
          orderTypeStr = 'Market'
        break;
        case ERateOrderType.LIMIT:
          rateStr = `${format(new Decimal(averageTradeRate || rate || '0').mul(100),2)}%`;
          orderTypeStr = 'Limit'
          break;
      }
      // rateStr = `${format(new Decimal(rate||rateOrder.rate||'0').mul(100),2)}%`;
      executedMaturityComponent = <Box component="span">FLOAT</Box>;
    } else {
      const mDate = parseMaturityDate(maturityDate!);
      const oDate = moment(orderDate);
      const daysTo = getDaysDifference(mDate,now());
      const executedDaysTo = getDaysDifference(mDate,oDate);
      const dateString = formatStartOfDayMarketDate(mDate);
      executedMaturityComponent = <Box component="span"><MarketRollingDateLabel daysToMaturity={executedDaysTo} referenceDate={oDate}/>&nbsp;</Box>;
      if (daysTo > 0) {
        maturityComponent = <Box component="span"><MarketRollingDateLabel daysToMaturity={daysTo}/>&nbsp;</Box>;
        maturityDateComponent = <Box sx={sxDate}>{dateString}</Box>;
      }
      rateStr = `${format(new Decimal(averageTradeRate || rate || '0').mul(100),2)}%`;
      orderTypeStr = orderType === ERateOrderType.MARKET ? 'Market' : 'Limit';
    }
    if(status){
      // // TODO handle TWAP type
      switch(status){
        //@ts-ignore
        case ERateOrderStatus.PENDING: //enum overriden
          statusStrComponent = 'Pending'; 
          break;
        case ERateOrderStatus.ONBOOK:
          if (fulfilled.gt(0)) {
            // const quantity = quantity || 0;
            const filledPercentage = fulfilled.dividedBy(quantity).mul(100).toFixed(2);
            const filledTooltip = `Size: ${format(fulfilled.toNumber(), tokenDecimals)}/${format(quantity,tokenDecimals)}`
            statusStrComponent = <Tooltip title={filledTooltip} placement="right" arrow enterTouchDelay={0}><span>{`Onbook - Partially Filled: ${filledPercentage}%`}</span></Tooltip>; 
          } else {
            statusStrComponent = 'Onbook'; 
          }
          break;
        case ERateOrderStatus.DONE: 
          statusStrComponent = 'Done'; 
          statusType = EOrderStatusType.DONE; 
          break;
        case ERateOrderStatus.ERROR: 
          statusStrComponent = 'Error'; 
          statusType = EOrderStatusType.ERROR; 
          break;
        case ERateOrderStatus.PARTIALLY_FILLED:
          const filledPercentage = fulfilled.dividedBy(quantity).mul(100).toFixed(2)
          const filledTooltip = `Size: ${format(fulfilled, tokenDecimals)} / ${format(quantity,tokenDecimals)}`
          statusStrComponent = <Tooltip title={filledTooltip} placement="right" arrow enterTouchDelay={0}><span>{`Partially Filled: ${filledPercentage}%`}</span></Tooltip>; 
          statusType = EOrderStatusType.PARTIAL; 
          break;
        case ERateOrderStatus.MANUALLY_CANCELLED: 
          statusStrComponent = 'Manually Cancelled';
          const filledSize = fulfilled
          const cancelledSize = quantity.sub(fulfilled)
          const cancelledPercentage = cancelledSize.dividedBy(quantity).mul(100).toFixed(2)
          const mcFilledPercentage = fulfilled.dividedBy(quantity).mul(100).toFixed(2)
          const cancelledTooltip = (<Box sx={{ display: 'flex', flexDirection: 'column'}}>
            <Box sx={{display: 'flex', flexDirection: 'row', width: '100%'}}>
              <Box component={'span'} sx={{ flex: '1'}}>Fulfilled: </Box><Box component={'span'} sx={{ flex: '2', textAlign: 'right'}}>{`${format(filledSize, tokenDecimals)} (${mcFilledPercentage}%)`}</Box>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <Box component={'span'} sx={{ marginRight: '5px'}}>Cancelled: </Box> <Box component={'span'} sx={{ textAlign: 'right'}}>{`${format(cancelledSize, tokenDecimals)} (${cancelledPercentage}%)`}</Box>
            </Box>
          </Box>)
          statusStrComponent = <Tooltip title={cancelledTooltip} placement="right" arrow enterTouchDelay={0}><span>{`Manually Cancelled: ${mcFilledPercentage}% / ${cancelledPercentage}%`}</span></Tooltip>; 
          statusType = EOrderStatusType.ERROR; 
          break;
        case ERateOrderStatus.MARKET_EXPIRED: 
          statusStrComponent = 'Market Expired'; 
          statusType = EOrderStatusType.ERROR; 
        break;
        case ERateOrderStatus.AUTO_CANCELLED: 
          statusStrComponent = 'Auto Cancelled'; 
          statusType = EOrderStatusType.ERROR; 
          break;
        default: 
          statusStrComponent = `Status: ${status}`; 
          statusType = EOrderStatusType.ERROR; 
      }
    }
    switch(source){
      case EOrderSource.ACCOUNT_TRANSFER: statusStrComponent = <>Account Transfer - {statusStrComponent}</>; break;
      case EOrderSource.LIQUIDATION: statusStrComponent = <>Liquidation - {statusStrComponent}</>; break;
    }
    const statusComponent = <Box sx={statusSx[statusType]}>{statusStrComponent}</Box>;
    const tokenIcon = !token?<LoadingCell sx={{ marginLeft: '1.5rem'}}/>:<TokenIcon token={token} withCode={true} size={14}/>;
    const accountAddressComponent = userAddress&&<AccountAddress address={userAddress} withAvatar avatarPosition='left' disableLink/>;
  
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        data-active={isSelected}
      >
        {publicView&&<ValueTableCell sticky={true} width="50%" order={-10}>
          {!showsUserAddressLink?accountAddressComponent:
            <Link component={RouterLink} to={paths.exploreUserPage(userAddress||'')} sx={{color:'text.primary'}}>{accountAddressComponent}</Link>
          }
        </ValueTableCell>}
        <ValueTableCell label="Token" sticky={true} order={-10}>
          <Box>{status === ERateOrderStatus.ONBOOK && !publicView && <TableCheckbox selectionStatus={isSelected ? 'checked' : 'none'} onSelectionChange={onSelectionChange}/>}{tokenIcon}</Box></ValueTableCell>
        <ValueTableCell mono label="Position" width="66%" align="right">{amountComponent}</ValueTableCell>
        {publicView && <ValueTableCell mono label="USD" width="66%" align="right">{amountUsdComponent}</ValueTableCell>}
        <ValueTableCell label="Maturity" order={-8} align="right">{maturityComponent}{maturityDateComponent}</ValueTableCell>
        {(publicView || type === 'history') && <ValueTableCell label="Executed" order={-8} align="right">{executedMaturityComponent}</ValueTableCell>}
        <ValueTableCell sx={sxBorderRight} label="Rate" order={-7} align="right">{rateStr}</ValueTableCell>
        <ValueTableCell label="OrderType" align="right">{orderTypeStr}</ValueTableCell>
        {publicView && <ValueTableCell mono label="MTM" width="33%">{mtmComponent}</ValueTableCell>}
        {publicView && <ValueTableCell mono label="PV01" width="33%">{dv01Str}</ValueTableCell>}
        <ValueTableCell label="Status" align="right">{statusComponent}</ValueTableCell>
        <ValueTableCell mono label="Create Time" order={-9} align="right"><Box sx={sxDate}>{formatDateTime(orderDate)}</Box></ValueTableCell>
        <ValueTableCell mono label="Update Time" order={-9} align="right"><Box sx={sxDate}>{formatDateTime(updateDate ?? orderDate)}</Box></ValueTableCell>
        {!publicView && type==='open' &&<ValueTableCell label="Action" align="right"><Button sx={sxTokenTableCompactButton} onClick={()=>dialogRef?.current?.openDialog([orderHistoryItem])} variant="text"><Box sx={sxActionButton}>Cancel</Box></Button></ValueTableCell>}
      </TableRow>
    );
  }