import { Box } from '@mui/material';
import MarketTradeForm from 'src/Components/MarketTradeForm';
import { styleGrid, styleHeader, stylePanelScrollable } from 'src/styles/GridBox';
import apis from 'src/utils/apis';
import { paths } from 'src/utils/paths';
import { GridComponentParams } from '.';
import MarketRollingDateLabel from '../MarketRollingDateLabel';
import TokenSwitcher from '../TokenSwitcher';
import DragGrabber from './DragGrabber';

function OrderForm({token,market, selectedOrderBookEntry,onSelectOrderBookEntry, onMarketSwitcherSelectPath}:GridComponentParams){
  const {data:markets} = apis.rate.useRateMarkets();
  const tradePage = paths.useCallbackTradePage();
  return (
    <Box sx={styleGrid} style={{flex:1}}>
      <Box sx={styleHeader}>Order Form{market&&<>: <MarketRollingDateLabel market={market} withMarketCode/></>}<DragGrabber/></Box>
      {/* // TODO add maturity option on select callback */}
      <Box sx={{width:'100%',maxWidth:'300px',mx:'auto',my:'8px',px:'12px'}}>
        <Box sx={{ fontSize: '12px', fontWeight: '700', margin: '8px 0 4px', display: 'block' }}>Asset</Box>
        <TokenSwitcher token={token} type="icons" onSelectPath={(token)=>{
          const market = markets?.find(m=>m.tokenId===token.tokenId);
          if(!market) return undefined;
          return tradePage(market);
        }}/>
      </Box>
      <Box sx={stylePanelScrollable}><Box sx={{width:'100%'}}>
        {token&&<MarketTradeForm token={token} marketId={market?.marketId} selectedOrderBookEntry={selectedOrderBookEntry} onSelectOrderBookEntry={onSelectOrderBookEntry} onMarketSwitcherSelectPath={onMarketSwitcherSelectPath}/>}
      </Box></Box>
    </Box>
  );
}

export default OrderForm;