import { Box } from '@mui/material';
import Decimal from 'decimal.js';
import { useAuth } from 'src/AuthProvider';
import UserTokensGraph from 'src/Components/UserTokensGraph';
import useAppStore from 'src/store';
import { styleGrid, styleHeader, styleHeaderExtra, stylePanelChartContainer } from 'src/styles/GridBox';
import apis from 'src/utils/apis';
import { format } from 'src/utils/numbers';
import { GridComponentParams } from '.';
import DragGrabber from './DragGrabber';
import { withGraphPosition } from './GraphPositionProvider';

const UserTokensGraphWithGraphPosition = withGraphPosition(UserTokensGraph);

function MTMGraph({market,token}:GridComponentParams){
  const auth = useAuth();
  const tokenId = token?.tokenId;
  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  const { currentUserAccountId: currentUserAccountId } = useAppStore();
  const tradeAccount = userAccounts&&apis.user.extractUserAccount(userAccounts,currentUserAccountId);
  const accountIds = tradeAccount?[tradeAccount.accountId]:[]; //userAccounts?.map(w=>w.accountId);
  const { data:mtms } = apis.user.useUserMarkToMarketByToken(auth,{accountIds});
  const userMtm = new Decimal(mtms?.find(mtm=>mtm.tokenId===tokenId)?.mtm||0);
  return (
    <Box sx={styleGrid}>
      <Box sx={styleHeader}>MTM
        {userMtm&&<>:<Box component="span" sx={styleHeaderExtra}>{format(userMtm)}</Box></>}
      <DragGrabber/></Box>
      <Box sx={stylePanelChartContainer}>
        <UserTokensGraphWithGraphPosition token={token} graphKey='mtm' singleTokenGraph/>
      </Box>
    </Box>
  );
}


export default MTMGraph;