import { Box, Button, Checkbox, CheckboxProps, IconButton, InputAdornment, Link, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { OrderTableHeadCell } from 'src/types';
import ValueTableCell from 'src/Components/ValueTableCell';
// import { ReactComponent as CheckedIcon } from 'src/Components/Icons/CheckedIcon.svg';
// import { ReactComponent as IndeterminateIcon } from 'src/Components/Icons/IndeterminateIcon.svg';
import { TableCheckbox } from './TableCheckbox'
import { isFunction, isString } from 'lodash';

const sxCheckbox: SxProps = {
    padding: '0',
    paddingRight: '12px',
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      fill: 'primary.light'
    },
    '&.Mui-checked': {
      color: 'primary.light',
    }
}

export const TradeHistoryTableHead = ({
    cells,
    type,
    selectionStatus = 'none',
    onSelectionChange
} : {
    cells: readonly OrderTableHeadCell[],
    type?: 'open' | 'history',
    selectionStatus?: 'checked' | 'partial' | 'none',
    onSelectionChange?: (currentStatus: 'checked' | 'partial' | 'none') => void
}) => {
    return (<TableHead>
        <TableRow>
        {(cells).map((headCell,idx) => {
            const styleByPosition = idx === 0 ? { borderTopLeftRadius: '4px', zIndex: '3' } : (idx === cells.length - 1 ? { borderTopRightRadius: '4px' } : {})
            return (<ValueTableCell
            key={idx}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            colSpan={headCell.colSpan||1}
            width={headCell?.width}
            sticky={headCell?.sticky}
            sx={{...(headCell?.style || {}), ...styleByPosition}}
            >
            {(headCell.withSelector && type &&  headCell.withSelector(type))?
                (<Box sx={{ display: 'flex', width: isString(headCell?.width) ? headCell?.width : (isFunction(headCell?.width) ? headCell.width(type ?? '') : 'auto') }}>
                    <Box>
                        {/* <Checkbox sx={sxCheckbox} onChange={() => onSelectionChange && onSelectionChange(selectionStatus)} checked={selectionStatus === 'all'} checkedIcon={<CheckedIcon />} indeterminate={selectionStatus === 'partial'} indeterminateIcon={<IndeterminateIcon />}/> */}
                        <TableCheckbox
                            onSelectionChange={(status) => onSelectionChange && onSelectionChange(status)}
                            selectionStatus={selectionStatus}
                        />
                    </Box>
                    {headCell.label}
                </Box>) :
                (<Box sx={{ width: isString(headCell?.width) ? headCell?.width : (isFunction(headCell?.width) ? headCell.width(type ?? '') : 'auto') }}>{headCell.label}</Box>)
            }
            </ValueTableCell>
        )})}
        </TableRow>
    </TableHead>);
}
