import { Market, MarketFixedRate, Token } from "src/types";
import { SwapParameterStore } from "src/store/useSwapParametersStore";

export const getMarketByLeg = (legKey: string) => (state: SwapParameterStore): Market | MarketFixedRate | undefined => {
    const marketId = state.legs?.[legKey]?.marketId
    const markets = state?.markets
    return markets?.find(m => m.marketId === marketId)
}

export const getTokenByLeg = (legKey: string) => (state: SwapParameterStore): Token | undefined => {
    const tokenId = state.legs?.[legKey]?.tokenId
    const tokens = state?.tokens
    return tokens?.find(t => t.tokenId === tokenId)
}