import Decimal from 'decimal.js';
import { EMarketCategory } from 'src/types';
import {
	Bar,
	HistoryMetadata, LibrarySymbolInfo,
	SubscribeBarsCallback
} from '../../charting_library/datafeed-api';
import apis from '../apis';
import { periodLengthMilliseconds } from '../numbers';

interface DataSubscriber {
	symbolInfo: LibrarySymbolInfo;
	resolution: string;
	lastBarTime: number | null;
	listener: SubscribeBarsCallback;
}

interface DataSubscribers {
	[guid: string]: DataSubscriber;
}

export interface GetBarsResult {
	bars: Bar[];
	meta: HistoryMetadata;
}

export class DataPulseProvider {
	private readonly _subscribers: DataSubscribers = {};
	private _requestsPending: number = 0;
	protected _marketType: EMarketCategory = EMarketCategory.SPOT;

	public constructor({updateFrequency=10*1000,marketType=EMarketCategory.SPOT}:{updateFrequency?:number,marketType?:EMarketCategory}={}) {
		this._marketType = marketType;
		setInterval(this._updateData.bind(this), updateFrequency);
	}

	public subscribeBars(symbolInfo: LibrarySymbolInfo, resolution: string, newDataCallback: SubscribeBarsCallback, listenerGuid: string): void {
		if (this._subscribers.hasOwnProperty(listenerGuid)) {
			console.log(`DataPulseProvider: already has subscriber with id=${listenerGuid}`);
			return;
		}

		this._subscribers[listenerGuid] = {
			lastBarTime: null,
			listener: newDataCallback,
			resolution: resolution,
			symbolInfo: symbolInfo,
		};

		console.log(`DataPulseProvider: subscribed for #${listenerGuid} - {${symbolInfo.name}, ${resolution}}`);
	}

	public unsubscribeBars(listenerGuid: string): void {
		delete this._subscribers[listenerGuid];
		console.log(`DataPulseProvider: unsubscribed for #${listenerGuid}`);
	}

	private async _updateData(): Promise<void> {
		if (this._requestsPending > 0) {
			return;
		}

		this._requestsPending = 0;
		for (const listenerGuid in this._subscribers) { // tslint:disable-line:forin
			this._requestsPending += 1;
			try {
				await this._updateDataForSubscriber(listenerGuid);
				this._requestsPending -= 1;
				console.log(`DataPulseProvider: data for #${listenerGuid} updated successfully, pending=${this._requestsPending}`);
			}catch(error){
				this._requestsPending -= 1;
				console.log(`DataPulseProvider: data for #${listenerGuid} updated with error=${error}, pending=${this._requestsPending}`);
			};
		}
	}

	private async _updateDataForSubscriber(listenerGuid: string): Promise<void> {
		const subscriptionRecord = this._subscribers[listenerGuid];

		const rangeEndTime = parseInt((Date.now()).toString());

		const interval = periodLengthMilliseconds(subscriptionRecord.resolution);
		const rangeStartTime = rangeEndTime - (interval * 2);

		let marketHistory;
		if(this._marketType===EMarketCategory.SPOT){
			// spot
			marketHistory = await apis.rate.fetchMarketHistory({
				marketId:(subscriptionRecord.symbolInfo.ticker as string),
				start:rangeStartTime,end:rangeEndTime,interval
			});
		}else{
			// fixed rate
			marketHistory = await apis.fixedrate.fetchMarketHistory({
				marketId:(subscriptionRecord.symbolInfo.ticker as string),
				start:rangeStartTime,end:rangeEndTime,interval
			});
		}
		const bars:Bar[] = (marketHistory||[]).map(history=>{
			const bar = {time:history.date,open:0,high:0,low:0,close:0};
			['open','high','low','close'].forEach(k=>{
				bar[k] = new Decimal(history[k]).mul(100).toDP(2).toNumber();
			});
			return bar;
		});
		// console.log("bars", bars);
		this._onSubscriberDataReceived(listenerGuid, {
			bars, meta:{
				noData: bars.length===0,
			}
		});
	}

	private _onSubscriberDataReceived(listenerGuid: string, result: GetBarsResult): void {
		// means the subscription was cancelled while waiting for data
		if (!this._subscribers.hasOwnProperty(listenerGuid)) {
			console.log(`DataPulseProvider: Data comes for already unsubscribed subscription #${listenerGuid}`);
			return;
		}

		const bars = result.bars;
		if (bars.length === 0) {
			return;
		}

		const lastBar = bars[bars.length - 1];
		const subscriptionRecord = this._subscribers[listenerGuid];

		if (subscriptionRecord.lastBarTime !== null && lastBar.time < subscriptionRecord.lastBarTime) {
			return;
		}

		const isNewBar = subscriptionRecord.lastBarTime !== null && lastBar.time > subscriptionRecord.lastBarTime;

		// Pulse updating may miss some trades data (ie, if pulse period = 10 secods and new bar is started 5 seconds later after the last update, the
		// old bar's last 5 seconds trades will be lost). Thus, at fist we should broadcast old bar updates when it's ready.
		if (isNewBar) {
			if (bars.length < 2) {
				throw new Error('Not enough bars in history for proper pulse update. Need at least 2.');
			}

			const previousBar = bars[bars.length - 2];
			subscriptionRecord.listener(previousBar);
		}

		subscriptionRecord.lastBarTime = lastBar.time;
		subscriptionRecord.listener(lastBar);
	}
}
