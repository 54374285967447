import apis from 'src/utils/apis';
import { BaseMarket, ERateOrderStatus, EUserAccountType, FixedRateOrder, FixedRateOrderData, FloatRateOrderData, RateOrder, TradeHistoryItem } from 'src/types';
import { TRADE_HISTORY_TABLE_PAGE_LIMIT } from 'src/constants/app';
import { useAuth } from 'src/AuthProvider';
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useRef } from 'react';
import {
    mapFixedRateOrderHistoryItem, 
    mapFloatRateOrderHistoryItem
} from 'src/Components/TradeHistoryTable'
import { usePrevious } from 'src/utils/common';
import { isEqual, isUndefined } from 'lodash';

type PageLastOrderId = {
    floatOrderId?: number;
    fixedOrderId?: number;
}

export const useUserTradeHistoryByMarket = ({
    selectedMarketId,
    type = 'history',
    getCurrentLastOrderIds,
    updateLastOrderIds,
    page
}: {
    selectedMarketId?:number
    type?: 'open' | 'history',
    getCurrentLastOrderIds: (marketKey: string) => PageLastOrderId,
    updateLastOrderIds: (orders: TradeHistoryItem[], marketKey: string, hasNextPage: boolean, cutoffFloatingOrderId?: number, cutoffFixedOrderId?: number) => void,
    page: number
}) => {
    const auth = useAuth();
    const prevType = usePrevious(type)
    const prevMarketId = usePrevious(selectedMarketId)
    const prevPage = usePrevious(page)
    const {data:tokens} = apis.token.useTokens();
    const {data:markets} = apis.rate.useMarkets();
    const {isLoading:isLoadingUserAccounts,data:userAccounts} = apis.user.useUserAccounts(auth);
    const queryClient = useQueryClient();
    const orderHistoryItems = useRef<Map<string,TradeHistoryItem[] | undefined>>(new Map())
    const tradingAccount = userAccounts?.filter(uw => uw.type === EUserAccountType.TRADING)[0];
    const { accountId } = tradingAccount||{};

    let marketType: 'rateMarket' | 'fixedRateMarket' | 'unknown' = 'unknown'
    let market: BaseMarket | undefined
    if (selectedMarketId !== undefined && markets){
        market = markets.find(market=>market.marketId===selectedMarketId)
        marketType = (markets.find(market=>market.marketId===selectedMarketId)?.maturityDate !== undefined) ? 'fixedRateMarket': 'rateMarket';
    }
    const flatOrdersData = (rateOrderData: FloatRateOrderData[] | FixedRateOrderData[]) => {
        const orderIds = {}
        const orders: (RateOrder | FixedRateOrder)[] = []
        rateOrderData.forEach(orderData => {
            orderData.orders.forEach((order: RateOrder | FixedRateOrder) => {
                if (!orderIds[order.orderId]) {
                    orderIds[order.orderId] = true
                    orders.push(order)
                }
            })
        })
        return orders
    }

    const currentLastOrderIds = useMemo(
        () => {
            return getCurrentLastOrderIds(`${selectedMarketId}-${type}`)
        }, [getCurrentLastOrderIds, selectedMarketId, type]
    )

    const queryKey = useMemo(
        () => {
            return [
                'user',
                { 
                    category: marketType === 'rateMarket' ? 'floating' : 'fixed',
                    action:'liveRateOrders',
                    userId: auth.user?.userId,
                    accountId,
                    marketId: market?.marketId,
                    pending: type === 'open',
                    done: type === 'history',
                    startId: marketType === 'rateMarket' ? currentLastOrderIds.floatOrderId : currentLastOrderIds.fixedOrderId,
                    limit: TRADE_HISTORY_TABLE_PAGE_LIMIT}]
        }, [marketType, auth, accountId, market, type, currentLastOrderIds]
    )

    const prevQueryKey = usePrevious(queryKey)

    useEffect(() => {
        if (selectedMarketId && selectedMarketId !== prevMarketId) {

            orderHistoryItems.current.set(`${selectedMarketId}-open`,undefined)
            orderHistoryItems.current.set(`${selectedMarketId}-closed`,undefined)
        }
    }, [type, selectedMarketId, queryClient, queryKey, prevType, prevMarketId, prevQueryKey])
    useEffect(() => {
        if (page !== undefined && page !== prevPage) {
            orderHistoryItems.current.set(`${selectedMarketId}-${type}`,undefined)
        }
    }, [page, prevPage, type, selectedMarketId])

    const { isInitialLoading:isInitialLoadingLiveFixedRateOrders, isFetching:isFetchingFixedRateOrders, data:liveFixedRateOrdersData } = apis.fixedrate.useRealTimeUserFixedRateOrders(auth,{
        accountId, market, limit:TRADE_HISTORY_TABLE_PAGE_LIMIT, startId:currentLastOrderIds.fixedOrderId,
        pending: type === 'open', done: type === 'history', disabled: marketType !== 'fixedRateMarket' //|| page !== 0
    });

    const fixedRateOrders = liveFixedRateOrdersData?.orders;
    const { isInitialLoading: isInitialLoadingRateOrders, isFetching: isFetchingRateOrders, data:rateOrdersData } = apis.rate.useRealTimeUserRateOrders(auth,{
        accountId, market, limit:TRADE_HISTORY_TABLE_PAGE_LIMIT, startId:currentLastOrderIds.floatOrderId,
        pending: type === 'open', done: type === 'history', disabled: marketType !== 'rateMarket'
    });
    const rateOrders = rateOrdersData?.orders;

    const isLoadingTable = useMemo(
        () => {
        return isInitialLoadingRateOrders || isInitialLoadingLiveFixedRateOrders || isLoadingUserAccounts
        }, [isInitialLoadingRateOrders, isInitialLoadingLiveFixedRateOrders, isLoadingUserAccounts]
    )

    // table items
    useEffect(() => {
        if ((marketType === 'fixedRateMarket' && liveFixedRateOrdersData?.type === 'initial') || (marketType === 'rateMarket' && rateOrdersData?.type ===  'initial')) {
            const items:TradeHistoryItem[] = []
            if(fixedRateOrders && marketType === 'fixedRateMarket'){
                fixedRateOrders.forEach(fixedRateOrder=>{
                    items.push(mapFixedRateOrderHistoryItem(fixedRateOrder, tokens, markets))
                });
            }
            if(rateOrders && marketType === 'rateMarket'){
                rateOrders.filter((rateOrder: RateOrder) => rateOrder.status !== ERateOrderStatus.ERROR).forEach((rateOrder: RateOrder) => {
                const orderHistoryItem = mapFloatRateOrderHistoryItem(rateOrder, markets)
                if (orderHistoryItem) items.push(orderHistoryItem)
                });
            }
            
            items.sort((a,b)=>a.orderDate>b.orderDate?-1:a.orderDate<b.orderDate?1:0);

            if (!orderHistoryItems.current.get(`${selectedMarketId}-${type}`)) {
                updateLastOrderIds(items, `${selectedMarketId}-${type}`, !!(liveFixedRateOrdersData?.hasNextPage ?? rateOrdersData?.hasNextPage))
                orderHistoryItems.current.set(`${selectedMarketId}-${type}`, items)
            }
        } else if (Array.isArray(rateOrdersData) && page === 0) {
            const orders = flatOrdersData(rateOrdersData)
            const originalOpenItems = orderHistoryItems.current.get(`${selectedMarketId}-open`)
            const originalClosedItems = orderHistoryItems.current.get(`${selectedMarketId}-history`)
            const openItems = originalOpenItems !== undefined ? [...originalOpenItems] : undefined
            const closedItems = originalClosedItems !== undefined ? [...originalClosedItems] : undefined
            orders.forEach(order => {
                const orderHistoryItem = mapFloatRateOrderHistoryItem(order, markets)
                if (!orderHistoryItem) return
                if (order.status === ERateOrderStatus.ONBOOK) {
                    if (!isUndefined(openItems)) {
                        const currentItemIndex = openItems.findIndex(item => item.orderId === orderHistoryItem.orderId)
                        if (currentItemIndex >= 0) {
                            openItems[currentItemIndex] = orderHistoryItem
                        } else {
                            openItems.unshift(orderHistoryItem)
                        }
                    }
                } else if (order.status !== ERateOrderStatus.ERROR) {
                    if (!isUndefined(openItems)) {
                        const currentOpenItemIndex = openItems.findIndex(item => item.orderId === orderHistoryItem.orderId)
                        if (currentOpenItemIndex >= 0) {
                            openItems.splice(currentOpenItemIndex,1)
                        }
                    }
                    if (!isUndefined(closedItems)) {
                        const currentClosedItemIndex = closedItems.findIndex(item => item.orderId === orderHistoryItem.orderId)
                        if (currentClosedItemIndex >= 0) {
                            closedItems[currentClosedItemIndex] = orderHistoryItem
                        } else {
                            closedItems.unshift(orderHistoryItem)
                        }
                    }
                }
            })
            if (!isEqual(openItems, originalOpenItems)) {
                updateLastOrderIds(openItems ?? [], `${selectedMarketId}-open`, openItems ? openItems?.length >= 50 : false)
                orderHistoryItems.current.set(`${selectedMarketId}-open`, [...(openItems ?? [])])
            }
            if (!isEqual(closedItems, originalClosedItems)) {
                updateLastOrderIds(closedItems ?? [], `${selectedMarketId}-history`, closedItems ? closedItems.length >= 50 : false)
                orderHistoryItems.current.set(`${selectedMarketId}-history`, [...(closedItems ?? [])])
            }
        } else if (Array.isArray(liveFixedRateOrdersData) && page === 0) {
            const orders = flatOrdersData(liveFixedRateOrdersData)
            const originalOpenItems = orderHistoryItems.current.get(`${selectedMarketId}-open`)
            const originalClosedItems = orderHistoryItems.current.get(`${selectedMarketId}-history`)
            const openItems = originalOpenItems !== undefined ? [...originalOpenItems] : undefined
            const closedItems = originalClosedItems ? [...originalClosedItems] : undefined
            orders.forEach(order => {
                const orderHistoryItem = mapFixedRateOrderHistoryItem(order as FixedRateOrder, tokens, markets)
                if (!orderHistoryItem) return
                if (order.status === ERateOrderStatus.ONBOOK) {
                    if (!isUndefined(openItems)) {
                        const currentItemIndex = openItems.findIndex(item => item.orderId === orderHistoryItem.orderId)
                        if (currentItemIndex >= 0) {
                            openItems[currentItemIndex] = orderHistoryItem
                        } else {
                            openItems.unshift(orderHistoryItem)
                        }
                    }
                } else if (order.status !== ERateOrderStatus.ERROR) {
                    if (!isUndefined(openItems)) {
                        const currentOpenItemIndex = openItems.findIndex(item => item.orderId === orderHistoryItem.orderId)
                        if (currentOpenItemIndex >= 0) {
                            openItems.splice(currentOpenItemIndex,1)
                        }
                    }
                    if (!isUndefined(closedItems)) {
                        const currentClosedItemIndex = closedItems.findIndex(item => item.orderId === orderHistoryItem.orderId)
                        if (currentClosedItemIndex >= 0) {
                            closedItems[currentClosedItemIndex] = orderHistoryItem
                        } else {
                            closedItems.unshift(orderHistoryItem)
                        }
                    }
                }
            })
            if (!isEqual(openItems, originalOpenItems)) {
                updateLastOrderIds(openItems ?? [], `${selectedMarketId}-open`, openItems ? openItems.length >= 50 : false)
                orderHistoryItems.current.set(`${selectedMarketId}-open`, [...(openItems ?? [])])
            }
            if (!isEqual(closedItems, originalClosedItems)) {
                updateLastOrderIds(closedItems ?? [], `${selectedMarketId}-history`, closedItems ? closedItems.length >= 50 : false)
                orderHistoryItems.current.set(`${selectedMarketId}-history`, [...(closedItems ?? [])])
            }
        }
    }, [fixedRateOrders, rateOrders, liveFixedRateOrdersData, rateOrdersData, marketType, markets, tokens, updateLastOrderIds, orderHistoryItems, type, selectedMarketId, page])

    return {
        isLoadingTable,
        isFetchingUpdates: isFetchingFixedRateOrders || isFetchingRateOrders,
        orderHistoryItems: orderHistoryItems.current.get(`${selectedMarketId}-${type}`) ?? []
    }
}