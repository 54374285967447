import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { SxProps } from '@mui/system';
import Decimal from 'decimal.js';
import moment from 'moment';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'src/AuthProvider';
import ComponentLoadingIcon from 'src/Components/ComponentLoadingIcon';
import { BootstrapMenuItem, BootstrapSelect, sxSelectMenu } from 'src/styles/Form';
import { sxCellLoading, sxDeltaTextNegative, sxDeltaTextPositive, sxMonoFont } from 'src/styles/General';
import { sxBorrowAmount, sxDate, sxTokenTable, sxTokenTableCompactCell, sxTokenTableContainer } from 'src/styles/TokenTable';
import { BaseMarket, MarketData, MarketForSymbol, MarketPastRate, Token } from 'src/types';
import apis from 'src/utils/apis';
import { getMarketSymbolFromMarketRate, isTokenStableCoin, usePrevious } from 'src/utils/common';
import { formatStartOfDayMarketDate, useRollingDates } from 'src/utils/date';
import { format, formatTokenAmount } from 'src/utils/numbers';
import MarketRollingDateLabel from './MarketRollingDateLabel';
import TokenIcon from './TokenIcon';
import ValueTableCell from './ValueTableCell';

const sxPanelContainer:SxProps = {
  "table td, table th": {
    px:1,py:0.25,border:'none',whiteSpace:'nowrap',
  },
};
const sxSwitcherTable:SxProps = {
  ...sxTokenTable, 
  "td": { 
    fontSize: "0.875rem",
  },
  "th": { 
    fontSize: "0.75rem",
    fontWeight: 700,
    textTransform: 'uppercase',
    bgcolor:'table.dark',
  },
  "th,td": { 
    py:'4px',border:'none',whiteSpace:'nowrap',
  },
}
const sxStickyCell:SxProps = {
  "th.sticky, td.sticky": {
    padding: '0'
  },
}

const sxStickyInnerCell:SxProps = {
  paddingLeft: '0.5rem',
}

const sxStickyHeaderCell:SxProps = {
  height: '1.75rem',
  lineHeight: '1.75rem'
}

const sxStickyBodyCell:SxProps = {
  height: '1.6rem',
  lineHeight: '1.6rem'
}

const sxTHeadBorder:SxProps = {
  border: '0',
  ":after": {
    content: '""',
    position: 'absolute',
    left: '0',
    width: '100%',
    bottom: "0",
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'border.dark',
  }
}
const sxRowClickable:SxProps = {
  cursor:"pointer",
  ":hover":{
    bgcolor:"action.hover",
  }
}
const sxDeltaNegative:SxProps = {
  color:'error.main',
};
const sxDeltaPositive:SxProps = {
  color:'success.main',
};

const sxTokenTableMaturityCell: SxProps = {
  '&:is(th):is(th),&:is(td):is(td)':{
    px: '12px',
  }
}

const sxEmptyRowStickyCell: SxProps = {
  ":after": {
    borderRightColor: 'border.dark',
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
  }
}

type LayoutMarketRate = MarketPastRate & {midRateOrRate?: number, midRateOrRate24?: number}

type TColumnType = 'simple'|'public'|'user'|'compact';

function MarketSwitcher({
  token,disabled=false,disableClick=false,onChangedSelectedMarketId,layoutStyle='panel',
  columnType='public',
  onSelectPath, selectOnMouseEnter, isMarketSticky = false, withExtraRow = false
}:{
  token:Token,disabled?:boolean,disableClick?:boolean,onChangedSelectedMarketId?:(selectedMarketId:number)=>void,layoutStyle?:'table'|'panel'|'select',
  columnType?:TColumnType,
  onSelectPath?:(market:MarketForSymbol)=>string|undefined, selectOnMouseEnter?:false, isMarketSticky?: boolean, withExtraRow?: boolean
}){
  const auth = useAuth();
  const navigate = useNavigate();
  const tokenId = token?.tokenId;
  const {marketId:marketIdParamString} = useParams();
  const marketIdParam = marketIdParamString?parseInt(marketIdParamString):undefined;
  const [isOpeningMarketSelect,setIsOpeningMarketSelect] = useState(false);
  const [isShowingAllMarketMaturities,setIsShowingAllMarketMaturities] = useState(false);
  const {data:markets} = apis.rate.useMarkets({tokenId});
  const frMarketIds = markets?.filter(m=>m.daysToMaturity!==undefined).map(m=>m.marketId);
  const {isLoading:isLoadingMarkets,data:marketRatesByToken} = apis.rate.useMarketsPastRates({tokenId,frMarketIds});
  const marketRates = marketRatesByToken && marketRatesByToken[tokenId];
  const rollingDates = useRollingDates()
  const {isLoading:isLoadingUserMarketData,data:userMarketData} = apis.rate.useUserMarketDataByToken(auth,{tokenId});
  const [selectedMarketId,setSelectedMarketId] = useState<number|undefined>(marketIdParam);
  const handleSelectedMarketId = useCallback((selectedMarketId:number)=>{
    if(marketIdParamString===undefined) setSelectedMarketId(selectedMarketId);
    onChangedSelectedMarketId&&onChangedSelectedMarketId(selectedMarketId);
    const marketRate = marketRates?.find(m=>m.marketId===selectedMarketId);
    if(marketRate && onSelectPath){
	    // navigate to trade subpage
      const path = onSelectPath({
        marketId: marketRate.marketId, 
        maturityDate: marketRate.maturityDate, 
        daysToMaturity: marketRate.daysToMaturity, 
        token: token
      });
      if(path) navigate(path);
	  }
  },[marketIdParamString, marketRates, navigate, onChangedSelectedMarketId, onSelectPath, token]);

  useEffect(()=>{
    setSelectedMarketId(marketIdParam)
  },[marketIdParam]);
  useEffect(()=>{
    if(onSelectPath===undefined && marketRates && marketRates.length > 0){
      handleSelectedMarketId(marketRates[0].marketId);
    }
  },[marketRates,handleSelectedMarketId,onSelectPath]);

  const marketRow = (marketRate:LayoutMarketRate,token:Token,i:number, suggestedVolumeDP?: number)=>{
    const marketId = marketRate.marketId;
    const isFixedRate = marketRate.daysToMaturity!==undefined;
    const _userMarketData = isFixedRate?userMarketData?.fr.filter(fr=>fr.marketId===marketId)[0]:userMarketData?.ir;
    const market = (markets?.find(m=>m.marketId===marketId))||undefined;
    return (<MarketRow key={`${token.code}-${i}`}
      market={market} marketRate={marketRate} userMarketData={_userMarketData} token={token} columnType={columnType} 
      isLoading={isLoadingUserMarketData} disableClick={disableClick} selectedMarketId={selectedMarketId} suggestedVolumeDP={suggestedVolumeDP}
      handleSelectedMarketId={handleSelectedMarketId} selectOnMouseEnter={selectOnMouseEnter} isMarketSticky={isMarketSticky}
    />);
  }
  const marketSelectItem = (marketRate:MarketPastRate,token:Token,i:number)=>{
    const m = getMarketSymbolFromMarketRate(marketRate,token);
    return (
    <BootstrapMenuItem key={`${token.code}-${i}`} value={m.marketId}>{isShowingAllMarketMaturities?
      (<><MarketRollingDateLabel market={m} formatAsDay/>&nbsp;{m.maturityDate&&<Box sx={sxDate}>{formatStartOfDayMarketDate(m.maturityDate)}</Box>}</>):
      (<MarketRollingDateLabel market={m} withMarketCode withMarketDate/>)
    }</BootstrapMenuItem>);
  }
  let tableHead = <></>;
  switch(columnType){
    case 'simple': tableHead = (
      <TableHead>
        <TableRow>
          { isMarketSticky ? 
            <ValueTableCell sticky={isMarketSticky} width={'9rem'} sx={{ minWidth: '9rem' }}><Box sx={{ ...sxStickyInnerCell, ...sxStickyHeaderCell } as SxProps}>Maturity</Box></ValueTableCell> :
            <ValueTableCell>Maturity</ValueTableCell>
          }
          <ValueTableCell align="right">Rate</ValueTableCell>
          <ValueTableCell align="right">24h Chg</ValueTableCell>
          <ValueTableCell align="right">24h Volume</ValueTableCell>
          <ValueTableCell align="right">Amount</ValueTableCell>
          <ValueTableCell align="right">PV01</ValueTableCell>
        </TableRow>
      </TableHead>
    ); break;
    case 'public': tableHead = (
      <TableHead>
        <TableRow><ValueTableCell>Maturity</ValueTableCell><ValueTableCell align="right">Rate</ValueTableCell><ValueTableCell align="right">24h Chg</ValueTableCell><ValueTableCell align="right">24h Volume</ValueTableCell>
        </TableRow>
      </TableHead>
    ); break;
    case 'compact': tableHead = (
      <TableHead sx={sxTHeadBorder}>
        <TableRow><ValueTableCell colSpan={2}>{token&&<TokenIcon token={token} withCode size={12} variant='bold'/>}</ValueTableCell></TableRow>
      </TableHead>
    ); break;
    case 'user': tableHead = (
      <TableHead>
        <TableRow>
          <ValueTableCell colSpan={1} width="0">Maturity</ValueTableCell>
          <ValueTableCell align="right">Rate</ValueTableCell><ValueTableCell align="right">Amount</ValueTableCell>
        </TableRow>
      </TableHead>
    ); break;
  }

  const layoutMarketRates: LayoutMarketRate[] = (marketRates||[]).filter((mr: MarketPastRate) => {
    if (!mr.daysToMaturity) return true;
    let index = 0;
    let distance = Number.POSITIVE_INFINITY;
    rollingDates.forEach((rd, i) => {
      if (Math.abs(rd.daysTo - (mr?.daysToMaturity ?? 0)) < distance) {
        index = i;
        distance = Math.abs(rd.daysTo - (mr?.daysToMaturity ?? 0));
      }
    })
    if (distance > 0) {
      const marketWithSameDays = marketRates?.find((market: MarketPastRate) => market.daysToMaturity === rollingDates[index].daysTo);
      if (marketWithSameDays) return false;
    }
    return true;
  })

  const suggestedVolumeDP = useMemo(()=>{
    let suggestedVolumeDP = isTokenStableCoin(token)?2:4;
    layoutMarketRates?.forEach((market: LayoutMarketRate, index: number) => {
      const days = market.daysToMaturity || 0; 
      const midRateOrRate = new Decimal(market.rate || market.midRate|| 0);
      let midRateOrRate24 = new Decimal(market.rate24 || market.midRate24 || 0);
      if (days > 0 && midRateOrRate24.eq(0) && index > 0) {
        const prevMarket = layoutMarketRates[index - 1]
        midRateOrRate24 = new Decimal(prevMarket.rate24 || prevMarket.midRate24 || 0)
      }
      market.midRateOrRate = midRateOrRate.toNumber()
      market.midRateOrRate24 = midRateOrRate24.toNumber()
      if (new Decimal(market.volume24 || 0).gte(1e6)) {
        suggestedVolumeDP = 0
      }
    })
    return suggestedVolumeDP;
  },[layoutMarketRates,token]);

  switch(layoutStyle){
    case 'table': return (
      <>
        <ComponentLoadingIcon isLoading={isLoadingMarkets} curtain/>
        <TableContainer sx={sxTokenTableContainer}>
          <Table sx={sxSwitcherTable}>
            {tableHead}
            <TableBody>
              {layoutMarketRates?.map((frm,i)=>marketRow(frm,token,i, suggestedVolumeDP))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
    case 'panel': return (
      <>
        <ComponentLoadingIcon isLoading={isLoadingMarkets} curtain/>
        <TableContainer sx={sxPanelContainer}>
          <Table sx={{...sxSwitcherTable, ...(isMarketSticky ? sxStickyCell : {}), height: '100%'} as SxProps}>
            {tableHead}
            <TableBody>
              {layoutMarketRates?.map((frm,i)=>marketRow(frm,token,i, suggestedVolumeDP))}
              {withExtraRow && EmptyMarketRow()}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
    default:
    case 'select': return (
      <BootstrapSelect MenuProps={{sx:sxSelectMenu}}
        id="market-select"
        value={layoutMarketRates?.map(frm=>frm.marketId).includes(selectedMarketId||-1)?selectedMarketId:''}
        open={isOpeningMarketSelect}
        onOpen={()=>setIsOpeningMarketSelect(true)}
        onClose={()=>setIsOpeningMarketSelect(false)}
        onChange={(event)=>{
          if(event.target.value==='maturitiesToggle'){
            setIsShowingAllMarketMaturities(!isShowingAllMarketMaturities);
            setTimeout(()=>{
              setIsOpeningMarketSelect(true);
            },1);
          }else{ 
            handleSelectedMarketId(parseInt(`${event.target.value}`))
          }
        }}
        disabled={disabled}
      > 
        {/* {isShowingAllMarketMaturities&&<BootstrapMenuItem key={'maturitiesToggle'} value={'maturitiesToggle'}>...Show Only Standard Maturities</BootstrapMenuItem>} */}
        {layoutMarketRates?.map((frm,i)=>marketSelectItem(frm,token,i))}
        {/* {!isShowingAllMarketMaturities&&<BootstrapMenuItem key={'maturitiesToggle'} value={'maturitiesToggle'}>...Show All Maturities</BootstrapMenuItem>} */}
      </BootstrapSelect>
    );
  }
}
const RateComponent = memo(({rate}:{rate?:string})=>{
  const rateDecimal = rate !== undefined ? (new Decimal(rate||0)) : undefined;
  const previousRate = usePrevious(rateDecimal);
  const deltaIndicatorStyle = (previousRate && rateDecimal !== undefined) ? (rateDecimal.gt(previousRate) ? sxDeltaTextPositive : (rateDecimal.lt(previousRate) ? sxDeltaTextNegative : undefined)) : undefined;
  return <>
    {rateDecimal === undefined ? <Box component='span' sx={sxCellLoading}>-</Box> : <Box component='span' sx={deltaIndicatorStyle}>{format(rateDecimal.mul(100),2)}%</Box>}
  </>;
});

function EmptyMarketRow() {
  return (<TableRow sx={{ height: '100%'}}>
  <ValueTableCell mono sticky sx={sxEmptyRowStickyCell}></ValueTableCell>
  <ValueTableCell mono align="right"></ValueTableCell>
  <ValueTableCell mono align="right"></ValueTableCell>
  <ValueTableCell mono align="right"></ValueTableCell>
  <ValueTableCell mono align="right"></ValueTableCell>
  <ValueTableCell mono align="right"></ValueTableCell>
</TableRow>)
}

function MarketRow({
  market,marketRate,userMarketData,token,columnType,
  handleSelectedMarketId,isLoading,disableClick,selectedMarketId,
  selectOnMouseEnter, isMarketSticky, suggestedVolumeDP
}:{
  market?:BaseMarket,marketRate:LayoutMarketRate,userMarketData?:MarketData,token:Token,columnType:TColumnType,
  handleSelectedMarketId:(marketId:number)=>unknown,isLoading:boolean,disableClick:boolean,selectedMarketId?:number,
  selectOnMouseEnter?:false, isMarketSticky?: boolean, suggestedVolumeDP?: number
}){
  const {data:marketInfo} = apis.rate.useMarketInfo({market});
  const rate = (marketInfo?.rate || marketInfo?.midRate) ? (new Decimal(marketInfo?.rate || marketInfo?.midRate || 0)):undefined;
  let rate24 = marketRate?.midRateOrRate24 || new Decimal(marketRate.rate24 || marketRate.midRate24 || 0);
  const rateDelta24 = rate?.minus(rate24);
  const volume24 = new Decimal(marketRate.volume24||0);
  const position = userMarketData&&(new Decimal(userMarketData.position||0));
  const dv01 = userMarketData&&(new Decimal(userMarketData.dv01||0));
  const baseMarketForSymbolCode = getMarketSymbolFromMarketRate(marketRate,token);
  const marketCodeComponent = <>
    <MarketRollingDateLabel market={baseMarketForSymbolCode} withMarketCode withMarketDate/>
  </>;

  const rateComponent = <RateComponent rate={ marketInfo?.rate || marketInfo?.midRate }/>;
  const rateDelta24Component = <>{(!rateDelta24||rateDelta24.isZero())?'-':
    <Box component='span' sx={rateDelta24.isNegative()?sxDeltaNegative:sxDeltaPositive}>{format(rateDelta24.mul(100),2,true)}%</Box>}</>;
  const volume24Component = <>{volume24.isZero()?'-':format(volume24, suggestedVolumeDP)}</>;
  switch(columnType){
    case 'simple':
      return (
        <TableRow hover id='table-row-simple' onClick={()=>handleSelectedMarketId(marketRate.marketId)}
          onMouseEnter={()=>selectOnMouseEnter&&handleSelectedMarketId(marketRate.marketId)}
          sx={!disableClick?sxRowClickable:{}}
          data-active={marketRate.marketId===selectedMarketId}
        >
          { isMarketSticky ? 
            <ValueTableCell mono sticky={isMarketSticky}><Box sx={{ ...sxStickyInnerCell, ...sxStickyBodyCell } as SxProps}>{marketCodeComponent}</Box></ValueTableCell> : 
            <ValueTableCell mono>{marketCodeComponent}</ValueTableCell>
          }
          <ValueTableCell mono align="right">{rateComponent}</ValueTableCell>
          <ValueTableCell mono align="right">{rateDelta24Component}</ValueTableCell>
          <ValueTableCell mono align="right">{volume24Component}</ValueTableCell>
          <ValueTableCell mono align="right" id='value-cell-position'>
            {!position ? <span>-</span> :
              <Box sx={position.isNegative()?sxBorrowAmount:{}}>{position.isZero()?'-':formatTokenAmount(token,position)}</Box>
            }
          </ValueTableCell>
          <ValueTableCell mono align="right">
            {!dv01 ? <span>-</span> :
              <Box sx={dv01.isNegative()?sxBorrowAmount:{}}>{dv01.isZero()?'-':format(dv01,4)}</Box>
            }
          </ValueTableCell>
        </TableRow>
      );
    case 'public':
      return (
        <TableRow hover onClick={()=>handleSelectedMarketId(marketRate.marketId)}
          onMouseEnter={()=>selectOnMouseEnter&&handleSelectedMarketId(marketRate.marketId)}
          sx={!disableClick?sxRowClickable:{}}
          data-active={marketRate.marketId===selectedMarketId}
        >
          <ValueTableCell mono>{marketCodeComponent}</ValueTableCell>
          <ValueTableCell mono align="right">{rateComponent}</ValueTableCell>
          <ValueTableCell mono align="right">{rateDelta24Component}</ValueTableCell>
          <ValueTableCell mono align="right">{volume24Component}</ValueTableCell>
        </TableRow>
      );
    case 'compact':
      return (
        <TableRow hover onClick={()=>handleSelectedMarketId(marketRate.marketId)}
          onMouseEnter={()=>selectOnMouseEnter&&handleSelectedMarketId(marketRate.marketId)}
          sx={!disableClick?sxRowClickable:{}}
          data-active={marketRate.marketId===selectedMarketId}
        >
          <ValueTableCell mono>
            <MarketRollingDateLabel market={baseMarketForSymbolCode} />
            {marketRate.maturityDate!==undefined&&<Box component="span" sx={{opacity:0.5}}>
            </Box>}
          </ValueTableCell>
          <ValueTableCell mono align="right">{rateComponent}</ValueTableCell>
        </TableRow>
      );
    case 'user':
      return (
        <TableRow hover onClick={()=>handleSelectedMarketId(marketRate.marketId)}
          onMouseEnter={()=>selectOnMouseEnter&&handleSelectedMarketId(marketRate.marketId)}
          sx={!disableClick?sxRowClickable:{}}
          data-active={marketRate.marketId===selectedMarketId}
        >
          <ValueTableCell mono width="0" sx={sxTokenTableMaturityCell} align="left"><MarketRollingDateLabel market={market} withMarketCode withMarketDate/></ValueTableCell>
          <ValueTableCell mono width="100%" align="right">{rateComponent}</ValueTableCell>
          <ValueTableCell mono align="right">
            <>{!position ? (isLoading?<Box component='span' sx={sxCellLoading}>-</Box> : '-') :
              <Box sx={position.isNegative()?sxBorrowAmount:{}}>{position.isZero()?'-':formatTokenAmount(token,position)}</Box>
            }</>
          </ValueTableCell>
        </TableRow>
      );
  }
}

export default MarketSwitcher;