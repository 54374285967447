import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { useMemo, useState } from 'react';
import { withLocalGraphPosition } from 'src/Components/MarketGrids/GraphPositionProvider';
import { MARKETS_PAGE_TOKENS } from 'src/constants/app';
import { sxTokenSwitchItem } from 'src/styles/Chart';
import { sxBox, sxBoxTableContainerBordered, sxBoxTitle, sxColumnGridGraph, sxColumnGridTable, sxColumnsGrid, sxFilterBoxRow, sxTitleRow } from 'src/styles/General';
import { sxPageHeadingRow, sxPageHeadingSmall, sxPageSection } from 'src/styles/Page';
import { USER_TOKEN_GRAPH_DATE_BUCKET_TYPES, UserTokensGraphDateBucketType } from 'src/types';
import { formatUSDWithSign } from 'src/utils/numbers';
import { useAuth } from '../AuthProvider';
import apis from '../utils/apis';
import DefaultTokensSelector, { defaultTokens } from './DefaultTokensSelector';
import RateBucketsGraph from './RateBucketsGraph';
import ReloadDateText, { useReloadDateTextRefs } from './ReloadDateText';
import UserRateBucketsTable from './UserRateBucketsTable';
import UserTokensGraph from './UserTokensGraph';
import UserTokensTable from './UserTokensTable';
import { sxBorrowAmount } from 'src/styles/TokenTable';

const RateBucketsGraphWithLocalGraphPosition = withLocalGraphPosition(RateBucketsGraph);

type GraphType = 'Amounts'|'PV'|'DV01'|'Orders';
const GRAPH_TYPES:GraphType[] = ['Amounts','PV','DV01','Orders'];

type ValueUnitType = 'USD'|'LOCAL';
const VALUE_UNIT_TYPES:ValueUnitType[] = ['USD','LOCAL'];

function UserTokensSum({graphType,selectedTokenCodes=[],selectedAccountId}:{graphType:GraphType,selectedTokenCodes?:string[],selectedAccountId?:number}){
  const auth = useAuth();
  const {data:tokens} = apis.token.useMarketsPageTokens();
  const {data:userAccountPerformanceLive} = apis.user.useUserAccountPerformanceLive(auth,{ accountId:selectedAccountId });
  const groupedPositions = useMemo(() => userAccountPerformanceLive?.positionDetails, [userAccountPerformanceLive])
  const {data:mtms} = apis.user.useUserMarkToMarketByMarket(auth,{accountIds:selectedAccountId!==undefined?[selectedAccountId]:[]});
  const {data:allRateOrdersData} = apis.user.useUserAllRateOrders(auth,{accountId:selectedAccountId, pending:true, limit:10000});
  const sum = useMemo(()=>{
    const selectedTokenIds = tokens?.filter(t=>selectedTokenCodes.includes(t.code)).map(t=>t.tokenId);
    switch(graphType){
      case 'DV01': case 'Amounts': {
        return groupedPositions?.filter(d=>selectedTokenIds?.includes(d.tokenId)).reduce((sum,d)=>{
          const token = tokens?.find(t=>t.tokenId===d.tokenId);
          return sum.add(new Decimal(d[graphType==='DV01'?'dv01':'quantity']||0).mul(token?.price||0));
        },new Decimal(0));
      }
      case 'PV': {
        const mtm = mtms?.filter(d=>selectedTokenIds?.includes(d.tokenId)).reduce((sum,d)=>{
          const token = tokens?.find(t=>t.tokenId===d.tokenId);
          return sum.add(new Decimal(d.mtm||0).mul(token?.price||0));
        },new Decimal(0));
        const positions = groupedPositions?.filter(d=>selectedTokenIds?.includes(d.tokenId)).reduce((sum,d)=>{
          const token = tokens?.find(t=>t.tokenId===d.tokenId);
          return sum.add(new Decimal(d.quantity||0).mul(token?.price||0));
        },new Decimal(0));
        return mtm&&positions ? mtm.add(positions) : undefined;
      }
      case 'Orders': {
        return allRateOrdersData?.orders.filter(d=>selectedTokenCodes?.includes(d.instrumentId||'')).reduce((sum,d)=>{
          const token = tokens?.find(t=>t.code===d.instrumentId);
          return sum.add(new Decimal(d.quantity||0).mul(token?.price||0));
        },new Decimal(0));
      }
    }
  },[groupedPositions,allRateOrdersData,mtms,graphType,tokens,selectedTokenCodes])||new Decimal(0);
  return <Box component={'span'} sx={sum.lt(0) ? sxBorrowAmount : {}}>{formatUSDWithSign(sum)}</Box>;
}

function RiskPositionsSection({selectedAccountId}:{selectedAccountId?:number}){
  const auth = useAuth();
  const [selectedOrdersTokenCode,setSelectedOrdersTokenCode] = useState(MARKETS_PAGE_TOKENS[0]);
  const { data:tokens } = apis.token.useMarketsPageTokens();

  // filter selectors
  const [selectedTokenCodes,setSelectedTokenCodes] = useState(defaultTokens);
  const [selectedGraphType,setSelectedGraphType] = useState(GRAPH_TYPES[0]);
  const [selectedDateBucketType,setSelectedDateBucketType] = useState(USER_TOKEN_GRAPH_DATE_BUCKET_TYPES[0]);
  const [selectedValueUnitType,setSelectedValueUnitType] = useState(VALUE_UNIT_TYPES[0]);
  const showsUSDValue = selectedValueUnitType==='USD';

  // reload text reference 
  const reloadDateTextRefs = useReloadDateTextRefs();
  
  const {content,exclusiveDefaultTokensSelector} = useMemo(()=>{
    let graphAndDataKey:"position"|"mtm"|"pv"|"dv01"|undefined;
    let exclusiveDefaultTokensSelector = false;
    let graph;
    let table;
    switch(selectedGraphType){
      case 'Amounts': graphAndDataKey = "position"; break;
      case 'PV': graphAndDataKey = "pv"; break;
      // case 'MTM': graphAndDataKey = "mtm"; break;
      case 'DV01': graphAndDataKey = "dv01"; break;
      case 'Orders': exclusiveDefaultTokensSelector = true; break;
    }
    if(graphAndDataKey!==undefined){
      graph = <UserTokensGraph graphKey={graphAndDataKey} showsUSDValue={showsUSDValue} dateBucketType={selectedDateBucketType} tokenCodes={selectedTokenCodes} padGraph reloadDateTextRefs={reloadDateTextRefs} showsEmptyStateCurtain hasMaxHeight={false} watermarkType='top-center' minHeight={300} withLegend/>;
      table = <UserTokensTable dataKey={graphAndDataKey} showsUSDValue={showsUSDValue} dateBucketType={selectedDateBucketType}/>;
    }else{
      graph = <RateBucketsGraphWithLocalGraphPosition token={tokens?.filter(t=>t.code===selectedOrdersTokenCode)[0]} type="userOrders" showsUSDValue={showsUSDValue} dateBucketType={selectedDateBucketType} padGraph showsEmptyStateCurtain/>;
      table = <UserRateBucketsTable showsUSDValue={showsUSDValue} dateBucketType={selectedDateBucketType} reloadDateTextRefs={reloadDateTextRefs}/>;
    }
    const content = (
      <Box>
        <Box sx={{...sxTitleRow, borderWidth: '0', borderBottomWidth: '1px', borderBottomColor: 'border.dark', borderBottomStyle: 'solid'}}>
            <Typography variant="h2" sx={sxPageHeadingSmall}>Total:&nbsp;
              <UserTokensSum graphType={selectedGraphType} selectedTokenCodes={selectedTokenCodes} selectedAccountId={selectedAccountId}/>
            </Typography>
            <Box sx={{display:'flex',columnGap:'8px',alignItems:'center'}}>
              View in <ValueUnitSelector selectedType={selectedValueUnitType} onChange={selectedType=>setSelectedValueUnitType(selectedType)}/>
            </Box>
          </Box>
      
      <Box sx={{...sxColumnsGrid,gridTemplateColumns:'7fr 5fr', gap: '0'}}>
        <Box sx={{...sxColumnGridTable, m: '0', borderWidth: '0'}}>
          <Box sx={{borderBottomWidth: '1px', borderBottomColor: 'border.dark', borderBottomStyle: 'solid', marginBottom: '-1px'}}>{table}</Box>
        </Box>
        <Box sx={{...sxColumnGridGraph, borderLeftWidth: '1px', borderLeftColor: 'border.dark', borderLeftStyle: 'solid'}}>
          <Box sx={{p:'16px'}}><Typography variant="h2" sx={sxPageHeadingSmall}>{selectedGraphType} {selectedDateBucketType}</Typography></Box>
          <Box sx={{flex:1}}>{graph}</Box>
        </Box>
      </Box>
      </Box>
    );
    return {content,exclusiveDefaultTokensSelector};
  },[selectedDateBucketType,selectedGraphType,selectedOrdersTokenCode,selectedTokenCodes,selectedValueUnitType,showsUSDValue,tokens,selectedAccountId,reloadDateTextRefs]);

  return (
    <Box sx={sxPageSection}>
      <Box sx={sxPageHeadingRow}>
        <Typography variant="h1" sx={sxPageHeadingSmall}>My Risk Positions</Typography>
        <ReloadDateText reloadDateTextRefs={reloadDateTextRefs}/>
      </Box>
      {/* Graphs */}
      <Box sx={{...sxBox,p: '0', marginBottom:'16px'}}>
        <Box sx={{...sxBoxTitle, mx: '0', p: '12px'}}>
          <Box sx={{...sxFilterBoxRow, marginBottom: '0'}}>
            <DefaultTokensSelector exclusive={exclusiveDefaultTokensSelector} onChange={setSelectedTokenCodes}/>
            <GraphTypeSelector selectedType={selectedGraphType} onChange={selectedType=>setSelectedGraphType(selectedType)}/>
            <DateBucketTypeSelector selectedType={selectedDateBucketType} onChange={selectedType=>setSelectedDateBucketType(selectedType)}/>
          </Box>
        </Box>
        <Box>{content}</Box>
      </Box>
    </Box>
  );
}

function GraphTypeSelector({ selectedType, onChange }:{ selectedType:GraphType, onChange?:(selectedType:GraphType)=>any }){
  return (
    <ButtonGroup variant="outlined" aria-label="outlined primary button group">
    {GRAPH_TYPES.map((type,i)=>{
      const inactive = selectedType!==type;
      return (
        <Button key={i} sx={sxTokenSwitchItem} variant={inactive?'outlined':'contained'} 
          onClick={()=>{
            onChange&&onChange(type);
          }}
        >{type}</Button>
      )
    })}
    </ButtonGroup>
  );
}

function DateBucketTypeSelector({ selectedType, onChange }:{ selectedType:UserTokensGraphDateBucketType, onChange?:(selectedType:UserTokensGraphDateBucketType)=>any }){
  return (
    <ButtonGroup variant="outlined" aria-label="outlined primary button group">
    {USER_TOKEN_GRAPH_DATE_BUCKET_TYPES.map((type,i)=>{
      const inactive = selectedType!==type;
      return (
        <Button key={i} sx={sxTokenSwitchItem} variant={inactive?'outlined':'contained'} 
          onClick={()=>{
            onChange&&onChange(type);
          }}
        >{type}</Button>
      )
    })}
    </ButtonGroup>
  );
}

function ValueUnitSelector({ selectedType, onChange }:{ selectedType:ValueUnitType, onChange?:(selectedType:ValueUnitType)=>any }){
  return (
    <ButtonGroup variant="outlined" aria-label="outlined primary button group">
    {VALUE_UNIT_TYPES.map((type,i)=>{
      const inactive = selectedType!==type;
      return (
        <Button key={i} sx={sxTokenSwitchItem} variant={inactive?'outlined':'contained'} 
          onClick={()=>{
            onChange&&onChange(type);
          }}
        >{type}</Button>
      )
    })}
    </ButtonGroup>
  );
}

export default RiskPositionsSection;