import { Box, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Decimal from "decimal.js";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BreadcrumbsBack from "src/Components/BreadcrumbsBack";
import PublicTradeHistoryTable from "src/Components/PublicTradeHistoryTable";
import TokenIcon from "src/Components/TokenIcon";
import AccountAddress from "src/Components/WalletAddress";
import { sxBox, sxBoxCompactInner, sxBoxTitle } from "src/styles/General";
import { sxContentWide } from "src/styles/Page";
import { sxTable, sxVerticalSeparator } from "src/styles/Table";
import { sxLabel } from "src/styles/TokenTable";
import { AccountsLossGivenDefault } from "src/types";
import apis from "src/utils/apis";
import { format, formatUSDWithSign, isValidDecimal } from "src/utils/numbers";
import { paths } from "src/utils/paths";

// TODO refactor and reuse ExploreAccountsPanel code
const sxPadBottom:SxProps = {
  "td:is(td)":{
    paddingBottom: 2,
  }
}
const sxNoPadRight:SxProps = {
  paddingRight: 0, 
};
const sxNoPadLeft:SxProps = {
  paddingLeft: 0, 
};
const sxVerticalSeparatorNoPadLeft:SxProps ={
  ...sxNoPadLeft, ...sxVerticalSeparator
}

const coverageLimitRange = (d:Decimal)=>Decimal.max(-100,Decimal.min(100,d));
const formatLossPoint = (lossPoint:Decimal)=>lossPoint.toDP(1).lte(-100)||lossPoint.toDP(1).gte(0)?'-':formatPercent(lossPoint);
const formatPercent = (p:Decimal)=>!isValidDecimal(p)?'-':`${format(coverageLimitRange(p).mul(100).toDP(1),1)}%`;

function UserPublicProfile(){
  const { userAddress } = useParams();
  const navigate = useNavigate();
  useEffect(()=>{ if(!userAddress){ navigate(paths.explorePage('trades')); } },[userAddress]);
  const [priceChange,setPriceChange] = useState(0.9);
  const {data:allTokens} = apis.token.useTokens();
  const {isLoading,data:accountsLossGivenDefault} = apis.infinity.useAccountsLossGivenDefault({priceChange,userAddress});
  const tokens = Object.keys(accountsLossGivenDefault||{}).map((tokenIdString:string)=>allTokens?.filter(t=>t.tokenId==Number(tokenIdString))[0]).filter(t=>t!=undefined);
  const priceChangePercent = Math.round((1-priceChange)*100);
  const accountProfileMap:{[address:string]:{[tokenIdString:string]:AccountsLossGivenDefault}} = {};
  accountsLossGivenDefault&&Object.keys(accountsLossGivenDefault).map((tokenIdString:string)=>{
    accountsLossGivenDefault[tokenIdString].map(accountData=>{
      accountProfileMap[accountData.address] = accountProfileMap[accountData.address]||{};
      accountProfileMap[accountData.address][tokenIdString] = accountData;
    });
  });
  const getAssetLiabilityRow = ()=>{
    if(!userAddress) return;
    const tokenEntries = accountProfileMap[userAddress];
    if(!tokenEntries) return;
    let originalAsset = new Decimal(0);
    let originalLiability = new Decimal(0);
    let newAsset = new Decimal(0);
    let newLiability = new Decimal(0);
    let healthscoreSum = new Decimal(0);
    Object.keys(tokenEntries).map(tokenIdString=>{
      const entry = tokenEntries[tokenIdString];
      originalAsset = originalAsset.add(new Decimal(entry.originalAsset));
      originalLiability = originalLiability.add(new Decimal(entry.originalLiability));
      newAsset = newAsset.add(new Decimal(entry.newAsset));
      newLiability = newLiability.add(new Decimal(entry.newLiability));
      healthscoreSum = healthscoreSum.add(new Decimal(entry.healthscore));
    });
    const healthscore = healthscoreSum.div(tokens.length);
    const coverage = (originalAsset.div(originalLiability.abs()));
    return (
    <TableRow hover>
      <TableCell width={0}></TableCell>
      <TableCell>{formatUSDWithSign(originalAsset,true)}</TableCell>
      <TableCell>{formatUSDWithSign(originalLiability.abs(),true)}</TableCell>
      <TableCell>{formatPercent(healthscore)}</TableCell>
      <TableCell sx={sxVerticalSeparator}>{formatPercent(coverage)}</TableCell>
      {tokens.map((token,i)=>{
        const tokenIdString = `${token!.tokenId}`;
        const tokenData = tokenEntries[tokenIdString];
        if(!tokenData) return (<TableCell key={token!.code}>-</TableCell>);
        const originalCoverage = (new Decimal(tokenData.originalAsset).div(new Decimal(tokenData.originalLiability).abs()));
        const newCoverage = (new Decimal(tokenData.newAsset).div(new Decimal(tokenData.newLiability).abs()));
        const deltaCoverage = originalCoverage.sub(newCoverage);
        const lossPoint = originalCoverage.sub(100).div(newCoverage).mul(1-priceChange).mul(-1);
        return (<>
          <TableCell key={`${token!.code}-delta`} sx={sxNoPadRight}>
            {formatPercent(deltaCoverage)}
          </TableCell>
          <TableCell key={`${token!.code}-lossPoint`} sx={i==tokens.length-1?sxVerticalSeparatorNoPadLeft:sxNoPadLeft} align='left'>
            <Box sx={sxLabel}>{formatLossPoint(lossPoint)}</Box>
          </TableCell>
        </>);
      })}
    </TableRow>
    )
  }
  if(!userAddress){
    return null;
  }
  return (
    <Box sx={sxContentWide}>
      <BreadcrumbsBack/>
      <Box sx={sxBox}>
        <AccountAddress address={userAddress} withAvatar avatarPosition='left' avatarSize={64} avatarScale={4} disableLink sx={{fontSize:'1.5rem',fontWeight:700}}/>
      </Box>
      <Box sx={sxBox}>
        <Box sx={sxBoxTitle}>Overview</Box>
        <Box sx={sxBoxCompactInner}>
          <TableContainer>
            <Table sx={sxTable}>
              <TableHead><TableRow><TableCell width={0}></TableCell><TableCell colSpan={4} sx={sxVerticalSeparator}>Baseline</TableCell><TableCell colSpan={tokens.length*2}>Price Sensitivity ({priceChangePercent}% Delta)</TableCell></TableRow></TableHead>
              <TableBody>
                <TableRow hover>
                <TableCell width={0}></TableCell><TableCell>Asset</TableCell><TableCell>Liability</TableCell><TableCell>H/S</TableCell><TableCell sx={sxVerticalSeparator}>Coverage</TableCell>
                  {tokens.map((t,i)=>(
                    <TableCell key={i} sx={i==tokens.length-1?sxVerticalSeparator:{}} colSpan={2}><TokenIcon token={t}/>{t?.code}</TableCell>
                  ))}
                </TableRow>
                {getAssetLiabilityRow()}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Box sx={sxBox}>
        <Box sx={sxBoxCompactInner}>
          <PublicTradeHistoryTable userAddress={userAddress}/>
        </Box>
      </Box>
    </Box>
  );
}

export default UserPublicProfile;