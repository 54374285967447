import { Box, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from 'src/utils/common';
import PortfolioLPTransactionsPanel from '../Components/PortfolioLPTransactionsPanel';
import WalletPrompt from '../Components/WalletPrompt';
import { sxPageHeading } from '../styles/Page';

function PortfolioLPHistory(){
  usePageTitle(['LP Position','Portfolio']);
  const navigate = useNavigate();
  return (
    <>
      <Box>
        <WalletPrompt prompt="Your Lending, Borrowing, and Collateral positions will appear here. Set up different pools or portfolios to take advantage of isolated or more targeted LTVs."/>
        <Box sx={{marginBottom:2}}>
          <Typography variant="h1" sx={sxPageHeading}>LP Position Transaction History</Typography>
          <Link sx={{cursor:'pointer'}} onClick={()=>navigate(-1)}>(&lt; Back to Portfolio Page)</Link>
        </Box>
        <PortfolioLPTransactionsPanel/>
      </Box>
    </>
  );
}

export default PortfolioLPHistory;