import axios, { AxiosError } from "axios";
// import ndjsonStream from 'can-ndjson-stream';
import ndjson from 'fetch-ndjson';
import moment from "moment";
import { API_BASE } from 'src/constants/app';
import { ZodSchema, z } from "zod";
import { AuthContextType } from '../../AuthProvider';
import { AccountWalletToken, Token, TokenWithBalance } from "../../types";
import { GeneralError, parseError, publishCloseSnackbar } from "../common";
axios.defaults.withCredentials = true;

export interface APIResponse {
  errorCode?: number,
  errorMsgKey?: string,
  success: boolean,
  data?: APIErrorData|any,
  id?: number, // WS calls
}
interface APIErrorData {
  reason: string
}

const getAuthorizationHeader = async (auth?:AuthContextType) => {
  if (!auth) return {}
  try {
    const { token, exp = 0 } = auth?.accessToken ?? {};
    const currentTime = moment().unix()
    let headers = {}
    if (!token || exp < currentTime) {
      const url = `${API_BASE}/api/user/login/refresh`
      try {
        const res = await axios.post(url, {headers:{
          'content-type':'application/json'
        }});
        auth.updateAccessToken({
          token: res.data.data.accessToken.token,
          exp: res.data.data.accessToken.data.payload.exp
        })
        headers = {
          'Authorization': 'Bearer ' + res.data.data.accessToken.token
        }
      } catch (error) {
        await auth.signout()
        await handleError(url,error,auth);
        throw error;
      }
    } else {
      headers = {
        'Authorization': 'Bearer ' + token
      }
    }
    return headers
  } catch(error) {
    throw error;
  }
}

export function stream(onData:Function,url:string,params?:any,auth?:AuthContextType):void{
  (async()=>{
    try {
      const res  = await fetch(API_BASE+url+'?'+qs(params));
      let reader = res.body!.getReader();
      let gen = ndjson(reader);
      while (true) {
        let { done, value } = await gen.next();
        // console.log(`done=${done}, value=${JSON.stringify(value)}`);
        onData(value);
        if (done) {
          return;
        }
      }
    }catch(error:any){
      if(typeof error == 'string') throw error;
      if(typeof error == 'undefined') throw new Error('unknown error');
    }
  })();
}
export async function streamWait(url:string,params?:any,auth?:AuthContextType):Promise<APIResponse>{
  let dataset = [];
  try {
    const res  = await fetch(API_BASE+url+'?'+qs(params));
    let reader = res.body!.getReader();
    let gen = ndjson(reader);
    while (true) {
      let { done, value } = await gen.next();
      // console.log(`done=${done}, value=${JSON.stringify(value)}`);
      if (done) {
        return {errorCode:0,errorMsgKey:'',success:true,data:{marketHistory:dataset}};
      }
      dataset.push(value);
    }
  }catch(error:any){
    return {errorCode:0,errorMsgKey:error as string|'unknown',success:false};
  }
}
export async function get(url:string,params?:any,auth?:AuthContextType):Promise<APIResponse>{
  if(auth&&!auth.user) throw new Error(`API Error: Awaiting login at ${url}`);
  let data:APIResponse;
  try {
    const headers = await getAuthorizationHeader(auth)
    const res = await axios.get(API_BASE+url,{ headers , params });
    data = res?.data;
  }catch(error:any){
    await handleError(url,error,auth);
    throw error;
  }
  await handleError(url,data,auth);
  publishCloseSnackbar(url);
  return data;
}
export async function postFD(url:string,params?:any,auth?:AuthContextType):Promise<APIResponse>{
  if(auth&&!auth.user) throw new Error(`API Error: Awaiting login at ${url}`);
  let data:APIResponse;
  try{
    const headers = await getAuthorizationHeader(auth)
    const res = await axios.post(API_BASE+url,qs(params), { headers });
    data = res?.data;
  }catch(error:any){
    await handleError(url,error,auth);
    throw error;
  }
  await handleError(url,data,auth);
  publishCloseSnackbar(url);
  return data;
}
export async function putFD(url:string,params?:any,auth?:AuthContextType):Promise<APIResponse>{
  if(auth&&!auth.user) throw new Error(`API Error: Awaiting login at ${url}`);
  let data:APIResponse;
  try{
    const headers = await getAuthorizationHeader(auth)
    const res = await axios.put(API_BASE+url,qs(params), { headers });
    data = res?.data;
  }catch(error:any){
    await handleError(url,error,auth);
    throw error;
  }
  await handleError(url,data,auth);
  publishCloseSnackbar(url);
  return data;
}
export async function doDelete(url:string,auth?:AuthContextType):Promise<APIResponse>{
  if(auth&&!auth.user) throw new Error(`API Error: Awaiting login at ${url}`);
  let data:APIResponse;
  try{
    const headers = await getAuthorizationHeader(auth)
    const res = await axios.delete(API_BASE+url, { headers });
    data = res?.data;
  }catch(error:any){
    await handleError(url,error,auth);
    throw error;
  }
  await handleError(url,data,auth);
  publishCloseSnackbar(url);
  return data;
}
export async function post(url:string,params?:any,auth?:AuthContextType):Promise<APIResponse>{
  if(auth&&!auth.user) throw new Error(`API Error: Awaiting login at ${url}`);
  let data:APIResponse;
  try{
    const headers = await getAuthorizationHeader(auth)
    const res = await axios.post(API_BASE+url,params,{headers:{
      'content-type':'application/json',
      ...headers
    }});
    data = res?.data;
  }catch(error:any){
    await handleError(url,error,auth);
    throw error;
  }
  await handleError(url,data,auth);
  publishCloseSnackbar(url);
  return data;
}
export async function put(url:string,params?:any,auth?:AuthContextType):Promise<APIResponse>{
  if(auth&&!auth.user) throw new Error(`API Error: Awaiting login at ${url}`);
  let data:APIResponse;
  try{
    const headers = await getAuthorizationHeader(auth)
    const res = await axios.put(API_BASE+url,params,{headers:{
      'content-type':'application/json',
      ...headers
    }});
    data = res?.data;
  }catch(error:any){
    await handleError(url,error,auth);
    throw error;
  }
  await handleError(url,data,auth);
  publishCloseSnackbar(url);
  return data;
}
async function handleError(url:string,error:any=undefined,auth?:AuthContextType){
  if(error===undefined) return;
  let apiResponse:APIResponse|undefined;
  if((error as AxiosError)?.response!==undefined){
    const { response } = error;
    apiResponse = response.data;
  }else if((error as APIResponse).errorCode!==undefined){
    apiResponse = error; 
  }
  if(apiResponse){
    const { errorCode, errorMsgKey } = apiResponse;
    if(errorCode!==undefined){
      switch(errorCode){
        case 1000:
        case 1001:
        if(errorMsgKey==='error.permission'){
          // console.log('reset auth session',auth);
          const retried = await auth?.sessionExpires();
          if(retried) throw new Error(); // silent error
          throw new GeneralError({type:'api',key:url,message:`Permission Denied at ${url}`});
        }
        break;
      }
      throw new GeneralError({type:'api',key:url,message:`${errorMsgKey||'unknown error'} - ${url}`});
    }
    const {message} = parseError(error);
    throw new GeneralError({type:'api',key:url,message:`${message} - ${url}`});
  }
};

function qs(obj:Record<string,string>):string{
  return (new URLSearchParams(obj)).toString();
};

export function containsToken(t: Token | TokenWithBalance | AccountWalletToken, tokenId: number | undefined) {
  if (t.tokenType === 2) {
    if (t["accountId"]!==undefined) {
      const wt = t as AccountWalletToken;
      const erc721Tokens = wt?.erc721Tokens;
      return erc721Tokens?.some(wt721 => {
        const id = parseInt(t.tokenId.toString() + wt721.erc721TokenId).toString() || '';
        return tokenId?.toString() === id;
      }) || false;
    } else { // tokenWithBalance
      const tb = t as TokenWithBalance;
      const erc721Tokens = tb.erc721Tokens;
      return erc721Tokens?.some(erc721Id => {
        const id = parseInt(t.tokenId.toString() + erc721Id).toString() || '';
        return tokenId?.toString() === id;
      }) || false;
    }
  }
  return tokenId === t.tokenId;
}

/* safeParse with error handling */
export function zp<T>(zo:ZodSchema<T>|ZodSchema<any>,o:any,throwsError=true,silent=false):T|undefined{
  const r = zo.safeParse(o);
  if(r.success) return r.data; 
  const msg = `Error when parsing API response: ${r.error.issues.map(({code,path,message})=>`[${code}] ${message} at [${path.join(',')}]`).join(';')}`;
  if(!silent) console.error(msg);
  if (throwsError) throw new Error(msg);
}

export function zFallback<T>(value:T){
  return z.any().transform(()=>value);
}
export function zWithFallback<T>(schema: z.ZodType<T>, fallback: T){
  return z.preprocess(
    (value) => {
      const parseResult = schema.safeParse(value);
      if (parseResult.success) return value;
      return fallback;
    },
    z.custom((v) => true)
  );
}