import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import Decimal from 'decimal.js';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { sxMonoFont } from 'src/styles/General';
import theme from 'src/utils/theme';
import { useAuth } from '../AuthProvider';
import {
  sxChartTable
} from '../styles/TokenTable';
import {
  ETokenValuationProtocol,
  EUserAccountType
} from '../types';
import apis from '../utils/apis';
import { format } from '../utils/numbers';
import { ISelectedTransferAccountToken } from './CashPositionsPanel';

// TODO update to be grouped by token providers (infinity, basic, ...protocols)
enum ECashPositionTokenType { BASIC="Basic Tokens", COMPLEX="Yield-Bearing Tokens"};
type handleClickAccountFn = (selectedTransferAccountToken:ISelectedTransferAccountToken)=>void;
interface CashPosition {
  tokenId: number;
  tokenType: ECashPositionTokenType;
  minter?: string;
  price: Decimal;
  chainBalance?: Decimal;
  currentAccountBalance: Decimal;
  tradingAccountsBalance: Decimal;
}
interface HeadCell {
  disablePadding: boolean;
  id: keyof CashPosition;
  label: string;
}
const headCells: readonly HeadCell[] = [
  {
    id: 'tokenId',
    disablePadding: false,
    label: 'Tokens',
  },
  {
    id: 'minter',
    disablePadding: false,
    label: 'Minter',
  },
  {
    id: 'chainBalance',
    disablePadding: false,
    label: 'Your Wallet',
  },
  {
    id: 'currentAccountBalance',
    disablePadding: false,
    label: 'Current Account',
  },
  {
    id: 'tradingAccountsBalance',
    disablePadding: false,
    label: 'Trading Account',
  },
];

function CashPositionsChart(){
  const auth = useAuth();
  const { data:tokens } = apis.token.useTokens();
  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  const { data:chainTokens } = apis.user.useChainTokens(auth,tokens);
  // parse user data into cashPositionMap
  const cashPositionMap:Map<number,CashPosition> = new Map();
  const getCashPositionMapEntry = (tokenId:number)=>{
    return cashPositionMap.get(tokenId)||{
      tokenId: tokenId,
      minter: '',
      tokenType: 'basic',
      price: new Decimal(0),
      chainBalance: undefined,
      currentAccountBalance: new Decimal(0),
      tradingAccountsBalance: new Decimal(0),
    };
  };
  const updateCashPositionMap = (tokenId:number,update:Partial<CashPosition>)=>{
    const p = getCashPositionMapEntry(tokenId);
    cashPositionMap.set(tokenId,Object.assign(p,update));
  };
  // iterate userAccounts to fill in positions
  userAccounts?.map(uw=>{
    const {type} = uw;
    uw.tokens.map(tw=>{
      const { tokenId, quantity } = tw;
      let { currentAccountBalance, tradingAccountsBalance } = getCashPositionMapEntry(tokenId);
      if(type==EUserAccountType.CURRENT){
        currentAccountBalance = currentAccountBalance.add(new Decimal(quantity));
        updateCashPositionMap(tokenId,{currentAccountBalance})
      }else{
        tradingAccountsBalance = tradingAccountsBalance.add(new Decimal(quantity));
        updateCashPositionMap(tokenId,{tradingAccountsBalance})
      }
    });
  });
  // iterate tokens for USD values
  tokens?.map(ct=>{
    const { tokenId, tokenValuationProtocol, price } = ct;
    const tokenType = tokenValuationProtocol==0?ECashPositionTokenType.BASIC:ECashPositionTokenType.COMPLEX;
    updateCashPositionMap(tokenId,{tokenType,price:new Decimal(price||0)});
  });
  // iterate chainTokens for walletBalance 
  chainTokens?.map(ct=>{
    const { tokenId, balance } = ct;
    let { chainBalance } = getCashPositionMapEntry(tokenId);
    chainBalance = (chainBalance||new Decimal(0)).add(new Decimal(balance||0));
    updateCashPositionMap(tokenId,{chainBalance});
  });
  // map cashpositions into type list - type->cashPosition[]
  const cashPositionTypeList:Map<ECashPositionTokenType,CashPosition[]> = new Map();
  for(let [tokenId,cashPosition] of cashPositionMap){
    const {tokenType} = cashPosition;
    const list = cashPositionTypeList.get(tokenType)||[];
    list.push(cashPosition);
    cashPositionTypeList.set(tokenType,list);
  }
  // iterate for token rows & sums
  let chainBalanceUsdValueSum:Decimal|undefined;
  let currentAccountBalanceUsdValueSum = new Decimal(0);
  let tradingAccountsBalanceUsdValueSum = new Decimal(0);
  tokens&&userAccounts&&Array.from(cashPositionTypeList.keys()).map((tokenType, index) => {
    const tokenRows = cashPositionTypeList.get(tokenType)?.map((cashPosition,index2)=>{
      const { tokenId, price, chainBalance, currentAccountBalance, tradingAccountsBalance } = cashPosition;
      const token = tokens.filter(token=>token.tokenId==tokenId)[0];
      // we don't need erc721 id in cash position table for now
      if (token && token.erc721TokenId) token.erc721TokenId = '';
      const complexTokenPrice: Decimal = token.underlyingAssets?.map(ua => {
        const underlying = tokens.filter(token=>token.tokenId==ua.underlyingTokenId)[0];
        return new Decimal(underlying.price || '0').mul(new Decimal(ua.underlyingTokenIndex || '0'));
      })
          .reduce((prev, next) => prev.add(next), new Decimal('0')) || new Decimal('0');
      const newPrice = tokenType == ECashPositionTokenType.COMPLEX ? complexTokenPrice:price;
      cashPosition.price = newPrice;
      // for atoken on chain usd value, it's different from infinity
      if(chainBalance){
        const chainTokenPrice = token.tokenValuationProtocol==ETokenValuationProtocol.AAVE_V2? new Decimal(tokens.find(t => t.tokenId==token.underlyingAssets?.at(0)?.underlyingTokenId)?.price || '0') : price;
        chainBalanceUsdValueSum = (chainBalanceUsdValueSum||new Decimal(0)).add(chainBalance.mul(chainTokenPrice));
      }
      currentAccountBalanceUsdValueSum = currentAccountBalanceUsdValueSum.add(currentAccountBalance.mul(newPrice));
      tradingAccountsBalanceUsdValueSum = tradingAccountsBalanceUsdValueSum.add(tradingAccountsBalance.mul(newPrice));
      if (token.tokenType==2) { // update erc721 token usd value
          userAccounts.map(uw => {
            const {type} = uw;
            const tw = uw.tokens.filter(token => token.tokenId==tokenId)[0];
            const totalValueUsd = tw?.erc721Tokens?.map(wt721 => {
              const tokenValueUsd = wt721?.underlyingAssets?.map(ua => {
                const ut = tokens?.filter(t => t.tokenId == ua.underlyingTokenId)[0];
                const underlyingPrice = new Decimal(ut?.price || '0');
                const underlyingTokenAmount = new Decimal(ua.underlyingTokenIndex || '0');
                return underlyingPrice.mul(underlyingTokenAmount);
              }).reduce((prev, next) => prev.add(next), new Decimal(0)) || new Decimal('0');
              return tokenValueUsd;
            }).reduce((previousValue, currentValue) => previousValue.add(currentValue), new Decimal('0')) || new Decimal('0');
            if(type==EUserAccountType.CURRENT){
              currentAccountBalanceUsdValueSum = currentAccountBalanceUsdValueSum.add(totalValueUsd);
            }else{
              tradingAccountsBalanceUsdValueSum = tradingAccountsBalanceUsdValueSum.add(totalValueUsd);
            }
        });
      }
    })||[];
  });
  // data for pieChart
  const pieChartData = [{name:'Current Account',value:currentAccountBalanceUsdValueSum.toNumber()},{name:'Trading Accounts',value:tradingAccountsBalanceUsdValueSum.toNumber()}]
  return (<>
  <TableContainer component={Box}>
    <Table sx={sxChartTable} aria-label="Chart" size="medium">
      <TableBody><TableRow hover><TableCell><Box sx={{display:'flex',alignItems:'center'}}>
        <ResponsiveContainer aspect={1} width={160} height="100%">
          <PieChart>
            <Pie data={pieChartData} cx="50%" cy="50%" outerRadius="100%" fill={theme.palette.primary.main} dataKey="value"
              labelLine={false}
              // label={({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
              //   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
              //   const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
              //   const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);
              //   return (
              //     <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
              //       {`${(percent * 100).toFixed(0)}%`} - {pieChartData[index].name}
              //     </text>
              //   );
              // }}
            >
              {pieChartData.map((entry, index) => (
                <Cell key={`cell-${index}`} stroke="transparent" fill={index%2==0?theme.palette.primary.main:theme.palette.warning.main} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer></Box>
      </TableCell><TableCell><Box>
        <Box component={'label'}>Current Account</Box>
        <Box sx={sxMonoFont}>${format(currentAccountBalanceUsdValueSum,0)}</Box>
        <Box>&nbsp;</Box>
        <Box component={'label'}>Trading Account</Box>
        <Box sx={sxMonoFont}>${format(tradingAccountsBalanceUsdValueSum,0)}</Box>
      </Box></TableCell></TableRow></TableBody>
    </Table>
  </TableContainer>
  </>);
}

export default CashPositionsChart;