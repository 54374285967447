import { Box, SxProps } from '@mui/material';
import WalletPrompt from './WalletPrompt';

const sxInline:SxProps = {
  display:'inline-block',verticalAlign:'bottom'
};
const sxWrapper:SxProps = {
  position:'absolute',top:0,left:0,right:0,bottom:0
};
const sxInner:SxProps = {
  bgcolor:'#00000066',position:'absolute',top:0,left:0,right:0,bottom:0,display:'grid',alignItems:'center',justifyContent:'center',zIndex:100
};

function ConnectWalletPromptPanel({inline=false}:{inline?:boolean}) {
  return (
  <Box sx={inline?sxInline:sxWrapper}>
    <Box sx={sxInner}>
      <WalletPrompt loginButton={true}/>
    </Box>
  </Box>);
}

export default ConnectWalletPromptPanel;
