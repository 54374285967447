// TODO add zod typeguard
import { AuthContextType } from 'src/AuthProvider';
import { EOrderSide, RateTwapOrder } from '../../types';
import { get, post, putFD } from './common';

/**
 * Create rate market Twap order
 * */
export async function createTwapOrder(params:ICreateTwapParams):Promise<RateTwapOrder> {
  const { data } = await post(`/api/rate/twap/create`, params);
  const { twapOrder } = data;
  if(twapOrder != null && twapOrder != undefined)
      twapOrder.orderDate = twapOrder.createDate; // TODO
  return twapOrder;
}

export interface ICreateTwapParams {
  marketId: number; //
  accountId: number; //
  side: EOrderSide; //  - 1 for borrow, 0 for lend
  durationMin: number;
  quantity: string; //
};

/**
 * fetch Twap orders
 * */
export async function fetchUserTwapOrders(auth:AuthContextType,{accountId}:{accountId?:number}={}):Promise<RateTwapOrder[]> {
  const { data } = await get(`/api/rate/twaps`,{accountId},auth);
  const { twapOrders }:{ twapOrders:RateTwapOrder[] } = data;
  return twapOrders;
};
  
/**
 * terminate Twap order
 * */
export async function terminateTwapOrder(twapId:number):Promise<number> {
  const { data } = await putFD(`/api/rate/twap/terminate`, {twapId:twapId});
  const { result } = data;
  return result;
}
