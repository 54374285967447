import { Box, Button, Link } from '@mui/material';
import { useQueryClient } from "@tanstack/react-query";
import { useState } from 'react';
import useAppStore from 'src/store';
import { useAuth } from '../AuthProvider';
import { styleBoxPanel, sxBox } from '../styles/General';
import LoadingIcon from './Loading';
import LoginDialog from './LoginDialog';

function WalletPrompt({prompt=`You haven't connected a wallet.`,loginButton=false}:{prompt?:string,loginButton?:boolean}){
  const auth = useAuth();
  const appstate = useAppStore();
  const queryClient = useQueryClient();
  const [ showingDialog, setShowingDialog ]:[boolean,Function] = useState(false);

  function handleClickOpenConnect(event: React.MouseEvent) {
    setShowingDialog(true);
  }
  function handleCloseConnect(){
    setShowingDialog(false);
  }

  if(loginButton){
    if(appstate.loginState==='RECOVERING'){
      return <LoadingIcon size={30}/>;
    }
    return (<>
      <Button component={Link} color="secondary" variant="contained" onClick={handleClickOpenConnect}>Connect Wallet</Button>
      <LoginDialog handleClose={handleCloseConnect} showing={showingDialog}/>
    </>);
  }

  return (
    <>
      {!auth.user?(
        <Box sx={sxBox}>
          <Box sx={{...styleBoxPanel,flexDirection:'row'}}>
            <Box>{prompt}</Box>
            <Button  onClick={handleClickOpenConnect}>Connect Wallet</Button>
            <LoginDialog handleClose={handleCloseConnect} showing={showingDialog}/>
          </Box>
        </Box>
      ):null}
    </>
  );
}


export default WalletPrompt;