import { UseQueryResult, useQueries, useQuery } from "@tanstack/react-query";
import Decimal from 'decimal.js';
import moment from 'moment';
import { AuthContextType } from 'src/AuthProvider';
import { z } from 'zod';
import { 
  BaseMarket,
  BaseRateOrder,
  BaseRateOrderSchema,
  CancelledOrderResponseItem,
  EMarketCategory,
  EOrderSide,
  ERateOrderType,
  FixedRateOrderData,
  FixedRateOrderSchema,
  FixedRateOrderWSSchema,
  MarketFixedRate,
  MarketFixedRateSchema,
  MarketFixedRateWithSize,
  MarketFixedRateWithSizeSchema,
  MarketHistoryItem,
  MarketHistoryItemSchema,
  MarketImpliedForwardRateRate,
  MarketImpliedForwardRateRateSchema,
  MarketYieldCurveHistoricalMap,
  MarketYieldCurveHistoricalMapSchema,
  MarketYieldCurveRate,
  MarketYieldCurveRateSchema
} from 'src/types';
import { get, post, postFD, streamWait, zp } from './common';
import { useMarkets } from "./rate";
import { useTokens } from "./token";
import { getMarketWsSymbolCode } from "src/utils/common";
import { useWebsocketWithContext } from "./WebsocketContext";
import { useMemo } from "react";

/**
 * fetch rate markets with tokens
 * */
export function useFixedRateMarkets({tokenId}:{tokenId?:number}) {
  const ret = useMarkets();
  let data:MarketFixedRate[]|undefined;
  if(ret.data){ data = ret.data.filter(m=>m.daysToMaturity!==undefined&&(tokenId===undefined||tokenId===m.tokenId)).map(m=>(m as MarketFixedRate)); }
  return {...ret,data} as UseQueryResult<MarketFixedRate[], unknown>;
}

/**
 * fetch non active markets
 * @param marketIds 
 * @returns 
 */
export function useFixedRateMarketsByMarketIds(marketIds:number[]){
  const queries = marketIds.map(marketId=>{
    return {queryKey: ['market',{category:'fixed',action:'market',marketId}], queryFn:async()=>await _fetchMarketByMarketId({marketId})};
  });
  const {data:tokens,isLoading:isLoadingTokens,isFetching:isFetchingTokens,isError:isErrorTokens} = useTokens();
  return useQueries({queries}).reduce((ret,data)=>{
    if(data.data) {
      const token = tokens?.find(token=>token.tokenId==data.data?.tokenId);
      const market = token&&zp(MarketFixedRateSchema,{...data.data,token});
      if(market){ ret.data.push(market); }
    }
    ret.isError = ret.isError||data.isError;
    ret.isLoading = ret.isLoading||data.isLoading;
    ret.isFetching = ret.isFetching||data.isFetching;
    return ret;
  },{data:new Array<MarketFixedRate>(),isFetching:isFetchingTokens,isLoading:isLoadingTokens,isError:isErrorTokens});
}
// TODO fixed api returned name for WS topic 
async function _fetchMarketByMarketId({marketId,silent}:{marketId:number,silent?:boolean}) {
  const { data } = await get(`/api/p/fixed_rate/market/${marketId}`);
  const market = zp(MarketFixedRateSchema.omit({token:true}),data?.market?{...data.market,category:EMarketCategory.FIXED}:undefined,false,true);
  return market||null;
}
/**
 * fetch market size info for token
 * */
 export function useMarketsSize() {
  return useQuery(['market',{category:'fixed',action:'size'}],async()=>await _fetchMarketSize(),{});
}
export function useMarketSize(tokenId?:number) {
 const ret = useQuery(['market',{category:'fixed',action:'size'}],async()=>await _fetchMarketSize(),{enabled:tokenId!=undefined});
 return {...ret,data:ret.data?.filter(ms=>{
   return ms.tokenId==tokenId
 })[0]};
}
/* for tradingview chart */
export async function fetchMarketSize() {
  return _fetchMarketSize();
}
async function _fetchMarketSize():Promise<MarketFixedRateWithSize[]|undefined> {
  const { data } = await get(`/api/p/fixed_rate/markets/size`, {
    offset:0, limit: 1000
  });
  return zp(z.array(MarketFixedRateWithSizeSchema),data.markets);
}
/**
 * fetch market yield curve data
 * */
export function useMarketsYieldCurve(tokenIds:number[]=[]) {
  return useQuery(['market',{category:'fixed',action:'yieldCurve',tokenIds}],async ()=>{
    const rets = await Promise.allSettled(tokenIds.map(tokenId=>_fetchMarketYieldCurve({tokenId})));
    return rets.map(r=>r.status==='fulfilled'?r.value:[]).reduce((map,rates,i)=>{
      if(rates){
        return {...map,[tokenIds[i]]:rates};
      }
      return map;
    },{} as {[key:number]:MarketYieldCurveRate[]});
  },{
    enabled:tokenIds&&tokenIds.length>0,"keepPreviousData":true,"refetchInterval":60000
  });
}
export function useMarketYieldCurve({tokenId}:{tokenId:number}) {
  return useQuery(['market',{category:'fixed',action:'yieldCurve',tokenId}],async()=>await _fetchMarketYieldCurve({tokenId}),{"keepPreviousData":true,"refetchInterval":60000});
}
async function _fetchMarketYieldCurve({tokenId}:{tokenId:number}):Promise<MarketYieldCurveRate[]|undefined>{
  const { data } = await get(`/api/p/fixed_rate/yieldCurve/estimated`, {
    tokenId
  });
  return zp(z.array(MarketYieldCurveRateSchema),data.interpolatedRates);
}
/**
 * fetch market implied forward rate data
 * */
export function useMarketsImpliedForwardRate(tokenIds:number[]=[]) {
  return useQuery(['market',{category:'fixed',action:'impliedForwardRate',tokenIds}],async ()=>{
    const rets = await Promise.allSettled(tokenIds.map(tokenId=>_fetchMarketImpliedForwardRate({tokenId})));
    return rets.map(r=>r.status==='fulfilled'?r.value:[]).reduce((map,rates,i)=>{
      if(rates){
        return {...map,[tokenIds[i]]:rates};
      }
      return map;
    },{} as {[key:number]:MarketImpliedForwardRateRate[]});
  },{
    enabled:tokenIds&&tokenIds.length>0,"keepPreviousData":true,"refetchInterval":60000
  });
}
export function useMarketImpliedForwardRate({tokenId}:{tokenId:number}) {
  return useQuery(['market',{category:'fixed',action:'impliedForwardRate',tokenId}],async()=>await _fetchMarketImpliedForwardRate({tokenId}),{"keepPreviousData":true,"refetchInterval":60000});
}
async function _fetchMarketImpliedForwardRate({tokenId}:{tokenId:number}):Promise<MarketImpliedForwardRateRate[]|undefined>{
  const { data } = await get(`/api/p/fixed_rate/impliedForwardRate`, {
    tokenId
  });
  return zp(z.array(MarketImpliedForwardRateRateSchema),data.impliedForwardRates);
}

/**
 * fetch market historical yield curve data
 * */
export function useMarketsYieldCurveHistoricalByTokens(
  {tokenIds=[],daysToInclude}:
  {tokenIds?:number[],daysToInclude?:number}
) {
  const queries = tokenIds.map(tokenId=>{
    return {
      queryKey:['market',{category:'fixed',action:'yieldCurveHistorical',tokenId}],
      queryFn:async()=>await _fetchMarketsYieldCurveHistorical({tokenId,daysToInclude}),
      enabled:tokenId!=undefined,
    };
  });
  return useQueries({queries}).reduce((ret,data,i)=>{
    if(data.data) ret.data = {...ret.data,[tokenIds[i]]:data.data};
    ret.isError = ret.isError||data.isError;
    ret.isLoading = ret.isLoading||data.isLoading;
    ret.isFetching = ret.isFetching||data.isFetching;
    return ret;
  },{data:{} as {[key:number]:{
    historicalCurve: MarketYieldCurveHistoricalMap;
    bucketLabels: string[];
  }},isFetching:false,isLoading:false,isError:false});
}
export function useMarketsYieldCurveHistorical(
  {tokenId,daysToInclude}:
  {tokenId?:number,daysToInclude?:number}
) {
  return useQuery(['market',{category:'fixed',action:'yieldCurveHistorical',tokenId}],async()=>await _fetchMarketsYieldCurveHistorical({tokenId,daysToInclude}),{
    enabled:tokenId!=undefined,
  });
}
async function _fetchMarketsYieldCurveHistorical({tokenId,daysToInclude}:{tokenId?:number,daysToInclude?:number}){
  const { data } = await get(`/api/p/fixed_rate/yieldCurve/historical`, { tokenId, daysToInclude });
  const historicalCurve = zp(MarketYieldCurveHistoricalMapSchema,data.historicalCurve);
  if(!historicalCurve) return;
  // load bucket labels from api
  const keys = Object.keys(historicalCurve);
  // get last item (today's values) for the floating rate label as well
  const bucketLabels = historicalCurve[keys[keys.length-1]].sort((a,b)=>a.xpos>b.xpos?1:a.xpos<b.xpos?-1:0).map(item=>item.label); 
  return {historicalCurve,bucketLabels};
}

/**
 * place order
 * */
export async function postRateOrder(auth:AuthContextType,rateOrderParams:{
  marketId: number;
  accountId: number;
  side: EOrderSide; 
  orderType: ERateOrderType;
  quantity: string; 
  rate?: string; //   Only required for limit order
  clientOrderId: string; //  Random string to prevent duplicate order
}):Promise<BaseRateOrder|undefined> {
  if(rateOrderParams.orderType==2&&rateOrderParams.rate==undefined){
    throw 'Rate needed for limit order';
  }
  const { data } = await post(`/api/fixed_rate/order`, rateOrderParams, auth);
  const { order } = data;
  return zp(BaseRateOrderSchema,{...order,orderId:order?.orderId||-1});
}

export async function cancelRateOrder(auth:AuthContextType,{orderId}:{orderId:number}):Promise<boolean> {
  await postFD(`/api/fixed_rate/order/cancel`, {orderId}, auth);
  // throw on error
  return true;
}

export async function cancelBatchRateOrder(auth:AuthContextType,{ orderIds, accountId } : { orderIds: number[], accountId: number }): Promise<CancelledOrderResponseItem[]> {
  const result = await post(`/api/fixed_rate/order/batch/cancel?accountId=${accountId}`, { orderIds }, auth);
  if (result?.success) {
    return result?.data?.cancelOrders ?? []
  } else {
    throw new Error('Batch cancel error')
  }
}

/**
 * fetch transactions for user
 * */
export function useUserRateOrders(auth:AuthContextType,{
  accountId, marketId, pending, done, startId, limit, disabled, 
}:{
  accountId?:number, marketId?:number, pending?:boolean, done?:boolean, startId?:number, limit?:number, disabled?:boolean,
}={}) {
  return useQuery(['user',{user:auth.user,category:'fixed',action:'fixedRateOrders',accountId, marketId, pending, done, startId, limit}],async()=>await _fetchUserRateOrders(auth,{accountId, marketId, pending, done, startId, limit}),{
    keepPreviousData: true,
    enabled:!disabled&&!!auth.user&&accountId!=undefined
  });
}
async function _fetchUserRateOrders(auth:AuthContextType,{
  accountId, marketId, pending, done, startId, limit=100  // pending/done = provide to exclusive show status [true:==, false:!=] to pending/done
}:{
  accountId?:number, marketId?:number, pending?:boolean, done?:boolean, startId?:number, limit?:number
}) {
  const { data } = await get(`/api/user/fixed_rate/orders`,{
    accountId, marketId, pending, done, startId, limit:limit+1
  },auth);
  const orders = zp(z.array(FixedRateOrderSchema),data.orders);
  return orders&&{ orders:orders.slice(0,limit), hasNextPage:orders.length>limit, lastOrderId:orders.at(orders.length-1)?.orderId };
};

export function useRealTimeUserFixedRateOrders(auth:AuthContextType, {
  accountId, market, pending, done, startId, limit = 50, disabled, 
} : {
  accountId?:number, market?:BaseMarket, pending?:boolean, done?:boolean, startId?:number, limit?:number, disabled?:boolean, wsLogged?: boolean
}={}) {
  if (disabled) return { isInitialLoading: false, isFetching: false, data: undefined }
  return _fetchRealTimeUserFixedRateOrders(auth, { accountId, market, pending, done, startId, limit })
}

function _fetchRealTimeUserFixedRateOrders(auth:AuthContextType, {
  accountId, market, pending, done, startId, limit = 50
} : {
  accountId?:number, market?:BaseMarket, pending?:boolean, done?:boolean, startId?:number, limit?:number, wsLogged?: boolean
}={}) {
  const queryKey = useMemo(() => {
    return ['user',{category:'fixed',action:'liveRateOrders',user:auth.user,accountId, marketId: market?.marketId, pending, done, startId, limit}];
  }, [accountId, auth.user, done, limit, market?.marketId, pending, startId]);
  const symbol = getMarketWsSymbolCode(market);
  const eventType = 'userOrder';
  const arrayLimit = limit
  const api = (accountId !== undefined && market?.marketId !== undefined) ? `/api/user/fixed_rate/orders?accountId=${accountId}&marketId=${market?.marketId}&pending=${!!pending}&done=${!!done}&limit=${limit + 1}${ startId !== undefined ? `&startId=${startId}` : ''}` : undefined

  return useWebsocketWithContext<FixedRateOrderData>({
    api, queryKey, symbol, eventType, arrayLimit, receive:(payload) => {
      if (payload?.data?.orders) {
        const orders = zp(z.array(FixedRateOrderSchema), payload.data.orders);
        return orders&&{ type: 'initial', orders:orders.slice(0,limit), hasNextPage:orders.length>limit, lastOrderId:orders.at(orders.length-1)?.orderId };
      } else {
        const order = zp(FixedRateOrderSchema,zp(FixedRateOrderWSSchema, {...payload.data.userOrder, maturityDate: market?.maturityDate}));
        return order && { type: 'update', orders: [order]}
      }
      
    },
  });
}



/* for tradingview chart */
export function fetchMarketHistory({market,marketId,start,end,interval}:{market?:MarketFixedRate,marketId?:number|string,start:number,end:number,interval?:number}) {
  return _fetchMarketHistory({market,marketId,start,end,interval});
}
async function _fetchMarketHistory({market,marketId,start,end,interval}:{market?:MarketFixedRate,marketId?:number|string,start:number,end:number,interval?:number}):Promise<MarketHistoryItem[]|undefined>/*<{
  ts: number,
  lastBlock: number,
  chain: number,
  coins: Coin[],
}>*/ {
  if(market==undefined&&marketId==undefined) return [];
  if(start<1e12) start*= 1000;
  if(end<1e12) end*= 1000;
  if(interval==undefined){
    interval = 15*60*1000; // 15 mins;
  }
  const { data } = await streamWait(`/api/p/fixed_rate/market/${marketId}/history`, { 
    start,end,interval
  });
  const marketHistory = zp(z.array(MarketHistoryItemSchema),data.marketHistory);
  if(market&&marketHistory){
    // add current date graph bar data since market history only pulls up to yesterday
    marketHistory.push({
      // dont care about these values - not used by graph
      open: '0', close: '0', low: '0', volume: '0', totalValue: '0',
      // graph data
      high: new Decimal(market.rate||0).mul(2).toString(), lendDepth: market.deposits||0, borrowDepth: market.borrows||0, date: Date.now(),
    });
  }
  return marketHistory;
}