import {
  Box, SxProps
} from '@mui/material';
import { Decimal } from 'decimal.js';
import { sxBorrowAmount, sxNoDeltaAmount } from 'src/styles/TokenTable';
import { Token } from 'src/types';
import { format, formatTokenAmount } from 'src/utils/numbers';

/**
 * 
 * @param value number|string|Decimal|undefined 
 * @param dp number - decimal places
 * @param factor number - multiplying factor (e.g. for converting decimals to percentages, supply 100)
 * @param prefix string
 * @param suffix string
 * @param token Token - supply to enable tokenUSDValue conversion
 * @param showsTokenUSDValue boolean - true to convert value to USD with `token.price`
 * @param negativeParenthesis boolean - true to show parenthesis for negative values
 * @param monocolor boolean - true to remove color coded direction
 * @param fadeZero boolean - true to apply opacity to zero value
 * @param fontSize string - font size override
 * @param sx SxProps - style to apply to element
 */
function SidedQuantityValue({
  value,dp,prefix="",suffix="",token,negativeParenthesis,monocolor,fadeZero,fontSize,sx={},showsTokenUSDValue,factor=1,
}:{
  value?:number|string|Decimal,dp?:number,prefix?:string,suffix?:string,token?:Token,negativeParenthesis?:boolean,monocolor?:boolean,fadeZero?:boolean,fontSize?:string,sx?:SxProps,showsTokenUSDValue?:boolean,factor?:number,
}){
  if(value===undefined) return <Box>-</Box>;
  if(showsTokenUSDValue){
    dp = 2;
    prefix = token?"":"$";
  }
  const valueDecimal = value ? (Decimal.isDecimal(value)?(value as Decimal):new Decimal(value)).mul(factor) : new Decimal(0);
  const signum = valueDecimal.gte(0)?1:-1;
  if(!monocolor){
    if((!valueDecimal||valueDecimal.eq(0))){
      if(fadeZero) sx = {...sx, ...sxNoDeltaAmount} as SxProps;
    }else if(valueDecimal.lt(0)){
      sx = {...sx, ...sxBorrowAmount} as SxProps;
    }
  }
  if(fontSize){
    sx = {...sx,fontSize};
  }
  sx = {...sx,overflow:'auto'};
  const valueText = token?formatTokenAmount(token,valueDecimal.abs(),showsTokenUSDValue,true,dp):format(valueDecimal.abs(),dp);
  const text = `${prefix}${valueText}${suffix}`;
  if(negativeParenthesis){
    return (
      <Box sx={sx}>{signum===1?text:`(${text})`}</Box>
    );
  }else{
    return (
      <Box sx={sx}>{signum===1?'':'-'}{text}</Box>
    )
  }
}

export default SidedQuantityValue;