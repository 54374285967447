import { Box, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import Decimal from 'decimal.js';
import moment from 'moment';
import { useMemo } from 'react';
import { sxBox, sxBoxCompactInner, sxBoxCompactInnerWithFullTable, sxBoxTitle, sxColumn, sxColumns } from 'src/styles/General';
import { sxPageHeadingRow, sxPageHeadingSmall, sxPageSection } from 'src/styles/Page';
import { sxTokenTable, sxTokenTableContainer } from 'src/styles/TokenTable';
import { BaseMarket } from 'src/types';
import { useAuth } from '../AuthProvider';
import apis from '../utils/apis';
import MarketRollingDateLabel from './MarketRollingDateLabel';
import ReloadDateText from './ReloadDateText';
import SidedQuantityValue from './SidedQuantityValue';
import ValueTableCell from './ValueTableCell';
import { parseAndSum } from 'src/utils/numbers';


function LiquidationMetricSection({selectedAccountId}:{selectedAccountId?:number}){
  const auth = useAuth();
  const { data:tokens } = apis.token.useMarketsPageTokens();
  const { data:markets }: { data?: BaseMarket[]} = apis.rate.useMarkets();
  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  const { data:accountRisk } = apis.user.useUserAccountRisk(auth,{accountId: selectedAccountId});
  const { data:accountTokensWithImpact, isFetching:isFetchingTokens } = apis.user.useUserAccountTokensWithImpact(auth,{accountId:selectedAccountId});
  const { data:accountPositionsWithImpacts, isFetching:isFetchingPositions } = apis.user.useUserPositionsWithImpact(auth,{accountId:selectedAccountId});
  const { accountPositionsOrderByImpact, accountPositionsOrderByImpactPercentage } = accountPositionsWithImpacts||{};

  const queryClient = useQueryClient();
  const isFetching = isFetchingTokens||isFetchingPositions;
  const lastUpdatedTime = useMemo(()=>moment(),[isFetching]);
  const handleClickReload = ()=>{
    queryClient.invalidateQueries(['user',{type:'accountTokenImpact'}]);
    queryClient.invalidateQueries(['user',{type:'accountPositionImpact'}]);
  };

  const { availableFunds } = useMemo(()=>{
    const availableFunds = accountRisk&&new Decimal(accountRisk.netAssetValue||0).sub(new Decimal(accountRisk.initialMargin||0));
    return { availableFunds };
  },[accountRisk]);
  
  return (
    <Box sx={sxPageSection}>
      <Box sx={sxPageHeadingRow}>
        <Typography variant="h1" sx={sxPageHeadingSmall}>Margin &amp; Liquidation Metrics</Typography>
        <ReloadDateText isFetching={isFetching} lastUpdatedTime={lastUpdatedTime} handleClick={handleClickReload}/>
      </Box>
      {/* Summary & Breakdown */}
      <Box sx={{...sxColumns,columnGap:'16px',marginBottom:'8px'}}>
        {/* Summary */}
        <Box sx={{...sxColumn,flex:4}}><Box sx={sxBox}>
          <Box sx={sxBoxTitle}>Summary</Box>
          <Box sx={sxBoxCompactInner}>
            <TableContainer sx={sxTokenTableContainer}>
              <Table sx={sxTokenTable}>
                <TableHead>
                  <TableRow>
                    <ValueTableCell sticky>NAV</ValueTableCell>
                    <ValueTableCell mono align="right"><SidedQuantityValue fontSize={'0.875rem'} prefix="$" dp={2} value={accountRisk?.netAssetValue}/></ValueTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover>
                    <ValueTableCell sticky>&nbsp;&nbsp;&nbsp;&nbsp;PV (Assets)</ValueTableCell>
                    <ValueTableCell mono align="right"><SidedQuantityValue prefix="$" dp={2} value={accountRisk?.pvAsset}/></ValueTableCell>
                  </TableRow>
                  <TableRow hover>
                    <ValueTableCell sticky>&nbsp;&nbsp;&nbsp;&nbsp;PV (Liabilities)</ValueTableCell>
                    <ValueTableCell mono align="right"><SidedQuantityValue prefix="$" dp={2} value={accountRisk?.pvLiability}/></ValueTableCell>
                  </TableRow>
                  <TableRow>
                    <ValueTableCell sticky>&nbsp;</ValueTableCell>
                    <ValueTableCell mono align="right">&nbsp;</ValueTableCell>
                  </TableRow>
                  <TableRow hover>
                    <ValueTableCell sticky>Stressed NAV (StrNAV) </ValueTableCell>
                    <ValueTableCell mono align="right"><SidedQuantityValue prefix="$" dp={2} value={accountRisk?.stressedNav}/></ValueTableCell>
                  </TableRow>
                  <TableRow hover>
                    <ValueTableCell sticky>Maintenance Margin (MM)</ValueTableCell>
                    <ValueTableCell mono align="right"><SidedQuantityValue prefix="$" dp={2} value={accountRisk?.maintenanceMargin}/></ValueTableCell>
                  </TableRow>
                  <TableRow hover>
                    <ValueTableCell sticky>Initial Margin (IM)</ValueTableCell>
                    <ValueTableCell mono align="right"><SidedQuantityValue prefix="$" dp={2} value={accountRisk?.initialMargin}/></ValueTableCell>
                  </TableRow>
                  <TableRow hover>
                    <ValueTableCell sticky>Max Borrow</ValueTableCell>
                    <ValueTableCell mono align="right"><SidedQuantityValue prefix="$" dp={2} value={accountRisk?.maxBorrowInUsd}/></ValueTableCell>
                  </TableRow>
                  <TableRow hover>
                    <ValueTableCell sticky>Available Funds</ValueTableCell>
                    <ValueTableCell mono align="right"><SidedQuantityValue prefix="$" dp={2} value={availableFunds}/></ValueTableCell>
                  </TableRow>
                  {/* {isLoadingAny&&<TableRow hover><ValueTableCell colSpan={10} sx={{textAlign:'center'}}><LoadingIcon inline={true}/></ValueTableCell></TableRow>} */}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box></Box>
        {/* Breakdown */}
        <Box sx={{...sxColumn,flex:8}}><Box sx={sxBox}>
          <Box sx={sxBoxTitle}>Breakdown Per Token &amp; Risk Factor</Box>
          <Box sx={sxBoxCompactInnerWithFullTable}>
            <TableContainer sx={sxTokenTableContainer}>
              <Table sx={sxTokenTable}>
                <TableHead>
                  <TableRow>
                    <ValueTableCell sticky borderRight></ValueTableCell>
                    <ValueTableCell align="right" borderRight>FX</ValueTableCell>
                    <ValueTableCell align="right">Parallel Move</ValueTableCell>
                    <ValueTableCell align="right">Slope</ValueTableCell>
                    <ValueTableCell align="right" borderRight>Curvature</ValueTableCell>
                    {/* <ValueTableCell align="right" borderRight>Illiquidity</ValueTableCell> */}
                    <ValueTableCell align="right">Total</ValueTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover>
                    <ValueTableCell sticky borderRight><b>TOTAL</b></ValueTableCell>
                    <ValueTableCell mono align="right" borderRight><SidedQuantityValue prefix="$" dp={2} value={accountRisk?.fxImpact}/></ValueTableCell>
                    <ValueTableCell mono align="right"><SidedQuantityValue prefix="$" dp={2} value={accountRisk?.parallelImpact}/></ValueTableCell>
                    <ValueTableCell mono align="right"><SidedQuantityValue prefix="$" dp={2} value={accountRisk?.slopeImpact}/></ValueTableCell>
                    <ValueTableCell mono align="right" borderRight><SidedQuantityValue prefix="$" dp={2} value={accountRisk?.curveImpact}/></ValueTableCell>
                    {/* <ValueTableCell mono align="right" borderRight><SidedQuantityValue prefix="$" dp={2} value={undefined}/></ValueTableCell> */}
                    <ValueTableCell mono align="right"><SidedQuantityValue prefix="$" dp={2} value={
                      parseAndSum(accountRisk?.fxImpact, accountRisk?.parallelImpact, accountRisk?.slopeImpact, accountRisk?.curveImpact)
                    }/></ValueTableCell>
                  </TableRow>
                  {accountTokensWithImpact?.map((tokenWithImpact,i)=>{
                    const token = tokens?.find(token=>token.tokenId===tokenWithImpact.tokenId);
                    return (
                    <TableRow hover key={i}>
                      <ValueTableCell sticky borderRight><b>{token?.code}</b></ValueTableCell>
                      <ValueTableCell mono align="right" borderRight><SidedQuantityValue prefix="$" dp={2} value={tokenWithImpact?.fxImpact}/></ValueTableCell>
                      <ValueTableCell mono align="right"><SidedQuantityValue prefix="$" dp={2} value={tokenWithImpact?.parallelImpact}/></ValueTableCell>
                      <ValueTableCell mono align="right"><SidedQuantityValue prefix="$" dp={2} value={tokenWithImpact?.slopeImpact}/></ValueTableCell>
                      <ValueTableCell mono align="right" borderRight><SidedQuantityValue prefix="$" dp={2} value={tokenWithImpact?.curveImpact}/></ValueTableCell>
                      {/* <ValueTableCell mono align="right" borderRight><SidedQuantityValue prefix="$" dp={2} value={undefined}/></ValueTableCell> */}
                      <ValueTableCell mono align="right"><SidedQuantityValue prefix="$" dp={2} value={
                        parseAndSum(tokenWithImpact?.fxImpact, tokenWithImpact?.parallelImpact, tokenWithImpact?.slopeImpact, tokenWithImpact?.curveImpact)
                      }/></ValueTableCell>
                    </TableRow>
                    );
                  })}
                  <TableRow sx={{height:'100%'}}>
                    <ValueTableCell sticky borderRight></ValueTableCell>
                    <ValueTableCell mono align="right" borderRight></ValueTableCell>
                    <ValueTableCell mono align="right"></ValueTableCell>
                    <ValueTableCell mono align="right"></ValueTableCell>
                    <ValueTableCell mono align="right" borderRight></ValueTableCell>
                    {/* <ValueTableCell mono align="right" borderRight></ValueTableCell> */}
                    <ValueTableCell mono align="right"></ValueTableCell>
                  </TableRow>
                  {/* {isLoadingAny&&<TableRow hover><ValueTableCell colSpan={10} sx={{textAlign:'center'}}><LoadingIcon inline={true}/></ValueTableCell></TableRow>} */}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box></Box>
      {/* Contributors */}
      <Box sx={{...sxColumns,columnGap:'16px',marginBottom:'8px'}}>
        {/* Impact $ */}
        <Box sx={sxColumn}><Box sx={sxBox}>
          <Box sx={sxBoxTitle}>My Top 5 Contributors - Impact ($)</Box>
          <Box sx={sxBoxCompactInner}>
            <TableContainer sx={sxTokenTableContainer}>
              <Table sx={sxTokenTable}>
                <TableHead>
                  <TableRow hover>
                    <ValueTableCell sticky></ValueTableCell>
                    <ValueTableCell sticky>Maturity</ValueTableCell>
                    <ValueTableCell align="right">Position</ValueTableCell>
                    <ValueTableCell align="right">Weighted Avg. Rate</ValueTableCell>
                    <ValueTableCell align="right">Impact ($)</ValueTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accountPositionsOrderByImpact?.map((position,i)=>{
                    const market = markets?.find(market=>market.marketId===position.marketId);
                    const token = tokens?.find(token=>token.tokenId===position.tokenId);
                    const rate = market?.daysToMaturity===undefined ? undefined : position.rate; // floating rate market rate is hidden
                    return (
                    <TableRow hover key={i}>
                      <ValueTableCell sticky>{i+1}</ValueTableCell>
                      <ValueTableCell label="Maturity" sticky>{market?<MarketRollingDateLabel market={market} withMarketCode/>:position.instrumentId}</ValueTableCell>
                      <ValueTableCell label="Position" mono align="right"><SidedQuantityValue token={token} value={position.quantity}/></ValueTableCell>
                      <ValueTableCell label="Weighted Avg. Rate" mono align="right"><SidedQuantityValue value={rate} factor={100} suffix="%" dp={2}/></ValueTableCell>
                      <ValueTableCell label="Impact (%)" mono align="right"><SidedQuantityValue prefix="$" dp={2} value={position.totalImpact}/></ValueTableCell>
                    </TableRow>
                    );
                  })}
                  {/* {isLoadingAny&&<TableRow hover><ValueTableCell colSpan={10} sx={{textAlign:'center'}}><LoadingIcon inline={true}/></ValueTableCell></TableRow>} */}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box></Box>
        {/* Impact % */}
        <Box sx={sxColumn}><Box sx={sxBox}>
          <Box sx={sxBoxTitle}>My Top 5 Contributors - Impact (%)</Box>
          <Box sx={sxBoxCompactInner}>
            <TableContainer sx={sxTokenTableContainer}>
              <Table sx={sxTokenTable}>
                <TableHead>
                  <TableRow hover>
                    <ValueTableCell sticky></ValueTableCell>
                    <ValueTableCell sticky>Maturity</ValueTableCell>
                    <ValueTableCell align="right">Position</ValueTableCell>
                    <ValueTableCell align="right">Weighted Avg. Rate</ValueTableCell>
                    <ValueTableCell align="right">Impact (%)</ValueTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accountPositionsOrderByImpactPercentage?.map((position,i)=>{
                    const market = markets?.find(market=>market.marketId===position.marketId);
                    const token = tokens?.find(token=>token.tokenId===position.tokenId);
                    const rate = market?.daysToMaturity===undefined ? undefined : position.rate; // floating rate market rate is hidden
                    return (
                    <TableRow hover key={i}>
                      <ValueTableCell sticky>{i+1}</ValueTableCell>
                      <ValueTableCell label="Maturity" sticky>{market?<MarketRollingDateLabel market={market} withMarketCode/>:position.instrumentId}</ValueTableCell>
                      <ValueTableCell label="Position" mono align="right"><SidedQuantityValue token={token} value={position.quantity}/></ValueTableCell>
                      <ValueTableCell label="Weighted Avg. Rate" mono align="right"><SidedQuantityValue value={rate} factor={100} suffix="%" dp={2}/></ValueTableCell>
                      <ValueTableCell label="Impact (%)" mono align="right"><SidedQuantityValue value={position.impactPercentage} suffix="%" dp={2}/></ValueTableCell>
                    </TableRow>
                    );
                  })}
                  {/* {isLoadingAny&&<TableRow hover><ValueTableCell colSpan={10} sx={{textAlign:'center'}}><LoadingIcon inline={true}/></ValueTableCell></TableRow>} */}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box></Box>
      </Box>
    </Box>
  );
}

export default LiquidationMetricSection;