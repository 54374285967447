import { Button, ButtonGroup, SxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { MARKETS_PAGE_TOKENS } from 'src/constants/app';
import { sxTokenSwitchItem } from '../styles/Chart';
import apis from '../utils/apis';

const sxInlineWrapper:SxProps = {
  my: '16px',
};

export const defaultTokens = MARKETS_PAGE_TOKENS;

function DefaultTokensSelector({ showsAll, inline, exclusive, onChange, initTokenCodes }:{ showsAll?:boolean, inline?:boolean, exclusive?:boolean, onChange?:(selectedTokenCodes:string[])=>any, initTokenCodes?: string[] }){
  const { data:tokens } = apis.token.useMarketsPageTokens();
  const [selectedTokenCodes,setSelectedTokenCodes] = useState(initTokenCodes ?? MARKETS_PAGE_TOKENS.slice(0,exclusive?1:MARKETS_PAGE_TOKENS.length));
  useEffect(()=>{
    if(exclusive){
      setSelectedTokenCodes(selectedTokenCodes=>selectedTokenCodes.slice(0,1));
    }
    onChange&&onChange(selectedTokenCodes);
  },[exclusive]);
  const isSelectedAll = selectedTokenCodes.length===MARKETS_PAGE_TOKENS.length;
  const isSelectedNone = selectedTokenCodes.length===0;
  return (
    <ButtonGroup sx={inline?sxInlineWrapper:undefined} variant="outlined" aria-label="outlined primary button group">
    {tokens&&tokens.length>1&&tokens.map((token,i)=>{
      // const color = graphLineColors[i%graphLineColors.length];
      const inactive = selectedTokenCodes&&selectedTokenCodes.indexOf(token.code)<0;
      return (
        <Button key={i} sx={sxTokenSwitchItem} variant={inactive?'outlined':'contained'} 
          onClick={()=>{
            if(exclusive){
              setSelectedTokenCodes([token.code]);
              onChange&&onChange([token.code]);
            }else{
              const codes = selectedTokenCodes.indexOf(token.code)<0?
              [...selectedTokenCodes,token.code]: // add
              selectedTokenCodes.filter(c=>c!=token.code) // remove
              ;
              setSelectedTokenCodes(codes);
              onChange&&onChange(codes);
            }
          }}
        >{token.code}</Button>
      )
    })}
    {(showsAll&&!exclusive)&&<>
      <Button sx={sxTokenSwitchItem} variant={isSelectedAll?'contained':'outlined'} onClick={()=>{
        const codes = [...MARKETS_PAGE_TOKENS];
        setSelectedTokenCodes(codes);
        onChange&&onChange(codes);
      }}>All</Button>
      <Button sx={sxTokenSwitchItem} variant={isSelectedNone?'contained':'outlined'} onClick={()=>{
        const codes:string[] = [];
        setSelectedTokenCodes(codes);
        onChange&&onChange(codes);
      }}>None</Button>
    </>}
    </ButtonGroup>
  );
}

export default DefaultTokensSelector;