import { createTheme } from '@mui/material/styles';
import {
  blueDark, blueDarkA, brown, brownA, brownDark, cyanDark,
  cyanDarkA,
  grassDark, gray, grayDark, grayDarkA, orangeDark,
  orangeDarkA,
  purpleDark,
  slateDark,
  tomatoDark,
  tomatoDarkA,
  amberDark
} from '@radix-ui/colors';

const { palette } = createTheme();
const theme = createTheme({
  typography: {
    fontFamily: [
      'Inter', 'Helvetica', 'Arial', 'sans-serif',
    ].join(','),
    allVariants: { /* fontWeight: 400 */ },
  },
  palette: {
    mode: 'dark',
    background: {
      default: blueDark.blue2,
      paper: blueDark.blue3,
    },
    primary: {
      main: blueDark.blue8,
      dark: blueDark.blue7,
      light: blueDark.blue9,
      100: blueDark.blue1,
      200: blueDark.blue2,
      300: blueDark.blue3,
      400: blueDark.blue4,
      500: blueDark.blue5,
      600: blueDark.blue6,
      700: blueDark.blue7,
      800: blueDark.blue8,
      900: blueDark.blue9,
      A100: blueDarkA.blueA1,
      A200: blueDarkA.blueA2,
      A400: blueDarkA.blueA4,
      A700: blueDarkA.blueA7,
    }, 
    secondary: {
      main: brown.brown8,
      dark: brown.brown9,
      light: brown.brown6,
      100: brownDark.brown1,
      200: brownDark.brown2,
      300: brownDark.brown3,
      400: brownDark.brown4,
      500: brownDark.brown5,
      600: brownDark.brown6,
      700: brownDark.brown7,
      800: brownDark.brown8,
      900: brownDark.brown9,
      A100: brownA.brownA1,
      A200: brownA.brownA2,
      A400: brownA.brownA4,
      A700: brownA.brownA7,
    },
    grey: {
      100: grayDark.gray1,
      200: grayDark.gray2,
      300: grayDark.gray3,
      400: grayDark.gray4,
      500: grayDark.gray5,
      600: grayDark.gray6,
      700: grayDark.gray7,
      800: grayDark.gray8,
      900: grayDark.gray9,
      A100: grayDarkA.grayA1,
      A200: grayDarkA.grayA2,
      A400: grayDarkA.grayA4,
      A700: grayDarkA.grayA7,
    },
    slate: {
      100: slateDark.slate1,
      200: slateDark.slate2,
      300: slateDark.slate3,
      400: slateDark.slate4,
      500: slateDark.slate5,
      600: slateDark.slate6,
      700: slateDark.slate7,
      800: slateDark.slate8,
      900: slateDark.slate9,
    },
    text: {
      primary: gray.gray1,
      secondary: gray.gray4,
      disabled: grayDark.gray10
    },
    action: {
      hover: blueDarkA.blueA5,
    },
    dialog: {
      main: blueDark.blue8,
      dark: blueDark.blue9,
      light: blueDark.blue6,
    },
    success: {
      main: grassDark.grass9,
      dark: grassDark.grass8,
    },
    error: {
      main: tomatoDark.tomato9,
      light: tomatoDarkA.tomatoA6
    },
    warning: {
      main: orangeDark.orange9,
      dark: orangeDark.orange8,
    },
    negative: {
      main: orangeDark.orange8,
      dark: orangeDark.orange6,
      light: orangeDark.orange9,
      300: orangeDarkA.orangeA3,
      400: orangeDarkA.orangeA4,
      500: orangeDarkA.orangeA5,
    },
    positive: {
      main: cyanDark.cyan8,
      dark: cyanDark.cyan6,
      light: cyanDark.cyan9,
      300: cyanDarkA.cyanA3,
      400: cyanDarkA.cyanA4,
      500: cyanDarkA.cyanA5,
    },
    purple: palette.augmentColor({
      color: {
        100: purpleDark.purple1,
        200: purpleDark.purple2,
        300: purpleDark.purple3,
        400: purpleDark.purple4,
        500: purpleDark.purple5,
        600: purpleDark.purple6,
        700: purpleDark.purple7,
        800: purpleDark.purple8,
        900: purpleDark.purple9,
      },
    }),
    amber: palette.augmentColor({
      color: {
        100: amberDark.amber1,
        200: amberDark.amber2,
        300: amberDark.amber3,
        400: amberDark.amber4,
        500: amberDark.amber5,
        600: amberDark.amber6,
        700: amberDark.amber7,
        800: amberDark.amber8,
        900: amberDark.amber9,
      },
    }),
    table: palette.augmentColor({
      color: {
        main: blueDark.blue4,
        dark: blueDark.blue3,
        darker: blueDark.blue2,
        light: blueDark.blue7,
        lighter: blueDark.blue8,
      },
    }),
    border: palette.augmentColor({
      color: {
        dark: blueDark.blue7,
        main: blueDark.blue8,
        light: blueDark.blue9,
      },
    }),
    button: palette.augmentColor({
      color: {
        dark: blueDark.blue5,
        main: blueDark.blue7,
      },
    }),
    buttonDisabled: palette.augmentColor({
      color: {
        main: gray.gray10,
        dark: blueDark.blue3,
      },
    }),
    orderBook: palette.augmentColor({
      color: {
        main: grayDark.gray11,
      },
    }),
    tooltip: palette.augmentColor({
      color: {
        main: slateDark.slate3,
        dark: slateDark.slate1,
        light: slateDark.slate5,
      },
    }),
    note: palette.augmentColor({
      color: {
        main: grayDark.gray11,
      },
    }),
  },
  breakpoints: {
    values: {
      mobile: 425,
      tablet: 640,
      laptop: 1024,
      desktop: 1280,
      desktopWide: 1366,
      desktopWider: 1600,
    }
  },
  // spacing: (factor:number)=>`${factor*2}px`,
  // zIndex: {
  //   mobileStepper: number;
  //   speedDial: number;
  //   appBar: number;
  //   drawer: number;
  //   modal: number;
  //   snackbar: number;
  //   tooltip: number;
  // }
});

theme.typography.h1 = { fontWeight: 700, fontSize: '1.500rem', marginBottom: '16px' };//[theme.breakpoints.down('tablet')]: { fontSize: '2.125rem', /* fontWeight: 400, */ } };
theme.typography.h2 = { fontWeight: 700, fontSize: '1.475rem', };
theme.typography.h3 = { fontWeight: 700, fontSize: '1.350rem', };
theme.typography.h4 = { fontWeight: 700, fontSize: '1.250rem', };
theme.typography.h5 = { fontWeight: 700, fontSize: '1.125rem', };
theme.typography.h6 = { fontWeight: 700, fontSize: '1.000rem', };
// theme.typography.subtitle1 = { };
// theme.typography.subtitle2 = { };
// theme.typography.body1 = { };
// theme.typography.body2 = { };
// theme.typography.button = { };
// theme.typography.caption = { };
// theme.typography.overline = { };

export default theme;
