/**
 * @dev call fetch apis with use hooks (not the api call functions directly) for better centralized cache handling 
 * @todo remove exports from apis/* to limit direct api function calls?
 *  */
import * as fixedrate from './apis/fixedrate';
import * as infinity from './apis/infinity';
import * as lp from './apis/lp';
import * as rate from './apis/rate';
import * as token from './apis/token';
import * as twap from './apis/twap';
import * as user from './apis/user';

interface APIError {
  errorCode: number,
  errorMsgKey: string,
  success: boolean,
  data: APIErrorData,
}
interface APIErrorData {
  reason: string
}

const apis = Object.assign({
  user, rate, fixedrate, token, lp, twap, infinity
},
{
  // 
});

export enum EQueryKey {
  MARKETS
};
export function queryKey(key:EQueryKey,params:Object){
  params = JSON.parse(JSON.stringify(params)); // sanitize params 
  return [key,params];
}

export default apis;
