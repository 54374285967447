import { Box, Typography } from '@mui/material';
import { sxContentWide } from 'src/styles/Page';
import { usePageTitle } from 'src/utils/common';
import LiquidityProviderTable from '../Components/LiquidityProviderTable';

function LiquidityDashboard(){
  usePageTitle(['Liquidity Providers']);
  return (
    <>
      <Box sx={sxContentWide}>
        <Typography variant="h1">Liquidity Providers (May Be Deprecated)</Typography>
        <Typography variant="subtitle1">Provide Liquidity to Lenders or Borrowers in exchange for interest-like income </Typography>
        <LiquidityProviderTable/>
      </Box>
    </>
  );
}

export default LiquidityDashboard;