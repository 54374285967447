import Decimal from 'decimal.js';
import { isEmpty, isEqual, isUndefined } from 'lodash';
import { ESwapLegType, ETradeType, Market, MarketFixedRate, Token } from 'src/types';
import { create } from 'zustand';

interface LegParams {
    tokenId?: number;
    marketId?: number;
}

type State = {
    tradeType: ETradeType | null;
    legs: {
        [key: string]: LegParams
    };
    markets: (Market | MarketFixedRate)[];
    tokens: Token[];
    mergedOrderBook: {
        bestAsk: string,
        bestBid: string
    };
}

type Action = {
    setTokens: (tokens: State['tokens']) => void;
    setTradeType: (tradeType: ETradeType) => void;
    setMarkets: (markets: (Market | MarketFixedRate)[], tokenId: number, isFixed: boolean) => void;
    setLegMarket: (legKey: string, marketId: number) => void;
    setLegToken: (legKey: string, tokenId: number) => void;
    setMergedOrderBook: (mergedOrderBook: State['mergedOrderBook']) => void;
    getExchangeRate: () => string;
}

export type SwapParameterStore = State & Action;

const getFixedMarketId = (markets: (Market | MarketFixedRate)[], tokenId: number, daysToMaturity: number): number | undefined => {
    return markets.find(m => m.daysToMaturity === daysToMaturity && m.tokenId === tokenId)?.marketId
}

const getFloatMarketId = (markets: (Market | MarketFixedRate)[], tokenId: number | undefined): number | undefined => {
    return markets.find(m => isUndefined(m.daysToMaturity) && m.tokenId === tokenId)?.marketId
}

const getMarketIdByToken = (markets: (Market | MarketFixedRate)[], tokenId: number, daysToMaturity?: number): number | undefined => {
    return markets.find(m => m.daysToMaturity === daysToMaturity && m.tokenId === tokenId)?.marketId
}

const getFirstToken = (tokens: Token[], tokenToSkip?: number) => {
    if (!tokenToSkip) {
        return tokens[0]?.tokenId
    }
    return tokens.find(t => t.tokenId !== tokenToSkip)?.tokenId
}

export const useSwapParameterStore = create<State & Action>((set, get) => ({
    tradeType: null,
    legs: {},
    mergedOrderBook: { bestAsk: '', bestBid: '' },
    markets: [],
    tokens: [],
    setTokens: (tokens: Token[]) => {
        const prevTokens = get().tokens
        if (!isEqual(prevTokens, tokens)) {
            set((state) => {
                if (isEmpty(state.tokens)) {
                    return {
                        ...state,
                        tokens,
                        legs: {
                            leg1: {
                                ...state.legs.leg1,
                                tokenId: tokens[0].tokenId,
                                marketId: !isEmpty(state.markets) ? getFixedMarketId(state.markets, tokens[0].tokenId, 1) : undefined,
                            },
                            leg2: {
                                ...state.legs.leg2,
                                tokenId: state.tradeType === ETradeType.INTEREST_RATE_SWAP ? tokens[0].tokenId : tokens[1].tokenId,
                                marketId: !isEmpty(state.markets) ? getFloatMarketId(state.markets, tokens[1].tokenId) : undefined,
                            }
                        }
                    }
                } else {
                    return { tokens }
                }
            })
        }
    },
    setMarkets: (markets: (Market | MarketFixedRate)[],) => {
        const stateMarkets = get().markets
        const legs = get().legs
        const newLegs = {
            ...legs
        }
        if (!isUndefined(legs?.leg1?.tokenId) && isUndefined(legs?.leg1?.marketId)) {
            newLegs.leg1.marketId = markets.find(m => m.daysToMaturity === 1 && m.tokenId === legs.leg1.tokenId)?.marketId
        }
        if (!isUndefined(legs?.leg2?.tokenId) && isUndefined(legs?.leg2?.marketId)) {
            newLegs.leg2.marketId = markets.find(m => isUndefined(m.daysToMaturity) && m.tokenId === legs.leg2.tokenId)?.marketId
        }
        if (!isEqual(stateMarkets, markets)) {
            set((state) => ({
                ...state,
                markets,
                legs: {
                    ...newLegs
                }
            }))
        }
    },
    setLegMarket: (legKey: string, marketId: number) => {
        const newMarket = get().markets.find(m => m.marketId === marketId)
        const legs = get().legs
        const newLegs = {
            ...legs,
            [legKey]: {
                ...legs[legKey],
                marketId: marketId
            }
        }

        if (legKey === ESwapLegType.PRIMARY) {
            const secondaryMarket = get().markets.find(m => m.marketId === legs.leg2.marketId)
            if (!isUndefined(secondaryMarket?.daysToMaturity)) {
                const secondaryTokenId = legs.leg2?.tokenId
                const newSecondaryMarketId = get().markets.find(m => m.tokenId === secondaryTokenId && m.daysToMaturity === newMarket?.daysToMaturity)?.marketId
                newLegs.leg2 = {
                    ...newLegs.leg2,
                    marketId: newSecondaryMarketId
                }
            }
        }
        set((state) => ({
            ...state,
            legs: {
                ...newLegs
            }
        }))
    },
    setLegToken: (legKey: string, tokenId: number) => {
        const tradeType = get().tradeType
        const currentTokenId = get().legs?.[legKey].tokenId
        const currentMarketId = get().legs?.[legKey].marketId
        const markets = get().markets
        const tokens = get().tokens
        const legs = get().legs
        const currentMarket = markets.find(m => m.marketId === currentMarketId)
        const newLegs = {
            ...legs,
            [legKey]: {
                ...legs[legKey],
                tokenId: tokenId,
                marketId: !isEmpty(markets) ? getMarketIdByToken(markets, tokenId, currentMarket?.daysToMaturity) : undefined,
            }
        }
        if (legKey === ESwapLegType.PRIMARY) {
            const leg2TokenId = legs.leg2.tokenId
            const leg2MarketId = legs.leg2.marketId
            const newLeg2TokenId = tradeType === ETradeType.INTEREST_RATE_SWAP ? tokenId : ((isUndefined(leg2TokenId) || leg2TokenId === tokenId) ? getFirstToken(tokens, tokenId) : leg2TokenId)
            newLegs.leg2 = {
                ...legs.leg2,
                tokenId: newLeg2TokenId,
                marketId: leg2TokenId === newLeg2TokenId ? leg2MarketId : (!isEmpty(markets) ? getMarketIdByToken(markets, newLeg2TokenId ?? -1) : undefined),
            }
        }
        if (currentTokenId !== tokenId) {
            set((state) => ({
                ...state,
                legs: {
                    ...newLegs
                }
            }))
        }
    },
    setTradeType: (tradeType: ETradeType) => {
        const tokens = get().tokens
        const markets = get().markets
        let newLegs = {}
        if (!isEmpty(tokens)) {
            const secondaryTokenId = tradeType === ETradeType.INTEREST_RATE_SWAP ? tokens[0].tokenId : tokens[1].tokenId;
            newLegs = {
                leg1: {
                    tokenId: tokens[0].tokenId,
                    marketId: !isEmpty(markets) ? getFixedMarketId(markets, tokens[0].tokenId, 1) : undefined,
                },
                leg2: {
                    tokenId: secondaryTokenId,
                    marketId: !isEmpty(markets) ? getFloatMarketId(markets, secondaryTokenId) : undefined,
                }
            }
        }
        set({
            tradeType,
            legs: newLegs
        })
    },
    setMergedOrderBook: ({ bestAsk, bestBid}: { bestAsk: string, bestBid: string}) => {
        const prevMergedOrderBook = get().mergedOrderBook
        if (!isEqual(prevMergedOrderBook, { bestAsk, bestBid })) {
            set({
                mergedOrderBook: { bestAsk, bestBid }
            })
        }
    },
    getExchangeRate: () => {
        const legs = get().legs
        const tokens = get().tokens
        const primaryToken = tokens.find(t => t.tokenId === legs?.leg1?.tokenId) 
        const secondaryToken = tokens.find(t => t.tokenId === legs?.leg2?.tokenId)
        if (isUndefined(primaryToken) || isUndefined(secondaryToken)) {
            return '0.00'
        } else {
            const primaryTokenPrice = new Decimal(primaryToken.price)
            const secondaryTokenPrice = new Decimal(secondaryToken.price)
            return primaryTokenPrice.div(secondaryTokenPrice).valueOf()
        }
    }
}))