import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { MarketFixedRate, UserTokensGraphDateBucketType } from "src/types";
import { unique } from 'src/utils/common';
import { getDaysToMaturitiesFromMarkets, now } from 'src/utils/date';

const useCutoffDays = (marketsFixedRate?: MarketFixedRate[], dateBucketType?:UserTokensGraphDateBucketType): number[] => {
    const [cutoffDays, setCutoffDays] = useState<number[]>([])

    useEffect(
        () => {
            let newCutoffDays:number[];
            switch(dateBucketType){
              case 'Monthly':
                newCutoffDays = (new Array(6).fill(0)).map((_,i)=>now().add(i+1,'month').diff(now(),'day'));
                break;
              case 'Quarterly':
                newCutoffDays = (new Array(6).fill(0)).map((_,i)=>now().add(i+3,'month').diff(now(),'day'));
                break;
              case 'Semi-Annually':
                newCutoffDays = (new Array(4).fill(0)).map((_,i)=>now().add(i+6,'month').diff(now(),'day'));
                break;
              case 'Annually':
                newCutoffDays = (new Array(3).fill(0)).map((_,i)=>now().add(i+12,'month').diff(now(),'day'));
                break;
              case 'By Contract': default:
                const daysToMaturities = getDaysToMaturitiesFromMarkets(marketsFixedRate);
                newCutoffDays = unique([0,...daysToMaturities]);
                break;
            }
            if (!isEqual(newCutoffDays, cutoffDays)) {
                setCutoffDays(newCutoffDays)
            }
        }, [marketsFixedRate, cutoffDays, dateBucketType]
    )

    return cutoffDays;
}

export default useCutoffDays;