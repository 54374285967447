import {
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import Decimal from 'decimal.js';
import { useMemo, useState } from 'react';
import { useAuth } from 'src/AuthProvider';
import HealthscoreValue from 'src/Components/HealthscoreValue';
import useFreeCashByToken from 'src/hooks/useFreeCashByToken';
import { styleGrid, styleHeader, stylePanelScrollable } from 'src/styles/GridBox';
import { sxStylelessTable } from 'src/styles/Table';
import { EUserAccountType } from 'src/types';
import apis from 'src/utils/apis';
import { isTokenStableCoin } from 'src/utils/common';
import { format } from 'src/utils/numbers';
import { GridComponentParams } from '.';
import LoadingIcon from '../Loading';
import ValueTableCell from '../ValueTableCell';
import DragGrabber from './DragGrabber';

enum TabValue {
  LEND=0, BORROW=1,
}

function MarketInfo({token,market,isFetching}:GridComponentParams){
  const auth = useAuth();
  const tokenId = token?.tokenId;

  const {isLoading,error,data:userAccounts} = apis.user.useUserAccounts(auth);
  const {isLoading:isLoadingMarketSize,data:fixedMarketSize} = apis.fixedrate.useMarketSize(tokenId);
  const {isLoading:isLoadingMarketsFixedRate,data:marketsFixedRate} = apis.fixedrate.useFixedRateMarkets({tokenId});
  const {data:userMarketData} = apis.rate.useUserMarketDataByToken(auth,{tokenId});
  const { portfolioFreeCashLocalQuantity } = useFreeCashByToken(tokenId)
  const { data: marketsByToken } = apis.rate.useMarkets({ tokenId });

  const frMarketIds = marketsFixedRate?.map(m=>m.marketId);
  const {data:marketsRatesByToken} = apis.rate.useMarketsPastRates({tokenId,frMarketIds});
  const marketsRates = token&&marketsRatesByToken&&marketsRatesByToken[token.tokenId];
  const lends = new Decimal(fixedMarketSize?.deposits||0).add(new Decimal(market?.deposits||0));
  const borrows = new Decimal(fixedMarketSize?.borrows||0).add(new Decimal(market?.borrows||0));
  const userNetPositions = useMemo(() => userMarketData ? new Decimal(userMarketData.summary.position||0) : new Decimal(0), [userMarketData])
  const dp = useMemo(() => token ? (isTokenStableCoin(token) ? 2 : 4) : 2, [token])
  const marketSize = lends.add(borrows);
  const volume = marketsRates?.reduce((sum,rate)=>sum.add(new Decimal(rate.volume||0)),new Decimal(0))||new Decimal(0);
  
  const userTokenAccount = userAccounts&&apis.user.extractAccountTokenAccountFromAccounts(userAccounts,EUserAccountType.TRADING,token?.tokenId)
  const parentAccount = userTokenAccount&&apis.user.extractUserAccount(userAccounts,userTokenAccount.accountId);
  // console.log('parentAccount',parentAccount);
  const [ tabValue, setTabValue ]:[number,Function] = useState(TabValue.LEND);
  function handleTabChange(event:React.SyntheticEvent,value:string|number){
    setTabValue(typeof value=='number'?value:parseInt(value));
  }

  const userAccount = useMemo(() => {
    return userAccounts?.find(userAccount => userAccount.type == EUserAccountType.TRADING)
  }, [userAccounts])

  const maxBorrow = format(new Decimal(parentAccount?.maxBorrowInUsd||0).div(new Decimal(token?.price||0)), dp);

  return (
    <Box sx={styleGrid}>
      <Box sx={styleHeader}>{token?.code} Market &amp; Account Info<DragGrabber/></Box>
      <Box sx={stylePanelScrollable}>
        <TableContainer component={Box}>
          <Table sx={{ ...sxStylelessTable, marginBottom: '12px' }} aria-label="Markets" size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} sx={{ lineHeight: '18px', paddingTop: '12px !important'}}>Account Balance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover>
                <TableCell>Cash Balance</TableCell>
                <ValueTableCell mono sx={{ textAlign: "right" }}>{userTokenAccount ? `${format(userTokenAccount.quantity,dp)} ${token?.code}` : '-'}</ValueTableCell>
              </TableRow>
              <TableRow hover>
                <TableCell>Net Amounts</TableCell>
                <ValueTableCell mono sx={{ textAlign: "right" }}>{userNetPositions ? `${format(userNetPositions,dp)} ${token?.code}` : '-'}</ValueTableCell>
              </TableRow>
              <TableRow hover>
                <TableCell>Max Lend</TableCell>
                <ValueTableCell mono sx={{ textAlign: "right" }}>{userTokenAccount ? `${format(userTokenAccount.availableQuantity,dp)} ${token?.code}` : '-'}</ValueTableCell>
              </TableRow>
              <TableRow hover>
                <TableCell>Max Borrow</TableCell>
                <ValueTableCell mono sx={{ textAlign: "right" }}>{`${maxBorrow} ${token?.code}`}</ValueTableCell>
              </TableRow>
              <TableRow hover>
                <TableCell>Health Score</TableCell>
                <ValueTableCell mono sx={{ textAlign: "right" }}><HealthscoreValue value={parentAccount?.healthScore}/></ValueTableCell>
              </TableRow>
              {/* <TableRow hover>
                <TableCell>Health Score</TableCell>
                <ValueTableCell mono>{parentAccount&&format(parentAccount.healthscore,0)||'-'}</ValueTableCell>
              </TableRow> */}
              {/* <TableRow hover><TableCell colSpan={2}>&nbsp;</TableCell></TableRow> */}
              {/* <TableRow hover>
                <TableCell>24h Volume</TableCell>
                <ValueTableCell mono>{format(volume)}</ValueTableCell>
              </TableRow> */}
            </TableBody>
          </Table>
          <Table sx={sxStylelessTable} aria-label="Markets" size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Market Info</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover>
                <TableCell>Min. Order Size</TableCell>
                <ValueTableCell mono sx={{ textAlign: "right" }}>{market?.minQuantity ? `${market?.minQuantity} ${token?.code}` : '-'}</ValueTableCell>
              </TableRow>
              <TableRow hover>
                <TableCell>Min. Size Increment</TableCell>
                <ValueTableCell mono sx={{ textAlign: "right" }}>{market?.quantityStep  ? `${market?.quantityStep} ${token?.code}` : '-'}</ValueTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {!market&&isFetching&&<LoadingIcon curtain/>}
      </Box>
    </Box>
  );
}


export default MarketInfo;