import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { useQueryClient } from "@tanstack/react-query";
import Decimal from 'decimal.js';
import { Fragment, Ref, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MARKETS_PAGE_TOKENS } from 'src/constants/app';
import useSnackbar from 'src/hooks/useSnackbar';
import { sxBox, sxBoxBigLabel, sxBoxCompactInner, sxButtonPlaceholder, sxHalfOpacity, sxInlineLink, sxRow, sxTitleFloatRight } from 'src/styles/General';
import { sxContentFullHeight, sxPageHeadingRow, sxPageHeadingSmall, sxPageHeadingWrapper } from 'src/styles/Page';
import { sxVerticalSeparator } from 'src/styles/Table';
import { useHoursSinceRollover } from 'src/utils/apis/infinity';
import { generateClientOrderId } from 'src/utils/common';
import { date, today } from 'src/utils/date';
import { paths } from 'src/utils/paths';
import { useAuth } from '../AuthProvider';
import { sxEmptyRow, sxParentRow, sxShortRow, sxTokenTable, sxTokenTableContainer } from '../styles/TokenTable';
import {
  AggregatedMarketPosition,
  BaseMarket,
  ConfirmationDialog,
  EOrderSide,
  ERateOrderType,
  ETradeType,
  EUserAccountType,
  GroupedPosition,
  PNLHistoricalItem,
  UserAccountPerformanceLiveItem,
  Token,
  TradeParams,
  UserAccount
} from '../types';
import apis from '../utils/apis';
import { format } from '../utils/numbers';
import CashPositionsTable from './CashPositionsTable';
import DeltaValue from './DeltaValue';
import HealthscoreValue from './HealthscoreValue';
import LoadingIcon from './Loading';
import MarketRollingDateLabel from './MarketRollingDateLabel';
import PNLSection from './PNLSection';
import SidedQuantityValue from './SidedQuantityValue';
import TokenIcon from './TokenIcon';
import TradeDialog from './TradeConfirmationDialog';
import ValueTableCell from './ValueTableCell';

const sxHeaderBox:SxProps = {
  ...sxBox,
  overflow: 'auto !important', // TEMP FIX for overflowing numbers
};
const sxHeaderBoxInner:SxProps = {
  display:'flex',
};
const sxHeaderValueBox:SxProps = {
  p: '8px',
  marginRight: '48px',
  flex: 1, flexBasis: '50%',
};
const sxHeaderValueBoxLabel:SxProps = {
  textTransform: 'uppercase',
  fontSize: '0.75rem',
  fontWeight: '700',
  marginBottom: '8px',
  whiteSpace: 'nowrap',
};
const sxPositionRowExpandIcon:SxProps = {
  fontSize:'14px',marginTop:'4px',
};
const styleAccountTabLink:SxProps = {
  cursor: 'pointer',
  marginRight: 1,
  opacity: .5,
  color: 'text.primary',
  textDecorationColor: 'inherit',
};
const styleAccountTabLinkActive:SxProps = {
  opacity: 1,
};
const sxPositionRowTokenCell:SxProps = {
  display: 'flex', justifyContent: 'space-between',
};
interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  align: 'left'|'center'|'right';
}
const headCells: readonly HeadCell[] = [
  {
    id: 'tokenId',
    align: 'left',
    disablePadding: false,
    label: 'Tokens',
  },
  {
    id: 'marketId',
    align: 'left',
    disablePadding: false,
    label: 'Maturity',
  },
  {
    id: 'size',
    align: 'right',
    disablePadding: false,
    label: 'Amount',
  },
  // {
  //   id: 'usdValue',
  //   align: 'right',
  //   disablePadding: false,
  //   label: 'USD$',
  // },
  {
    id: 'rate',
    align: 'right',
    disablePadding: false,
    label: 'Weighted Avg Rate',
  },
  {
    id: 'marketRate',
    align: 'right',
    disablePadding: false,
    label: 'Market Rate',
  },
  // {
  //   id: 'accruedInterest',
  //   align: 'right',
  //   disablePadding: false,
  //   label: 'Accrued Interest (USD$)',
  // },
  {
    id: 'dv01',
    align: 'right',
    disablePadding: false,
    label: 'PV01',
  },
  {
    id: 'pv',
    align: 'right',
    disablePadding: false,
    label: 'PV ($)',
  },
  {
    id: 'mtm',
    align: 'right',
    disablePadding: false,
    label: 'Unrealised P&L',
  },
  {
    id: 'action',
    align: 'left',
    disablePadding: false,
    label: 'Action',
  },
];

function PortfolioPanel(){
  const auth = useAuth();
  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  const [ selectedAccountId, setSelectedAccountId ] = useState<number|undefined>();
  const showsAccountselector = false;
  function handleClickAccount(event:React.MouseEvent){
    const accountId = parseInt(event.currentTarget.getAttribute('data-account-id')||`${getFirstTradingAccountId(userAccounts)}`);
    setSelectedAccountId(accountId===-1?undefined:accountId);
  }
  const getFirstTradingAccountId = useCallback((_userAccounts=userAccounts)=>{
    return _userAccounts?.filter(uw=>uw.type===EUserAccountType.TRADING).at(0)?.accountId;
  },[userAccounts]);
  useEffect(()=>{
    if(!selectedAccountId&&userAccounts){
      setSelectedAccountId(getFirstTradingAccountId(userAccounts));
    }
  },[selectedAccountId,userAccounts,getFirstTradingAccountId]);
  return (
    <>
      {showsAccountselector&&<Box sx={{px:2}}>
        {userAccounts&&userAccounts.filter(userAccount=>userAccount.type==EUserAccountType.TRADING).map((userAccount, idx)=>(
          <Link key={idx} sx={
            Object.assign({},styleAccountTabLink,selectedAccountId==userAccount.accountId?styleAccountTabLinkActive:null)
          } data-account-id={userAccount.accountId} onClick={handleClickAccount}>{userAccount.name}</Link>
        ))}
      </Box>}
      <>
      {userAccounts?userAccounts.filter(
        userAccount=>userAccount.type===EUserAccountType.TRADING&&userAccount.accountId==selectedAccountId
        ).map((userAccount,idx)=>(<Fragment key={idx}>

        {/* Portfolio Summary */}
        <OverviewSection account={userAccount}/>
        
        {/* PNL */}
        <PNLSection accountId={userAccount.accountId}/>
        
        {/* Cash Details Overview */}
        <Box sx={{my:'24px'}}><CashPositionsTable type="overview"/></Box>

        {/* Position Details */}
        <PositionDetailsSection key={idx} account={userAccount}/>

      </Fragment>)):(<Box sx={sxContentFullHeight}><LoadingIcon curtain/></Box>)}
      </>
    </>
  );
}

function OverviewSection({account}:{account:UserAccount}){
  const auth = useAuth();
  
  const { accountId } = account;
  const { data:userAccountPerformanceLive/* , dataUpdatedAt, isFetching */ } = apis.user.useUserAccountPerformanceLive(auth,{ accountId });

  const { nav, livePnL, cash, positionsPv, borrowLimit, healthscore } = useMemo(()=>{
    let nav:Decimal|undefined;
    let livePnL:Decimal|undefined;
    let cash:Decimal|undefined;
    let positionsPv:Decimal|undefined;
    let borrowLimit:Decimal|undefined;
    let healthscore:number|undefined;
    if(userAccountPerformanceLive){
      nav = new Decimal(userAccountPerformanceLive.nav || 0);
      livePnL = new Decimal(userAccountPerformanceLive.livePnL || 0);
      cash = new Decimal(userAccountPerformanceLive.cash || 0);
      positionsPv = new Decimal(userAccountPerformanceLive.positionsPv || 0);
      borrowLimit = new Decimal(userAccountPerformanceLive.borrowLimit || 0);
      healthscore = userAccountPerformanceLive.healthscore;
    }
    console.log(`[numcheck] Summary nav - livePnL =${nav?.minus(livePnL ?? 0).toString()} - should match live pnl table's total prev close nav`);
    return { nav, livePnL, cash, positionsPv, borrowLimit, healthscore };
  },[userAccountPerformanceLive]);


  return (<>
    <Box sx={sxRow}>
      <Box sx={sxHeaderBox}>
        <Box sx={sxHeaderBoxInner}>
          <Box sx={sxHeaderValueBox}><Box sx={sxHeaderValueBoxLabel}>NAV</Box><Box sx={sxBoxBigLabel}><SidedQuantityValue value={nav} prefix="$" dp={2}/></Box></Box>
          <Box sx={sxHeaderValueBox}><Box sx={sxHeaderValueBoxLabel}>{"Live P&L"}</Box><Box sx={sxBoxBigLabel}><DeltaValue value={livePnL} prefix="$" dp={2} showsZero/></Box></Box>
        </Box>
      </Box>
      <Box sx={sxHeaderBox}>
        <Box sx={sxHeaderBoxInner}>
          <Box sx={sxHeaderValueBox}><Box sx={sxHeaderValueBoxLabel}>Cash</Box><Box sx={sxBoxBigLabel}><SidedQuantityValue value={cash} prefix="$" dp={2}/></Box></Box>
          <Box sx={sxHeaderValueBox}><Box sx={sxHeaderValueBoxLabel}>Positions PV</Box><Box sx={sxBoxBigLabel}><SidedQuantityValue value={positionsPv} prefix="$" dp={2}/></Box></Box>
        </Box>
      </Box>
      <Box sx={sxHeaderBox}>
        <Box sx={sxHeaderBoxInner}>
          <Box sx={sxHeaderValueBox}><Box sx={sxHeaderValueBoxLabel}>Borrow Limit</Box><Box sx={sxBoxBigLabel}><SidedQuantityValue value={borrowLimit} prefix="$" dp={2}/></Box></Box>
          <Box sx={sxHeaderValueBox}><Box sx={sxHeaderValueBoxLabel}>Health Score</Box><Box sx={sxBoxBigLabel}><HealthscoreValue value={healthscore}/></Box></Box>
        </Box>
      </Box>
    </Box>
  </>);
}

function PositionDetailsSection({account}:{account:UserAccount}){
  const auth = useAuth();
  const hoursSinceRollover = useHoursSinceRollover();
  
  const { accountId, healthScore, maxBorrowInUsd='0', tokens:accountToken } = account;
  const {data:markets} = apis.rate.useMarkets();
  const {data:tokens} = apis.token.useTokens();
  const { data:userAccountPerformanceLive, isLoading:isLoadingRatePositions } = apis.user.useUserAccountPerformanceLive(auth,{ accountId });
  const positionRowRefArray = useRef(new Array<TGroupedPositionRow>());

  // api data
  const {
    positionUsdValueSum, interestUsdValueSum, tokenRows, 
  } = useMemo(()=>{
    const groupedPositions = userAccountPerformanceLive?.positionDetails;
    let positionUsdValueSum = new Decimal(0);
    let interestUsdValueSum = new Decimal(0);
    let pvSum = new Decimal(0);
    let pvSumFromPositions = new Decimal(0);
    const tokenRows = (markets&&groupedPositions&&tokens&&MARKETS_PAGE_TOKENS.map((tokenCode,refIdx)=>{
      const token = tokens.find(token=>token.code===tokenCode)!;
      if(!token) return null;
      const groupedPosition = groupedPositions.find(p=>p.tokenId===token.tokenId);
      const tokenPriceDecimal = new Decimal(groupedPosition?.price ?? 0);
      let pvSumInGroup = new Decimal(0);
      groupedPosition?.positions?.forEach((position,index)=>{
        const { quantity, priceIndex, interest, instrumentId } = position;
        // render 
        const isLend = new Decimal(quantity).gte(0);
        // market & token might load async
        const market = markets.find(market=>market.instrumentId===instrumentId);
        const accruedInterest = !(market?.lendPriceIndex||market?.borrowPriceIndex)?'0':new Decimal(quantity||0).mul(new Decimal(
          (isLend?market.lendPriceIndex:market.borrowPriceIndex) ?? 0
        ).sub(new Decimal(priceIndex||'0'))).mul(isLend?1:-1).toString();
        positionUsdValueSum = positionUsdValueSum.add(new Decimal(quantity||0).mul(tokenPriceDecimal));
        const interestEitherMarketCategory = interest!==undefined?
          new Decimal(interest):
          new Decimal(quantity||0).mul(new Decimal(market?.rate||0)).div(365).div(24).mul(hoursSinceRollover)
        ;
        interestUsdValueSum = interestUsdValueSum.add(interestEitherMarketCategory); 
        const pvPosition = new Decimal(position.pv ?? 0).mul(tokenPriceDecimal);
        pvSumInGroup = pvSumInGroup.add(pvPosition);
      });
      pvSumFromPositions = pvSumFromPositions.add(pvSumInGroup);
      const pv = new Decimal(groupedPosition?.pv ?? 0).mul(tokenPriceDecimal);
      pvSum = pvSum.add(pv);
      // console.log(`[numcheck] Position Details ${token.code} positions PV sum equals grouped: ${pvSum.eq(pvSumFromPositions)}`)
      return (<GroupedPositionRowForwarded key={`${token.tokenId}`} groupedPosition={groupedPosition} markets={markets} token={token} account={account} ref={ref=>{positionRowRefArray.current[refIdx] = ref}}/>);
    }))||[];
    console.log(`[numcheck] Position Details pvSum=${pvSum.toString()} - should match summary positions pv`);
    return {
      positionUsdValueSum, interestUsdValueSum, tokenRows, 
    };
  },[ account,tokens,markets,userAccountPerformanceLive,hoursSinceRollover ]);

  return (<>
    <Box sx={sxPageHeadingRow}>
      <Box sx={sxPageHeadingWrapper}>
        <Typography variant="h1" sx={sxPageHeadingSmall}>Position Details</Typography>
        <Box sx={sxTitleFloatRight}>
          <Link sx={sxInlineLink} onClick={()=>positionRowRefArray.current.forEach(ref=>ref.expand())}>Expand all</Link>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Link sx={sxInlineLink} onClick={()=>positionRowRefArray.current.forEach(ref=>ref.collapse())}>Collapse all</Link>
        </Box>
      </Box>
    </Box>
    {(isLoadingRatePositions)?<Box sx={{width:'100%',textAlign:'center'}}><LoadingIcon inline={true}/></Box>:<>
    <Box sx={sxBox}><Box sx={sxBoxCompactInner}>
      <TableContainer component={Box} sx={sxTokenTableContainer}>
        <Table sx={sxTokenTable} aria-label="Markets" size="medium">
          <TableHead>
            <TableRow>
              {headCells.map((headCell,idx) => (
                <ValueTableCell
                  key={idx}
                  align={headCell.align}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sticky={idx===0}
                  sx={idx===0||idx===headCells.length-2?sxVerticalSeparator:undefined}
                >
                  {headCell.label}
                </ValueTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
          {tokenRows}
          {tokenRows?.length===0&&<TableRow hover sx={sxEmptyRow}>
            <TableCell colSpan={10}>
              Portfolio not found.
            <TableCell></TableCell>
            <TableCell></TableCell>
            </TableCell>
          </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    </Box></Box>
    </>}
  </>);
}

function GroupedPositionRow({groupedPosition,markets=[],token,account}:{groupedPosition?:GroupedPosition,markets?:BaseMarket[],token:Token,account:UserAccount},ref:Ref<any>){
  const navigate = useNavigate();
  const hoursSinceRollover = useHoursSinceRollover();
  const {tokenId} = token;
  const {side,quantity,pv,price,mtm,dv01,positions} = groupedPosition||{};
  const [expanded,setExpanded] = useState(false);
  const {data:tokens} = apis.token.useTokens();

  const ExpandIcon = expanded?KeyboardArrowUp:KeyboardArrowDown;

  useImperativeHandle(ref, () => {
    return {
      expand() { setExpanded(true) },
      collapse() { setExpanded(false) },
    };
  }, []);

  function handleClickRow(){
    handleClickExpandRow();
  }

  function handleClickExpandRow(){
    setExpanded(expanded=>!expanded);
  }
  // function handleClickViewOrders(){
  //   navigate(paths.portfolioPage("order-history",`${tokenId}`))
  // }

  // positions
  const {positionRows,interestUsdValueSum} = useMemo(()=>{
    let interestUsdValueSum = new Decimal(0);
    const positionRows = positions?.map((position,index)=>{
      const { quantity, interest, instrumentId, tokenId } = position;
      // render 
      const isLend = new Decimal(quantity).gte(0);
      // market & token might load async
      const market = markets.find(market=>market.instrumentId===instrumentId);
      const token = tokens?.find(token=>token.tokenId===tokenId);
      const interestEitherMarketCategory = interest!==undefined?
      new Decimal(interest):
      new Decimal(quantity||0).mul(new Decimal(market?.rate||0)).div(365).div(24).mul(hoursSinceRollover)
      ;
      interestUsdValueSum = interestUsdValueSum.add(interestEitherMarketCategory); 
      return <PositionRow key={index} position={position} market={market} account={account}/>;
    });
    return {positionRows,interestUsdValueSum};
  },[markets,tokens,positions,account,hoursSinceRollover]);
  const hasRows = positionRows && positionRows.length>0;

  // grouped row
  const amount = new Decimal(quantity||'0');
  const { pvUsdValue } = useMemo(()=>{
    const pvUsdValue = new Decimal(pv ?? 0).mul(price ?? 0); 
    return {pvUsdValue};
  },[pv,price]);
  const usdValue = amount.mul(new Decimal(token.price||0))
  const amountComponent = <SidedQuantityValue value={amount} token={token}/>;
  // const usdValueComponent =<SidedQuantityValue value={usdValue} prefix="$" dp={2}/>;
  const pvInUSDComponent =<SidedQuantityValue value={pvUsdValue} prefix="$" dp={2}/>;
  const mtmComponent =<SidedQuantityValue value={mtm} prefix="$" dp={2}/>;
  // const interestComponent = <SidedQuantityValue value={new Decimal(interestUsdValueSum||0)} prefix="$" dp={2}/>
  const dv01Component = <SidedQuantityValue value={dv01} dp={4}/>;

  return (
    <>
      <TableRow
        role="checkbox"
        tabIndex={-1}
        hover={true} // !!lpPosition}
        sx={{...sxParentRow,cursor:hasRows?'pointer':'default'}}
        className={"expandedChild"}
        onClick={handleClickRow}
      >
        <ValueTableCell sticky={true} sx={sxVerticalSeparator}>
          <Box sx={sxPositionRowTokenCell}>
            <TokenIcon token={token} withCode={true} size={14} variant="bold"/>
            <ExpandIcon sx={{...sxPositionRowExpandIcon,visibility:hasRows?'visible':'hidden'}} />
          </Box>
        </ValueTableCell>
        <ValueTableCell label="Market" width="50%"><Box sx={sxHalfOpacity}>All Markets</Box></ValueTableCell>
        <ValueTableCell mono align="right" label="Position" width="50%">{amountComponent}</ValueTableCell>
        {/* <ValueTableCell mono align="right" label="USD$" width="50%">{usdValueComponent}</ValueTableCell> */}
        <ValueTableCell mono align="right" label="Weighted Avg Rate" width="50%"><Box sx={sxHalfOpacity}>N/A</Box></ValueTableCell>
        <ValueTableCell mono align="right" label="Market Rate" width="50%"><Box sx={sxHalfOpacity}>N/A</Box></ValueTableCell>
        {/* <ValueTableCell mono align="right" label="Accrued Interest (USD$)" width="50%">{interestComponent}</ValueTableCell> */}
        <ValueTableCell mono align="right" label="PV01" width="50%">{dv01Component}</ValueTableCell>
        <ValueTableCell mono align="right" label="PV ($)" width="50%">{pvInUSDComponent}</ValueTableCell>
        <ValueTableCell mono align="right" label="Unrealised P&L" width="50%" sx={sxVerticalSeparator}>{mtmComponent}</ValueTableCell>
        <ValueTableCell label="" width="50%">
          <Button variant="text" sx={sxButtonPlaceholder}>Unwind</Button>
          <Button variant="text" sx={sxButtonPlaceholder}>View Orders</Button>
        </ValueTableCell>
      </TableRow>
      {expanded&&positionRows}
    </>
  );
}
const GroupedPositionRowForwarded = forwardRef(GroupedPositionRow);
type TGroupedPositionRow = {
  expand: ()=>void;
  collapse: ()=>void;
}

function PositionRow({position,market,account,isChild}:{position:AggregatedMarketPosition,market?:BaseMarket,account:UserAccount,isChild?:boolean}){
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const hoursSinceRollover = useHoursSinceRollover();
  const queryClient = useQueryClient();
  const confirmationDialogRef = useRef<ConfirmationDialog | null>(null)
  const {data:tokens} = apis.token.useTokens();

  const { instrumentId, tokenId, accountId, side, quantity, rate, interest, pv, price, mtm, dv01 } = position;
  const token = tokens?.find(token=>token.tokenId===tokenId);
  const isFloatMarket = market?.daysToMaturity===undefined;
  
  function handleClickRow(){
  }

  // function handleClickViewOrders(){
  //   navigate(paths.portfolioPage("order-history",`${tokenId}/${instrumentId}`));
  // }

  function handleClickUnwindButton(position:AggregatedMarketPosition){
    const { tokenId } = position;
    const token = tokens?.find(token=>token.tokenId===tokenId);
    if(!market||!token){
      snackbar.error('Market not found!');
      return;
    }
    const orderOppositeSide = side===EOrderSide.LEND?EOrderSide.BORROW:EOrderSide.LEND;
    // const averageRateDecimal = new Decimal(rate ?? 0);
    const marketRateDecimal = new Decimal(market.rate ?? 0); // unwind fixed rate: at market rate
    const quantityDecimal = new Decimal(quantity).abs(); // unwind logic uses the exact quantity
    const interestDecimal = new Decimal(interest ?? 0).abs(); // only for floating rate market
    // TODO shows interest as a separate field under the unwind dialog
  
    const tradeParams: TradeParams = {
      primaryLeg: {
        marketId: market.marketId,
        accountId: accountId,
        side: orderOppositeSide, //  1 for borrow, 0 for lend
        orderType: ERateOrderType.LIMIT, //  1 for market order, 2 for limit order
        quantity: quantityDecimal.toString(), 
        adjustedRate: marketRateDecimal,
        maxSlippage: new Decimal(0),
        orderRate: marketRateDecimal,
        targetRate: marketRateDecimal,
        clientOrderId: generateClientOrderId(),
      },
    }
    if(market.daysToMaturity===undefined){
      // delete tradeParams.primaryLeg.adjustedRate;
      tradeParams.secondaryLeg = {
        ...tradeParams.primaryLeg,
        quantity: interestDecimal.toString(),
      };
    }
    confirmationDialogRef?.current?.openDialog({ tradeType: ETradeType.UNWIND_POSITION, tradeParams, primaryToken: token, primaryMarket: market})
  }
  const handleSuccessOrder = () => {
    queryClient.invalidateQueries({queryKey:['user']});
    queryClient.invalidateQueries({queryKey:['market']});
  };


  const amount = new Decimal(quantity||'0');
  const { pvUsdValue } = useMemo(()=>{
    const pvUsdValue = new Decimal(pv ?? 0).mul(price ?? 0); 
    return {pvUsdValue};
  },[pv,price]);
  const isLend = side===EOrderSide.LEND;
  const interestEitherMarketCategory = interest!==undefined?
    new Decimal(interest):
    new Decimal(quantity||0).mul(new Decimal(market?.rate||0)).div(365).div(24).mul(hoursSinceRollover)
  ;
  const amountComponent = <SidedQuantityValue value={amount} token={token}/>;
  // const usdValueComponent =<SidedQuantityValue value={amount} token={token} showsTokenUSDValue/>;
  const pvInUSDComponent = <SidedQuantityValue value={pvUsdValue} prefix="$" dp={2}/>;
  const mtmComponent = isFloatMarket?'-':<SidedQuantityValue value={mtm} prefix="$" dp={2}/>;
  // const accruedComponent = <SidedQuantityValue value={interestEitherMarketCategory} showsTokenUSDValue/>
  const dv01Component = <SidedQuantityValue value={dv01} dp={4}/>;

  const rateComponent = isFloatMarket? '-' : `${format(new Decimal(rate ?? '0').mul(100),2)}%`;
  const marketRateComponent = `${format(new Decimal(market?.rate ?? '0').mul(100),2)}%`;

  const viewOrdersLink = paths.portfolioPage("order-history",`${tokenId}/${market?.marketId}`);

  return (
    <>
      <TableRow
        role="checkbox"
        tabIndex={-1}
        hover={true} // !!lpPosition}
        sx={sxShortRow}
        className={"expandedChild"}
        onClick={handleClickRow}
      >
        <ValueTableCell sticky={true} sx={sxVerticalSeparator}></ValueTableCell>
        <ValueTableCell mono label="Market" width="50%"><MarketRollingDateLabel market={market} withMarketCode withMarketDate/></ValueTableCell>
        <ValueTableCell mono align="right" label="Notional" width="50%">{amountComponent}</ValueTableCell>
        {/* <ValueTableCell mono align="right" label="USD$" width="50%">{usdValueComponent}</ValueTableCell> */}
        <ValueTableCell mono align="right" label="Weighted Avg Rate" width="50%">{rateComponent}</ValueTableCell>
        <ValueTableCell mono align="right" label="Market Rate" width="50%">{marketRateComponent}</ValueTableCell>
        {/* <ValueTableCell mono align="right" label="Accrued Interest (USD$)" width="50%">{accruedComponent}</ValueTableCell> */}
        <ValueTableCell mono align="right" label="PV01" width="50%">{dv01Component}</ValueTableCell>
        <ValueTableCell mono align="right" label="PV ($)" width="50%">{pvInUSDComponent}</ValueTableCell>
        <ValueTableCell mono align="right" label="Unrealised P&L" width="50%" sx={sxVerticalSeparator}>{mtmComponent}</ValueTableCell>
        <ValueTableCell label="" width="50%">
          <UnwindButton position={position} handleClick={handleClickUnwindButton}/>
          <Button variant="text" /* onClick={handleClickViewOrders} */ href={viewOrdersLink} target='_blank'>View Orders</Button>
        </ValueTableCell>
      </TableRow>
      <TradeDialog ref={confirmationDialogRef} onSuccess={handleSuccessOrder}/>

    </>
  );
}
function UnwindButton({position,handleClick}:{position:AggregatedMarketPosition,handleClick:Function}) {
  return (
    <Button variant="text" onClick={(event)=>{
      event.stopPropagation();
      handleClick(position);
    }}>Unwind</Button>
  );
}

export default PortfolioPanel;