import { SxProps } from '@mui/system';
import { StylePrimaryButton } from './General';
export const sxDialogPaper:SxProps = {
  bgcolor: 'background.paper',
  backgroundImage: 'none',
  borderRadius: '4px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'border.dark',
  width: '400px',
  maxWidth: '95vw',
};
export const sxDialog:SxProps = {
  ".MuiDialog-paper":sxDialogPaper
};
export const sxDialogTitle:SxProps = {
  position: 'relative',
  textAlign: 'left',
  fontSize: '1.125rem',
  p: '12px', 
  borderWidth: '0',
  borderBottomWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'border.dark',
  "&~.MuiBox-root":{
    p: '12px',
  }
};
export const sxDialogTitleCompact:SxProps = {
  textAlign: 'left',
  fontSize: '0.875rem',
  fontWeight: 700,
  p: '12px', 
  borderWidth: '0',
  borderBottomWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'border.dark',
  "&~.MuiBox-root":{
    p: '12px',
  }
};
export const sxDialogPanel:SxProps = {
  display: 'flex',
  flexDirection:'column',
  flex: 1,
  // alignItems: 'center',
  px: 3,
  paddingBottom: 2,
  "&:empty":{ p:0 },
};
export const sxDialogCloseButton:SxProps = {
  position: 'absolute',
  right: '4px',
  top: '50%', transform: 'translateY(-50%)',
};
export const sxDialogActions:SxProps ={
  paddingBottom: 2,
  justifyContent: 'center',
  "&:empty":{ p:0 },
  ".MuiButton-root": {
    py: 1, px: 4,
  }
};
export const styleDialogButton:SxProps = {
  marginBottom: 2,
  color: 'grey.900',
  bgcolor: 'grey.500',
  ":hover":{
    bgcolor: 'grey.300',
  }
};
export const styleDialogButtonOutlined:SxProps = {
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'border.dark',
  ":hover":{
    borderColor: 'border.light',
    borderWidth: '2px',
  // bgcolor: 'border.dark',
  },
  "&+&":{
    marginTop: '12px',
  }
};
  
export const styleDialogPrimaryButton:SxProps = StylePrimaryButton;

export const StyleConfirmRow:SxProps = {
  display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent:'center', textAlign:'center', my: 1,
};
export const StyleConfirmBox:SxProps = {
};
export const StyleConfirmBoxLabel:SxProps = {
  fontSize: '0.75rem', opacity: 0.5,
};
export const StyleConfirmBoxName:SxProps = {
  fontSize: '1.5rem',
};
export const StyleConfirmBoxArrow:SxProps = {
  fontSize: '2rem', fontWeight: 700, mx: 2,
};

export const sxOrderStatusTitle:SxProps = {
  textAlign: 'center',
  marginBottom: '32px',
  marginTop: '68px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
};

export const sxOrderStatusSuccessTitle: SxProps = {
  ...sxOrderStatusTitle,
  'svg':{
    color: 'success.main',
    verticalAlign: 'bottom',
  }
};

export const sxOrderStatusErrorTitle: SxProps = {
  ...sxOrderStatusTitle,
  marginTop: '120px',
  'svg':{
    color: 'error.main',
    verticalAlign: 'bottom',
  }
};

export const sxOrderStatusContent:SxProps = {
  textAlign: 'center',
  my: '16px',
};

export const sxOrderStatusDetails: SxProps = {
  my: '40px',
  mx: '16px',
  fontSize: '1.125rem',
  fontFamily: 'Inter',
  lineHeight: '1.75rem',
  fontWeight: '700',
  textAlign: 'center'
}

export const sxDialogCell:SxProps = {
  borderWidth: '0',
  borderBottomWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'border.dark',
  padding: '0',
  lineHeight: '3.5rem',
  '&.MuiTableCell-root': {
    padding: '0',
    fontWeight: '700'
  }
};

export const sxDialogCellValue:SxProps = {
  ...sxDialogCell,
  textAlign: 'right',
  fontSize: '1.25rem',
  '&.MuiTableCell-root': {
    padding: '0',
    fontWeight: '400'
  }
};

export const sxConfirmationDialog = {
  ".MuiDialog-paper": {
    ...sxDialogPaper,
    width: '480px',
    marginBotton: '36px'
  }
};

export const sxSummary:SxProps = {
  px: '28px',
  flexDirection: 'column',
};

export const sxOrderSummary:SxProps = {
  ...sxSummary,
  fontSize: '1rem',
  lineHeight: '1.8rem',
  marginBottom: '40px'
};

export const sxErrorStatusMain: SxProps = {
  textAlign: 'center',
  fontSize: '1.2rem',
  lineHeight: '1.8rem',
  marginBottom: '40px'
};

export const sxFulfilledTrade:SxProps = {
  marginBottom: '24px'
};

export const sxSupport:SxProps = {
  textAlign: 'center',
  color: 'grey.900',
  mx: '40px'
};

export const sxStatusTitleLabel:SxProps = {
  fontSize: '1.5rem',
  lineHeight: '2rem',
  fontWeight: '700'
}

export const sxSummaryDetailsText:SxProps = {
  fontSize: '1.1rem',
  lineHeight: '1.7rem',
  fontFamily: 'Inter'
}

export const sxConfirmDialogActionButtonContainer = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingBottom: '24px'
}

export const sxActionButton:SxProps = {
  minWidth: '86%',
  mx: 'auto',
  borderWidth: '2px'
}

export const sxActionButtonFirst:SxProps = {
  ...sxActionButton,
  mb: '8px'
}