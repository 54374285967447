import Decimal from 'decimal.js';
import moment from 'moment';
import { TOKEN_MAX_DECIMALS } from 'src/constants/app';
import { Token } from 'src/types';
import { isTokenStableCoin } from './common';

export function isValidDecimal(d:Decimal){
  return d.isFinite()&&!d.isNaN();
}

const maxDP = 6; // maximum decimal places = 6
export function preciseFloat(n:number|string){
  if(typeof n=='number'&&isNaN(n)) return n;
  const dp = Math.min(maxDP,((''+n).split('.')[1]||'').length||0);
  const factor = Math.pow(10,dp);
  const ret = Math.round(parseFloat(''+n)*factor)/factor;
  return ret;
}

export function dps(n:number|string|Decimal){
  if(typeof n=='string'||typeof n=='number') n = new Decimal(n);
  return n.decimalPlaces();
}

/**
 * max decimal places from constants.TOKEN_MAX_DECIMALS (9)
 * @param n number
 * @param dp decimal places
 * @param withSign if true, prepend + sign to output
 * @returns formatted number string, comma-separated thousands
 */
export function format(n:number|string|Decimal|undefined|null,dp?:number,withSign?:boolean){
	if(typeof n=='undefined'||n==null) return 'NaN';
	if(!(n instanceof Decimal)) n = new Decimal(n);
  const noDPpreference = dp==undefined;
	if(dp==undefined) dp = n.decimalPlaces()||2;
  const sign = n.gte(0);
	let [left,right] = (n.toFixed(dp)).toString().split('.');
  if(noDPpreference&&left.length>5) right = ''; // auto remove decimals when no dp is supplied
  // max decimals from constants
  if(right) right = right.slice(0,TOKEN_MAX_DECIMALS);
	return (withSign&&sign&&"+"||"")+left.replace(/\B(?=(\d{3})+(?!\d))/g, ',')+(right&&right.length>0?('.'+right):'');
}
export function formatUSDWithSign(n:number|string|Decimal|undefined|null,hidesZero=false,useFormatN=false,showsDp=false){
	if(typeof n=='undefined'||n==null) return 'NaN';
	if(!(n instanceof Decimal)) n = new Decimal(n);
  if(hidesZero&&n.eq(0)) return '-';
  const t = useFormatN?formatN(n.abs(),showsDp?2:0):format(n.abs(),showsDp?2:0);
  return `${n.lt(0)?'-':''}$${t}`;
}
export function formatUSD(amount:number|string|Decimal|undefined|null){
  return format(amount,0);
}
export function formatTokenAmount(token:Token,amount:number|string|Decimal|undefined|null,showsUSDValue?:boolean,showsDp=false,dp?:number){
  if(amount===undefined||amount===null) return '-';
  if(showsUSDValue){
    amount = (amount instanceof Decimal)?amount:new Decimal(amount);
    return formatUSDWithSign(amount.mul(token.price),false,false,showsDp);
  }
  const decimals = dp!==undefined ? dp : isTokenStableCoin(token)?2:4;
  return format(amount,decimals);
}
export function formatTokenDV01(token:Token,amount:number|string|Decimal|undefined|null,showsUSDValue?:boolean){
  const decimals = isTokenStableCoin(token)?2:4;
  return format(amount,decimals);
}

type formatNParamType = {
  num:number|Decimal, digits?:number, prefix?:string, suffix?:string,
};
export function formatN(paramsOrNum:number|Decimal|formatNParamType,digits?:number) {
  if((typeof paramsOrNum==='number'||paramsOrNum instanceof Decimal)){
    paramsOrNum = {num: paramsOrNum,digits};
  }
  return _formatN(paramsOrNum);
}
function _formatN({num,digits=0,prefix='',suffix=''}:formatNParamType) {
  if(typeof num=='number'){
    num = new Decimal(num);
  }
  const sign = num.gte(0)?'':'-';
  num = num.abs();
  const lookup = [
    { value: 1, symbol: "" , digits:0},
    { value: 1e3, symbol: "K" , digits:0},
    { value: 1e6, symbol: "M" , digits:2},
    { value: 1e9, symbol: "B" , digits:2},
    { value: 1e12, symbol: "T" , digits:2},
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find((item)=>{
    return (num as Decimal).gte(item.value);
  });
  return item ? `${sign}${prefix}${(num.div(item.value)).toFixed(digits||item.digits).replace(rx, "$1")}${item.symbol}${suffix}` : num.toFixed(digits||0);
}


export function decomma(str:string){
  return str.replace(/,/g,'');
}

export function periodLengthMilliseconds(resolution: string, requiredPeriodsCount: number = 1): number {
	let daysCount = 0;

	if (resolution === 'D' || resolution === '1D') {
		daysCount = requiredPeriodsCount;
	} else if (resolution === 'M' || resolution === '1M') {
		daysCount = 31 * requiredPeriodsCount;
	} else if (resolution === 'W' || resolution === '1W') {
		daysCount = 7 * requiredPeriodsCount;
	} else {
		daysCount = requiredPeriodsCount * parseInt(resolution) / (24 * 60);
	}

	return daysCount * 24 * 60 * 60 * 1000;
}

// export function decToHex(n:number,prefix?:boolean){
// 	return (prefix?'0x':'')+n.toString(16);
// }

function _convertSecsToMs(n:number|string|undefined){
	if(typeof n=='undefined') return 'NaN';
	if(typeof n=='string') n = parseFloat(n);
	if(n<1e12) n*= 1000; // assume it's in seconds not ms
	return n;
}

export function formatDateTime(n:number|string|undefined){
	n = _convertSecsToMs(n);
	return (`${moment(n).format('DD-MMM-YY HH:mm:ss')}`).toUpperCase();
}

export function formatTime(n:number|string|undefined){
	n = _convertSecsToMs(n);
	return moment(n).format('HH:mm:ss');
}

export function formatDayNumber(n:number){
  let unit = 'D';
  if(n>=365){
    n = Math.floor(n/365);
    unit = 'Y';
  }else if(n>=30){
    n = Math.floor(n/30);
    unit = 'M';
  }
  return `${n}${unit}`;
}


/**
 * A function for converting hex <-> dec w/o loss of precision.
 *
 * The problem is that parseInt("0x12345...") isn't precise enough to convert
 * 64-bit integers correctly.
 *
 * Internally, this uses arrays to encode decimal digits starting with the least
 * significant:
 * 8 = [8]
 * 16 = [6, 1]
 * 1024 = [4, 2, 0, 1]
 */

// Adds two arrays for the given base (10 or 16), returning the result.
// This turns out to be the only "primitive" operation we need.
function add(x:number[]|null, y:number[]|null, base:number) {
	x = x||[]; y = y||[];
  var z = [];
  var n = Math.max(x.length, y.length);
  var carry = 0;
  var i = 0;
  while (i < n || carry) {
    var xi = i < x.length ? x[i] : 0;
    var yi = i < y.length ? y[i] : 0;
    var zi = carry + xi + yi;
    z.push(zi % base);
    carry = Math.floor(zi / base);
    i++;
  }
  return z;
}

// Returns a*x, where x is an array of decimal digits and a is an ordinary
// JavaScript number. base is the number base of the array x.
function multiplyByNumber(num:number, x:number[]|null, base:number) {
	x = x||[];
  if (num < 0) return null;
  if (num == 0) return [];

  var result:number[] = [];
  var power = x;
  while (true) {
    if (num & 1) {
      result = add(result, power, base);
    }
    num = num >> 1;
    if (num === 0) break;
    power = add(power, power, base);
  }

  return result;
}

function parseToDigitsArray(str:string, base:number) {
  var digits = str.split('');
  var ary = [];
  for (var i = digits.length - 1; i >= 0; i--) {
    var n = parseInt(digits[i], base);
    if (isNaN(n)) return null;
    ary.push(n);
  }
  return ary;
}

function convertBase(str:string, fromBase:number, toBase:number) {
  var digits = parseToDigitsArray(str, fromBase);
  if (digits === null) return null;

  var outArray:number[] = [];
  var power:number[]|null = [1];
  for (var i = 0; i < digits.length; i++) {
    // invariant: at this point, fromBase^i = power
    if (digits[i]) {
      outArray = add(outArray, multiplyByNumber(digits[i], power, toBase), toBase);
    }
    power = multiplyByNumber(fromBase, power, toBase);
  }

  var out = '';
  for (var i = outArray.length - 1; i >= 0; i--) {
    out += outArray[i].toString(toBase);
  }
  return out;
}

export function decToHex(dec:number|string,prefix?:boolean) {
	const decStr = dec+'';
  var hex = convertBase(decStr, 10, 16);
  return prefix /*hex*/ ? '0x' + hex : null;
}

export function hexToDec(hexStr:string) {
  if (hexStr.substring(0, 2) === '0x') hexStr = hexStr.substring(2);
  hexStr = hexStr.toLowerCase();
  return convertBase(hexStr, 16, 10);
}


export const VALIDATE_NUMBER_PATTERN_WITH_COMMA = /^(\d{1,3}(,\d{0,3})*)?(\.\d*)?$/;
export const VALID_NUMBER_PATTERN_WITHOUT_COMMA = /^(\d*\.?\d*)$/;

export const isValidNumberInput = (value: string) => VALIDATE_NUMBER_PATTERN_WITH_COMMA.test(value) || VALID_NUMBER_PATTERN_WITHOUT_COMMA.test(value)


/**
 * Math.ceil to the nearest order of magnitude
 * e.g. ceilToNearestOoM(123) returns 200
 * @param n 
 * @returns 
 */
export function ceilToNearestOoM(n:number){
  if(n===0) return 0;
  const neg = n<0;
  const oom = getOoM(n);
  return (neg?Math.floor:Math.ceil).apply(Math,[n/oom])*oom;
}
function getOoM(n:number){
  return Math.max(Math.pow(10,Math.floor(Math.log10(Math.abs(n)))),1); // min:1
}

export function getEqualDistanceTicks(min:number,max:number){
  // round to nearest ceiling 
  min = ceilToNearestOoM(min);
  max = ceilToNearestOoM(max);
  // get the bigger magnitude
  const maxAbs = Math.max(Math.abs(min),Math.abs(max));
  // calculaet distance: try 5 step or 10 step
  const oom = getOoM(maxAbs);
  const diff = Math.abs(max-min);
  let distance = (oom===1||(oom===10&&diff===10))?1:(maxAbs/oom>5?1:.5)*oom;
  let count = Math.ceil(diff/distance);
  if(count<=2&&oom!==1){
    distance/= 10;
    count*= 10; 
  }
  // adjust min to align to distance intervals
  const minOffset = min%distance;
  const minAdjusted = min-minOffset+((minOffset<0?-1:0)*distance);
  const ticks = new Array(count+1).fill(minAdjusted).map((v,i)=>v+i*distance);
  return ticks;
}

/**
 * @returns Decimal sum of the numbers(in string type)
 */
export function parseAndSum(...numberStrings:(string|undefined)[]){
  return numberStrings.reduce((sum,numberString)=>sum.add(new Decimal(numberString ?? 0)),new Decimal(0));
}