import { SxProps, TableCell } from '@mui/material';
import { sxVerticalSeparator } from 'src/styles/Table';
import { sxDesktopOnly, sxMonoFont } from '../styles/General';
import { sxStickeyCell } from '../styles/TokenTable';

const sxCell:SxProps = {
  position: 'relative',
  // disable mobile wrap layout
  // [theme.breakpoints.down('tablet')]:{
  //   overflow: 'auto',
  //   ':is(td):nth-of-type(n)':{
  //     width: 'auto', p: 1, //bgcolor: 'inherit',
  //   },
  //   // paddingTop: '1.75rem',
  //   ':before':{
  //     content: 'attr(data-label)',
  //     fontSize: '0.75rem',
  //     display: 'block',
  //     opacity: 0.5,
  //     whiteSpace: 'nowrap',
  //   }
  // },
  'label:first-of-type':{
    position: 'absolute',
    fontSize: '0.75rem',
    top: '0', 
    // [theme.breakpoints.up('tablet')]:{
    //   display:'none',
    // },
  }
};

function ValueTableCell(props:any){
  const {
    label="",order,width,hideOnMobile=false,sticky=false,mono=false,borderRight=false,
  }:{
    label?:string,order?:number,width?:string,hideOnMobile?:boolean,sticky?:boolean,mono?:boolean,borderRight?:boolean,
  } = props;
  let passedProps = Object.assign({},props);
  delete passedProps.width;
  delete passedProps.hideOnMobile;
  delete passedProps.sticky;
  delete passedProps.mono;
  delete passedProps.borderRight;
  return (
    <TableCell className={sticky ? 'sticky' : ''} align="left" {...passedProps} sx={{
      ...sxCell,
      ...(hideOnMobile?sxDesktopOnly:{}),
      ...(sticky?sxStickeyCell:{}),
      ...(props.sx||{}),
      ...(borderRight?sxVerticalSeparator:{}),
      ...(label?{}:{alignSelf:'center'}),
      ...(mono?sxMonoFont:{}),
      order,width:width?`${width} !important`:undefined,
    } as SxProps} data-label={label}>{/* <label>{label}</label> */}{props.children}</TableCell>
  )
}


export default ValueTableCell;