import { Box, FormControl, InputLabel, SxProps, Table, TableBody, TableCell, TableRow } from '@mui/material';
import Decimal from 'decimal.js';
import { isUndefined } from 'lodash';
import SwapMarketSwitcher from 'src/Components/Swaps/SwapMarketSwitcher';
import { getMarketByLeg, getTokenByLeg } from 'src/Components/Swaps/selectors';
import TokenAmountInput from 'src/Components/TokenAmountInput';
import { useSwapParameterStore } from 'src/store/useSwapParametersStore';
import { styleFormCell, sxCompactFormTable, sxInputLabel } from 'src/styles/Form';
import { EOrderSide, ESwapTradeSideLabel } from 'src/types';
import SwapTokenSwitcher from './SwapTokenSwitcher';

const sxSwapFormTable:SxProps = {
    maxWidth: '100%',
    "td":{
      border: 0,
      py: '12px',
      px: '0px'
    },
}

const sxSectionTitle:SxProps = {
  fontSize: '1.125rem',
  lineHeight: '1.75rem',
  fontWeight: '700'
}

const sxAssetSelectedItem = {
  ".MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input": {
    paddingLeft: '2px'
  }
}

const displayExchangeRate = (exchangeRate: string) => {
    const exchangeRateDecimal = new Decimal(exchangeRate)
    return exchangeRateDecimal.toFixed(exchangeRateDecimal.gte(1) ? 2 : 6);
}

const SwapSecondaryTradeForm = ({ legKey, quantity, exchangeRate, primarySide }: any) => {
    const primaryToken = useSwapParameterStore(getTokenByLeg('leg1'))
    const token = useSwapParameterStore(getTokenByLeg(legKey))
    const market = useSwapParameterStore(getMarketByLeg(legKey))
    const isFixedMarket = !isUndefined(market?.daysToMaturity)
    const quantityStep = market?.quantityStep||'0'; 
    const decimals = new Decimal(quantityStep).decimalPlaces()
    const orderSideLabel = `(${primarySide === EOrderSide.LEND ? ESwapTradeSideLabel.PAY : ESwapTradeSideLabel.RECEIVE})`

    return (<>
        <Box sx={sxSectionTitle}>Leg 2: {isFixedMarket ? 'Fixed': 'Float'} {orderSideLabel}</Box>
        <Table sx={{...sxCompactFormTable, ...sxSwapFormTable }}>
          <TableBody>
            <TableRow hover>
              <TableCell>
                <Box sx={{display:'grid',gridAutoColumns:'1fr',gridAutoFlow:'column',columnGap:'16px'}}>
                    <FormControl variant="standard" fullWidth sx={{...styleFormCell, ...sxAssetSelectedItem}}>
                      <InputLabel shrink htmlFor="market-select" sx={sxInputLabel}>Asset</InputLabel>
                      <SwapTokenSwitcher legKey={"leg2"}/>
                    </FormControl>
                    <FormControl variant="standard" fullWidth sx={styleFormCell}>
                      <InputLabel shrink htmlFor="market-select" sx={sxInputLabel}>Maturity</InputLabel>
                      <SwapMarketSwitcher legKey={"leg2"} />
                    </FormControl>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>
                <TokenAmountInput name='quantity' fullWidth
                  label='Size'
                  sx={styleFormCell} suffix={token?.code} bottomLabel={<>{`(${primaryToken?.code}:${token?.code} @ ${displayExchangeRate(exchangeRate)})`}</>}
                  readOnly 
                  amount={quantity}
                  decimals={decimals}
                  placeholder={new Decimal(0).toFixed(decimals)}
                  disabled
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>);
}

export default SwapSecondaryTradeForm