import { SxProps } from '@mui/material';
import { gridHeaderHeight, sxRowsContainer } from 'src/styles/AgGrid';

export const sxRowsContainerOrderBook:SxProps = {
    ...sxRowsContainer,
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr 40px 1fr",
    gridAutoRows: "1fr",
    gap: "0px 0px",
    gridAutoFlow: "row",
    gridTemplateAreas:`
      "asks"
      "rate"
      "bids"
    `,
};

export const sxEQRateRow:SxProps = {
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    borderWidth: '0',
    borderStyle: 'solid',
    borderColor: 'border.dark',
    borderTopWidth: '1px',
    borderBottomWidth: '1px',
    alignItems: 'center',
    fontSize: '1.25em',
};

export const sxEQRateCell:SxProps = {
    flex: 1,
    textAlign: 'left',
    fontWeight: 700,
    px:2,
};

export const sxRows:SxProps = {
    '.value-delta-up':{ '--ag-value-change-value-highlight-background-color': 'var(--animCellUpBgColor)', },
    '.value-delta-down':{ '--ag-value-change-value-highlight-background-color': 'var(--animCellDownBgColor)', },
};

export const sxRowsAsks:SxProps = { ...sxRows, gridArea: "asks", overflow: "hidden", 
    pt: `${gridHeaderHeight}px`,
    "&>div":{
      display: "flex",
      width: "100%",
      justifyContent: "stretch",
      alignItems: "flex-end",
    },
    ".ag-header": {
      display:'none',
    },
    ".ag-root-wrapper":{
      width:"100%",
    },
    ".ag-row:hover":{
      bgcolor: 'negative.300',
    },
};

export const sxRowsRate:SxProps = { gridArea: "rate", ...sxEQRateRow };
export const sxRowsBids:SxProps = { ...sxRows, gridArea: "bids", overflow: "hidden", 
  ".ag-row:hover":{
      bgcolor: 'positive.300',
    },
};
export const sxCellFontSmoothing:SxProps = { fontSmoothing: 'antialiased', WebkitFontSmoothing: 'antialiased' };
export const sxCellInner:SxProps = { ...sxCellFontSmoothing, zIndex:1 };
export const sxCellInnerPercentage:SxProps = {...sxCellInner,position:'fixed',top:'1px',bottom:'1px',right:'0px'};
export const sxRateCell:SxProps = {
    textAlign:'right'
};
  
export const sxRateCellAsks:SxProps = {
    ...sxRateCell,
    color: 'negative.light'
};

export const sxRateCellBids:SxProps = {
    ...sxRateCell,
    color: 'positive.light'
};

export const sxVolumeCell: SxProps = {
  textAlign: 'right',
  color: 'orderBook.main'
}