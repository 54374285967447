import { Alert, Box, Button, Snackbar, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Navigate,
  Outlet, Route, Routes, useLocation
} from "react-router-dom";
import { CHAIN_ID, FEATURE_VERSION } from 'src/constants/app';
import ErrorFallback from 'src/Components/ErrorFallback';
import Footer, { FOOTER_HEIGHT } from 'src/Components/Footer';
import Header, { HEADER_HEIGHT } from 'src/Components/Header';
import QuickStartTipsFab from 'src/Components/QuickStartTipsFab';
import RolloverDialog from 'src/Components/RolloverDialog';
// pages
import { useQueryClient } from '@tanstack/react-query';
import MarketsDashboard from 'src/Pages/MarketsDashboard';
import TradeDashboard from 'src/Pages/TradeDashboard';
import useAppStore from 'src/store';
import { sxContent, useTestnetNotificationHeight } from 'src/styles/Page';
import { RequireAuth } from './AuthProvider';
import WithGlossaryTooltip from './Components/WithGlossaryTooltip';
import Explore from './Pages/Explore';
import LiquidityDashboard from './Pages/LiquidityDashboard';
import LiquidityTransaction from './Pages/LiquidityTransaction';
import MarketTrade from './Pages/MarketTrade';
import MarketUnknown from './Pages/MarketUnknown';
import MyPortfolio from './Pages/MyPortfolio';
import PortfolioLPHistory from './Pages/PortfolioLPHistory';
import SwapsAndDerivatives from './Pages/SwapsAndDerivatives';
import UserPublicProfile from './Pages/UserPublicProfile';
import { sxDesktopOnly, sxMobileOnly } from './styles/General';
import apis from './utils/apis';
import { sendGA, usePageTitle } from './utils/common';
import { timeTo } from './utils/date';
import theme from './utils/theme';

function AppContent() {
  const rolloverRef = useRef<any>(null)
  const location = useLocation();
  // DEV prefetch is glitchy with retry option
  // apis.token.usePrefetchMarketsPageTokens();
  apis.token.useMarketsPageTokens();
  // market rollover trigger
  const queryClient = useQueryClient();
  const {nextMarketRolloverTime} = apis.infinity.useMarketRolloverHour();
  // reset query cache for all markets when rollover time comes 
  useEffect(()=>{
    if(!queryClient||!nextMarketRolloverTime) return;
    let nextResetTimeout:NodeJS.Timeout|undefined;
    const scheduleResetMarketCache = (()=>{
      const ms = timeTo(nextMarketRolloverTime);
      if (ms > 0) {
        nextResetTimeout = setTimeout(()=>{
          rolloverRef.current?.openDialog?.();
          queryClient.invalidateQueries(['market']);
          queryClient.invalidateQueries(['market',{category:'all',action:'listPastRates'}]);
          queryClient.invalidateQueries(['market',{category:'fixed',action:'list'}]);
          queryClient.invalidateQueries(['appState','serverTime']);
          scheduleResetMarketCache();
        }, ms);
      }
    });
    scheduleResetMarketCache();
    return ()=>{ 
      if(nextResetTimeout!==undefined) {
        clearTimeout(nextResetTimeout); 
      }
    };
  },[nextMarketRolloverTime,queryClient]);
  // on location change
  useEffect(()=>{
    sendGA('page_view');
  },[location]);
  return (
    <Routes>
      <Route element={<Layout ref={rolloverRef} /> }>
        <Route path="/" element={<Navigate replace to="/markets" state={{from:location}}/> }/>
        {/* markets */}
        <Route path="/markets" element={<MarketsDashboard/>} />
        <Route path="/lend-borrow">
          <Route index element={<MarketTrade/>} />
          <Route path=":tokenCode" element={<MarketTrade/>} />
          <Route path="*" element={<Navigate replace to="/markets" state={{from:location}} />} />
        </Route>
        <Route path="/trade">
          <Route path=":tokenId/:marketId/:marketCode" element={<TradeDashboard/>} />
          <Route index element={<TradeDashboard/>} />
          <Route path="*" element={<Navigate replace to="/trade" state={{from:location}} />} />
        </Route>
        {FEATURE_VERSION === 'development' && <Route path="/liquidity">
            <Route index element={<LiquidityDashboard/>} />
            <Route path=":marketCode" element={<LiquidityTransaction/>} />
            <Route path="*" element={<Navigate replace to="/liquidity" state={{from:location}} />} />
          </Route>
        }
        {FEATURE_VERSION === 'development' && <Route path="/derivatives">
            <Route index element={<SwapsAndDerivatives/>} />
            <Route path=":swapType" element={<SwapsAndDerivatives/>} />
            <Route path="*" element={<Navigate replace to="/derivatives/irs" state={{from:location}} />} />
          </Route>
        }
        {/* global page */}
        <Route path="/explore">
          <Route path="overview" element={<Explore tab={"overview"}/>} />
          <Route path="trades" element={<Explore tab={"trades"}/>} />
          <Route path="accounts" element={<Explore tab={"accounts"}/>} />
          <Route path="user"><Route path=":userAddress" element={<UserPublicProfile/>} /></Route>
          <Route index element={<Navigate replace to="/explore/overview" state={{from:location}} />} />
        </Route>
        {/* TODO implement */}
        <Route path="/derivatives" element={<ComingSoon/>} /> {/* element={<Derivatives/>} /> */}
        <Route path="/strategy" element={<ComingSoon/>} /> {/* element={<Strategy/>} /> */}
        <Route path="/insights" element={<ComingSoon/>} /> {/* element={<Insights/>} /> */}
        {/* user pages */}
        {FEATURE_VERSION === 'development' && <Route path="/my-portfolio/lp-positions/:accountId/:lpId" element={<RequireAuth><PortfolioLPHistory /></RequireAuth>} />}
        <Route path="/my-portfolio">
          {/* cash */}
          <Route path="accounts" element={<RequireAuth><MyPortfolio tab={"accounts"}/></RequireAuth>} />
          <Route path="cash-transfers" element={<RequireAuth><MyPortfolio tab={"cash-transfers"}/></RequireAuth>} />
          {/* trading account */}
          <Route path="positions-pnl" element={<RequireAuth><MyPortfolio tab={"positions-pnl"}/></RequireAuth>} />
          <Route path="risk-overview" element={<RequireAuth><MyPortfolio tab={"risk-overview"}/></RequireAuth>} />
          <Route path="open-orders" element={<RequireAuth><MyPortfolio tab={"open-orders"}/></RequireAuth>} />
          <Route path="order-history" element={<RequireAuth><MyPortfolio tab={"order-history"}/></RequireAuth>}>
            <Route path=":tokenId" element={<Navigate replace to="/order-history" />} >
              <Route path=":marketId" element={<Navigate replace to="/order-history" />} />
            </Route>
            <Route path="*" element={<Navigate replace to="/order-history" />} />
          </Route>
          {/* positions */}
          {FEATURE_VERSION === 'development' && <Route path="lp-positions" element={<RequireAuth><MyPortfolio tab={"lp-positions"}/></RequireAuth>} />}
          {/* mtm - hidden from menu */}
          <Route path="mtm-performance" element={<RequireAuth><MyPortfolio tab={"mtm-performance"}/></RequireAuth>} />
          {/* default */}
          <Route index element={<Navigate replace to="/my-portfolio/accounts" state={{from:location}} />} />
        </Route>
        <Route path="/unknown-market">
          <Route index element={<MarketUnknown />} />
          <Route path=":tokenId" element={<MarketUnknown/>} />
        </Route>
        {/* default fallback */}
        <Route path="*" element={<NoMatch/>}/>
      </Route>
    </Routes>
  );
}

const  Layout = forwardRef((props, ref) => {
  const topOffset = useTestnetNotificationHeight();
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Box sx={{
        overflow: 'hidden',
        height: '100%',
        bgcolor: 'background.default',
        color: 'text.primary',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: `${HEADER_HEIGHT} 1fr ${FOOTER_HEIGHT}`,
        gridAutoRows: '1fr',
        gap: '0px 0px',
        gridAutoFlow: 'row',
        gridTemplateAreas: `
          "header"
          "main"
          "footer"`,
      }}> 
        <Noticebar/>
        <Header featureVersion="development"/>
        <WithGlossaryTooltip>
          <Box sx={{
            gridArea: 'main',
            bgcolor: 'background.default',
            overflowX: 'hidden',
            paddingTop: topOffset,
          }}>
            <Outlet />
          </Box>
          </WithGlossaryTooltip>
        <QuickStartTipsFab/>
        <Footer/>
      </Box>
      <RolloverDialog ref={ref} />
    </ErrorBoundary>
  );
})

function Noticebar(){
  const appstate = useAppStore();
  const { showsTestnetNotification } = appstate;
  // const [showingSnackbar,setShowingSnackbar] = useState(true);
  const noticeBarHeight = useTestnetNotificationHeight();
  useEffect(()=>{
    if (CHAIN_ID !== 11155111) {
      appstate.set('showsTestnetNotification',false);
    }
  },[]);
  const closeBanner = () => {
    appstate.set('showsTestnetNotification',false);
  }
  if(!showsTestnetNotification) return null;
  return (<>
    <Box sx={{
      gridArea: 'notice-bar',
      px: 2,
      bgcolor:'amber.900',
      color: 'black',
      fontSize: '0.875rem',
      fontFamily: 'Inter',
      height: noticeBarHeight,
      lineHeight: noticeBarHeight,
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      whiteSpace: 'nowrap',
      overflow: 'auto',
      zIndex: theme.zIndex.appBar,
      textAlign: 'center',
      'a':{
        display:'inline',
      },
      ...sxDesktopOnly
    }}>
      Welcome to Infinity Exchange's Testnet App. 
      We are currently running under the Sepolia Network. Click&nbsp;<a target="_blank" rel="noreferrer" href="https://www.infinity.exchange/article/infinity-testnet-v2-a-quickstart-guide-to-lend-borrow-crypto-dec-2023">here</a>&nbsp;for our quickstart guide.
      <Button sx={{minWidth:0, padding: 'auto', float: 'right'}} onClick={closeBanner}><Close sx={{ width: '0.875rem', height: '0.875rem', color: 'black' }}/></Button>
    </Box>
    <Box
      sx={{...sxMobileOnly,top:'50px'}}
    >
      <Alert severity="warning" sx={{bgcolor:'amber.900',color:'black'}} onClose={closeBanner}>
      Welcome to Infinity Exchange's Testnet App. 
      We are currently running under the Sepolia Network. Click&nbsp;<a target="_blank" rel="noreferrer" href="https://www.infinity.exchange/article/infinity-testnet-v2-a-quickstart-guide-to-lend-borrow-crypto-dec-2023">here</a>&nbsp;for our quickstart guide.
      <Button sx={{minWidth:0, padding: 'auto', float: 'right'}} onClick={closeBanner}><Close sx={{ width: '0.875rem', height: '0.875rem', color: 'black' }}/></Button>
      </Alert>
    </Box>
    </>
  )
}

function NoMatch(){
  usePageTitle(['404']);
  return (
    <Box sx={sxContent}>
      <Typography variant='h3'>Turn around, these aren't the droids you're looking for.</Typography>
    </Box>
  );
}

function ComingSoon(){
  usePageTitle([]);
  return (
    <Box sx={sxContent}>
      <Typography variant='h3'>This page is coming soon!</Typography>
    </Box>
  );
}

export default AppContent;
