import { Box } from '@mui/material';
import Decimal from 'decimal.js';
import { isNil, reduce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CartesianGrid, ReferenceLine, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts';
import { CategoricalChartFunc as ICategoricalChartFuncProps } from 'recharts/types/chart/generateCategoricalChart';
import { useAuth } from 'src/AuthProvider';
import { GraphPositionContextType } from 'src/Components/MarketGrids/GraphPositionProvider';
import { FLOAT_SHORT, RATE_BUCKET_BP_THRESHOLD, RATE_BUCKET_SIZE } from 'src/constants/app';
import useCutoffDays from 'src/hooks/useCutoffDays';
import useDaysToMaturityMap from 'src/hooks/useDaysToMaturity';
import useRateBucketData from 'src/hooks/useRateBucketData';
import useAppStore from 'src/store';
import { sxChartPaddingWrapper, sxChartTooltip, sxChartTooltipLegendBoxTitle, sxChartWrapper, sxChartWatermarkAboveCenter } from 'src/styles/Chart';
import { MarketPastRate, RateBucketEntry, Token, UserTokensGraphDateBucketType } from 'src/types';
import apis from 'src/utils/apis';
import { hslStrToHex } from 'src/utils/common';
import { useCallbackRollingDateLabel } from 'src/utils/date';
import { format, formatUSDWithSign } from 'src/utils/numbers';
import { toPercentage } from 'src/utils/string';
import theme from 'src/utils/theme';
import GraphEmptyStateCurtain from './GraphEmptyStateCurtain';
import LoadingIcon from './Loading';

const MIN_OPACITY = 0.2;
const OPACITY_RANGE = 0.8;

const ChartTooltip = ({ active, payload, cutoffDays, showsUSDValue }: {active?:boolean, payload?:any, cutoffDays:number[], showsUSDValue?:boolean } ): React.ReactElement | null => {
  const getRollingDateLabel = useCallbackRollingDateLabel();
  if (active && payload && payload.length) {
    const dayIndex = payload[0].payload.dayIndex;
    const label = dayIndex === 0 ? 'FLOAT' : getRollingDateLabel(cutoffDays[dayIndex]);
    const dataPayload = payload[0].payload;
    return (
      <Box sx={sxChartTooltip}>
        <Box sx={sxChartTooltipLegendBoxTitle}>{label}</Box>
        <Box>
          <Box>{`Rate: ${toPercentage(dataPayload.minRate)}% ${dataPayload.minRate !== dataPayload.maxRate ? ` - ${toPercentage(dataPayload.maxRate)}%` : ''} (${dataPayload.bp > 0 ? '+' : ''}${format(new Decimal(dataPayload.bp),1)}bp)`}</Box>
          <Box>{`Size: ${showsUSDValue?formatUSDWithSign(new Decimal(payload[0].payload.quantity)):format(new Decimal(payload[0].payload.quantity))}`}</Box>
        </Box>
      </Box>
    );
  }
  return null;
}

/**
 * 
 * @param type data to be rendered
 * @param bucketData aggregate data inbetween standard dates as bucket
 * @returns 
 */
function RateBucketsGraph({token,type,bucketData,graphPosition, maxGraphHeight,showsUSDValue,dateBucketType='By Contract',padGraph,showsEmptyStateCurtain}:{token?:Token,type:'userOrders'|'liquidity',bucketData?:boolean,graphPosition?:GraphPositionContextType, maxGraphHeight?: number,showsUSDValue?:boolean,dateBucketType?:UserTokensGraphDateBucketType,padGraph?:boolean,showsEmptyStateCurtain?:boolean}){
  const boxRef = useRef<HTMLElement>(null);
  const { xPosition, setActiveGraph, handleMousePosition } = graphPosition||{};
  const auth = useAuth();
  // user account info
  const { currentUserAccountId: currentUserAccountId } = useAppStore();
  const [ xPadding, setXPadding] = useState<number>(15);
  const [ yRefPosition, setYRefPosition ] = useState<number | null>(null);
  const { data:userAccounts } = apis.user.useUserAccounts(auth);
  const tradeAccount = userAccounts&&apis.user.extractUserAccount(userAccounts,currentUserAccountId);
  
  const tokenId = token?.tokenId;
  const {isLoading:isLoadingMarket,data:spotMarket} = apis.rate.useMarket({tokenId});
  const irMarketId = spotMarket?.marketId;
  const {isLoading:isLoadingMarketsFixedRate,data:marketsFixedRate} = apis.fixedrate.useFixedRateMarkets({tokenId});

  // checks if user has any order placed (for showing "start trading prompt")
  const {data:userAccountPerformanceLive} = apis.user.useUserAccountPerformanceLive(auth,{ accountId: tradeAccount?.accountId });
  const groupedPositions = useMemo(() => userAccountPerformanceLive?.positionDetails, [userAccountPerformanceLive])
  const hasPlacedOrders = useMemo(()=>{
    return (groupedPositions?.length||0)>=0;
  },[groupedPositions]);

  const getRollingDateLabel = useCallbackRollingDateLabel();
  
  const frMarketIds = marketsFixedRate?.map(m=>m.marketId);
  const {isFetching:isFetchingRateBuckets,data:rateBuckets} = type==='userOrders'?
    apis.rate.useUserMarketRateBuckets(auth,{irMarketId,frMarketIds,accountId:tradeAccount?.accountId}):
    apis.rate.useMarketRateBuckets({irMarketId,frMarketIds});
  const {isLoading:isLoadingMarketsRatesByToken,data:marketsRatesByToken} = apis.rate.useMarketsPastRates({tokenId,frMarketIds});
  const isLoadingAny = isLoadingMarket||isLoadingMarketsFixedRate||isFetchingRateBuckets||isLoadingMarketsRatesByToken;
  const cutoffDays = useCutoffDays(marketsFixedRate,dateBucketType);
  const daysToMaturityMap = useDaysToMaturityMap(spotMarket, marketsFixedRate);
  
  const dataArray: RateBucketEntry[] = useMemo( () => (rateBuckets||[]), [rateBuckets] );
  const isEmpty = dataArray.length===0;
  const marketRatesMap = useMemo(
    () => {
      if (isNil(marketsRatesByToken)) return {};
      return reduce(marketsRatesByToken, (map:{[marketId:number]: MarketPastRate & { marketRate: number}}, marketRates: MarketPastRate[]) => {
        marketRates.forEach((mr: MarketPastRate) => {
          map[mr.marketId] = 
            {
              ...mr,
              marketRate: new Decimal(mr?.midRate||mr?.rate||0).toNumber()
            }
        })
        return map;
      },{});
    }, [marketsRatesByToken]
  );

  const data = useRateBucketData({marketRatesMap,daysToMaturityMap,cutoffDays,dataArray,showsUSDValue,token});

  const yMax = RATE_BUCKET_BP_THRESHOLD;
  const xTicks = [...cutoffDays.map((v,i)=>i)];

  useEffect(() => {
    if (boxRef.current !== null) {
      const xAxisNode: HTMLElement | null = boxRef.current.querySelector('.recharts-xAxis .recharts-cartesian-axis-line')
      if (xAxisNode === null) return
      const xAxisWidth: number = Number(xAxisNode?.getAttribute('width')) ?? 0;
      const xAxisOffset: number = Number(xAxisNode?.getAttribute('x')) ?? 0;
      const padding = Math.max(10,(xAxisWidth-xAxisOffset)/cutoffDays.length/2);
      if (xPadding !== padding) {
        setXPadding(padding);
      }      
    }
  }, [boxRef, cutoffDays, xPadding]);

  const handleMouseMove: ICategoricalChartFuncProps = useCallback(
    (e) => {
      if (e === null) {
        handleMousePosition&&handleMousePosition(null)
        setYRefPosition(null)
        return
      }
      if (!isNil(e.xValue)) {
        // The vertical zone is from {x}.5 to {x+1}.5
        const xValue = Math.round(e.xValue)
        handleMousePosition&&handleMousePosition(xValue)
      }

      if (yRefPosition !== e.yValue)
        if (!isNil(e.yValue)) {
          setYRefPosition(e.yValue)
        } else {
          setYRefPosition(null)
        }
    }, [handleMousePosition, yRefPosition, setYRefPosition]
  );

  const handleMouseLeave = useCallback(
    () => {
      setActiveGraph&&setActiveGraph(null);
      handleMousePosition&&handleMousePosition(null);
      setYRefPosition(null)
    }, [setActiveGraph, handleMousePosition, setYRefPosition]
  );

  const sxContainerStyle = {
    ...(padGraph ? sxChartPaddingWrapper : sxChartWrapper),
    ...sxChartWatermarkAboveCenter
  }

  return (<Box sx={sxContainerStyle} ref={boxRef} onMouseEnter={setActiveGraph&&(() => setActiveGraph(type))} onMouseLeave={handleMouseLeave}><ResponsiveContainer maxHeight={maxGraphHeight}>
    <ScatterChart data={data} onMouseMove={handleMouseMove}>
      <CartesianGrid vertical={false} stroke={`${hslStrToHex(theme.palette.text.primary)}22`} />
      <ReferenceLine y={0} stroke="#ffffff33" />
      <YAxis fontSize={"0.625rem"} dataKey={'bp'} type="number" 
        domain={[-yMax,yMax]} 
        tickLine={false} tickFormatter={(v,i)=>`${v}bp`} width={40} stroke={`${hslStrToHex(theme.palette.text.primary)}88`}
      />
      <XAxis fontSize={"0.625rem"} padding={{left: xPadding, right: xPadding}} interval={"preserveEnd"} dataKey={'dayIndex'} type="number" ticks={[...xTicks]} domain={[xTicks[0], xTicks[xTicks.length - 1]]} stroke={`${hslStrToHex(theme.palette.text.primary)}88`} tickLine={false} tickFormatter={(v,dataIdx)=>{
        let label = '';
        switch(dateBucketType){
          case 'Monthly': label = `${dataIdx+1}M`; break;
          case 'Quarterly': label = `${dataIdx>=4?'1Y':''}Q${dataIdx%4+1}`; break;
          case 'Semi-Annually': label = `${dataIdx>=2?'1Y':''}${dataIdx%2+1}H`; break;
          case 'Annually': label = `${dataIdx+1}Y`; break;
          case 'By Contract': default:
            label = `${(v < 0 || v > 11) ? '' : (v===0?FLOAT_SHORT:getRollingDateLabel(cutoffDays[v]))}`; break;
        }
        return label;
      }}/>
      <Scatter name="values" shape={(payload)=>{
        const {cx,cy,xAxis,yAxis,bp,quantity,localMaxQuantity, dayIndex} = payload;
        if (!quantity) return (<rect x={0} y={0} rx="0" ry="0" width={0} height={0}>mark</rect>)
        const yCount = RATE_BUCKET_BP_THRESHOLD * 2 / RATE_BUCKET_SIZE;
        const w = Math.max(10,(xAxis.width-xAxis.x)/cutoffDays.length-2); // -2 margins
        const calculatedHeight = Math.floor(yAxis.height/Math.max(1,yCount))
        const h = new Decimal(quantity||0).eq(0)?0:yCount===0?20:Math.max(5,calculatedHeight - 2);
        const x = cx-w/2;
        const y = cy - h;
        const quantityRatio = quantity/(localMaxQuantity||1);
        return (<rect key={`${dayIndex}-${bp}`} style={{
          fill: `${bp>=0?hslStrToHex(theme.palette.negative.main):hslStrToHex(theme.palette.positive.main)}aa`,
          opacity: quantityRatio * OPACITY_RANGE + MIN_OPACITY,
        }} x={x} y={y} rx="0" ry="0" width={w} height={h}>mark</rect>)
      }}/>
      { /* If we remove and add back the the reference line every time when we leave or enter to the graph area then the bars would be rerendered with the rendering animation.
        To avoid this effect we keep the reference line and just make it hidden when we don't want to show it */}
      <ReferenceLine y={yRefPosition ?? undefined} strokeDasharray='3 3' stroke={`${hslStrToHex(theme.palette.text.primary)}88`} visibility={yRefPosition === null ? 'hidden' : 'visible'}/>
      <ReferenceLine x={xPosition ?? undefined} strokeDasharray='3 3' stroke={`${hslStrToHex(theme.palette.text.primary)}88`} visibility={xPosition === null ? 'hidden' : 'visible'}/>
      {/* If we use the Tooltip's cursor than in a lot of case the reference line will jump to the top-left corner and back, to avoid this we use our own reference line instead */}
      <Tooltip content={<ChartTooltip cutoffDays={cutoffDays} showsUSDValue={showsUSDValue}/>} cursor={false} animationDuration={0}/>
      
    </ScatterChart>
  </ResponsiveContainer>
  {isEmpty&&showsEmptyStateCurtain&&<GraphEmptyStateCurtain showsStartTrading={!hasPlacedOrders} extraText={!hasPlacedOrders?undefined:'No Orders'}/>}
  {(isLoadingAny&&!rateBuckets)&&<LoadingIcon curtain/>}
  </Box>);
}

export default RateBucketsGraph;