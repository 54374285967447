import {
  Box, SxProps
} from '@mui/material';
import { ColumnApi, GridApi } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import Decimal from 'decimal.js';
import { useEffect, useRef, useState } from 'react';
import LoadingIcon from 'src/Components/Loading';
import { gridHeaderHeight, gridRowHeight, sxRowsContainer } from 'src/styles/AgGrid';
import { styleGrid, styleHeader, stylePanelNoPadding } from 'src/styles/GridBox';
import { sxVolumeCell } from 'src/styles/OrderBook';
import { EOrderSide, RateTransaction } from 'src/types';
import apis from 'src/utils/apis';
import { dps, format, formatTime } from 'src/utils/numbers';
import { GridComponentParams } from '.';
import DragGrabber from './DragGrabber';

const sxCell:SxProps = {
  fontSmoothing: 'antialiased',
  WebkitFontSmoothing: 'antialiased',
  textAlign: 'right'
};

function MarketTrades({token,market}:GridComponentParams){
  const limit = 100;
  const {isLoading,error,data:trxs} = apis.rate.useMarketTransactions({market,limit});
  const [rateTransactions,setRateTransactions] = useState<RateTransaction[]>([]);
  const gridApiRef = useRef<GridApi|undefined>();
  const columnApiRef = useRef<ColumnApi|undefined>();
  const onGridReady = (params:any) => {
    // setGridApi(params.api);
    gridApiRef.current = params.api;
    columnApiRef.current = params.columnApi;
    if (trxs && trxs?.length > 0 && gridApiRef.current) {
      const gridCfg:{[key:string]:RateTransaction[]} = {add:trxs||[],update:[],remove:[]};
      gridApiRef.current.forEachNode((node)=>gridCfg.remove.push(node.data as RateTransaction));
      gridApiRef.current.applyTransactionAsync(gridCfg);
    }
    // params.api.sizeColumnsToFit();
    window.addEventListener('resize', function () {
      setTimeout(function () {
        // params.api.sizeColumnsToFit();
      });
    });
    // params.api.sizeColumnsToFit();
  };
  useEffect(()=>{
    // console.log('reset rows on market change');
    gridApiRef.current?.setRowData([]); // reset on market change
  },[gridApiRef,market?.marketId]);
  useEffect(()=>{
    const gridApi = gridApiRef.current;
    const columnApi = columnApiRef.current;
    if(!gridApi || !columnApi) return;
    // console.log('trxs',trxs?.length);
    const gridCfg:{[key:string]:RateTransaction[]} = {add:trxs||[],update:[],remove:[]};
    gridApi.forEachNode((node)=>gridCfg.remove.push(node.data as RateTransaction));
    gridApi.applyTransactionAsync(gridCfg);
  },[gridApiRef,columnApiRef,trxs]);

  const quantityStepDecimals = dps(new Decimal(market?.quantityStep||0).toNumber());

  function SizeCell({value,data}:{value:string,data:RateTransaction}){
    return (
      <Box sx={{ ...sxCell, ...sxVolumeCell } as SxProps}>{format(value,quantityStepDecimals)}</Box>
    );
  }
  function RateCell({value,data}:{value:string,data:RateTransaction}){
    return (
      <Box sx={{...sxCell, color:data.side === EOrderSide.LEND ? 'success.main' : 'error.main'}}>{format(new Decimal(value||0).mul(100),2)}%</Box>
    );
  }
  function TimeCell({value,data}:{value:string,data:RateTransaction}){
    return (
      <Box sx={{ ...sxCell, ...sxVolumeCell } as SxProps}>{formatTime(value)}</Box>
    );
  }

  return (
    <Box sx={styleGrid}>
      <Box sx={styleHeader}>Market Trades<DragGrabber/></Box>
      <Box sx={stylePanelNoPadding} >
        <Box sx={sxRowsContainer}>
        {isLoading&&<LoadingIcon curtain/>}
        <AgGridReact /* animateRows */ /* enableCellChangeFlash */ rowData={rateTransactions}
          /* domLayout='autoHeight' */ headerHeight={gridHeaderHeight} rowHeight={gridRowHeight} 
          onGridReady={onGridReady}
          suppressMovableColumns suppressNoRowsOverlay columnDefs={[
          {field:"rate",headerName:"Rate",cellRenderer:RateCell, width: 60, cellStyle: {paddingLeft: '12px'}},
          {field:"quantity",headerName:"Size",cellRenderer:SizeCell,flex:3},
          {field:"date",headerName:"Time",cellRenderer:TimeCell,flex:3},
        ]}/>
        </Box>
      </Box>
    </Box>
  );
}

export default MarketTrades;